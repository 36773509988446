<template>
<message-bubble
  :isMine="isMine"
  :message="message"
  :groupId="groupId"
  :customClass="customClass">
  <div class="custom-element-wrapper">
    <div class="survey"  v-if="payload.data === 'survey'">
      <div class="title">对IM DEMO的评分和建议</div>
      <el-rate
          v-model="rate"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}">
      </el-rate>
      <div class="suggestion">{{payload.ext}}</div>
    </div>
    <share-message
      :message="message"
      :isInner="isInner"
      :currentConversation="currentConversation"
      v-else-if="!payload.data && payload.ext && payload.ext.type.indexOf('Share') > -1">
    </share-message>
    <span
      class="at-message"
      v-html="translateAtMessage(payload.ext.data.message)"
      v-else-if="!payload.data && payload.ext && payload.ext.type === 'atMessage'">
    </span>
    <span
      class="common-template-message"
      v-else-if="!payload.data && payload.ext && payload.ext.type === 'visibleLink'"
    >
      <i v-if="payload.ext.data.title" class="common-template-message-title">{{payload.ext.data.title}}</i>
      <i v-if="payload.ext.data.content" class="common-template-message-content">{{payload.ext.data.content}}</i>
      <a 
        v-if="payload.ext.data.button && payload.ext.data.url && payload.ext.data.url.indexOf('departmentCreateRequestDeny') == -1" 
        class="common-template-message-link" 
        target="_blank" 
        :class="{'disable-link': isDisableLink}"
        :href="payload.ext.data.url">{{payload.ext.data.button}}</a>
      <a 
        v-if="payload.ext.data.button && payload.ext.data.url && payload.ext.data.url.indexOf('departmentCreateRequestDeny') > -1" 
        class="common-template-message-link" 
        href="javascript:void(0)" 
        @click="showAuditDenyDetail(payload.ext.data.url)">{{payload.ext.data.button}}</a>
    </span>
    <span class="text" v-else>{{text}}</span>
  </div>
</message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
import ShareMessage from './share-message'
import emitter from '@src/js/mixins/emitter';
import { ACTION } from '../../utils/trtcCustomMessageMap'
import { formatDuration } from '../../utils/formatDuration'
import { emojiMap, emojiUrl } from '#/js/util/emojiMap.js'

export default {
  name: 'CustomElement',
  mixins: [emitter],
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    groupId: String,
    currentConversation: Object,
    isInner: Boolean
  },
  components: {
    MessageBubble,
    ShareMessage
  },
  computed: {
    text() {
      return this.translateCustomMessage(this.payload)
    },
    rate() {
      return parseInt(this.payload.description)
    },
    customClass() {
      let payload = this.payload;
      if(!payload.data && payload.ext && payload.ext.type !== 'atMessage') {
        return 'custom-message-bubble'
      }
      if(!payload.data && payload.ext && payload.ext.type === 'atMessage' && payload.ext.data.isAtMe) {
        return 'at-message-content'
      }
      return ""
    },
    isDisableLink() {
        return location.pathname.includes('Employee/') || location.pathname.includes('Operation/');
    },
  },
  methods: {
    translateCustomMessage(payload) {
      let videoPayload = {}
      try{
        videoPayload = JSON.parse(payload.data)
      } catch(e) {
        videoPayload = {}
      }
      if (payload.data === 'group_create') {
        return `${payload.ext}`
      }
      switch (videoPayload.action) {
        case ACTION.VIDEO_CALL_ACTION_DIALING:
          return '[请求通话]'
        case ACTION.VIDEO_CALL_ACTION_SPONSOR_CANCEL:
          return '[取消通话]'
        case ACTION.VIDEO_CALL_ACTION_REJECT:
          return '[拒绝通话]'
        case ACTION.VIDEO_CALL_ACTION_SPONSOR_TIMEOUT:
          return '[无应答]'
        case ACTION.VIDEO_CALL_ACTION_ACCEPTED:
          return '[开始通话]'
        case ACTION.VIDEO_CALL_ACTION_HANGUP:
          return `[结束通话，通话时长：${formatDuration(videoPayload.duration)}]`
        case ACTION.VIDEO_CALL_ACTION_LINE_BUSY:
          return '[正在通话中]'
        case ACTION.VIDEO_CALL_ACTION_ERROR:
          return '[设备异常]'
        default:
          return '[自定义消息]'
      }
    },
    translateAtMessage(text) {
      //at消息里面表情文字转换为表情图片展示
      let emojiCheckText = text,
          left = -1,
          right = -1,
          imgHtml = "",
          textleft = 0;
      while (emojiCheckText !== '') {
        left = emojiCheckText.indexOf('[')
        right = emojiCheckText.indexOf(']')
        switch (left) {
          case 0:
            if (right === -1) {
              emojiCheckText = '';
            } else {
              let _emoji = emojiCheckText.slice(0, right + 1);
              if (emojiMap[_emoji]) {
                imgHtml = `<img src="${emojiUrl + emojiMap[_emoji]}" width="20px" height="20px"/>`;
                text = text.substring(0, textleft) + imgHtml + text.substring(textleft + right + 1);
                textleft += imgHtml.length;
                emojiCheckText = text.substring(textleft);
                imgHtml = "";
              } else {
                textleft += 1;
                emojiCheckText = emojiCheckText.slice(1)
              }
            }
            break
          case -1:
            emojiCheckText = ''
            break
          default:
            textleft += left;
            emojiCheckText = emojiCheckText.substring(left);
            break
        }
      }
      //@用户 过长 换行展示
      let res = "",
          userName = "",
          userNameReg = "";
      if(document.querySelector('input[name="userRealName"]')) {
        userName = document.querySelector('input[name="userRealName"]').value + '@' + document.querySelector('input[name="userNickName"]').value;
      } else {
        userName = this.$store.state.user.userInfo.fullName;
      }
      userNameReg = new RegExp('@' + userName, "g");
      res = text.replace(userNameReg, `<span class="at-message-name">@${userName}</span>`).replace(/\@所有人/g, '<span class="at-message-name">@所有人\</span>').replace(/((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?(\s|$)/, function() {
        return `<a class="text-message-link" href="${arguments[0]}" target="_blank">${arguments[0]}</a>`;
      });
      let divBox = document.createElement('div');
      divBox.style.width = '249px';
      divBox.style.inlineHeight = '18px';
      divBox.style.visibility = 'hidden';
      divBox.style.position = 'absolute';
      divBox.innerHTML = res;
      document.body.appendChild(divBox);
      if(divBox.querySelector('.at-message-name') && divBox.querySelector('.at-message-name').offsetHeight > 20) {
        res = res.replace('<span class="at-message-name">', `<br/><span class="at-message-name">`);
      }
      document.body.removeChild(divBox);
      res = res.replace(/\n/g, '<br/>');
      return res;
    },
    showAuditDenyDetail(url) {
      let index = url.indexOf('-');
      let id = url.slice(index + 1, url.length)
      console.log(id);
      this.dispatch('IMBaseApp', 'show-audit-deny-detail-dialog', id);
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}
.survey {
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.suggestion {
  padding-top: 10px;
  font-size: 14px;
}
.at-message {
  white-space: normal !important;
}
.common-template-message {
  display: inline-block;
  padding: 20px 10px;
  width: 286px;

  &-title {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    font-style: normal;
  }

  &-content {
    max-height: 105px;
    display: -webkit-box;
    font-size: 14px;
    color: #666;
    font-style: normal;
    line-height: 1.5;
    text-align: justify;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  &-link {
    float: right;
    margin-top: 20px;
    color: $primary;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<style lang="scss">
.custom-message-bubble.message-content {
  background-color: #fff;
  padding: 0;
}
.at-message-content.message-content {
  animation: at-filker 2s linear;
}
@keyframes at-filker {
  0% {
    background-color: #38bc9d;
    color: #fff;
  }
  25% {
    background-color: #f6f6f6;
    color: #333;
  }
  50% {
    background-color: #38bc9d;
    color: #fff;
  }
  75% {
    background-color: #f6f6f6;
    color: #333;
  }
  100% {
    background-color: #38bc9d;
    color: #fff;
  }
}
.custom-element-wrapper {
  .at-message-name {
    padding: 0 4px;
    border-radius: 9px;
    color: $primary;
  }
}
</style>
