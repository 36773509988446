var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "share-message" },
    [
      _vm.message.msgContent.ext.type === "jobShare"
        ? [
            _c(
              "div",
              {
                staticClass: "job-share-card",
                on: {
                  click: function ($event) {
                    return _vm.goToLink(`/#/Job/${_vm.shareData.jobId}`)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "share-card-title",
                    attrs: {
                      title: `${_vm.shareData.firmShortName}-${_vm.shareData.jobName}`,
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.shareData.firmShortName) +
                        "-" +
                        _vm._s(_vm.shareData.jobName) +
                        "\n          "
                    ),
                  ]
                ),
                _c("div", { staticClass: "share-card-info" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.shareData.location) +
                      " | " +
                      _vm._s(_vm.shareData.yearOfExperience) +
                      " | " +
                      _vm._s(_vm.shareData.degree) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "share-card-info" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.shareData.salary) +
                      " | 佣金 " +
                      _vm._s(_vm.shareData.commissionValue) +
                      "\n          "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "share-card-footer" },
                  [
                    _c("span", { staticClass: "job-publish-info" }, [
                      _c("span", { staticClass: "job-publish-avatar" }, [
                        _c("img", { attrs: { src: _vm.jobCreatorAvatar } }),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "job-publish-name",
                          attrs: { title: _vm.shareData.creatorName },
                        },
                        [_vm._v(_vm._s(_vm.shareData.creatorName))]
                      ),
                      _vm._v(" 发布\n              "),
                    ]),
                    _vm.message.status === "unSend"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "share-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleShare.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                  发给对方\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "candidate-share-card",
                on: {
                  click: function ($event) {
                    return _vm.goToLink(
                      `/#/candidateDetail/${_vm.shareData.resumeId}`
                    )
                  },
                },
              },
              [
                _c("div", { staticClass: "share-card-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.candidateName) +
                      "-" +
                      _vm._s(_vm.shareData.title) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "share-card-info" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.shareData.company) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "share-card-info" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.shareData.cityName) +
                      " | " +
                      _vm._s(_vm.shareData.yearOfExperience) +
                      "年 | " +
                      _vm._s(_vm._f("degreeMap")(_vm.shareData.degree)) +
                      " | " +
                      _vm._s(_vm.shareData.school) +
                      "\n          "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "share-card-footer" },
                  [
                    _vm.message.status === "unSend"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "share-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleShare.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                  发给对方\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }