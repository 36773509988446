var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.messageIconClass,
      staticStyle: { width: "16px", height: "16px" },
      on: { click: _vm.handleIconClick },
    },
    [_vm._v(_vm._s(_vm.messageIconClass === "message-send-fail" ? "!" : ""))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }