<template>
    <div class="summary-entry-container">
        <!-- <float-option
            ref="floatOption"
            @showSystemRecommend="showSystemRecommend"
            :systemRecommendIsShow="systemRecommendIsShow"
            :isNewProject="isNewProject"
            :user="user"
        ></float-option> -->

        <!-- <chat-robot ref="chatRobot"></chat-robot> -->
        <instant-message
            ref="instantMessage"
            :isNewProject="isNewProject"
        ></instant-message>

        <!--landingPage 弹窗-->
        <landingDialog ref="landingDialog"></landingDialog>
        <!--landingPage 弹窗END-->

        <!--智能推荐弹窗-->
        <systemRecommendDialog ref="systemRecommendDialog" @close="closeSystemRecommend"></systemRecommendDialog>
        <!-- 年度报告 -->
        <!-- <template v-if="canShowAnnualReport">
            <annual-report
                :isShowAnnualReport="isShowAnnualReport"
                @showAnnualReport="showAnnualReport"
            ></annual-report>
            <div
                class="annual-report-btn"
                @click="showAnnualReport(true)"
                v-show="!isShowAnnualReport"
            ></div>
        </template> -->
        <!-- <cf-report-swiper v-if="isCfReportSwiperShow"></cf-report-swiper> -->
        <notice-dialog ref="noticeDialog" @showNoticeList="showNoticeList"></notice-dialog>
        <!-- <standstill-job-dialog ref="standstillJobDialog" @standstillJobUpdate="standstillJobUpdate"></standstill-job-dialog> -->
        <!-- <image-dialog
            width="770px"
            height="435px"
            :imageSrc="require('../../assets/images/job_share_guide.png')"
            imageAlt="职位分享功能上线"
            v-if="isImageShow"
        ></image-dialog> -->
        <point-rank-dialog ref="pointRankDialog" v-if="isCfUser && !isTsdnuser"></point-rank-dialog>

        <edit-department-dialog ref="departmentDialog"></edit-department-dialog>

        <!-- 再次操作确认进入面试列表相关代码 -->
        <el-dialog
            ref="confirmListDialog"
            custom-class="confirm-list-dialog"
            width="1200px"
            :show-close="false"
            :visible.sync="isShowConfListDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            v-if="isCfUser">
            <template v-if="!hasConfirmed">
                <div class="confirm-list-title">
                    候选人进入面试确认列表
                </div>
                <div class="confirm-list-tip">
                    由于面试双向确认的新功能于2021/2/5上线，为了将2021/1/1至2021/2/5的数据补充完整，TS和PM环节双向确认面试才可记为有效面试，请大家在2021/3/1零点前将“候选人进入面试确认列表”中的候选人处理完毕，以免影响QBB发放。该功能将于2021/3/1零点下线，过时数据将无法再追回。
                </div>
                <el-table
                    ref="confirmListTable"
                    class="confirm-list-table"
                    :data="confirmList"
                    max-height="284"
                    stripe
                    tooltip-effect="light"
                    v-loading="confirmListLoading"
                    @selection-change="confListSelectionChange">
                    <el-table-column
                        type="selection"
                        width="28"
                    ></el-table-column>
                    <el-table-column
                        prop="updated"
                        label="时间"
                        width="150">
                        <template slot-scope="scope">
                            <span class="update-date-cell">
                                {{scope.row.updateDate | dateFilter}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="candidateName"
                        label="候选人姓名"
                        width="90"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <!-- <a
                                class="candidate-name-link"
                                :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.candidateId}`"
                                target="_blank">
                                {{scope.row.candidateName}}
                            </a> -->
                            <a
                                class="candidate-name-link"
                                :href="`/#/candidateDetail/${scope.row.candidateId}`"
                                target="_blank">
                                {{scope.row.candidateName}}
                            </a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="jobName"
                        label="推荐职位"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.jobName}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="customerName"
                        label="推荐公司"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.customerName}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="最新状态"
                        width="110">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.status | recommendText}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommenderName"
                        label="推荐顾问"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.recommenderName}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="publisherName"
                        label="职位发布顾问"
                        width="120"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.jobOwnerName}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommendDate"
                        label="推荐时间"
                        width="150">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.recommendDate | dateFilter}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="interviewDate"
                        label="进入面试时间"
                        width="150">
                        <template slot-scope="scope">
                            <span>
                                {{scope.row.interviewDate | dateFilter}}
                            </span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div>
                            <span class="empty-img"></span>
                            <p class="empty-data">暂无数据</p>
                        </div>
                    </template>
                </el-table>
                <div class="work-table-footer">
                    <div class="footer-left">
                        <input hidden v-model="confListAllSelect" type="checkbox">
                        <span class="all-select-checkbox" @click="handleConfListAllSelect"></span>
                        <span>全选</span>
                        <span class="select-count">已选择
                            <span class="color-orange">{{confListMultipleCount}}</span>
                            条记录
                        </span>
                    </div>
                    <div class="footer-right">
                        <el-button
                            class="normal-btn confirm-into-btn"
                            :disabled="confListMultipleCount == 0"
                            @click="handleConfirmInto(true)">
                            确认进入过面试
                        </el-button>
                        <el-button
                            class="normal-btn"
                            :disabled="confListMultipleCount == 0"
                            @click="handleConfirmInto(false)">
                            未进入过面试
                        </el-button>
                        <el-button
                            class="normal-btn"
                            @click="hideConfListDialog">
                            退出
                        </el-button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="confirm-done">
                    <i class="confirm-done-icon"></i>
                    <p>已确认所选候选人{{hasConfirmedType == 0 ? '进入面试' : '未进入面试'}}</p>
                </div>
                <div class="confirm-done-footer">
                    <el-button
                        class="normal-btn"
                        @click="hideConfListDialog">
                        退出
                    </el-button>
                    <el-button
                        class="normal-btn confirm-into-btn"
                        @click="continueConfirm">
                        继续确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <!-- 再次操作确认进入面试列表相关代码 end -->

        <!-- 周年庆 -->
        <anniversary-dialog
            ref="anniversaryDialog"
            v-if="isCfUser"
         />
         
        <!-- <div class="ali-resume-dialog-btn" :class="isNewProject ? 'ali-resume-dialog-btn-new' : ''" v-if="isCfUser && canShowAliResumeBtn" @click="showAliResumeDialog">敏感简历</div> -->

        <!-- 工作日报弹窗 -->
        <summary-dialog
            ref="summaryDialog"
            :close-summary-dialog="closeDialog"
        ></summary-dialog>

        <!-- 实时数据弹窗 -->
        <realtimedata-dialog
            ref="realtimedataDialog"
            :close-realtimedata-dialog="closeRealtimedataDialog"
        ></realtimedata-dialog>

        <transition name="fade">
            <div v-if="showAppDesc" class="helpful-app-desc">
                <h3>
                    全部应用
                    <i class="el-icon-close" @click="handleHideAppDesc"></i>
                </h3>
                <ul>
                    <li>
                        <img :src="require('@src/assets/images/floatOption/logo_xls.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">简历小猎手</p>
                            <p class="desc">帮助你自动保存招聘网站简历、自动判重匹配人才库简历，快速更新旧简历的浏览器插件。<br/>当前支持领英、脉脉、猎聘、拉勾、智联、智联卓聘、前程无忧等主流招聘网站。</p>
                            <a href="https://xls.bazhua.me/download" target="_blank"><span @click="downloadResumeHunter">下载&raquo;</span></a>
                        </div>
                    </li>
                    <!-- <li v-if="isShowPhoneAssistant">
                        <img :src="require('@src/assets/images/floatOption/logo_phone.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">电话助手</p>
                            <p class="desc">集一键拨打电话、智能识别简历自动弹屏、添加寻访、报表分析、录音回听等功能于一身的电话拨打系统，接入设备登录后即可使用。</p>
                            <a href="http://dl.rchezi.com/PhoneAssistant/PhoneAssistant_Setup.exe">
                                <span @click="downloadPhoneAssistant">下载&raquo;</span>
                            </a>
                            <a href="/Headhunting/MyCompany.html#/phoneAssistant" target="_blank">使用说明&raquo;</a>
                        </div>
                    </li> -->
                    <li>
                        <img :src="require('@src/assets/images/floatOption/logo_lbd.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">猎必得APP</p>
                            <p class="desc">
                                1.APP也可在企业库快速找人，收藏简历、编辑简历、拨打电话、添加寻访、添加事项提醒；<br/>
                                2.实时查看我推荐的候选人的流程状态，快速处理推荐给我的候选人的流程状态；<br/>
                                3.可通过Trackinglist轻松跟进人选。<br/>
                                4.智能拨号：支持在线拨打免费网络电话
                            </p>
                            <a href="javascript:void(0)" @click="handleToggleAndroidAppQrcode">安卓下载&raquo;</a>
                            <a class="ios-btn" href="javascript:void(0)" @click="handleToggleIOSAppQrcode">苹果下载&raquo;</a>
                            <!-- <a class="ios-btn" href="javascript:void(0)">苹果下载<i>（即将开放）</i></a> -->
                            <div class="qrcode-box">
                                <img class="qrcode android-qrcode" v-show="showAndroidAppQrcode" :src="require('@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png')" />
                                <img class="qrcode ios-qrcode" v-show="showIOSAppQrcode" :src="require('@src/assets/images/floatOption/qrcode_dl_ios_app.png')" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>

    </div>
</template>

<script>
    import FloatOption from '#/component/page/float-option/float-option.vue'
    // import ChatRobot from '#/component/chatRobot/chatRobot.vue';
    import landingDialog from '#/component/summary/landing-dialog.vue'
    import systemRecommendDialog from './systemRecommend-dialog.vue';
    import moment from 'moment'
    import LBDRequest from '@src/js/util/request.js';
    import shortTips from "@src/js/plugin/jquery.setting";
    // import CfReportSwiper from '#/component/activity/cfReportSwiper.vue';
    // import AnnualReport from '#/component/common/dialog/annualReport.vue';
    import NoticeDialog from '#/component/notice/notice-dialog.vue';
    // import StandstillJobDialog from '#/component/common/dialog/standstill-job-dialog.vue';
    import JobService from '#/js/service/jobService.js';
    import InstantMessage from '#/component/page/open-instant-message/index.vue';
    // import ImageDialog from '#/component/activity/imageDialog.vue'
    import PointRankDialog from '#/component/common/dialog/point-rank-dialog.vue';
    import AnniversaryDialog from '#/component/common/dialog/anniversary-dialog.vue';
    import EditDepartmentDialog from '#/views/customers/component/edit-department-dialog.vue';
    import { recommendHashMap } from '#/js/config/recommend-detail.json';
    import { interviewConfirm as interviewConfirmUrl } from '#/js/config/api.json';
    import summaryDialog from '#/component/page/float-option/summary-dialog.vue';
    import RealtimedataDialog from '#/component/page/float-option/realtimedata-dialog.vue';

    if(!window._request) {
        window._request = new LBDRequest({
            mockBaseUrl: 'http://192.168.1.72:3000/mock/20',    //可选
            errMsgHandler: shortTips,                    //必选
            commonHeaders: {                                    //可选
            },
            dynamicCommonHeaders: () => {                       //可选
                return {
                    "UPASession": JSON.stringify({
                        "PageViewId" : window._pageViewId || '',
                        "PageCode" : window._pageCode || ''
                    })
                }
            }
        })._request;
    }

    const JOB_TYPE = {
        0: '开放猎场',
        1: '定向委托',
        2: '平安职位'
    }

    if(!window.messageQueue){
        window.messageQueue = [];
    }

    // function show_pingan_message (data){
    //     let date = moment(new Date());
    //     if(_global_summary){
    //         if(window._pingan_notify && !window._pingan_notify.closed){
    //             window._pingan_notify.close();
    //         }
    //         window._pingan_notify = _global_summary.$notify({
    //             title: '提示',
    //             message: createMessageHtml(data),
    //             dangerouslyUseHTMLString: true,
    //             duration: 0,
    //             showClose: false,
    //         })

    //         _global_summary.$nextTick(() => {
    //             $('.el-notification .btn-ignore').click(function () {
    //                 _global_summary.ignore();
    //                 window._pingan_notify.close();
    //                 if(data.jobsBatchCreatedType === 1){
    //                     localStorage.setItem(`${date.format('YYYY-MM-DD')}_pingan_auto_remind`,'true');
    //                 }
    //             })

    //             $('.el-notification .btn-goView').click(function () {
    //                 _global_summary.toView();
    //                 window._pingan_notify.close();
    //                 if(data.jobsBatchCreatedType === 1){
    //                     localStorage.setItem(`${date.format('YYYY-MM-DD')}_pingan_auto_remind`,'true');
    //                 }
    //             })
    //         })
    //     }
    // }

    // function createMessageHtml (data){
    //     let html = data.jobsBatchCreatedType === 1 ? `${data.distributor}已为你自动创建<span class="color-orange">${data.importCount}</span>个${JOB_TYPE[data.jobsBatchCreatedType]}的职位` : `${data.distributor}给你分配了<span class="color-orange">${data.importCount}</span>个${JOB_TYPE[data.jobsBatchCreatedType]}`;
    //     html = `${html}<div class="btn-group"><span class="btn-ignore">忽略</span><span class="btn-goView color-primary">去看看</span></div>`
    //     return html;
    // }

    // setInterval(function (){
    //     let date = moment(new Date());
    //     if(messageQueue && messageQueue.length > 0) {
    //         setTimeout(function () {
    //             if((messageQueue[0].jobsBatchCreatedType === 1 && !localStorage.getItem(`${date.format('YYYY-MM-DD')}_pingan_auto_remind`)) ||
    //                 messageQueue[0].jobsBatchCreatedType === 2) {
    //                 //系统自动创建职位且还未展示或开放猎场职位的消息才展示
    //                 show_pingan_message(messageQueue.shift(1));
    //             } else {
    //                 messageQueue.shift(1);
    //             }
    //         },300);
    //     } else {}
    // }, 2000)

    export default {
        name: "summaryEntry",
        components: {
            FloatOption,
            landingDialog,
            // ChatRobot,
            systemRecommendDialog,
            // CfReportSwiper,
            // AnnualReport,
            NoticeDialog,
            // StandstillJobDialog,
            InstantMessage,
            // ImageDialog,
            PointRankDialog,
            EditDepartmentDialog,
            AnniversaryDialog,
            summaryDialog,
            RealtimedataDialog,
        },
        props:{
            user: Object,
            isHrOrPm: {
                type: Boolean,
                default: false
            },
            hasIm: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                systemRecommendIsShow: 1,
                // isShowAnnualReport: true,
                isNewProject: false,
                // 再次操作确认进入面试列表相关代码
                isShowConfListDialog: false,
                hasConfirmed: false,
                hasConfirmedType: 0,
                confirmList: [],
                confirmListLoading: false,
                confListMultipleSelection: [],
                // 再次操作确认进入面试列表相关代码 --end
                showAppDesc: false,
                showAndroidAppQrcode: false,
                showIOSAppQrcode: false,
                forceExpand: false,
            }
        },
        computed: {
            isCfUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            isTsdnuser(){
                return this.$store.state.user.userInfo.tsdnuser;
            }, 
            isAdministrator(){
                return this.$store.state.user.userInfo.isAdministrator;
            },
            isBusinessPermission() {
                return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Talent') || (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Job');
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },

            // 再次操作确认进入面试列表相关代码
            confListMultipleCount() {
                return this.confListMultipleSelection.length;
            },
            confListAllSelect() {
                return this.confirmList.length > 0 ? this.confirmList.length == this.confListMultipleSelection.length : false;
            },
            // 再次操作确认进入面试列表相关代码 --end

            // canShowAliResumeBtn() {
            //     let startStr = location.host == 'www.liebide.com' ? '2020-08-10' : '2020-08-08';
            //     console.log(startStr);
            //     let curTime = + new Date,
            //         startTime = + new Date(startStr),
            //         endTime = + new Date('2020-08-12 20:00:00');
            //     return curTime >= startTime && curTime < endTime;
            // }
            // canShowAnnualReport() {
            //     let today = new Date().getTime();
            //     return today >= new Date('2020/01/01 00:00:00').getTime() && today < new Date('2020/01/06 00:00:00').getTime() && this.$store.state.user.userInfo.isCFUser;
            // }
            // isCfReportSwiperShow() {
            //     if(localStorage.getItem('cfReportSwiperViewed')) {
            //         return false;
            //     }
            //     let today = new Date().getTime();
            //     let result =  today >= new Date('2020/01/17 00:00:00').getTime() && today < new Date('2020/01/20 00:00:00').getTime() && this.$store.state.user.userInfo.isCFUser;
            //     if(result) {
            //         localStorage.setItem('cfReportSwiperViewed', true);
            //     }
            //     return result;
            // },
            // isImageShow() {
            //     if(location.href.indexOf('/Headhunting/#/myOrders/grabbedJob') > -1 || location.href.indexOf('/Headhunting/#/myOrders/createdJob') > -1) {
            //         let today = new Date().getTime();
            //         if(today >= new Date('2020/06/05 00:00:00').getTime() && today < new Date('2020/06/13 00:00:00').getTime()) {
            //             if(!localStorage.getItem('jobShareGuideShowDate') || moment(localStorage.getItem('jobShareGuideShowDate')).dates() < new Date().getDate()) {
            //                 localStorage.setItem('jobShareGuideShowDate', moment().format('YYYYMMDD'));
            //                 this.$nextTick(() => {
            //                     if(document.querySelector('#floatOption')){
            //                         document.querySelector('#floatOption').style.zIndex = "2000";
            //                     }
            //                 })
            //                 return true;
            //             } else {
            //                 return false;
            //             }
            //         } else {
            //             return false;
            //         }
            //     } else {
            //         return false;
            //     }
            // },
        },
        filters: {
            dateFilter(val) {
                return val ? moment(val).format('YYYY-MM-DD HH:mm') : '-';
            },
            recommendText(status) {
                return recommendHashMap[status].text;
            }
        },
        created() {
            // if(localStorage.getItem('isFirstShowAnnualReport')) {
            //     this.isShowAnnualReport = false;
            // } else {
            //     localStorage.setItem('isFirstShowAnnualReport', false);
            // }
            if(window._isNewProject){
                this.isNewProject = true;
            }
        },
        mounted(){
            window._global_summary = this;
            // if(this.user && this.user.isCFUser) {
            //     this.getPingAnRemind();
            // }

            if (this.isHrOrPm) {
                return false;
            }

            let nowMinutes = new Date().getMinutes();
            if(nowMinutes % 15 == 0) {
                this.setNoticeInterval();
            } else {
                let timeout = 0, recentTime = new Date(),
                    minutes = Math.ceil(nowMinutes / 15) * 15;
                recentTime.setMinutes(minutes);
                recentTime.setSeconds(0);
                timeout = recentTime.getTime() - new Date().getTime();
                this.setNoticeTimeout(timeout);
            }
            if(new Date().getTime() >= new Date('2020/05/06 00:00:00').getTime()) {
                if(this.isCfUser && (!localStorage.getItem('pointRankListDate') || localStorage.getItem('pointRankListDate') !== moment().day(1).format('YYYYMMDD'))) {
                    this.$refs.pointRankDialog && this.$refs.pointRankDialog.show(0);
                }
            }
            if(location.href.indexOf('/jobList') === -1 
                && location.href.indexOf('/firmSearch') === -1
                && location.href.indexOf('/firmCreate') === -1
                && location.href.indexOf('/firmJoining') === -1
                && location.href.indexOf('/firmQuestionnaire') === -1
            ) {
                this.getStandstillJobNum();
                // this.getUnbindDeptJobList();
                // if(this.isAdministrator || this.isBusinessPermission){
                if(this.isBusinessPermission){
                    this.getUnbindDeptJobList();
                }
            }
            
            // 再次操作确认进入面试列表相关代码
            if(this.isCfUser) {
                let today = new Date(new Date().toLocaleDateString()).getTime();
                if(today < new Date('2021/03/01 00:00:00').getTime()) {
                    if(!localStorage.getItem('confirmListDialog') || new Date(localStorage.getItem('confirmListDialog')).getTime() < today) {
                        this.getConfirmList();
                        localStorage.setItem('confirmListDialog', moment().format('YYYY/MM/DD'));
                    }
                }
            }
            // 再次操作确认进入面试列表相关代码 --end
            this.checkAnniversary();
        },
        methods: {
            // 判断是否进入周年庆
            checkAnniversary() {
                const joinFirmTime = this.userInfo.joinFirmTime;
                // const joinFirmTime = '2020-12-13';
                const _diff = moment().diff(moment(joinFirmTime), 'years'),
                    _start = moment(joinFirmTime).add(_diff, 'years'),
                    _end = moment(_start).add(7, 'days');
                if(_diff < 1) return;

                const isBetween = moment().isBetween(_start, _end);
                const _showYear = localStorage.getItem('anniversaryShowed');
                const _yearMatched = _showYear ? _showYear != moment().format('YYYY') : true;
                
                if(isBetween && _yearMatched) {
                    this.$refs.anniversaryDialog.show();
                }
            },

            closeSystemRecommend(){
                this.systemRecommendIsShow = 3;
            },
            showSystemRecommend (){
                this.systemRecommendIsShow = 2;
                this.$refs.systemRecommendDialog.show()
            },
            floatHidden (){
                this.systemRecommendIsShow = 1;
            },
            // 平安定向职位提醒
            // getPingAnRemind(){
            //     let date = moment();

            //     if(localStorage.getItem(`${date.format('YYYY-MM-DD')}_pingan_auto_remind`)){
            //         return false;
            //     }

            //     if(date.hour() <= 9){
            //         return false;
            //     }

            //     $.get('/Headhunting/Job/GetExternalJobMessage',{
            //         type: 1
            //     },function (res) {
            //         try{
            //             localStorage.removeItem(`${moment().subtract(1, 'days').format('YYYY-MM-DD')}_pingan_auto_remind`);
            //             if(res && res.succeeded && res.data.importCount > 0){
            //                 if(window.messageQueue){
            //                     messageQueue.push(res.data);
            //                 } else {
            //                     window.messageQueue = [];
            //                     messageQueue.push(res.data);
            //                 }
            //             } else {
            //                 // TODO
            //             }
            //         } catch (e){
            //             console.log(e);
            //             //捕捉不支持localStorage的浏览器产生的报错
            //         }
            //     }).then(res => {

            //     });
            // },
            // ignore () {
            //     if(window.lbdTrack){
            //         lbdTrack.track('message_btn_ignore',{
            //             from: 'pingan_auto_message'
            //         })
            //     } else {
            //         _tracker.track('message_btn_toView',JSON.stringify({
            //             from: 'pingan_auto_message'
            //         }))
            //     }
            // },
            // toView (){
            //     window.open('/Headhunting/#/myOrders/createdJob');
            //     if(window.lbdTrack){
            //         lbdTrack.track('message_btn_toView',{
            //             from: 'pingan_auto_message'
            //         })
            //     }else{
            //         _tracker.track('message_btn_toView',JSON.stringify({
            //             from: 'pingan_auto_message'
            //         }))
            //     }
            // },
            // getAppointedCustomers(){
            //     let _this = this;
            //     if(window.appointedCustomersId) {
            //         _this.appointedCustomersId = window.appointedCustomersId;
            //         return true;
            //     }
            //     $.get('/Firm/GetAppointedCustomers').then(res =>{
            //         if(res.succeeded || res.Succeeded) {
            //             _this.appointedCustomersId = res.data[0].defaultCustomerId;
            //             window.appointedCustomersId = res.data[0].defaultCustomerId;
            //         }else{
            //             if(res.errorCode == 401 || res.ErrorCode == 401) {
            //                 location.href = '/Login';
            //             }
            //         }
            //     }).fail(err => {
            //     })
            // },
            // showAnnualReport(result) {
            //     this.isShowAnnualReport = result;
            // },
            setNoticeTimeout(timeout) {
                let noticeTimeout = setTimeout(() => {
                    this.getNoticeDialog();
                    this.setNoticeInterval();
                    clearTimeout(noticeTimeout);
                }, timeout);
            },
            setNoticeInterval() {
                setInterval(() => {
                    this.getNoticeDialog();
                }, 900000);
            },
            getNoticeDialog() {
                let nowTime = new Date(), year, month, nowMinutes;
                year = nowTime.getFullYear();
                month = nowTime.getMonth() + 1;
                nowMinutes = Math.ceil(nowTime.getMinutes() / 15) * 15;
                nowTime.setMinutes(nowMinutes);
                // todo:这个会冒一个空白黑色泡泡
                _request({
                    method: 'GET',
                    url: `/Resume/Reminder/list?year=${year}&month=${month}`
                }).then(res => {
                    if(res.length > 0) {
                        let list = [];
                        res.forEach(item => {
                            if(moment(item.remindTime).format('YYYY-MM-DD HH:mm') == moment(nowTime).format('YYYY-MM-DD HH:mm') && item.status == 0) {
                                list.push(item);
                            }
                        });
                        if(list.length > 0) {
                            this.showNoticeDialog(list);
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            showNoticeDialog(list) {
                localStorage.removeItem('realTimeNotice');
                this.$refs.noticeDialog.show(list);
            },
            showNoticeList() {
                let noticePopover = document.querySelector('.notice-popover');
                if(this.isNewProject) {
                    // this.$parent.$refs.noticeWidget.popoverVisible = true;
                    if(localStorage.getItem('isFoldSiderbar') && JSON.parse(localStorage.getItem('isFoldSiderbar'))) {
                        document.querySelector('.notice-popover').style.left = '26px';
                    } else {
                        document.querySelector('.notice-popover').style.left = '82px';
                    }
                    let windowHeight = window.innerHeight;
                    if(windowHeight >= 584) {
                        document.querySelector('.notice-popover').style.top = '45px';
                    } else {
                        document.querySelector('.notice-popover').style.top = (windowHeight - 553) + 'px';
                    }
                    document.querySelector('.notice-popover').style.position = 'fixed';
                    document.querySelector('.notice-popover').style.display = '';
                    this.$parent.$refs.noticeWidget.showPopover();
                } else {
                    document.querySelector('.notice-popover').style.left = (window.innerWidth - 425) + 'px';
                    let windowHeight = window.innerHeight;
                    if(windowHeight >= 567) {
                        document.querySelector('.notice-popover').style.top = '25px';
                    } else if(windowHeight >= 542) {
                        document.querySelector('.notice-popover').style.top = (windowHeight - 542)/2 + 'px';
                    } else {
                        document.querySelector('.notice-popover').style.top = (windowHeight - 542) + 'px';
                    }
                    document.querySelector('.notice-popover').style.position = 'fixed';
                    document.querySelector('.notice-popover').style.display = '';
                    document.querySelector('.site-header').style.zIndex = '1000';
                    $(".square-notice-widget").addClass("square-notice-widget-hover");
                    $("#integralBall").removeClass("default-hover");
                    this.$refs.floatOption.$refs.noticeWidget.showPopover();
                }
            },
            getStandstillJobNum() {
                JobService.getStandStillJob({
                    includeDetail: false
                }).then(res => {
                    if(document.querySelector('form.lbd-server-variable')) {
                        let standstillJobInput = document.createElement('input');
                        standstillJobInput.name = 'standstillJobNum';
                        standstillJobInput.value = res.total;
                        standstillJobInput.type = 'hidden';
                        document.querySelector('form.lbd-server-variable').appendChild(standstillJobInput);
                    } else {
                        this.$store.dispatch('setStandstillJobNum', res.total);
                    }
                }).catch(err => {
                    if(typeof err === 'object') {
                        console.log(err);
                        shortTips(err.message || err.errorMessage || '系统异常，请稍后重试');
                    }
                });
            },
            getUnbindDeptJobList() {
                JobService.getUnbindDeptJobList({
                    customerId: '',
                    orderBy: 0
                }).then(res => {
                    if(document.querySelector('form.lbd-server-variable')) {
                        let unbindDeptJobInput = document.createElement('input');
                        unbindDeptJobInput.name = 'unbindDeptJobNum';
                        unbindDeptJobInput.value = res.total;
                        unbindDeptJobInput.type = 'hidden';
                        document.querySelector('form.lbd-server-variable').appendChild(unbindDeptJobInput);
                    } else {
                        if(res.data && res.data.length > 0) {
                            this.$store.dispatch('setUnbindDeptJobNum', res.data.length);
                        }else {
                            this.$store.dispatch('setUnbindDeptJobNum', 0);
                        }
                    }
                })
            },
            // showStandstillJobDialog() {
            //     let dialogId = 'StandstillJobDialogAutoShow-' + new Date().getTime();
            //     this.$refs.standstillJobDialog.show(dialogId);
            // },
            // standstillJobUpdate(number) {
            //     this.$store.dispatch('setStandstillJobNum', number);
            // },
            quitIM() {
                this.$refs.instantMessage.logout();
            },
            // showAliResumeDialog() {
            //     try {
            //         this.$refs.floatOption.$refs.aliResumeDialog.refresh();
            //     }catch(e) {}
            // },
            // 再次操作确认进入面试列表相关代码
            getConfirmList(isFromFloatingList) {
                if(!this.isShowConfListDialog && isFromFloatingList) {
                    this.isShowConfListDialog = true;
                }
                this.confirmListLoading = true;
                _request({
                    method: 'GET',
                    url: interviewConfirmUrl.get_interview_confirm_list,
                }).then(res => {
                    this.confirmListLoading = false;
                    this.confirmList = res;
                    if(this.confirmList.length <= 0) {
                        if(isFromFloatingList) {
                            shortTips('暂无待确认进入面试的记录')
                            this.hideConfListDialog();
                        }
                        return false;
                    }
                    if(!this.isShowConfListDialog && !isFromFloatingList) {
                        this.isShowConfListDialog = true;
                    }
                }).catch(err => {
                    this.confirmListLoading = false;
                })
            },
            confListSelectionChange(val) {
                this.confListMultipleSelection = val;
            },
            handleConfListAllSelect() {
                this.$refs.confirmListTable.toggleAllSelection();
            },
            handleConfirmInto(type) {
                if(this.confListMultipleSelection.length <= 0) {
                    shortTips("至少选择一条记录")
                    return false;
                }

                let logIds = [];
                this.confListMultipleSelection.forEach(item => {
                    logIds.push(item.id);
                })
                this.confirmListLoading = true;
                _request({
                    method: 'POST',
                    url: interviewConfirmUrl.confirm_interview,
                    data: {
                        logIds: logIds,
                        isPromoteInterview: type
                    }
                }).then(res => {
                    this.confirmListLoading = false;
                    this.hasConfirmed = true;
                    if(type) {
                        this.hasConfirmedType = 0;
                    }else {
                        this.hasConfirmedType = 1;
                    }
                    this.confListMultipleSelection = [];
                }).catch(err => {
                    this.confirmListLoading = false;
                })
            },
            continueConfirm() {
                this.getConfirmList();
                this.hasConfirmed = false;
            },
            hideConfListDialog() {
                this.hasConfirmed = false;
                this.isShowConfListDialog = false;
            },
            showWorkReport() {
                if(window.lbdTrack){
                    lbdTrack.track('clickWorkReport', {})
                } else {
                    _tracker.track('clickWorkReport');
                }
                let noMoreBtn = false;
                if (window.location.href.indexOf("workReport") > -1) {
                    noMoreBtn = true;
                }
                localStorage.setItem("hasClickSummary", true);
                this.hasHoverTip = false;
                this.isShowDialog = true;
                this.$refs.summaryDialog.showDialog({
                    isToday: true,
                    user: this.user,
                    noMoreBtn: noMoreBtn,
                    triggerType: 2
                });
            },

            showRealtimeData() {
                this.$refs.realtimedataDialog.showDialog();
            },
            closeDialog() {},
            closeRealtimedataDialog() {},
            handleHideAppDesc() {
                this.showAppDesc = false;
            },
            handleToggleAndroidAppQrcode() {
                this.showAndroidAppQrcode = !this.showAndroidAppQrcode;
            },
            handleToggleIOSAppQrcode() {
                this.showIOSAppQrcode = !this.showIOSAppQrcode;
            },
            handleCloseHelpfulGuide() {
                this.forceExpand = false;
                this.showAppDesc = true;
                localStorage.setItem('helpfulAppGuideViewed', true);
            },
            downloadResumeHunter() {
                if(window.lbdTrack){
                    lbdTrack.track('clickResumeHunter', {})
                } else {
                    _tracker.track('clickResumeHunter');
                }
                // window.open('https://xls.bazhua.me/download');
            },
            // 再次操作确认进入面试列表相关代码 --end
        },
    }
</script>

<style lang="scss">
    .summary-entry-container {
        position: absolute;
        width: 0;
        height: 0;
    }
    .el-notification{
        .el-notification__group{
            width: 100%;
        }

        .btn-group{
            text-align: right;
        }

        .btn-ignore,.btn-goView{
            cursor: pointer;
        }

        .btn-goView:hover {
            color: #36B495;
            text-decoration: underline;
        }

        .btn-ignore{
            color: #999999;
            margin-right: 20px;
            &:hover {
                color: #666;
            }
        }

        .color-orange{
            color: #F5A623;
        }

        .color-primary{
            color: #38bc9d;
        }
    }
    .annual-report-btn {
        position: fixed;
        right: 0;
        top: 63px;
        z-index: 1;
        width: 141px;
        height: 55px;
        background: url("~@src/assets/images/annualReportBtn.png") no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .ali-resume-dialog-btn {
        position: fixed;
        top: 160px;
        right: 0;
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        background-color: $primary;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        cursor: pointer;
        z-index: 10;
    }
    .ali-resume-dialog-btn-new {
        top: 50px;
    }
    // 再次操作确认进入面试列表相关代码
    .confirm-list-dialog.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 30px 50px;
        }
        .color-orange{
            color: #F5A623;
        }
        .confirm-list-title {
            text-align: center;
            color: #666;
            font-size: 28px;
            line-height: 48px;
        }
        .confirm-list-tip {
            color: #f5a623;
            font-size: 14px;
            line-height: 24px;
            padding: 16px 0 6px;
            .floating-list-link {
                color: #f5a623;
                &:hover {
                    color: $primary;
                }
            }
        }
        .confirm-list-table {
            width: 100%;
            border: 1px solid #d3d3d3;
            .el-loading-mask {
                z-index: 900;
            }
            th {
                padding: 0;
                height: 40px;
                line-height: 40px;
                color: #666666;
                border-right: none;
                background-color: #f8f8f8;
                .el-checkbox {
                    padding: 0 10px;
                }
                .cell {
                    padding: 0 10px;
                    .el-checkbox {
                        display: none;
                    }
                }
            }
            td {
                padding: 0;
                height: 40px;
                line-height: 40px;
                .cell {
                    white-space: nowrap;
                    cursor: default;
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;
                        &::after {
                            border-color: $primary;
                        }
                    }
                    .candidate-name-link {
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
            .el-table-column--selection .cell {
                padding-left: 10px;
                padding-right: 0;
            }
            .el-table__row {
                td {
                    border: none;
                }
                .el-checkbox__inner {
                    border-color: #999;
                }
            }
            .el-table__empty-block {
                margin: 20px 0;
                .el-table__empty-text {
                    line-height: inherit;
                }
                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 120px;
                    height: 114px;
                    background-size: contain;
                }
                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }
            .el-table-column--selection {
                border-right: none;
            }
        }
        .work-table-footer {
            overflow: hidden;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            .footer-left {
                height: 28px;
                line-height: 28px;
                letter-spacing: 0px;
                margin-left: 10px;
                .all-select-checkbox {
                    position: relative;
                    top: 2px;
                    display: inline-block;
                    margin-right: 6px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #999;
                    border-radius: 2px;
                    cursor: pointer;
                    background-color: #fff;
                }
                input:checked + .all-select-checkbox {
                    border-color: $primary;
                    &:after {
                        position: relative;
                        display: block;
                        content: '';
                        top: 2px;
                        left: 2px;
                        width: 10px;
                        height: 6px;
                        transform: rotate(-45deg);
                        border-left: 1px solid;
                        border-bottom: 1px solid;
                        border-color: $primary;
                    }
                }
                .select-count {
                    margin-left: 20px;
                }
            }
        }
        .normal-btn {
            height: 28px;
            min-width: 84px;
            padding: 0 12px;
            border-radius: 2px;
            line-height: 28px;
            color: #666;
            background-color: #fff;
            border-color: #ccc;

            span {
                line-height: 26px;
                vertical-align: top;
            }

            &:hover,
            &:focus {
                color: #fff;
                background-color: #48CAAC;
                border-color: #48CAAC;
            }
            &.confirm-into-btn {
                background-color: $primary;
                border-color: $primary;
                color: #fff;
                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #48CAAC;
                    border-color: #48CAAC;
                }
            }
            &.is-disabled,
            &.is-disabled:hover,
            &.is-disabled:focus {
                background: #F8F8F8;
                border: 1px solid #DDDDDD;
                color: #999;
            }
        }
        .confirm-done {
            width: 100%;
            height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .confirm-done-icon {
                width: 90px;
                height: 90px;
                margin-bottom: 38px;
                background: url("~@src/assets/images/icon/icon-done.svg") no-repeat center center;
            }
            p {
                color: #666;
                font-size: 28px;
                line-height: 28px;
            }
            &-footer {
                height: 28px;
                margin: 20px 0 12px;
                .normal-btn {
                    float: right;
                }
                .confirm-into-btn {
                    margin-right: 10px;
                }
            }
        }
    }


    .helpful-app-desc {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 493px;
        padding-top: 18px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
        border-right: 1px solid #eee;
        border-radius: 2px;
        z-index: 1000;

        > h3 {
            padding: 0 24px;
            line-height: 44px;

            > i {
                float: right;
                font-size: 14px;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                background-color: #f5f5f5;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        > ul {
            padding-left: 19px;

            li {
                padding: 20px 24px 15px 3px;
                border-bottom: 1px solid #eee;

                > img {
                    display: block;
                    float: left;
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                &:last-child {
                    border: 0 none;
                }
            }

            .helpful-app-desc-right {
                overflow: hidden;

                p.title {
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 3px;

                    i {
                        font-size: 12px;
                    }
                }

                p.desc {
                    font-size: 14px;
                    color: #666;
                    margin: 0;
                }
                
                a {
                    margin-top: 6px;
                    margin-right: 10px;
                    color: $primary;

                    &.ios-btn {
                        margin-left: 50px;

                        i{
                            font-size: 12px;
                            color: #666;
                        }
                    }
                }

                > img, .qrcode {
                    display: block;
                    width: 94px;
                    height: 94px;
                }
                .qrcode-box {
                    position: relative;
                    width: 100%;
                    height: 94px;
                }
                .qrcode {
                    position: absolute;
                    top: 0;
                    left: 0;

                    &.android-qrcode {
                        left: -4px;
                    }

                    &.ios-qrcode {
                        left: 120px;
                    }
                }
            }
        }
    }
    .fade-enter-active {
        transition: all .3s ease;
    }
    .fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-enter, .fade-leave-to {
        transform: translateX(493px);
        opacity: 0;
    }
    // 再次操作确认进入面试列表相关代码 --end
</style>
