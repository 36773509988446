<template>
    <div
        class="message-send-box-wrapper"
        :style="focus ? {'backgroundColor': 'white'} : {}"
        v-show="currentConversation.conversationID">
        <div class="message-operations">
            <el-popover
                placement="top"
                width="400"
                trigger="click"
                v-model="emojiPoppperShow"
                popper-class="emojis-popper">
                <div class="emojis">
                    <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
                        <img :src="emojiUrl + emojiMap[item]" style="width:30px;height:30px" />
                    </div>
                </div>
                <!-- <font-icon class="emoji-btn" href="#icon-xiaolian" slot="reference" title="发表情"></font-icon> -->
                <i class="emoji-btn" slot="reference" title="发表情"></i>
            </el-popover>
            <i class="el-icon-folder" title="发文件" @click="handleSendFileClick"></i>
        </div>
        <el-popover
            trigger="manual"
            v-model="isShowAtGroupMember"
            placement="top-start"
            popper-class="at-member-list-popover"
            :visible-arrow="false">
            <el-radio-group
                v-model="atUserID"
                class="at-member-list"
                @change="handleSelectAtUser">
                <el-radio
                    :class="member.nick === '所有人' ? 'at-all-checkbox' : ''"
                    :label="member.userID"
                    v-for="(member, index) in atMemberList"
                    :key="index"
                    @click.native.prevent="handleClickAtUser(member.userID)">
                    <img class="at-member-avatar" src="~@src/assets/images/instantMessage/at-all-user.png" v-if="member.nick === '所有人'"/>
                    <img class="at-member-avatar" :src="member.avatar" v-else-if="member.avatar"/>
                    <img class="at-member-avatar" src="~@src/assets/images/default-user.png" v-else/>
                    <span class="at-member-name">{{member.nick || member.userID}}</span>
                </el-radio>
            </el-radio-group>
            <textarea
                slot="reference"
                ref="messageInput"
                id="messageInput"
                rows="2"
                resize="false"
                :value="messageContent"
                class="text-input"
                placeholder="请输入"
                @focus="focus = true"
                @blur="focus = false"
                @input.stop="handleInput"
                @keydown.enter.exact.prevent="handleEnter"
                @keyup.ctrl.enter.prevent.exact="handleLine"
                @keydown.left.stop="handleLeft"
                @keydown.right.stop="handleRight"
                @click.exact.prevent="handleClickIndex"
                @keydown.up.stop="handleUp"
                @keydown.down.stop="handleDown"
                @keydown.delete.stop="handleDelete"
            ></textarea>
        </el-popover>
        <div class="bottom">
            <span class="message-send-tip">Ctrl+Enter换行</span>
            <el-tooltip
                class="item"
                effect="light"
                content="不能发送空白信息"
                placement="left-start"
                v-model="isEmptyMessageContent"
                :manual="true">
                <el-button
                    type="primary"
                    class="message-send-btn"
                    @click="createInputMessage">
                    发 送
                </el-button>
            </el-tooltip>
        </div>
        <input type="file" ref="filePicker" @change="createFileMessage" style="display: none" />
    </div>
</template>

<script>
import { emojiMap, emojiName, emojiUrl } from '#/js/util/emojiMap.js';
import imService from '#/js/service/openIMService.js';
export default {
    props: {
        currentConversation: Object,
        memberList: Array,
        toAccount: String,
        userId: String,
        isWorkBenchMain: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focus: false,
            cpLock: true,
            isShowAtGroupMember: false,
            atUserID: "",
            atUsers: [],
            messageContent: "",
            inputIndex: 0,
            isEmptyMessageContent: false,
            emojiPoppperShow: false,
            emojiMap: emojiMap,
            emojiName: emojiName,
            emojiUrl: emojiUrl,
            atMemberList: [],
            atInputIndex: -1,
            isSafari: false,
            isComposing: false
        }
    },
    mounted() {
        document.addEventListener('click', this.hideAtGroupMember);
        this.$refs.messageInput && this.$refs.messageInput.addEventListener('paste', this.handlePaste);
        this.$refs.messageInput && this.$refs.messageInput.addEventListener('compositionend', this.updateMessageContent);
        let ua = window.navigator.userAgent.toLowerCase();
        this.isSafari = ua.indexOf('chrome') == -1 && ua.indexOf('safari') > -1
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideAtGroupMember);
        this.$refs.messageInput && this.$refs.messageInput.removeEventListener('paste', this.handlePaste);
        this.$refs.messageInput && this.$refs.messageInput.removeEventListener('compositionend', this.updateMessageContent);
    },
    methods: {
        hideAtGroupMember(ev) {
            if(this.isShowAtGroupMember) {
                this.isShowAtGroupMember = false;
            }
        },
        handlePaste(e) {
            let clipboardData = e.clipboardData,
                file;
            if(clipboardData && clipboardData.files && clipboardData.files.length > 0) {
                file = clipboardData.files[0]
            }
            if (typeof file === 'undefined') {
                return
            }
            this.createImageMessage(file);
        },
        handleSelectAtUser() {
            if(this.messageContent.slice(this.atInputIndex, this.inputIndex) !== "") {
                let beforeAtContent = this.messageContent.substring(0, this.atInputIndex),
                    afterAtContent = this.messageContent.substring(this.inputIndex);
                this.messageContent = beforeAtContent + afterAtContent;
                this.inputIndex = this.atInputIndex;
            }
            let atUser = this.memberList.find(member => {
                return member.userID === this.atUserID
            })
            if(this.inputIndex < this.messageContent.length) {
                let beforeAtContent = this.messageContent.substring(0, this.inputIndex),
                    afterAtContent = this.messageContent.substring(this.inputIndex);
                this.messageContent = beforeAtContent + atUser.nick + ' ' + afterAtContent;
            } else {
                this.messageContent += atUser.nick + ' ';
            }
            this.inputIndex += atUser.nick.length + 1;
            this.isShowAtGroupMember = false;
            this.atInputIndex = -1;
            this.atUserID = "";
            this.$refs.messageInput.focus();
            this.$nextTick(() => {
                this.$refs.messageInput.selectionStart = this.inputIndex;
                this.$refs.messageInput.selectionEnd = this.inputIndex;
            })
        },
        handleInput(ev) {
            if(ev.inputType === 'insertCompositionText') {
                ev.preventDefault();
            } else {
                if(this.isSafari && ev.inputType === 'insertFromComposition' && !/[\u4E00-\u9FFF]+/g.test(ev.data)) {
                    this.isComposing = true;
                }
                this.updateMessageContent(ev);
            }
        },
        updateMessageContent(ev) {
            if(this.messageContent.length > 3000) {
                let enc = new TextEncoder("utf-8");
                for(let i = 0; i < 6000; i++) {
                    if(enc.encode(ev.target.value).length > 9000) {
                        ev.target.value = ev.target.value.substring(0, ev.target.value.length-1);
                    } else {
                        this.messageContent = ev.target.value;
                        break;
                    }
                }
            } else {
                this.messageContent = ev.target.value;
                this.inputIndex = ev.target.selectionStart;
                if(this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
                    if(ev.data == '@') {
                        this.isShowAtGroupMember = true;
                        this.atInputIndex = ev.target.selectionStart;
                    }
                    this.filterAtMemberList();
                }
            }
        },
        handleLeft(ev) {
            this.inputIndex = ev.target.selectionStart - 1 < 0 ? 0 : ev.target.selectionStart - 1;
            let index = -1;
            this.memberList.some(item => {
                index = this.messageContent.indexOf(`@${item.nick} `);
                if(index > -1) {
                    for(let i = index; i <= this.inputIndex; i++) {
                        if((index + item.nick.length + 1) === this.inputIndex) {
                            this.inputIndex = index;
                            this.$nextTick(() => {
                                this.$refs.messageInput.selectionStart = this.inputIndex;
                                this.$refs.messageInput.selectionEnd = this.inputIndex;
                            })
                            ev.preventDefault();
                            return true;
                        } else {
                            i = index + item.nick.length + 2;
                            index = this.messageContent.substring(i).indexOf(`@${item.nick} `);
                            if(index > -1) {
                                index += i;
                                continue;
                            } else {
                                break;
                            }
                        }
                    }
                }
            })
        },
        handleRight(ev) {
            this.inputIndex = ev.target.selectionStart + 1 > this.messageContent.length ? this.messageContent.length : ev.target.selectionStart + 1;
            let index = -1;
            this.memberList.some(item => {
                index = this.messageContent.indexOf(`@${item.nick} `);
                if(index > -1) {
                    for(let i = index; i <= this.inputIndex; i++) {
                        if((index + 1) === this.inputIndex) {
                            this.inputIndex = index + item.nick.length + 2;
                            this.$nextTick(() => {
                                this.$refs.messageInput.selectionStart = this.inputIndex;
                                this.$refs.messageInput.selectionEnd = this.inputIndex;
                            })
                            ev.preventDefault();
                            return true;
                        } else {
                            i = index + item.nick.length + 2;
                            index = this.messageContent.substring(i).indexOf(`@${item.nick} `);
                            if(index > -1) {
                                index += i;
                                continue;
                            } else {
                                break;
                            }
                        }
                    }
                }
            })
        },
        handleClickIndex(ev) {
            this.inputIndex = ev.target.selectionStart;
        },
        handleEnter(ev) {
            if(this.isShowAtGroupMember) {
                this.handleSelectAtUser()
            } else {
                if((!this.isSafari && !ev.isComposing) || (this.isSafari && !this.isComposing)) {
                    this.createInputMessage()
                } else if(this.isSafari && this.isComposing) {
                    this.isComposing = false;
                }
            }
        },
        handleLine() {
            if(this.inputIndex < this.messageContent.length) {
                let beforeAtContent = this.messageContent.substring(0, this.inputIndex),
                    afterAtContent = this.messageContent.substring(this.inputIndex);
                this.messageContent = beforeAtContent + '\n' + afterAtContent;
            } else {
                this.messageContent += '\n';
            }
            this.inputIndex += 1;
            this.$nextTick(() => {
                this.$refs.messageInput.selectionStart = this.inputIndex;
                this.$refs.messageInput.selectionEnd = this.inputIndex;
                this.$refs.messageInput.scrollTop += 16;
            })
        },
        handleUp() {
            const index = this.atMemberList.findIndex(
                member => member.userID === this.atUserID
            )
            if (index - 1 < 0) {
                return
            }
            this.atUserID = this.atMemberList[index - 1].userID
            if(document.querySelector(".at-member-list .el-radio.is-checked") && document.querySelector(".at-member-list .el-radio.is-checked").offsetTop - 30 <= document.querySelector(".at-member-list").scrollTop) {
                document.querySelector(".at-member-list").scrollTop -= 30;
            }
        },
        handleDown() {
            const index = this.atMemberList.findIndex(
                member => member.userID === this.atUserID
            )
            if (index + 1 >= this.atMemberList.length) {
                return
            }
            this.atUserID = this.atMemberList[index + 1].userID;
            if(document.querySelector(".at-member-list .el-radio.is-checked") && document.querySelector(".at-member-list .el-radio.is-checked").offsetTop >= 130) {
                document.querySelector(".at-member-list").scrollTop += 30;
            }
        },
        handleDelete(ev) {
            if(ev.target.selectionStart !== ev.target.selectionEnd) {
                return;
            }
            let index = -1,
                beforeAtContent = "",
                afterAtContent = "";
            this.memberList.some(item => {
                index = this.messageContent.indexOf(`@${item.nick} `);
                if(index > -1) {
                    for(let i = index; i <= this.inputIndex; i++) {
                        if((ev.code === 'Backspace' && (index + item.nick.length + 2) === this.inputIndex) || (ev.code === 'Delete' && index === this.inputIndex)) {
                            if(ev.code === 'Backspace') {
                                beforeAtContent = this.messageContent.substring(0, index);
                                afterAtContent = this.messageContent.substring(index + item.nick.length + 2);
                            } else if (ev.code === 'Delete') {
                                beforeAtContent = this.messageContent.substring(0, index);
                                afterAtContent = this.messageContent.substring(index + item.nick.length + 2);
                            }
                            this.messageContent = beforeAtContent + afterAtContent;
                            this.inputIndex = index;
                            this.$nextTick(() => {
                                this.$refs.messageInput.selectionStart = this.inputIndex;
                                this.$refs.messageInput.selectionEnd = this.inputIndex;
                            })
                            ev.preventDefault();
                            return true;
                        } else {
                            i = index + item.nick.length + 2;
                            index = this.messageContent.substring(i).indexOf(`@${item.nick} `);
                            if(index > -1) {
                                index += i;
                                continue;
                            } else {
                                break;
                            }
                        }
                    }
                }
            })
        },
        chooseEmoji(item) {
            if(this.inputIndex < this.messageContent.length) {
                let beforeAtContent = this.messageContent.substring(0, this.inputIndex),
                    afterAtContent = this.messageContent.substring(this.inputIndex);
                this.messageContent = beforeAtContent + item + afterAtContent;
            } else {
                this.messageContent += item;
            }
            this.inputIndex += item.length;
            this.emojiPoppperShow = false;
            this.$refs.messageInput.focus();
            this.$nextTick(() => {
                this.$refs.messageInput.selectionStart = this.inputIndex;
                this.$refs.messageInput.selectionEnd = this.inputIndex;
            })
        },
        handleSendFileClick() {
            this.$refs.filePicker.click()
        },
        createFileMessage() {
            let filePicker = this.$refs.filePicker;
            if(filePicker.files.length && filePicker.files[0].type.indexOf('image') > -1) {
                this.createImageMessage(filePicker.files[0], () => {
                    this.$refs.filePicker.value = null
                });
            } else {
                let _self = this;
                let file = filePicker.files[0];
                openIM.createFileMessage({
                    filePath: '',
                    fileName: file.name,
                    uuid: _generateUUID(),
                    sourceUrl: '',
                    fileSize: file.size
                }).then(({ data })=>{
                    console.log(data);
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        const result = fileReader.result;
                        console.log(result)
                        _self.sendMessageByBuffer(data, result);
                    }
                    fileReader.readAsArrayBuffer(file);
                }).catch(err => {
                    console.log(err);
                })
                // openIM.createFileMessageFromFullPath({
                //     fileFullPath: URL.createObjectURL(file),
                //     fileName: file.name
                // }).then(({ data })=>{
                //     this.sendMessage(data);
                // }).catch( err =>{
                //     console.log(err);
                // }).finally(() => {
                //     this.$refs.filePicker.value = null;
                // });
            }
        },
        createImageMessage(file) {
            let _self = this;
            let img = new Image();
            img.onload = () => {
                const baseInfo = {
                    uuid: _generateUUID(),
                    type: file.name.split('.').pop().toLowerCase(),
                    size: file.size,
                    width: img.width,
                    height: img.height,
                    url: ""
                    // url: "https://tse1-mm.cn.bing.net/th/id/OET.c76a5b52d32b47009b0391076108c614?w=272&h=272&c=7&rs=1&o=5&dpr=2&pid=1.9"
                }
                openIM.createImageMessage({
                    sourcePicture: baseInfo,
                    bigPicture: baseInfo,
                    snapshotPicture: baseInfo,
                }).then(({ data })=>{
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        const result = fileReader.result;
                        console.log(result)
                        _self.sendMessageByBuffer(data, result);
                    }
                    fileReader.readAsArrayBuffer(file);

                    // _self.sendImageMessage(data);
                }).catch(err => {
                    console.log(err);
                })
            }
            img.src = URL.createObjectURL(file);
        },
        checkIfInAtUsers(userId) {
            let result = false;
            this.atUsers.some(item => {
                if (item.atUserID == userId) {
                    result = true;
                    return true;
                }
            });
            return result;
        },
        createInputMessage() {
            let message = {},
                currentConversationType = this.currentConversation.conversationType;
            if(this.messageContent === '' || this.messageContent.trim().length === 0) {
                this.messageContent = ''
                this.isEmptyMessageContent = true;
                setTimeout(() => {
                    if(this.isEmptyMessageContent) {
                        this.isEmptyMessageContent = false;
                    }
                }, 3000)
                return
            } else {
                this.isEmptyMessageContent = false;
            }
            let atList = this.messageContent.match(/(\@[\u4e00-\u9fa5_a-zA-Z0-9]+){2,} |\@所有人 /gi);
            if(atList && atList.length > 0) {
                //存在@消息 需要确认是否有艾特多人
                let members = this.memberList;
                if(atList.indexOf("@所有人 ") > -1) {
                    //@所有人 把全部群组成员作为@对象 ===> 由于多成员的群组存在把全部成员的ID作为文本时会超过最长文本的问题，故使用特定的id进行代替所有人(改于20200509)
                    // members.forEach(member => {
                    //     if(member.userID !== this.userId && member.nick !== "所有人") {
                    //         this.atUsers.push(member.userID);
                    //     }
                    // });
                    // this.atUsers.push({
                    //     atUserID: '********-****-****-****-************',
                    //     groupNickname: '所有人'
                    // });
                    this.atUsers.push('********-****-****-****-************');
                } else {
                    atList.forEach(item => {
                        let atItem = item.substring(1, item.length - 1);
                        for(let i = 0; i < members.length; i++) {
                            if(members[i].nick === atItem && !this.checkIfInAtUsers(members[i].userID)) {
                                this.atUsers.push(members[i].userID);
                                // this.atUsers.push({
                                //     atUserID: members[i].userID,
                                //     groupNickname: members[i].nick
                                // })
                                break;
                            }
                        }
                    });
                }
            }
            if(this.atUsers.length > 0) {
                //自定义消息-@消息
                // let extension = JSON.stringify({
                //         type: "atMessage",
                //         data: {
                //             message: this.messageContent,
                //             atUsers: this.atUsers,
                //             isAtMe: false
                //         }
                //     })
                // message = tim.createCustomMessage({
                //     to: this.toAccount,
                //     conversationType: currentConversationType,
                //     payload: {
                //         data: "",
                //         description: "",
                //         extension: extension
                //     }
                // });

                // let atUserIds = [];
                // this.atUsers.forEach(item => {
                //     atUserIds.push(item.atUserID);
                // });

                let extension = JSON.stringify({
                    type: "atMessage",
                    data: {
                        message: this.messageContent,
                        atUsers: this.atUsers,
                        isAtMe: false
                    }
                })

                openIM.createCustomMessage({
                    data: extension,
                    extension: 'atMessage',
                    description: ''
                }).then(({ data })=>{
                    this.sendMessage(data);
                    this.atUsers = [];
                    this.messageContent = '';
                }).catch(err => {
                    console.log(err);
                })

                // openIM.createTextAtMessage({
                //     text: this.messageContent,
                //     atUserIDList: atUserIds,
                //     atUserInfoList: this.atUsers,
                //     message: ''
                // }).then(({ data })=>{
                //     this.sendMessage(data);
                //     this.atUsers = [];
                //     this.messageContent = '';
                // }).catch(err=>{
                //     console.log(err);
                // })
            } else {
            console.log('c-----------------1')
                openIM.createTextMessage(this.messageContent).then(({ data })=>{
                    this.sendMessage(data);
                    console.log('c-----------------2')
                    this.atUsers = [];
                    this.messageContent = '';
                }).catch(err=>{
                    console.log('c-----------------3')
                    console.log(err);
                })
            }
        },
        sendImageMessage(message) {
            openIM.sendMessageNotOss({
                recvID: this.currentConversation.type === TIM.TYPES.CONV_C2C ? this.toAccount : '',
                groupID: this.currentConversation.type === TIM.TYPES.CONV_GROUP ? this.toAccount : '',
                message: message,
            }).then(({ data, errCode })=>{
                if (errCode === 0 || errCode === undefined) {
                    // message.msgSeq = data.message.sequence;
                    // message.msgTime = data.message.time;
                    this.$emit('sendMessage', JSON.parse(data));
                    console.log('image message:', JSON.parse(data));
                } else {
                    console.log('发送失败！');
                }
            }).catch(err=>{
                console.log('sendMessageError：', err);
            });
        },
        sendMessageByBuffer(message, buffer) {
            console.log(buffer);
            openIM.sendMessageByBuffer({
                recvID: this.currentConversation.type === TIM.TYPES.CONV_C2C ? this.toAccount : '',
                groupID: this.currentConversation.type === TIM.TYPES.CONV_GROUP ? this.toAccount : '',
                message: message,
                fileArrayBuffer: buffer
            }).then(({ data, errCode })=>{
                if (errCode === 0 || errCode === undefined) {
                    // message.msgSeq = data.message.sequence;
                    // message.msgTime = data.message.time;
                    this.$emit('sendMessage', JSON.parse(data));
                } else {
                    console.log('发送失败！');
                }
            }).catch(err=>{
                console.log('sendMessageError：', err);
            });
        },
        sendMessage(message) {
            let messageJson = JSON.parse(message);
            //将发送的消息推入消息列表，并且消息展示窗口滚动条要在最后
            if(this.toAccount === this.$store.state.user.userInfo.tbdFirmId) {
                let params = {
                        messageType: "",
                        isWorkBenchMain: this.isWorkBenchMain,
                        isFromWorkBench: window._isNewProject || false
                    };
                switch(messageJson.contentType) {
                    case openIM.MessageType.TEXTMESSAGE: 
                        params.messageType = "文本消息";
                        break;
                    case openIM.MessageType.PICTUREMESSAGE: 
                        params.messageType = "图片消息";
                        break;
                    case openIM.MessageType.FILEMESSAGE: 
                        params.messageType = "文件消息";
                        break;
                    case openIM.MessageType.ATTEXTMESSAGE: 
                    case openIM.MessageType.CUSTOMMESSAGE: 
                        params.messageType = "@消息等自定义消息";
                        break;
                }
                if(window._tracker) {
                    _tracker.track('IMFirmGroupSendMessage', JSON.stringify(params));
                } else {        
                    lbdTrack.track('IMFirmGroupSendMessage', params);
                }
            }
            
            openIM.sendMessage({
                recvID: this.currentConversation.type === TIM.TYPES.CONV_C2C ? this.toAccount : '',
                groupID: this.currentConversation.type === TIM.TYPES.CONV_GROUP ? this.toAccount : '',
                offlinePushInfo: (messageJson.contentType === openIM.MessageType.ATTEXTMESSAGE || messageJson.contentType === openIM.MessageType.CUSTOMMESSAGE) ? 
                {
                    title: '新消息提醒',
                    desc: messageJson.content,
                    ex: '',
                    iOSPushSound: '',
                    iOSBadgeCount: true
                } : null,
                message: message,
            }).then(({ data, errCode })=>{
                if (errCode === 0 || errCode === undefined) {
                    // message.msgSeq = data.message.sequence;
                    // message.msgTime = data.message.time;
                    this.$emit('sendMessage', JSON.parse(data));
                } else {
                    console.log('发送失败！');
                }
            }).catch(err=>{
                console.log('sendMessageError：', err);
            });
        },
        filterAtMemberList() {
            if(!this.isShowAtGroupMember && this.atInputIndex === -1) return
            this.isShowAtGroupMember = false;
            let filterText = this.messageContent.slice(this.atInputIndex, this.inputIndex);
            if(filterText) {
                this.atMemberList = this.memberList.filter(item => {
                    return item.userID !== this.userId && item.nick.indexOf(filterText) > -1;
                })
            } else {
                this.atMemberList = this.memberList.filter(item => {
                    return item.userID !== this.userId;
                });
            }
            setTimeout(() => {
                this.isShowAtGroupMember = true;
                if(!this.atMemberList.length || this.atInputIndex === this.inputIndex + 1) {
                    this.isShowAtGroupMember = false;
                }
            }, 0);
        },
        handleClickAtUser(userID) {
            if(!userID) {
                this.handleSelectAtUser();
            } else {
                this.atUserID = userID;
                this.handleSelectAtUser();
            }
        },
        // 自定义消息发送
        customBtn() {
            const obj = {
                jobId: '1234-1341324-134134-1341341',
                firmShortName: '华为111',
                jobName: '后端开发工程师',
                location: '武汉222',
                yearOfExperience: '3-5年',
                degree: '本科',
                salary: '20k-35k',
                commissionValue: '40%',
            };

            openIM.createCustomMessage({
                data: JSON.stringify(obj),
                extension: 'Share',
                description: "",
            })
            .then(({ data }) => {
                console.log(`data:`, data)
                this.sendMessage(data);
            })
            .catch(err => { 
                console.log(`err:`, err)
            });
        },
    }
}
</script>

<style lang="scss" scope>
.message-send-box-wrapper {
    height: 118px;
    min-height: 118px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px 12px 8px 10px;
    border-top: 1px solid #ddd;
    .message-operations {
        height: 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .emoji-btn {
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-top: 1px;
            cursor: pointer;
            background: url('~@src/assets/images/icon/icon-xiaolian.svg') no-repeat;
            background-size: contain;
        }
        .el-icon-folder {
            font-size: 20px;
            color: #999;
            margin-left: 8px;
            cursor: pointer;
        }
    }
    .text-input {
        width: 100%;
        border: none;
        resize: none;
        color: #444;
        font-size: 12px;
    }
    .bottom {
        position: relative;
        text-align: right;
        .message-send-tip {
            position: absolute;
            bottom: 8px;
            right: 84px;
            color: #999;
            font-size: 12px;
            line-height: 16px;
        }
        .message-send-btn {
            width: 64px;
            min-width: unset;
            height: 32px;
            line-height: 30px;
            font-size: 12px;
        }
    }
}
</style>

<style lang="scss">
.emojis-popper.el-popper {
    .emojis {
        height: 160px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: scroll;
        .emoji {
            display: block;
            height: 40px;
            width: 40px;
        }
    }
}
.at-member-list-popover.el-popper {
    padding: 0;
    min-width: 135px;
    margin-bottom: 5px;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.20);
    .at-member-list {
        max-height: 165px;
        width: 135px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .el-radio {
            margin: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
            &.is-checked {
                background-color: rgba(0, 0, 0, 0.1);
            }
            &.at-all-checkbox {
                border-bottom: 1px solid #d8d8d8;
                margin-bottom: 5px;
                height: 35px;
                line-height: 34px;
            }
            .el-radio__input {
                display: none;
            }
            .el-radio__label {
                width: 100%;
                padding-left: 10px;
                display: inline-flex;
                align-items: center;
                font-size: 12px;
                color: #444;
                .at-member-avatar {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 50%;
                    border: 1px solid $primary;
                }
                .at-member-name {
                    margin-left: 6px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>