import moment from 'moment';
moment.locale('zh-cn');//"zh-cn"
export function momentFormatDate (date) {
    let nowTime = new Date();
    let nowTimeStamp = nowTime.getTime();
    let thisTimeStamp = new Date(date).getTime();
    let dayTimeStamp = new Date(new Date(date).toLocaleDateString()).getTime();
    let todayTimeStamp = new Date(nowTime.toLocaleDateString()).getTime();
    let yestodayTimeStamp = new Date(nowTime.toLocaleDateString()).getTime() - 24*60*60*1000;
    if(nowTimeStamp - thisTimeStamp < 60*60*1000){
        return moment(date).fromNow();
    }else if(dayTimeStamp === todayTimeStamp){
        return `今天  ${moment(date).format('HH:mm:ss')}`;
    }else if(dayTimeStamp === yestodayTimeStamp){
        return `昨天  ${moment(date).format('HH:mm:ss')}`;
    }else{
        return date;
    }
}

export const formatDate = (date, format='YYYY-MM-DD') => {
    return moment(date).format(format);
};

// 字符串超出长度处理成...
export const overEllipsis = (str, len = 10) => {
    if(str && str.length > len) {
        return str.substr(0, len) + '...';
    }
    return str;
};

export function wrap (text, prefix, suffix) {
    if (!prefix && !suffix) {
        prefix = '(';
        suffix = ')';
    }

    return text === '' || text == null ? '' : prefix + text + suffix;
}

// 深圳 => [深圳]
export function square (text, notZero) {
    if(notZero && text == 0){
        text = '';
    }
    return wrap(text, '[', ']');
}

export function avatar (url) {
    return url || require('#/assets/images/default-user.png');
}

export function logo (url) {
    return url || require('#/assets/images/default-company.png');
}

export function tpl (text, template) {
    return String(text).replace(/\%[pP]/, template);
}

export const getAgeFromBirthday = (birth) => {
    if(birth == "0001-01-01T00:00:00") {
        return '未知';
    } else {
        return moment().diff(moment(birth), 'years') + '岁';
    }
};