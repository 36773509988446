<template>
    <div class="member-item">
        <el-checkbox 
            :value="value" 
            @change="(value) => handleMemberSelect(value, member)"
            :disabled="disabled"
        >
            <img :src="`${avatarBaseUrl}/${isGroup ? 'group' : 'user'}/${isGroup ? member.groupId : member.unionId}`" />
            <!-- <img :src="member.avatarUrl" /> -->
            <template v-if="isShare">
                <div v-if="type == 'innerMemberList' || type == 'outerMemberList'" class="member-text-info">
                    <span class="member-name">{{member.userName}}</span>
                    <span class="firm-name">{{member.company}} | {{member.title}}</span>
                </div>
                <span v-if="type == 'groupList'" class="member-name">{{member.groupName}}</span>
                <span v-if="type == 'conversationList' && isGroup" class="member-name">{{member.groupName}}</span>
                <div v-if="type == 'conversationList' && !isGroup" class="member-text-info">
                    <span class="member-name">{{member.name}}</span>
                    <span
                        class="firm-name"
                        v-if="member.company || member.title">
                        {{member.company}} | {{member.title}}
                    </span>
                    <span
                        class="firm-name"
                        v-else>
                        职通车候选人
                    </span>
                </div>
            </template>
            <template v-else>
                <span v-if="type == 'teamMemberList' || type == 'innerMemberList'" class="member-name">{{member.realName}}@{{member.nickname}}</span>
                <div v-if="type == 'outerMemberList'" class="member-text-info">
                    <span class="member-name">{{member.realName}}@{{member.nickname}}</span>
                    <span class="firm-name">{{member.firmShortName}}</span>
                </div>
            </template>
        </el-checkbox>
    </div>
</template>

<script>
import { avatarHost } from '../utils/avatarHost.js';
export default {
    props: {
        member: Object,
        value: Boolean,
        disabled: Boolean,
        isGroup: Boolean,
        isShare: Boolean,
        type: String
    },
    computed: {
        avatarBaseUrl() {
            return avatarHost();
        }
    },
    methods: {
        handleMemberSelect(value, member) {
            this.$emit('member-select', value, member);
        }
    }
}
</script>

<style lang="scss" scoped>
    .member-item {
        display: flex;
        align-items: center;
        height: 47px;

        .el-checkbox {
            margin-bottom: 0;
        }

        img {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border-radius: 50%;
            border: 1px solid $primary;
        }

        .member-text-info {
            display: inline-block;
            vertical-align: top;
            
            .member-name {
                display: block;
                max-width: 160px;
            }
        }

        .member-name, .firm-name {
            display: inline-block;
            max-width: 162px;
            color: #4A4A4A;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }
        .firm-name {
            display: block;
            font-size: 12px;
            color: #999;
        }
    }
</style>