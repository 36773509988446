var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-department-select" },
    [
      _c("el-input", {
        staticClass: "department-select-input",
        attrs: {
          disabled: _vm.isDisabled,
          placeholder: _vm.placeholder,
          size: _vm.size,
        },
        on: {
          input: _vm.handleInput,
          focus: _vm.handleInputFocus,
          blur: function ($event) {
            return _vm.handleInputBlur($event)
          },
        },
        model: {
          value: _vm.selectText,
          callback: function ($$v) {
            _vm.selectText = $$v
          },
          expression: "selectText",
        },
      }),
      _c("el-cascader-panel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchCascaderVisible,
            expression: "searchCascaderVisible",
          },
        ],
        ref: "departmentSearchCascader",
        staticClass: "department-cascader",
        attrs: {
          options: _vm.filterDepartments,
          size: _vm.size,
          props: {
            checkStrictly: !_vm.isSearchOnlyLeaf,
            emitPath: false,
            value: "id",
            label: "name",
            lazy: true,
            lazyLoad: _vm.getSubDepartmentList,
          },
        },
        on: {
          "expand-change": _vm.handleExpandChange,
          change: (val) => _vm.handleDepartmentId(val, "cascader-search"),
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("el-cascader-panel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.isFocus &&
              !_vm.isInput &&
              !_vm.isDisabled &&
              _vm.departmentList.length > 0,
            expression:
              "\n            isFocus && !isInput && !isDisabled && departmentList.length > 0\n        ",
          },
        ],
        ref: "departmentCascader",
        staticClass: "department-cascader",
        attrs: {
          options: _vm.departmentList,
          size: _vm.size,
          props: {
            checkStrictly: !_vm.isOnlyLeaf,
            emitPath: false,
            value: "id",
            label: "name",
            lazy: true,
            lazyLoad: _vm.getSubDepartmentList,
          },
        },
        on: {
          "expand-change": _vm.handleExpandChange,
          change: (val) => _vm.handleDepartmentId(val, "cascader"),
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }