var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        visible: _vm.feedbackVisible,
        "custom-class": "suggest-feedback",
        width: "510px",
        "show-close": false,
        title: "意见反馈",
        "close-on-click-modal": false,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.feedbackVisible = $event
        },
      },
    },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          placeholder: "请详细描写您的意见建议，之后的升级一定会给你惊喜~",
          maxlength: "200",
          "show-word-limit": "",
          resize: "none",
        },
        model: {
          value: _vm.textarea,
          callback: function ($$v) {
            _vm.textarea = $$v
          },
          expression: "textarea",
        },
      }),
      _c(
        "div",
        { staticClass: "feedback-button" },
        [
          _c("el-button", { on: { click: _vm.handleCancle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { staticClass: "default", on: { click: _vm.submit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }