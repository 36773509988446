import tool from '../tool.js';
let url = {
    userInfo: '/Point/Rules',
    getPointRule: '/Point/Rules',
    myPointInfo: '/My/Point/Statistic',
    getDetails : '/My/Point/GetDetails',
};

import { point as pointUrl } from '#/js/config/api.json';
import { point as javaPointUrl } from '#/js/config/javaApi.json';

export default {
    getPointRule : () => {
        return _request({
            method: 'GET',
            url: url.getPointRule
        })
    },
    myPointInfo: () => {
        return _request({
            method: 'GET',
            url: url.myPointInfo
        })
    },
    getDetails: (cur,take) => {
        return _request({
            method: 'GET',
            url: url.getDetails,
            data: {
                start: (cur - 1) * take || 0,
                take: take || 10,
                _: + new Date()
            }
        })
    },
    getRecordList(param) {
        return _request({
            method: 'POST',
            url: pointUrl.point_record_list,
            data: param
        })
    },
    getExchangeRecordList(param) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: '8702',
            url: javaPointUrl.prize_exchange_list,
            data: param
        })
    },
    getUserInfo() {
        return _request({
            method: 'POST',
            url: url.userInfo
        })
    },
    getCanExchangeList() {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: '8702',
            url: javaPointUrl.prize_can_exchange_list
        })
    },
    prizeExchange(param) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'point',
            baseURLPort: '8702',
            url: javaPointUrl.prize_exchange,
            data: param
        })
    },
    getPointRankList(param) {
        return _request({
            method: "POST",
            url: pointUrl.point_rank_list,
            data: param
        });
    }
}
