var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "member-list-wrapper" }, [
    _c("div", { staticClass: "search-box" }, [
      _c(
        "div",
        { staticClass: "search-box-select" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "search-box-dropdown",
              attrs: { placement: "bottom-start", trigger: "click" },
              on: { command: _vm.handleCommand },
            },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.searchType == 0 ? "内部" : "外部")
                ),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "search-box-menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      class: { "is-selected": _vm.searchType == 0 },
                      attrs: { command: "0" },
                    },
                    [_vm._v("内部")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      class: { "is-selected": _vm.searchType == 1 },
                      attrs: { command: "1" },
                    },
                    [_vm._v("外部")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "search-box-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.keyword,
              expression: "keyword",
              modifiers: { trim: true },
            },
          ],
          attrs: {
            maxlength: "100",
            type: "text",
            placeholder: "搜索名字、昵称...",
          },
          domProps: { value: _vm.keyword },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleSearch.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.keyword = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("span", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchMode,
              expression: "searchMode",
            },
          ],
          staticClass: "search-box-input-clear el-icon-error",
          on: { click: _vm.handleClearSearch },
        }),
      ]),
    ]),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.searchMode,
            expression: "!searchMode",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.teamListLoading,
            expression: "teamListLoading",
          },
        ],
        ref: "team-list",
        staticClass: "team-list",
      },
      _vm._l(_vm.teamList, function (team, index) {
        return _c(
          "li",
          { key: "team" + team.teamId, staticClass: "team-item" },
          [
            _c(
              "p",
              {
                staticClass: "team-name",
                class: team.expanded ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.handleExpandTeam(team, index)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(team.teamName) +
                    " (" +
                    _vm._s(team.count) +
                    ")\n                "
                ),
                team.count > 0 && !team.expanded
                  ? _c("i", { staticClass: "el-icon-arrow-right" })
                  : _vm._e(),
                team.count > 0 && team.expanded
                  ? _c("i", { staticClass: "el-icon-arrow-down" })
                  : _vm._e(),
              ]
            ),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: team.expanded,
                    expression: "team.expanded",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: team.memberListLoading,
                    expression: "team.memberListLoading",
                  },
                ],
              },
              _vm._l(team.memberList, function (member) {
                return _c(
                  "li",
                  { key: member.unionId },
                  [
                    _c("member-item", {
                      attrs: {
                        member: member,
                        type: "teamMemberList",
                        disabled: _vm.checkMemberDisabled(member.unionId),
                        value: _vm.checkMemberSelected(member.unionId),
                      },
                      on: { "member-select": _vm.handleMemberSelect },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      }),
      0
    ),
    _vm.showSearchInnerMemberList
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchMode && _vm.searchType == 0,
                expression: "searchMode && searchType == 0",
              },
            ],
            staticClass: "inner-member-list",
          },
          [
            _vm._l(_vm.innerMemberList, function (member) {
              return [
                member.realName.indexOf(_vm.innerSearchKeyword) > -1 ||
                member.nickname.indexOf(_vm.innerSearchKeyword) > -1
                  ? _c(
                      "li",
                      { key: member.unionId },
                      [
                        _c("member-item", {
                          attrs: {
                            member: member,
                            type: "innerMemberList",
                            disabled: _vm.checkMemberDisabled(member.unionId),
                            value: _vm.checkMemberSelected(member.unionId),
                          },
                          on: { "member-select": _vm.handleMemberSelect },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _c(
      "ul",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.outerListLoading,
            expression: "outerListLoading",
          },
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchMode && _vm.searchType == 1,
            expression: "searchMode && searchType == 1",
          },
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.getOuterMemberList,
            expression: "getOuterMemberList",
          },
        ],
        staticClass: "outer-member-list",
        attrs: {
          "infinite-scroll-immediate": false,
          "infinite-scroll-disabled":
            !_vm.outerListFetched ||
            _vm.outerListPageLoading ||
            _vm.outerListHasNoMore,
        },
      },
      [
        _vm._l(_vm.outerMemberList, function (member) {
          return _c(
            "li",
            { key: member.unionId },
            [
              _c("member-item", {
                attrs: {
                  member: member,
                  type: "outerMemberList",
                  disabled: _vm.checkMemberDisabled(member.unionId),
                  value: _vm.checkMemberSelected(member.unionId),
                },
                on: { "member-select": _vm.handleMemberSelect },
              }),
            ],
            1
          )
        }),
        _vm.outerListFetched && _vm.outerListPageLoading
          ? _c("p", { staticClass: "loading-info" }, [_vm._v("加载中...")])
          : _vm._e(),
        _vm.outerListFetched && _vm.outerListHasNoMore
          ? _c("p", { staticClass: "loading-info" }, [_vm._v("没有更多了")])
          : _vm._e(),
      ],
      2
    ),
    _vm.showEmptyTip
      ? _c("div", { staticClass: "empty-data" }, [
          _vm._v("\n        暂无数据\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }