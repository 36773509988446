var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPreviewer,
          expression: "showPreviewer",
        },
      ],
      staticClass: "image-previewer-wrapper",
      on: { mousewheel: _vm.handleMouseWheel },
    },
    [
      _c("div", { staticClass: "image-wrapper" }, [
        _c("img", {
          staticClass: "image-preview",
          style: { transform: `scale(${_vm.zoom}) rotate(${_vm.rotate}deg)` },
          attrs: { src: _vm.previewUrl },
          on: { click: _vm.close },
        }),
      ]),
      _c("i", {
        staticClass: "el-icon-close close-button",
        on: { click: _vm.close },
      }),
      _vm.index !== 0
        ? _c("i", {
            staticClass: "el-icon-back prev-button",
            on: { click: _vm.goPrev },
          })
        : _vm._e(),
      _vm.index !== _vm.imgUrlList.length - 1
        ? _c("i", {
            staticClass: "el-icon-right next-button",
            on: { click: _vm.goNext },
          })
        : _vm._e(),
      _c("div", { staticClass: "actions-bar" }, [
        _c("i", {
          staticClass: "el-icon-zoom-out",
          on: { click: _vm.zoomOut },
        }),
        _c("i", { staticClass: "el-icon-zoom-in", on: { click: _vm.zoomIn } }),
        _c("i", {
          staticClass: "el-icon-refresh-left",
          on: { click: _vm.rotateLeft },
        }),
        _c("i", {
          staticClass: "el-icon-refresh-right",
          on: { click: _vm.rotateRight },
        }),
        _c("span", { staticClass: "image-counter" }, [
          _vm._v(_vm._s(_vm.index + 1) + " / " + _vm._s(_vm.imgUrlList.length)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }