var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "point-rank-dialog-wrapper",
      attrs: {
        visible: _vm.dialogVisible,
        width: "956px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "point-rank-dialog",
        "append-to-body": "",
      },
    },
    [
      _c("div", { staticClass: "point-rank-wrapper" }, [
        _c("span", {
          staticClass: "hide-dialog-btn",
          on: { click: _vm.hideDialog },
        }),
        _c("div", { staticClass: "point-rank-type" }, [
          _c(
            "span",
            {
              staticClass: "rank-type-item",
              class: { active: _vm.rankType === 0 },
              on: {
                click: function ($event) {
                  return _vm.handleRankType(0)
                },
              },
            },
            [_vm._v("\r\n                周榜\r\n            ")]
          ),
          _c(
            "span",
            {
              staticClass: "rank-type-item",
              class: { active: _vm.rankType === 1 },
              on: {
                click: function ($event) {
                  return _vm.handleRankType(1)
                },
              },
            },
            [_vm._v("\r\n                总榜\r\n            ")]
          ),
        ]),
        _c("div", { staticClass: "team-btn-list" }, [
          _c(
            "span",
            {
              staticClass: "team-btn-item",
              class: { active: _vm.isTeam },
              on: {
                click: function ($event) {
                  return _vm.handleTeamType(true)
                },
              },
            },
            [_vm._v("\r\n                团队\r\n            ")]
          ),
        ]),
        _c("div", { staticClass: "point-rank" }, [
          _c("span", { staticClass: "point-rank-title" }, [
            _vm._v(
              "\r\n                " +
                _vm._s(
                  _vm.rankType === 1
                    ? "总"
                    : _vm.rankDateType === 1
                    ? "本周"
                    : "上周"
                ) +
                "积分" +
                _vm._s(_vm.isTeam ? "团队" : "个人") +
                "排行榜\r\n            "
            ),
          ]),
          _vm.rankType === 0
            ? _c("div", { staticClass: "point-rank-time" }, [
                _vm._v(
                  "\r\n                统计时间：" +
                    _vm._s(_vm.rankTime) +
                    "\r\n            "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "point-rank-list-wrapper",
            },
            [
              _c(
                "ul",
                { staticClass: "point-rank-list" },
                [
                  _vm._l(_vm.rankList, function (item, index) {
                    return [
                      _c("li", { key: index, staticClass: "point-rank-item" }, [
                        _c(
                          "span",
                          {
                            staticClass: "rank-result",
                            class: index < 3 ? `rank-result-${index}` : "",
                          },
                          [
                            _vm._v(
                              "\r\n                                " +
                                _vm._s(index > 2 ? index + 1 : "") +
                                "\r\n                            "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "rank-user-info" }, [
                          !_vm.isTeam
                            ? _c("img", {
                                staticClass: "rank-user-avatar",
                                attrs: { src: item.avatar },
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "rank-user-name",
                              style: _vm.isTeam ? "max-width: 230px;" : "",
                              attrs: { title: item.name },
                            },
                            [
                              _vm._v(
                                "\r\n                                    " +
                                  _vm._s(item.name) +
                                  "\r\n                                "
                              ),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "rank-point" }, [
                          _vm._v(
                            "\r\n                                " +
                              _vm._s(!_vm.isTeam ? "" : "人均") +
                              "\r\n                                " +
                              _vm._s(_vm._f("currency")(item.point, "", 0)) +
                              "分\r\n                            "
                          ),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "point-rank-list-footer" },
            [
              _c("span", { staticClass: "my-rank-result" }, [
                _vm._v(
                  "\r\n                    我的" +
                    _vm._s(!_vm.isTeam ? "" : "团队") +
                    "排名为：\r\n                    "
                ),
                _c("span", { staticClass: "text-orange" }, [
                  _vm._v(_vm._s(_vm.rank || "-")),
                ]),
              ]),
              _c("span", { staticClass: "rank-point-sum" }, [
                _vm._v(
                  "\r\n                    " +
                    _vm._s(
                      _vm.isTeam
                        ? _vm.rankType === 1
                          ? "人均总"
                          : _vm.rankDateType === 1
                          ? "本周人均"
                          : "上周人均"
                        : _vm.rankType === 1
                        ? "总"
                        : _vm.rankDateType === 1
                        ? "本周获得"
                        : "上周获得"
                    ) +
                    "积分：\r\n                    "
                ),
                _c("span", { staticClass: "text-orange" }, [
                  _vm._v(_vm._s(_vm.point || "-")),
                ]),
              ]),
              _vm.isTeam
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "popper-class": "average-point-tip",
                        effect: "light",
                        content: "人均积分=团队在职人员总积分/团队在职人员",
                        placement: "bottom-start",
                        "visible-arrow": false,
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon-question" },
                        [
                          _c("font-icon", {
                            staticClass: "question-hover",
                            attrs: { href: "#icon-talent_ql" },
                          }),
                          _c("font-icon", {
                            staticClass: "question-no-hover",
                            attrs: { href: "#icon-talent_qd" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.rankType === 0
          ? _c(
              "span",
              {
                staticClass: "last-week-btn text-operate-btn",
                on: { click: _vm.handleRankDateType },
              },
              [
                _vm._v(
                  "\r\n            " +
                    _vm._s(_vm.rankDateType === 0 ? "本周" : "上周") +
                    "积分排行榜\r\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }