class EventBus{
    constructor(){
        this.event=Object.create(null);
    };
    //注册事件
    on(name,fn){
        if(!this.event[name]){
        //一个事件可能有多个监听者
            this.event[name]=[];
        };
        this.event[name].push(fn);
    };
    //触发事件
    emit(name,...args){
        //给回调函数传参
        this.event[name]&&this.event[name].forEach(fn => {
            fn(...args)
        });
    };
    //只被触发一次的事件
    once(name,fn){
        //在这里同时完成了对该事件的注册、对该事件的触发，并在最后并取消该事件。
        const cb=(...args)=>{
        //触发
            fn.apply(this,args);
        //取消
            this.off(name,fn);
        };
        //监听
        this.on(name,cb);
    };
    //取消事件
    off(name,offcb){
        if(this.event[name]){
            let index=this.event[name].findIndex((fn)=>{
                return offcb===fn;
            })
            this.event[name].splice(index,1);
            if(!this.event[name].length){
                delete this.event[name];
            }
        }
    }
}

class globalSignalR extends EventBus{
    aliveInterval = null;
    isLocalStorageSupport = true;
    connection = null;
    _data = {
        "tokenFn":() => {},
        "connectName":"",
        "urlPrefix":"",
        "hubMethodName":"",
        "setTime":1900,
        "aliveTime":10000,
    };
    constructor(config){
        super();
        if (window.localStorage) {
            // console.log('该浏览器支持localStorage!');
            this.isLocalStorageSupport = true;
            Object.assign(this._data,config);
            if(this._data.urlPrefix) {
                this._data.urlPrefix = this._data.urlPrefix.replace(/https?:/, location.protocol);
            }
            this.init();
            this.handleOnStorage();
        } else {
            // console.log('该浏览器不支持localStorage');
            this.isLocalStorageSupport = false;
            this.signalRConnect();
        }
    }

    init(){
        if(localStorage.getItem(`${this._data.connectName}SignalRAlive`) === 'true'){
            if( (+ new Date() - parseInt(localStorage.getItem(`${this._data.connectName}SignalRTimeStamp`),10)) > this._data.aliveTime ){
                this.signalRConnect();
            }else{
                this.checkSignalRAlive();
            }
        }else{
            this.signalRConnect();
        }
    }

    signalRConnect(){
        if(!this._data.tokenFn()) {
            console.log('无授权信息');
            return false;
        }
        let Url = `${this._data.urlPrefix}/${this._data.connectName}`;
        let TimeInterval = null;
        //该页面为主连接页面，清除signalR活跃轮询。
        clearInterval(this.aliveInterval);
   
        //signalR活跃时间戳与状态更新
        if(this.isLocalStorageSupport){
            localStorage.setItem(`${this._data.connectName}SignalRAlive`,true);
            localStorage.setItem(`${this._data.connectName}SignalRTimeStamp`,+ new Date())
            TimeInterval = setInterval(()=>{
                if(!this._data.tokenFn()) {
                    this.connection && this.connection.stop();
                    localStorage.setItem(`${this._data.connectName}SignalRAlive`,false);
                    clearInterval(TimeInterval);
                }else {
                    localStorage.setItem(`${this._data.connectName}SignalRTimeStamp`,+ new Date());
                }
            },this._data.setTime)
        }

        console.log(this._data.tokenFn());
        //建立连接
        this.connection = new signalR.HubConnectionBuilder()
                .withUrl(Url, { accessTokenFactory: () => this._data.tokenFn() })
                .configureLogging(signalR.LogLevel.None)
                .build();
        
        //连接socket
        this.connection.start().then(()=> {
            // console.log(`${this._data.connectName}-signalR-connected`);
        });


        //断开连接手动重连
        async function start() {
            try {
                await this.connection.start().then(()=>{
                    // console.log(`${this._data.connectName}-signalR-connected`);
                });
            } catch (err) {
                // console.log(err);
                setTimeout(() => start(), 5000);
            }
        };

        this.connection.onclose(async () => {
            // console.log(`${this._data.connectName}-signalR-reconnect`);
            await start();
        });

        //接收服务器端的消息
        if(Object.prototype.toString.call(this._data.hubMethodName) === '[object Array]'){
            this._data.hubMethodName.forEach(item => {
                this.connection.on(`${item}`, (message) => {
                    let data = {
                        name:item,
                        data:message,
                        receiveTime:+ new Date(),
                    }
                    localStorage.setItem(`${this._data.connectName}SignalRData`, JSON.stringify(data));
                    super.emit('receiveSignalRData',data);
                });                
            });
        }else if(Object.prototype.toString.call(this._data.hubMethodName) === '[object String]'){
            this.connection.on(`${this._data.hubMethodName}`, (message) => {
                let data = {
                    name:this._data.hubMethodName,
                    data:message,
                    receiveTime:+ new Date(),
                }
                localStorage.setItem(`${this._data.connectName}SignalRData`, JSON.stringify(data));
                super.emit('receiveSignalRData',data);
            });
        }
    }

    isSignalROn(){
        if( (+ new Date() - parseInt(localStorage.getItem(`${this._data.connectName}SignalRTimeStamp`),10)) > this._data.aliveTime ){
            return false;
        }else{
            return true;
        }
    }

    checkSignalRAlive(){
        //轮询signalR活跃时间戳
        this.aliveInterval = setInterval(() => {
            if( (+ new Date() - parseInt(localStorage.getItem(`${this._data.connectName}SignalRTimeStamp`),10)) > this._data.aliveTime ){
                localStorage.setItem(`${this._data.connectName}SignalRAlive`,false)
                // console.log(`${this._data.connectName}-signalR-check-connected`)
                this.signalRConnect();
            }
        }, this._data.setTime);
    }

    signalRSend(methodName,data){
        console.log('发送消息：' + methodName);
        //发送方法，若为主连接直接发送，否则存入localStorage
        if(this.connection){
            if(data){
                this.connection.invoke(`${methodName}`,data);
            }else{
                this.connection.invoke(`${methodName}`);
            }
        }else{
            let JsonData = {
                sendMethodName : methodName,
                data : data,
                sendTime:+ new Date(),
            }
            localStorage.setItem(`${this._data.connectName}SignalRSend`,JSON.stringify(JsonData));
        }
    }

    handleOnStorage(){
        window.onstorage = (e) => {
            //监听signalR活跃状态改变
            if(e.key == `${this._data.connectName}SignalRAlive` && e.oldValue === "false" && e.newValue === "true" ){
                clearInterval(this.aliveInterval);
                this.checkSignalRAlive();
            }
            //监听signalR数据改变
            if(e.key == `${this._data.connectName}SignalRData`){
                super.emit('receiveSignalRData',JSON.parse(e.newValue));
            }
            
            //监听signalR发送数据
            if(e.key == `${this._data.connectName}SignalRSend`){
                let data = JSON.parse(e.newValue);
                if(this.connection){
                    if(data.data){
                        this.connection.invoke(`${data.sendMethodName}`,data.data);
                    }else{
                        this.connection.invoke(`${data.sendMethodName}`);
                    }
                }
            }
        };

        // window.onbeforeunload = ()=>{
        //     if(this.connection){
        //         localStorage.setItem(`${this._data.connectName}SignalRAlive`,false);
        //     }
        // }
    }

    onSignalRData(name,fn){
        super.on('receiveSignalRData',(data)=>{
            if(data.name == name ){
                fn(data)
            }
        })
    }

}

export default globalSignalR;