import Vue from "vue";
import VueRouter from "vue-router";
import WorkTableApp from "#/entry/WorkTableApp.vue";
import store from "#/store";

// 兼容safari环境下的decodeURIComponent方法对'%'符号编码的报错
// 会导致解码失效
// try{
//     decodeURIComponent('%');
// }catch(e){
//     decodeURIComponent = function(value){
//         value = encodeURIComponent(value);
//         return decodeURI(value);
//     }
// }
// 兼容End

import axios from 'axios';

import "#/css/element-variables.scss";
import "#/css/element-custom.scss";
import "#/css/reset.css";
import '#/css/index.css';
import "#/css/common-new.scss";
import ElementUI from "element-ui";

Vue.use(ElementUI);

// 使用指令
import Directives from '#/js/directives/index';
Vue.use(Directives);

// 因为数据面板组件需要将老页面的过滤器和样式迁入
// import '@/scss/entry/library.scss';
// import '@/scss/entry/common.scss';

import FontIcon from '#/component/common/fontIcon.vue';
Vue.component('FontIcon', FontIcon);

const Summary = () => import("#/component/summary/summary.js");
const WorkTableHome = () => import("#/views/work-table-home/index.vue");
const FloatingListRecommendToMe = () => import("#/views/floating-list/floatingListRecommendToMe.vue");
const FloatingListMyRecommend = () => import("#/views/floating-list/floatingListMyRecommend.vue");
const FloatingListUnApplyList = () => import("#/views/floating-list/floatingListUnApplyList.vue");
const FloatingListApplyList = () => import("#/views/floating-list/floatingListApplyList.vue");
const FloatingListDimissionApplyList = () => import("#/views/floating-list/floatingListDimissionApplyList.vue");
const FloatingListDimissionUnApplyList = () => import("#/views/floating-list/floatingListDimissionUnApplyList.vue");
const FloatingListOfferList = () => import("#/views/floating-list/floatingListOfferList.vue");
const FloatingListRecommendAudit = () => import("#/views/floating-list/floatingListRecommendAudit.vue");

const TalentPool = () => import("#/views/talent-pool/talentPool.vue");
const TrackingList = () => import("#/views/tracking-list/trackingList.vue");
const Data = () => import("#/views/data/dataContainer.vue");
// const TeamData = () => import("#/views/data/teamData.vue");
// const TeamData = () => import("../../../src/component/statistics/statistics-page/index.vue");
const TeamData = () => import('#/views/performance/index.vue');
const TypesData = () => import("#/views/types-data/index.vue");
// const JdtData = () => import("#/views/jdt-data/index.vue");
const TalentReuse = () => import('#/views/talent-reuse/talentReuse.vue');
const MyOrdersCreatedJob = () => import("#/views/my-orders/myOrdersCreatedJob.vue");
const MyOrdersGrabbedJob = () => import("#/views/my-orders/myOrdersGrabbedJob.vue");
const MyOrdersSharedJob = () => import("#/views/my-orders/myOrdersSharedJob.vue");
const MyOrdersA2aAuditJob = () => import("#/views/my-orders/myOrdersA2aAuditJob.vue");
const PerformanceSet = () => import("#/views/performance-set/index.vue");
const PointSettingPrize = () => import("#/views/point-setting/prize-management.vue");
const PointSettingPoint = () => import("#/views/point-setting/point-management.vue");
const PointSettingSettleMent = () => import("#/views/point-setting/settlement-management.vue");
const ResumeMerge = () => import("#/views/resume-merge/index.vue");
const ResumeMergeLog = () => import("#/views/resume-merge/resumeMergeLog.vue");
const JdtSet = () => import("#/views/userPermissionSet/jdtSet.vue");
const AuditOpportunity = () => import("#/views/audit-content/audit-opportunity/index.vue");
const OfferBroadcast = () => import("#/views/offer-broadcast/index.vue");
const JobSet = () => import("#/views/userPermissionSet/jobSet.vue");
const Member = () => import("#/views/member/member.vue");
const PerformancesDetail = () => import('#/views/performances-detail/index');
const TeamPoints = () => import('#/views/team-points/teamPoints.vue');
const WeekReport = () => import('#/views/work-report/week-report.vue');
const DeliveryTarget = () => import('#/views/delivery-target/index.vue');
const DeliveryTargetDetail = () => import('#/views/delivery-target/detail.vue');
const OfferReport = ()=> import('#/views/offer-report/index.vue');
const JdtReport = ()=> import('#/views/jdt-report/index.vue');
const LeaderFeedbackData = () => import('#/views/leader-feedback-data/index.vue');
const BusinessLineData = () => import('#/views/business-line-data/businessLineData.vue');
const EvaluateCountReport = () => import('#/views/evaluate-count-report/evaluateCountReport.vue');
const PersonalEvaluateCountReport = () => import('#/views/evaluate-count-report/personalEvaluateCountReport.vue');
const TrackingListComment = () => import("#/views/tracking-list/tracking-list-comment.vue");
const Jdt = () => import('#/views/jdt/index.vue');
const CorporationOpportunity = () => import('#/views/corporation-opportunity/index.vue');
const OpportunityJobDtail = () => import('#/views/opportunity-detail/job-detail.vue');
const opportunityResumeDetail = () => import('#/views/opportunity-detail/resume-detail.vue');
const personalCenter = () => import('#/views/personal-center/index.vue');
const myCompanyCandidates = () => import('#/views/candidate-list/index');
const FirmCandidates = () => import('#/views/candidate-list/layout/firm-candidates.vue');
const personalCandidate = () => import('#/views/candidate-list/personal-candidates/index');
const namelistCandidate = () => import('#/views/candidate-list/namelist-candidates/index');
const linkedinCandidate = () => import('#/views/candidate-list/linkedin-candidates/index');
const candidateDetail = () => import('#/views/candidate-detail-bak/candidateDetail.vue');
const batchCandidateDetail = () => import('#/views/candidate-detail-bak/batchCandidateDetail.vue');
const batchOpportunityResumeDetail = () => import('#/views/opportunity-detail/batch-resume-detail.vue');
const linkedinCandidateDetail = () => import('#/views/candidate-detail-linkedin/index.vue');
const ResumeUpload = () => import('#/views/resumeUpload/index.vue');
const SingleUpload = () => import('#/views/resumeUpload/component/singleUpload.vue');
const MultipleUpload = () => import('#/views/resumeUpload/component/MultipleUpload.vue');
const ResumeUploadResult = ()=> import('#/views/resumeUpload/processProgress.vue');
const cloudRecommendation = () => import('#/views/cloudRecommendation/cloudRecommendation');
const CandidateUpdatecheck = () => import('#/views/candidate-update/candidate-updatecheck-new.vue');
const CocUpdate = () => import('#/views/coc-update/index.vue');
const JobList = () => import('#/views/job-list/index.vue');
const ProtocolJobList = () => import('#/views/protocol-job-list/index.vue');
const JobDetail = () => import('#/views/job-detail/jobDetail.vue');
const JobCreateCopy = () => import('#/views/job-create-copy/index.vue');
const JobCreateSingle = () => import('#/views/job-create-single/index.vue');
const JobCreateSingleSuccess = () => import('#/views/job-create-single/create-success.vue');
const JobCreateMulti = () => import('#/views/job-create-multi/index.vue');
const Project = () => import("#/views/project/index.vue");
const ProjectDetail = () => import('#/views/program-detail/project-detail.vue');
const MyGoal = () => import('#/views/program-detail/my-goal.vue');
const RecommendDetail = () => import('#/views/recommend-detail/index.vue');
const resultRecommend = () => import('#/views/result-recommend/resultRecommend.vue');
const Market = () => import('#/views/market/index.vue');
const CompanySetting = () => import('#/views/company-setting/index.vue');
const Authorization = () => import('#/views/authorization/index.vue');
// const OrgAuditSet = () => import("#/views/userPermissionSet/orgAuditSet.vue");
const MembersRoleSet = () => import("#/views/userPermissionSet/membersRoleSet.vue");
const RolePermissionSet = () => import("#/views/userPermissionSet/rolePermissionSet.vue");
const BusinessClassificationSet = () => import("#/views/userPermissionSet/businessClassificationSet.vue");
const EnterpriseWechatSet = () => import("#/views/userPermissionSet/enterpriseWechatSet.vue");
const DepartmentApplyList = () => import('#/views/department-create/applyList.vue');
const DepartmentAuditList = () => import('#/views/department-create/auditList.vue');
const OperationPlatform = () => import('#/views/operation-platform/operation-platform.vue');
const OperationData = () => import('#/views/operation-platform/operation-data.vue');
const HrFirm = () => import('#/views/hr-firm/index.vue');
const CustomerList = () => import('#/views/customer-list/index.vue');
const CustomerDetail = () => import('#/views/customers/customer-detail.vue');
const CustomerJobList = () => import('#/views/customers/layout/job-list.vue');
const CustomerDepartment = () => import('#/views/customers/layout/department-list.vue');
const AddCustomersRank = () => import('#/views/customers/addCustomersRank.vue');
const DepartmentDetail = () => import('#/views/department/department-detail.vue');
const BatchCreateDepartment = () => import("#/views/department-create/batchCreateDepartment.vue");
const ApplyFormPage = () => import('#/views/apply-invoice/index.vue'); // 申请开票
const JobMultiPublishResult = () => import('#/views/job-create-multi/component/publish-result.vue');
const sendEmail = () => import('#/views/send-mail/sendMail.vue');
const UserHomepage = () => import('#/views/user-homepage/index.vue');
const UserHomepageIndex = () => import('#/views/user-homepage/layout/index.vue');
const UserHomepageJobs = () => import('#/views/user-homepage/layout/jobs.vue');
const UserHomepageCandidates = () => import('#/views/user-homepage/layout/candidates.vue');
const TSDN = () => import('#/views/Tsdn/index.vue');
// const UserHomepageCloudCandidates = () => import('#/views/user-homepage/layout/cloud-candidates.vue');

import shortTips from "@src/js/plugin/jquery.setting";

window.shortTips = shortTips;

import * as lbdGlobalUtils from "#/js/util/global.js";
import { _generateUUID } from "#/js/util/global";
import { _momentFormatDate } from "#/js/util/global";
import * as degreeInfoFilters from '#/js/filters/degreeInfo';
import * as commomFilters from '#/js/filters/commonFilters.js';
import * as numberFilters from '#/js/filters/number.js';
import * as dateFilters from '#/js/filters/date.js';
// import * as stringFilters from '#/js/filter-vue/string';

Object.assign(window, lbdGlobalUtils);
window._pageViewId = _generateUUID();
window._isNewProject = true;//用于区分新老项目的标志位
//注册全局的filter

let filterObj = Object.assign({}, numberFilters, dateFilters, degreeInfoFilters, commomFilters);

Object.keys(filterObj).forEach(filter => {
    Vue.filter(filter, filterObj[filter]);
})
//定义全局_request方法
import LBDRequest from '@src/js/util/request.js';
if(!window._request) {
    window._request = new LBDRequest({
        mockBaseUrl: 'http://192.168.1.72:3000/mock/20',    //可选
        errMsgHandler: shortTips,                           //必选
        commonHeaders: {                                    //可选
        },
        dynamicCommonHeaders: () => {                       //可选
            return {
                "UPASession": JSON.stringify({
                    "PageViewId" : window._pageViewId || '',
                    "PageCode" : window._pageCode || ''
                })
            }
        }
    })._request;
}

window._uuid = _generateUUID();
window._pageViewId = window._uuid;
window._pageCode = 'Index';

//定义全局_tracker方法
import Tracker from '#/js/util/tracker.js';
window._tracker = new Tracker({ pageCode: "index" });

//定义全局弹窗队列方法
import LayerRegister from '#/js/util/layerRegister.js';
window._layerRegister = new LayerRegister();

window._momentFormatDate = _momentFormatDate;

Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err);
}
Vue.use(Summary); //工作总结的全局组件

const routes = [
    {
        path: "/",
        component: WorkTableHome,
        name:'WorkTableHome',
        meta: {
            title: "工作台_猎必得_最有效的O2O猎头招聘平台",
            keepAlive: false,
            pageCode: "Index"
        }
    },
    {
        path: "/floatingList",
        component: FloatingListRecommendToMe,
        name: 'FloatingListRecommendToMe',
        meta: {
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            keepAlive: true,
            pageCode: "FloatingListRecommendToMe"
        }
    },
    {
        path: "/floatingList/myRecommend",
        component: FloatingListMyRecommend,
        name: 'FloatingListMyRecommend',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListMyRecommend"
        }
    },
    {
        path: "/floatingList/unApply",
        component: FloatingListUnApplyList,
        name: 'FloatingListUnApplyList',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListUnApplyList"
        }
    },
    {
        path: "/floatingList/apply",
        component: FloatingListApplyList,
        name: 'FloatingListApplyList',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListApplyList"
        }
    },
    {
        path: "/floatingList/dimissionApply",
        component: FloatingListDimissionApplyList,
        name: 'FloatingListDimissionApplyList',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListDimissionApplyList"
        }
    },
    {
        path: "/floatingList/dimissionUnApply",
        component: FloatingListDimissionUnApplyList,
        name: 'FloatingListDimissionUnApplyList',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListDimissionUnApplyList"
        }
    },
    {
        path: "/floatingList/offerList",
        component: FloatingListOfferList,
        name: 'FloatingListOfferList',
        meta: {
            keepAlive: true,
            title: "流程管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListOfferList"
        }
    },
    {
        path: "/floatingList/recommendAudit",
        component: FloatingListRecommendAudit,
        name: 'FloatingListRecommendAudit',
        meta: {
            keepAlive: true,
            title: "HR直招推荐审批_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "FloatingListRecommendAudit"
        }
    },
    {
        path: "/talentPool",
        component: TalentPool,
        name: 'TalentPool',
        meta: {
            keepAlive: true,
            title: "我的人才池_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "TalentPool"
        }
    },
    {
        path: "/trackingList",
        component: TrackingList,
        name: 'TrackingList',
        meta: {
            keepAlive: true,
            title: "人才跟进_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "TrackingList"
        }
    },
    {
        path: "/data",
        component: Data,
        name: 'Data',
        meta: {
            keepAlive: true,
            title: "个人数据_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "Data"
        }
    },
    {
        path: "/teamData",
        component: TeamData,
        name: 'TeamData',
        meta: {
            keepAlive: true,
            title: "团队数据_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "TeamData"
        }
    },
    {
        path: "/typesData",
        component: TypesData,
        name: 'TypesData',
        meta: {
            keepAlive: true,
            title: "赛道数据_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "TypesData"
        }
    },
    // {
    //     path: "/jdtData",
    //     component: JdtData,
    //     name: 'JdtData',
    //     meta: {
    //         keepAlive: true,
    //         title: "职通车数据_猎必得_最有效的O2O猎头招聘平台",
    //         pageCode: "JdtData"
    //     }
    // },
    {
        path: '/talentReuse',
        component: TalentReuse,
        meta: {
            title: '人才复用_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'TalentReuse'
        }
    },
    {
        path: '/workbench/talentReuse',
        component: TalentReuse,
        meta: {
            title: '人才复用_猎必得_最有效的O2O猎头招聘平台'
        }
    },
    {
        path: "/myOrders/createdJob",
        component: MyOrdersCreatedJob,
        name: 'MyOrdersCreatedJob',
        meta: {
            keepAlive: true,
            title: "我的订单_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "MyOrdersCreatedJob"
        }
    },
    {
        path: "/myOrders/grabbedJob",
        component: MyOrdersGrabbedJob,
        name: 'MyOrdersGrabbedJob',
        meta: {
            keepAlive: true,
            title: "我的订单_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "MyOrdersGrabbedJob"
        }
    },
    {
        path: "/myOrders/sharedJob",
        component: MyOrdersSharedJob,
        name: 'MyOrdersSharedJob',
        meta: {
            keepAlive: true,
            title: "我分享的职位_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "MyOrdersSharedJob"
        }
    },
    {
        path: "/myOrders/a2aAuditJob",
        component: MyOrdersA2aAuditJob,
        name: 'MyOrdersA2aAuditJob',
        meta: {
            keepAlive: true,
            title: "A2A职位审批_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "MyOrdersA2aAuditJob"
        }
    },
    {
        path: "/performanceSet",
        component: PerformanceSet,
        name: 'PerformanceSet',
        meta: {
            keepAlive: true,
            title: "业绩设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "PerformanceSet"
        }
    },
    {
        path: "/pointSetting/prize",
        component: PointSettingPrize,
        name: 'PointSettingPrize',
        meta: {
            keepAlive: true,
            title: "奖品管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "PointSettingPrize"
        }
    },
    {
        path: "/pointSetting/settlement",
        component: PointSettingSettleMent,
        name: 'PointSettingSettleMent',
        meta: {
            keepAlive: true,
            title: "结算管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "PointSettingSettleMent"
        }
    },
    {
        path: "/pointSetting/point",
        component: PointSettingPoint,
        name: 'PointSettingPoint',
        meta: {
            keepAlive: true,
            title: "积分管理_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "PointSettingPoint"
        }
    },
    {
        path: "/resumeMerge",
        component: ResumeMerge,
        name: 'ResumeMerge',
        meta: {
            keepAlive: true,
            title: "简历设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "ResumeMerge"
        }
    },
    {
        path: "/resumeMergeLog",
        component: ResumeMergeLog,
        name: 'ResumeMergeLog',
        meta: {
            keepAlive: true,
            title: "简历合并日志_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "ResumeMergeLog"
        }
    },
    {
        path: "/jdtSet",
        component: JdtSet,
        name: 'JdtSet',
        meta: {
            keepAlive: true,
            title: "职通车设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "JdtSet"
        }
    },
    // 大厂机会审批
    {
        path: "/auditOpportunity",
        component: AuditOpportunity,
        name: 'AuditOpportunity',
        meta: {
            keepAlive: true,
            title: "大厂机会审批_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "AuditOpportunity"
        }
    },
    // offer播报
    {
        path: "/offerBroadcast",
        component: OfferBroadcast,
        name: 'OfferBroadcast',
        meta: {
            keepAlive: true,
            title: "Offer播报_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "OfferBroadcast"
        }
    },
    {
        path: "/jobSet",
        component: JobSet,
        name: 'JobSet',
        meta: {
            keepAlive: true,
            title: "职位类别设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "JobSet"
        }
    },
    // {
    //     path: "/orgAuditSet",
    //     component: OrgAuditSet,
    //     name: 'OrgAuditSet',
    //     meta: {
    //         keepAlive: true,
    //         title: "用户权限设置_猎必得_最有效的O2O猎头招聘平台",
    //         pageCode: "OrgAuditSet"
    //     }
    // },
    {
        path: "/membersRoleSet",
        component: MembersRoleSet,
        name: 'MembersRoleSet',
        meta: {
            keepAlive: true,
            title: "成员角色设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "MembersRoleSet"
        }
    },
    {
        path: "/rolePermissionSet",
        component: RolePermissionSet,
        name: 'RolePermissionSet',
        meta: {
            keepAlive: true,
            title: "角色权限设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "RolePermissionSet"
        }
    },
    {
        path: "/businessClassification",
        component: BusinessClassificationSet,
        name: 'BusinessClassificationSet',
        meta: {
            keepAlive: true,
            title: "业务分类设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "BusinessClassificationSet"
        }
    },
    {
        path: "/enterpriseWechatSet",
        component: EnterpriseWechatSet,
        name: 'EnterpriseWechatSet',
        meta: {
            keepAlive: true,
            title: "企业微信设置_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "EnterpriseWechatSet"
        }
    },
    {
        path: "/departmentApplyList",
        component: DepartmentApplyList,
        name: 'DepartmentApplyList',
        meta: {
            keepAlive: true,
            title: "我的申请_组织架构_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "DepartmentApplyList"
        }
    },
    {
        path: "/departmentAuditList",
        component: DepartmentAuditList,
        name: 'DepartmentAuditList',
        meta: {
            keepAlive: true,
            title: "我的审批_组织架构_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "DepartmentAuditList"
        }
    },
    {
        path: '/member',
        component: Member,
        name:'Member',
        meta: {
            title: '成员_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'MyCompanyMember'
        }
    },
    {
        path: '/performancesDetail',
        component: PerformancesDetail,
        meta: {
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'MyCompanyPerformancesDetail'
        }
    },
    {
        path: '/teamPoints',
        component: TeamPoints,
        name: 'TeamPoints',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'TeamPoints'
        }
    },
    {
        path: '/weekReport',
        component: WeekReport,
        name: 'WeekReport',
        meta: {
            keepAlive: true,
            title: '推荐面试情况_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'WeekReport'
        }
    },
    {
        path: '/deliveryTarget',
        component: DeliveryTarget,
        name: 'DeliveryTarget',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'DeliveryTarget'
        }
    },
    {
        path: '/deliveryTargetDetail',
        component: DeliveryTargetDetail,
        name: 'DeliveryTargetDetail',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'DeliveryTargetDetail'
        }
    },
    {
        path: '/trackingListShare',
        component: OfferReport,
        meta: {
            title: 'TrackingList分享_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'MyCompanyTrackingListShare'
        }
    },
    {
        path:'/offerReport',
        name:'offerReport',
        component: OfferReport,
        meta:{
            title:'业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'OfferReport'
        },
    },
    {
        path:'/jdtReport',
        name:'jdtReport',
        component: JdtReport,
        meta:{
            title:'职通车_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'JdtReport'
        },
    },
    {
        path: '/leaderFeedbackData',
        component: LeaderFeedbackData,
        name: 'LeaderFeedbackData',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'LeaderFeedbackData'
        }
    },
    {
        path: '/businessLineData',
        component: BusinessLineData,
        name: 'BusinessLineData',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'BusinessLineData'
        }
    },
    {
        path: '/evaluateCountReport',
        component: EvaluateCountReport,
        name: 'EvaluateCountReport',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'EvaluateCountReport'
        }
    },
    {
        path: '/personalEvaluateCountReport',
        component: PersonalEvaluateCountReport,
        name: 'PersonalEvaluateCountReport',
        meta: {
            keepAlive: true,
            title: '业绩_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'PersonalEvaluateCountReport'
        }
    },
    {
        path: '/trackingListComment',
        component: TrackingListComment,
        name: 'TrackingListComment',
        meta: {
            keepAlive: true,
            title: '周报_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'TrackingListComment'
        }
    },
    // 职通车
    {
        path: '/jdt',
        component: Jdt,
        name: 'Jdt',
        meta: {
            keepAlive: true,
            title: '职通车_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'Jdt'
        }
    },
    // 大厂机会
    {
        path: '/corporationOpportunity',
        component: CorporationOpportunity,
        name: 'CorporationOpportunity',
        meta: {
            keepAlive: true,
            title: '大厂机会_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'CorporationOpportunity'
        }
    },
    {
        path: '/opportunityJobDetail/:id',
        component: OpportunityJobDtail,
        name: 'OpportunityJobDtail',
        meta: {
            keepAlive: true,
            title: '大厂机会职位详情_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'OpportunityJobDtail'
        }
    },
    {
        path: '/opportunityResumeDetail/:id',
        component: opportunityResumeDetail,
        name: 'opportunityResumeDetail',
        meta: {
            keepAlive: true,
            title: '大厂机会简历详情_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'opportunityResumeDetail'
        }
    },
    {
        path: '/personalCenter',
        component: personalCenter,
        meta: {
            title: '个人中心_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'PersonalCenter'
        }
    },
    {
        path:'/candidates',
        component: myCompanyCandidates,
        name: 'FirmCandidate',
        meta:{
            title:'人才库_猎必得_最有效的O2O猎头招聘平台'
        },
        children:[
            {
                path: 'firmCandidate',
                name: 'FirmCandidate',
                meta:{
                    title:'企业人才库_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'FirmCandidates'
                },
                component: FirmCandidates
            },
            {
                path:'personal',
                name: 'PersonalCandidate',
                meta:{
                    title:'我的人才库_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'PersonalCandidate'
                },
                component: personalCandidate
            },
            {
                path:'namelist',
                meta:{
                    title:'简易人才库_猎必得_最有效的O2O猎头招聘平台'
                },
                component: namelistCandidate
            },
            {
                path:'linkedin',
                name: 'LinkedinCandidate',
                meta:{
                    title:'领英人才库_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'LinkedinCandidate'
                },
                component: linkedinCandidate
            },
            // {
            //     path:'enterprise',
            //     name: 'EnterpriseCandidate',
            //     meta:{
            //         title:'企业人才库_猎必得_最有效的O2O猎头招聘平台',
            //         pageCode: 'EnterpriseCandidate'
            //     },
            //     component: enterpriseCandidate
            // }
            {
                path:'enterprise',
                redirect: '/candidatesfirmCandidate'
            }
        ]
    },
    {
        path: '/candidateDetail/:resumeId',
        component: candidateDetail,
        meta: {
            title: '猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'candidateDetail'
        },
        children: [
            {
                path: 'communicationList',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
            {
                path: 'recommendationList',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
            {
                path: 'comments',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
            {
                path: 'timeline',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
            {
                path: 'recommendation/:recommendationid',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
            {
                path: 'attachments',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'candidateDetail'
                },
                component: null
            },
        ]
    },
    {
        path: '/batchCandidateDetail',
        component: batchCandidateDetail,
        meta: {
            title: '批量候选人_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'batchCandidateDetail'
        },
    },
    {
        path: '/batchOpportunityResumeDetail',
        component: batchOpportunityResumeDetail,
        meta: {
            title: '批量简历_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'batchOpportunityResumeDetail'
        },
    },
    {
        path:'/linkedinCandidateDetail/:resumeId',
        component: linkedinCandidateDetail,
        meta:{
            title:'猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'LinkedinCandidateDetail'
        }
    },
    {
        path: '/resumeUpload',
        component: ResumeUpload,
        meta: {
            title: '上传简历',
            pageCode: 'ResumeUpload'
        },
        children:[
            {
                path:'singleUpload',
                meta:{
                    title:'单份上传简历_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'SingleResumeUpload'
                },
                component:SingleUpload
            },
            {
                path:'batchUpload',
                meta:{
                    title:'批量上传简历_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'BatchResumeUpload'
                },
                component:MultipleUpload,
                props: {
                    uploadType: 1
                }
            },
            {
                path:'excelUpload',
                meta:{
                    title:'Excel上传简历_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'ExcelUpload'
                },
                component:MultipleUpload,
                props: {
                    uploadType: 0
                }
            }
        ]
    },
    {
        path:'/resumeUploadResult/:id',
        name:'resumeUploadResult',
        component: ResumeUploadResult,
        meta:{
            title:'上传结果_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'ResumeUploadResult'
        },
        props: (route) => ({uploadType: route.query.uploadType})
    },
    {
        path:'/recommendation/:recommendationid',
        component:cloudRecommendation,
        meta:{
            title:'云端人才_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'cloudRecommendation'
        },
        children: [
            {
                path: 'recommendationList',
                component: null,
                meta:{
                    title:'云端人才_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'cloudRecommendation'
                }
            },
            {
                path: 'comments',
                component: null,
                meta:{
                    title:'云端人才_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'cloudRecommendation'
                }
            },
        ]
    },
    {   
        path: '/candidate/updatecheck',
        component: CandidateUpdatecheck,
        meta: {
            title: '更新确认_添加候选人_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'CandidateUpdatecheck'
        }
    },
    {   
        path: '/candidate/cocUpdate',
        component: CocUpdate,
        meta: {
            title: '大厂简历对比_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'CocUpdate'
        }
    },
    {
        path:'/jobList',
        component: JobList,
        name:'JobListWrapper',
        meta:{
            title:'职位搜索_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'JobList'
        },
    },
    {
        path:'/protocolJobList',
        component: ProtocolJobList,
        name:'ProtocolJobListWrapper',
        meta:{
            title:'协议职位_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'ProtocolJobList'
        },
    },
    {
        path: '/job/:jobId',
        component: JobDetail,
        meta: {
            title: '猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobDetail'
        },
        children: [
            {
                path: 'recommendationList',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'JobDetail'
                },
                component: null
            },
            {
                path: 'recommendation/:recommendationid',
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'JobDetail'
                },
                component: null
            },
        ]
    },
    {
        path: '/jobCreate/copy',
        component: JobCreateCopy,
        meta: {
            title: '复制创建职位_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobCreateCopy'
        }
    },
    {
        path: '/jobCreate/single',
        component: JobCreateSingle,
        meta: {
            title: '创建职位_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobCreateSingle'
        }
    },
    {
        path: '/jobCreate/single-success',
        component: JobCreateSingleSuccess,
        meta: {
            title: '创建成功_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobCreateSingleSuccess'
        }
    },
    {
        path: '/jobCreate/multi',
        component: JobCreateMulti,
        meta: {
            title: '批量创建职位_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobCreateMulti'
        }
    },
    {
        path: "/project",
        component: Project,
        name: 'ProjectList',
        meta: {
            title: "项目_猎必得_最有效的O2O猎头招聘平台",
            pageCode: "Project"
        }
    },
    // 项目详情页面
    {
        path: '/projectDetail/:projectId',
        component: ProjectDetail,
        name: 'ProjectDetail',
        meta: {
            keepAlive: true,
            title: '项目详情_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'ProjectDetail'
        }
    },
    // 我的交付目标
    {
        path: '/myGoal',
        component: MyGoal,
        name: 'MyGoal',
        meta: {
            keepAlive: true,
            title: '我的交付目标_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'MyGoal'
        }
    },
    {
        path:'/recommendDetail',
        name:'recommendDetail',
        component: RecommendDetail,
        meta:{
            title:'推荐候选人_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'RecommendDetail'
        },
    },
    {
        path:'/resultRecommend',
        component:resultRecommend,
        meta:{
            title: '推荐结果_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'ResultRecommend'
        }
    },
    {
        path:'/market',
        name:'market',
        component: Market,
        meta:{
            title:'市场_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'Market'
        },
    },
    {
        path: '/companySetting',
        component: CompanySetting,
        name: 'CompanySetting',
        meta: {
            title: '资料_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'CompanySetting'
        }
    },
    {
        path: '/authorization',
        component: Authorization,
        meta: {
            title: '授权管理_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'Authorization'
        }
    },
    {
        path: '/operationPlatform',
        component: OperationPlatform,
        name:'OperationPlatform',
        meta: {
            // keepAlive: true,
            title: '运营平台_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'OperationPlatform'
        }
    },
    {
        path: '/operationPlatform/operationData',
        component: OperationData,
        name:'OperationData',
        meta: {
            // keepAlive: true,
            title: '运营数据_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'OperationData'
        }
    },
    {
        path: '/hrFirm',
        component: HrFirm,
        name: 'HrFirm',
        meta: {
            title: '企业介绍_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'HrFirm'
        }
    },
    {
        path:'/customerList',
        name:'CustomerWrapperList',
        component: CustomerList,
        meta:{
            title:'客户_猎必得_最有效的O2O猎头招聘平台',
            pageCode:'CustomerList'
        },
    },
    {
        path: '/Customer/:customerId',
        component: CustomerDetail,
        children: [
            {
                path: '',
                component: CustomerJobList,
                meta: {
                    title: '客户_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'CustomerJobList'
                },
                props: true
            },
            {
                path: 'department',
                component: CustomerDepartment,
                meta: {
                    title: '组织架构_猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'CustomerDepartment'
                },
                props: true
            }
        ]
    },
    {
        path: '/addCustomerRank',
        component: AddCustomersRank,
        meta: {
            title: '全部客户职级_猎必得_最有效的O2O猎头招聘平台'
        }
    },
    {
        path: '/Customer/:customerId/department/:id',
        component: DepartmentDetail,
        meta: {
            title: '组织架构_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'DepartmentDetail'
        }
    },
    {
        path: '/batchCreateDepartment/:customerId',
        component: BatchCreateDepartment,
        name: 'BatchCreateDepartment',
        meta: {
            keepAlive: true,
            title: '批量编辑组织架构_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'BatchCreateDepartment'
        }
    },
    {
        path: '/applyFormPage',
        component: ApplyFormPage,
        name: 'ApplyFormPage',
        meta: {
            title: '申请开票_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'ApplyFormPage'
        }
    },
    {   
        path: '/jobCreate/multi/publishResult',
        component: JobMultiPublishResult,
        meta: {
            title: '批量创建职位_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'JobMultiPublishResult'
        }
    },
    {
        path: '/sendEmail',
        component: sendEmail,
        meta: {
            title: '发送邮件_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'SendEmail'
        }
    },
    {
        path: '/tsdn',
        component: TSDN,
        meta: {
            title: 'TSDN_猎必得_最有效的O2O猎头招聘平台',
            pageCode: 'TSDN'
        }
    },
    {
        path: '/user/:userId',
        component: UserHomepage,
        children: [
            {
                path: '',
                component: UserHomepageIndex,
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'UserHomepageIndex'
                }
            },
            {
                path: 'jobs',
                component: UserHomepageJobs,
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'UserHomepageJobs'
                }
            },
            {
                path: 'candidates',
                component: UserHomepageCandidates,
                meta: {
                    title: '猎必得_最有效的O2O猎头招聘平台',
                    pageCode: 'UserHomepageCandidates'
                }
            },
            // {
            //     path: 'cloudCandidates',
            //     component: UserHomepageCloudCandidates,
            //     meta: {
            //         title: '猎必得_最有效的O2O猎头招聘平台',
            //         pageCode: 'UserHomepageCloudCandidates'
            //     }
            // }
        ]
    },
];

const router = new VueRouter({
    routes
});

router.afterEach((to, from) => {
    if (to.meta.title) {
        let firmName = store.state.user.userInfo.firmShortName ? '_' + store.state.user.userInfo.firmShortName + '_' : '_';

        if (to.meta.title.indexOf('我的申请_组织架构') == 0 || to.meta.title.indexOf('我的审批_组织架构') == 0) {
            document.title = to.meta.title.replace(/\_/, '*').replace(/\_/, firmName).replace(/\*/, '_');
        } else {
            document.title = to.meta.title.replace(/\_/, firmName);
        }
    }
    window._pageViewId = _generateUUID();

    if (_tracker) {
        if(store.state.user.userInfo && store.state.user.userInfo.userName) {
            _tracker.trackPageView({
                Title: document.title || '猎必得',
                PreviousPageCode: (from && from.meta.pageCode) || 'NULL',
                PreviousTriggerEventCode: 'NULL',
                PageData: JSON.stringify({})
            });
        }else {
            window.mainVm && window.mainVm.$watch('$store.state.user.userInfo', function(newVal, oldVal) {
                if(newVal && newVal.userName) {
                    if(newVal.firmShortName && document.title.indexOf(newVal.firmShortName) == -1) {
                        document.title = document.title.replace(/\_/, '_' + newVal.firmShortName + '_');
                    }
                    _tracker.trackPageView({
                        Title: document.title || '猎必得',
                        PreviousPageCode: (from && from.meta.pageCode) || 'NULL',
                        PreviousTriggerEventCode: 'NULL',
                        PageData: JSON.stringify({})
                    });
                }
            })
        }
    }
    setTimeout(() => {
        if(to.name !== 'WorkTableHome'){
            to.name && store.commit('keepAlive/keepAlive', to.name);
        }

    }, 1000);
});

router.beforeEach((to, from, next) => {
    if (to.meta.pageCode == 'JobDetail') {
        window.location.href = location.origin + '/Headhunting/MyCompany.html#' + to.path;
    }
    
    if (to.meta.pageCode == 'candidateDetail' || to.meta.pageCode == 'batchCandidateDetail'
        || to.meta.pageCode == 'BatchCreateDepartment'
        || to.meta.pageCode == 'SingleResumeUpload'
        || to.meta.pageCode == 'BatchResumeUpload'
        || to.meta.pageCode == 'ExcelUpload'
        || to.meta.pageCode == 'ResumeUploadResult'
        || to.meta.pageCode == 'CandidateUpdatecheck'
        || to.meta.pageCode == 'cloudRecommendation'
        || to.meta.pageCode == 'RecommendDetail'
        || to.meta.pageCode == 'ResultRecommend'
        || to.meta.pageCode == 'MyCompanyPerformancesDetail'
        || to.meta.pageCode == 'TeamPoints'
        || to.meta.pageCode == 'WeekReport'
        || to.meta.pageCode == 'DeliveryTarget'
        || to.meta.pageCode == 'DeliveryTargetDetail'
        || to.meta.pageCode == 'MyCompanyTrackingListShare'
        || to.meta.pageCode == 'OfferReport'
        || to.meta.pageCode == 'JdtReport'
        || to.meta.pageCode == 'LeaderFeedbackData'
        || to.meta.pageCode == 'BusinessLineData'
        || to.meta.pageCode == 'EvaluateCountReport'
        || to.meta.pageCode == 'PersonalEvaluateCountReport'
        || to.meta.pageCode == 'TrackingListComment'
        || to.meta.pageCode == 'CustomerJobList'
        || to.meta.pageCode == 'CustomerDepartment'
        || to.meta.pageCode == 'ProjectDetail'
        || to.meta.pageCode == 'OpportunityJobDtail'
        || to.meta.pageCode == 'opportunityResumeDetail'
    ) {
        window.location.href = location.origin + '/Headhunting/MyCompany.html#' + to.fullPath;
    }


    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    window._pageCode = to.meta.pageCode || 'Index';
    _tracker && _tracker.setPageCode(window._pageCode);
    if(from.path == '/'){
        to.name && store.commit('keepAlive/noKeepAlive', to.name);
        if(to.name == 'FloatingListMyRecommend'){
            store.commit('keepAlive/noKeepAlive', 'FloatingListRecommendToMe');
        }
        next();
    }

    // 路由守卫
    // console.log("11111"+from.path);
    // console.log("请求"+to.path);
    // console.log(store.state.user)
    // 管理员
    const isAdministrator = store.state.user.userInfo.isAdministrator;
    const isAdminOrTeamLeader = store.state.user.userInfo.isAdminOrTeamLeader;

    //非CF不能查看业绩设置页面
    const isCFUser = store.state.user.userInfo.isCFUser;
    // if (!isCFUser && to.path == '/performanceSet') {
    //     shortTips("权限不足，请联系管理员")
    //     sessionStorage.setItem('tabItems', JSON.stringify([]));
    //     next('/')
    // }
    
    // 权限列表
    const userPrivilegeCodeList = store.state.user.userInfo.privilegeCodeList;
    // console.log(userPrivilegeCodeList)
    let isBusinessPermission; // 业务
    let isDataPermission; // 数据
    let isTeamDataPermission; // 团队数据
    let isMemberPermission; // 成员
    let isFinancialPermission; //财务
    let isHuntingCompanySetPermission; // 猎企设置
    let isPlatformOperations; // 平台运营

    if(userPrivilegeCodeList){
        // isBusinessPermission = userPrivilegeCodeList.includes('Business');
        isDataPermission = userPrivilegeCodeList.includes('Data');
        isTeamDataPermission = userPrivilegeCodeList.includes('TeamData');
        isMemberPermission = userPrivilegeCodeList.includes('Member');
        isFinancialPermission = userPrivilegeCodeList.includes('Financial');
        // isHuntingCompanySetPermission = userPrivilegeCodeList.includes('HuntingCompanySet');
        isPlatformOperations = userPrivilegeCodeList.includes('PlatformOperations');

        isBusinessPermission = userPrivilegeCodeList.includes('Project') || 
            userPrivilegeCodeList.includes('Talent') || 
            userPrivilegeCodeList.includes('Market') || 
            userPrivilegeCodeList.includes('Customer') || 
            userPrivilegeCodeList.includes('Job') || 
            userPrivilegeCodeList.includes('AgreementJob') || 
            userPrivilegeCodeList.includes('CompanyOpportunity') || 
            userPrivilegeCodeList.includes('AgreementJobManage');
            
        // 业务：职位，客户，人才，市场，职通车，项目，学院
        if(isAdministrator || isBusinessPermission){
            next()
        } else {
            if(
            //     to.path == '/myOrders/grabbedJob' || to.path == '/myOrders/createdJob' || to.path == '/myOrders/sharedJob' || 
            //    to.path == '/trackingList' || 
            //    to.path == '/talentPool' || 
               to.path == '/departmentApplyList' || to.path == '/departmentAuditList' ||
               to.path == '/jobList' || to.path == `/Job/${to.params.jobId}`){
                shortTips("权限不足，请联系管理员 ")
                sessionStorage.setItem('tabItems', JSON.stringify([]));
                next('/')
            }
        }
        // 数据
        // if(isAdministrator || isDataPermission || ((to.path == '/data' || to.path == '/teamData') && isTeamDataPermission)){
        if((to.path == '/data' && (isAdministrator || isAdminOrTeamLeader || userPrivilegeCodeList.includes('PersonalData'))) ||
        (to.path == '/teamData' && (isAdministrator || isAdminOrTeamLeader || userPrivilegeCodeList.includes('TeamData') || userPrivilegeCodeList.includes('LimitedTeamData'))) ||
        (to.path == '/typesData' && (isAdministrator || isAdminOrTeamLeader || userPrivilegeCodeList.includes('TrackData')))
        ) {
            next()
        } else {
            if(to.path == '/data' || to.path == '/teamData' || to.path == '/typesData' || to.path == '/jdtData'){
                shortTips("权限不足，请联系管理员 ")
                sessionStorage.setItem('tabItems', JSON.stringify([]));
                next('/')
            }
        }
        // 成员
        if(isAdministrator || isMemberPermission){
            next()
        } else {
            if(to.path == '/member'){
                shortTips("权限不足，请联系管理员 ")
                next('/')
            }
        }
        // 财务
        if(isAdministrator || isFinancialPermission || isBusinessPermission){
            next()
        } else {
            if(to.path == '/floatingList'|| to.path == '/floatingList/myRecommend' || to.path == '/floatingList/unApply' || to.path == '/floatingList/apply'){
                shortTips("权限不足，请联系管理员 ")
                sessionStorage.setItem('tabItems', JSON.stringify([]));
                next('/')
            }
        }


        // ['PerformanceSet', 'PointSet', 'ResumeSet','AuthorizationSet', 'CompanyOpportunityAuditSet', 'OfferBroadcastSet', 'JobCategorySet', 'UserPermissionSet', 'BusinessClassifySet', 'FirmSet', 'WeComSet',


        isHuntingCompanySetPermission = userPrivilegeCodeList.includes('PerformanceSet') || 
            userPrivilegeCodeList.includes('PointSet') || 
            userPrivilegeCodeList.includes('ResumeSet') || 
            userPrivilegeCodeList.includes('AuthorizationSet') || 
            userPrivilegeCodeList.includes('CompanyOpportunityAuditSet') || 
            userPrivilegeCodeList.includes('OfferBroadcastSet') || 
            userPrivilegeCodeList.includes('JobCategorySet') || 
            userPrivilegeCodeList.includes('UserPermissionSet') || 
            userPrivilegeCodeList.includes('BusinessClassifySet') || 
            userPrivilegeCodeList.includes('FirmSet') || 
            userPrivilegeCodeList.includes('WeComSet');

        // 猎企设置
        if(isAdministrator || isHuntingCompanySetPermission){
            next();
        } else {
            if((to.path == '/performanceSet' && (!userPrivilegeCodeList.includes('PerformanceSet') || !isCFUser)) || 
               ((to.path == '/pointSetting/prize' || to.path == '/pointSetting/settlement' || to.path == '/pointSetting/point') && (!userPrivilegeCodeList.includes('PointSet') || !isCFUser)) || 
               (to.path == '/resumeMerge' && (!userPrivilegeCodeList.includes('ResumeSet') || !isCFUser)) ||
               (to.path == '/authorization' && (!userPrivilegeCodeList.includes('AuthorizationSet') || !isCFUser)) ||
               (to.path == '/auditOpportunity' && (!userPrivilegeCodeList.includes('CompanyOpportunityAuditSet') || !isCFUser)) ||
               (to.path == '/offerBroadcast' && (!userPrivilegeCodeList.includes('OfferBroadcastSet') || !isCFUser)) ||
               (to.path == '/jobSet' && !userPrivilegeCodeList.includes('JobCategorySet')) || 
            //    to.path == '/jdtSet' ||
                ((to.path == '/membersRoleSet' || to.path == '/rolePermissionSet') && !userPrivilegeCodeList.includes('UserPermissionSet'))
            ) {
                    shortTips("权限不足，请联系管理员")
                    sessionStorage.setItem('tabItems', JSON.stringify([]));
                    next('/')
            }
        }

        // 平台运营权限
        if(['/operationPlatform', '/operationPlatform/operationData'].includes(to.path)) {
            if(isPlatformOperations) {
                next();
            } else {
                shortTips("权限不足，请联系管理员 ");
                next('/')
            }
        }
    }
});

window.mainVm = new Vue({
    router,
    store,
    render: h => h(WorkTableApp)
}).$mount("#app");
