<template>
    <div class="dialog" :class="isShow ? 'show' : 'hide'">
        <div class="screen"></div>

        <div class="dialog-container">
            <div>
                <header>
                    <div class="table-center header">
                        <div>系统已经帮你找到<span class="color-ori">{{ listData.length}}</span>位</div>
                        <div>高质量候选人，快来试试吧</div>
                    </div>
                </header>
                <div class="icon-new icon-close" @click="hide">

                </div>
            </div>

            <div class="candidate-list">
                <el-container
                    class="candidate-item"
                    v-for="(item,index) in listData"
                    :key="index">

                    <el-main>
                        <el-row class="first-info">
                            <span class="user-info-name">{{ item.candidateName }}</span>
                            <span class="user-info-jobTitle">{{ item.title }}</span>
                            <el-button
                                v-if="!item.isTracking"
                                type="primary"
                                @click="handleAddTrackingList(item)"
                                round
                                plain>
                                <i class="el-icon-plus"></i>
                                加入Tracking List
                            </el-button>
                            <el-button
                                v-if="item.isTracking"
                                type="primary"
                                @click="handleViewTrackingList(item)"
                                round
                                plain>
                                <i class="el-icon-check"></i>
                                查看Tracking List
                            </el-button>
                        </el-row>

                        <el-row>
                            <span class="user-info-location">{{ item.city }}</span> |
                            <span class="user-info-company text-ellipsis">{{ item.company }}</span>
                        </el-row>

                        <el-row>
                            <span v-if="item.school" class="user-info-school">{{ item.school }} |</span>
                            <span v-if="item.degree" class="user-info-title">{{ item.degree }} |</span>
                            <span v-if="item.yearOfExperience" class="user-info-exp">{{ item.yearOfExperience }}年</span>
                        </el-row>
                    </el-main>
                    <el-aside width="278px">
                        <el-row class="user-info-updatetime">
                            <span>最近更新：{{item.updated | momentFormatDate}}</span>
                        </el-row>

                        <el-row class="text-right" v-if="item.operationStatus !== 2 && item.operationStatus !== 3">
                            <el-button @click="unSuitable(item)">暂不符合</el-button>
                            <el-button
                                type="primary"
                                @click="toView(item)"
                                v-if="item.operationStatus === 0">
                                立即看看
                            </el-button>

                            <el-button
                                class="el-button--surprise"
                                v-if="item.operationStatus === 4"
                                @click="toView(item)">
                                立即查看，有惊喜
                            </el-button>

                            <el-button
                                class="el-button--warning"
                                @click="recommend(item)"
                                v-if="item.operationStatus === 1">
                                推荐试试
                            </el-button>
                        </el-row>

                        <el-row class="text-right" v-else>
                            <span class="recommend icon icon-resolve" v-if="item.operationStatus === 2">已推荐</span>
                            <span class="unsuitable" v-if="item.operationStatus === 3">暂不符合</span>
                        </el-row>
                    </el-aside>
                </el-container>
            </div>
        </div>
    </div>
</template>

<script>
    import AIRecommendService from '#/js/service/AIRecommendService';
    import talentPoolService from '@src/js/service/talentPoolService.js';
    import moment from 'moment';

    export default {
        name: "landing-dialog",
        data() {
            return {
                isShow: false,
                listData: [],
                candidateIds: [],
                trackType : false,
                _tracker : {},
            }
        },
        created() {
            this.init();
            window.landingdialog = this;
        },
        methods: {
            async init() {
                let path = location.hash;
                if(path.indexOf('/candidates/firmCandidate') == -1 && path.indexOf('/candidates/enterprise') == -1 && path.indexOf('/candidates/personal') == -1 && path.indexOf('/candidates/linkedin') == -1) {
                    return false;
                }

                let date = moment(new Date());
                let id = this.$store.state.user.userInfo.id ? this.$store.state.user.userInfo.id : $('input[name="userId"]').val();
                let isCFUser = this.$store.state.user.userInfo.isCFUser ? this.$store.state.user.userInfo.isCFUser : $('input[name="isCFUser"]').val();

                if(!isCFUser){
                    return false;
                }

                if(localStorage.getItem(`${date.format('YYYY_MM_DD')}_${id}_landing_dialog`)){
                    return false;
                }

                this.trackType = window._tracker ? true : false;
                this._tracker = window._tracker ? _tracker : lbdTrack;

                await AIRecommendService
                    .getAIRecommend(0, 12)
                    .then((res) => {
                        this.listData = res.list;
                        if(this.listData.length > 0){
                            let _this = this;
                            _this.listData.forEach((candidate) => {
                                this.candidateIds.push(candidate.candidateId);
                            });
                            setTimeout(function(){
                                if(moment().hour() >= 10 ){
                                    const trackParams = {
                                        list_count: _this.listData.length,
                                        from: 'landing_page',
                                        candidateIds: _this.candidateIds,

                                    };
                                    _this.isShow = true;
                                    localStorage.setItem(`${date.format('YYYY_MM_DD')}_${id}_landing_dialog`, 'true');
                                    _this._tracker.track('landing_show', _this.trackType ? JSON.stringify(trackParams) : trackParams);
                                }
                            }, 3000);
                        } else {
                            this.isShow = false;
                        }
                    })
            },
            show() {
                const trackParams = {
                    list_count: this.listData.length,
                    from: 'landing_page',
                    candidateIds: this.candidateIds,
                };
                this._tracker.track('landing_show',this.trackType ? JSON.stringify(trackParams) : trackParams);
                return this.isShow = true;
            },
            hide() {
                return this.isShow = false;
            },
            async toView(item) {
                const trackParams = {
                    candidateId: item.candidateId,
                    resumeId: item.resumeId,
                    from: 'landing_page'
                };
                this._tracker.track(item.operationStatus === 4 ? 'landing_toViewCandidate' : 'landing_toViewCandidate_special', this.trackType ? JSON.stringify(trackParams) : trackParams);
                let tempWindow = window.open('_blank');
                return await AIRecommendService
                    .toView(item.candidateId)
                    .then(res => {
                        item.operationStatus = 1;
                        // tempWindow.location = `/Headhunting/MyCompany.html#/candidateDetail/${item.candidateId}`
                        tempWindow.location = `/#/candidateDetail/${item.candidateId}`
                    });
            },
            async unSuitable(item) {
                const trackParams = {
                    candidateId: item.candidateId,
                    resumeId: item.resumeId,
                    from: 'landing_page'
                };
                this._tracker.track('landing_unSuitableCandidate',this.trackType ? JSON.stringify(trackParams) : trackParams);
                return await AIRecommendService
                    .unSuitable(item.candidateId)
                    .then(res => {
                        item.operationStatus = 3;
                    })
            },
            recommend(item){
                const trackParams = {
                    candidateId: item.candidateId,
                    resumeId: item.resumeId,
                    from: 'landing_page'
                };
                this._tracker.track('landing_recommendCandidate',this.trackType ? JSON.stringify(trackParams) : trackParams);
                //window.open(`/Headhunting/MyCompany.html#/candidateDetail/${item.candidateId}`)
                let tempWindow = window.open('_blank');
                // tempWindow.location = `/Headhunting/MyCompany.html#/candidateDetail/${item.candidateId}`;
                tempWindow.location = `/#/candidateDetail/${item.candidateId}`;
            },
            handleAddTrackingList (item){
                const trackParams = {
                    candidateId: item.candidateId,
                    resumeId: item.resumeId,
                    from: 'landing_page'
                };

                talentPoolService.addToTrackingList({
                    sourceFrom: 9,
                    resumeIds: item.resumeId
                }).then(res => {
                    if(res.successCount === 1){
                        item.isTracking = true;
                        this._tracker.track('addTrackingList',this.trackType ? JSON.stringify(trackParams) : trackParams);
                        shortTips('候选人已加入Tracking List！');
                    } else {
                        shortTips('候选人加入Tracking List失败！');
                    }
                }).catch(err => {
                    shortTips('候选人加入Tracking List失败！');
                })

            },
            handleViewTrackingList (item){
                if(localStorage){
                    localStorage.setItem('trackingList_search_name', item.candidateName);
                    localStorage.setItem('trackingList_search_company', item.company);
                    localStorage.setItem('trackingList_search_title', item.title);
                    localStorage.setItem('trackingList_search_mobile', item.mobile);
                }
                return window.open(`/Headhunting/#/trackingList`);
            }
        },
        watch: {
            '$store.state.user.userInfo.isCFUser' : (value) => {
                console.log(value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~#/css/common';

    /*@font-face {*/
        /*font-family: 'HY_FONT';*/
        /*src: url("../../assets/fonts/landing_page_font_family.tff") format('tff');*/
    /*}*/

    .table-center{
        display: table;
        margin: auto;
    }

    .dialog {

        .screen {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000000, .5);
            z-index: 6010;
        }

        header {
            height: 125px;
            background-image: url('~@src/assets/images/summary/pic_title.png');
        }

        .header{
            padding-top: 17px;
            font-size: 36px;
            color: #fff;
            line-height: 43px;
            letter-spacing: 2.25px;
            text-shadow: 0 2px 2px #098265;

            .color-ori{
                color: #ff7700;
            }
        }

        .icon-close {
            position: absolute;
            top: 10px;
            right: 10px;
            margin-right: 0;
            cursor: pointer;
            color: #fff;
        }

        .dialog-container {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 920px;
            background-color: #fff;
            z-index: 6010;
            border-radius: 4px;
            overflow: hidden;
        }

        .candidate-list {
            padding: 16px;
            max-height: 435px;
            overflow-y: auto;
        }

        .candidate-item {
            margin-bottom: 10px;
            padding: 18px 24px;
            border: 1px solid #DDDDDD;
            transition: .3s;
            border-radius: 4px;

            &:hover {
                box-shadow: 0 2px 4px 0 rgba(#000, .3);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.disable {
                background-color: #DDDDDD;

                &:hover {
                    box-shadow: unset;
                }
            }
        }

        .first-info {
            margin-bottom: 13px;
            color: #444 !important;
        }

        .user-info-name {
            margin-right: 13px;
            font-size: 16px;
            text-shadow: 0 0 0;
        }

        .user-info-company {
            display: inline-block;
            vertical-align: bottom;
            max-width: 300px;
        }

        .user-info-updatetime {
            position: relative;
            text-align: right;
            margin-bottom: 27px !important;
            font-size: 12px;
            color: #666666;
        }

        .text-right{
            text-align: right;
        }

        .unsuitable{
            color: $primary;
            font-size: 16px;
        }

        .recommend{
            width: 90px;
            height: 24px;
            color: #F98F33;
            font-size: 16px;
        }

        .icon-resolve:before{
            content: '';
            display: inline-block;
            float: left;
            margin-right: 6px;
            width: 33px;
            height: 22px;
            background-image: url("../../assets/images/landing/pic_ytj.svg");
        }

        /deep/ {
            .el-main {
                padding: 0;
                overflow: hidden;
            }

            .el-row {
                margin-bottom: 7px;
                color: #666;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .el-button{
                font-size: 16px;

                &.is-round{
                    margin-left: 11px;
                    text-align: center;
                    min-width: 100px;
                    border: none;
                }
            }

            .el-button--default {
                &.el-button--surprise{
                    background: #FC7859;
                    color: #fff;
                    border-color: #FC7859;

                    &:hover, &:active {
                        background: #FA623F;
                    }
                }
            }
        }
    }
    .show{
        display: block;
    }
    .hide{
        display: none;
    }
</style>
