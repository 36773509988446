const state = {
    myResumeItaps: [],
    myJobItaps: []
}

const getters = {

}

const actions = {
    setMyResumeItaps({ commit, state }, itaps) {
        commit('updateMyResumeItaps', { itaps });
    },
    setMyJobItaps({ commit, state }, itaps) {
        commit('updateMyJobItaps', { itaps });
    }
}

const mutations = {
    updateMyResumeItaps(state, { itaps }) {
        itaps.forEach(item => {
            if(!item.tagDataList) {
                item.tagDataList = [];
            }
        })
        state.myResumeItaps = itaps;
    },
    updateMyJobItaps(state, { itaps }) {
        let myItaps = [];
        itaps.forEach((item) => {
            if(typeof item == 'object'){
                myItaps.push(item.tagName);
            }else if(typeof item == 'string'){
                myItaps.push(item);
            }
        });
        state.myJobItaps = myItaps;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
