export function convertSdkMsgToLbdMsg(message) {
    return {
        data: null,
        description: null,
        extension: null,
        fromAccountId: message.from,
        toAccountId: message.to,
        msgType: message.type,
        msgSeq: message.sequence,
        msgTime: message.time,
        isRevoked: message.isRevoked || false,
        msgContent: message.payload,
        msgKey: message.msgKey,
        conversationID: message.conversationID,
        openIMMsg: message.openIMMsg || null
    }
}

export function convertConversation (conversation) {
    let openIMLastMsg = conversation.latestMsg != '' ? JSON.parse(conversation.latestMsg) : null;
    let messageRemindType = TIM.TYPES.MSG_REMIND_ACPT_AND_NOTE;
    switch (conversation.recvMsgOpt) { 
        case openIM.OptType.Nomal:
            messageRemindType = TIM.TYPES.MSG_REMIND_ACPT_AND_NOTE;
            break;
        case openIM.OptType.Mute:
            messageRemindType = TIM.TYPES.MSG_REMIND_DISCARD;
            break;
        case openIM.OptType.WithoutNotify:
            messageRemindType = TIM.TYPES.MSG_REMIND_ACPT_NOT_NOTE;
            break;
        default:
            break;
    }       
    
    return {
        conversationID: conversation.conversationID.replace('group', 'GROUP').replace('single', 'C2C').replace('c2c', 'C2C'),
        openIMConversationID: conversation.conversationID,
        type: convertConversationType(conversation.conversationType),
        subType: '',
        unreadCount: conversation.unreadCount,
        openIMLastMsg: openIMLastMsg,
        lastMessage: openIMLastMsg ? {
            nick: openIMLastMsg.senderNickname,
            nameCard: '',
            lastTime: openIMLastMsg.sendTime / 1000,
            lastSequence: openIMLastMsg.seq,
            fromAccount: openIMLastMsg.sendID,
            fromAccoutName: openIMLastMsg.senderNickname,
            isRevoked: openIMLastMsg.contentType === 111 ? true : false,
            messageForShow: getMsgType(openIMLastMsg).content,
            type: getMsgType(openIMLastMsg).type,   
            payload: getMsgType(openIMLastMsg).payload
        } : {
            nick: '',
            nameCard: '',
            lastTime: '',
            lastSequence: '',
            fromAccount: '',
            isRevoked: false,
            messageForShow: '',
            type: '',
            payload: {}
        },
        groupProfile: {
            name: conversation.showName || '',
            memberNum: 0,
            groupID: conversation.groupID || '',
            selfInfo: {
                messageRemindType: messageRemindType
            },
            avatar: conversation.faceURL,
        },
        userProfile: {
            userID: conversation.userID,
            nick: conversation.showName || '',
            avatar: conversation.faceURL,
        },
        groupAtInfoList: [],
        remark: '',
        isPinned: false,
        messageRemindType: '',
        markList: [],
        customData: '',
        conversationGroupList: [],
        isNotInGroup: conversation.isNotInGroup || false
    }
}

export function convertGroup(group) {
    return {
        data: null,
        description: null,
        extension: null,
        fromAccountId: message.from,
        toAccountId: message.to,
        msgType: message.type,
        msgSeq: message.sequence,
        msgTime: message.time,
        isRevoked: message.isRevoked || false,
        msgContent: message.payload
    }
}

export function convertMessage (message) {
    let msgStatus = '';
    switch (message.status) { 
        case openIM.MessageStatus.Sending:
            msgStatus = 'unSend';
            break;
        case openIM.MessageStatus.Succeed:
            msgStatus = 'success';
            break;
        case openIM.MessageStatus.Failed:
            msgStatus = 'fail';
            break;
        default:
            break;
    }
    let conversationID = '';
    if (message.sessionType === openIM.SessionType.Single) { 
        conversationID = `C2C_${message.recvID}C2C_${message.sendID}`;
    }
    if (message.sessionType === openIM.SessionType.Group) { 
        conversationID = `GROUP_${message.groupID}`;
    }
    
    return {
        ID: message.clientMsgID,
        type: getMsgType(message).type,
        payload: getMsgType(message).payload || {},
        conversationID: conversationID,
        conversationType: convertConversationType(message.sessionType),
        to: message.recvID,
        from: message.sendID,
        status: msgStatus,
        isRevoked: message.contentType === 111,
        nick: message.senderNickname,
        avatar: message.senderFaceUrl,
        time: message.sendTime,
        msgKey: message.serverMsgID,
        openIMMsg: JSON.stringify(message)
    }
}

function convertConversationType (conversationType) { 
    switch (conversationType) { 
        case openIM.SessionType.Single:
            return TIM.TYPES.CONV_C2C;
        case openIM.SessionType.Group:
            return TIM.TYPES.CONV_GROUP;
        case openIM.SessionType.Notification:
            return TIM.TYPES.CONV_SYSTEM;
        default:
            return '';
    }
}

function getMsgType (openIMMsg) { 
    let content = '', type = '', payload = {};
    let groupTipDetail = (openIMMsg.notificationElem && openIMMsg.notificationElem.detail) ? JSON.parse(openIMMsg.notificationElem.detail) : null;
    switch (openIMMsg.contentType) { 
        case openIM.MessageType.TEXTMESSAGE:
            type = TIM.TYPES.MSG_TEXT;
            content = openIMMsg.content;
            payload = {
                text: openIMMsg.content
            };
            break;
        case openIM.MessageType.PICTUREMESSAGE:
            type = TIM.TYPES.MSG_IMAGE;
            content = '[图片]';
            payload = {
                uuid: openIMMsg.pictureElem.sourcePicture.uuid,
                imageFormat: 255,
                imageInfoArray: [openIMMsg.pictureElem.sourcePicture]
            };
            break;
        case openIM.MessageType.VOICEMESSAGE:
            type = TIM.TYPES.MSG_SOUND;
            content = '[语音]';
            payload = {
                url: openIMMsg.soundElem.sourceUrl,
                size: openIMMsg.soundElem.dataSize,
                second: Math.round(openIMMsg.soundElem.duration/1000),
            };
            break;
        case openIM.MessageType.VIDEOMESSAGE:
            type = TIM.TYPES.MSG_VIDEO;
            content = '[视频]';
            break;
        case openIM.MessageType.FILEMESSAGE:
            type = TIM.TYPES.MSG_FILE;
            content = '[文件]';
            payload = {
                uuid: openIMMsg.fileElem.uuid,
                fileName: openIMMsg.fileElem.fileName,
                fileUrl: openIMMsg.fileElem.sourceUrl,
                fileSize: openIMMsg.fileElem.fileSize
            };
            break;
        case openIM.MessageType.CUSTOMMESSAGE:
            type = TIM.TYPES.MSG_CUSTOM;
            content = '[自定义消息]';
            payload = {
                data: openIMMsg.customElem.data,
                description: openIMMsg.customElem.description,
                extension: openIMMsg.customElem.extension
            };
            break;
        case openIM.MessageType.NOTIFICATION:
            type = TIM.TYPES.MSG_GRP_SYS_NOTICE;
            content = '[通知消息]';
            break;
        case openIM.MessageType.GROUPCREATED:
            type = TIM.TYPES.MSG_TEXT;
            // type = TIM.TYPES.MSG_GRP_TIP;
            // payload = {
            //     operationType: TIM.TYPES.GRP_TIP_MBR_JOIN
            // },
            content = (openIMMsg.notificationElem?.defaultTips || '[群通知消息]');
            break;
        // case openIM.MessageType.GROUPINFOUPDATED:
            // type = TIM.TYPES.MSG_GRP_TIP;
            // payload = groupTipDetail ? {
            //     operationType: TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED,
            //     operatorID: groupTipDetail.opUser.operatorUserID,
            //     newGroupProfile: {
            //         name: 'xxx'
            //     }
            // } : null,
            // content = (openIMMsg.notificationElem?.defaultTips || '[群通知消息]');
            // break;
        // case openIM.MessageType.MEMBERQUIT:
            // type = TIM.TYPES.MSG_GRP_TIP;
            // payload = groupTipDetail ? {
            //     userIDList: groupTipDetail.kickedUserList.map(item => item.userID)
            // } : null,
            // content = '';
            // break;
        case openIM.MessageType.MEMBERINVITED:
            // type = TIM.TYPES.MSG_GRP_TIP;
            // payload = groupTipDetail ? {
            //     operationType: TIM.TYPES.GRP_TIP_MBR_JOIN,
            //     operatorID: groupTipDetail.opUser.operatorUserID,
            //     userIDList: groupTipDetail.invitedUserList ? groupTipDetail.invitedUserList.map(item => item.userID) : []
            // } : null,
            // content = '';
            // break;
        case openIM.MessageType.MEMBERKICKED:
            // type = TIM.TYPES.MSG_GRP_TIP;
            // payload = groupTipDetail ? {
            //     operationType: TIM.TYPES.GRP_TIP_MBR_KICKED_OUT,
            //     operatorID: groupTipDetail.opUser.operatorUserID,
            //     userIDList: groupTipDetail.kickedUserList.map(item => item.userID)
            // } : null,
            // content = (openIMMsg.notificationElem?.defaultTips || '[群通知消息]');
            // break;
        case openIM.MessageType.GROUPINFOUPDATED:
        case openIM.MessageType.MEMBERQUIT:
        case openIM.MessageType.GROUPOWNERTRANSFERRED:
        case openIM.MessageType.MEMBERENTER:
        case openIM.MessageType.GROUPDISMISSED:
        case openIM.MessageType.GROUPMEMBERMUTED:
        case openIM.MessageType.GROUPMEMBERCANCELMUTED:
        case openIM.MessageType.GROUPMUTED:
        case openIM.MessageType.GROUPCANCELMUTED:
        case openIM.MessageType.BURNMESSAGECHANGE:
            type = TIM.TYPES.MSG_GRP_TIP;
            payload = null;
            content = (openIMMsg.notificationElem?.defaultTips || '[群通知消息]');
            // content = '';
            break;
        default:
            type = TIM.TYPES.MSG_CUSTOM;
            content = openIMMsg.content;
            break;
    }
    return {
        type: type,
        content: content,
        payload: payload
    };
}