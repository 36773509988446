var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "500px",
        title: "请选择不通过审核的原因",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "rejectForm",
          staticClass: "reject-form",
          attrs: { model: _vm.formData, "label-width": "114px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原因", prop: "rejectResean" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.rejectResean,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "rejectResean", $$v)
                    },
                    expression: "formData.rejectResean",
                  },
                },
                _vm._l(_vm.reasonList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细说明", prop: "auditDetail" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formData.auditDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "auditDetail", $$v)
                  },
                  expression: "formData.auditDetail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.auditLoading,
                  expression: "auditLoading",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }