var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-item" },
    [
      _c(
        "el-checkbox",
        {
          attrs: { value: _vm.value, disabled: _vm.disabled },
          on: { change: (value) => _vm.handleMemberSelect(value, _vm.member) },
        },
        [
          _c("img", {
            attrs: {
              src: `${_vm.avatarBaseUrl}/${_vm.isGroup ? "group" : "user"}/${
                _vm.isGroup ? _vm.member.groupId : _vm.member.unionId
              }`,
            },
          }),
          _vm.isShare
            ? [
                _vm.type == "innerMemberList" || _vm.type == "outerMemberList"
                  ? _c("div", { staticClass: "member-text-info" }, [
                      _c("span", { staticClass: "member-name" }, [
                        _vm._v(_vm._s(_vm.member.userName)),
                      ]),
                      _c("span", { staticClass: "firm-name" }, [
                        _vm._v(
                          _vm._s(_vm.member.company) +
                            " | " +
                            _vm._s(_vm.member.title)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.type == "groupList"
                  ? _c("span", { staticClass: "member-name" }, [
                      _vm._v(_vm._s(_vm.member.groupName)),
                    ])
                  : _vm._e(),
                _vm.type == "conversationList" && _vm.isGroup
                  ? _c("span", { staticClass: "member-name" }, [
                      _vm._v(_vm._s(_vm.member.groupName)),
                    ])
                  : _vm._e(),
                _vm.type == "conversationList" && !_vm.isGroup
                  ? _c("div", { staticClass: "member-text-info" }, [
                      _c("span", { staticClass: "member-name" }, [
                        _vm._v(_vm._s(_vm.member.name)),
                      ]),
                      _vm.member.company || _vm.member.title
                        ? _c("span", { staticClass: "firm-name" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.member.company) +
                                " | " +
                                _vm._s(_vm.member.title) +
                                "\n                "
                            ),
                          ])
                        : _c("span", { staticClass: "firm-name" }, [
                            _vm._v(
                              "\n                    职通车候选人\n                "
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ]
            : [
                _vm.type == "teamMemberList" || _vm.type == "innerMemberList"
                  ? _c("span", { staticClass: "member-name" }, [
                      _vm._v(
                        _vm._s(_vm.member.realName) +
                          "@" +
                          _vm._s(_vm.member.nickname)
                      ),
                    ])
                  : _vm._e(),
                _vm.type == "outerMemberList"
                  ? _c("div", { staticClass: "member-text-info" }, [
                      _c("span", { staticClass: "member-name" }, [
                        _vm._v(
                          _vm._s(_vm.member.realName) +
                            "@" +
                            _vm._s(_vm.member.nickname)
                        ),
                      ]),
                      _c("span", { staticClass: "firm-name" }, [
                        _vm._v(_vm._s(_vm.member.firmShortName)),
                      ]),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }