var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "un-audit-customer-department-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.unAuditCustomerDepartmentdialogVisible,
            width: "748px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.unAuditCustomerDepartmentdialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "title-tip" }, [
                    _vm._v("\n                当前有 "),
                    _c("span", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.auditList?.length)),
                    ]),
                    _vm._v(" 条组织架构审批未完成 请先处理\n            "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.auditList, "tooltip-effect": "light" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      "show-overflow-tooltip": true,
                      "min-width": "150",
                      label: "部门名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerName",
                      "min-width": "150",
                      label: "客户名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creator",
                      "show-overflow-tooltip": true,
                      "min-width": "150",
                      label: "申请人",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "150", label: "申请时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.created
                                    ? scope.row.created.slice(0, 10)
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "approve-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApprove(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("审批")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("edit-department-dialog", {
        ref: "departmentDialog",
        on: {
          "audit-resolved": _vm.handleAuditResolved,
          "audit-reject": _vm.handleAuditReject,
        },
      }),
      _c("audit-reject-dialog", {
        ref: "auditRejectDialog",
        on: { "audit-reject-done": _vm.handleAuditResolved },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }