var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "556px",
        visible: _vm.dialogVisible,
        "show-close": !!_vm.isAudit,
        title: _vm.title,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        "custom-class": "edit-department-dialog",
      },
      on: { close: _vm.cancelHandle },
    },
    [
      _c(
        "el-form",
        {
          ref: "departmentForm",
          staticClass: "department-form",
          attrs: {
            model: _vm.departmentForm,
            rules: _vm.departmentFormRule,
            "label-width": "114px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户名称", prop: "customerId" } },
            [
              _c("customer-select", {
                ref: "customerSelect",
                attrs: {
                  placeholder: "输入客户名称关键词",
                  isDisabled:
                    (_vm.isAudit && !_vm.isAuditEdit) || _vm.isDisabledCustomer,
                },
                on: { customerSelected: _vm.handleCustomerSelected },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上级部门名称", prop: "parentDepartmentId" } },
            [
              _c("customer-department-select", {
                ref: "departmentSelect",
                attrs: {
                  customerId: _vm.departmentForm.customerId,
                  isDisabled:
                    (_vm.isAudit && !_vm.isAuditEdit) ||
                    !_vm.departmentForm.customerId,
                  departmentId: _vm.departmentForm.parentDepartmentId,
                },
                on: {
                  "update-department": _vm.updateParentdepartmentId,
                  "update-exist-departments": _vm.updateExistDepartments,
                },
              }),
              !(_vm.isAudit && !_vm.isAuditEdit)
                ? _c("span", { staticClass: "parent-department-tip" }, [
                    _vm._v(
                      "尽量输入完整的部门路径，多级部门之间用“，”符号分隔。"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部门名称", prop: "name" } },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    width: "384",
                    trigger: "manual",
                    disabled: _vm.existDepartmentList.length == 0,
                  },
                  model: {
                    value: _vm.existDepartmentVisible,
                    callback: function ($$v) {
                      _vm.existDepartmentVisible = $$v
                    },
                    expression: "existDepartmentVisible",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      slot: "reference",
                      disabled: _vm.isAudit && !_vm.isAuditEdit,
                      placeholder: "输入部门名称",
                      maxlength: 10,
                    },
                    on: {
                      focus: function ($event) {
                        _vm.existDepartmentVisible = !_vm.existDepartmentVisible
                      },
                      blur: function ($event) {
                        _vm.existDepartmentVisible = !_vm.existDepartmentVisible
                      },
                    },
                    slot: "reference",
                    model: {
                      value: _vm.departmentForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.departmentForm, "name", $$v)
                      },
                      expression: "departmentForm.name",
                    },
                  }),
                  _c("div", { staticClass: "exist-department-list" }, [
                    _c("p", [_vm._v("当前已存在以下部门")]),
                    _c(
                      "ul",
                      _vm._l(_vm.existDepartmentList, function (item, index) {
                        return _c("li", { key: index }, [_vm._v(_vm._s(item))])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部门人数", prop: "memberCount" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isAudit && !_vm.isAuditEdit,
                    placeholder: "选择部门人数",
                  },
                  model: {
                    value: _vm.departmentForm.memberCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.departmentForm, "memberCount", $$v)
                    },
                    expression: "departmentForm.memberCount",
                  },
                },
                _vm._l(_vm.memberCounts, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部门简介", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isAudit && !_vm.isAuditEdit,
                  type: "textarea",
                  placeholder: "请输入部门简介",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.departmentForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.departmentForm, "description", $$v)
                  },
                  expression: "departmentForm.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "业务简介", prop: "business" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isAudit && !_vm.isAuditEdit,
                  type: "textarea",
                  placeholder: "请输入业务简介",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.departmentForm.business,
                  callback: function ($$v) {
                    _vm.$set(_vm.departmentForm, "business", $$v)
                  },
                  expression: "departmentForm.business",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "岗位需求", prop: "requirement" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isAudit && !_vm.isAuditEdit,
                  type: "textarea",
                  placeholder: "请输入岗位需求",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.departmentForm.requirement,
                  callback: function ($$v) {
                    _vm.$set(_vm.departmentForm, "requirement", $$v)
                  },
                  expression: "departmentForm.requirement",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "面试流程", prop: "interviewProcess" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isAudit && !_vm.isAuditEdit,
                  type: "textarea",
                  placeholder: "请输入面试流程",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.departmentForm.interviewProcess,
                  callback: function ($$v) {
                    _vm.$set(_vm.departmentForm, "interviewProcess", $$v)
                  },
                  expression: "departmentForm.interviewProcess",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "薪资结构", prop: "salaryStructure" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isAudit && !_vm.isAuditEdit,
                  type: "textarea",
                  placeholder: "请输入薪资结构",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.departmentForm.salaryStructure,
                  callback: function ($$v) {
                    _vm.$set(_vm.departmentForm, "salaryStructure", $$v)
                  },
                  expression: "departmentForm.salaryStructure",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isAudit
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.auditEditHandle } }, [
                _vm._v("编辑"),
              ]),
              _c("el-button", { on: { click: _vm.rejectHandle } }, [
                _vm._v("不通过"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.auditLoading,
                      expression: "auditLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.resolveHandle },
                },
                [_vm._v("通 过")]
              ),
            ],
            1
          )
        : _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelHandle } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }