var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.currentConversation.conversationID,
          expression: "currentConversation.conversationID",
        },
      ],
      staticClass: "message-send-box-wrapper",
      style: _vm.focus ? { backgroundColor: "white" } : {},
    },
    [
      _c(
        "div",
        { staticClass: "message-operations" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "top",
                width: "400",
                trigger: "click",
                "popper-class": "emojis-popper",
              },
              model: {
                value: _vm.emojiPoppperShow,
                callback: function ($$v) {
                  _vm.emojiPoppperShow = $$v
                },
                expression: "emojiPoppperShow",
              },
            },
            [
              _c(
                "div",
                { staticClass: "emojis" },
                _vm._l(_vm.emojiName, function (item) {
                  return _c(
                    "div",
                    {
                      key: item,
                      staticClass: "emoji",
                      on: {
                        click: function ($event) {
                          return _vm.chooseEmoji(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: { src: _vm.emojiUrl + _vm.emojiMap[item] },
                      }),
                    ]
                  )
                }),
                0
              ),
              _c("i", {
                staticClass: "emoji-btn",
                attrs: { slot: "reference", title: "发表情" },
                slot: "reference",
              }),
            ]
          ),
          _c("i", {
            staticClass: "el-icon-folder",
            attrs: { title: "发文件" },
            on: { click: _vm.handleSendFileClick },
          }),
        ],
        1
      ),
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "manual",
            placement: "top-start",
            "popper-class": "at-member-list-popover",
            "visible-arrow": false,
          },
          model: {
            value: _vm.isShowAtGroupMember,
            callback: function ($$v) {
              _vm.isShowAtGroupMember = $$v
            },
            expression: "isShowAtGroupMember",
          },
        },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "at-member-list",
              on: { change: _vm.handleSelectAtUser },
              model: {
                value: _vm.atUserID,
                callback: function ($$v) {
                  _vm.atUserID = $$v
                },
                expression: "atUserID",
              },
            },
            _vm._l(_vm.atMemberList, function (member, index) {
              return _c(
                "el-radio",
                {
                  key: index,
                  class: member.nick === "所有人" ? "at-all-checkbox" : "",
                  attrs: { label: member.userID },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleClickAtUser(member.userID)
                    },
                  },
                },
                [
                  member.nick === "所有人"
                    ? _c("img", {
                        staticClass: "at-member-avatar",
                        attrs: {
                          src: require("@src/assets/images/instantMessage/at-all-user.png"),
                        },
                      })
                    : member.avatar
                    ? _c("img", {
                        staticClass: "at-member-avatar",
                        attrs: { src: member.avatar },
                      })
                    : _c("img", {
                        staticClass: "at-member-avatar",
                        attrs: {
                          src: require("@src/assets/images/default-user.png"),
                        },
                      }),
                  _c("span", { staticClass: "at-member-name" }, [
                    _vm._v(_vm._s(member.nick || member.userID)),
                  ]),
                ]
              )
            }),
            1
          ),
          _c("textarea", {
            ref: "messageInput",
            staticClass: "text-input",
            attrs: {
              slot: "reference",
              id: "messageInput",
              rows: "2",
              resize: "false",
              placeholder: "请输入",
            },
            domProps: { value: _vm.messageContent },
            on: {
              focus: function ($event) {
                _vm.focus = true
              },
              blur: function ($event) {
                _vm.focus = false
              },
              input: function ($event) {
                $event.stopPropagation()
                return _vm.handleInput.apply(null, arguments)
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  $event.preventDefault()
                  return _vm.handleEnter.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 0) return null
                  $event.stopPropagation()
                  return _vm.handleLeft.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "right", 39, $event.key, [
                      "Right",
                      "ArrowRight",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 2) return null
                  $event.stopPropagation()
                  return _vm.handleRight.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  $event.stopPropagation()
                  return _vm.handleUp.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  $event.stopPropagation()
                  return _vm.handleDown.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del",
                    ])
                  )
                    return null
                  $event.stopPropagation()
                  return _vm.handleDelete.apply(null, arguments)
                },
              ],
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                if (!$event.ctrlKey) return null
                $event.preventDefault()
                if ($event.shiftKey || $event.altKey || $event.metaKey)
                  return null
                return _vm.handleLine.apply(null, arguments)
              },
              click: function ($event) {
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                $event.preventDefault()
                return _vm.handleClickIndex.apply(null, arguments)
              },
            },
            slot: "reference",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("span", { staticClass: "message-send-tip" }, [
            _vm._v("Ctrl+Enter换行"),
          ]),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "light",
                content: "不能发送空白信息",
                placement: "left-start",
                manual: true,
              },
              model: {
                value: _vm.isEmptyMessageContent,
                callback: function ($$v) {
                  _vm.isEmptyMessageContent = $$v
                },
                expression: "isEmptyMessageContent",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "message-send-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.createInputMessage },
                },
                [_vm._v("\n                发 送\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "filePicker",
        staticStyle: { display: "none" },
        attrs: { type: "file" },
        on: { change: _vm.createFileMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }