<template>
    <el-popover
        placement="bottom"
        width="428"
        popper-class="point-card"
        :visible-arrow="false"
        trigger="hover">
        <div class="point-card">
            <div class="point-card-header">
                <h3>当前总积分</h3>
                <span class="point-card-date">20190320-{{today}}</span>
            </div>
            <div class="point-card-body">
                <p class="sum-point">{{integralCount | currency('',0)}}</p>
                <p class="today-point">
                    今日获得积分
                    <span class="font-orange">{{todayPoint | currency('',0)}}</span>
                </p>
            </div>
            <div class="point-card-footer">
                <a class="point-card-link" href="/Headhunting/MyCompany.html#/myPoint" target="_blank" @click="goToMyPoint('PointCard')">积分规则</a>
                |
                <a class="point-card-link" href="/Headhunting/MyCompany.html#/myPointGetRecord" target="_blank" @click="goToMyPointGetRecord">获得明细</a>
                <template v-if="isShowPointRank">
                    |
                    <span class="point-card-link" @click="$emit('showPointRank')">排行榜</span>
                </template>
            </div>
        </div>
        <a
            class="integral-wrap"
            slot="reference"
            href="/Headhunting/MyCompany.html#/myPoint"
            target="_blank"
            @click="goToMyPoint('TopBar')">
            <font-icon href="#icon-qiandai"></font-icon>
            <ICountUp
                v-show="isShowUpdate"
                ref="countUp"
                :endVal="integralCount"
                :options="options"
            />
            <span v-show="!isShowUpdate">{{integralCount | currency('',0)}}</span>
            <transition name="integral">
                <div v-if="isShowTip" class="integral-tip">积分+{{increaseCount}}</div>
            </transition>
        </a>
    </el-popover>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import { integral as integral_url } from '#/js/config/api.json';
import moment from 'moment';
export default {
    components:{
        ICountUp
    },
    data() {
        return {
            today: moment().format('YYYYMMDD'),
            integralCount: 0,
            todayPoint: 0,
            isShowTip: false,
            increaseCount : 10,//增长积分数
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                startVal: 0
            },
            isShowUpdate:false,
            tipQueenArr:[]//提示的队列，先进先出原则
        }
    },
    computed: {
        isShowPointRank() {
            return new Date().getTime() >= new Date('2020/05/06 00:00:00').getTime()
        }
    },
    created() {
        this.getMyPoint();
        // 积分更新 暴露给全局
        window.UPDATE_INTEGRAL_NEW = (json) => {
            this.updateCount(json.point);
        }
    },
    methods: {
        updateCount(count){
            this.tipQueenArr.push(count);
            let len = this.tipQueenArr.length;
            this.updatePoint(count,(len-1)*3000);
        },
        updatePoint(count,time){
            let delay = time || 0;
            setTimeout(() => {
                this.isShowUpdate = true;
                this.increaseCount = count;
                this.isShowTip = true;
            }, delay);
            setTimeout(() => {
                this.isShowTip = false;
                const that = this;
                this.$refs.countUp.update(that.integralCount + count);
                this.integralCount += count;
                this.tipQueenArr.splice(0,1);
            }, delay+3000);
        },
        getMyPoint(){
            let url = integral_url.my_point_info;
            _request({
                url: integral_url.my_point_info,
                method: "GET"
            }).then(res => {
                this.integralCount = res.currentPoint;
                this.todayPoint = res.todayGetPoint;
            }).catch(err => {});
        },
        goToMyPoint(from) {
            _tracker.track('ClickGoToMyPoint', JSON.stringify({
                from: from
            }));
        },
        goToMyPointGetRecord() {
            _tracker.track('ClickGoToMyPointGetRecord');
        }
    }
}
</script>

<style lang="scss" scoped>
.integral-wrap{
    display: inline-block;
    position: relative;
    color: $primary;
    padding: 0 13px;
    height: 24px;
    line-height: 24px;
    background-color: rgba(56,188,157,.1);
    border-radius: 15px;
    margin: 0 9px;
    cursor: pointer;
    /deep/ .font-icon{
        color: $primary;
        font-size: 18px;
        margin-right: 6px;
    }
    .integral-tip{
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -44px;
        height: 30px;
        line-height: 30px;
        width: 88px;
        text-align: center;
        border-radius: 16px;
        background: rgba(0,0,0,.4);
    }
    &:hover{
        background-color: #38bc9d;
        color: #fff;
        /deep/ .font-icon{
            color: #fff;
        }
    }
}
.integral-enter-active {
    animation: riseUp 3s ease-out;
}
@keyframes riseUp{
    0%{
        bottom: -10px;
        transform: scale(1);
    }
    100%{
        bottom: 66px;
        transform: scale(1.2);
    }
}
</style>
<style lang="scss">
.point-card.el-popover {
    padding: 30px;
    padding-bottom: 20px;
    background: url("~@src/assets/images/date_bgpic_wallet.svg") no-repeat center #fff;
    cursor: default;
    .point-card-header {
        line-height: 31px;
        margin-bottom: 30px;
        h3 {
            margin: 0;
            padding: 0;
            margin-right: 10px;
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            color: #444;
        }
        .point-card-date {
            font-size: 16px;
            color: #666;
        }
    }
    .point-card-body {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .sum-point {
            margin-bottom: 10px;
            font-size: 46px;
            font-weight: bold;
            line-height: 61px;
            color: $primary;
        }
        .today-point {
            font-size: 18px;
            color: #666;
            line-height: 24px;
            .font-orange {
                margin-left: 10px;
                font-weight: bold;
                color: #FF7700;
            }
        }
    }
    .point-card-footer {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #999;
        .point-card-link {
            color: #999;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
            &:nth-of-type(1) {
                margin-right: 5px;
            }
            &:nth-last-of-type(1) {
                margin-left: 5px;
            }
        }
    }
    p {
        margin: 0;
        padding: 0;
    }
}
</style>