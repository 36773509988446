<template>
      <el-dialog
        :visible.sync="feedbackVisible"
        custom-class="suggest-feedback"
        width="510px"
        :show-close="false"
        title="意见反馈"
        v-loading="loading"
        :close-on-click-modal="false"
        :append-to-body="true"
    >
        <el-input
            type="textarea"
            placeholder="请详细描写您的意见建议，之后的升级一定会给你惊喜~"
            v-model="textarea"
            maxlength="200"
            show-word-limit
            resize="none">
        </el-input>
        <div class="feedback-button">
            <el-button @click="handleCancle">取消</el-button>
            <el-button class="default" @click="submit">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            feedbackVisible: false,
            textarea: '',
            loading: false
        }
    },
    methods: {
        show() {
            this.feedbackVisible = true;
        },
        submit(){
            if(!this.textarea){
                shortTips("意见建议不能为空");
                return;
            }
            this.loading = true;
            let data = {
                    description: this.textarea,
                    pageUrl: location.href
                }
            _request({
                url: `/Feedback`,
                method: 'POST',
                data: data
            }).then((res) => {
                this.feedbackVisible = false;
                this.textarea = '';
                shortTips("提交成功！");
            }).catch((err) => {
                console.log(err);
            }).finally(()=>{
                this.loading = false;
            })
        },
        handleCancle(){
            this.feedbackVisible = false;
            this.textarea ='';
        },
    }
}
</script>

<style lang="scss" scope>
.suggest-feedback{
    padding: 0;
    .el-dialog__header{
        font-size: 18px;
        color: #444;
        letter-spacing: 0;
        border-bottom: 1px solid #DDDDDD;
    }
    .el-dialog__body{
        padding: 30px 45px;
        textarea{
            height: 180px;
            font-size: 14px;
            color: #666 !important;
        }
        .feedback-button{
            text-align: center;
            margin-top: 30px;
            font-size: 14px;
            color: #999;
            .default{
                background-color: #38BC9C;
                color: #FFFFFF;
                &:hover{
                    background-color: #36b495;
                }
            }
        }
    }
}
</style>