<template>
    <div class="customer-department-select">
        <el-input
            v-model="selectText"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :size="size"
            class="department-select-input"
            @input="handleInput"
            @focus="handleInputFocus"
            @blur="handleInputBlur($event)"
        ></el-input>
        <!-- <el-select
            v-show="searchCascaderVisible && filterDepartments.length == 0"
            v-model="value"
            ref="departmentSelect"
            popper-class="department-select"
            :no-data-text="noDataTip"
            :size="size"
            @visible-change="handleSelectBlur"
            @change="(val) => handleDepartmentId(val, 'select')">
            <el-option
                v-for="department in filterDepartments"
                :key="department.id"
                :label="department.name"
                :value="department.id"
                :title="department.name"
            ></el-option>
        </el-select> -->
        <el-cascader-panel
            v-model="value"
            ref="departmentSearchCascader"
            class="department-cascader"
            :options="filterDepartments"
            :size="size"
            v-show="searchCascaderVisible"
            :props="{
                checkStrictly: !isSearchOnlyLeaf,
                emitPath: false,
                value: 'id',
                label: 'name',
                lazy: true,
                lazyLoad: getSubDepartmentList,
            }"
            @expand-change="handleExpandChange"
            @change="(val) => handleDepartmentId(val, 'cascader-search')"
        ></el-cascader-panel>
        <el-cascader-panel
            v-model="value"
            ref="departmentCascader"
            class="department-cascader"
            :options="departmentList"
            :size="size"
            v-show="
                isFocus && !isInput && !isDisabled && departmentList.length > 0
            "
            :props="{
                checkStrictly: !isOnlyLeaf,
                emitPath: false,
                value: 'id',
                label: 'name',
                lazy: true,
                lazyLoad: getSubDepartmentList,
            }"
            @expand-change="handleExpandChange"
            @change="(val) => handleDepartmentId(val, 'cascader')"
        ></el-cascader-panel>
    </div>
</template>

<script>
import DepartmentService from "#/js/service/departmentService.js";
export default {
    props: {
        size: {
            type: String,
            default: "default",
        },
        customerDepartmentName: {
            type: String,
            default: "",
        },
        // 是否只返回无子部门的部门
        isOnlyLeaf: {
            type: Boolean,
            default: false,
        },
        isSearchOnlyLeaf: {
            type: Boolean,
            default: false,
        },
        departmentId: {
            type: [String, Number],
            default: "",
        },
        // 是否禁用
        isDisabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "选择或输入部门关键词",
        },
        customerId: {
            type: String,
            default: "",
        },
        canSrcoll: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            departmentList: [],
            filterDepartments: [],
            brotherCustomerDepartmentDic: {},
            value: null,
            isFocus: false,
            isInput: false,
            searchCascaderVisible: false,
            selectText: "",
            noDataTip: "关键字搜索至少输入两个字",
        };
    },
    created() {
        // console.log(this.isDisabled);
    },
    watch: {
        // selectText: {
        //     handler: function(val) {
        //         this.debounce(this.getDepartmentFilterList.bind(this, val), 500);
        //     }
        // },
        departmentId: {
            handler: function (val) {
                if (val && !this.value) {
                    this.value = val;
                }
            },
        },
        isFocus: {
            handler: function (val) {
                if (val) {
                    let departmentCascader = this.$refs.departmentCascader;
                    let node = departmentCascader.getCheckedNodes();
                    if (node.length && node[0] !== null) {
                        for (let i = 1; i < node[0].pathLabels.length; i++) {
                            this.$set(
                                departmentCascader.menus,
                                i,
                                node[0].pathNodes[i - 1].children
                            );
                        }
                        if (node[0].children.length === 0) {
                            departmentCascader.menus.splice(
                                node[0].pathLabels.length
                            );
                        }
                    }
                    this.$nextTick(() => {
                        let cascaderList = this.$el.querySelectorAll(
                                ".el-cascader-menu__list"
                            ),
                            activeCascaderIndex = null;
                        cascaderList.forEach((item, index) => {
                            // 判断是否可以滚动
                            if (this.canSrcoll) {
                                if (item.querySelector(".is-active")) {
                                    activeCascaderIndex = index;
                                    item.querySelector(
                                        ".is-active"
                                    ).scrollIntoView(false);
                                } else {
                                    item.querySelector(".in-active-path") &&
                                        item
                                            .querySelector(".in-active-path")
                                            .scrollIntoView(false);
                                }
                            }
                        });
                        this.$refs.departmentCascader.$el.scrollLeft =
                            180 * activeCascaderIndex;
                    });
                }
            },
        },
    },
    mounted() {
        this.selectText = this.customerDepartmentName;

        document.addEventListener("click", this.handleSearchCascaderBlur);
    },
    methods: {
        handleInput(val) {
            // console.log(val);
            this.debounce(this.getDepartmentFilterList.bind(this, val), 500);
        },
        debounce(fn, wait) {
            if (this.fun !== null) {
                clearTimeout(this.fun);
            }
            this.fun = setTimeout(fn, wait);
        },
        setSearchCascaderEmptyText(str) {
            try {
                let target =
                    this.$refs.departmentSearchCascader.$el.querySelector(
                        ".el-cascader-menu__empty-text"
                    );
                target.innerText = str;
            } catch (e) {}
        },
        getDepartmentFilterList(val) {
            if (this.value) {
                this.value = null;
            }
            if (val) {
                this.isInput = true;
                this.isFocus = false;
                if (val.length >= 2) {
                    let arr = val.split(/[,，]/);
                    var reg = new RegExp(/[\u4e00-\u9fa5_a-zA-Z0-9_]{2,}/);
                    let res = arr.filter(function (el) {
                        return reg.test(el);
                    });
                    // console.log(res)
                    val = res.join();
                    // console.log(val)

                    this.setSearchCascaderEmptyText("加载中...");
                    DepartmentService.getDepartmentBySearchBatch({
                        customerId: this.customerId,
                        keys: val,
                        // isIncludeParent: !this.isOnlyLeaf
                    })
                        .then((res) => {
                            this.brotherCustomerDepartmentDic =
                                res.brotherCustomerDepartments || {};
                            let filterList = [],
                                list = res.customerDepartments;
                            if (list.length) {
                                list.forEach((department) => {
                                    filterList.push({
                                        name: department.path,
                                        id: Number(department.id),
                                        leaf:
                                            res.brotherCustomerDepartments[
                                                department.id
                                            ].length == 0,
                                    });
                                });
                            }
                            this.filterDepartments = filterList;
                            if (!filterList.length) {
                                this.noDataTip = "无匹配数据";
                                this.setSearchCascaderEmptyText("无匹配数据");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.noDataTip = "关键字搜索至少输入两个字";
                    this.setSearchCascaderEmptyText("关键字搜索至少输入两个字");
                    this.filterDepartments = [];
                }
                this.$nextTick(() => {
                    // this.$refs.departmentSelect.visible = true;
                    this.searchCascaderVisible = true;
                });
            } else {
                this.isInput = false;
                this.isFocus = true;
                // this.$refs.departmentSelect.visible = false;
                this.searchCascaderVisible = false;
            }
        },
        handleInputFocus() {
            document.removeEventListener("click", this.handleCascaderBlur);
            this.isFocus = true;
        },
        handleInputBlur(ev) {
            // this.searchCascaderVisible = false;

            let cascaderEle = this.$refs.departmentCascader.$el;
            if (
                cascaderEle === ev.relatedTarget ||
                cascaderEle.contains(ev.relatedTarget)
            ) {
                if (!ev.relatedTarget.classList.contains("is-checked")) {
                    document.addEventListener("click", this.handleCascaderBlur);
                    return;
                }
            }
            this.isFocus = false;
            if (!this.value && this.value != 0) { //后端返回数据可能为数值0
                this.selectText = "";
            }
        },
        handleCascaderBlur(ev) {
            ev.stopPropagation();
            let path = ev.path || (ev.composedPath && ev.composedPath());
            if (
                this.$refs.departmentCascader &&
                path.indexOf(this.$refs.departmentCascader.$el) === -1 &&
                this.isFocus
            ) {
                this.isFocus = false;
            }
        },
        handleSearchCascaderBlur(ev) {
            ev.stopPropagation();
            let path = ev.path || (ev.composedPath && ev.composedPath());

            if (
                this.$refs.departmentSearchCascader &&
                path.indexOf(this.$refs.departmentSearchCascader.$el) === -1 &&
                this.searchCascaderVisible
            ) {
                this.searchCascaderVisible = false;
            }
        },
        handleSelectBlur(val) {
            if (!val && !this.value) {
                this.$emit("update-department", {
                    id: this.value,
                    name: this.selectText,
                    departmentName: "",
                });
                this.$emit("update-exist-departments", []);
            }
        },
        getDepartmentList(id, cb) {
            DepartmentService.getDepartmentList({
                id: id,
            })
                .then((res) => {
                    if (res.length) {
                        res = this.trasformList(res);
                        this.departmentList = res;
                    } else {
                        this.departmentList = [];
                    }
                    cb && cb();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        trasformList(list) {
            list.forEach((item) => {
                item.hasChildren = item.hasChildNodes;
                delete item.hasChildNodes;
                if (!item.hasChildren) {
                    item.leaf = true;
                }
                if (item.childNodes.length) {
                    item.childNodes = this.trasformList(item.childNodes);
                    item.children = item.childNodes;
                }
                delete item.childNodes;
            });
            return list;
        },
        getSubDepartmentList(node, resolve) {
            let orgNode = node;
            if (orgNode.children && orgNode.children.length) {
                orgNode.children = [];
                this.isFocus = true;
                resolve(orgNode.data.children);
            } else {
                if (orgNode.value) {
                    orgNode.children = [];
                    DepartmentService.getSubDepartmentList({
                        id: orgNode.value,
                        customerId: this.customerId,
                    })
                        .then((res) => {
                            this.isFocus = true;
                            if (res && res.length) {
                                res.forEach((item) => {
                                    item.leaf = !item.hasChildren;
                                });
                                resolve(res);
                            } else {
                                resolve([]);
                            }
                        })
                        .catch((err) => {
                            resolve([]);
                            console.log(err);
                        });
                }
            }
        },
        handleExpandChange(val) {
            let length = val.length;
            this.$nextTick(() => {
                this.$refs.departmentCascader.$el.scrollLeft = 180 * length;
            });
        },
        handleDepartmentId(val, type) {
            this.isFocus = false;
            let parentPath = "";
            let departmentName = "";

            let node;
            if (type === "cascader-search") {
                this.isInput = false;
                this.searchCascaderVisible = false;
                this.filterDepartments = [];
                this.getDepartmentList(val);

                node = this.$refs.departmentSearchCascader.getCheckedNodes();
            } else if (type === "cascader") {
                node = this.$refs.departmentCascader.getCheckedNodes();
            }
            this.selectText =
                (node.length && node[0].pathLabels.join("-")) || "";
            parentPath = (node.length && node[0].pathLabels.join("-")) || "";
            departmentName = node[0].label;
            if(departmentName.includes("-")) {
                const _departmentNameList = departmentName.split("-");
                departmentName = _departmentNameList[_departmentNameList.length -1];
            }
            this.$emit("update-department", {
                id: val,
                name: this.selectText,
                departmentName: departmentName,
            });

            let existDepartmentList = [];

            if (this.brotherCustomerDepartmentDic.hasOwnProperty(val)) {
                if (
                    this.brotherCustomerDepartmentDic[val] &&
                    this.brotherCustomerDepartmentDic[val].length > 0
                ) {
                    this.brotherCustomerDepartmentDic[val].forEach((item) => {
                        if (parentPath) {
                            existDepartmentList.push(`${parentPath}-${item}`);
                        } else {
                            existDepartmentList.push(item);
                        }
                    });
                }

                this.$emit(
                    "update-exist-departments",
                    existDepartmentList || []
                );
            } else {
                DepartmentService.getSubDepartmentList({
                    id: val,
                    customerId: this.customerId,
                }).then((res) => {
                    res.forEach((item) => {
                        if (parentPath) {
                            existDepartmentList.push(
                                `${parentPath}-${item.name}`
                            );
                        } else {
                            existDepartmentList.push(item.name);
                        }
                    });
                    this.$emit(
                        "update-exist-departments",
                        existDepartmentList || []
                    );
                });
            }
        },
        editInit(department) {
            if (department.id !== 0 && department.id !== "0") {
                this.getDepartmentList(department.id, () => {
                    this.$nextTick(() => {
                        this.handleDepartmentId(department.id, "cascader");
                    });
                });
            } else {
                this.value = department.id;
                this.departmentList.push({
                    name: department.name,
                    id: department.id,
                    leaf: true,
                });
                this.selectText = department.name;
            }
        },
        reset() {
            this.departmentList = [];
            this.filterDepartments = [];
            this.value = null;
            this.selectText = "";
            this.isFocus = false;
            this.isInput = false;
        },
    },
};
</script>

<style lang="scss" scope>
.customer-department-select {
    position: relative;
    height: 40px;
    .department-select-input {
        position: absolute;
        z-index: 10;
        height: 40px;
        line-height: 40px;
        top: 0px;
        left: 0px;
        width: 100%;
        color: #666;
        pointer-events: unset;

        .el-input__inner {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .el-select {
        width: 100%;
        .el-input__inner {
            visibility: hidden;
        }
        .el-input__suffix {
            display: none;
        }
    }
}
.department-cascader.el-cascader-panel {
    position: absolute;
    top: 40px;
    z-index: 10;
    background-color: #fff;
    max-width: 100%;
    overflow: auto;
    overflow-x: auto;

    .el-cascader-menu {
        &:last-child {
            .el-icon-check.el-cascader-node__prefix {
                display: none;
            }
        }

        .el-cascader-menu__list {
            padding: 0;

            .el-cascader-menu__empty-text {
                line-height: 20px;
            }
        }
        .el-cascader-node {
            padding: 0;
            height: 40px;
            line-height: 40px;
            &.in-active-path {
                font-weight: normal;
            }
            &.in-checked-path {
                font-weight: bold;
            }
            label {
                opacity: 0;
                width: calc(100% - 30px);
                position: absolute;
                z-index: 10;
            }
            .el-cascader-node__prefix {
                display: none;
            }
            .el-cascader-node__label {
                position: relative;
                height: 40px;
                padding-right: 24px;

                span {
                    display: inline-block;
                    max-width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .has-children {
                    position: absolute;
                    right: 5px;
                    line-height: 40px;
                }
            }
        }
    }
}
.department-select {
    max-width: 384px;
}
</style>
