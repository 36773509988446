const state = {
    candidateInfo: {},
    canSetCloud:false,
    lockStatus: {
        isOfferStamp: false,
        isOnboardLock: false,
        candidateId: ''
    }
}

const getters = {

}

const actions = {
    setCandidateDetail({ commit, state }, candidateInfo) {
        commit('updateCandidateDetail', { candidateInfo: candidateInfo });
    },
    setcanSetCloud({ commit, state }, canSetCloud) {
        commit('_updatecanSetCloud', { canSetCloud: canSetCloud });
    },
    getLockStatusAsync ({ commit, state }, id) {
        _request({
            url: `/CandidateLock/${id}/IsOfferStamp`,
            method: 'GET'
        }).then(res =>{
            commit('_updateLockStatus', {lockStatus: res});
        })
    }
}

const mutations = {
    updateCandidateDetail(state, { candidateInfo }) {
        state.candidateInfo = candidateInfo;
    },
    _updatecanSetCloud(state, { canSetCloud }) {
        state.canSetCloud = canSetCloud;
    },
    _updateLockStatus(state, {lockStatus}) {
        Object.assign(state.lockStatus, lockStatus);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
