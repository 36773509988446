var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "conversation-item",
      class: {
        choose: _vm.conversation.conversationID === _vm.currentConversationID,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.selectConversation.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "conversation-avatar",
          class: {
            "is-online": _vm.isOnline,
            "is-group": !_vm.isC2c && !_vm.isSystem,
          },
        },
        [
          !_vm.isSystem
            ? _c("img", { attrs: { src: _vm.avatar } })
            : _c("img", {
                attrs: {
                  src: require("@src/assets/images/icon_system_message.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "conversation-info",
          style: { "max-width": _vm.maxWidth },
        },
        [
          _c("div", { staticClass: "conversation-info-header" }, [
            _c(
              "span",
              {
                staticClass: "conversation-name",
                class: {
                  "has-inner-label": _vm.checkResult === 1,
                  "has-candidate-label": _vm.checkResult === 3,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.isSystem ? "系统通知" : _vm.conversationName) +
                    "\n                "
                ),
                _vm.checkResult === 1 && !_vm.isCompany
                  ? _c("span", { staticClass: "conversation-label" }, [
                      _vm._v("内部"),
                    ])
                  : _vm._e(),
                _vm.isCompany
                  ? _c(
                      "span",
                      { staticClass: "conversation-label company-label" },
                      [_vm._v("全员")]
                    )
                  : _vm._e(),
                _vm.checkResult === 3
                  ? _c(
                      "span",
                      { staticClass: "conversation-label candidate-label" },
                      [_vm._v("职通车候选人")]
                    )
                  : _vm._e(),
              ]
            ),
            _c("span", { staticClass: "conversation-date" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("timeFilter")(_vm.conversation.lastMessage.lastTime)
                  ) +
                  "\n            "
              ),
            ]),
          ]),
          _c("div", { staticClass: "last-message-container" }, [
            _c("span", { staticClass: "last-message" }, [
              _vm.conversation.unreadCount > 0 && _vm.hasMessageAtMe
                ? _c("span", { staticClass: "atme-tip" }, [_vm._v("[有人@我]")])
                : _vm._e(),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.messageFrom) +
                  _vm._s(_vm.messageForShow) +
                  "\n            "
              ),
            ]),
            !_vm.isSystem &&
            !_vm.isC2c &&
            _vm.conversation.groupProfile.selfInfo.messageRemindType ==
              "AcceptNotNotify"
              ? _c("i", { staticClass: "el-icon-close-notification" })
              : _vm._e(),
            _c("div", { staticClass: "unread-count-container" }, [
              _vm.isSystem ||
              _vm.isC2c ||
              (!_vm.isC2c &&
                _vm.conversation.groupProfile.selfInfo.messageRemindType ==
                  "AcceptAndNotify")
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.conversation.unreadCount > 0 &&
                            _vm.conversation.conversationID !==
                              _vm.currentConversationID,
                          expression:
                            "conversation.unreadCount > 0 && conversation.conversationID !== currentConversationID",
                        },
                      ],
                      staticClass: "unread-count",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.conversation.unreadCount > 99
                              ? _vm.conversation.unreadCount + "+"
                              : _vm.conversation.unreadCount
                          ) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            !_vm.isC2c &&
            !_vm.isSystem &&
            _vm.conversation.groupProfile.selfInfo.messageRemindType ==
              "AcceptNotNotify" &&
            _vm.conversation.unreadCount > 0
              ? _c("span", { staticClass: "unread-tip" })
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }