import qs from 'qs';

/**
 * 数组快速排序
 * @param arr 原始数组
 * @param low 开始下标
 * @param high 结束下标
 * @param label 排序keyname
 * @returns array【暂时默认为倒序数组】
 */
function quickSort(arr,low,high, label){
    let key = arr[low];
    let start = low;
    let end = high;
    while(end > start) {
        while (end > start && arr[end][label] >= key[label]){
            end--;
        }
        if (arr[end][label] < key[label]) {
            let temp = arr[end];
            arr[end] = arr[start];
            arr[start] = temp;
        }
        while (end > start && arr[start][label] <= key[label]) {
            start++;
        }
        if (arr[start][label] > key[label]) {    //可能左侧没有比基准值大的
            let temp = arr[start];
            arr[start] = arr[end];
            arr[end] = temp;
        }
    }
    //排完后start等于end，即基准值在此次排序中的最终位置，递归调用
    if (start > low+1){
        return this.quickSort(arr, low, start - 1,label);
    }
    //如果start小等于low+1，说明左侧只有0或者1个数字，不需要再进行排序，递归调用
    if (end < high-1) {
        return this.quickSort(arr, end + 1, high,label);
    }

    let result = [];
    for(let key in arr){
        result.unshift(arr[key]);
    }
    return result;
}

export default {
    /**
     * 获取URL在本网站下的请求绝对地址
     * @param url
     */
    absoluteUrl: (url) => {
        return `${location.origin}${url}`;
    },
    /**
     * 数字转字符
     * @param int 需要转换的数字
     * @param length 转换后字符的长度
     * @returns {*}
     */
    numberToString: (int, length) => {
        var str = int.toString();
        if (str.length < length) {
            for (var i = 0; i < length - str.length; i++) {
                int = '0' + int;
            }
            return int;
        } else {
            return str;
        }
    },
    /**
     * 对象转formData(a=1&b=2....)
     * @param params 入参对象
     * @returns {string} search params
     */
    toFormData: (params = {}) => {
        return qs.stringify(params);
    },
    /**
     * 函数节流
     * @param {funciton} fn
     * @param {时间} time
     * @returns {Function}
     */
    throttle: (fn, time) => {
        let startTime = Number(new Date());
        let _this = this;
        return function () {
            let nowTime = Number(new Date());
            if (nowTime - startTime >= time) {
                startTime = nowTime;
                fn.call(_this);
            } else {
                return false;
            }
        }
    },
    quickSort,
}
