<template>
    <el-autocomplete
        class="customer-select-input"
        popper-class="customer-select"
        v-model="comCustomerName"
        :size="size"
        :maxlength="128"
        :fetch-suggestions="querySearchAsync"
        :placeholder="placeholder"
        :valueKey="valueKey"
        :disabled="isDisabled"
        @select="handleSelect"
        @blur="handleBlur"
        ></el-autocomplete>
</template>

<script>
import customerListService from '#/js/service/customerListService.js';
export default {
    name: "customerSelectOrWrite",
    props: {
        size: {
            type: String,
            default: 'default'
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择客户"
        },
        valueKey: {
            type: String,
            default: 'customerName'
        },
        customerId: {
            type: String,
            default: '',
        },
        customerName: {
            type: String,
            default: ''
        },
    },
    data() {
      return {
        customerList: [],
        comCustomerName: '',
      };
    },
    methods: {
        getCustomerList() {
            let params = {};
            if(this.firmId) {
                params.firmId = this.firmId;
            }
            customerListService.getMyCompanyCustomers(params)
            .then(res =>{
                res.forEach(item =>{
                    delete item.subCustomers;
                })
                this.customerList = res;
            }).catch(err => {
                console.log(err)
            })
        },
        querySearchAsync(queryString, cb) {
            const customerList = this.customerList;
            const results = queryString ? customerList.filter(this.createStateFilter(queryString)) : customerList;
            cb(results);
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state[this.valueKey].toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
            };
        },
        handleSelect(item) {
            this.$emit('update:customerId', item.customerId);
            this.$emit('update:customerName', item.customerName);
            this.$emit('finish-callback');
        },
        handleBlur(e) {
            const idx = this.customerList.findIndex(item => item[this.valueKey] == this.comCustomerName);
            if(this.comCustomerName && this.comCustomerName!='') {
                if(idx < 0){
                    this.$emit('update:customerId', null);
                    this.$emit('update:customerName', this.comCustomerName);
                }else{
                    this.$emit('update:customerId', this.customerList[idx].customerId);
                    this.$emit('update:customerName', this.customerList[idx].customerName);
                }
                this.$emit('finish-callback');
            }
        },
    },
    mounted() {
        this.comCustomerName = this.customerName;
        this.getCustomerList();
    },
    watch: {
        customerName(val) {
            this.comCustomerName = val;
        }
    }
  };
</script>

<style lang="scss" scoped>
.customer-select-input {
    width: 100%;
}
.customer-select {
    width: 100%;
}
</style>
