var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "current-conversation-wrapper" }, [
    _c(
      "div",
      { staticClass: "current-conversation" },
      [
        _c("conversation-header-bar", {
          attrs: {
            "current-conversation": _vm.currentConversation,
            "is-large-size": _vm.isLargeSize,
            userProfileMap: _vm.userProfileMap,
            groupInfo: _vm.groupInfo,
          },
          on: {
            "en-large-dialog": function ($event) {
              return _vm.$emit("enLargeDialog")
            },
            "close-instant-message": function ($event) {
              return _vm.$emit("closeInstantMessage")
            },
            "show-group-info": _vm.showGroupInfo,
          },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.messageLoading,
                expression: "messageLoading",
              },
            ],
            staticClass: "content",
            class: { "content-bg": !_vm.currentConversation.conversationID },
          },
          [
            _c(
              "div",
              {
                ref: "message-list",
                staticClass: "message-list",
                on: { scroll: _vm.onScroll },
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.flipOverType == 0 && !_vm.isCompleted,
                      expression: "flipOverType == 0 && !isCompleted",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.messagePagingLoading,
                      expression: "messagePagingLoading",
                    },
                  ],
                  staticClass: "more",
                }),
                _vm.currentMessageListFetched && _vm.isCompleted
                  ? _c("div", { staticClass: "no-more" }, [
                      _vm._v("没有更多了"),
                    ])
                  : _vm._e(),
                _vm._l(_vm.currentMessageList, function (message, index) {
                  return _c("message-item", {
                    key: index,
                    attrs: {
                      message: message,
                      deleteMemberNick: _vm.deleteMemberNick,
                      memberList: _vm.memberList,
                      "current-conversation": _vm.currentConversation,
                      "current-user-profile": _vm.currentUserProfile,
                      isInner: _vm.isInner,
                    },
                  })
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.flipOverType == 1 && !_vm.isAfterCompleted,
                      expression: "flipOverType == 1 && !isAfterCompleted",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.messagePagingLoading,
                      expression: "messagePagingLoading",
                    },
                  ],
                  staticClass: "more",
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isPositionMode &&
                      !_vm.hasNewMessage &&
                      _vm.isShowScrollButtomTips,
                    expression:
                      "!isPositionMode && !hasNewMessage && isShowScrollButtomTips",
                  },
                ],
                staticClass: "to-bottom-tip",
                on: { click: _vm.scrollMessageListToButtom },
              },
              [_vm._v("回到初始位置")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isPositionMode &&
                      _vm.hasNewMessage &&
                      _vm.distanceToBottom > 10,
                    expression:
                      "!isPositionMode && hasNewMessage && (distanceToBottom > 10)",
                  },
                ],
                staticClass: "at-me-tips",
                on: { click: _vm.scrollMessageListToButtom },
              },
              [_vm._v("您有新消息")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isAtMe,
                    expression: "isAtMe",
                  },
                ],
                staticClass: "at-me-tips",
                on: { click: _vm.scrollToAtMe },
              },
              [
                _vm._v("\n                有人提到我\n                "),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.isAtMe = false
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPositionMode && _vm.hasNewMessage,
                    expression: "isPositionMode && hasNewMessage",
                  },
                ],
                staticClass: "at-me-tips",
                on: { click: _vm.refreshCurrentConversation },
              },
              [_vm._v("\n                您有新消息\n            ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isAtMe && _vm.unreadCount > 10,
                    expression: "!isAtMe && unreadCount > 10",
                  },
                ],
                staticClass: "unread-tips",
                on: {
                  click: function ($event) {
                    return _vm.$emit("getMore", {
                      unreadCount:
                        _vm.unreadCount - _vm.currentMessageList.length,
                    })
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-thumb" }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.unreadCount) +
                    "条新消息\n                "
                ),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.unreadCount = 0
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        !_vm.isSystem
          ? _c("message-send-box", {
              ref: "messageSendBox",
              attrs: {
                userId: _vm.userId,
                currentConversation: _vm.currentConversation,
                memberList: _vm.memberList,
                toAccount: _vm.toAccount,
              },
              on: { sendMessage: _vm.handleSendMessage },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.currentConversation.type &&
    _vm.currentConversation.type === _vm.TIM.TYPES.CONV_GROUP
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowGroupInfo,
                expression: "isShowGroupInfo",
              },
            ],
            staticClass: "profile-wrapper",
          },
          [
            _c("group-profile", {
              ref: "groupProfile",
              attrs: {
                group: _vm.currentConversation.groupProfile,
                currentConversation: _vm.currentConversation,
                memberList: _vm.memberList.slice(1),
                userId: _vm.userId,
                isCompany:
                  _vm.companyGroupId ===
                  _vm.currentConversation.groupProfile.groupID,
              },
              on: {
                deleteMember: _vm.deleteGroupMember,
                quitGroup: _vm.quitGroupSuccess,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.isShowGroupInfo
      ? _c("div", {
          ref: "profileWrapperModal",
          staticClass: "profile-wrapper-modal",
        })
      : _vm._e(),
    _c("div", { staticClass: "member-profile-card-wrapper" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }