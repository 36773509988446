<template>
  <el-dialog
    :visible="isShow"
    :title="isAddGroupNember ? '选择群成员' : '创建群聊'"
    width="602px"
    custom-class="create-group-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :destroy-on-close="true"
    @close="hide">
    <div class="create-group-container">
        <div class="member-list-container">
            <member-list
                :userId="userId"
                ref="memberList"
                @memberSelected="handleSelectMember">
            </member-list>
        </div>
        <div class="group-create-container">
            <selected-member-list
                :height="302"
                :selectedMemberList="selectedMemberList"
                @removeSelected="removeSelected">
            </selected-member-list>
            <div class="group-create">
                <div class="create-title">
                    群名称-必填
                </div>
                <el-input
                    class="group-name-input"
                    :value="groupName"
                    placeholder="取个群名称方便搜索"
                    @input="groupNameInput"
                    :disabled="isAddGroupNember"
                ></el-input>
                <div class="group-btn-list">
                    <el-button class="creat-group-btn" @click="createGroup" type="primary">{{isAddGroupNember ? '确定' : '创建'}}</el-button>
                    <el-button class="cancel-create-btn" @click="hide">取消</el-button>
                </div>
            </div>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import MemberList from './member-list.vue';
import SelectedMemberList from './selected-member-list.vue';
import emitter from '#/js/mixins/emitter.js';
import imService from '#/js/service/openIMService.js';
import { avatarHost } from '../utils/avatarHost.js';
export default {
    props: {
        userId: String
    },
    components: {
        MemberList,
        SelectedMemberList
    },
    data() {
        return {
            isShow: false,
            isAddGroupNember: false,
            addInitCount: 0,
            groupName: "",
            selectedMemberList: [],
            groupID: "",
        }
    },
    mixins: [emitter],
    methods: {
        show(isAdd, group) {
            this.isShow = true;
            this.$nextTick(() => {
                this.$refs.memberList.init();
            });
            if(isAdd) {
                this.isAddGroupNember = isAdd;
                this.groupID = group.groupID;
                this.groupName = group.name;
                this.addInitCount = group.memberList.length;
                group.memberList.forEach(item => {
                    item.disabled = true;
                })
                this.selectedMemberList = group.memberList;
                this.$nextTick(() => {
                    this.$refs.memberList.selectedMemberList = group.memberList;
                })
            }
        },
        hide() {
            this.isShow = false;
            this.isAddGroupNember = false;
            this.addInitCount = 0;
            this.groupName = "";
            this.selectedMemberList = [];
            this.$refs.memberList.reset();
        },
        handleSelectMember(list) {
            this.selectedMemberList = list;
            console.log(this.selectedMemberList);
        },
        removeSelected(member) {
            this.$refs.memberList.handleMemberSelect(false, member);
        },
        groupNameInput(value) {
            let enc = new TextEncoder("utf-8");
            for(let i = 0; i < 30; i++) {
                if(enc.encode(value).length > 30) {
                    value = value.substring(0, value.length-1);
                } else {
                    this.groupName = value;
                    break;
                }
            }
        },
        refreshSignature() {
            return new Promise((resolve, reject) => {
                if(localStorage.getItem('imUserSig')) {
                    resolve(true);
                } else {
                    imService.getUserSig().then(res =>{
                        localStorage.setItem('imUserSig', res.token);
                        resolve(true);
                    }).catch(err => {
                        resolve(false);
                    })
                }

            })
        },
        async createGroup() {
            let checkSig = await this.refreshSignature();
            if (!checkSig) {
                shortTips("未知异常，请刷新页面重试！");
                return false;
            }
            if(this.isAddGroupNember) {
                if(this.selectedMemberList.length <= this.addInitCount) {
                    shortTips("请选择群成员");
                    return false;
                }

                let addMemberList = [];
                this.selectedMemberList.filter(item => {
                    if(!item.disabled) {
                        addMemberList.push(item.unionId);
                    }
                })

                imService.addGroupMember({
                    groupID: this.groupID,
                    invitedUserIDList: addMemberList,
                    token: localStorage.getItem('imUserSig'),
                    reason: ''
                }).then(res =>{
                    console.log(res);
                    shortTips("添加成功");
                    this.dispatch('IMBaseApp', 'updateMemberList')
                    this.hide();
                }).catch((err) => {
                    console.log('addGroupMemberError：', err);
                })
                
                // tim.addGroupMember({
                //     groupID: this.groupID,
                //     userIDList: addMemberList
                // }).then(({ data }) => {
                //     if(data.failureUserIDList.length > 0) {
                //         let failUserNames = [],
                //             userName = "",
                //             failUser = {};
                //         data.failureUserIDList.forEach(failUser => {
                //             failUser = this.selectedMemberList.find(member => member.unionId === failUser)
                //             if(failUser.nick || failUser.realName) {
                //                 userName = failUser.nick || failUser.realName;
                //                 failUserNames.push(userName)
                //             }
                //         });
                //         shortTips(`${failUserNames.join(',')}账号异常，添加失败`);
                //     } else {
                //         shortTips("添加成功");
                //     }
                //     if(data.successUserIDList.length) {
                //         this.dispatch('IMBaseApp', 'updateMemberList')
                //     }
                //     this.hide();
                // }).catch(err => {
                //     console.log('addGroupMemberError：', err);
                // })
            } else {
                if(!this.groupName) {
                    shortTips("请输入群名称！");
                    return false;
                } else {
                    let enc = new TextEncoder("utf-8");
                    if(enc.encode(this.groupName).length > 30) {
                        shortTips("群名称过长");
                        return false;
                    }
                }
                if(this.selectedMemberList.length < 2) {
                    shortTips("至少选择2位联系人");
                    return false;
                } else if(this.selectedMemberList.length > 100) {
                    shortTips("最多可选择100位联系人！");
                    return false;
                }

                let memberList = [{ userID: this.userId }];
                this.selectedMemberList.forEach(item => {
                    memberList.push({
                        userID: item.unionId
                    });
                })

                imService.createGroup({
                    token: localStorage.getItem('imUserSig'), 
                    ownerUserID: this.userId, 
                    groupName: this.groupName, 
                    memberList: memberList
                }).then(res =>{
                    shortTips("群聊创建成功");
                    this.hide();
                    // setTimeout(() => {
                    //     this.$emit('selectConversation', { conversationID: res.groupID }, true);
                    // }, 1000)
                }).catch((err) => {
                    console.log('createGroupError', err);
                })
                // let memberList = [],
                //     groupID = _generateUUID();
                // this.selectedMemberList.forEach(item => {
                //     memberList.push({
                //         userID: item.unionId
                //     });
                // })
                // tim.createGroup({
                //     name: this.groupName,
                //     type: TIM.TYPES.GRP_PRIVATE,
                //     groupID: groupID,
                //     avatar: `${avatarHost()}/group/${groupID}`,
                //     memberList: memberList
                // }).then(({ data }) => {
                //     shortTips("群聊创建成功");
                //     this.hide();
                //     setTimeout(() => {
                //         this.$emit('selectConversation', {conversationID: `GROUP${groupID}`}, true);
                //     }, 1000)
                // }).catch(err => {
                //     console.log('createGroupError', err);
                // })
            }
        }
    }
}
</script>

<style lang="scss" scope>
.create-group-dialog.el-dialog {
    width: 602px;
    height: 490px;
    .el-dialog__header {
        background-color: $primary;
        border-radius: 6px 6px 0 0;
        height: 50px;
        line-height: 50px;
        .el-dialog__title {
            color: #fff;
            font-size: 16px;
        }
        .el-dialog__close {
            color: #fff;
        }
    }
    .el-dialog__body {
        padding: 0;
    }
    .create-group-container {
        display: flex;
        height: 440px;
        .member-list-container {
            width: 274px;
            height: 100%;
        }
        .group-create-container {
            width: 328px;
            border-left: 1px solid #d8d8d8;
            .group-create {
                height: 138px;
                width: 100%;
                padding: 10px 14px 8px 10px;
                border-top: 1px solid #d8d8d8;
                .create-title {
                    color: #444;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
                .group-name-input {
                    margin-bottom: 14px;
                    .el-input__inner {
                        border: 1px solid #c5c5c5;
                        height: 34px;
                        line-height: 34px;
                        border-radius: 6px;
                    }
                }
                .group-btn-list {
                    display: flex;
                    justify-content: space-between;
                    .creat-group-btn, .cancel-create-btn {
                        border-radius: 6px;
                        line-height: 36px;
                        height: 36px;
                        width: 140px;
                    }
                }
            }
        }
    }
}
</style>