const state = {
    keepAliveComponents: []//需要缓存的数组
}

const getters = {
    keepAliveComponents(state){
        return state.keepAliveComponents;
    }
}

const actions = {
    invokeKeepAlive({ commit, state }, component) {
        commit('keepAlive', component);
    },
    invokeNoKeepAlive({ commit, state }, component) {
        commit('noKeepAlive', component);
    },
}

const mutations = {
    keepAlive (state, component) {
        !state.keepAliveComponents.includes(component) &&
            state.keepAliveComponents.push(component)
    },
    noKeepAlive (state, component) {
        const index = state.keepAliveComponents.indexOf(component)
        index !== -1 &&
            state.keepAliveComponents.splice(index, 1)
    }

}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
