<template>
    <div class="current-conversation-wrapper">
        <div class="current-conversation">
            <conversation-header-bar
                :current-conversation="currentConversation"
                :is-large-size="isLargeSize"
                :userProfileMap="userProfileMap"
                :groupInfo="groupInfo"
                @en-large-dialog="$emit('enLargeDialog')"
                @close-instant-message="$emit('closeInstantMessage')"
                @show-group-info="showGroupInfo"
            ></conversation-header-bar>
            <div
                v-loading="messageLoading"
                class="content"
                :class="{'content-bg': !currentConversation.conversationID }">
                <div class="message-list" ref="message-list" @scroll="onScroll">
                    <div class="more" v-show="flipOverType == 0 && !isCompleted" v-loading="messagePagingLoading"></div>
                    <div class="no-more" v-if="currentMessageListFetched && isCompleted">没有更多了</div>
                    <message-item
                        v-for="(message, index) in currentMessageList" 
                        :key="index"
                        :message="message"
                        :deleteMemberNick="deleteMemberNick"
                        :memberList="memberList"
                        :current-conversation="currentConversation"
                        :current-user-profile="currentUserProfile"
                        :isInner="isInner"
                    />
                    <div class="more" v-show="flipOverType == 1 && !isAfterCompleted" v-loading="messagePagingLoading"></div>
                </div>
                <div v-show="!isPositionMode && !hasNewMessage && isShowScrollButtomTips" class="to-bottom-tip" @click="scrollMessageListToButtom">回到初始位置</div>
                <div v-show="!isPositionMode && hasNewMessage && (distanceToBottom > 10)" class="at-me-tips" @click="scrollMessageListToButtom">您有新消息</div>
                <div v-show="isAtMe" class="at-me-tips" @click="scrollToAtMe">
                    有人提到我
                    <i class="el-icon-close" @click.stop="isAtMe = false;"></i>
                </div>
                <div v-show="isPositionMode && hasNewMessage" class="at-me-tips" @click="refreshCurrentConversation">
                    您有新消息
                </div>
                <div v-show="!isAtMe && unreadCount > 10" class="unread-tips" @click="$emit('getMore', {unreadCount: unreadCount - currentMessageList.length})">
                    <i class="el-icon-thumb"></i>
                    {{unreadCount}}条新消息
                    <i class="el-icon-close" @click.stop="unreadCount = 0;"></i>
                </div>
            </div>
            <message-send-box
                ref="messageSendBox"
                :userId="userId"
                :currentConversation="currentConversation"
                :memberList="memberList"
                :toAccount="toAccount"
                @sendMessage="handleSendMessage"
                v-if="!isSystem"></message-send-box>
        </div>
        <div
            class="profile-wrapper"
            v-if="currentConversation.type && currentConversation.type === TIM.TYPES.CONV_GROUP"
            v-show="isShowGroupInfo">
            <group-profile
                ref="groupProfile"
                :group="currentConversation.groupProfile"
                :currentConversation="currentConversation"
                :memberList="memberList.slice(1)"
                :userId="userId"
                :isCompany="companyGroupId === currentConversation.groupProfile.groupID"
                @deleteMember="deleteGroupMember"
                @quitGroup="quitGroupSuccess">
            </group-profile>
        </div>
        <div
            ref="profileWrapperModal"
            class="profile-wrapper-modal"
            v-if="isShowGroupInfo">
        </div>
        <div class="member-profile-card-wrapper"></div>
    </div>
</template>

<script>
import ConversationHeaderBar from './conversation-header-bar.vue';
import MessageItem from '../message/message-item.vue';
import MessageSendBox from '../message/message-send-box.vue';
import GroupProfile from '../group/group-profile.vue';
import { convertMessage } from '../utils/convertIM.js';

export default {
    components: {
        ConversationHeaderBar,
        MessageItem,
        MessageSendBox,
        GroupProfile
    },
    props: {
        currentConversation: Object,
        isLargeSize: Boolean,
        isCompleted: Boolean,
        isAfterCompleted: Boolean,
        currentMessageList: Array,
        currentMessageListFetched: Boolean,
        memberList: Array,
        currentUserProfile: Object,
        userId: String,
        isInner: Boolean,
        messageLoading: Boolean,
        messagePagingLoading: Boolean,
        isPositionMode: Boolean,
        flipOverType: Number,
        companyGroupId: String,
        userProfileMap: Object
    },
    data() {
        return {
            preScrollHeight: 0,
            distanceToBottom: 0,
            isShowScrollButtomTips: false,
            isAtMe: false,
            unreadCount: 0,
            isShowGroupInfo: false,
            groupInfo: {},
            hasNewMessage: false,
            deleteMemberNick: "",
            atMeMsgStart: -1
        }
    },
    computed: {
        toAccount() {
            if(!this.currentConversation.type || this.currentConversation.type === TIM.TYPES.CONV_SYSTEM) {
                return ""
            }
            return this.currentConversation.type === TIM.TYPES.CONV_GROUP ? this.currentConversation.groupProfile.groupID : this.currentConversation.userProfile.userID;
        },
        isSystem() {
            return this.currentConversation.conversationID == 'C2C_openIM123456';
        },
    },
    updated() {
        if(!this.isPositionMode) {
            this.keepMessageListOnButtom()
        }
        // 1. 系统会话隐藏右侧资料组件
        // 2. 没有当前会话时，隐藏右侧资料组件。
        //    背景：退出群组/删除会话时，会出现一处空白区域
        // if (this.currentConversation.conversationID === '@TIM#SYSTEM' || typeof this.currentConversation.conversationID === 'undefined') {
        //     this.showConversationProfile = false
        // }
    },
    methods: {
        refreshGroupInfo() {
            const groupIDList = [this.currentConversation.groupProfile.groupID];
            openIM.getGroupsInfo(groupIDList).then(({ data })=>{
                console.log(data);
                this.groupInfo = JSON.parse(data)[0];
            }).catch(err => {
                console.log(err);
            })
        },
        showGroupInfo() {
            this.isShowGroupInfo = true;
            this.$refs.groupProfile.show();
            document.querySelector('.instant-message-container').addEventListener('click', this.hideGroupInfo);
        },
        hideGroupInfo(ev) {
            if(this.isShowGroupInfo && (!ev || ev.target === this.$refs.profileWrapperModal)) {
                this.isShowGroupInfo = false;
                document.querySelector('.instant-message-container').removeEventListener('click', this.hideGroupInfo);
            }
        },
        onScroll() {
            let messageListNode = this.$refs['message-list']
            if (!messageListNode || !this.currentMessageListFetched) {
                return
            }

            this.distanceToBottom = messageListNode.scrollHeight - messageListNode.offsetHeight - messageListNode.scrollTop;
            if(this.distanceToBottom < 10) {
                this.hasNewMessage = false;
            }

            if(this.preScrollHeight - messageListNode.scrollTop > 1800) {
                this.isShowScrollButtomTips = true
            } else if (this.preScrollHeight - messageListNode.scrollTop <= (messageListNode.clientHeight * 2)) {
                this.isShowScrollButtomTips = false
            }
            if(
                this.isPositionMode
                && !this.isAfterCompleted
                && !this.messageLoading
                && !this.messagePagingLoading 
                && (messageListNode.scrollHeight - messageListNode.clientHeight - messageListNode.scrollTop <= 2)
            ) {
                this.$emit('getMore', {
                    flipOverType: 1
                });
            }
            if(
                !this.isCompleted
                && !this.messageLoading
                && !this.messagePagingLoading
                && messageListNode.scrollTop < 20
            ) {
                if(this.currentMessageList.length >= this.unreadCount) {
                    this.unreadCount = 0;
                }
                this.$emit('getMore', {
                    flipOverType: 0
                });
            }
        },
        // 如果滚到底部就保持在底部，否则提示是否要滚到底部
        keepMessageListOnButtom() {
            let messageListNode = this.$refs['message-list']
            if (!messageListNode) {
                return
            }
            // 距离底部70px内强制滚到底部
            if (this.preScrollHeight - messageListNode.scrollTop - messageListNode.clientHeight < 70) {
                messageListNode.scrollTop = messageListNode.scrollHeight;
            }
            this.preScrollHeight = messageListNode.scrollHeight
        },
        // 直接滚到底部
        scrollMessageListToButtom() {
            this.hasNewMessage = false;
            this.$nextTick(() => {
                let messageListNode = this.$refs['message-list']
                if (!messageListNode) {
                    return
                }
                messageListNode.scrollTop = messageListNode.scrollHeight
                this.preScrollHeight = messageListNode.scrollHeight
                this.isShowScrollButtomTips = false
            })
        },
        handleReceiveMessage() {
            this.hasNewMessage = true;
        },
        handleSendMessage(message) {
            message = convertMessage(message);
            if(message.type === TIM.TYPES.MSG_CUSTOM && !message.payload.data) {
                try {
                    message.payload.ext = JSON.parse(message.payload.extension);
                } catch(err) {
                    message.payload.ext = message.payload.extension;
                }
            } else if(message.type === TIM.TYPES.MSG_CUSTOM && message.payload.data) {
                try {
                    message.payload.ext = JSON.parse(message.payload.data);
                    message.payload.data = null;
                } catch(err) {
                    message.payload.ext = message.payload.data;
                    message.payload.data = null;
                }
            }
            if(this.isPositionMode) {
                this.hasNewMessage = true;
            }else {
                this.$emit('updateMessageList', message);
                this.$nextTick(() => {
                    this.scrollMessageListToButtom();
                    this.isShowScrollButtomTips = false;
                })
            }

            
        },
        refreshCurrentConversation() {
            this.$emit('updateCurrentConversation', {
                conversation: this.currentConversation
            });
        },
        scrollToAtMe() {
            if(this.currentMessageList.length < this.unreadCount) {
                this.$emit('getMore', {unreadCount: this.unreadCount - this.currentMessageList.length});
            } else {
                if(this.currentMessageList.length === 10 && this.unreadCount !== 0) {
                    this.atMeMsgStart = this.currentMessageList.length - this.unreadCount;
                }
                let start = this.atMeMsgStart !== -1 ? this.atMeMsgStart : 0;
                let newMessageList = this.currentMessageList.slice(start, this.currentMessageList.length);
                let atMeMessageIndex = newMessageList.findIndex(message => {
                    return message.msgContent.ext && message.msgContent.ext.type === 'atMessage' && message.msgContent.ext.data.atUsers.some(item => {
                        return item === this.userId || (item === '********-****-****-****-************' && message.toAccountId !== this.userId);
                    })
                })
                if(atMeMessageIndex > -1) {
                    atMeMessageIndex += start;
                    let messageListNode = this.$refs['message-list'];
                    if (!messageListNode) {
                        return
                    }
                    this.isAtMe = false;
                    //跳到@我的消息位置之后提醒消失 此时如果停留在当前会话有新的@消息的时候 需要保证下一次查询是从该位置开始
                    this.atMeMsgStart = this.currentMessageList.length;
                    this.$nextTick(() => {
                        messageListNode.getElementsByClassName('message-wrapper')[atMeMessageIndex].scrollIntoView();
                    })
                }
            }
        },
        deleteGroupMember(nick) {
            this.deleteMemberNick = nick;
            this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                deleteMemberNick: nick
            });
        },
        quitGroupSuccess() {
            this.$emit('quitGroup');
        }
    }
}
</script>

<style lang="scss" scope>
.current-conversation-wrapper {
    position: relative;
    flex-grow: 1;
    min-width: 380px;
    border-left: 1px solid #ddd;

    .more {
        display: flex;
        justify-content: center;
        font-size: 12px;
        height: 30px;
    }

    .no-more {
        display: flex;
        justify-content: center;
        color: #a5b5c1;
        font-size: 12px;
        padding: 10px 10px;
    }

    .current-conversation {
        display: flex;
        flex-direction: column;
        height: 100%;
        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 292px;
            margin-bottom: 0;
            border: 0 none;
            flex: 1;
            flex-grow: 1;
            overflow: hidden;
            &.content-bg {
                background: url('~@src/assets/images/msg_default.png') no-repeat center;
            }
            .message-list {
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                padding: 0 12px;
            }
            .to-bottom-tip {
                position: absolute;
                cursor: pointer;
                padding: 5px;
                width: 120px;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 5px;
                font-size: 12px;
                text-align: center;
                border-radius: 10px;
                border: #ccc 1px solid;
                background-color: #fff;
                color: $primary;
            }
            .at-me-tips, .unread-tips, .new-message-tips {
                position: absolute;
                cursor: pointer;
                color: $primary;
                width: 92px;
                height: 32px;
                line-height: 30px;
                right: 4px;
                bottom: 4px;
                font-size: 12px;
                text-align: center;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                .el-icon-close {
                    cursor: pointer;
                }
            }
            .unread-tips {
                width: 100px;
                top: 8px;
            }
        }
    }
    .profile-wrapper {
        width: 280px;
        height: calc(100% - 40px);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: #fff;
        box-shadow: -2px 2px 6px 0 rgba(164, 164, 164, 0.2);
        overflow: hidden;
        overflow-y: auto;
    }
    .profile-wrapper-modal {
        width: calc(100% + 280px);
        height: 100%;
        z-index: 9;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>