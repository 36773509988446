<template>
    <div>
        <div class="work-report-container" v-if="isShow">
            <div class="work-report-dialog">
                <!-- <span class="view-more-btn" @click="viewMore" v-if="!noMoreBtn">查看往期日报》</span> -->
                <div class="work-report-title">
                    每日工作小结
                    <span>{{date.replace(/-/g,'.')}}</span>
                    <span class="view-more-report" @click="viewMore" v-if="!noMoreBtn">查看往期日报</span>
                    <i class="btn-new close-btn" @click="closeDialog"></i>
                </div>
                <div class="work-report-content" id="workReport">
                    <div class="content-top">
                        <p class="content-title">今日offer数：<span @click="goToDetail({key: 'recommendationDetails', RecommendationDetailType: '4'})">{{reportData.offers}}</span></p>
                        <div class="avatar-wrap">
                            <img :src="reportData.avatarUrl?reportData.avatarUrl:require('@src/assets/images/icon/work-report/avatar.png')"/>
                        </div>
                        <p class="user-name">{{reportData.userName}}</p>
                        <span class="qrcode-wrap"><i class="icon-new icon-scan"></i>扫码分享
                            <div class="qrcode-box" v-if="qrcode">
                                <img :src="qrcode">
                            </div>
                        </span>
                    </div>
                    <ul class="report-list">
                        <li class="report-item">
                        <fieldset>
                            <legend>订单</legend>
                            <div class="report-data">
                                <!-- <p class="data-item" @click="goToDetail({key: 'orderDetails', orderDetailType: '1'})"> -->
                                <p class="data-item" @click="goToDetail({key: 'jobDetails', achievementDetailType: '1'})">
                                    <span class="data-title">创建职位数</span>
                                    <span class="data-num">{{reportData.jobs}}</span>
                                </p>
                                <!-- <p class="data-item" @click="goToDetail({key: 'orderDetails', orderDetailType: '2', JobType: '0'})"> -->
                                <p class="data-item" @click="goToDetail({key: 'orderDetails', achievementDetailType: '1'})">
                                    <span class="data-title">接单数</span>
                                    <span class="data-num">{{reportData.orders}}</span>
                                </p>
                                <!-- <p class="data-item" @click="goToDetail({key: 'orderDetails', orderDetailType: '3'})"> -->
                                <p class="data-item" @click="goToDetail({key: 'jobDetails', orderDetailType: '2'})">
                                    <span class="data-title">职位关键信息更新数</span>
                                    <span class="data-num">{{reportData.keyUpdateJobs}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                        <li class="report-item">
                        <fieldset>
                                <!-- @click="goToDetail({key: 'candidateDetails', CandidateDetailType: '0'})" -->
                            <legend class="report-item-candidate-create" @click="goToDetail({key: 'candidateDetails', achievementDetailType: '1'})">人才创建(<span class="data-num">{{reportData.candidates}}</span>)</legend>
                            <div class="report-data">
                                <!-- <p class="data-item" @click="goToDetail({key: 'candidateDetails', CandidateDetailType: '1'})"> -->
                                <p class="data-item" @click="goToDetail({key: 'candidateDetails', achievementDetailType: '3'})">
                                    <span class="data-title">创建profile数</span>
                                    <span class="data-num">{{reportData.profileCandidates}}</span>
                                </p>
                                <!-- <p class="data-item"  @click="goToDetail({key: 'candidateDetails', CandidateDetailType: '2'})"> -->
                                <p class="data-item"  @click="goToDetail({key: 'candidateDetails', achievementDetailType: '2'})">
                                    <span class="data-title">创建完整简历数</span>
                                    <span class="data-num">{{reportData.completeCandidates}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                        <li class="report-item">
                        <fieldset>
                            <legend>人才查看</legend>
                            <div class="report-data">
                                <!-- <p class="data-item" @click="goToDetail({key: 'candidateViewDetails', CandidateViewDetailType: '1'})"> -->
                                <p class="data-item" @click="goToDetail({key: 'candidateViewDetails', CandidateViewDetailType: '1'})">
                                    <span class="data-title">查看简历数</span>
                                    <span class="data-num">{{reportData.candidateDetailViews}}</span>
                                </p>
                                <!-- <p class="data-item"  @click="goToDetail({key: 'candidateViewDetails', CandidateViewDetailType: '2'})"> -->
                                <p class="data-item"  @click="goToDetail({key: 'candidateViewDetails', CandidateViewDetailType: '2'})">
                                    <span class="data-title">查看联系方式数</span>
                                    <span class="data-num">{{reportData.candidateContactViews}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                        <li class="report-item">
                        <fieldset>
                            <legend>人才更新</legend>
                            <div class="report-data">
                                <p class="data-item"  @click="goToDetail({key: 'candidateUpdateDetails', CandidateUpdateDetailType: '1'})">
                                    <span class="data-title">更新简历数</span>
                                    <span class="data-num">{{reportData.candidateUpdates}}</span>
                                </p>
                                <p class="data-item"  @click="goToDetail({key: 'candidateUpdateDetails', CandidateUpdateDetailType: '2'})">
                                    <span class="data-title">重大更新数</span>
                                    <span class="data-num">{{reportData.candidateKeyUpdates}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                        <li class="report-item">
                        <fieldset>
                            <legend>人才沟通和寻访</legend>
                            <div class="report-data">
                                <p class="data-item"  @click="goToDetail({key: 'candidateInquiryLogDetails', achievementDetailType: '2'})">
                                    <span class="data-title">添加寻访数</span>
                                    <span class="data-num">{{reportData.inquiryLogs}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'candidateInquiryLogDetails', achievementDetailType: '1'})">
                                    <span class="data-title"><i class="red-dot"></i>有效寻访数</span>
                                    <span class="data-num">{{reportData.validInquiryLogs}}</span>
                                </p>
                                <p class="data-item"  @click="goToDetail({key: 'callRecordDetails', achievementDetailType: '1'})">
                                    <span class="data-title">拨打电话数</span>
                                    <span class="data-num">{{reportData.calls}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'callRecordDetails', achievementDetailType: '2'})">
                                    <span class="data-title">有效通话数</span>
                                    <span class="data-num">{{reportData.validCalls}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                        <li class="report-item">
                        <fieldset>
                            <legend>业务流程</legend>
                            <div class="report-data">
                                <p class="data-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '1', OperationStatus: '1'})">
                                    <span class="data-title">推荐人才数（Candidate）</span>
                                    <span class="data-num">{{reportData.recommendCandidates}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '2', OperationStatus: '2'})">
                                    <span class="data-title">接受推荐</span>
                                    <span class="data-num">{{reportData.acceptedRecommendCandidates}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '3', OperationStatus: '3'})">
                                    <span class="data-title">淘汰推荐</span>
                                    <span class="data-num">{{reportData.rejectedCandidateRecommends}}</span>
                                </p>
                                <p class="data-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '4', OperationStatus: '1'})">
                                    <span class="data-title">人才推荐数（Placement）</span>
                                    <span class="data-num">{{reportData.candidateRecommends}}</span>
                                </p>
                                <p class="data-item child-item" @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '5', OperationStatus: '2'})">
                                    <span class="data-title">接受推荐</span>
                                    <span class="data-num">{{reportData.acceptedCandidateRecommends}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '6', OperationStatus: '3'})">
                                    <span class="data-title">淘汰推荐</span>
                                    <span class="data-num">{{reportData.rejectedCandidateRecommends}}</span>
                                </p>
                                <p class="data-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '7', OperationStatus: '1'})">
                                    <span class="data-title">面试数（Interview）</span>
                                    <span class="data-num">{{reportData.interviewCandidates}}</span>
                                </p>
                                <p class="data-item child-item" @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '8', OperationStatus: '2'})">
                                    <span class="data-title">面试通过</span>
                                    <span class="data-num">{{reportData.interviewPassedCandidates}}</span>
                                </p>
                                <p class="data-item child-item"  @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '9', OperationStatus: '3'})">
                                    <span class="data-title">面试失败</span>
                                    <span class="data-num">{{reportData.interviewFailedCandidates}}</span>
                                </p>
                            </div>
                        </fieldset>
                        </li>
                    </ul>
                </div>
                <div class="work-report-footer">
                    <div class="btn-wrap">
                        <span class="btn-new btn-scan"><i class="icon-new icon-scan"></i>扫码分享
                            <div class="qrcode-box">
                                <img :src="qrcode">
                            </div>
                        </span>
                        <span class="btn-new" @click="scrollToTop"><i class="icon-new icon-top"></i>回到顶部</span>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            :title="moreReportTitle"
            class="more-report-dialog"
            :visible.sync="moreReportDialogVisible"
            :modal="false"
            width="540px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <summary-list @getTotalCount="getTotalCount" @showDialog="showDialog"></summary-list>
        </el-dialog>
    </div>

</template>

<script>
    import summaryList from '#/component/summary/summary-list.vue'
    import { daily_report as daily_report_url } from '#/js/config/api.json';

    export default {
        name: "summaryDialog",
        props:{
            closeSummaryDialog: Function
        },
        components: {
            summaryList
        },
        data() {
            return {
                moreReportTitle: "全部小结",
                uuid: '',
                isShow: false,
                moreReportDialogVisible: false,
                reportData:{
                    jobs: 0,
                    orders: 0,
                    keyUpdateJobs: 0,
                    candidates: 0,
                    profileCandidates: 0,
                    completeCandidates: 0,
                    waitInquiryCandidates: 0,
                    candidateDetailViews: 0,
                    candidateContactViews: 0,
                    candidateUpdates: 0,
                    candidateKeyUpdates: 0,
                    inquiryLogs: 0,
                    validInquiryLogs: 0,
                    calls: 0,
                    validCalls: 0,
                    recommendCandidates: 0,
                    acceptedRecommendCandidates: 0,
                    rejectedRecommendCandidates: 0,
                    candidateRecommends: 0,
                    acceptedCandidateRecommends: 0,
                    rejectedCandidateRecommends: 0,
                    interviewCandidates: 0,
                    interviewPassedCandidates: 0,
                    interviewFailedCandidates: 0,
                    offers: 0,
                    avatarUrl: null,
                    userName: ''
                },
                qrcode:null,
                date: '',
                userId: '',
                noMoreBtn: false,
                triggerType: 0,
                loading: false,
            }
        },
        created() {
            window.summaryVM = this;
        },
        computed:{
            unionId(){
                return this.$store.state.user.userInfo.unionId;
            }
        },
        methods: {
            getTotalCount(totalCount) {
                this.moreReportTitle = `全部小结(${totalCount})`;
            },
            handleClose() {
                this.moreReportDialogVisible = false;
                // this.noMoreBtn = false;
                this.isShow = true;
            },
            scrollToTop(){
                let contentDom = document.getElementById('workReport');
                contentDom.scrollTop = 0;
            },
            closeDialog(){
                this.isShow = false;
                this.closeSummaryDialog && this.closeSummaryDialog();
                // 移除上一次选中的工作日报的样式
                $('.summary-item').removeClass('summary-item-active');
                // if (window.socketServer && socketServer.server.sendCloseWorkReportAsync) {
                //     socketServer.server.sendCloseWorkReportAsync(this.$data.userId);
                // }
                if(window._globalSignalR){
                    _globalSignalR.signalRSend('SendCloseWorkReportAsync');
                }
            },
            getReport(userid){
                const upaParam = {
                    "UPASession": JSON.stringify({
                        "PageViewId" : this.uuid
                    })
                };
                this.loading = true;
                _request({
                    url: daily_report_url.my_daily_report,
                    method: 'GET',
                    headers: upaParam,
                    data: {
                        date: this.date,
                        userId: userid,
                        triggerType: this.triggerType
                    }
                })
                .then(res => {
                    this.reportData = res;
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            init(userid,isToday,time,triggerType){
                // console.log(userid)
                let timeStamp,date;
                if(time){
                    timeStamp = time;
                    date = this.formatDate(timeStamp);
                }else{
                    timeStamp = new Date().getTime();
                    date = isToday?this.formatDate(timeStamp):this.formatDate(timeStamp-24*60*60*1000);
                }
                this.date = date;
                this.userId = userid;
                this.triggerType = triggerType;
                this.qrcode = `${_host.portal}/Sharing/QRCode?url=`+encodeURIComponent(`${_host.h5_page}/report.html?date=${timeStamp}&userid=${userid}&isShare=true`);
                this.getReport(userid);
            },
            showDialog(options){
                this.uuid = _generateUUID();
                let userId;
                if(options.user){
                    userId = options.user.id;
                }else{
                    //为兼容老版的页面，从input隐藏域取值
                    userId = document.querySelector('input[name="userId"]').value;
                }
                // let noMoreBtn = options.noMoreBtn || false;
                this.init(userId,options.isToday,options.timeStamp,options.triggerType);
                this.isShow = true;
                this.moreReportDialogVisible = false;
                // this.noMoreBtn = noMoreBtn;
            },
            formatDate(timeStamp){
                let date = new Date(timeStamp);
                let year = date.getFullYear();
                let month = date.getMonth()+1;
                let day = date.getDate();
                return `${year}-${month<9?'0'+month:month}-${day<9?'0'+day:day}`;
            },
            viewMore(){
                // window.open('/Headhunting/MyCompany.html#/workReport');
                this.moreReportDialogVisible = true;
                // this.noMoreBtn = true;
                this.isShow = false;
            },
            goToDetail(data) {
                let json = {};
                json.userIds = [this.userId];
                json.startDate = this.date;
                json.endDate = this.date;
                json.triggerType = this.triggerType;
                json = Object.assign({}, json, data);
                sessionStorage.removeItem('perpormancesDetailData');
                localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
                // window.open('/MyCompany/PerformancesDetail', '_blank');
                // window.open('/Headhunting/MyCompany.html?frompage=daily#/performancesDetail', '_blank');
                // window.open('/?frompage=daily#/performancesDetail', '_blank');
                window.open('/Headhunting/#/performancesDetail', '_blank');
                return false;
            }
        },
    }
</script>

<style lang="scss">
    .work-report-container{
        height: 100%;
        overflow-y: auto;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.3);
        z-index: 9999;
        .icon-new{
            display: inline-block;
            width: 12px;
            height: 12px;
            vertical-align: middle;
            &.icon-scan{
                background: url('~@src/assets/images/icon/work-report/icon_scan.png') center no-repeat;
                background-size: cover;
                margin-right: 4px;
                margin-top: -2px;
            }
            &.icon-top{
                background: url('~@src/assets/images/icon/work-report/icon_top.png') center no-repeat;
                background-size: cover;
                margin-right: 4px;
                margin-top: -2px;
            }
        }
    }
    .work-report-dialog{
        width: 338px;
        // height: 80%;
        // height: 728px;
        height: 665px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // transform: translate(-100%,-50%);
        background: #fff;
        border-radius: 8px;
        padding: 16px 8px 0 16px;
        color: #444;
        font-family: "Microsoft Yahei";
        // .view-more-btn{
        //     position: absolute;
        //     height: 24px;
        //     line-height: 24px;
        //     text-align: center;
        //     top: -30px;
        //     left: 0;
        //     background: #38BC9C;
        //     border-radius: 18px;
        //     color: #fff;
        //     padding: 0 8px 0 14px;
        //     cursor: pointer;
        // }
        .view-more-report {
            cursor: pointer;
            color: #38BC9C !important;
            padding: 0 !important;
        }
        .work-report-title{
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 6px;
            >span{
                font-size: 12px;
                color: #666;
                padding: 0 16px;
                font-weight: bold;
            }
        }
        .qrcode-box{
            position: absolute;
            bottom: 100%;
            border: 1px solid #38BC9C;
            padding: 4px;
            display: none;
            background-color: #fff;
        }
        .work-report-content{
            position: absolute;
            top: 50px;
            left: 16px;
            right: 8px;
            bottom: 47px;
            font-size: 14px;
            /*height: 632px;*/
            overflow-y: auto;
            //定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸
            &::-webkit-scrollbar
            {
                width: 8px;
                height: 4px;
                background-color: #fff;
            }

            //定义滚动条轨道 内阴影+圆角
            &::-webkit-scrollbar-track
            {
                //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: #fff;
            }

            //定义滑块 内阴影+圆角
            &::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #eee;
            }
            .content-title{
                >span{
                    color: #F98F33;
                    cursor: pointer;
                }
            }

            .content-top{
                position: relative;
                .user-name{
                    text-align: center;
                    width: 160px;
                    margin: 0 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }
                .avatar-wrap{
                    width: 41px;
                    height: 41px;
                    border: 1px solid #38BC9C;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 10px auto;
                    >img{
                        width: 100%;
                        height: 100%;
                    }
                }

                .qrcode-wrap{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    font-size: 12px;
                    color: #999;
                    cursor: pointer;

                    .qrcode-box > img {
                        width: 54px;
                        height: 54px;
                    }

                    &:hover{
                        .qrcode-box{
                            display: block;
                        }
                    }
                }
            }
            .report-list{
                .report-item{
                    margin-bottom: 10px;
                    fieldset{
                        margin: 0;
                        padding: 0;
                        border: none;
                        font-size: 14px;
                        border-top:1px dashed #eee;

                    }
                    legend{
                        font-size: 14px;
                        width: auto;
                        padding-right: 6px;
                        margin-bottom: 10px;
                        border: none;
                    }

                    .report-item-candidate-create {
                        cursor: pointer;
                    }
                    .data-num{
                        flex-grow: 1;
                        text-align: right;
                        color: #F98F33;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
        }
        .btn-wrap{
            text-align: center;
            padding: 12px 0;
            .btn-new{
                padding: 6px 15px;
                margin: 0 14px;
                border-radius: 12px;
                position: relative;
                &:hover{
                    background: #EEE;
                    color: #38BC9D;
                }
                &.btn-scan{
                    &:hover{
                        .qrcode-box{
                            display: block;
                            left: 6px;
                            bottom: 110%;
                        }
                    }

                }
            }
        }
    }

    .el-dialog__wrapper.more-report-dialog {
        // height: 665px;
        // position: absolute !important;
        // top: 50% !important;
        // left: 50% !important;
        // // transform: translate(-50%,-50%);
        // transform: translate(0,-50%);

        // overflow: visible !important;

        .el-dialog {
            border-radius: 8px;
            margin-top: 0 !important;

            .el-dialog__title {
                font-size: 16px;
                font-weight: bold;
            }

            .el-dialog__header {
                border-bottom: none;
            }

            .el-dialog__body {
                padding: 0 15px 20px;
            }
        }
    }

    .btn-new{
        cursor: pointer;
        &.close-btn{
            position: absolute;
            display: inline-block;
            width: 20px;
            height: 20px;
            right: 11px;
            top: 16px;
            background: url('~@src/assets/images/icon/work-report/icon_close.png') center no-repeat;
            border-radius: 50%;
            &:hover{
                background-color: rgba(216,216,216,.4);
            }
        }
    }


    .report-data{
        padding: 0 34px;

        .data-item{
            margin-bottom: 6px;
            display: flex;
            justify-content: space-between;
            color: #666;
            cursor: pointer;
            &.child-item{
                color: #999;
                padding-left: 10px;
                .data-num{
                    font-weight: normal;
                }
            }
        }
    }
    .red-dot{
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #FF6564;
        vertical-align: middle;
        margin-right: 4px;
    }
    .work-report-footer{
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 8px;
        height: 47px;
        background-color: #fff;
    }



</style>
