import {
    job as jobUrl, department as departmentUrl,
    recommend as recommendUrl,
    data as dataUrl,
} from '#/js/config/api.json';

const URL = {
    getJobInfo: '/Headhunting/JobsBatchImport/GetJobs',
    getShareJobInfo: '/Job/%p/EmailSharingJobs',
    getRecommendCandidate: '/Headhunting/Candidate/QueryCandidate',
    getRecommendJob: '/Candidate/%p/JobsForRecommendation'
};

export default {
    /**
     * 获取职位信息
     * @param batchNo 职位id
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getJobInfo (batchNo) {
        return _request({
            method: 'GET',
            url: URL.getJobInfo,
            data: {
                batchNo: batchNo ? batchNo : undefined,
            }
        });
    },
    getShareJobInfo (batchNo) {
        return _request({
            method: 'GET',
            url: URL.getShareJobInfo.replace('%p', batchNo),
        });
    },
    /**
     * 推荐弹框--搜索职位
     * @param {
     *  candidateId: 0  候选人ID
     *  orderBy: 0		排序方式：0为更新时间，1为发布时间，2为佣金金额，3为佣金比例
     *  jobSource: 1	来源：1为内部职位，2为市场抢单
     *  keyword: 		搜索关键词
     *  start: 0		每一页展示的第一个索引
     *  take: 6		一页展示数量
     * }
     * @returns {
     *  list: [],
     *  total: 0	职位全部数量
     * }
     */
    getJobsForRecommendation (candidateId, param = { orderBy, jobSource, keyword = '', start, take } = {}) {
        param._ = new Date().getTime();
        return _request({
            url: URL.getRecommendJob.replace(/\%p/ig, candidateId),
            method: 'GET',
            data: param
        });
    },
    /**
     * 推荐弹框--搜索候选人
     * @param {
     *  JobId: 0        职位ID
     *  Location: 0		城市ID
     *  keyword: 		搜索关键词
     *  start: 0		每一页展示的第一个索引
     *  take: 6		    一页展示数量
     * }
     * @returns {
     *  list: [],
     *  total: 0	职位全部数量
     * }
     */
    getCandidatesForRecommendation (param = { JobId, Location, Keywords = '', Sort = 1, start, take } = {}) {
        param._ = new Date().getTime();
        return _request({
            url: URL.getRecommendCandidate,
            method: 'GET',
            data: param
        });
    },
    /**
     * 僵尸职位弹框--获取僵尸职位
     * @param {
        *  includeDetail: Boolean       是否需要返回僵尸职位列表，为true则返回
     * }
     * @returns {
     *  ossifiedJobs: [],   僵尸职位列表
     *  total: 0	职位全部数量
     * }
     */
    getStandStillJob (param = { includeDetail = false } = {}) {
        return _request({
            url: jobUrl.get_standstill_job,
            method: 'GET',
            data: param,
            throwBusinessError: true
        });
    },
    /**
     * 职位分享弹窗--单个分享职位信息/编辑对外信息初始化
     * @param {
        *  jobId: String        职位Id
        *  jobSharingId: String   职位分享Id 若为单个分享职位弹窗则传空字符串
        *  unionId: String      用户unionId 调用openAPI需要加在头部
     * }
     * @returns {}
     */
    getSingleJobShareInfo (params = { jobId, jobSharingId } = {}) {
        return _request({
            url: jobUrl.initialize_share_edit,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        });
    },
    /**
     * 职位分享弹窗--单个分享职位/单个添加到职位名片提交
     */
    singleJobShareInfoAdd (params = {}) {
        return _request({
            url: jobUrl.single_job_share_confirm,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        });
    },
    /**
     * 职位分享弹窗--编辑对外信息
     */
    singleJobShareInfoUpdate (params = {}) {
        return _request({
            url: jobUrl.update_job_share_info,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        });
    },
    /**
     * 职位分享弹窗--批量添加到职位卡片初始化
     */
    getBatchJobShareInfo (ids) {
        return _request({
            url: jobUrl.batch_job_share_edit_info,
            method: 'POST',
            data: ids,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        });
    },
    /**
     * 职位分享弹窗--单个分享职位提交
     */
    batchAddJobShareInfo (params = {}) {
        return _request({
            url: jobUrl.batch_add_job_share_card,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        });
    },
    getJobList (params = {}) {
        return _request({
            url: jobUrl.job_list,
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },
    getHRJobList (params = {}) {
        return _request({
            url: jobUrl.hr_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    getJobRecordStatus (params = {}) {
        return _request({
            method: 'POST',
            url: jobUrl.url_check_list_record_status,
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params,
            throwBusinessError: true,
            throwHttpError: true
        });
    },
    getJobOrdersCount (params = {}) {
        return _request({
            method: 'POST',
            url: jobUrl.job_orders_count_top,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params
        });
    },
    getRecruitingJobList (params) {
        return _request({
            method: 'GET',
            url: jobUrl.recruiting_job_list,
            data: params
        });
    },
    getJobDetail (params = { jobId } = {}) {
        return _request({
            url: jobUrl.url_job_info.replace(/\%p/ig, params.jobId),
            method: 'GET'
        });
    },
    bindJobDepartment (params) {
        return _request({
            url: departmentUrl.job_bind_department.replace(/\%p/ig, params.jobId).replace(/\%d/ig, params.departmentId),
            method: 'POST'
        });
    },
    getUnbindDeptJobList (params = {}) {
        return _request({
            url: jobUrl.get_Unbind_dept_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    batchBindJobDept (params = {}) {
        return _request({
            url: jobUrl.batch_bind_job_dept,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    // 修改职位状态
    updateJobStatsus (params = {}) {
        return _request({
            url: jobUrl.update_job_statsus,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },

    // 猎企职位的抢单

    grabHrJob (params = {}) {
        return _request({
            url: jobUrl.grab_hr_order.replace(/\%p/ig, params.jobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },

    // 职位推荐回复列表
    recommendationEvaluateReplies (commentId) {
        return _request({
            url: recommendUrl.recommendation_evaluate_replies.replace(/\%p/ig, commentId),
            method: 'GET',
        });
    },

    // 判断是否可以评价，如果没有replyCommentId就表示是否可以回复的
    checkCanEvaluate (recommendationId, replyCommentId = '') {
        return _request({
            url: jobUrl.check_can_evaluate.replace(/\%p/ig, recommendationId),
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: {
                replyCommentId: replyCommentId,
            },
        });
    },
    getJobCategoryData(firmId = '') {
        return _request({
            url: dataUrl.job_category + `?firmId=${firmId}`,
            method: 'GET',
            apiType: 'oldApi',
        });
    },


    //  协议职位 Start
    jobDistributeBind (params = {}) {
        return _request({
            url: jobUrl.job_distribute_bind,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    jobDistributeUnBind (params = {}) {
        return _request({
            url: jobUrl.job_distribute_unbind.replace(/\%p/ig, params.jobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    getUnbindJobList(params = {}) {
        return _request({
            url: jobUrl.job_distribute_get_not_bind_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    getBindJobList(params = {}) {
        return _request({
            url: jobUrl.job_distribute_bind_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    
    getDistributeJobList(params = {}) {
        return _request({
            url: jobUrl.get_distribute_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi"
        });
    },
    getDistributeAllJobList (params = {}) {
        return _request({
            url: jobUrl.distribute_job_list,
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //  协议职位 End

    

};
