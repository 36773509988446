<template>
    <div class="member-list-wrapper">
        <div class="search-box">
            <div class="search-box-select">
                <el-dropdown 
                    class="search-box-dropdown" 
                    placement="bottom-start" 
                    trigger="click"
                    @command="handleCommand"
                >
                    <span class="el-dropdown-link">
                        {{searchType == 0 ? '内部' : '外部'}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="search-box-menu" slot="dropdown">
                        <el-dropdown-item command="0" :class="{'is-selected': searchType == 0}">内部</el-dropdown-item>
                        <el-dropdown-item command="1" :class="{'is-selected': searchType == 1}">外部</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="search-box-input">
                <input 
                    maxlength="100" 
                    type="text" 
                    placeholder="搜索名字、昵称..." 
                    v-model.trim="keyword"
                    @keyup.enter="handleSearch"
                />
                <span v-show="searchMode" class="search-box-input-clear el-icon-error" @click="handleClearSearch"></span> 
            </div>
        </div>
        <ul v-show="!searchMode" ref="team-list" class="team-list" v-loading="teamListLoading">
            <li class="team-item" v-for="(team, index) in teamList" :key="'team' + team.teamId">
                <p 
                    class="team-name" 
                    :class="team.expanded ? 'active' : ''" 
                    @click="handleExpandTeam(team, index)"
                >
                    {{team.teamName}} ({{team.count}})
                    <i v-if="team.count > 0 && !team.expanded" class="el-icon-arrow-right"></i>
                    <i v-if="team.count > 0 && team.expanded" class="el-icon-arrow-down"></i>
                </p>
                <ul v-show="team.expanded" v-loading="team.memberListLoading">
                    <li v-for="member in team.memberList" :key="member.unionId">
                        <member-item 
                            :member="member"
                            type="teamMemberList"
                            :disabled="checkMemberDisabled(member.unionId)"
                            :value="checkMemberSelected(member.unionId)"
                            @member-select="handleMemberSelect"
                        ></member-item>
                    </li>
                </ul>
            </li>
        </ul>

        <ul class="inner-member-list" v-show="searchMode && searchType == 0" v-if="showSearchInnerMemberList">
            <template v-for="member in innerMemberList">
                <li
                    v-if="member.realName.indexOf(innerSearchKeyword) > -1 || member.nickname.indexOf(innerSearchKeyword) > -1" 
                    :key="member.unionId"
                >
                    <member-item 
                        :member="member"
                        type="innerMemberList"
                        :disabled="checkMemberDisabled(member.unionId)"
                        :value="checkMemberSelected(member.unionId)"
                        @member-select="handleMemberSelect"
                    ></member-item>
                </li>
            </template>
        </ul>
        <ul 
            class="outer-member-list" 
            v-loading="outerListLoading"
            v-show="searchMode && searchType == 1"
            v-infinite-scroll="getOuterMemberList"
            :infinite-scroll-immediate="false"
            :infinite-scroll-disabled="!outerListFetched || outerListPageLoading || outerListHasNoMore"
        >
            <li v-for="member in outerMemberList" :key="member.unionId">
                <member-item
                    :member="member"
                    type="outerMemberList"
                    :disabled="checkMemberDisabled(member.unionId)"
                    :value="checkMemberSelected(member.unionId)"
                    @member-select="handleMemberSelect"
                ></member-item>
            </li>
            <p class="loading-info" v-if="outerListFetched && outerListPageLoading">加载中...</p>
            <p class="loading-info" v-if="outerListFetched && outerListHasNoMore">没有更多了</p>
        </ul>
        <div class="empty-data" v-if="showEmptyTip">
            暂无数据
        </div>
    </div>
</template>

<script>
import imService from '#/js/service/openIMService.js';
import MemberItem from './member-item.vue';
export default {
    components: {
        MemberItem
    },
    props: {
        userId: String
    },
    data() {
        return {
            searchMode: false,
            searchType: 0, //0:内部, 1:外部
            teamListLoading: false,
            teamList: [],
            selectedMemberList: [],
            keyword: '',
            innerSearchKeyword: '',
            innerMemberList: [],
            outerMemberList: [],
            outerListFetched: false,
            outerListLoading: false,
            outerListPageLoading: false, //滚动分页loading
            outerListHasNoMore: false,
            outerPage: 1,
            outerPageSize: 20
        }
    },
    mounted() {},
    computed: {
        showEmptyTip() {
            if(
                (this.searchMode && this.searchType == 0 && !this.showSearchInnerMemberList) 
                || (this.searchMode && this.searchType == 1 && !this.outerListFetched)
                || (!this.searchMode && !this.teamList.length && !this.teamListLoading)
            ){
                return true;
            }
            return false;
        },
        showSearchInnerMemberList() {
            if(this.searchMode && this.searchType == 0) {
                return this.innerMemberList.filter(item => item.realName.indexOf(this.innerSearchKeyword) > -1 || item.nickname.indexOf(this.innerSearchKeyword) > -1).length > 0
            }
            return false
        }
    },
    methods: {
        init() {
            this.getTeamList();
            this.getInnerMemberList();
        },
        reset() {
            this.searchMode = false;
            this.searchType = 0;
            this.keyword = '';
            this.innerSearchKeyword = '';
            this.selectedMemberList = [];
            this.innerMemberList = [];
            this.outerMemberList = [];
            this.outerListFetched = false;
            this.outerListLoading = false;
            this.outerListPageLoading = false;
            this.outerListHasNoMore = false;
            this.outerPage = 1;
        },
        handleCommand(command) {
            this.searchType = command;
            if(command == 1) {
                this.keyword = '';
                this.searchMode = true;
            }else {
                this.keyword = '';
                this.searchMode = false;
            }
        },
        handleMemberSelect(value, member) {
            let selectedIndex = -1;
            this.selectedMemberList.some((item, index) =>{
                if(item.unionId == member.unionId) {
                    selectedIndex = index;
                    return true;
                }
            })
            if(value) {
                if(selectedIndex == -1) {
                    this.selectedMemberList.push(member);
                }
            }else {
                if(selectedIndex > -1) {
                    this.selectedMemberList.splice(selectedIndex, 1);
                }
            }
            this.$emit('memberSelected', this.selectedMemberList);
        },
        getChildTeams(list, result) {
            list.forEach(item =>{
                let obj = {
                    teamName: item.teamName,
                    teamId: item.teamId,
                    count: item.count,
                    expanded: false,
                    memberFetched: false,
                    memberListLoading: false,
                    memberList: []
                }
                result.push(obj);
                if(item.childTeams.length >0) {
                    this.getChildTeams(item.childTeams, result);
                }
            })
        },
        getTeamList() {
            this.teamListLoading = true;
            const params = {
                DeepestLevel: 1, // 最深层级
                Type: 1, // 统计的数据类型
            };
            imService.getTeamList(params)
                .then(res =>{
                    let result = [];
                    this.getChildTeams(res.childTeams, result);
                    this.teamList = result;
                }).finally(() =>{
                    this.teamListLoading = false;
                })
        },
        getMemberList(team) {
            imService.getMemberList({
                teamId: team.teamId
            }).then(res => {
                team.memberFetched = true;
                team.memberListLoading = false;
                team.memberList = team.memberList.concat(res.list || []);
            })
        },
        handleExpandTeam(team, index) {
            if(team.count <= 0) {
                return false;
            }
            if(team.expanded) {
                team.expanded = false;
            }else {
                this.teamList.forEach(item =>{
                    item.expanded = false;
                })
                this.$refs['team-list'].getElementsByClassName('team-name')[index].scrollIntoView();
                team.expanded = true;
                if(!team.memberFetched) {
                    team.memberListLoading = true;
                    this.getMemberList(team);
                }
            }
        },
        checkMemberSelected(unionId) {
            return unionId === this.userId || this.selectedMemberList.some(item => {
                return item.unionId === unionId
            });
        },
        checkMemberDisabled(unionId) {
            return unionId === this.userId || this.selectedMemberList.some(item =>{
                return item.unionId === unionId && item.disabled;
            });
        },
        getInnerMemberList() {
            imService.getMemberList({
                teamId: "0",
                take: -1
            }).then(res =>{
                this.innerMemberList = res.list;
            })
        },
        getOuterMemberList() {
            if(this.outerPage == 1) {
                this.outerListLoading = true;
            }
            this.outerListPageLoading = true;
            imService.getOuterMemberList({
                keyword: this.keyword,
                offset: (this.outerPage - 1) * this.outerPageSize,
                pageSize: this.outerPageSize
            }).then(res =>{
                this.outerListFetched = true;
                this.outerMemberList = this.outerMemberList.concat(res.records);
                this.outerPage += 1;
                if(res.records.length < this.outerPageSize) {
                    this.outerListHasNoMore = true;
                }else {
                    if(res.current < res.pages) {
                        this.outerListHasNoMore = false;
                    }else {
                        this.outerListHasNoMore = true;
                    }
                }
            }).finally(() => {
                this.outerListLoading = false;
                this.outerListPageLoading = false;
            })
        },
        handleSearch() {
            if(this.keyword) {
                this.searchMode = true;
                if(this.searchType == 0) {
                    this.innerSearchKeyword = this.keyword;
                }else {
                    this.outerMemberList = [];
                    this.outerPage = 1;
                    this.outerListFetched = false;
                    this.outerListHasNoMore = false;
                    this.getOuterMemberList();
                }
            }else {
                if(this.searchType == 0) {
                    this.searchMode = false;
                }else {
                    this.outerMemberList = [];
                }
            }
        },
        handleClearSearch() {
            this.keyword = '';
            if(this.searchType == 0) {
                this.searchMode = false;
                this.innerSearchKeyword = '';
            }else {
                this.outerMemberList = [];
                this.outerListFetched = false;
                this.outerListLoading = false;
                this.outerListPageLoading = false;
                this.outerListHasNoMore = false;
                this.outerPage = 1;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .member-list-wrapper {
        width: 100%;
        padding: 0 14px;
        margin-top: 14px;

        .search-box {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 28px;
            padding: 0;
            margin: 0;
            background-color: #F8F8F8;
            border: 1px solid #DDDDDD;
            border-radius: 4px;

            &-select {
                position: relative;
                text-align: center;
                line-height: 26px;
                cursor: pointer;

                /deep/ .search-box-dropdown {
                    min-width: 66px;
                    display: block;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 18px;
                    background-color: #DDDDDD;
                }
            }

            &-input {
                position: relative;
                width: 100%;
                vertical-align: top;

                input {
                    padding-left: 6px;
                    padding-right: 26px;
                    max-width: 499px;
                    width: 100%;
                    height: 26px;
                    line-height: 26px;
                    outline: unset;
                    border: unset;
                    background-color: #F8F8F8;
                    vertical-align: middle;
                }

                &-clear {
                    position: absolute;
                    top: 50%;
                    right: 6px;
                    margin-top: -7px;
                    display: none;
                }

                &:hover .search-box-input-clear {
                    display: block;
                }
            }

        }

        .team-list, .inner-member-list, .outer-member-list {
            min-height: 100px;
            max-height: 350px;
            margin-top: 12px;
            margin-right: -14px;
            font-size: 14px;
            color: #4A4A4A;
            user-select: none;
            overflow-y: scroll;
        }

        .team-list {

            .team-item {
                padding: 0;
                padding-right: 14px;
                .team-name {
                    display: flex;
                    flex-direction: horizontal;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 36px;
                    cursor: default;
                    margin: 0;

                    &.active {
                        font-weight: bold;
                        background-color: #fff !important;
                    }
                }

                >ul {
                    width:100%;
                    min-height: 30px;
                }
                &:hover {
                    .team-name {
                        background-color: #f8f8f8;
                    }
                }
            }
            li:hover {
                background-color: #fff;
            }
        }
        .member-item {
            height: auto;
            margin: 0;
            padding: 5px 0;
            border-bottom: none;
            &:hover {
                background-color: #f8f8f8;
            }
            img {
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 50%;
            }

            .member-text-info {
                display: inline-block;
                vertical-align: top;
                
                .member-name {
                    display: block;
                    max-width: 160px;
                }
            }
        }

        .outer-member-list {
            .loading-info {
                text-align: center;
            }
        }
        .empty-data {
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0px;
        }
    }
</style>

<style lang="scss">
.search-box-menu.el-dropdown-menu {
    padding: 0;
    .el-dropdown-menu__item.is-selected {
        background-color: #ebf8f5;
        color: #60c9b1;
    }
}
</style>