var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showNotice,
          expression: "showNotice",
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "notice-container",
    },
    [
      _c("div", { staticClass: "notice-title" }, [
        _c("span", { staticClass: "title-text" }, [_vm._v("事项提醒")]),
        _c("span", { staticClass: "date-picker" }, [
          _c("button", { on: { click: _vm.handleMonthMinus } }, [
            _c("i", { staticClass: "el-icon-arrow-left" }),
          ]),
          _vm._v(
            "\n            " +
              _vm._s(_vm.date.month) +
              "月 " +
              _vm._s(_vm.date.year) +
              "\n            "
          ),
          _c("button", { on: { click: _vm.handleMonthPlus } }, [
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]),
        ]),
        _c("span", {
          staticClass: "notice-close",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClose.apply(null, arguments)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "notice-tab" }, [
        _c(
          "span",
          {
            staticClass: "tab-item delay-item",
            class: _vm.statusSelect == 2 ? "isSelect" : "",
            on: {
              click: function ($event) {
                return _vm.tabNotice(2)
              },
            },
          },
          [_vm._v("已延期事项  " + _vm._s(_vm.isPastCount))]
        ),
        _c(
          "span",
          {
            staticClass: "tab-item done-item",
            class: _vm.statusSelect == 1 ? "isSelect" : "",
            on: {
              click: function ($event) {
                return _vm.tabNotice(1)
              },
            },
          },
          [_vm._v("已完成事项  " + _vm._s(_vm.isDoneCount))]
        ),
      ]),
      _vm.noticeList.length > 0
        ? _c(
            "ul",
            { staticClass: "notice-list" },
            _vm._l(_vm.noticeList, function (item) {
              return _c(
                "li",
                {
                  key: item.id,
                  staticClass: "notice-item",
                  class:
                    item.status == 1
                      ? "done-item"
                      : item.status == 2
                      ? "past-item"
                      : "",
                },
                [
                  _c("p", { staticClass: "notice-info" }, [
                    _c("span", { staticClass: "info-name" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `/#/candidateDetail/${item.resumeId}`,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(item.realName))]
                      ),
                    ]),
                    item.isToday
                      ? _c("span", { staticClass: "info-time" }, [
                          _vm._v(
                            "今日 " +
                              _vm._s(_vm._f("getHM")(item.remindTime)) +
                              "事项"
                          ),
                        ])
                      : _c("span", { staticClass: "info-time color-gray" }, [
                          _vm._v(
                            _vm._s(_vm._f("showMonthTime")(item.remindTime)) +
                              "事项"
                          ),
                        ]),
                    _c("span", { staticClass: "info-status" }, [
                      _vm._v(_vm._s(_vm._f("statusText")(item.status))),
                    ]),
                  ]),
                  _c("p", { staticClass: "notice-content" }, [
                    _vm._v(_vm._s(item.content)),
                  ]),
                  _c("notice-opr", {
                    attrs: { noticeItem: item, index: item.index },
                    on: {
                      handleDone: _vm.handleDone,
                      handleDel: _vm.handleDel,
                      keepVisible: _vm.keepVisible,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "no-notice-data" }, [
            _vm._v("\n        暂无提醒事项\n    "),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }