var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "conversation-header-bar",
      on: { click: _vm.handleCloseInstantMessage },
    },
    [
      _c(
        "div",
        {
          staticClass: "conversation-info",
          style: _vm.currentConversation.conversationID
            ? {}
            : { visibility: "hidden" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm.currentConversation.type == _vm.TIM.TYPES.CONV_C2C
            ? _c(
                "span",
                {
                  staticClass: "chat-name-c2c",
                  attrs: { title: _vm.currentConversation.userProfile.nick },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.conversationName) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm.currentConversation.type == _vm.TIM.TYPES.CONV_C2C &&
          _vm.currentConversation.conversationID !== "C2C_openIM123456"
            ? _c("span", { staticClass: "user-info" }, [
                _c(
                  "span",
                  {
                    staticClass: "user-info-firmname",
                    attrs: {
                      title:
                        (_vm.userProfileMap[
                          _vm.currentConversation.userProfile.userID
                        ] &&
                          _vm.userProfileMap[
                            _vm.currentConversation.userProfile.userID
                          ].company) ||
                        "",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          (_vm.userProfileMap[
                            _vm.currentConversation.userProfile.userID
                          ] &&
                            _vm.userProfileMap[
                              _vm.currentConversation.userProfile.userID
                            ].company) ||
                            ""
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "user-info-title",
                    attrs: {
                      title:
                        (_vm.userProfileMap[
                          _vm.currentConversation.userProfile.userID
                        ] &&
                          _vm.userProfileMap[
                            _vm.currentConversation.userProfile.userID
                          ].title) ||
                        "",
                    },
                  },
                  [
                    _vm._v(
                      "\n                 | " +
                        _vm._s(
                          (_vm.userProfileMap[
                            _vm.currentConversation.userProfile.userID
                          ] &&
                            _vm.userProfileMap[
                              _vm.currentConversation.userProfile.userID
                            ].title) ||
                            ""
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.currentConversation.type == _vm.TIM.TYPES.CONV_GROUP
            ? [
                _c(
                  "span",
                  {
                    staticClass: "chat-name-group",
                    attrs: { title: _vm.currentConversation.groupProfile.name },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.currentConversation.groupProfile.name) +
                        "\n            "
                    ),
                  ]
                ),
                _c("span", { staticClass: "chat-name-group-number" }, [
                  _vm._v("(" + _vm._s(_vm.groupInfo.memberCount) + ")"),
                ]),
              ]
            : _vm._e(),
          _vm.currentConversation.type == _vm.TIM.TYPES.CONV_SYSTEM
            ? _c("span", { staticClass: "chat-name-system" }, [
                _vm._v("系统通知"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "instant-message-operate",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "operate-btn",
              attrs: { title: _vm.isLargeSize ? "缩小窗口" : "放大窗口" },
              on: { click: _vm.handleEnLargeDialog },
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLargeSize,
                    expression: "!isLargeSize",
                  },
                ],
                staticClass: "enlarge-btn",
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLargeSize,
                    expression: "isLargeSize",
                  },
                ],
                staticClass: "shrink-btn",
              }),
            ]
          ),
          _vm.currentConversation.type == _vm.TIM.TYPES.CONV_GROUP
            ? _c(
                "span",
                {
                  staticClass: "operate-btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleShowGroudInfo.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "member-btn",
                    attrs: { title: "查看群组资料" },
                  }),
                ]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "operate-btn",
              on: { click: _vm.handleCloseInstantMessage },
            },
            [
              _c("i", {
                staticClass: "close-im-btn",
                attrs: { title: "关闭窗口" },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }