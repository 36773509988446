var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bn-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.offerVisible,
            "custom-class": "offer-bn",
            width: "440px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.offerVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "offer-bn-container" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Offer播报")]),
            _c("div", { staticClass: "close-area" }, [
              _c("span", { staticClass: "seconds" }, [
                _vm._v(_vm._s(_vm.offerBroadcastCountDown) + "秒后"),
              ]),
              _c(
                "span",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      return _vm.offerClose(true)
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "item-wrap" },
              [
                _vm.offerBroadcast.length <= 3
                  ? _vm._l(_vm.offerBroadcast, function (it, index) {
                      return _c(
                        "div",
                        { key: "offer_item_" + index, staticClass: "offer-it" },
                        [
                          _c("i", { staticClass: "el-icon-star-on" }),
                          _c(
                            "span",
                            {
                              attrs: {
                                title: `${it.CustomerName} ${(
                                  it.CustomerBusinessDomains || []
                                ).join("-")} 成交1个offer`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(it.CustomerName) +
                                  " " +
                                  _vm._s(
                                    (it.CustomerBusinessDomains || []).join("-")
                                  ) +
                                  " " +
                                  _vm._s(it.JobCategory) +
                                  " 成交1个offer"
                              ),
                            ]
                          ),
                          _c("i", { staticClass: "el-icon-star-on" }),
                        ]
                      )
                    })
                  : [
                      _c(
                        "marquee",
                        {
                          attrs: {
                            behavior: "scroll",
                            direction: "up",
                            height: "160",
                          },
                        },
                        _vm._l(_vm.offerBroadcast, function (it, index) {
                          return _c(
                            "div",
                            {
                              key: "offer_item_" + index,
                              staticClass: "offer-it",
                            },
                            [
                              _c("i", { staticClass: "el-icon-star-on" }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(it.CustomerName) +
                                    " " +
                                    _vm._s(
                                      (it.CustomerBusinessDomains || []).join(
                                        "-"
                                      )
                                    ) +
                                    " 成交1个offer"
                                ),
                              ]),
                              _c("i", { staticClass: "el-icon-star-on" }),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
              ],
              2
            ),
            _vm.hasRedPackage
              ? _c("div", { staticClass: "has-red-package" }, [
                  _c("img", {
                    attrs: {
                      alt: "",
                      src: require("@src/assets/images/broadcast-notification/offer-botton-bg.png"),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.redPackageVisible,
            "custom-class": "rp-bn",
            width: "337px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.redPackageVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "rp-bn-container" }, [
            _c("div", { staticClass: "close-area" }, [
              _c("span", { staticClass: "seconds" }, [
                _vm._v(_vm._s(_vm.redPackageBroadcastCountDown) + "秒后"),
              ]),
              _c(
                "span",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      return _vm.redPackageClose(true)
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isGrabed,
                    expression: "!isGrabed",
                  },
                ],
                staticClass: "face-page",
                class: { open: _vm.openLoading },
              },
              [
                _c("div", { staticClass: "fp-text" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.currentRedPackage.CustomerName) +
                      " \n                    "
                  ),
                  _c("br"),
                  _vm._v("福利降落\n                "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "open-btn",
                    on: { click: _vm.openRedPackage },
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: "红包按钮",
                        src: require("@src/assets/images/broadcast-notification/rp-open-btn@2x.png"),
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isReceived && _vm.isGrabed,
                    expression: "isReceived && isGrabed",
                  },
                ],
                staticClass: "get-page",
              },
              [
                _c("p", { staticClass: "tit" }, [_vm._v("恭喜你抢到红包")]),
                _c(
                  "p",
                  {
                    staticClass: "amount",
                    domProps: { textContent: _vm._s(_vm.amount.toFixed(2)) },
                  },
                  [_vm._v("99.00")]
                ),
                _c("p", { staticClass: "tips" }, [
                  _vm._v(
                    "\n                    稍后红包领取二维码将通过系统消息发送给你\n                    "
                  ),
                  _c("br"),
                  _vm._v("请及时扫码领取\n                "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isReceived && _vm.isGrabed,
                    expression: "!isReceived && isGrabed",
                  },
                ],
                staticClass: "fail-page",
              },
              [
                _c("p", { staticClass: "tit" }, [
                  _vm._v("慢了一步，什么也没有抢到"),
                ]),
                _c("img", {
                  attrs: {
                    alt: "红包失败",
                    src: require("@src/assets/images/broadcast-notification/fail-icon@2x.png"),
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }