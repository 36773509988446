<template>
<message-bubble :isMine="isMine" :message="message">
  <div class="sound-element-wrapper" title="单击播放" @click="playOrPause">
    <!-- class="iconfont icon-voice" -->
    <i
      class="icon-voice-new"
      :class="{'is-mine-sound': isMine}"
      style="position: relative;"
    ></i>
    {{ second + '"' }}
  </div>
</message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
export default {
  name: 'SoundElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },
  components: {
    MessageBubble
  },
  data() {
    return {
      amr: null
    }
  },
  computed: {
    url() {
      return this.payload.url
    },
    size() {
      return this.payload.size
    },
    second() {
      return this.payload.second
    }
  },
  methods: {
    playOrPause(ev) {
      let icon = ev.target.querySelector('.icon-voice-new'),
          playingSpan = document.createElement('span'),
          imSoundAudio = "";
      playingSpan.id = "playingSpan";
      playingSpan.classList.add('playing-box');
      // 目前移动端的语音消息采用 aac 格式，以前用 amr 格式。默认先用 audio 标签播放，若无法播放则尝试 amr 格式播放。
      //一个页面共用一个IM音频
      if(document.querySelector('#imSoundAudio')) {
        // 如果已经生成IM音频，点击之后首先判断该音频是否指向当前这个语音消息
        // 如果是，则判断该语音的状态：暂停状态，首先将当前播放时间置0（避免之前播放的不是该语音而从中间开始播放），重新播放；播放状态，则暂停，并将当前播放时间置0
        // 如果该音频未指向当前语音消息，则首先将音频暂停，将音频指向当前这个语音消息地址，之后从头播放
        imSoundAudio = document.querySelector('#imSoundAudio');
        if(document.querySelector('#playingSpan')) {
          document.querySelector('#playingSpan').parentElement.removeChild(document.querySelector('#playingSpan'));
        }
        if((imSoundAudio.src === this.url && imSoundAudio.paused) || imSoundAudio.src !== this.url) {
          if(imSoundAudio.src !== this.url) {
            imSoundAudio.pause();
            imSoundAudio.src = this.url;
          }
          icon.appendChild(playingSpan);
          imSoundAudio.currentTime  = 0;
          imSoundAudio.play();
        } else {
          imSoundAudio.pause();
          imSoundAudio.currentTime = 0;
        }
      } else {
        const audio = document.createElement('audio');
        audio.id = "imSoundAudio";
        audio.style.visibility = false;
        audio.addEventListener('error', this.tryPlayAMR); // 播放出错，则尝试使用 AMR 播放
        audio.src = this.url;
        var promise = audio.play()
        if (promise) {
          promise.then(() => {
            icon.appendChild(playingSpan);
            document.body.appendChild(audio);
          })
          promise.catch(() => {})
        }
        imSoundAudio = audio;
      }
      imSoundAudio.addEventListener('pause', () => {
        if(document.querySelector('#playingSpan')) {
          document.querySelector('#playingSpan').parentElement.removeChild(document.querySelector('#playingSpan'));
        }
      })
      imSoundAudio.addEventListener('ended', () => {
        if(document.querySelector('#playingSpan')) {
          document.querySelector('#playingSpan').parentElement.removeChild(document.querySelector('#playingSpan'));
        }
      })
    },
    tryPlayAMR() {
      try {
        const isIE = /MSIE|Trident|Edge/.test(window.navigator.userAgent)
        // amr 播放组件库在 IE 不支持
        if (isIE) {
          shortTips('您的浏览器不支持该格式的语音消息播放，建议更换为谷歌浏览器')
          return
        }
        // 动态插入 amr 播放组件库
        if (!window.BenzAMRRecorder) {
          const script = document.createElement('script')
          script.addEventListener('load', this.playAMR)
          script.src = './BenzAMRRecorder.js'
          const firstScript = document.getElementsByTagName('script')[0]
          firstScript.parentNode.insertBefore(script, firstScript)
          return
        }
        this.playAMR()
      } catch (error) {
        shortTips('您的浏览器不支持该格式的语音消息播放，建议更换为谷歌浏览器')
      }
    },
    playAMR() {
      if (!this.amr && window.BenzAMRRecorder) {
        this.amr = new window.BenzAMRRecorder()
      }
      if (this.amr.isInit()) {
        this.amr.play()
        return
      }
      this.amr.initWithUrl(this.url).then(() => {
        this.amr.play()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sound-element-wrapper {
  padding: 0px 10px;
  cursor: pointer;
  .icon-voice-new{
    display: inline-block;
    background: url('~@src/assets/images/icon/audio-left.png') no-repeat;
    background-size: 12px 16px;
    width: 12px;
    height: 16px;
    vertical-align: text-top;
    margin-right: 6px;
    position: relative;
  }
  .is-mine-sound {
    display: inline-block;
    transform: rotate(180deg);
  }
}
</style>

<style lang="scss">
.playing-box {
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 16px;
  right: 0;
  top: 0;
  background-color: #f6f6f6;
  animation: slide-out 500ms linear infinite;
}
@keyframes slide-out {
  0% {
    width: 10px;
  }
  100% {
    width: 0;
  }
}
</style>