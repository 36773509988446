const msg = {
  clientMsgID: 'abc',
  serverMsgID: 'abcd',
  createTime: 1677896434242,
  sendTime: 1677896434243,
  sessionType: 1,
  sendID: 'xxx',
  recvID: 'yyy',
  msgFrom: 100,
  contentType: 101,
  platformID: 5,
  senderNickname: '狗子',
  senderFaceUrl: 'https://tse4-mm.cn.bing.net/th/id/OIP-C.FCTwhUntNzs42rKAVrh-KAAAAA?w=204&h=204&c=7&r=0&o=5&dpr=2&pid=1.7',
  groupID: 'zzz',
  content: '你好啊',
  seq: '1100-3111',
  isRead: false,
  status: 2,
  offlinePush: {
    title: '你',
    desc: '我',
    ex: '他',
    iOSPushSound: 'water',
    iOSBadgeCount: false,
  },
  attachedInfo: 'haha',
  attachedInfoElem: null,
  ex: '',
  pictureElem: null,
  soundElem: null,
  videoElem: null,
  fileElem: null,
  faceElem: null,
  mergeElem: null,
  atElem: null,
  locationElem: null,
  customElem: null,
  quoteElem: null,
  notificationElem: null,
}

const conversitionList = [
  {
    conversationID: '123',
    conversationType: 1,
    userID: 'xxxx-xxxx',
    groupID: 'group-xxx',
    showName: '李二狗',
    faceURL: 'https://tse4-mm.cn.bing.net/th/id/OIP-C.FCTwhUntNzs42rKAVrh-KAAAAA?w=204&h=204&c=7&r=0&o=5&dpr=2&pid=1.7',
    recvMsgOpt: 0,
    unreadCount: 10,
    groupAtType: 1,
    latestMsg: JSON.stringify(msg),
    latestMsgSendTime: 1677896434241,
    draftText: '',
    draftTextTime: 0,
    isPinned: false,
    isNotInGroup: false,
    isPrivateChat: false,
    attachedInfo: '{}',
    ex: '{}'
  }
]

const conversition = conversitionList[0];



export { 
  conversitionList,
  conversition
}