var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { staticClass: "icon font-icon", attrs: { "aria-hidden": "true" } },
    [_c("use", { attrs: { "xlink:href": _vm.href } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }