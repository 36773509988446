var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wb-top" }, [
    _c("div", { staticClass: "cell" }, [
      _c("img", {
        attrs: {
          alt: "logo",
          src: require("@src/assets/images/workbench/workbench-logo.png"),
        },
      }),
      _c("p", { staticClass: "notice" }, [_c("notice-widget")], 1),
    ]),
    _c(
      "div",
      { staticClass: "cell" },
      [
        _c(
          "el-button",
          [
            _c("font-icon", {
              staticClass: "btn-icon",
              attrs: { href: "#icon-zuzhijiagou" },
            }),
            _vm._v("组织架构\n        "),
          ],
          1
        ),
        _c(
          "el-button",
          [
            _c("font-icon", {
              staticClass: "btn-icon",
              attrs: { href: "#icon-file-add" },
            }),
            _vm._v("职位\n        "),
          ],
          1
        ),
        _c(
          "el-button",
          [
            _c("font-icon", {
              staticClass: "btn-icon",
              attrs: { href: "#icon-add-circle" },
            }),
            _vm._v("简历\n        "),
          ],
          1
        ),
        _c("font-icon", {
          staticClass: "btn-icon-b",
          attrs: { href: "#icon-shoujihao" },
        }),
        _c("font-icon", {
          staticClass: "btn-icon-b",
          attrs: { href: "#icon-modular" },
        }),
        _c("font-icon", {
          staticClass: "btn-icon-b",
          attrs: { href: "#icon-xiaoxizhongxin" },
        }),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "uesr-avator" }, [
      _c("img", {
        attrs: {
          alt: "user-avator",
          src: require("@src/assets/images/workbench/workbench-logo.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }