var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "message-bubble",
    {
      attrs: { isMine: _vm.isMine, message: _vm.message, groupId: _vm.groupId },
    },
    [
      _vm._l(_vm.contentList, function (item, index) {
        return [
          item.name === "text"
            ? _c("span", {
                key: index,
                domProps: { innerHTML: _vm._s(_vm.translateText(item.text)) },
              })
            : item.name === "img"
            ? _c("img", {
                key: index,
                attrs: { src: item.src, width: "20px", height: "20px" },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }