import { anniversary as anniversaryUrl } from '#/js/config/api.json'

export default {
    annualUserData(userId) {
        return _request({
            url: anniversaryUrl.annual_user_data.replace(/\%p/, userId),
            method: "GET",
            // data: params,
            baseURL: "LbdOpenApi",
        });
    },
};