var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "notice-opr",
    },
    [
      _vm.noticeItem.status != 1
        ? _c(
            "span",
            {
              staticClass: "opr-btn done-btn",
              on: { click: _vm.handleFinish },
            },
            [_vm._v("完成")]
          )
        : _vm._e(),
      _c(
        "span",
        { staticClass: "opr-btn del-btn", on: { click: _vm.handleDelete } },
        [_vm._v("删除")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }