<template>
    <!-- :filter-method="customerFilter" -->
    <el-select
        class="customer-select-input"
        popper-class="customer-select"
        filterable
        clearable
        :disabled="isDisabled"
        v-model="customerId"
        :placeholder="placeholder"
        @keyup.enter.native="handleKeyupEnter"
        @clear="handleCustomerChange({
            customerId: '',
            customerName: ''
        })">
        <el-option
            v-for="customer in customerFilterList"
            :key="customer.customerId"
            :value="customer.customerId"
            :label="customer.customerName"
            :title="customer.customerName"
            @click.native="handleCustomerChange(customer)"
        ></el-option>
    </el-select>
</template>

<script>
import customerListService from '#/js/service/customerListService.js';
export default {
    name: "customerSelect",
    props: {
        firmId: {
            type: String,
            default: ""
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择客户"
        }
    },
    data() {
        return {
            customerId: '',
            customerFilterList: [],
            customerList: [],
        }
    },
    mounted() {
        this.getCustomerList();
    },
    methods: {
        getCustomerList() {
            let params = {};
            if(this.firmId) {
                params.firmId = this.firmId;
            }
            customerListService.getMyCompanyCustomers(params)
            .then(res =>{
                res.forEach(item =>{
                    delete item.subCustomers;
                })
                this.customerList = res;
                this.customerFilterList = res;
            }).catch(err => {
                console.log(err)
            })
        },
        customerFilter(val) {
            if(val) {
                this.customerFilterList = this.customerList.filter(customer => 
                    customer.customerName.toLowerCase().indexOf(val.toLowerCase()) > -1
                )
            } else {
                this.customerFilterList = this.customerList
            }
        },
        getSelectedCustomer() {
            return this.customerList.find(item => item.customerId === this.customerId);
        },
        handleCustomerChange(customer) {
            this.customerId = customer.customerId;
            this.$emit('customerSelected', {
                customerId: this.customerId, 
                customerName: customer.customerName
            });
        },
        // 兼容enter快捷键选择模式
        handleKeyupEnter() {
            const _oCustomer = this.customerFilterList.find(item => item.customerId == this.customerId);
            this.$emit('customerSelected', {
                customerId: _oCustomer.customerId, 
                customerName: _oCustomer.customerName
            });
        },
        reset() {
            this.customerId = '';
            this.customerFilter();
        },
        
        setData(customerId) {
            this.customerId = customerId;
        },
    }
}
</script>

<style lang="scss">
.customer-select {
    max-width: 380px;
}
</style>
