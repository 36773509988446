<template>
  <message-bubble
    :isMine=isMine
    :message=message
    :groupId="groupId">
     <!-- el-image在IE下会自动加上用于兼容object-fit的类，该类的样式在没设置图片宽高是会 GG -->
    <img class="image-element" :src="imageUrl" @load="onImageLoaded" @click="handlePreview" />
    <el-progress
      v-if="showProgressBar"
      :percentage="percentage"
      :color="percentage => (percentage === 100 ? '#67c23a' : '#409eff')"
    />
  </message-bubble>  
</template>

<script>
import MessageBubble from '../message-bubble'
import { Progress } from 'element-ui'
import emitter from '@src/js/mixins/emitter';
export default {
  name: 'ImageElemnt',
  mixins: [emitter],
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    groupId: String,
    isWorkBenchMain: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MessageBubble,
    ElProgress: Progress
  },
  computed: {
    imageUrl() {
      const url = this.payload.imageInfoArray[0].url
      if (typeof url !== 'string') {
        return ''
      }
      return url.slice(0, 2) === '//' ? `https:${url}` : url
    },
    showProgressBar() {
      return this.$parent.message.status === 'unSend'
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100)
    }
  },
  methods: {
    onImageLoaded(event) {
      if(this.isWorkBenchMain) {
        this.dispatch('FrimChatRoom', 'image-loaded', event);
      } else {
        this.dispatch('IMBaseApp', 'image-loaded', event);
      }
    },
    handlePreview() {
      if(this.isWorkBenchMain) {
        this.dispatch('FrimChatRoom', 'image-preview', {
          url: this.payload.imageInfoArray[0].url
        });
      } else {
        this.dispatch('IMBaseApp', 'image-preview', {
          url: this.payload.imageInfoArray[0].url
        });
      }
    }
  }
}
</script>

<style lang="scss" scope>
.image-element {
  max-width: 150px;
  cursor: zoom-in;
}
</style>
