var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-wrap" }, [
    _c(
      "div",
      {
        staticClass: "cell-l",
        on: {
          click: function ($event) {
            return _vm.handleMove("left")
          },
        },
      },
      [
        _c("font-icon", {
          staticClass: "icon",
          attrs: { href: "#icon-xiangzuo" },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "cell-wrap" }, [
      _c(
        "ul",
        {
          ref: "wrapInner",
          staticClass: "wrap-inner",
          style: { left: _vm.left + "px" },
        },
        _vm._l(_vm.tabItems, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: item.isActive ? "tab-cell active" : "tab-cell",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleTabItem(item, index)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "tab-text", attrs: { title: item.cnTitle } },
                [_c("i", [_vm._v(_vm._s(item.cnTitle))])]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-close",
                  on: {
                    click: function ($event) {
                      return _vm.hanleCloseTab(index)
                    },
                  },
                },
                [
                  _c("font-icon", {
                    staticClass: "tab-close-icon",
                    attrs: { href: "#icon-close-copy" },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "cell-r",
        on: {
          click: function ($event) {
            return _vm.handleMove("right")
          },
        },
      },
      [_c("font-icon", { attrs: { href: "#icon-xiangzuo-copy" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }