import { getSDK } from "@/js/open-im-lib";
import { SessionType, GroupAtType, OptType, MessageType, MessageStatus } from "@/js/open-im-lib/types/enum.js";
import { CbEvents } from "@/js/open-im-lib/constant/index.js";

const openIM = getSDK();

// import { OpenIMSDK, SessionType, GroupAtType, OptType, MessageType, MessageStatus, CbEvents } from 'open-im-sdk';
// const openIM = new OpenIMSDK();
openIM.SessionType = SessionType;
openIM.GroupAtType = GroupAtType;
openIM.OptType = OptType;
openIM.MessageType = MessageType;
openIM.MessageStatus = MessageStatus;
openIM.CbEvents = CbEvents;

// import { CbEvents } from 'open-im-sdk'
console.log(SessionType.Group);

// openIM.on(CbEvents.ONCONNECTFAILED,(data)=>{
//   // 连接失败...
// })
// openIM.on(CbEvents.ONCONNECTSUCCESS,(data)=>{
//   // 连接成功...
// })
// openIM.on(CbEvents.ONCONNECTING,(data)=>{
//   // 连接中...
// })
// openIM.on(CbEvents.ONKICKEDOFFLINE,(data)=>{
//   // 被踢下线...
// })
// openIM.on(CbEvents.ONUSERTOKENEXPIRED,(data)=>{
//   // token过期...
// })

//登录
// const config = {
//   userID: "userID",                                // 用户ID
//   token: "token",                                    // 用户token
//   url: "ws://121.37.25.71:10003",    // jssdk server ws地址
//   platformID: 5,                                    // 平台号
// };
// openIM.login(config).then(res => {
//   console.log("login suc...");
// }).catch(err => {
//   console.log("login failed...");
// })

//登出
// openIM.logout().then(res => {
//   console.log("logout suc...");
// }).catch(err => {
//   console.log("logout failed...");
// })

//获取登录状态
// openIM.getLoginStatus().then(res => {
//   // 101:登录成功 102:登陆中 103:登录失败 201:登出
// }).catch(err => {

// })

//获取登录用户
// openIM.getLoginUser().then(res => {
//   // 当前登录用户ID
// }).catch(err => {

// })

// export const imLogin = (config) => { 
//   openIM.login(config)
// }

// export const getAllConversationList = () => {
//   openIM.getAllConversationList().then(({ data }) => {
//     return data;
//   }).catch(err => {
//     console.log(err);
//     return [];
//   })
// }

// export {
//   SessionType,
//   CbEvents,
// }

export default openIM;