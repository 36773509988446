const state = {
    verificationToken: ''
}

const getters = {

}

const actions = {
    setVerificationToken({ commit, state }, verificationToken) {
        commit('updateVerificationToken', { verificationToken });
    }
}

const mutations = {
    updateVerificationToken(state, { verificationToken }) {
        state.verificationToken = verificationToken;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
