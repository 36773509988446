const state = {
    tabItems:[],
    isActive: false,
    firmGroup: {
        unreadCount: 0,
        newMessageList: [],
        deleteMemberNick: ""
    }
}

const getters = {
    renderTabItems(state){
        return state.tabItems;
    }
}

const actions = {
    invokePushTabItems({ commit, state }, item) {
        commit('pushTabItems', item);
    },
    invokeSwitchTab({ commit, state }, itemIndex) {
        commit('switchTab', itemIndex);
    },
    invokeSetTabItems({ commit, state }, tabItems) {
        commit('setTabItems', tabItems);
    },
    invokeSetFirmGroupInfo({ commit, state }, firmGroupInfo) {
        commit('setFirmGroupInfo', firmGroupInfo);
    },
}

const mutations = {
    pushTabItems(state,tabItem){
        let isExist = false;
        state.tabItems.forEach(item=>{
            item.isActive = false;
            if(tabItem.path == item.path){
                item.isActive = true;
                isExist = true;
            }
        })
        if(!isExist){
            state.tabItems.push(tabItem);
        }
        sessionStorage.setItem('tabItems',JSON.stringify(state.tabItems));
    },
    switchTab(state,itemIndex){
        state.tabItems.forEach((item,index)=>{
            if(itemIndex == index){
                state.tabItems[index].isActive = true;
            }else{
                state.tabItems[index].isActive = false;
            }
        })
        sessionStorage.setItem('tabItems',JSON.stringify(state.tabItems));
    },
    setTabItems(state,tabItems){
        state.tabItems = tabItems;
    },
    setFirmGroupInfo(state, firmGroupInfo) {
        Object.assign(state.firmGroup, firmGroupInfo);
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
