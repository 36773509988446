<template>
    <div class="wb-top">
        <div class="cell">
            <img
                alt="logo"
                src="~@src/assets/images/workbench/workbench-logo.png"
            />
            <!-- <p class="notice">
                <font-icon
                    class="notice-icon"
                    href="#icon-shengyin"
                ></font-icon>
                <span class="txt">事项提醒</span>
                <span class="see">点击查看</span>
            </p> -->
            <p class="notice">
                <notice-widget></notice-widget>
            </p>
            
        </div>
        <div class="cell">
            <el-button>
                <font-icon
                    class="btn-icon"
                    href="#icon-zuzhijiagou"
                ></font-icon>组织架构
            </el-button>
            <el-button>
                <font-icon
                    class="btn-icon"
                    href="#icon-file-add"
                ></font-icon>职位
            </el-button>
            <el-button>
                <font-icon
                    class="btn-icon"
                    href="#icon-add-circle"
                ></font-icon>简历
            </el-button>

            <font-icon
                class="btn-icon-b"
                href="#icon-shoujihao"
            ></font-icon>
            <font-icon
                class="btn-icon-b"
                href="#icon-modular"
            ></font-icon>
            <font-icon
                class="btn-icon-b"
                href="#icon-xiaoxizhongxin"
            ></font-icon>

            <span class="uesr-avator">
                <img
                    alt="user-avator"
                    src="~@src/assets/images/workbench/workbench-logo.png"
                />
            </span>
        </div>
    </div>
</template>

<script>
import NoticeWidget from '#/component/notice/noticeWidget';

export default {
    name: 'workbench-top',
    components: {
        NoticeWidget,
    },
    data () {
        return {
        };
    },
    created () { },
    mounted () { },
    methods: {

    },
}
</script>

<style lang="scss" scope>
.wb-top {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: white;
    border-bottom: 1px solid #ebebeb;
    align-items: center;
    .cell {
        display: flex;
        align-items: center;
        .notice {
            margin-left: 21px;
            display: inline-block;
            margin: 0 0 0 21px;
            &::before {
                content: '';
                display: inline-block;
                width: 1px;
                height: 20px;
                background: #ebebeb;
                vertical-align: middle;
                margin-right: 21px;
            }
            .notice-icon {
                font-size: 20px;
            }
            .txt,
            .see {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                // line-height: 22px;
                margin-left: 12px;
                cursor: pointer;
            }
            .see {
                color: #ff4b4b;
            }
        }

        .btn-icon {
            fill: #666;
            margin-right: 2px;
        }
        .btn-icon-b {
            fill: #333;
            font-size: 22px;
            margin-left: 23px;
            cursor: pointer;
            &:hover {
                fill: $primary;
            }
        }

        .uesr-avator {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-left: 28px;
            border: 1px solid rgba(168, 168, 168, 0.44);
            border-radius: 50%;
            overflow: hidden;
        }
    }
}
</style>