// 使用说明
// 创建：在全局New一个实例
// 添加：
export default class LayerRegister {
    layerQuene = {};
  
    constructor() {
    }
  
    sleep(time) {
      return new Promise((resolve, reject) =>{
        setTimeout(resolve, time)
      })
    }
  
    generateId() {
      return Math.floor(Math.random() * 10000);
    }
  
    async add(json) {
      let id = json.id;
  
      //通过id+"."，使对象的key按添加顺序排列，可以使用"."以外的其他字符，目的是将id转换为非整数字符串
      this.layerQuene[`${id}.`] = {
        show: json.show
      };

      if(Object.keys(this.layerQuene).indexOf(`${id}.`) == 0) {
        json.show();
      }
    }
  
    async delete(id) {
      this.layerQuene[`${id}.`] && delete this.layerQuene[`${id}.`]
      await this.next();
    }
  
    async next() {
        await this.sleep(300);
        if(Object.entries(this.layerQuene).length > 0) {
          Object.entries(this.layerQuene)[0][1].show();
        }
    }
  }