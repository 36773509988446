var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "审核不通过详情",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "rejectForm",
          staticClass: "reject-form",
          attrs: { model: _vm.formData, "label-width": "114px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原因", prop: "rejectResean" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  type: "textarea",
                  placeholder: "暂无",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formData.rejectResean,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "rejectResean", $$v)
                  },
                  expression: "formData.rejectResean",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细说明", prop: "auditDetail" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  type: "textarea",
                  placeholder: "暂无",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formData.auditDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "auditDetail", $$v)
                  },
                  expression: "formData.auditDetail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审批人", prop: "auditDetail" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  type: "textarea",
                  placeholder: "暂无",
                  maxlength: "300",
                  rows: 3,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formData.auditName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "auditName", $$v)
                  },
                  expression: "formData.auditName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }