var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isShow
        ? _c("div", { staticClass: "work-report-container" }, [
            _c("div", { staticClass: "work-report-dialog" }, [
              _c("div", { staticClass: "work-report-title" }, [
                _vm._v("\n                每日工作小结\n                "),
                _c("span", [_vm._v(_vm._s(_vm.date.replace(/-/g, ".")))]),
                !_vm.noMoreBtn
                  ? _c(
                      "span",
                      {
                        staticClass: "view-more-report",
                        on: { click: _vm.viewMore },
                      },
                      [_vm._v("查看往期日报")]
                    )
                  : _vm._e(),
                _c("i", {
                  staticClass: "btn-new close-btn",
                  on: { click: _vm.closeDialog },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "work-report-content",
                  attrs: { id: "workReport" },
                },
                [
                  _c("div", { staticClass: "content-top" }, [
                    _c("p", { staticClass: "content-title" }, [
                      _vm._v("今日offer数："),
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goToDetail({
                                key: "recommendationDetails",
                                RecommendationDetailType: "4",
                              })
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.reportData.offers))]
                      ),
                    ]),
                    _c("div", { staticClass: "avatar-wrap" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.reportData.avatarUrl
                            ? _vm.reportData.avatarUrl
                            : require("@src/assets/images/icon/work-report/avatar.png"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "user-name" }, [
                      _vm._v(_vm._s(_vm.reportData.userName)),
                    ]),
                    _c("span", { staticClass: "qrcode-wrap" }, [
                      _c("i", { staticClass: "icon-new icon-scan" }),
                      _vm._v("扫码分享\n                        "),
                      _vm.qrcode
                        ? _c("div", { staticClass: "qrcode-box" }, [
                            _c("img", { attrs: { src: _vm.qrcode } }),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("ul", { staticClass: "report-list" }, [
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c("legend", [_vm._v("订单")]),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "jobDetails",
                                    achievementDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("创建职位数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.jobs)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "orderDetails",
                                    achievementDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("接单数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.orders)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "jobDetails",
                                    orderDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("职位关键信息更新数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.keyUpdateJobs)),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c(
                          "legend",
                          {
                            staticClass: "report-item-candidate-create",
                            on: {
                              click: function ($event) {
                                return _vm.goToDetail({
                                  key: "candidateDetails",
                                  achievementDetailType: "1",
                                })
                              },
                            },
                          },
                          [
                            _vm._v("人才创建("),
                            _c("span", { staticClass: "data-num" }, [
                              _vm._v(_vm._s(_vm.reportData.candidates)),
                            ]),
                            _vm._v(")"),
                          ]
                        ),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateDetails",
                                    achievementDetailType: "3",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("创建profile数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.profileCandidates)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateDetails",
                                    achievementDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("创建完整简历数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.completeCandidates)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c("legend", [_vm._v("人才查看")]),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateViewDetails",
                                    CandidateViewDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("查看简历数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.candidateDetailViews)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateViewDetails",
                                    CandidateViewDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("查看联系方式数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.candidateContactViews)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c("legend", [_vm._v("人才更新")]),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateUpdateDetails",
                                    CandidateUpdateDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("更新简历数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.candidateUpdates)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateUpdateDetails",
                                    CandidateUpdateDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("重大更新数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.candidateKeyUpdates)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c("legend", [_vm._v("人才沟通和寻访")]),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateInquiryLogDetails",
                                    achievementDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("添加寻访数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.inquiryLogs)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "candidateInquiryLogDetails",
                                    achievementDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _vm._m(0),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.validInquiryLogs)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "callRecordDetails",
                                    achievementDetailType: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("拨打电话数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.calls)),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "callRecordDetails",
                                    achievementDetailType: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("有效通话数"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(_vm._s(_vm.reportData.validCalls)),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "report-item" }, [
                      _c("fieldset", [
                        _c("legend", [_vm._v("业务流程")]),
                        _c("div", { staticClass: "report-data" }, [
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "1",
                                    OperationStatus: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("推荐人才数（Candidate）"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.recommendCandidates)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "2",
                                    OperationStatus: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("接受推荐"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.acceptedRecommendCandidates
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "3",
                                    OperationStatus: "3",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("淘汰推荐"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.rejectedCandidateRecommends
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "4",
                                    OperationStatus: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("人才推荐数（Placement）"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.candidateRecommends)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "5",
                                    OperationStatus: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("接受推荐"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.acceptedCandidateRecommends
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "6",
                                    OperationStatus: "3",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("淘汰推荐"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.rejectedCandidateRecommends
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "7",
                                    OperationStatus: "1",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("面试数（Interview）"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(_vm.reportData.interviewCandidates)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "8",
                                    OperationStatus: "2",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("面试通过"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.interviewPassedCandidates
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "data-item child-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail({
                                    key: "recommendationDetails",
                                    achievementDetailType: "9",
                                    OperationStatus: "3",
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "data-title" }, [
                                _vm._v("面试失败"),
                              ]),
                              _c("span", { staticClass: "data-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.reportData.interviewFailedCandidates
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "work-report-footer" }, [
                _c("div", { staticClass: "btn-wrap" }, [
                  _c("span", { staticClass: "btn-new btn-scan" }, [
                    _c("i", { staticClass: "icon-new icon-scan" }),
                    _vm._v("扫码分享\n                        "),
                    _c("div", { staticClass: "qrcode-box" }, [
                      _c("img", { attrs: { src: _vm.qrcode } }),
                    ]),
                  ]),
                  _c(
                    "span",
                    { staticClass: "btn-new", on: { click: _vm.scrollToTop } },
                    [
                      _c("i", { staticClass: "icon-new icon-top" }),
                      _vm._v("回到顶部"),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "more-report-dialog",
          attrs: {
            title: _vm.moreReportTitle,
            visible: _vm.moreReportDialogVisible,
            modal: false,
            width: "540px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.moreReportDialogVisible = $event
            },
          },
        },
        [
          _c("summary-list", {
            on: {
              getTotalCount: _vm.getTotalCount,
              showDialog: _vm.showDialog,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "data-title" }, [
      _c("i", { staticClass: "red-dot" }),
      _vm._v("有效寻访数"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }