<template>
  <div
    ref="chatBubble"
    class="chat-bubble"
    @contextmenu.prevent
    @mousedown.stop="handleMessageDropdown">
    <div
      ref="messageContent"
      class="message-content"
      :class="bubbleStyle"
      @contextmenu.prevent
      :style="{'background-color': message.msgType === TIM.TYPES.MSG_FILE ? '#fff': ''}"
      v-if="!message.isRevoked">
      <slot></slot>
    </div>
    <div
      class="message-dropdown-btn-container"
      :style="{'display': isShowDropdown ? 'inline-block' : '', 'right': isMine ? 'unset' : '-26px', 'left': isMine ? '-26px' : 'unset'}"
      v-if="!message.isRevoked && !((message.msgType === 'TIMSoundElem' || message.msgType === 'TIMVideoFileElem') && (!isMine || !(isMine && !isTimeout)))">
      <span
        ref="messageDropdownBtn"
        class="message-dropdown-btn"
        :style="isMine ? 'margin-right: 6px;' : 'margin-left: 6px;'">
        <i class="el-icon-more"></i>
      </span>
    </div>
    <ul
      class="message-dropdown-menu"
      ref="messageDropdown"
      v-if="!message.isRevoked"
      v-show="isShowDropdown"
      v-loading="dropdownLoading">
      <li class="menu-item" ref="copyBtn">复制</li>
      <li class="menu-item" v-if="isMine && !isTimeout" @click="revokeMessage">撤回</li>
      <!-- <li class="menu-item" @click="deleteMessage">删除</li> -->
    </ul>
    <div class="group-tip-element-wrapper" v-if="message.isRevoked">
      {{text}}
      <!-- <el-button type="text" size="mini" class="edit-button" v-show="isEdit" @click="reEdit">&nbsp;重新编辑</el-button> -->
    </div>
  </div>
</template>

<script>
import imService from '#/js/service/openIMService.js';
import emitter from '@src/js/mixins/emitter';
import ClipboardJS from 'clipboard';    //文字复制插件

export default {
  name: 'MessageBubble',
  mixins: [emitter],
  data() {
    return {
      isTimeout: false,
      isShowDropdown: false,
      dropdownLoading: false
    }
  },
  props: {
    isMine: {
      type: Boolean
    },
    isNew: {
      type: Boolean
    },
    message: {
      type: Object,
      required: true
    },
    customClass: {
      type: String
    },
    groupId: String
  },
  created() {
    this.isTimeoutHandler()
  },
  mounted() {
    let _self = this;
    const copyLink = new ClipboardJS(_self.$refs.copyBtn, {
      target: () => {
        return _self.$refs.messageContent
      }
    });
    copyLink.on('success', (res) => {
      shortTips("复制成功");
    });
    copyLink.on('error', (err) => {
      console.log(err)
      shortTips("复制失败，请手动复制。");
    });
  },
  beforeDestroy() {},
  computed: {
    bubbleStyle() {
      let classString = ''
      if (this.isMine) {
        classString += 'message-send'
      } else {
        classString += 'message-received'
      }
      if (this.isNew) {
        classString += 'new'
      }
      if(this.customClass) {
        classString += ' ' + this.customClass
      }
      return classString
    },
    text() {
      if (!this.groupId && !this.isMine) {
        return '对方撤回了一条消息'
      }
      if (this.groupId && !this.isMine) {
        return `${this.message.fromAccountName}撤回了一条消息`
      }
      return '你撤回了一条消息'
    },
    // isEdit() {
    //   if (!this.isMine) {
    //     return false
    //   }
    //   if (this.message.type !== this.TIM.TYPES.MSG_TEXT) {
    //     return false
    //   }
    //   if (this.isTimeout) {
    //     return false
    //   }
    //   return true
    // },
  },
  methods: {
    isTimeoutHandler() { // 从发送消息时间开始算起，两分钟内可以撤回
      let now = new Date()
      if (parseInt(now.getTime()) - this.message.msgTime > 2 * 60 * 1000) {
        this.isTimeout = true
        return
      }
      setTimeout(this.isTimeoutHandler, 1000)
    },
    handleMessageDropdown(ev) {
      if(this.message.isRevoked || ((this.message.msgType === 'TIMSoundElem' || this.message.msgType === 'TIMVideoFileElem') && (!this.isMine || !(this.isMine && !this.isTimeout)))) {
        return;
      }
      let messageDropdown = this.$refs.messageDropdown,
          chatBubbleWidth = this.$refs.chatBubble.offsetWidth;
      if(ev.buttons === 2 && (ev.currentTarget === this.$refs.chatBubble) || ev.buttons === 1 && (ev.target === this.$refs.messageDropdownBtn || ev.target === this.$refs.messageDropdownBtn.querySelector('.el-icon-more'))) {
        if(ev.target === this.$refs.messageDropdownBtn || ev.target === this.$refs.messageDropdownBtn.querySelector('.el-icon-more')) {
          messageDropdown.style.bottom = 0;
          messageDropdown.style.top = '';
          if(this.isMine) {
            messageDropdown.style.left = 0;
            messageDropdown.style.right = '';
          } else {
            messageDropdown.style.left = '';
            if(chatBubbleWidth > 230) {
              messageDropdown.style.right = 0;
            } else {
              messageDropdown.style.right = '-80px';
            }
          }
        } else {
          messageDropdown.style.right = chatBubbleWidth - ev.layerX - 48 + 'px';
          messageDropdown.style.left = '';
          if((this.$refs.chatBubble.offsetHeight - ev.layerY + 20) < (messageDropdown.querySelectorAll('.menu-item').length * 28)) {
            messageDropdown.style.bottom = '-20px';
            messageDropdown.style.top = '';
          } else {
            messageDropdown.style.top = ev.layerY + 'px';
            messageDropdown.style.bottom = '';
          }
        }
        this.isShowDropdown = true;
      }
      setTimeout(() => {
        this.isShowDropdown = false;
      }, 3000);
    },
    refreshSignature() {
      return new Promise((resolve, reject) => {
        if(localStorage.getItem('imUserSig')) {
          resolve(true);
        } else {
          imService.getUserSig().then(res =>{
            this.userSig = res.token;
            localStorage.setItem('imUserSig', res.token);
            resolve(true);
          }).catch(err => {
            resolve(false);
          })
        }
      })
    },
    async revokeMessage() {
      let checkSig = await this.refreshSignature();
      if (!checkSig) {
        shortTips("未知异常，请刷新页面重试！");
        return false;
      }
      if(this.isTimeout) {
        shortTips('撤回失败，已超时!');
        return;
      }
      let params = {},
          message = this.message;
      if(!this.groupId) {
        params = {
          token: localStorage.getItem('imUserSig'),
          fromAccountId: message.fromAccountId,
          toAccountId: message.toAccountId,
          msgKey: message.msgKey,
          type: 0
        }
      } else {
        params = {
          token: localStorage.getItem('imUserSig'),
          fromAccountId: message.fromAccountId,
          groupId: this.groupId,
          msgKey: message.msgKey,
          type: 1
        }
      }
      this.dropdownLoading = true;
      imService.revokeMessage(params).then(res => {
        this.dropdownLoading = false;
        this.message.isRevoked = true;
        this.dispatch('IMBaseApp', 'revoke-message', {
          conversationID: this.groupId ? `GROUP_${this.groupId}` : `C2C_${this.message.toAccountId}`
        });
      }).catch(err => {
        this.dropdownLoading = false;
        console.log("revokeMessageError：", err);
      })
      // openIM.revokeMessage(this.message.openIMMsg).then(({ data })=>{
      //   imService.revokeMessage(params).then(res => {
      //     this.dropdownLoading = false;
      //     this.message.isRevoked = true;
      //     this.dispatch('IMBaseApp', 'revoke-message', {
      //       conversationID: this.groupId ? `GROUP${this.groupId}` : `C2C${this.message.toAccountId}`
      //     });
      //   }).catch(err => {
      //     this.dropdownLoading = false;
      //     console.log("revokeMessageError：", err);
      //   })
      // }).catch(err => {
      //   console.log(err);
      // })
    },
    // deleteMessage() {},
    // reEdit() {
    //   this.$bus.$emit('reEditMessage', this.message.payload.text)
    // },
  }
}
</script>

<style lang="scss" scope>
.chat-bubble {
  position: relative;
  &:hover {
    .message-dropdown-btn-container {
      display: inline-block;
    }
  }
  .message-content {
    outline: none;
    font-size: 14px;
    position: relative;
    max-width: 290px;
    word-wrap: break-word;
    word-break: break-all;
    padding: 8px;
    border: 1px solid #eee;
    // box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    color: #333;
    span {
      white-space: pre-wrap;
      margin: 0;
    }
    img {
      vertical-align: bottom;
    }
    // &::before {
    //   position: absolute;
    //   top: 0;
    //   width: 12px;
    //   height: 40px;
    //   content: "\e900";
    //   // content: "\e906";
    //   font-family: 'tim' !important;
    //   font-size: 24px; // 32px 在mac上会模糊 24px正常 , window 24px模糊 28px 32px正常  36px windows mac 基本一致，但是太大
    // }
  }
  .message-received {
    background-color: #e7f7f3;
    margin-left: 4px;
    border-radius: 0 4px 4px 4px;
    // &::before {
    //   left: -10px;
    //   transform: scaleX(-1);
    //   color: #fff;
    // }
    &.new {
      transform: scale(0);
      transform-origin: top left;
      animation: bounce 500ms linear both;
    }
  }
  .message-send {
    background-color: #f6f6f6;
    margin-right: 6px;
    border-radius: 4px 0 4px 4px;
    // &::before {
    //   right: -10px;
    //   color: $light-primary;
    // }
    &.new {
      transform: scale(0);
      transform-origin: top right;
      animation: bounce 500ms linear both;
    }
  }
  .message-dropdown-btn-container {
    display: none;
    position: absolute;
    bottom: 0;
    .message-dropdown-btn {
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #f6f6f6;
    }
    &:hover {
      display: inline-block;
    }
  }
  .message-dropdown-menu {
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    background-color: #fff;
    &:hover {
      display: block !important;
    }
    .menu-item {
      white-space: nowrap;
      font-size: 12px;
      padding: 0 12px;
      line-height: 28px;
      height: 28px;
      cursor: pointer;
      &:hover {
        background-color: rgba(56, 188, 158, 0.1);
      }
    }
    .circular {
      max-width: 30px;
    }
  }
  .group-tip-element-wrapper {
    background: #fff;
    padding: 4px 15px;
    border-radius: 3px;
    color: #a5b5c1;
    font-size: 12px;
  // .edit-button {
  //   padding-top: 4px;
  //   height: 20px;
  //   font-size: 10px;
  //  }
  }
}
@keyframes bounce { 
  0% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}
</style>
