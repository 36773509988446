const state = {
    standstillJobNum: 0,
    unbindDeptJobNum: 0
}

const actions = {
    setStandstillJobNum({ commit, state }, standstillJobNum) {
        commit('updateStandstillJobNum', { standstillJobNum });
    },
    setUnbindDeptJobNum({ commit, state }, unbindDeptJobNum) {
        commit('updateUnbindDeptJobNum', { unbindDeptJobNum });
    },
}

const mutations = {
    updateStandstillJobNum(state, { standstillJobNum }) {
        state.standstillJobNum = standstillJobNum;
    },
    updateUnbindDeptJobNum(state, { unbindDeptJobNum }) {
        state.unbindDeptJobNum = unbindDeptJobNum;
    }
}

export default {
    state,
    actions,
    mutations
}
