<template>
    <div
        class="conversation-item"
        :class="{ 'choose': conversation.conversationID === currentConversationID }"
        @click.stop="selectConversation">
        <div class="conversation-avatar" :class="{'is-online': isOnline, 'is-group': !isC2c && !isSystem}">
            <img :src="avatar" v-if="!isSystem"/>
            <img src="~@src/assets/images/icon_system_message.png" alt="" v-else />
        </div>
        <div class="conversation-info" :style="{'max-width': maxWidth}">
            <div class="conversation-info-header">
                <span class="conversation-name" :class="{'has-inner-label': checkResult === 1, 'has-candidate-label': checkResult === 3}">
                    {{ isSystem ? '系统通知' : conversationName }}
                    <span class="conversation-label" v-if="checkResult === 1 && !isCompany">内部</span>
                    <span class="conversation-label company-label" v-if="isCompany">全员</span>
                    <span class="conversation-label candidate-label" v-if="checkResult === 3">职通车候选人</span>
                </span>
                <span class="conversation-date">
                    {{conversation.lastMessage.lastTime | timeFilter}}
                </span>
            </div>
            <div class="last-message-container">
                <span class="last-message">
                    <span class="atme-tip" v-if="conversation.unreadCount > 0 && hasMessageAtMe">[有人@我]</span>
                    {{messageFrom}}{{messageForShow}}
                </span>
                <i class="el-icon-close-notification" v-if="!isSystem && !isC2c && conversation.groupProfile.selfInfo.messageRemindType == 'AcceptNotNotify'"></i>
                <div class="unread-count-container">
                    <span
                        class="unread-count"
                        v-if="isSystem || isC2c || (!isC2c && conversation.groupProfile.selfInfo.messageRemindType == 'AcceptAndNotify')"
                        v-show="conversation.unreadCount > 0 && conversation.conversationID !== currentConversationID">
                        {{conversation.unreadCount > 99 ? conversation.unreadCount + '+' : conversation.unreadCount}}
                    </span>
                </div>
                <span class="unread-tip" v-if="!isC2c && !isSystem && conversation.groupProfile.selfInfo.messageRemindType == 'AcceptNotNotify' && conversation.unreadCount > 0"></span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { avatarHost } from '../utils/avatarHost.js';
export default {
    props: {
        conversation: Object,
        currentConversationID: String,
        atMeConversationList: Array,
        maxWidth: String,
        userId: String,
        checkResult: Number,
        isCompany: Boolean,
        isOnline: Boolean
    },
    computed: {
        isSystem() {
           return this.conversation.conversationID == 'C2C_openIM123456';
        },
        isC2c() {
            return this.conversation.type == TIM.TYPES.CONV_C2C;
        },
        avatar() {
            if(this.conversation.type == TIM.TYPES.CONV_C2C) {
                return `${avatarHost()}/user/${this.conversation.userProfile.userID}` || this.conversation.userProfile.avatar;
                // return `${avatarHost()}/user/${this.conversation.userProfile.userID}`;
            } else {
                // return this.conversation.groupProfile.avatar || `https://bazapic.oss-cn-shenzhen.aliyuncs.com/user-avatar-dev/${this.conversation.groupProfile.groupID}`;
                return  `${avatarHost()}/group/${this.conversation.groupProfile.groupID}`;
            }
        },
        hasMessageAtMe() {
            return this.atMeConversationList.indexOf(this.conversation.conversationID) > -1;
        },
        conversationName() {
            if (this.isC2c) {
                return this.conversation.userProfile.nick || this.conversation.userProfile.userID;
            }
            if (this.conversation.type === TIM.TYPES.CONV_GROUP) {
                return this.conversation.groupProfile.name;
            }
            if (this.isSystem) {
                return '系统通知';
            }
            return ''
        },
        messageFrom() {
            if(this.isC2c ||
              (this.conversation.lastMessage.type === TIM.TYPES.MSG_CUSTOM && this.conversation.lastMessage.payload.data === 'group_create') ||
              this.conversation.lastMessage.isRevoked ||
              this.conversation.lastMessage.type === TIM.TYPES.MSG_GRP_TIP
            ) {
                return ""
            }
            if(this.conversation.lastMessage.fromAccount === 'baza_im_admin' || this.conversation.lastMessage.fromAccount === 'openIM123456') {
                return '通知：'
            }
            return this.conversation.lastMessage.fromAccoutName ? `${this.conversation.lastMessage.fromAccoutName}：` : ""
        },
        messageForShow() {
            if (this.conversation.lastMessage.isRevoked) {
                if (this.conversation.lastMessage.fromAccount == this.userId) {
                    return '你撤回了一条消息'
                }
                if (this.isC2c) {
                    return '对方撤回了一条消息'
                }
                return this.conversation.lastMessage.fromAccoutName ? `${this.conversation.lastMessage.fromAccoutName}撤回了一条消息` : '有信息被撤回'
            }
            if(this.conversation.lastMessage.type === TIM.TYPES.MSG_CUSTOM) {
                let extension = JSON.parse(this.conversation.lastMessage.payload.data);
                if(extension) {
                    switch(extension.type) {
                        case 'atMessage':
                            return extension.data.message;
                        case 'newRecommend':
                            return "简历推荐提醒";
                        case 'recommendStatusUpdate':
                            return "推荐状态变更提醒";
                        case 'newJob':
                            return "新增职位提醒";
                        case 'recommendNotProcess':
                            return "本月未处理推荐提醒";
                        case 'jobShare':
                            return `职位分享：[${extension.data.firmShortName}-${extension.data.jobName}]`;
                        case 'candidateShare':
                            return `候选人分享：[${this.checkResult !== 2 ? extension.data.candidateName : (extension.data.candidateName[0] + '**')}-${extension.data.title}]`;
                        default:
                            if(this.conversation.lastMessage.payload.data === 'group_create') {
                                return this.conversation.lastMessage.fromAccoutName ? `[${this.conversation.lastMessage.fromAccoutName}发起群聊]` : '[发起群聊]';
                            }
                            return '[群提示消息]';
                    }
                }
            }
            if(this.conversation.lastMessage.type === TIM.TYPES.MSG_GRP_TIP) {
                return '[群提示消息]';
            }
            return this.conversation.lastMessage.messageForShow;
        },
    },
    filters: {
        timeFilter(val) {
            if(val) {
                val *= 1000;
                let messageDate = new Date(val);
                let today = new Date(), year, date;
                year = today.getFullYear();
                date = today.getDate();
                if(messageDate.getDate() == date) {
                    return moment(val).format('HH:mm');
                } else if(messageDate.getDate() + 1 == date) {
                    return '昨天';
                } else if(messageDate.getDate() + 2 == date) {
                    return '前天';
                } else if(messageDate.getFullYear() == year) {
                    return moment(val).format('MM/DD');
                } else {
                    return moment(val).format('YYYY/MM/DD');
                }
            }
            return ""
        }
    },
    methods: {
        selectConversation() {
            this.$emit('handleCurrentConversation', this.conversation);
        }
    }
}
</script>

<style lang="scss" scope>
    .conversation-item {
        height: 64px;
        display: flex;
        padding: 12px;
        padding-right: 0;
        color: #999;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        &::after {
            content: "";
            width: 280px;
            height: 1px;
            display: inline-block;
            background-color: #ddd;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &.choose {
            background-color: rgba(56, 188, 157, 0.1);
            &:hover {
                background-color: rgba(56, 188, 157, 0.1);
            }
        }
        &:hover {
            background-color: rgba(56, 188, 157, 0.04);
        }
        .conversation-avatar {
            min-width: 28px;
            max-width: 28px;
            width: 28px;
            height: 28px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
            }
            &.is-group {
                img {
                    border: 1px solid $primary;
                }
            }
            &.is-online {
                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    overflow: hidden;
                    bottom: -4px;
                    right: -2px;
                    background-color: #38bc9d;
                    border: 2px solid #fff;
                }
            }
        }
        .conversation-info {
            width: 100%;
            margin-left: 6px;
            cursor: default;
            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 20px;
                .conversation-name {
                    color: #444;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    position: relative;
                    &.has-inner-label {
                        padding-right: 34px;
                    }
                    &.has-candidate-label {
                        padding-right: 77px;
                    }
                }
                .conversation-label {
                    display: inline-block;
                    width: 54px;
                    height: 34px;
                    position: absolute;
                    top: 1px;
                    right: 0;
                    border: 1px solid #74d0ba;
                    border-radius: 16px;
                    font-size: 20px;
                    transform: scale(0.5);
                    transform-origin: top right;
                    color: $primary;
                    text-align: center;
                    line-height: 30px;
                    &.company-label {
                        border-color: #e9b469;
                        color: #ec9e2e;
                    }
                    &.candidate-label {
                        width: 140px;
                    }
                }
                .conversation-date {
                    font-size: 12px;
                    margin-left: 10px;
                    white-space: nowrap;
                }
            }
            .last-message-container {
                line-height: 20px;
                display: flex;
                align-items: center;
                .last-message {
                    font-size: 12px;
                    flex-grow: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    .atme-tip {
                        color: #E24E3A;
                    }
                }
                .el-icon-close-notification {
                    font-size: 14px;
                    margin: 0 6px;
                }
                .unread-count-container {
                    width: 18px;
                    height: 18px;
                    margin-left: 6px;
                    .unread-count {
                        display: inline-block;
                        min-width: 36px;
                        max-width: 36px;
                        height: 36px;
                        background-color: #E24E3A;
                        color: #fff;
                        font-size: 20px;
                        text-align: center;
                        border-radius: 50%;
                        transform: scale(0.5);
                        transform-origin: top left;
                        line-height: 36px;
                    }
                }
                .unread-tip {
                    min-width: 8px;
                    max-width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #E24E3A;
                }
            }
        }
    }
</style>