<template>
  <div class="summary-list-wrap">
    <!-- <div class="summary-list-title">全部小结({{ totalCount }})</div> -->
    <ul class="summary-list" v-if="totalCount">
      <li
        class="summary-item"
        :class="current_active == index ? 'summary-item-active' : ''"
        v-for="(item, index) in listData"
        @click="showSummary(item, index)"
      >
        <span class="summary-title">{{ item.timeTitle }}工作日报</span>
        <span class="summary-time">{{ item.timeTitle }}</span>
      </li>
    </ul>
    <div class="summary-default" v-else>
      <div class="default-left">
        <img :src="require('@src/assets/images/pic_empty.png')" />
      </div>
      <div class="default-right">
        <p class="default-title">暂无订阅数据</p>
        <p>添加订阅，才能及时掌握工作情况哦～</p>
        <span class="btn-new btn-subscibe"></span>
      </div>
    </div>
    <el-pagination
      class="pagination-common"
      :current-page="current"
      :pager-count="5"
      layout="total, prev, pager, next, slot"
      :total="totalCount"
      v-if="totalCount"
      @current-change="handleCurrentChange">
      <span class="pagination-text">
        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
        <span @click="handlePagerJump">跳转</span>
      </span>
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "summaryList",
  data() {
    return {
      current_active: '',
      listData: [],
      totalCount: 0,
      totalPage: 1,
      current: 1,
      pagerJump: 0,
      user: ""
    };
  },
  created() {
    let userInfo = this.$store.state.user.userInfo;
    console.log(this.$store.state.user);
    this.user = userInfo;
    this.listData = this.generateData({
      page: 1
    });
    /* $.post('/User/userinfo', {
                a: 1
            }).then(res =>{
                if(res.succeeded) {
                    debugger
                    this.user = res.data;
                    this.listData = this.generateData({
                        page:1
                    })
                }
            }).fail(err => {
                debugger

            }) */
  },
  methods: {
    generateData(json) {
      let startTime = json.startTime || new Date("2019-03-02 00:00");
      let page = json.page || 1;
      let pageSize = json.pageSize || 10; //页大小
      let endTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ); //获取当天零点的时间
      let pageEndTime = endTime - (page - 1) * pageSize * 60 * 60 * 1000 * 24; //当前页的结束时间
      let dayNum = Math.ceil((endTime - startTime) / (60 * 60 * 1000 * 24)); //总天数
      this.totalCount = dayNum;
      this.$emit('getTotalCount', this.totalCount)
      let totalPage = Math.ceil(dayNum / pageSize); //总页数
      this.totalPage = totalPage;
      let mod = dayNum % pageSize; //余数
      let data = [];
      let len;
      if (page < totalPage) {
        len = pageSize;
      } else {
        len = dayNum - pageSize * (totalPage - 1);
      }
      for (let i = 0; i < len; i++) {
        let curEndTime = pageEndTime - i * 60 * 60 * 1000 * 24;
        let timeObj = {};
        timeObj.timeStamp = curEndTime;
        timeObj.timeTitle = this.formatDate(new Date(curEndTime));
        data.push(timeObj);
      }
      return data;
    },
    handleCurrentChange(val) {
      this.current = val;
      this.listData = this.generateData({
        page: this.current
      });
    },
    handlePagerJump(){
      let currentPager = Number(this.pagerJump),
      currentPageTotal = Math.ceil(this.totalCount/10);
      if(currentPager > 0 && currentPager <= currentPageTotal){
          this.handleCurrentChange(currentPager)
      }
    },
    formatDate(timeStamp, type) {
      let year = new Date(timeStamp).getFullYear();
      let month = new Date(timeStamp).getMonth() + 1;
      let day = new Date(timeStamp).getDate();
      let date;
      if (type == 1) {
        date = `${year}年${month > 9 ? month : "0" + month}月${
          day > 9 ? day : "0" + day
        }日`;
      } else {
        date = `${year}.${month > 9 ? month : "0" + month}.${
          day > 9 ? day : "0" + day
        }`;
      }
      return date;
    },
    showSummary(item, index) {
      this.current_active = index;

      // this.$summary.showDialog({
      //   isToday: true,
      //   user: this.user,
      //   timeStamp: item.timeStamp,
      //   noMoreBtn: true,
      //   triggerType: 2
      // });

      this.$emit('showDialog', {
        isToday: true,
        user: this.user,
        timeStamp: item.timeStamp,
        noMoreBtn: true,
        triggerType: 2
      });
    }
  }
};
</script>

<style lang="scss">
.summary-list-wrap {
  // width: 1126px;
  // margin: 0 auto;
  // color: #444;
  // padding: 20px 0;
  .summary-list-title {
    font-size: 18px;
  }
  .el-pagination.pagination-common {
    display: flex;
    // justify-content: space-between;
    text-align: right;
    margin-top: 40px;
    .el-pagination__total {
      max-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pagination-text span {
      margin-left: 10px !important;
    }
  }
}
.summary-list {
  font-size: 14px;
  // margin-top: 12px;
  // height: 560px;
  .summary-item {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    .summary-time {
      font-size: 14px;
      color: #999;
    }
    &:hover {
      color: #38bc9d;
      background: rgba(56, 188, 157, 0.12);
    }
    &-active {
      color: #38bc9d;
      background: rgba(56, 188, 157, 0.12);
    }
  }
}
.summary-default {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 260px 0 30px;
  .default-left {
    width: 240px;
    height: 214px;
    margin-right: 90px;
    > img {
      width: 100%;
    }
  }
  .default-right {
    font-size: 16px;
    .default-title {
      font-size: 18px;
      color: #444;
    }
  }
}
</style>
