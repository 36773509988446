var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "message-bubble",
    {
      attrs: { isMine: _vm.isMine, message: _vm.message, groupId: _vm.groupId },
    },
    [
      _c(
        "div",
        {
          staticClass: "file-element-wrapper",
          attrs: { title: "单击下载" },
          on: { click: _vm.downloadFile },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("i", { staticClass: "file-icon", class: _vm.fileIconName }),
            _c("div", { staticClass: "file-element" }, [
              _c("span", { staticClass: "file-name" }, [
                _vm._v(_vm._s(_vm.fileName)),
              ]),
            ]),
          ]),
          _vm.showProgressBar
            ? _c("el-progress", {
                attrs: {
                  percentage: _vm.percentage,
                  color: (percentage) =>
                    percentage === 100 ? "#67c23a" : "#409eff",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }