var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "490px",
        height: "850px",
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                width: "104",
                trigger: "hover",
                "popper-class": "share-popper",
              },
            },
            [
              _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c("i", { staticClass: "el-icon-share" }),
                _vm._v(" 分享"),
              ]),
              _c("div", { staticClass: "qr-code", attrs: { id: "qrcode" } }),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "pic-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "pic-main",
          },
          [
            _c("div", { staticClass: "h1" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/share_title.png"),
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "pre" }, [_vm._v("Dear")]),
            _c("div", { staticClass: "date" }, [
              _vm._v("Hi～今天是你加入我们 "),
              _c(
                "span",
                {
                  staticClass: "orange",
                  domProps: { textContent: _vm._s(_vm.annData.anniversary) },
                },
                [_vm._v("3")]
              ),
              _vm._v(" 周年的日子，你于 "),
              _c(
                "span",
                {
                  staticClass: "orange",
                  domProps: { textContent: _vm._s(_vm.annData.joinFirmTime) },
                },
                [_vm._v("2018年11月8日")]
              ),
              _vm._v(" 加入我们，转眼间，我们已经携手走过了 "),
              _c(
                "span",
                {
                  staticClass: "orange",
                  domProps: { textContent: _vm._s(_vm.annData.days) },
                },
                [_vm._v("1068")]
              ),
              _vm._v(" 个日日夜夜。"),
            ]),
            _c("div", { staticClass: "bold" }, [
              _vm._v("你做的每件事都被铭记"),
            ]),
            _c("div", { staticClass: "do-title" }, [_vm._v("你在猎必得")]),
            _c("div", { staticClass: "do-item" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/create.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "item-text" }, [
                _vm._v("\n                    创建了 "),
                _c(
                  "span",
                  {
                    staticClass: "green",
                    domProps: {
                      textContent: _vm._s(_vm.annData.candidateCount),
                    },
                  },
                  [_vm._v("6788")]
                ),
                _vm._v(" 位人才\n                "),
              ]),
            ]),
            _c("div", { staticClass: "do-item" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/search_icon.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "item-text" }, [
                _vm._v("\n                    寻访了 "),
                _c(
                  "span",
                  {
                    staticClass: "green",
                    domProps: {
                      textContent: _vm._s(_vm.annData.inquiryLogCount),
                    },
                  },
                  [_vm._v("6788")]
                ),
                _vm._v(" 位人才\n                "),
              ]),
            ]),
            _c("div", { staticClass: "do-item" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/tel_icon.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "item-text" }, [
                _vm._v("\n                    打了 "),
                _c(
                  "span",
                  {
                    staticClass: "green",
                    domProps: {
                      textContent: _vm._s(_vm.annData.callRecordCount),
                    },
                  },
                  [_vm._v("6788")]
                ),
                _vm._v(" 分钟电话\n                "),
              ]),
            ]),
            _c("div", { staticClass: "do-item" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/recommend_icon.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "item-text" }, [
                _vm._v("\n                    推荐了 "),
                _c(
                  "span",
                  {
                    staticClass: "green",
                    domProps: {
                      textContent: _vm._s(_vm.annData.recommendationCount),
                    },
                  },
                  [_vm._v("6788")]
                ),
                _vm._v(" 个人才\n                "),
              ]),
            ]),
            _c("div", { staticClass: "do-item" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/anniversary/offer.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "item-text" }, [
                _vm._v("\n                    帮助客户和人才 "),
                _c(
                  "span",
                  {
                    staticClass: "green",
                    domProps: { textContent: _vm._s(_vm.annData.offerCount) },
                  },
                  [_vm._v("6788")]
                ),
                _vm._v(" 个offer\n                "),
              ]),
            ]),
            _c("div", { staticClass: "tks-text" }, [
              _vm._v(
                "\n                感谢往日里你的辛勤付出，与公司一同成长、壮大。未来，让我们继续携手前行。"
              ),
              _c("span", { staticClass: "orange" }, [
                _vm._v(
                  "祝入职 " + _vm._s(_vm.annData.anniversary) + " 周年快乐！"
                ),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "heart" }, [
          _c("img", {
            attrs: {
              src: require("@src/assets/images/anniversary/love_icon.png"),
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "pic-footer" }, [
          _c("img", {
            attrs: {
              src: require("@src/assets/images/anniversary/logo.png"),
              alt: "",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }