const state = {
    userInfo: {}
}

const getters = {

}

const actions = {
    setUserInfo({ commit, state }, userInfo) {
        commit('updateUserInfo', { userInfo: userInfo });
    },
    changeUserInfo ({commit, state}, param){
        commit('setUserInfo', param);
    }
}

const mutations = {
    updateUserInfo(state, { userInfo }) {
        state.userInfo = userInfo;
        // console.log(state.userInfo)
    },
    setUserInfo(state, param){
        for( let key in param){
            state.userInfo[key] = param[key];
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
