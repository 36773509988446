export function avatarHost () {
    if(NODE_ENV == 'development') {
        return 'https://apis.rc.bazhua.tech/openIM/apis/direct/avatar';
    }
    if (NODE_ENV == 'rc') { 
        return 'https://apis.rc.bazhua.tech/openIM/apis/direct/avatar';
    }
    if(NODE_ENV == 'staging') {
        return 'https://apis-shenzhen-ali.bazhua.me/openIM/stg/apis/direct/avatar';
    }
    return 'https://apis-shenzhen-ali.bazhua.tech/openIM/apis/direct/avatar'
}