// canvas 实现 watermark
export function __WaterMark({
    // 使用 ES6 的函数默认值方式设置参数的默认取值
    // 具体参见 https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Functions/Default_parameters
    container = document.body,
    width = "160",
    height = "150",
    opacity = '0.01',
    fontSize = '24px',
    userName = "请勿外传",
    userId = "请勿外传",
    zIndex = 10001
} = {}) {
    if (userName.length > 6) {
        let length = userName.length;
        width = 25 * length;
        height = parseInt(width * (Math.sqrt(3) / 2));
    }
    let rotateX = parseInt(width / 2);
    let rotateY = parseInt(height / 2);
    const args = arguments[0];
    const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
  <text 
    text-anchor="middle"
    stroke="transparent"
    stroke-width="1"
    stroke-opacity="1"
    fill="#000"
    transform="rotate(30, ${rotateX} ${rotateY})"
    style="font-size: ${fontSize};
            font-family: 微软雅黑 Arial;
            opacity: ${opacity};
            font-weight: 200;">
    <tspan x="50%" y="50%">
        ${userName}
    </tspan>
    <tspan x="50%" dy="36px">
        ${userId}
    </tspan>
  </text>
</svg>`;
    const base64Url = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svgStr)))}`;
    const __wm = document.querySelector('.__wm');

    const watermarkDiv = __wm || document.createElement("div");
    const styleStr = `
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:${zIndex};
    pointer-events:none;
    background-repeat:repeat;
    background-image:url('${base64Url}')`;

    watermarkDiv.setAttribute("style", styleStr);
    watermarkDiv.classList.add("__wm");

    if (!__wm) {
        container.style.position = "relative";
        container.insertBefore(watermarkDiv, container.firstChild);
    }

    const MutationObserver =
        window.MutationObserver || window.WebKitMutationObserver;
    if (MutationObserver) {
        let mo = new MutationObserver(function () {
            const __wm = document.querySelector(".__wm");
            // 只在__wm元素变动才重新调用 __WaterMark
            if (
                (__wm && __wm.getAttribute("style") !== styleStr) ||
                !__wm
            ) {
                // 避免一直触发
                mo.disconnect();
                mo = null;
                __WaterMark(JSON.parse(JSON.stringify(args)));
            }
        });

        mo.observe(container, {
            attributes: true,
            subtree: true,
            childList: true
        });
    }
}