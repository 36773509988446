<template>
    <div class="search-result" v-loading="!isSearchEnd">
        <ul v-if="isSearchEnd && !empty" class="search-result-list">
            <li v-if="searchResultObject.innerMemberInfos && searchResultObject.innerMemberInfos.length > 0">
                <dl>
                    <dt>公司同事</dt>
                    <dd 
                        v-for="(member, index) in searchResultObject.innerMemberInfos" 
                        :key="member.unionId" 
                        v-show="isSingle || index <= 1 || (index >= 2 && innerMemberViewAll)"
                        @click="handleJumpConversation('c2c', member.unionId)"
                    >
                        <member-item 
                            v-if="isShare"
                            :member="member"
                            :isShare="true"
                            type="innerMemberList"
                            :disabled="checkMemberDisabled(member.unionId)"
                            :value="checkMemberSelected(member.unionId)"
                            @member-select="handleMemberSelect"
                        ></member-item>
                        <template v-else>
                            <img :src="`${avatarBaseUrl}/user/${member.unionId}`" />
                            <div class="text-info">
                                <p class="primary">{{member.userName}}</p>
                                <p class="normal">{{member.company}} | {{member.title}}</p>
                            </div>
                        </template>
                    </dd>
                </dl>
                <p 
                    v-if="!isSingle && searchResultObject.innerMemberInfos.length > 2 && innerMemberViewAll == false" 
                    class="view-more" 
                    @click="innerMemberViewAll = true"
                >查看全部</p>
            </li>
            <li v-if="filteredGroupInfos.length > 0">
                <dl>
                    <dt>群聊</dt>
                    <dd 
                        v-for="(group, index) in filteredGroupInfos" 
                        :key="group.groupId" 
                        v-show="isSingle || index <= 1 || (index >= 2 && groupViewAll)"
                        @click="handleJumpConversation('group', group.groupId)"
                    >
                        
                        <member-item 
                            v-if="isShare"
                            :member="group"
                            :isGroup="true"
                            :isShare="true"
                            type="groupList"
                            :disabled="checkMemberDisabled(group.groupId)"
                            :value="checkMemberSelected(group.groupId)"
                            @member-select="handleMemberSelect"
                        ></member-item>
                        <template v-if="!isShare">
                            <img :src="`${avatarBaseUrl}/group/${group.groupId}`" />
                            <div class="text-info">
                                <p class="primary">{{group.groupName}}</p>
                                <!-- <p class="normal"></p> -->
                            </div>
                        </template>
                    </dd>
                </dl>
                <p 
                    v-if="!isSingle && filteredGroupInfos.length > 2 && groupViewAll == false"
                    class="view-more" 
                    @click="groupViewAll = true"
                >查看全部</p>
            </li>
            <li v-if="!isShare && messageInfo && messageInfo.data && messageInfo.data.length > 0">
                <dl>
                    <dt>聊天记录</dt>
                    <dd 
                        v-for="(message, index) in messageInfo.data" 
                        :key="message.id" 
                        v-show="isSingle || index <= 1 || (index >= 2 && messageViewAll)"
                        @click="handleShowRecordList(message)"
                    >
                        <img v-if="message.type == 0" :src="`${avatarBaseUrl}/user/${message.id}`" />
                        <img v-if="message.type == 1" :src="`${avatarBaseUrl}/group/${message.id}`" />
                        <div class="text-info">
                            <p class="primary">{{message.name}}</p>
                            <p class="normal">{{message.count}}条相关的聊天记录</p>
                        </div>
                    </dd>
                </dl>
                <p 
                    v-if="!isSingle && messageInfo.data && messageInfo.data.length > 2 && messageViewAll == false"
                    class="view-more" 
                    @click="messageViewAll = true"
                >查看全部</p>
            </li>
            <li v-if="canShowOuterMembers && searchResultObject.outerMemberInfos && searchResultObject.outerMemberInfos.length > 0">
                <dl>
                    <dt>外部公司</dt>
                    <dd 
                        v-for="(member, index) in searchResultObject.outerMemberInfos" 
                        :key="member.unionId"
                        v-show="isSingle || index <= 1 || (index >= 2 && outerMemberViewAll)"
                        @click="handleJumpConversation('c2c', member.unionId)"
                    >
                        <member-item 
                            v-if="isShare"
                            :member="member"
                            :isShare="true"
                            type="outerMemberList"
                            :disabled="checkMemberDisabled(member.unionId)"
                            :value="checkMemberSelected(member.unionId)"
                            @member-select="handleMemberSelect"
                        ></member-item>
                        <template v-else>
                            <img :src="`${avatarBaseUrl}/user/${member.unionId}`" />
                            <div class="text-info">
                                <p class="primary">{{member.userName}}</p>
                                <p class="normal">{{member.company}} | {{member.title}}</p>
                            </div>
                        </template>
                    </dd>
                </dl>
                <p 
                    v-if="!isSingle && searchResultObject.outerMemberInfos.length > 2 && outerMemberViewAll == false"
                    class="view-more" 
                    @click="outerMemberViewAll = true"
                >查看全部</p>
            </li>
            <li v-if="searchResultObject.jdtMemberInfos  && searchResultObject.jdtMemberInfos .length > 0">
                <dl>
                    <dt>职通车候选人</dt>
                    <dd 
                        v-for="(member, index) in searchResultObject.jdtMemberInfos " 
                        :key="member.unionId"
                        v-show="isSingle || index <= 1 || (index >= 2 && jdtMemberViewAll)"
                        @click="handleJumpConversation('c2c', member.unionId)">
                        <member-item 
                            v-if="isShare"
                            :member="member"
                            :isShare="true"
                            type="outerMemberList"
                            :disabled="checkMemberDisabled(member.unionId)"
                            :value="checkMemberSelected(member.unionId)"
                            @member-select="handleMemberSelect"
                        ></member-item>
                        <template v-else>
                            <img :src="`${avatarBaseUrl}/user/${member.unionId}`" />
                            <div class="text-info">
                                <p class="primary">{{member.userName}}</p>
                                <p class="normal">{{member.company}} | {{member.title}}</p>
                            </div>
                        </template>
                    </dd>
                </dl>
                <p 
                    v-if="!isSingle && searchResultObject.jdtMemberInfos .length > 2 && jdtMemberViewAll == false"
                    class="view-more" 
                    @click="jdtMemberViewAll = true"
                >查看全部</p>
            </li>
        </ul>
        <div v-if="isSearchEnd && empty" class="empty">暂无数据</div>
        <transition name="fade">
            <div v-if="showRecordList" class="chat-record-list" v-loading="recordListLoading">
                <p class="chat-record-list-title">
                    <i>聊天记录-{{recordConversationName}}</i>
                    <span @click="handleHideRecordList">返回</span>
                </p>
                <ul
                    v-infinite-scroll="getRecordList"
                    :infinite-scroll-immediate="false"
                    :infinite-scroll-disabled="!recordListFetched || recordListPageLoading || recordListNoMore"
                >
                    <li v-for="(record, index) in recordList" :key="index" @click="handleJumpMessage(record)">
                        <img :src="`${avatarBaseUrl}/user/${record.fromAccountId}`" />
                        <div class="text-info">
                            <p class="primary">{{ record.userName }}</p>
                            <p class="normal">{{ record.content }}</p>
                        </div>
                        <div class="date">{{ record.msgTime | getDate }}</div>
                    </li>
                    <p class="loading-info" v-if="recordListFetched && recordListPageLoading">加载中...</p>
                    <p class="loading-info" v-if="recordListFetched && recordListNoMore">没有更多了</p>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import imService from '#/js/service/imService.js';
import MemberItem from '../group/member-item.vue';
import { getDate } from '../utils/date.js';
import { avatarHost } from '../utils/avatarHost.js';
export default {
    components: {
        MemberItem
    },
    data() {
        return {
            showRecordList: false,
            recordListLoading: false,
            recordListPageLoading: false, //分页loading
            recordListNoMore: false,
            recordListFetched: false,
            recordList: [],
            recordConversationName: '',
            innerMemberViewAll: false,
            outerMemberViewAll: false,
            jdtMemberViewAll: false,
            groupViewAll: false,
            messageViewAll: false,
            pageNo: 1,
            pageSize: 10
        }
    },
    props: {
        searchResultObject: Object,
        isSearchEnd: Boolean,
        isShare: Boolean,   //是否为分享职位、简历弹框
        selectedMemberList: Array,
        keyword: String,
        shareData: Object,
        innerLabelMap: Object
    },
    filters: {
        getDate(value) {
            if(!value) {
                return '';
            }
            let time = new Date(value);
            if (time.toString().indexOf('1970') > -1) {
                time = new Date(value * 1000);
            }
            return getDate(time);
        }
    },
    computed: {
        //搜索结果是否只有一个模块
        isSingle() {
            let count = 0;
            if(this.messageInfo && this.messageInfo.data && this.messageInfo.data.length > 0) {
                count++;
            }
            if(this.filteredGroupInfos.length > 0) {
                count++;
            }
            if(this.searchResultObject.innerMemberInfos && this.searchResultObject.innerMemberInfos.length > 0) {
                count++;
            }
            if(this.searchResultObject.jdtMemberInfos && this.searchResultObject.jdtMemberInfos.length > 0) {
                count++;
            }
            if(this.canShowOuterMembers && this.searchResultObject.outerMemberInfos && this.searchResultObject.outerMemberInfos.length > 0) {
                count++;
            }
            if(count > 1) {
                return false;
            }else {
                return true;
            }
        },
        messageInfo() {
            return this.searchResultObject.messageInfoPage
        },
        filteredGroupInfos() {
            let groupInfos = this.searchResultObject.groupInfos || [];
            if(this.isShare && this.shareData && !this.canShowOuterMembers) {
                let resultArr = [];
                groupInfos = groupInfos.filter(item => {
                    return item.innerCheck;
                })
            }
            return groupInfos;
        },
        canShowOuterMembers() {
            if(this.isShare && this.shareData && this.shareData.type == 'jobShare' && !this.shareData.isPublic) {
                return false;
            }
            return true;
        },
        empty() {
            if(this.isShare) {
                if(
                    (this.searchResultObject.innerMemberInfos && this.searchResultObject.innerMemberInfos.length > 0)
                    || (this.searchResultObject.outerMemberInfos && this.searchResultObject.outerMemberInfos.length > 0)
                    || (this.searchResultObject.jdtMemberInfos && this.searchResultObject.jdtMemberInfos.length > 0)
                    || (this.filteredGroupInfos.length > 0)
                ) {
                    return false;
                }
                return true;
            }else {
                if(
                    (this.searchResultObject.innerMemberInfos && this.searchResultObject.innerMemberInfos.length > 0)
                    || (this.searchResultObject.outerMemberInfos && this.searchResultObject.outerMemberInfos.length > 0)
                    || (this.searchResultObject.jdtMemberInfos && this.searchResultObject.jdtMemberInfos.length > 0)
                    || (this.filteredGroupInfos.length > 0)
                    || (this.messageInfo && this.messageInfo.data && this.messageInfo.data.length >0)
                ) {
                    return false;
                }
                return true;
            }
        },
        avatarBaseUrl() {
            return avatarHost();
        }
    },
    methods: {
        handleShowRecordList(message) {
            this.recordConversationName = message.name;
            this.recordList = [];
            this.showRecordList = true;
            this.recordListFetched = false;
            this.pageNo = 1;
            this.recordListLoading = true;
            this.getRecordList(message.key);
        },
        getRecordList(key) {
            this.recordListPageLoading = true;
            imService.getHistoryMessageList({
                key: key,
                keywords: [this.keyword],
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then(res => {
                this.recordList = res.data;
                this.recordListLoading = false;
                this.recordListPageLoading = false;
                if(res.hasNext) {
                    this.recordListNoMore = false;
                    this.pageNo++;
                }else {
                    this.recordListNoMore = true;
                }
            })
        },
        handleHideRecordList() {
            this.showRecordList = false;
        },
        handleJumpConversation(type, id) {
            openIM.getOneConversation({
                sourceID: id,
                sessionType: type == 'group' ? 2 : 1
            }).then(({ data })=>{
                let conversation = JSON.parse(data);
                this.$emit('handleCurrentConversation', conversation);
            }).catch(err => {
                console.log(err);
            })
        },
        handleJumpMessage(record) {
            this.$emit('handlePositionMessage', record);
        },
        checkMemberSelected(unionId) {
            return this.selectedMemberList.some(item => {
                return (item.unionId || item.groupId) === unionId
            });
        },
        checkMemberDisabled(unionId) {
            return this.selectedMemberList.some(item =>{
                return item.unionId === unionId && item.disabled;
            });
        },
        handleMemberSelect(value, member) {
            this.$emit('member-select', value, member);
        }
    }
}
</script>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateX(10px);
    }
    .search-result {
        position: relative;
        border-top: 1px solid #d8d8d8;
        height: 100%;
        padding-left: 14px;

        .empty {
            margin: 20px 14px 0 0;
            text-align: center;
            font-size: 14px;
            color: #999;
        }

        &-list {
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            padding-right: 8px;
            dt {
                padding-top: 5px;
                line-height: 36px;
                font-size: 12px;
                color: #999;
                font-weight: bold;
                border-bottom: 1px solid #d8d8d8;
            }

            dd {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 48px;
                margin: 4px 0 8px 0;
                cursor: default;

                > img {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                    border: 1px solid $primary;
                }
            }
            .view-more {
                font-size: 12px;
                color: $primary;
                cursor: pointer;
            }
        }
        .text-info {
            flex: 1;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 168px;

            p {
                width: 100%;
                margin: 0;
                line-height: 1.5;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.primary {
                    font-size: 14px;
                    color: #333;
                }
                &.normal {
                    font-size: 12px;
                    color: #999;
                }
            }
        }

        .chat-record-list {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            padding: 0 8px 0 14px;
            background-color: #fff;

            &-title {
                line-height: 38px;
                padding-top: 6px;
                margin: 0;
                font-size: 12px;
                color: #999;
                border-bottom: 1px solid #ddd;

                i {
                    display: inline-block;
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                span {
                    float: right;
                    font-size: 14px;
                    color: $primary;
                    margin-right: 4px;
                    cursor: default;
                }
            }

            .loading-info {
                text-align: center;
                margin: 10px 0;
                font-size: 12px;
                color: #999;
            }

            > ul {
                flex: 1;
                flex-grow: 1;
                overflow-y: auto;
                margin-right: -8px;
                padding-right: 8px;
            }

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 14px 0;
                border-bottom: 1px solid #ddd;

                > img {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .text-info {
                    max-width: 150px;
                }

                .date {
                    margin-top: -18px;
                    width: 60px;
                    font-size: 12px;
                    color: #999;
                    text-align: right;
                    padding-right: 4px;
                    white-space: nowrap;
                }
            }
        }
    }
</style>