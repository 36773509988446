import { render, staticRenderFns } from "./share-message.vue?vue&type=template&id=f83f385e&"
import script from "./share-message.vue?vue&type=script&lang=js&"
export * from "./share-message.vue?vue&type=script&lang=js&"
import style0 from "./share-message.vue?vue&type=style&index=0&id=f83f385e&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f83f385e')) {
      api.createRecord('f83f385e', component.options)
    } else {
      api.reload('f83f385e', component.options)
    }
    module.hot.accept("./share-message.vue?vue&type=template&id=f83f385e&", function () {
      api.rerender('f83f385e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/component/page/open-instant-message/message/message-elements/share-message.vue"
export default component.exports