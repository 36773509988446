var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "chatBubble",
      staticClass: "chat-bubble",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
        },
        mousedown: function ($event) {
          $event.stopPropagation()
          return _vm.handleMessageDropdown.apply(null, arguments)
        },
      },
    },
    [
      !_vm.message.isRevoked
        ? _c(
            "div",
            {
              ref: "messageContent",
              staticClass: "message-content",
              class: _vm.bubbleStyle,
              style: {
                "background-color":
                  _vm.message.msgType === _vm.TIM.TYPES.MSG_FILE ? "#fff" : "",
              },
              on: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      !_vm.message.isRevoked &&
      !(
        (_vm.message.msgType === "TIMSoundElem" ||
          _vm.message.msgType === "TIMVideoFileElem") &&
        (!_vm.isMine || !(_vm.isMine && !_vm.isTimeout))
      )
        ? _c(
            "div",
            {
              staticClass: "message-dropdown-btn-container",
              style: {
                display: _vm.isShowDropdown ? "inline-block" : "",
                right: _vm.isMine ? "unset" : "-26px",
                left: _vm.isMine ? "-26px" : "unset",
              },
            },
            [
              _c(
                "span",
                {
                  ref: "messageDropdownBtn",
                  staticClass: "message-dropdown-btn",
                  style: _vm.isMine
                    ? "margin-right: 6px;"
                    : "margin-left: 6px;",
                },
                [_c("i", { staticClass: "el-icon-more" })]
              ),
            ]
          )
        : _vm._e(),
      !_vm.message.isRevoked
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowDropdown,
                  expression: "isShowDropdown",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dropdownLoading,
                  expression: "dropdownLoading",
                },
              ],
              ref: "messageDropdown",
              staticClass: "message-dropdown-menu",
            },
            [
              _c("li", { ref: "copyBtn", staticClass: "menu-item" }, [
                _vm._v("复制"),
              ]),
              _vm.isMine && !_vm.isTimeout
                ? _c(
                    "li",
                    {
                      staticClass: "menu-item",
                      on: { click: _vm.revokeMessage },
                    },
                    [_vm._v("撤回")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.message.isRevoked
        ? _c("div", { staticClass: "group-tip-element-wrapper" }, [
            _vm._v("\n    " + _vm._s(_vm.text) + "\n    "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }