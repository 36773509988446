<template>
  <div class="workbench-body">

    <div class="workbench-top">
        <!-- <workbench-top></workbench-top> -->
        <topbar ref="topbar" :showBtn="!showTab"></topbar>
    </div>
    <div class="main-wrap">
        <div class="side-bar">
            <sidebar
                @fold-callback="handleFoldCallback"
                ></sidebar>
        </div>
        <div class="body-wrapped"
            :class="{'isFold': isFold}">
            <!-- tab导航条 -->
            <!-- <tab-navs></tab-navs> -->
            <work-table-tab v-if="showTab"></work-table-tab>

            <div class="page-container">
                <!-- 这里放视口 -->
                <keep-alive :include="keepAliveComponents">
                    <router-view v-if="userInfoFetched"></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
    <!-- <div class="sidebar-container">
      <sidebar></sidebar>
    </div>
    <div class="main-container-outer">
      <div class="main-container">
        <topbar ref="topbar" :showBtn="!showTab"></topbar>
        <work-table-tab v-if="showTab"></work-table-tab>
        
        <div :class="getWrapClass">
          <keep-alive :include="keepAliveComponents">
              <router-view v-if="userInfoFetched"></router-view>
          </keep-alive>
        </div>
      </div>
      
    </div> -->
    <!-- <div :class="isHome?'home-view':'tab-view'"> -->
    <!-- <user-profile-popover></user-profile-popover> -->
    <!-- <summary-entry ref="summaryEntry" :user="user" v-if="showSummaryEntry"></summary-entry> -->
    
    <!-- <div v-if="isHome && showPinduoduoTip" class="pinduoduo-tip">
      <div class="pinduoduo-tip-img">
        <img :src="require('#/assets/images/pinduoduo-tip.png')" />
        <span class="close-btn" @click="closePinduoduoTip"></span>
      </div>
    </div> -->

    <global-inquiry-dialog ref="globalInquiryDialog" @refresh-candidate-inquiry="refreshCandidateInquiry"></global-inquiry-dialog>
    <!-- 播报推送 -->
    <broadcast-notification
        ref="broadcastNotification"
    />

  </div>
</template>

<script>
import {mapState,mapGetters,mapActions} from 'vuex';
import Sidebar from "#/views/work-table-home/layout/sidebar.vue";
import WorkbenchTop from "#/component/workbench/workbench-top.vue";
import TabNavs from "#/component/workbench/tab-navs.vue";
import Topbar from "#/views/work-table-home/layout/topbar.vue";
import WorkTableTab from "#/views/work-table-home/layout/workTableTab.vue";
import GlobalInquiryDialog from '#/component/common/dialog/globalInquiryDialog.vue';
// import UserProfilePopover from '@/component/page/user-profile-popover/index';
import BroadcastNotification from "#/views/broadcast-notification/index.vue";
import globalSignalR from '#/js/util/globalSignalR.js'
// import globalWebSocket from '#/js/util/globalWebSocket.js'
import { __WaterMark } from '#/js/util/waterMark.js'
import SummaryEntry from '#/component/summary/summary-entry.vue';
import CertificationService from "#/js/service/certificationService.js";

import emitter from '#/js/mixins/emitter.js';

export default {
  name: "",
  componentName: 'App',
  mixins: [emitter],
  components: {
    Sidebar,
    Topbar,
    WorkTableTab,
    GlobalInquiryDialog,
    // UserProfilePopover,
    SummaryEntry,
    BroadcastNotification,

    WorkbenchTop,
    TabNavs,
  },
  data() {
    return {
      user: {},
      userInfoFetched: false,
      showTab: false,
      isHome: true,
      showSummaryEntry:false,
      isMounted: false,
      showPinduoduoTip: false,

      isFold: false,
    };
  },
  
  created() {
      // _request({
      //     method: 'POST',
      //     url: '/User/userinfo',
      //     throwBusinessError: true
      // }).then(res => {
      _request({
          method: 'GET',
          url: '/user/userInfo',
          baseURL: "LbdJavaApi",
          javaProject: "performance",
          throwBusinessError: true
      }).then(res => {
          this.user = res;
          this.userInfoFetched = true;
          this.$store.dispatch('setUserInfo', res);
          if(res.firmId){
              this.showSummaryEntry = true;
          }

          window.BazaCommonTrack && window.BazaCommonTrack.setUserId(res['id'] || '');

        let _href = '';
        const {identityType, joiningStep} = res || {};
        // 处理输入地址没有跳转相应hr或者平台运营地址
        if(identityType === 1) {
            _href = "/Employee/";
        } else if(identityType === 3) {
            _href = "/Operation/";
        } else {
            if(joiningStep == 0) {
                _href = `/Account/#/firmSearch`;
            } else if(joiningStep == 1) {
                console.log(`1`)
            } else if(joiningStep == 2) {
                _href = `/Account/#/firmQuestionnaire?type=create&firmName=${res.firmShortName}`;
            } else if(joiningStep == 3) {
                _href = `/Account/#/firmQuestionnaire?type=apply&firmName=${res.firmShortName}`;
            } 
            // else if(joiningStep == 4) {
            //     _href = "/Headhunting/";
            // }
        }


        //   if(res.joiningStep == 0){
        //       // location.href = '/Firm/Search';
        //       if(location.href.indexOf("/firmSearch") < 0) {
        //           location.href = '/Account/#/firmSearch';
        //       }
        //   }
        //    else if(res.joiningStep != 4 && location.href.indexOf("/#/personalCenter") < 0 && [0,2].includes(res.identityType)) {
        //       location.href = '/#/personalCenter';
        //   }
        

        if(joiningStep == 5) {
            _href = `/Account/#/firmInfos?identityType=${identityType}`;
        }
        if(joiningStep == 6) {
            _href = `/Account/#/firmJoinFail?identityType=${identityType}&description=${res.description}`;
        }

        if(_href) location.href = _href;

          // if(res.isCFUser) {
          //   let pddTipLastShowTime = localStorage.getItem('pinduoduoTipLastShowTime') ? parseInt(localStorage.getItem('pinduoduoTipLastShowTime')) : '';
          //   let curTime = +new Date();
          //   if(!(pddTipLastShowTime && (curTime - pddTipLastShowTime) < 24*60*60*1000 && new Date().getDate() == new Date(pddTipLastShowTime).getDate())) {
          //     this.showPinduoduoTip = true;
          //   }
          // }
          
            window._globalSignalR = new globalSignalR({
                connectName: 'BazaHub',
                urlPrefix:res.signalRServerUrl,
                tokenFn: () => { return _getCookie('access_token'); },
                //电话助手暂时采用旧的websocket，故不去监听相应事件
                // hubMethodName:["AddPoints","closeWorkReport","ReceiveMessage","NotifyAssignedPerson","CheckAppStatus","CallUp"]
                hubMethodName:["AddPoints","closeWorkReport","ReceiveMessage","NotifyAssignedPerson","NotifyCallServiceEnd","CloseInquiryLog", "NotifyRedPacketBroadCast", "NotifyRedPacketGrab"]
            })

            // offer的播报+红包的播报
            window._globalSignalR.onSignalRData('NotifyRedPacketBroadCast', (val) => {
                if(res.isCFUser) {
                    console.log('NotifyRedPacketBroadCast接收成功');
                    console.log(val);
                    // let receiveTimeList = JSON.parse(localStorage.getItem(`receiveTimeList`)) || [];

                    // if(!receiveTimeList.includes(val.receiveTime)) {
                        this.$refs.broadcastNotification.show({
                            type: 'offer',
                            data: val.data,
                            // receiveTime: val.receiveTime,
                        });
                    // }
                }
            });
            window._globalSignalR.onSignalRData('NotifyRedPacketGrab', (val) => {
                console.log('NotifyRedPacketGrab-------红包推送接收成功')
                console.log(val)
                if(res.isCFUser) {
                    this.$refs.broadcastNotification.show({
                        type: 'redPackage',
                        data: val.data,
                        // receiveTime: val.receiveTime,
                    });
                }
            });


//             document.addEventListener('visibilitychange', function () {
//   // 用户打开或回到页面
//   if (document.visibilityState === 'visible') {
//     document.title = '页面可见';
//   }
// });

// if(-1 !== window.location.host.indexOf('youtube')) {
//     document.addEventListener('DOMContentLoaded', (event) => {

//         let popupContainer = document.querySelector('ytd-popup-container');

//         let observer = new MutationObserver(function(mutationsList) {
//             let confirmButton = popupContainer.querySelector('.yt-confirm-dialog-renderer paper-button');
//             if(confirmButton){
//                 // confirmButton.click();
//                 document.location.reload(true);
//             }
//         });

//         observer.observe(popupContainer, {childList: true});
//     });
// }


          window._globalSignalR.onSignalRData('AddPoints',(val)=>{
              if(res.isCFUser) {
                console.log('AddPoints接收成功')
                val.data.point = val.data.Point;
                setTimeout(() =>{
                    shortTips(`～恭喜你～<br />获得${val.data.point}积分`);
                }, 2000)
                window.UPDATE_INTEGRAL_NEW&&window.UPDATE_INTEGRAL_NEW(val.data);
              }
          })
          window._globalSignalR.onSignalRData('closeWorkReport',(val)=>{
              console.log('closeWorkReport接收成功')
              if(window.summaryVM){
                  summaryVM.isShow = false;
              }
          })
          window._globalSignalR.onSignalRData('NotifyAssignedPerson',(val)=>{
              console.log('NotifyAssignedPerson接收成功')
              let data = {
                  created: val.data.data.Created,
                  customerName: val.data.data.CustomerName,
                  distributor: val.data.data.Distributor,
                  importCount: val.data.data.ImportCount,
                  jobs: val.data.data.Jobs,
                  jobsBatchCreatedType: val.data.data.JobsBatchCreatedType,
                  recordId: val.data.data.RecordId
              }
              messageQueue.push(data);
          })

          if(this.isMounted) {
            this.$refs.globalInquiryDialog.initInquirySignalREvent();
          }

          // window._globalWebSocket = new globalWebSocket({
          //     userId: res.id,
          //     userName: res.userName,
          //     webSocketUrl: res.webSocketUrl,
          //     tokenFn: () => { return _getCookie('access_token'); },
          // })
      }).catch(err => {
          if(err && (err.errorCode == 401 || err.ErrorCode == 401)) {
              location.href = '/Account/';
          }
      });

      // 因为userInfo不是实时的，相当于拿报401不准确
      CertificationService.loginedUserInfo();

      _request({
          method: 'POST',
          url: '/Antiforgerytoken',
      }).then(res => {
          this.$store.dispatch('setVerificationToken', res);
      })

      _request({
          method: 'POST',
          url: "/api/tagcenter/tag/list",
          baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
          headers: {
              "AuthorizationTag": _getCookie('access_token')
          }
      }).then((res) => {
          this.$store.dispatch('setMyResumeItaps', res.tagList);
      });

      this.$store.dispatch('setCandidateListUrl', localStorage.getItem('isOldCandidateList') == 'true' ? '/candidates/enterprise' : '/candidates/firmCandidate');
  },
  mounted() {
    this.isMounted = true;
    this.$on('showInstantMessage', (item) => {
      this.$refs.topbar.$refs.summaryEntry.$refs.instantMessage.showInstantMessage(item);
    });
    this.$on('show-confList-dialog', () => {
      this.$refs.topbar.$refs.summaryEntry.getConfirmList(true);
    });
    this.$on('showShareToImDialog', (shareData) => {
        this.$refs.topbar.$refs.summaryEntry.$refs.instantMessage.showShareToImDialog(shareData);
    });
    this.$on('createDepartment', (item) => {
        console.log(this.$refs)
        this.$refs.topbar.$refs.summaryEntry.$refs.departmentDialog.show(false, item);
    })

    if(this.userInfoFetched) {
      this.$refs.globalInquiryDialog.initInquirySignalREvent();
    }

    // this.mockOfferBC();
    // this.mockRedPackageBC();
    this.isFold = localStorage.getItem('isFoldSiderbar') ? JSON.parse(localStorage.getItem('isFoldSiderbar')) : false;
  },
  watch: {
      $route(to,from){
          console.log(to.path);
          console.log(to.params);
          if(
            to.path == '/' || 
            // to.path == '/member' || 
            // to.path == '/personalCenter' || 
            // to.path == '/candidates/firmCandidate' || to.path == '/candidates/personal' || to.path == '/candidates/namelist' || to.path == '/candidates/linkedin' || 
            to.path == `/candidateDetail/${to.params.resumeId}` || to.path == `/linkedinCandidateDetail/${to.params.resumeId}` || 
            to.path == `/candidateDetail/${to.params.resumeId}/recommendation/${to.params.recommendationid}` || to.path == `/candidateDetail/${to.params.resumeId}/recommendationList` || to.path == `/recommendation/${to.params.recommendationid}/recommendationList` || to.path == `/candidateDetail/${to.params.resumeId}/comments` || 
            to.path == `/candidateDetail/${to.params.resumeId}/communicationList` || to.path == `/candidateDetail/${to.params.resumeId}/timeline` || to.path == `/candidateDetail/${to.params.resumeId}/attachments` || 
            to.path == '/resumeUpload/singleUpload' || to.path == '/resumeUpload/batchUpload' || to.path == '/resumeUpload/excelUpload' || to.path == `/resumeUploadResult/${to.params.id}` || 
            to.path == '/candidate/updatecheck' || 
            to.path == '/candidate/cocUpdate' || 
            // to.path == '/jobList' || 
            to.path == `/Job/${to.params.jobId}` || to.path == `/Job/${to.params.jobId}/recommendationList` || to.path == `/Job/${to.params.jobId}/recommendation/${to.params.recommendationid}` || to.path == `/jobCreate/copy` || 
            // to.path == `/jobCreate/single` || to.path == `/jobCreate/multi` || 
            to.path == `/jobCreate/multi/publishResult` ||
            // to.path == '/customerList' || 
            to.path == `/Customer/${to.params.customerId}` || to.path == '/addCustomerRank' || 
            // to.path == `/project` || 
            to.path == `/ProjectDetail/${to.params.projectId}` || 
            to.path == `/myGoal` || 
            to.path == `/recommendDetail` || to.path == `/resultRecommend` || 
            // to.path == `/market` || 
            // to.path == `/companySetting` || 
            // to.path == `/authorization` || 
            to.path == `/performancesDetail` || 
            to.path == `/teamPoints` || 
            to.path == `/deliveryTarget` || to.path == `/deliveryTargetDetail` || to.path == `/weekReport` || 
            to.path == `/offerReport` || 
            to.path == `/jdtReport` || 
            // to.path == `/jdt` || 
            // to.path == `/corporationOpportunity` || 
            to.path == `/opportunityJobDetail/${to.params.id}` ||  to.path == `/opportunityResumeDetail/${to.params.id}` || 
            to.path == `/leaderFeedbackData` || 
            to.path == `/trackingListShare` || 
            to.path == `/businessLineData` || 
            to.path == `/trackingListComment` || 
            to.path == `/workbench/talentReuse` || 
            to.path == `/batchCreateDepartment/${to.params.customerId}` || to.path == `/Customer/${to.params.customerId}/department` || to.path == `/Customer/${to.params.customerId}/department/${to.params.id}` ||
            to.path == `/applyFormPage` ||
            to.path == `/sendEmail` ||
            to.path == `/user/${to.params.userId}` || to.path == `/User/${to.params.userId}` ||
            // to.path == '/operationPlatform' || 
            to.path == '/operationPlatform/operationData' ||
            to.path == '/batchCandidateDetail' || to.path == '/batchOpportunityResumeDetail' || 
            // to.path == '/auditOpportunity' ||
            // to.path == '/offerBroadcast' ||
            to.path == '/jobCreate/single-success'
          ){
              this.showTab = false;
              this.isHome = true;
          }else{
              this.showTab = true;
              this.isHome = false;
          }
      },
      userInfoFetched: function(val) {
          if(val) {
              __WaterMark({
                  userName: `${this.user.realName}@` || '',
                  userId: this.user.userName || ''
              })
          }
      }
  },
  computed: {
    ...mapGetters('keepAlive',{
        keepAliveComponents:'keepAliveComponents'
    }),
    enableShow() {
        // return (location.href.indexOf('/Headhunting/MyCompany.html#/emailVerify') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
        // return (location.href.indexOf('/Headhunting/#/personalCenter?isEmailVerify=true') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
        return (location.href.indexOf('/Headhunting/#/personalCenter?Tab=myAdvantage') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
    },
    getWrapClass() {
        // :class="isHome?'home-view':'tab-view'"
        if(this.isHome) {
            if(this.enableShow) {
                return 'home-view home-view-has-announcement';
            } else {
                return 'home-view';
            }
        } else {
            if(this.enableShow) {
                return 'tab-view tab-view-has-announcement';
            } else {
                return 'tab-view';
            }
        }
    },
  },
  methods: {
    closePinduoduoTip() {
      this.showPinduoduoTip = false;
      localStorage.setItem('pinduoduoTipLastShowTime', +new Date());
    },
    refreshCandidateInquiry() {
      if(window._pageCode == 'candidateDetail') {
          this.broadcast('candidateDetail', 'refreshCandidateInquiry');
      }
    },
    // 模拟offer播报
    mockOfferBC() {
        let count = 0;
        let t = setInterval(() => {
            const data = {
                "CustomerName":"春天" + count,
                "CustomerBusinessDomains":["M1","测试"],
                "JobCategory":"web前端",
                "HasRedPacket":true
            };
            this.$refs.broadcastNotification.show({
                type: 'offer',
                data: data,
            });
            
            count++;
            if(count>5) {
                clearInterval(t);
            }
        }, 1000);
    },
    mockRedPackageBC() {
        let count = 0;
        let t = setInterval(() => {
            const data = {
                "BroadcastSendingLogId": "0101010-" + count,
            };
            this.$refs.broadcastNotification.show({
                type: 'redPackage',
                data: data,
            });
            
            count++;
            if(count>2) {
                clearInterval(t);
            }
        }, 1000);
    },
    handleFoldCallback(val) {
        this.isFold = val;
    },
  }
};
</script>

<style>
@import "//at.alicdn.com/t/font_1120274_vybrdx4nunm.css";
</style>
<style lang="scss" scope>
.workbench-body {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .workbench-top {
        width: 100%;
        height: 60px;
        // background: orange;
    }
    .main-wrap {
        // flex-grow: 1;
        flex: 1;

        display: flex;
        flex-flow: row nowrap;
        .side-bar {
            // width: 230px;
            // max-width: 230px;
            height: 100%;
            // height: 100vh;
            
            // background: blue;
        }
        // .side-bar.is-fold{

        // }
        .body-wrapped {

            flex: 1;
            // background: gray;
            display: flex;
            height: calc(100vh - 60px);
            max-width: calc(100% - 230px);
            // max-height: 100%;
            flex-flow: column nowrap;
            
            .page-container {
                flex: 1;
                // height: calc(100% -52px);
                // max-height: calc(100% -52px);
                overflow-y: auto;
                // position: relative;
                // background: greenyellow;
            }
        }
        .isFold{
            max-width: calc(100% - 70px);
        }
    }
}
</style>

<style lang="scss">
@import "~#/css/common";
@import "#/css/scss/mixins/work-table-common.scss";
html, body {
  width: 100%;
  height: 100%;
}
.home-container {
  /* height: 100vh; */
  display: flex;
  flex-direction: row;
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.sidebar-container {
  height: 100vh;
}
.main-container-outer {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
  background: #f5f5f5;
  width: 100%;
  min-width: 1100px;
  height: 100%;
}
.home-view{
  height: calc(100% - 44px);
  // 兼容其他浏览器
  // height: calc(100% - 64px);
  // overflow: auto;
  position: relative;
}
.home-view-has-announcement{
    height: calc(100% - 95px);
}
.tab-view{
  /* 新工作台tab视图包裹层添加该类名 */
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.tab-view-has-announcement{
    height: calc(100% - 51px);
}

.pinduoduo-tip {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 12;

  &-img {
    position: relative;
    width: 62.5%;
    margin: 0 auto;

    .close-btn {
      position: absolute;
      top: 10%;
      right: 1.7%;
      width: 24px;
      height: 24px;
      min-width: unset;
      min-height: unset;
      background: url('~@src/assets/images/icon/icon-close-copy.svg') no-repeat center;
      background-size: contain;
      cursor: pointer;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}
</style>
