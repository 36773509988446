import {customerList as customerListUrl} from '@src/js/config/api.json';
export default {
    /**
     * 获取本周订单最多客户列表
     * @returns {*}
     */
    getJobCountTop() {
        return _request({
            method: "POST",
            url: customerListUrl.job_count_top7,
        });
    },
    getRecommendCountTop() {
        return _request({
            method: "POST",
            url: customerListUrl.recommendation_count_top7,
        });
    },
    getCustomerMoreThan20() {
        return _request({
            method: "GET",
            url: customerListUrl.customer_more_than_20,
        });
    },
    getCustomerList(param) {
        return _request({
            method: "POST",
            url: customerListUrl.get_list,
            data: param,
        });
    },
    getMyCompanyCustomers(params) {
        return _request({
            method: "GET",
            url: customerListUrl.get_my_company_customers,
            data: params || null
        });
    },
    // 合并到另一客户
    mergeToCustomer(param) {
        return _request({
            method: "POST",
            url: customerListUrl.merge_to_customer,
            baseURL: "LbdOpenApi",
            data: param,
        });   
    },
    changeToSubDepartment(param) {
        return _request({
            method: "POST",
            url: customerListUrl.change_to_sub_department,
            data: param,
        });   
    },
    // 删除客户
    deleteCustomer(customerId) {
        return _request({
            url: customerListUrl.delete_customer.replace(/%p/g, customerId),
            method: "DELETE",
            baseURL: "LbdOpenApi",
        });
    },
    createCustomer(param) {
        return _request({
            method: "POST",
            url: customerListUrl.create_customer,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param,
        });
    },
    // 创建客户
    newCreateCustomer(param) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: customerListUrl.new_create_customer,
            data: param,
        });
    },
    // 创建客户申请
    addCustomerBusinessApplication(param) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: customerListUrl.add_customer_business_application,
            data: param,
        });
    },
    getFirmMembers(param) {
        return _request({
            method: "GET",
            url: customerListUrl.my_firm_team_members,
            data: param,
        }); 
    }
};
