var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("inquiry-dialog", {
    ref: "inquiryDialog",
    on: { "phone-call-inquiry-added": _vm.refreshCandidateInquiry },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }