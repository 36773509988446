<template>
  <div class="share-message">
    <!-- 职位分享卡片 -->
    <template v-if="message.msgContent.ext.type === 'jobShare'">
        <!-- <div class="job-share-card" @click="goToLink(`/Headhunting/MyCompany.html#/Job/${shareData.jobId}`)"> -->
        <div class="job-share-card" @click="goToLink(`/#/Job/${shareData.jobId}`)">
            <div class="share-card-title" :title="`${shareData.firmShortName}-${shareData.jobName}`">
                {{shareData.firmShortName}}-{{shareData.jobName}}
            </div>
            <div class="share-card-info">
                {{shareData.location}} | {{shareData.yearOfExperience}} | {{shareData.degree}}
            </div>
            <div class="share-card-info">
                {{shareData.salary}} | 佣金 {{shareData.commissionValue}}
            </div>
            <div class="share-card-footer">
                <span class="job-publish-info">
                    <span class="job-publish-avatar">
                        <img :src="jobCreatorAvatar"/>
                    </span>
                    <span class="job-publish-name" :title="shareData.creatorName">{{shareData.creatorName}}</span> 发布
                </span>
                <el-button
                    class="share-btn"
                    @click.stop="handleShare"
                    v-if="message.status === 'unSend'">
                    发给对方
                </el-button>
            </div>
        </div>
    </template>
    <!-- 候选人分享卡片 -->
    <template v-else>
        <!-- <div class="candidate-share-card" @click="goToLink(`/Headhunting/MyCompany.html#/candidateDetail/${shareData.resumeId}`)"> -->
        <div class="candidate-share-card" @click="goToLink(`/#/candidateDetail/${shareData.resumeId}`)">
            <div class="share-card-title">
                {{candidateName}}-{{shareData.title}}
            </div>
            <div class="share-card-info">
                {{shareData.company}}
            </div>
            <div class="share-card-info">
                {{shareData.cityName}} | {{shareData.yearOfExperience}}年 | {{shareData.degree | degreeMap}} | {{shareData.school}}
            </div>
            <div class="share-card-footer">
                <el-button
                    class="share-btn"
                    @click.stop="handleShare"
                    v-if="message.status === 'unSend'">
                    发给对方
                </el-button>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
import emitter from '@src/js/mixins/emitter';
import { avatarHost } from '#/component/page/instant-message/utils/avatarHost.js';
export default {
    props: {
        message: Object,
        currentConversation: Object,
        isInner: Boolean
    },
    mixins: [emitter],
    computed: {
        shareData() {
            return this.message.msgContent.ext.data;
        },
        candidateName() {
            return this.isInner ? this.shareData.candidateName : this.shareData.candidateName[0] + "**"
        },
        userId() {
            return document.querySelector('input[name="userUnionId"]') ? document.querySelector('input[name="userUnionId"]').value : this.$store.state.user.userInfo.unionId;
        },
        jobCreatorAvatar() {
            // 默认头像为空的情况
            if(this.shareData?.creatorAvatar == undefined) {
               return require('@src/assets/images/default-user.png')
            }
            if(this.shareData?.creatorAvatar?.indexOf('http') !== 0) {
                return avatarHost() + this.shareData.creatorAvatar
            } else {
                return this.shareData.creatorAvatar
            }
        },
    },
    filters: {
        degreeMap(val) {
            if(typeof val === 'string') {
                return val
            }
            let map = {
                3: '本科',
                4: '硕士',
                6: '博士',
                2: '大专',
                0: '-'
            }
            return map[val]
        }
    },
    methods: {
        goToLink(url) {
            if(window.lbdTrack){
                lbdTrack.track('IMShareCardClick', {
                    isFromMe: this.message.fromAccountId === this.userId ? true : false,
                    type: this.message.msgContent.ext.type
                })
            } else {
                _tracker.track('IMShareCardClick',JSON.stringify({
                    isFromMe: this.message.fromAccountId === this.userId ? true : false,
                    type: this.message.msgContent.ext.type
                }))
            }
            window.open(url);
        },
        handleShare() {
            this.dispatch('IMBaseApp', 'share-message-send');
        }
    }
}
</script>

<style lang="scss" scope>
.share-message {
    width: 278px;
    min-height: 118px;
    padding: 10px 12px;
    border-radius: 6px;
    border: 1px solid #ddd;
    cursor: pointer;
    .share-card-title {
        color: #4a4a4a;
        line-height: 20px;
        margin-bottom: 6px;
    }
    .share-card-info {
        color: #9b9b9b;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .share-card-footer {
        span {
            white-space: normal;
        }
        .share-btn {
            min-width: unset;
            padding: 0;
            width: 66px;
            height: 24px;
            line-height: 22px;
            background-color: #fff;
            border-color: $primary;
            border-radius: 14px;
            color: $primary;
            font-size: 12px;
            &:hover, &:focus, &:active {
                background-color: $primary;
                color: #fff;
            }
        }
    }
    .job-share-card {
        .share-card-footer {
            display: flex;
            justify-content: space-between;
            .job-publish-info {
                line-height: 24px;
                display: inline-flex;
                color: #666;
                font-size: 12px;
                .job-publish-avatar {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    display: inline-block;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 8px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .job-publish-name {
                    display: inline-block;
                    max-width: 112px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .candidate-share-card {
        .share-card-footer {
            text-align: right;
        }
    }
}
</style>