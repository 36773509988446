<template>
    <div class="conversation-header-bar" @click="handleCloseInstantMessage">
        <div
            class="conversation-info"
            @click.stop
            :style="currentConversation.conversationID ? {} : {'visibility': 'hidden'}">
            <span
                class="chat-name-c2c"
                v-if="currentConversation.type == TIM.TYPES.CONV_C2C"
                :title="currentConversation.userProfile.nick">
                {{conversationName}}
            </span>
            <span class="user-info" v-if="currentConversation.type == TIM.TYPES.CONV_C2C && currentConversation.conversationID !== 'C2C_openIM123456'">
                <span
                    class="user-info-firmname"
                    :title="(userProfileMap[currentConversation.userProfile.userID] && userProfileMap[currentConversation.userProfile.userID].company) || ''">
                    {{(userProfileMap[currentConversation.userProfile.userID] && userProfileMap[currentConversation.userProfile.userID].company) || ''}}
                </span>
                <span
                    class="user-info-title"
                    :title="(userProfileMap[currentConversation.userProfile.userID] && userProfileMap[currentConversation.userProfile.userID].title) || ''">
                     | {{(userProfileMap[currentConversation.userProfile.userID] && userProfileMap[currentConversation.userProfile.userID].title) || ''}}
                </span>
            </span>
            <template v-if="currentConversation.type == TIM.TYPES.CONV_GROUP">
                <span
                    class="chat-name-group"
                    :title="currentConversation.groupProfile.name"
                    >
                    {{currentConversation.groupProfile.name}}
                </span>
                <span class="chat-name-group-number">({{groupInfo.memberCount}})</span>
            </template>
            <span class="chat-name-system" v-if="currentConversation.type == TIM.TYPES.CONV_SYSTEM">系统通知</span>
        </div>
        <div class="instant-message-operate" @click.stop>
            <span class="operate-btn" @click="handleEnLargeDialog" :title="isLargeSize ? '缩小窗口' : '放大窗口'">
                <i class="enlarge-btn" v-show="!isLargeSize"></i>
                <i class="shrink-btn" v-show="isLargeSize"></i>
            </span>
            <span
                class="operate-btn"
                @click.stop="handleShowGroudInfo"
                v-if="currentConversation.type == TIM.TYPES.CONV_GROUP">
                <i class="member-btn" title="查看群组资料"></i>
            </span>
            <span class="operate-btn" @click="handleCloseInstantMessage">
                <i class="close-im-btn" title="关闭窗口"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        conversationName() {
            return this.currentConversation.conversationID === 'C2Cbaza_im_admin' ? '系统通知' : this.currentConversation.userProfile.nick;
        }
    },
    props: {
        currentConversation: Object,
        isLargeSize: Boolean,
        userProfileMap: Object,
        groupInfo: Object,
    },
    methods: {
        handleEnLargeDialog() {
            this.$emit('en-large-dialog');
        },
        handleCloseInstantMessage() {
            this.$emit('close-instant-message');
        },
        handleShowGroudInfo() {
            this.$emit('show-group-info');
        }
    }
}
</script>

<style lang="scss" scoped>
    .conversation-header-bar {
        background: #38BC9D;
        height: 40px;
        min-height: 40px;
        padding: 0 6px 0 12px;
        line-height: 40px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        .conversation-info {
            max-width: 250px;
            .chat-name-c2c, .user-info-firmname, .user-info-title, .chat-name-group, .chat-name-group-number {
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .chat-name-c2c {
                max-width: 94px;
            }
            .user-info {
                font-size: 12px;
                display: inline-block;
                height: 100%;
                margin-left: 6px;
            }
            .user-info-firmname {
                max-width: 60px;
                margin-right: 3px;
            }
            .user-info-title {
                max-width: 70px;
            }
            .chat-name-group {
                max-width: 190px;
            }
        }
        .instant-message-operate {
            display: inline-flex;
            align-items: center;
            .operate-btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                margin-left: 8px;
                background-color: rgba(255, 255, 255, 0.2);
                cursor: pointer;
                &:focus, &:active, &:hover {
                    background-color: rgba(255, 255, 255, 0.5);
                }
            }
            .enlarge-btn {
                width: 14px;
                height: 14px;
                background: url('~@src/assets/images/icon/icon-enlarge.svg') no-repeat;
                background-size: contain;
            }
            .shrink-btn {
                width: 14px;
                height: 14px;
                background: url('~@src/assets/images/icon/icon-shrink.svg') no-repeat;
                background-size: contain;
            }
            .member-btn {
                width: 20px;
                height: 20px;
                background: url('~@src/assets/images/icon/icon-members.svg') no-repeat center;
                background-size: contain;
            }
            .close-im-btn {
                width: 12px;
                height: 12px;
                min-width: unset;
                min-height: unset;
                background: url('~@src/assets/images/icon/icon-close-copy.svg') no-repeat center;
                background-size: contain;
            }
        }
    }
</style>