var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "realtimedata-dialog" }, [
        _c("div", { staticClass: "statistic-bag" }, [
          _c("div", { staticClass: "main-content" }, [
            _c("i", {
              staticClass: "icon-close",
              on: { click: _vm.closeDialog },
            }),
            _vm._m(0),
            _c("div", { staticClass: "dialog-left" }, [
              _c("div", { staticClass: "count-item" }, [
                _c("i", { staticClass: "count-icon count-icon-job" }),
                _vm._v("\n                    更新职位\n                    "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(_vm.listData.recommendationStatistics.jobsCount)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "count-item" }, [
                _c("i", {
                  staticClass: "count-icon count-icon-recommendation",
                }),
                _vm._v("\n                    新增推荐\n                    "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.listData.recommendationStatistics.recommendationsCount
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "count-item" }, [
                _c("i", { staticClass: "count-icon count-icon-comment" }),
                _vm._v("\n                    新增评论\n                    "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(_vm.listData.recommendationStatistics.commentsCount)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "count-item" }, [
                _c("i", { staticClass: "count-icon count-icon-interview" }),
                _vm._v("\n                    面试安排\n                    "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.listData.recommendationStatistics.interviewsCount
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "count-item" }, [
                _c("i", { staticClass: "count-icon count-icon-offer" }),
                _vm._v("\n                    Offer　　\n                    "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.listData.recommendationStatistics.offerThisWeekCount
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "dialog-right" }, [
              _vm.listData.list.length != 0
                ? _c(
                    "ul",
                    { staticClass: "detail-list" },
                    _vm._l(_vm.listData.list, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "detail-item" },
                        [
                          _c("div", { staticClass: "avater-box" }, [
                            _c("img", {
                              staticClass: "avater",
                              attrs: {
                                src: [
                                  item.posterAvatarUrl
                                    ? item.posterAvatarUrl
                                    : _vm.avatar,
                                ],
                                width: "38",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "detail-info" }, [
                            _c("p", [
                              _c("span", { staticClass: "m-r-5" }, [
                                _vm._v(_vm._s(item.posterRealName)),
                              ]),
                              _c("span", { staticClass: "m-r-5" }, [
                                _vm._v("@" + _vm._s(item.posterNickName)),
                              ]),
                              _c("span", { staticClass: "m-r-5" }, [
                                _vm._v(_vm._s(item.posterFirmShortName)),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "20px" } },
                                [_vm._v(_vm._s(item.posterTitle))]
                              ),
                              _c("span", { staticClass: "created-time" }, [
                                _vm._v(_vm._s(item.created)),
                              ]),
                            ]),
                            item.type == 1
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                推荐了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 2 || item.type == 4
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                接受了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 3
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                拒绝了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(_vm._s(item.rejectedReason)),
                                  ]),
                                ])
                              : _vm._e(),
                            item.type == 5
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "推进到面试阶段\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 6
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                为候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "安排了面试\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 7
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                为候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "取消了面试\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 8
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                反馈了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v("的面试结果 ("),
                                  _c("span", [_vm._v(_vm._s(item.result))]),
                                  _vm._v(")"),
                                  _c("br"),
                                  _c("span", [_vm._v(_vm._s(item.evaluation))]),
                                ])
                              : _vm._e(),
                            item.type == 9
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "推进到Offer阶段\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 10
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "推进到入职阶段\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 11
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                淘汰了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 12
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                暂停了"
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.jobName) + " "),
                                  ]),
                                  _vm._v("职位"),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.reason) +
                                        " | " +
                                        _vm._s(item.feedToBreak)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            item.type == 13
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                完成了"
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.jobName) + " "),
                                  ]),
                                  _vm._v("职位"),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.reason) +
                                        " | " +
                                        _vm._s(item.feedToBreak)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            item.type == 14
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                重启了"
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.jobName) + " "),
                                  ]),
                                  _vm._v("职位\n                            "),
                                ])
                              : _vm._e(),
                            item.type == 15
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                发布了"
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.jobName) + " "),
                                  ]),
                                  _vm._v("职位\n                            "),
                                ])
                              : _vm._e(),
                            item.type == 16
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将"
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.jobName) + " "),
                                  ]),
                                  _vm._v("职位移交给"),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.to))]
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 17
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                  _vm._v(
                                    "设置为离职状态\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 18
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                筛选通过了候选人"
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "candidate-name" },
                                    [_vm._v(_vm._s(item.candidateName))]
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 22
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将职位["
                                  ),
                                  _c("span", [_vm._v(_vm._s(item.jobName))]),
                                  _vm._v(
                                    "]的状态修改为不紧急\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 23
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                将职位"
                                  ),
                                  _c("span", [_vm._v(_vm._s(item.jobName))]),
                                  _vm._v(
                                    "的状态修改为紧急\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 1024
                              ? _c("div", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.content),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.listData.list.length == 0
                ? _c("p", [_vm._v("暂无数据")])
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dialog-nav" }, [
      _c("img", {
        attrs: {
          src: "//hstatic.hirede.com/lbd/images/activity/2016010801.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }