const state = {
    candidateListUrl: '/candidates/firmCandidate'
}

const actions = {
    setCandidateListUrl({ commit, state }, candidateListUrl) {
        commit('updateCandidateListUrl', { candidateListUrl });
    }
}

const mutations = {
    updateCandidateListUrl(state, { candidateListUrl }) {
        state.candidateListUrl = candidateListUrl;
    }
}

export default {
    state,
    actions,
    mutations
}
