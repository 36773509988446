<template>
    <div class="group-profile" v-loading="loading">
        <div class="group-profile-item group-name">
            <div class="group-name-title">
                群名
            </div>
            <span class="group-name-show" v-if="!isUpdateGroupName">
                {{group.name}}
                <i class="el-icon-edit" @click="handleGroupName" v-if="!isCompany && isGroupOwner"></i>
            </span>
            <el-input
                v-model="groupNameInput"
                v-else-if="!isCompany"
                @input="inputGroupName"
                @blur="updateGroupName"
                @keydown.enter.native="updateGroupName">
            </el-input>
        </div>
        <div class="group-profile-item group-member-list-wrapper">
            <div class="group-member-list">
                <span class="group-member-item add-member-btn" v-if="!isCompany">
                    <i class="el-icon-circle-plus-outline btn-icon" @click="addGroupMember"></i>
                    添加
                </span>
                <span class="group-member-item remove-member-btn" v-if="!isCompany && isGroupOwner && !isRemoveGroupMember">
                    <i class="el-icon-circle-close btn-icon" @click="isRemoveGroupMember = true"></i>
                    删除
                </span>
                <span class="group-member-item finish-member-btn" v-if="!isCompany && isGroupOwner && isRemoveGroupMember">
                    <i class="el-icon-circle-check btn-icon" @click="isRemoveGroupMember = false"></i>
                    完成
                </span>
                <span
                    class="group-member-item"
                    v-for="(member, index) in groupMemberList"
                    :key="index">
                    <span class="member-avatar-wrapper" :style="member.userID === userId ? 'cursor: default;' : ''">
                        <img :src="member.avatar" @click="chatMember(member.userID)"/>
                    </span>
                    <span class="member-name" :title="member.nick">
                        {{member.nick}}
                    </span>
                    <i
                        class="el-icon-error"
                        @click="removeMember(member)"
                        v-if="member.userID !== userId && isRemoveGroupMember">
                    </i>
                </span>
            </div>
            <span class="group-member-more" v-if="hasMore" @click="loadMore = !loadMore">
                {{loadMore ? '收起' : '查看全部'}}
            </span>
        </div>
        <div class="group-profile-item message-notified-switch">
            <span>消息免提醒</span>
            <el-switch
                v-model="groupMessageNotified"
                active-color="#38bc9d"
                inactive-color="#d8d8d8"
                @change="handleMessageRemindType">
            </el-switch>
        </div>
        <div class="group-profile-item quit-group-btn" @click="beforeQuitGroup" v-if="!isCompany">
            <span>退出群聊</span>
        </div>
    </div>
</template>

<script>
import emitter from '#/js/mixins/emitter.js';
import imService from '#/js/service/openIMService.js';
export default {
    props: {
        group: Object,
        currentConversation: Object,
        userId: String,
        memberList: Array,
        isCompany: Boolean
    },
    mixins: [emitter],
    computed: {
        isGroupOwner() {
            return this.memberList.some(item => {
                return item.userID === this.userId && item.role === 'Owner';
            });
        },
        groupMemberList() {
            let hasMoreNumber = this.isCompany ? 12 : (this.isGroupOwner ? 10 : 11),
                list = [];
            let groupOwner = this.memberList.find(item => {
                return item.role === 'Owner';
            })
            list = this.memberList.sort((item1, item2) => {
                if(groupOwner && groupOwner.userID) {
                    return (item2.userID === groupOwner.userID) - (item1.userID === groupOwner.userID)
                } else {
                    return item1 - item2
                }
            });
            if(list.length > hasMoreNumber) {
                this.hasMore = true;
                if(this.loadMore) {
                    return [].concat(list);
                } else {
                   return this.isGroupOwner ? list.slice(0, 10) : list.slice(0, 11);
                }
            } else {
                this.hasMore = false;
                return [].concat(list);
            }
        }
    },
    data() {
        return {
            loading: false,
            isUpdateGroupName: false,
            groupNameInput: "",
            isRemoveGroupMember: false,
            loadMore: false,
            hasMore: false,
            groupMessageNotified: false
        }
    },
    methods: {
        show() {
            this.groupMessageNotified = this.group.selfInfo.messageRemindType === TIM.TYPES.MSG_REMIND_ACPT_NOT_NOTE;
            this.loadMore = false;
            this.isUpdateGroupName = false;
            this.isRemoveGroupMember = false;
        },
        handleGroupName() {
            this.groupNameInput = this.group.name;
            this.isUpdateGroupName = true;
        },
        updateGroupName() {
            if(!this.groupNameInput) {
                shortTips('群名不能为空！');
                return
            }
            this.loading = true;
            openIM.setGroupInfo({
                groupID: this.group.groupID,
                groupInfo: {
                    groupName: this.groupNameInput,
                    introduction: "",
                    notification: "",
                    faceURL: "",
                    ex: ""
                }
            }).then(({ data })=>{
                shortTips('修改成功');
                this.dispatch('IMBaseApp', 'update-group-info', {
                    name: this.groupNameInput,
                });
                this.isUpdateGroupName = false;
            }).catch(err=>{
                console.log('updateGroupProfileError：', err);
            }).finally(() => {
                this.loading = false;
            })
            // tim.updateGroupProfile(params)
            // .then(({ data }) => {
            //     shortTips('修改成功');
            //     this.dispatch('IMBaseApp', 'update-group-info', data.group);
            //     this.isUpdateGroupName = false;
            // }).catch(err => {
            //     console.log('updateGroupProfileError：', err);
            // }).finally(() => {
            //     this.loading = false;
            // })
        },
        addGroupMember() {
            let memberList = this.groupMemberList;
            memberList.forEach(item => {
                item.unionId = item.userID;
            })
            this.dispatch('IMBaseApp', 'add-group-member', {
                groupID: this.group.groupID,
                name: this.group.name,
                memberList: memberList
            });
        },
        refreshSignature() {
            return new Promise((resolve, reject) => {
                if(localStorage.getItem('imUserSig')) {
                    resolve(true);
                } else {
                    imService.getUserSig().then(res =>{
                        this.userSig = res.token;
                        localStorage.setItem('imUserSig', res.token);
                        resolve(true);
                    }).catch(err => {
                        resolve(false);
                    })
                }

            })
        },
        async removeMember(member) {
            let checkSig = await this.refreshSignature();
            if (!checkSig) {
                shortTips("未知异常，请刷新页面重试！");
                return false;
            }
            this.$confirm(`确定删除${member.nick}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
            }).then(() => {
                this.loading = true;
                //检查token为null则先获取token再发起请求

                imService.kickGroupMember({
                    groupID: this.group.groupID,
                    kickedUserIDList: [member.userID],
                    token: localStorage.getItem('imUserSig'),
                    reason: ''
                }).then(res => {
                    shortTips('删除成功');
                    this.$emit('deleteMember', member.nick);
                    this.dispatch('IMBaseApp', 'updateMemberList')
                }).catch(err => {
                    console.log('changeGroupOwnerError：', err);
                }).finally(() => {
                    this.loading = false;
                })
                // tim.deleteGroupMember({
                //     groupID: this.group.groupID,
                //     userIDList: [member.userID]
                // }).then(res => {
                //     shortTips('删除成功');
                //     this.$emit('deleteMember', member.nick);
                //     this.dispatch('IMBaseApp', 'updateMemberList')
                // }).catch(err => {
                //     console.log('changeGroupOwnerError：', err);
                // }).finally(() => {
                //     this.loading = false;
                // })
            }).catch(() => {});
        },
        handleMessageRemindType(value) {
            // tim.setMessageRemindType({
            //     groupID: this.group.groupID,
            //     messageRemindType: value ? TIM.TYPES.MSG_REMIND_ACPT_NOT_NOTE : TIM.TYPES.MSG_REMIND_ACPT_AND_NOTE
            // }).then(({ data }) => {
            //     this.dispatch('IMBaseApp', 'update-group-info', data.group);
            // })
            console.log(this.currentConversation.openIMConversationID);
            openIM.setConversationRecvMessageOpt({
                conversationIDList: [this.currentConversation.openIMConversationID],
                opt: value ? 2 : 0
            }).then(({ data }) => {
                // this.dispatch('IMBaseApp', 'update-group-info', {
                //     name: this.group.name,
                //     selfInfo: this.group.selfInfo
                // });
                console.log(data);
            }).catch(err=>{
                console.log(err);
            })
        },
        beforeQuitGroup() {
            if(this.groupMemberList.length === 1) {
                shortTips('当前群组只有一人，无法进行退群操作!');
                return false;
            }
            this.$confirm(`确定退出群聊${this.group.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
            }).then(() => {
                this.loading = true;
                if(this.isGroupOwner) {
                    let newOwner = this.groupMemberList.find(item => item.userID !== this.userId);
                    openIM.transferGroupOwner({
                        groupID: this.group.groupID,
                        newOwnerUserID: newOwner.userID
                    }).then(({ data })=>{
                        this.handleQuitGroup();
                    }).catch(err=>{
                        shortTips('系统异常，请稍后重试');
                        console.log('changeGroupOwnerError：', err);
                    })
                    // tim.changeGroupOwner({
                    //     groupID: this.group.groupID,
                    //     newOwnerID: newOwner.userID
                    // }).then(res => {
                    //     this.handleQuitGroup();
                    // }).catch(err => {
                    //     shortTips('系统异常，请稍后重试');
                    //     console.log('changeGroupOwnerError：', err);
                    // })
                } else {
                    this.handleQuitGroup();
                }
            }).catch(() => {});
        },
        handleQuitGroup() {
            // openIM.deleteConversationFromLocalAndSvr(`GROUP_${this.group.groupID}`).then(({ data })=>{
            //     shortTips('已退出群聊');
            //     console.log('删除会话成功');
            // }).catch(err=>{
            //     shortTips('系统异常，请稍后重试');
            //     console.log('deleteConversationError：', err);
            // }).finally(() => {
            //     this.loading = false;
            // })
            openIM.quitGroup(this.group.groupID).then(({ data })=>{
                shortTips('已退出群聊');
                // this.$emit('quitGroup');

                openIM.deleteConversationFromLocalAndSvr(`group_${this.group.groupID}`).then(({ data })=>{
                    console.log('删除会话成功');
                    this.$emit('quitGroup');
                }).catch(err=>{
                    console.log('deleteConversationError：', err);
                })
            }).catch(err=>{
                shortTips('系统异常，请稍后重试');
                console.log('quitGroupError：', err);
            }).finally(() => {
                this.loading = false;
            })
            // tim.quitGroup(this.group.groupID)
            // .then(res => {
            //     shortTips('已退出群聊');
            //     this.$emit('quitGroup');
            // }).catch(err => {
            //     shortTips('系统异常，请稍后重试');
            //     console.log('quitGroupError：', err);
            // }).finally(() => {
            //     this.loading = false;
            // })
        },
        inputGroupName(value) {
            let enc = new TextEncoder("utf-8");
            for(let i = 0; i < 30; i++) {
                if(enc.encode(value).length > 30) {
                    value = value.substring(0, value.length-1);
                } else {
                    this.groupNameInput = value;
                    break;
                }
            }
        },
        chatMember(userId) {
            if(userId !== this.userId) {
                this.dispatch('IMBaseApp', 'select-conversation-from-system-notice', `C2C_${userId}`)
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .group-profile {
        width: 100%;
        padding-top: 20px;
        animation: fade-in 2s linear;
        .group-profile-item {
            border-bottom: 1px solid #d8d8d8;
            padding: 0 14px;
            &:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
        .group-name {
            line-height: 20px;
            padding-bottom: 14px;
            .group-name-title {
                margin-bottom: 5px;
                color: #999;
            }
            .group-name-show {
                color: #333;
                font-weight: bold;
                .el-icon-edit {
                    margin-left: 10px;
                    color: #999;
                    cursor: pointer;
                    &:hover {
                        color: $primary;
                    }
                }
            }
            .el-input .el-input__inner {
                height: 20px;
                line-height: 20px;
                padding-left: 5px;
            }
        }
        .group-member-list-wrapper {
            padding: 8px 8px 0;
            min-height: calc(100% - 168px);
            .group-member-list {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 14px;
            }
            .group-member-item {
                margin-top: 12px;
                margin-right: 18px;
                width: 48px;
                height: 58px;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                color: #666;
                font-size: 12px;
                line-height: 18px;
                position: relative;
                &.finish-member-btn {
                    color: $primary;
                    .btn-icon {
                        color: $primary;
                    }
                }
                &:nth-of-type(4n) {
                    margin-right: 0 !important;
                }
            }
            .add-member-btn,
            .remove-member-btn,
            .finish-member-btn {
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
            }
            .btn-icon{
                width: 32px;
                height: 32px;
                margin-bottom: 8px;
                font-size: 32px;
                color: #999;
                &:hover {
                    color: $primary;
                }
            }
            .member-avatar-wrapper {
                width: 32px;
                height: 32px;
                margin-bottom: 8px;
                display: inline-block;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid $primary;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .el-icon-error {
                width: 10px;
                height: 10px;
                font-size: 10px;
                position: absolute;
                top: -2px;
                right: -2px;
                color: #bbb;
                cursor: pointer;
                &:hover {
                    color: #666;
                }
            }
            .member-name {
                display: inline-block;
                max-width: 48px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .group-member-more {
                width: 100%;
                padding-bottom: 14px;
                display: inline-block;
                margin-top: 12px;
                line-height: 18px;
                text-align: center;
                color: #999;
                cursor: pointer;
            }
        }
        .message-notified-switch {
            height: 54px;
            line-height: 53px;
            color: #333;
            display: flex;
            justify-content: space-between;
            .el-switch {
                height: 100%;
                line-height: inherit;
            }
        }
        .quit-group-btn {
            height: 54px;
            line-height: 54px;
            text-align: center;
            color: #C91D1D;
            cursor: pointer;
        }
    }
</style>