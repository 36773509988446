var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workbench-body" },
    [
      _c(
        "div",
        { staticClass: "workbench-top" },
        [_c("topbar", { ref: "topbar", attrs: { showBtn: !_vm.showTab } })],
        1
      ),
      _c("div", { staticClass: "main-wrap" }, [
        _c(
          "div",
          { staticClass: "side-bar" },
          [_c("sidebar", { on: { "fold-callback": _vm.handleFoldCallback } })],
          1
        ),
        _c(
          "div",
          { staticClass: "body-wrapped", class: { isFold: _vm.isFold } },
          [
            _vm.showTab ? _c("work-table-tab") : _vm._e(),
            _c(
              "div",
              { staticClass: "page-container" },
              [
                _c(
                  "keep-alive",
                  { attrs: { include: _vm.keepAliveComponents } },
                  [_vm.userInfoFetched ? _c("router-view") : _vm._e()],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("global-inquiry-dialog", {
        ref: "globalInquiryDialog",
        on: { "refresh-candidate-inquiry": _vm.refreshCandidateInquiry },
      }),
      _c("broadcast-notification", { ref: "broadcastNotification" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }