var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        title: "分享到",
        width: "602px",
        "custom-class": "share-to-im-dialog",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        "destroy-on-close": true,
      },
      on: { close: _vm.hide },
    },
    [
      _c("div", { staticClass: "share-to-im-container" }, [
        _c(
          "div",
          { staticClass: "member-list-container" },
          [
            _c("el-input", {
              staticClass: "search-input",
              attrs: { placeholder: "搜索", "prefix-icon": "el-icon-search" },
              on: { change: _vm.handleSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "keyword",
              },
            }),
            !_vm.isSearchMode
              ? _c("p", { staticClass: "member-list-container-tip" }, [
                  _vm._v("最近联系人"),
                ])
              : _vm._e(),
            !_vm.isSearchMode
              ? _c(
                  "ul",
                  { staticClass: "member-list-container-ul" },
                  _vm._l(_vm.memberList, function (member) {
                    return _c(
                      "li",
                      { key: member.unionId || member.groupId },
                      [
                        _c("member-item", {
                          attrs: {
                            member: member,
                            isShare: true,
                            isGroup: !!member.groupId,
                            type: "conversationList",
                            value: _vm.checkMemberSelected(member),
                          },
                          on: { "member-select": _vm.handleMemberSelect },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isSearchMode
              ? _c(
                  "div",
                  { staticClass: "search-result-container" },
                  [
                    _c("search-result", {
                      attrs: {
                        isShare: true,
                        searchResultObject: _vm.searchResultObject,
                        isSearchEnd: _vm.isSearchEnd,
                        shareData: _vm.shareData,
                        selectedMemberList: _vm.selectedMemberList,
                        innerLabelMap: _vm.innerLabelMap,
                      },
                      on: { "member-select": _vm.handleMemberSelect },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "share-operate-container" },
          [
            _c("selected-member-list", {
              attrs: {
                isShare: true,
                height: 390,
                selectedMemberList: _vm.selectedMemberList,
              },
              on: { removeSelected: _vm.removeSelected },
            }),
            _c(
              "div",
              { staticClass: "share-btn-list" },
              [
                _c(
                  "el-button",
                  { staticClass: "cancel-btn", on: { click: _vm.hide } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "share-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleShare },
                  },
                  [_vm._v("分享")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }