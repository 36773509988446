const state = {
    noticeCount: 0,
    noticeVisible: false
}

const getters = {
    noticeCount(state){
        return state.noticeCount;
    },
    noticeVisible(state){
        return state.noticeVisible;
    }
}

const actions = {
    invokeSetNoticeCount({ commit, state }, count) {
        commit('setNoticeCount', count);
    },
    invokeSetNoticeVisible({ commit, state }, bool) {
        commit('setNoticeVisible', bool);
    }
}

const mutations = {
    setNoticeCount (state, count) {
        state.noticeCount = count;
    },
    setNoticeVisible (state, bool){
        state.noticeVisible = bool;
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
