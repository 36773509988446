var shortTipCache = {};
export default function shortTips(text) {
    if (shortTipCache.fn) {
        clearTimeout(shortTipCache.ticker);
        shortTipCache.fn();
        shortTipCache.fn = null;
    }
    var $wrap = $('<div class="shortTips"></div>');
    $wrap.on('click', function (e) {
        $wrap.remove();
    });
    var remove = function () {
        $wrap.fadeOut("slow", function () {
            $wrap.remove();
            shortTipCache.fn = null;
        });
    };
    shortTipCache.fn = function () {
        $wrap.remove();
    }
    $('<span class="bg img-rounded"></span>').append($.trim(text)).appendTo($wrap);
    $("body").append($wrap);
    shortTipCache.ticker = setTimeout(remove, 1500);
}
