import { 
    customer as customerUrl,
    firm as firmUrl, 
} from '#/js/config/api.json'

const URL = {
    gradeCustomers: '/MyCompany/Customer/GradeCustomers',
    getCustomerGrade: '/MyCompany/Customer/%p/Grades',
    gradeCreateOrUpdate: '/MyCompany/Customer/%p/Grade/CreateOrUpdate',
    deleteGrade: '/MyCompany/Customer/Grade/%p/Delete',
};

export default {
    /**
     * 获取所有客户
     * yApi: http://192.168.1.72:3000/project/20/interface/api/711
     */
    gradeCustomers () {
        return _request({
            method: 'GET',
            url: URL.gradeCustomers,
        })
    },
    /**
     * 获得客户所有的职级信息
     * @param {String} customerId 客户ID
     * @param {number} start 分页起始条目
     * @param {number} take 每页条目数
     * @param {any} scope 作用域
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getCustomerGrade (data = {customerId,start,take} = {},scope){
        return _request({
            method: 'GET',
            url: URL.getCustomerGrade.replace('%p',data.customerId),
            data: {
                start: data.start || 0,
                take: data.take || 10
            }
        })
    },
    gradeCreateOrUpdate (data = { id, cutomerId, name, minsalary, maxsalary, discription } = {}, token) {
        return _request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            url: URL.gradeCreateOrUpdate.replace('%p',data.customerId),
            data: data,
        })
    },
    /**
     * 删除职级
     * @param {String} id 职级id
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    deleteGrade (id){
        return _request({
            url: URL.deleteGrade.replace('%p',id),
            method: 'GET'
        })
    },
    getCustomerDetail(params) {
        return _request({
            url: customerUrl.customer_detail.replace(/%p/g, params.customerId),
            method: "GET",
            baseURL: "LbdOpenApi",
        })
    },
    updateCustomerInfo(params) {
        // return _request({
        //     url: customerUrl.customer_update_info,
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        //     data: params,
        //     throwBusinessError: true
        // })
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.customer_update_info,
            data: params,
            throwBusinessError: true,
        });
    },
    getCustomerContacts(params) {
        return _request({
            url: customerUrl.get_customer_contacts.replace(/%p/g, params.id),
            method: "POST",
            baseURL: "LbdOpenApi",
            data: {
                start: params.start,
                take: params.take,
                customerId: params.id
            }
        })
    },
    getCustomerBDs(params) {
        return _request({
            url: customerUrl.get_customer_BDs.replace(/%p/g, params.id),
            method: "GET",
            data: {
                start: params.start,
                take: params.take,
                _: new Date().getTime()
            }
        })
    },
    // 创建客户联系人
    createCustomerContact(params) {
        return _request({
            url: customerUrl.create_customer_contact,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    // 编辑客户联系人
    updateCustomerContact(params) {
        return _request({
            url: customerUrl.update_customer_contact,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    // 添加客户BD
    createCustomerBD(params) {
        return _request({
            url: customerUrl.create_customer_BD,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    // 编辑客户BD
    updateCustomerBD(params) {
        return _request({
            url: customerUrl.update_customer_BD,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    // 删除客户BD
    deleteCustomerBD(customerId) {
        return _request({
            url: customerUrl.delete_customer_BD.replace(/%p/g, customerId),
            method: "DELETE",
            baseURL: "LbdOpenApi",
        })
    },
    getCustomerStatisticsInfo(params) {
        return _request({
            url: customerUrl.get_customer_statistics_info.replace(/%p/g, params.customerId),
            method: "GET",
            data: {
                _: new Date().getTime()
            }
        })
    },
    getSubCustomerList(params) {
        return _request({
            url: customerUrl.get_sub_customers,
            method: "GET",
            data: {
                customerId: params.customerId,
                _: new Date().getTime()
            }
        })
    },
    getCustomerJobList(params) {
        let customerId = params.customerId;
        delete params.customerId;
        params._ = new Date().getTime();
        return _request({
            url: customerUrl.get_customers_job_list.replace(/%p/g, customerId),
            method: "GET",
            data: params
        })
    },
    getCustomerGradeList(params) {
        let customerId = params.customerId;
        delete params.customerId;
        params._ = new Date().getTime();
        return _request({
            url: URL.getCustomerGrade.replace(/%p/g, customerId),
            method: "GET",
            data: params
        })
    },
    getOpenMarketList(params) {
        return _request({
            url: customerUrl.get_open_market_position,
            method: 'GET',
            data: params
        })
    },
    getDepStatisticsInfo(params) {
        return _request({
            url: customerUrl.department_statistics_info.replace(/%p/, params.customerId).replace(/%p/, params.type),
            method: "GET"
        })
    },
    getHaveAuditPermissionsCustomerList(throwHttpError = false) {
        return _request({
            url: customerUrl.get_have_audit_permissions_customer_list,
            method: "GET",
            baseURL: "LbdOpenApi",
            throwHttpError: throwHttpError,
        })
    },

    // 获取客户创建审批列表
    getCustomerAuditList() {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.get_my_audit_applications,
        });
    },
    getMyCustomerApply() {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.get_my_applications,
        });
    },
    // 审核接口
    customerApplyAudit(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.customer_apply_audit,
            data: params
        });
    },
    // 领域列表
    customerDomainList() { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.customer_domain_list,
        });
    },
    
    // 申请条数
    customerApplicationCount() { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.customer_application_count,
        });
    },


    // 合同相关接口
    // 添加附件
    addContractAttachment(params) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.add_contract_attachment,
            data: params,
        });
    },
    // 创建合同
    contractCreate(params) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.contract_create,
            data: params,
        });
    },
    // 编辑合同
    editContractInfo(params) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.edit_contract_info,
            data: params,
        });
    },

    // 创建支付
    createPayment(params) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.create_payment,
            data: params,
        });
    },
    
    // 编辑支付
    editPayment(params) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.edit_payment,
            data: params,
        });
    },
    // 更新客户默认付款合同

    // 获取客户合同支付列表
    contractPaymentList(customerContractId) { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.contract_payment_list.replace('%p', customerContractId),
        });
    },

    // 获取客户合同列表
    contractList(customerId) { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.contract_list.replace('%p', customerId),
        });
    },

    // 获取客户合同详情
    contractDetail(contractId) { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.get_contract_detail.replace('%p', contractId),
        });
    },
    
    // 操作日志
    operationLogList(contractId) { 
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: customerUrl.operation_log_list.replace('%p', contractId),
        });
    },

    // 更新客户默认合同
    changeCustomerDefaultPayment(contractId, params = {}) { 
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: customerUrl.change_customer_default_payment.replace('%p', contractId),
            data: params,
        });
    },

    // 获取客户的职位
    getCustomerJobLevel(customerId) {
        return _request({
            url: firmUrl.url_customer_job_level.replace(/\%p/ig, customerId),
            method: 'GET',
            data: {
                start: 0,
                take: 100,
            },
        });
    },
    // 根据推荐id获取客户默认合同
    getDefaultContractInfo(recommendId) {
        return _request({
            url: customerUrl.customer_default_contract.replace(/\%p/ig, recommendId),
            method: 'GET',
            baseURL: "LbdOpenApi",
        });
    },

}
