<template>
    <div class="bn-container">
        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="offerVisible"
            custom-class="offer-bn"
            width="440px"
        >
            <div class="offer-bn-container">
                <div class="title">Offer播报</div>
                <div class="close-area">
                    <span class="seconds">{{offerBroadcastCountDown}}秒后</span>
                    <span
                        @click="offerClose(true)"
                        class="close"
                    >关闭</span>
                </div>
                <div class="item-wrap">
                    <template v-if="offerBroadcast.length <=3">
                        <div
                            :key="'offer_item_' + index"
                            class="offer-it"
                            v-for="(it ,index) in offerBroadcast"
                        >
                            <i class="el-icon-star-on"></i>
                            <!-- ${it.JobCategory}  -->
                            <span
                                :title="`${it.CustomerName} ${(it.CustomerBusinessDomains|| []).join('-')} 成交1个offer`"
                            >{{it.CustomerName}} {{(it.CustomerBusinessDomains || []).join('-')}} {{it.JobCategory}} 成交1个offer</span>
                            <i class="el-icon-star-on"></i>
                        </div>
                    </template>
                    <template v-else>
                        <marquee
                            behavior="scroll"
                            direction="up"
                            height="160"
                        >
                            <div
                                :key="'offer_item_' + index"
                                class="offer-it"
                                v-for="(it ,index) in offerBroadcast"
                            >
                                <i class="el-icon-star-on"></i>
                                <!-- <span>{阿里巴巴} M1-A类 产品 成交1个offer</span> {{it.JobCategory}} -->
                                <span>{{it.CustomerName}} {{(it.CustomerBusinessDomains||[]).join('-')}} 成交1个offer</span>
                                <i class="el-icon-star-on"></i>
                            </div>
                        </marquee>
                    </template>
                </div>
                <div
                    class="has-red-package"
                    v-if="hasRedPackage"
                >
                    <img
                        alt
                        src="~@src/assets/images/broadcast-notification/offer-botton-bg.png"
                    />
                </div>
            </div>
        </el-dialog>

        <!-- 红包 -->
        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="redPackageVisible"
            custom-class="rp-bn"
            width="337px"
        >
            <div class="rp-bn-container">
                <div class="close-area">
                    <span class="seconds">{{redPackageBroadcastCountDown}}秒后</span>
                    <span
                        @click="redPackageClose(true)"
                        class="close"
                    >关闭</span>
                </div>
                <!-- 红包封面 -->
                <div
                    :class="{'open': openLoading}"
                    class="face-page"
                    v-show="!isGrabed"
                >
                    <div class="fp-text">
                        <!-- 阿里巴巴产品offer {{(currentRedPackage.CustomerBusinessDomains || []).join('-')}} {{currentRedPackage.JobCategory}}-->
                        {{currentRedPackage.CustomerName}} 
                        <br />福利降落
                    </div>
                    <div
                        @click="openRedPackage"
                        class="open-btn"
                    >
                        <img
                            alt="红包按钮"
                            src="~@src/assets/images/broadcast-notification/rp-open-btn@2x.png"
                        />
                    </div>
                </div>

                <!-- 抢到红包 -->
                <div
                    class="get-page"
                    v-show="isReceived && isGrabed"
                >
                    <p class="tit">恭喜你抢到红包</p>
                    <p
                        class="amount"
                        v-text="(amount).toFixed(2)"
                    >99.00</p>
                    <p class="tips">
                        稍后红包领取二维码将通过系统消息发送给你
                        <br />请及时扫码领取
                    </p>
                </div>

                <!-- 未抢到红包 -->
                <div
                    class="fail-page"
                    v-show="!isReceived && isGrabed"
                >
                    <p class="tit">慢了一步，什么也没有抢到</p>
                    <img
                        alt="红包失败"
                        src="~@src/assets/images/broadcast-notification/fail-icon@2x.png"
                    />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import broadcastService from '#/js/service/broadcastService.js';

export default {
    name: 'broadcast-notification',
    components: {
    },
    data () {
        return {
            offerVisible: false,
            offerBroadcastCountDown: 0,
            offerBroadcast: [],
            offerTimer: null,

            redPackageVisible: false,
            redPackageBroadcastCountDown: 0,
            redPackageBroadcast: [],
            redPackageTimer: null,
            isGrabed: false, // 当前红包是否被抢
            isReceived: false,
            amount: 0.00,
            currentRedPackage: {
            }, // 当前红包
            openLoading: false,
        };
    },
    computed: {
        hasRedPackage () {
            return this.offerBroadcast.some(it => it.HasRedPacket);
        },
    },
    created () { },
    mounted () {
        window.addEventListener('storage', event => {
            if (event.key == 'offerid' && event.newValue == this.offerBroadcast[0]?.SceneObjectId) {
                this.offerClose();
            }
            //  && event.newValue == this.redPackageBroadcast[0]?.SceneObjectId
            console.log(`event.key:`, event.key);
            if (event.key == 'redid' || event.key == 'red') {
                console.log(`kk--------------------------1`);
                this.redPackageClose();
            }
        });
    },
    methods: {
        show ({ type, data }) {
            console.log(type, data);

            const _data = JSON.parse(data);
            if (type == 'offer') {
                if (this.offerBroadcastCountDown > 0) {
                    this.offerBroadcastCountDown = 21;
                    const _idx = this.offerBroadcast.findIndex(it => it.SceneObjectId == _data.SceneObjectId);
                    if (_idx < 0) {
                        this.offerBroadcast.push(_data);
                    }
                } else {
                    this.offerBroadcastCountDown = 20;
                    this.offerBroadcast = [_data];
                    this.countDownOffer();
                }
            } else {
                console.log(`red package`);
                if (this.redPackageBroadcastCountDown > 0) {
                    // this.redPackageBroadcastCountDown = 20;
                    const _idx = this.redPackageBroadcast.findIndex(it => it.SceneObjectId == _data.SceneObjectId);
                    if (_idx < 0) {
                        this.redPackageBroadcast.push(_data);
                    }
                } else {
                    this.redPackageBroadcastCountDown = 20;
                    this.redPackageBroadcast = [_data];
                    this.countDownRedPackage(_data);
                }
                this.currentRedPackage = this.redPackageBroadcast[0];
            }
        },

        countDownOffer () {
            this.offerTimer = setInterval(() => {
                this.offerBroadcastCountDown--;
                this.offerVisible = true;
                if (this.offerBroadcastCountDown < 1) {
                    this.offerClose(true);
                }
            }, 1000);
        },
        offerClose (isRecord) {
            console.log(this.offerBroadcast[0].SceneObjectId);
            console.log(`save--------------offerid`);
            if (isRecord) {
                localStorage.removeItem(`offerid`);
                localStorage.setItem(`offerid`, this.offerBroadcast[0]?.SceneObjectId);
            }

            this.offerBroadcastCountDown = 0;
            this.offerVisible = false;
            this.offerBroadcast = [];
            clearInterval(this.offerTimer);
        },

        countDownRedPackage (data) {
            this.redPackageTimer = setInterval(() => {
                this.redPackageBroadcastCountDown--;
                this.redPackageVisible = true;
                if (this.redPackageBroadcastCountDown < 1) {
                    localStorage.removeItem(`redid`);
                    localStorage.setItem(`redid`, this.redPackageBroadcast[0]?.SceneObjectId);
                    this.redPackageBroadcast = this.redPackageBroadcast.filter(it => it.BroadcastSendingLogId != data.BroadcastSendingLogId);
                    this.redPackageVisible = false;
                    clearInterval(this.redPackageTimer);

                    // 红包初始化数据还原
                    this.isGrabed = false; // 当前红包是否被抢
                    this.isReceived = false;
                    this.amount = 0.00;
                    console.log(`a--------1`, this.redPackageBroadcast);
                    if (this.redPackageBroadcast.length > 0 && (!!this.redPackageBroadcast[0].CustomerName)) {
                        console.log(`a--------2`);
                        this.redPackageBroadcastCountDown = 20;
                        this.countDownRedPackage(this.redPackageBroadcast[0]);
                        this.isGrabed = false;
                    }
                }
            }, 1000);
        },
        redPackageClose (isRecord) {
            if (isRecord) {
                console.log(`hand close:`, this.redPackageBroadcast[0].SceneObjectId);
                localStorage.removeItem(`redid`);
                localStorage.setItem(`redid`, this.redPackageBroadcast[0].SceneObjectId);
                // localStorage['redid'] = this.redPackageBroadcast[0].SceneObjectId;
                // localStorage.red = this.redPackageBroadcast[0].SceneObjectId;
            }
            if (this.redPackageBroadcast.length > 1 && (!!this.redPackageBroadcast[1].CustomerName) ) {
                this.redPackageBroadcast = this.redPackageBroadcast.slice(1);
                this.redPackageBroadcastCountDown = 20;
                this.countDownRedPackage(this.redPackageBroadcast[0]);
                this.currentRedPackage = this.redPackageBroadcast[0];
            } else {
                clearInterval(this.redPackageTimer);
                this.redPackageBroadcastCountDown = 0;
                this.redPackageVisible = false;
                this.currentRedPackage = {
                    CustomerBusinessDomains: ''
                };
            }
            
            // 红包初始化数据还原
            this.isGrabed = false; // 当前红包是否被抢
            this.isReceived = false;
            this.amount = 0.00;
        },

        openRedPackage () {
            console.log(`抢红包`);
            this.openLoading = true;
            broadcastService
                .grabRedPacket({
                    BroadcastSendingLogId: this.currentRedPackage.BroadcastSendingLogId,
                })
                .then(res => {
                    console.log('grab-------------res', res);
                    this.isGrabed = true;
                    this.isReceived = res.isReceived;
                    this.amount = res.amount;
                })
                .catch(() => {
                    this.isGrabed = false;
                })
                .finally(() => {
                    this.openLoading = false;
                });
        },

    },
}
</script>

<style lang="scss" scope>
.bn-container {
    .offer-bn {
        background: white
            url('~@src/assets/images/broadcast-notification/offer-top-bg@2x.png')
            no-repeat top;
        border-radius: 10px !important;
        background-size: 100% auto;
        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            // padding: 22px;
            padding: 0;
            .offer-bn-container {
                position: relative;
                .title {
                    font-size: 28px;
                    font-family: 'Alimama ShuHeiTi';
                    font-weight: bold;
                    color: #fffefe;
                    line-height: 40px;
                    text-shadow: 1px 2px 2px #d94f4f;
                    padding: 29px 22px 29px;
                }
                .close-area {
                    width: 100px;
                    font-size: 14px;
                    position: absolute;
                    // right: -120px;
                    left: 450px;
                    top: -2px;
                    color: white;
                    text-align: left;
                    .seconds {
                        margin-right: 5px;
                    }
                    .close {
                        cursor: pointer;
                    }
                }
                .item-wrap {
                    width: 400px;
                    min-height: 167px;
                    background: white;
                    border-radius: 10px;
                    margin: 0 auto;
                    .offer-it {
                        height: 56px;
                        line-height: 56px;
                        font-size: 16px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        > i {
                            color: #ed7145;
                            font-size: 20px;
                        }
                        > span {
                            color: #333;
                            display: inline-block;
                            white-space: nowrap;
                            max-width: 290px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .has-red-package {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .rp-bn {
        background: white
            url('~@src/assets/images/broadcast-notification/red-package-bg.png')
            no-repeat top;
        border-radius: 20px !important;
        background-size: 100% auto;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 0;
            .rp-bn-container {
                position: relative;
                height: 417px;
                .close-area {
                    width: 100px;
                    font-size: 14px;
                    position: absolute;
                    left: 350px;
                    top: -2px;
                    color: white;
                    text-align: left;
                    .seconds {
                        margin-right: 5px;
                    }
                    .close {
                        cursor: pointer;
                    }
                }

                .face-page {
                    .fp-text {
                        font-size: 22px;
                        font-family: 'Microsoft YaHei';
                        font-weight: bold;
                        color: #fee7b3;
                        line-height: 40px;
                        text-align: center;

                        box-sizing: border-box;
                        padding: 100px 20px 87px;
                        // margin-top: 100px;
                    }
                    .open-btn {
                        width: 100%;
                        cursor: pointer;
                        > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .open {
                    animation: 2.4s move ease-in-out;
                }
                .tit {
                    font-size: 20px;
                    font-family: 'Microsoft YaHei';
                    font-weight: bold;
                    color: #fee7b3;
                    line-height: 40px;
                    padding: 100px 0 36px;
                    text-align: center;
                }
                .get-page {
                    .amount {
                        font-size: 60px;
                        font-family: 'Microsoft YaHei';
                        font-weight: bold;
                        color: #fee7b3;
                        line-height: 40px;
                        text-align: center;
                    }
                    .tips {
                        font-size: 14px;
                        font-family: 'Microsoft YaHei';
                        font-weight: 400;
                        color: #fee7b3;
                        line-height: 36px;
                        text-align: center;
                        margin-top: 85px;
                    }
                }
                .fail-page {
                    text-align: center;
                    > img {
                        width: 77px;
                        height: 77px;
                    }
                }

                @keyframes move {
                    0% {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                    90% {
                        transform: translateY(-190px);
                        opacity: 1;
                    }
                    100% {
                        transform: translateY(-240px);
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>