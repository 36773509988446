<template>
    <el-dialog
        title="请选择客户"
        :visible.sync="dialogVisible"
        width="380px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true"
    >
        <el-select
            class="auth-customer-select-input"
            popper-class="auth-customer-select"
            :disabled="isDisabled"
            filterable
            v-model="customerId"
            :placeholder="placeholder"
        >
            <el-option
                v-for="customer in customerList"
                :key="customer.id"
                :value="customer.id"
                :label="customer.name"
                :title="customer.name"
                @click.native="handleCustomerChange(customer)"
            ></el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
    
</template>

<script>
export default {
    name: "customerSelect",
    props: {
        firmId: {
            type: String,
            default: ""
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择客户"
        },
        customerList: Array
    },
    data() {
        return {
            dialogVisible: false,
            customerId: '',
        }
    },
    mounted() {
    },
    methods: {
        show() {
            this.dialogVisible = true;
        },
        getSelectedCustomer() {
            return this.customerList.find(item => item.id === this.customerId);
        },
        handleCustomerChange(customer) {
            this.customerId = customer.id;
        },
        cancelHandle() {
            this.customerId = '';
            this.dialogVisible = false;
        },
        confirmHandle() {
            if(!this.customerId) {
                shortTips('请选择客户');
                return false;
            }
            this.$emit('authCustomerSelected', this.customerId);
            this.cancelHandle();
        }
    }
}
</script>

<style lang="scss" scoped>
.auth-customer-select-input {
    width: 100%;
}
</style>
<style lang="scss">
.auth-customer-select, .auth-customer-select .el-select-dropdown__item {
    width:340px;
}
.auth-customer-select .el-select-dropdown__list {
    overflow-x: hidden;
}
</style>