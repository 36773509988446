var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "topbar-wrap" }, [
    _c("div", { staticClass: "cell" }, [
      _c("img", {
        attrs: {
          alt: "logo",
          src: require("@src/assets/images/workbench/workbench-logo.png"),
        },
        on: { click: _vm.jumpHomePage },
      }),
      _c(
        "div",
        { staticClass: "schedule-notice" },
        [_c("notice-widget", { ref: "noticeWidget" })],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "cell topbar-wrap-right" },
      [
        _c(
          "el-dropdown",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDepartmentPermission || _vm.isAdministrator,
                expression: "isDepartmentPermission || isAdministrator",
              },
            ],
            attrs: { placement: "bottom" },
          },
          [
            _c(
              "button",
              { staticClass: "custom-btn" },
              [
                _c("font-icon", {
                  staticClass: "btn-icon",
                  attrs: { href: "#icon-zuzhijiagou" },
                }),
                _vm._v("组织架构\r\n            "),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "top-bar no-arrow",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showDepartmentDialog.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("单个创建")]
                ),
                _vm.auditCustomerList.length > 0
                  ? _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showCustomerSelectDialog.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("批量创建")]
                    )
                  : _vm._e(),
                (_vm.isDepartmentManageAudit || _vm.isAdministrator) &&
                _vm.auditCustomerList.length > 0
                  ? _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.jumpApplyList("audit")
                          },
                        },
                      },
                      [_vm._v("我的审批")]
                    )
                  : _vm._e(),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.jumpApplyList("apply")
                      },
                    },
                  },
                  [_vm._v("我的申请")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dropdown",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isJobPermission || _vm.isAdministrator,
                expression: "isJobPermission || isAdministrator",
              },
            ],
            attrs: { placement: "bottom" },
          },
          [
            _c(
              "button",
              { staticClass: "custom-btn" },
              [
                _c("font-icon", {
                  staticClass: "btn-icon",
                  attrs: { href: "#icon-file-add" },
                }),
                _vm._v("职位\r\n                "),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "top-bar no-arrow",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.createJob(
                          "/jobCreate/single",
                          "createSingleJob"
                        )
                      },
                    },
                  },
                  [_vm._v("单个创建")]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.createJob(
                          "/jobCreate/multi",
                          "createBatchJob"
                        )
                      },
                    },
                  },
                  [_vm._v("批量创建")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dropdown",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTalentPermission || _vm.isAdministrator,
                expression: "isTalentPermission || isAdministrator",
              },
            ],
            ref: "resumeUploadDropdown",
            attrs: { placement: "bottom" },
          },
          [
            _c(
              "button",
              { staticClass: "custom-btn" },
              [
                _c("font-icon", {
                  staticClass: "btn-icon",
                  attrs: { href: "#icon-add-circle" },
                }),
                _vm._v("简历\r\n                "),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "top-bar no-arrow",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.gotoLink(
                          "/Headhunting/MyCompany.html?type=1#/resumeUpload/singleUpload"
                        )
                      },
                    },
                  },
                  [_vm._v("企业简历")]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.gotoLink(
                          "/Headhunting/MyCompany.html?type=0#/resumeUpload/singleUpload"
                        )
                      },
                    },
                  },
                  [_vm._v("个人简历")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-popover",
          {
            attrs: {
              placement: "bottom",
              width: "112",
              trigger: "hover",
              "open-delay": 500,
              "visible-arrow": false,
              "popper-class": "kefu-popover",
            },
          },
          [
            _c("div", { staticClass: "kefu-detail" }, [
              _c("img", {
                staticClass: "wechat-code",
                attrs: {
                  width: "98",
                  height: "98",
                  src: require("@src/assets/images/service-qr-code.png"),
                  alt: "微信二维码",
                },
              }),
              _c("p", { staticClass: "liebide-phone" }, [_vm._v("客服微信")]),
            ]),
            _c(
              "span",
              {
                staticClass: "icon-wrap",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _c("font-icon", {
                  staticClass: "btn-icon-b",
                  attrs: { href: "#icon-kefu" },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-popover",
          {
            attrs: {
              width: "328",
              trigger: "hover",
              "open-delay": 500,
              placement: "bottom",
              "visible-arrow": false,
              "popper-class": "app-card-popover",
            },
            on: { show: _vm.appCardShow },
            model: {
              value: _vm.appCardVisible,
              callback: function ($$v) {
                _vm.appCardVisible = $$v
              },
              expression: "appCardVisible",
            },
          },
          [
            _c("div", { staticClass: "app-card" }, [
              _c("div", { staticClass: "app-card-left" }, [
                _c("img", {
                  attrs: {
                    src: require("@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png"),
                    alt: "猎必得app",
                    width: "115px",
                    height: "115px",
                  },
                }),
                _c("span", { staticClass: "app-card-title" }, [
                  _vm._v("扫码下载猎必得app"),
                ]),
              ]),
              _c("div", { staticClass: "app-card-right" }, [
                _c("p", { staticClass: "app-card-text" }, [
                  _vm._v(
                    "\r\n                            1.随时随地，极速找人"
                  ),
                  _c("br"),
                  _vm._v(
                    "\r\n                            2.实时处理/查看推荐状态"
                  ),
                  _c("br"),
                  _vm._v(
                    "\r\n                            3.轻松跟进人选\r\n                        "
                  ),
                ]),
                _c("p", { staticClass: "app-card-text" }, [
                  _vm._v(
                    "\r\n                            更多功能，敬请期待…\r\n                        "
                  ),
                ]),
                _c("p", { staticClass: "app-card-text app-card-tip" }, [
                  _vm._v(
                    "\r\n                            八爪盒子app即是猎必得app\r\n                        "
                  ),
                ]),
              ]),
            ]),
            _c(
              "span",
              {
                staticClass: "icon-wrap",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _c("font-icon", {
                  staticClass: "btn-icon-b",
                  attrs: { href: "#icon-shoujihao" },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dropdown",
          {
            attrs: {
              trigger: "hover",
              placement: "bottom",
              "visible-arrow": false,
            },
            on: { command: _vm.handleToolsCommand },
          },
          [
            _c(
              "span",
              { staticClass: "icon-wrap" },
              [
                _c("font-icon", {
                  staticClass: "btn-icon-b",
                  attrs: { href: "#icon-modular" },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "tools-dropdown-menu no-arrow",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  { attrs: { command: "workDayReport" } },
                  [
                    _c(
                      "div",
                      { staticClass: "item-wrap" },
                      [
                        _c("font-icon", {
                          staticClass: "item-icon",
                          attrs: { href: "#icon-survey" },
                        }),
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v("工作日报"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c("el-dropdown-item", { attrs: { command: "realtimeData" } }, [
                  _c(
                    "div",
                    { staticClass: "item-wrap" },
                    [
                      _c("font-icon", {
                        staticClass: "item-icon",
                        attrs: { href: "#icon-chart-bar" },
                      }),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v("实时数据"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("el-dropdown-item", { attrs: { command: "appDesc" } }, [
                  _c(
                    "div",
                    { staticClass: "item-wrap" },
                    [
                      _c("font-icon", {
                        staticClass: "item-icon",
                        attrs: { href: "#icon-modular" },
                      }),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v("增值应用"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-dropdown-item",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          width: "152",
                          trigger: "hover",
                          "visible-arrow": false,
                          "popper-class": "help-detail-popover",
                        },
                      },
                      [
                        _c("div", { staticClass: "help-detail" }, [
                          _c("img", {
                            staticClass: "wechat-code",
                            attrs: {
                              width: "98",
                              height: "98",
                              src: _vm.helperWechatCode,
                              alt: "微信二维码",
                            },
                          }),
                          _c("p", { staticClass: "liebide-phone" }, [
                            _vm._v("0755-26014780"),
                          ]),
                          _c("p", [
                            _c(
                              "a",
                              {
                                staticClass: "liebide-email",
                                attrs: { href: "mailto: service@liebide.com" },
                              },
                              [_vm._v("service@liebide.com")]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "item-wrap",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("font-icon", {
                              staticClass: "item-icon",
                              attrs: { href: "#icon-work" },
                            }),
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v("小秘书"),
                            ]),
                            _c("font-icon", {
                              staticClass: "item-arrow-icon",
                              attrs: { href: "#icon-right-copy" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.user.isCFUser
                  ? _c(
                      "el-dropdown-item",
                      { attrs: { divided: "", command: "loveStudy" } },
                      [
                        _c(
                          "div",
                          { staticClass: "item-wrap" },
                          [
                            _c("font-icon", {
                              staticClass: "item-icon",
                              attrs: { href: "#icon-electronics" },
                            }),
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v("学院学习"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "span",
          {
            ref: "IMBtn",
            staticClass: "im-btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showIMDialog.apply(null, arguments)
              },
            },
          },
          [
            _c("font-icon", {
              staticClass: "btn-icon-im",
              attrs: { href: "#icon-xiaoxizhongxin" },
            }),
            _vm.messageNum > 0 && _vm.messageNum <= 99
              ? _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.messageNum)),
                ])
              : _vm._e(),
            _vm.messageNum > 99
              ? _c("span", { staticClass: "num-more-100" })
              : _vm._e(),
          ],
          1
        ),
        !!_vm.user.firmId
          ? _c("summary-entry", {
              ref: "summaryEntry",
              attrs: { user: _vm.user },
            })
          : _vm._e(),
        _c(
          "el-popover",
          {
            attrs: {
              placement: "bottom-end",
              width: "262",
              "popper-class": "avator-popper",
              "visible-arrow": false,
              trigger: "hover",
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "topbar-avatar",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _vm.userAvatar
                  ? _c("img", {
                      attrs: {
                        src: _vm.avatarBase64 || `${_vm.userAvatar}?size=60`,
                        alt: "avator",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@src/assets/images/default-user.png"),
                        alt: "avator",
                      },
                    }),
              ]
            ),
            _c("div", { staticClass: "avator-wrap" }, [
              _c("div", { staticClass: "avator-top" }, [
                _c(
                  "div",
                  {
                    staticClass: "pic",
                    on: {
                      click: function ($event) {
                        return _vm.gotoLink("/#/personalCenter")
                      },
                    },
                  },
                  [
                    _vm.userAvatar
                      ? _c("img", {
                          attrs: {
                            src:
                              _vm.avatarBase64 || `${_vm.userAvatar}?size=60`,
                            alt: "avator",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@src/assets/images/default-user.png"),
                            alt: "avator",
                          },
                        }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "infos",
                    on: {
                      click: function ($event) {
                        return _vm.gotoLink("/#/personalCenter")
                      },
                    },
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "name" },
                      [
                        _c("span", { staticClass: "name-txt ellipsis" }, [
                          _vm._v(_vm._s(_vm.user.realName)),
                        ]),
                        _c("font-icon", {
                          staticClass: "name-icon",
                          attrs: { href: "#icon-right-copy" },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "email" }, [
                      _vm._v(_vm._s(_vm.user.email)),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "func-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "func-cell",
                    on: {
                      click: function ($event) {
                        return _vm.gotoLink("/#/personalCenter")
                      },
                    },
                  },
                  [
                    _c("font-icon", {
                      staticClass: "func-icon",
                      attrs: { href: "#icon-shouye" },
                    }),
                    _c("p", { staticClass: "txt" }, [_vm._v("我的资料")]),
                  ],
                  1
                ),
                _vm.user.isCFUser
                  ? _c(
                      "div",
                      {
                        staticClass: "func-cell",
                        on: {
                          click: function ($event) {
                            return _vm.gotoLink(
                              "/Headhunting/MyCompany.html#/myPoint"
                            )
                          },
                        },
                      },
                      [
                        _c("font-icon", {
                          staticClass: "func-icon",
                          attrs: { href: "#icon-lihe" },
                        }),
                        _c("p", { staticClass: "txt" }, [_vm._v("我的积分")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "func-cell",
                    on: { click: _vm.showSuggestFeedBack },
                  },
                  [
                    _c("font-icon", {
                      staticClass: "func-icon",
                      attrs: { href: "#icon-pinglun" },
                    }),
                    _c("p", { staticClass: "txt" }, [_vm._v("意见反馈")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "func-cell",
                    on: {
                      click: function ($event) {
                        return _vm.gotoLink(
                          "https://www.yuque.com/r/liebide/books"
                        )
                      },
                    },
                  },
                  [
                    _c("font-icon", {
                      staticClass: "func-icon",
                      attrs: { href: "#icon-bangzhu" },
                    }),
                    _c("p", { staticClass: "txt" }, [_vm._v("帮助中心")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "func-cell", on: { click: _vm.logout } },
                  [
                    _c("font-icon", {
                      staticClass: "func-icon",
                      attrs: { href: "#icon-tuichu" },
                    }),
                    _c("p", { staticClass: "txt" }, [_vm._v("退出登录")]),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c("suggest-feedback-dialog", { ref: "suggestFeedback" }),
        _c("auth-customer-select-dialog", {
          ref: "authCustomerSelectDialog",
          attrs: { customerList: _vm.auditCustomerList },
          on: { authCustomerSelected: _vm.handleAuthCustomerSelect },
        }),
        _c("un-audit-customer-department-dialog", {
          ref: "unAuditCustomerDepartmentDialog",
          on: { "refresh-audit-list": _vm.getAuditList },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }