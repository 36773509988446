var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-autocomplete", {
    staticClass: "customer-select-input",
    attrs: {
      "popper-class": "customer-select",
      size: _vm.size,
      maxlength: 128,
      "fetch-suggestions": _vm.querySearchAsync,
      placeholder: _vm.placeholder,
      valueKey: _vm.valueKey,
      disabled: _vm.isDisabled,
    },
    on: { select: _vm.handleSelect, blur: _vm.handleBlur },
    model: {
      value: _vm.comCustomerName,
      callback: function ($$v) {
        _vm.comCustomerName = $$v
      },
      expression: "comCustomerName",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }