var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        title: _vm.isAddGroupNember ? "选择群成员" : "创建群聊",
        width: "602px",
        "custom-class": "create-group-dialog",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        "destroy-on-close": true,
      },
      on: { close: _vm.hide },
    },
    [
      _c("div", { staticClass: "create-group-container" }, [
        _c(
          "div",
          { staticClass: "member-list-container" },
          [
            _c("member-list", {
              ref: "memberList",
              attrs: { userId: _vm.userId },
              on: { memberSelected: _vm.handleSelectMember },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "group-create-container" },
          [
            _c("selected-member-list", {
              attrs: {
                height: 302,
                selectedMemberList: _vm.selectedMemberList,
              },
              on: { removeSelected: _vm.removeSelected },
            }),
            _c(
              "div",
              { staticClass: "group-create" },
              [
                _c("div", { staticClass: "create-title" }, [
                  _vm._v("\n                  群名称-必填\n              "),
                ]),
                _c("el-input", {
                  staticClass: "group-name-input",
                  attrs: {
                    value: _vm.groupName,
                    placeholder: "取个群名称方便搜索",
                    disabled: _vm.isAddGroupNember,
                  },
                  on: { input: _vm.groupNameInput },
                }),
                _c(
                  "div",
                  { staticClass: "group-btn-list" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "creat-group-btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.createGroup },
                      },
                      [_vm._v(_vm._s(_vm.isAddGroupNember ? "确定" : "创建"))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "cancel-create-btn",
                        on: { click: _vm.hide },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }