var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "message-bubble",
    {
      attrs: { isMine: _vm.isMine, message: _vm.message, groupId: _vm.groupId },
    },
    [
      _c("img", {
        staticClass: "image-element",
        attrs: { src: _vm.imageUrl },
        on: { load: _vm.onImageLoaded, click: _vm.handlePreview },
      }),
      _vm.showProgressBar
        ? _c("el-progress", {
            attrs: {
              percentage: _vm.percentage,
              color: (percentage) =>
                percentage === 100 ? "#67c23a" : "#409eff",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }