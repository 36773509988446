<template>
  <el-dialog
    width="556px"
    :visible="dialogVisible"
    :show-close="!!isAudit"
    @close="cancelHandle"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    custom-class="edit-department-dialog"
    v-loading="loading">
        <el-form
            class="department-form"
            ref="departmentForm"
            :model="departmentForm"
            :rules="departmentFormRule"
            label-width="114px">
            <el-form-item
                label="客户名称"
                prop="customerId">
                <customer-select
                    ref="customerSelect"
                    placeholder="输入客户名称关键词"
                    :isDisabled="(isAudit && !isAuditEdit) || isDisabledCustomer"
                    @customerSelected="handleCustomerSelected"
                ></customer-select>
            </el-form-item>
            <el-form-item
                label="上级部门名称"
                prop="parentDepartmentId">
                <customer-department-select
                    ref="departmentSelect"
                    :customerId="departmentForm.customerId"
                    :isDisabled="(isAudit && !isAuditEdit) || !departmentForm.customerId"
                    :departmentId="departmentForm.parentDepartmentId"
                    @update-department="updateParentdepartmentId"
                    @update-exist-departments="updateExistDepartments"
                ></customer-department-select>
                <span v-if="!(isAudit && !isAuditEdit)" class="parent-department-tip">尽量输入完整的部门路径，多级部门之间用“，”符号分隔。</span>
            </el-form-item>
            <el-form-item
                label="部门名称"
                prop="name">
                <el-popover
                    placement="bottom-start"
                    width="384"
                    trigger="manual"
                    :disabled="existDepartmentList.length == 0"
                    v-model="existDepartmentVisible">
                    <el-input
                        slot="reference"
                        :disabled="(isAudit && !isAuditEdit)"
                        @focus="existDepartmentVisible = !existDepartmentVisible"
                        @blur="existDepartmentVisible = !existDepartmentVisible"
                        v-model="departmentForm.name"
                        placeholder="输入部门名称"
                        :maxlength="10"
                    ></el-input>
                    <div class="exist-department-list">
                        <p>当前已存在以下部门</p>
                        <ul>
                            <li v-for="(item, index) in existDepartmentList" :key="index">{{item}}</li>
                        </ul>
                    </div>
                </el-popover>
            </el-form-item>
            <el-form-item
                label="部门人数"
                prop="memberCount">
                <el-select
                    :disabled="(isAudit && !isAuditEdit)"
                    v-model="departmentForm.memberCount"
                    placeholder="选择部门人数">
                    <el-option
                        v-for="item in memberCounts"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="部门简介"
                prop="description">
                <el-input
                    :disabled="(isAudit && !isAuditEdit)"
                    type="textarea"
                    v-model="departmentForm.description"
                    placeholder="请输入部门简介"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="业务简介"
                prop="business">
                <el-input
                    :disabled="(isAudit && !isAuditEdit)"
                    type="textarea"
                    v-model="departmentForm.business"
                    placeholder="请输入业务简介"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="岗位需求"
                prop="requirement">
                <el-input
                    :disabled="(isAudit && !isAuditEdit)"
                    type="textarea"
                    v-model="departmentForm.requirement"
                    placeholder="请输入岗位需求"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="面试流程"
                prop="interviewProcess">
                <el-input
                    :disabled="(isAudit && !isAuditEdit)"
                    type="textarea"
                    v-model="departmentForm.interviewProcess"
                    placeholder="请输入面试流程"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="薪资结构"
                prop="salaryStructure">
                <el-input
                    :disabled="(isAudit && !isAuditEdit)"
                    type="textarea"
                    v-model="departmentForm.salaryStructure"
                    placeholder="请输入薪资结构"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
        </el-form>
        <span v-if="isAudit" slot="footer" class="dialog-footer">
            <el-button @click="auditEditHandle">编辑</el-button>
            <el-button @click="rejectHandle">不通过</el-button>
            <el-button type="primary" @click="resolveHandle" v-loading="auditLoading">通 过</el-button>
        </span>
        <span v-else slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
import CustomerSelect from '#/component/common/customer-select.vue';
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
export default {
    components: {
        CustomerSelect,
        CustomerDepartmentSelect
    },
    data() {
        return {
            dialogVisible: false,
            existDepartmentVisible: false,
            existDepartmentList: [],
            isDisabledCustomer: false,
            isEdit: false,
            isAudit: false, //申请审核界面
            isAuditEdit: false, //申请审核界面点击编辑按钮
            isCustomerDeleted: false,  //审核界面客户是否已被删除
            loading: false,
            auditLoading: false,
            index: 0,
            departmentForm: {
                id: 0,
                customerId: "",
                customerName: '',
                parentId: null,
                parentDepartmentId: null,
                parentDepartmentName: '',
                name: "",
                memberCount: 0,
                description: "",
                business: "",
                requirement: "",
                interviewProcess: "",
                salaryStructure: "",
                groupVersion: ''
            },
            departmentFormRule: {
                customerId: [
                    {   required: true, message: "请选择客户", trigger: 'blur'    }
                ],
                parentDepartmentId: [
                    {   required: true, message: "请选择上级部门", trigger: 'blur'    }
                ],
                name: [
                    {   required: true, message: "请输入部门名称", trigger: 'blur'    }
                ]
            },
            customerName: "",
            memberCounts: [
                {
                    text: "少于50人",
                    value: 1
                }, {
                    text: "50-150人",
                    value: 2
                }, {
                    text: "150-500人",
                    value: 3
                }, {
                    text: "500-1000人",
                    value: 4
                }, {
                    text: "1000人以上",
                    value: 5
                }
            ],
        }
    },
    computed: {
        title() {
            let title = '添加组织架构';
            if(this.isAudit) {
                title = '申请创建组织架构信息';
            }else if (this.isEdit) {
                title = '编辑组织架构'
            }
            return title;
        }
    },
    methods: {
        show(isEdit = false, departmentJson, isAudit) {
            this.isEdit = isEdit;
            this.isAudit = isAudit;
            this.dialogVisible = true;

            if (departmentJson?.customerId == '') {
                this.isCustomerDeleted = true;
            }

            if(isAudit) {
                this.index = departmentJson.index;
                this.departmentForm.id = departmentJson.id;
                this.departmentForm.customerId = departmentJson.customerId;
                this.getDepartmentApplyDetail();
            }else {
                if(isEdit) {
                    Object.assign(this.departmentForm, departmentJson);
                    this.isDisabledCustomer = true;
                    this.getDepartmentDetail();
                } else if(departmentJson && departmentJson.customerId) {
                    this.isDisabledCustomer = true;
                    this.$nextTick(() => {
                        this.$refs.customerSelect.customerId = departmentJson.customerId;
                        this.handleCustomerSelected({
                            customerId: departmentJson.customerId,
                            customerName: departmentJson.customerName
                        });
                    })
                }
            }

        },
        getGroupVersion(customerId) {
            DepartmentService.getGroupVersion({
                customerId: customerId
            }).then(res => {
                this.departmentForm.groupVersion = res;
            })
        },
        getDepartmentDetail() {
            this.loading = true;
            DepartmentService.getDepartmentDetail({
                customerId: this.departmentForm.customerId,
                id: this.departmentForm.id
            }).then(res => {
                this.loading = false;
                // this.departmentForm = {id, customerId, name, memberCount, description, business, requirement, interviewProcess, salaryStructure, groupVersion} = res;
                Object.assign(this.departmentForm, res);
                this.departmentForm.parentDepartmentId = res.parentId;
                this.$refs.customerSelect.customerId = res.customerId;
                this.$refs.departmentSelect.editInit({
                    id: res.parentId,
                    name: res.path
                });
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        getDepartmentApplyDetail() {
            this.loading = true;
            DepartmentService.getDepartmentApplyDetail({
                id: this.departmentForm.id
            }).then(res => {
                this.loading = false;
                Object.assign(this.departmentForm, res);
                if (!this.isCustomerDeleted) {
                    this.$refs.customerSelect.customerId = res.customerId;
                    this.$refs.departmentSelect.editInit({
                        id: res.parentDepartmentId == 0 ? '0' : res.parentDepartmentId,
                        name: res.parentDepartmentName
                    });
                } else {
                    this.departmentForm.customerId = '';
                    this.departmentForm.parentDepartmentId = null;
                    this.departmentForm.parentDepartmentName = '';
                }
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        handleCustomerSelected(customer) {
            this.departmentForm.customerId = customer.customerId;
            this.departmentForm.customerName = customer.customerName;
            this.departmentForm.parentDepartmentId = null;
            this.departmentForm.parentDepartmentName = '';
            this.customerName = customer.customerName;
            this.$refs.departmentSelect.reset();
            this.$nextTick(() => {
                this.$refs.departmentSelect.departmentList.push({
                    name: customer.customerName,
                    id: '0',
                    leaf: true
                });
            })

            if(this.isAuditEdit || (!this.isAudit && !this.isEdit)) {
                if(customer && customer.customerId) {
                    this.getGroupVersion(customer.customerId);
                }else {
                    this.departmentForm.groupVersion = '';
                }
            }

            this.$refs.departmentForm.validateField('customerId', (valid) => {})
        },
        updateParentdepartmentId(val) {
            this.$set(this.departmentForm, 'parentId', val.id)
            this.$set(this.departmentForm, 'parentDepartmentId', val.id)
            this.$set(this.departmentForm, 'parentDepartmentName', val.departmentName)
            this.$refs.departmentForm.validateField('parentDepartmentId', (valid) => {})
        },
        updateExistDepartments(existDepartmentList) {
            this.existDepartmentList = existDepartmentList;
        },
        cancelHandle() {
            this.dialogVisible = false;
            this.isDisabledCustomer = false;
            this.isAudit = false;
            this.isAuditEdit = false;
            this.isCustomerDeleted = false;
            this.$refs.departmentForm.resetFields();
            this.$refs.departmentForm.$el.scrollTop = 0;
            this.$refs.customerSelect.customerId = "";
            this.$refs.departmentSelect.reset();
        },
        confirmHandle() {
            this.$refs.departmentForm.validate(isValid => {
                if(isValid) {
                    if(this.isEdit && this.departmentForm.parentDepartmentId === this.departmentForm.id) {
                        shortTips("上级部门不能选择当前部门！");
                        return false;
                    }
                    if(!this.departmentForm.groupVersion) {
                        shortTips("版本号无效，请切换客户重试！");
                        return false;
                    }
                    this.loading = true;
                    let params = Object.assign({}, this.departmentForm);
                    if(this.isEdit) {
                        DepartmentService.editDepartment(params)
                        .then(res => {
                            this.loading = false;
                            shortTips('编辑成功');
                            this.$emit("refresh");
                            this.cancelHandle();
                        }).catch(err => {
                            this.loading = false;
                            console.log(err)
                        })
                    }else {
                        DepartmentService.createOrUpdateDep(params)
                        .then(res => {
                            this.loading = false;
                            if(res.createType == 0) {
                                    // this.$emit("refresh");
                                shortTips('您申请创建的组织架构已提交，请等待审核结果');
                                
                            }else {
                                shortTips('创建成功');
                                if(location.href.indexOf('/jobCreate/single') === -1 && location.href.indexOf('/jobCreate/multi') === -1) {
                                    if(this.$route.meta.pageCode === 'CustomerDepartment') {
                                        location.reload();
                                    } else {
                                        window.open(`/Headhunting/MyCompany.html#/Customer/${this.departmentForm.customerId}/department`);
                                        // location.href = `/#/Customer/${this.departmentForm.customerId}/department`;
                                    }
                                }
                            }
                            this.cancelHandle();
                        }).catch(err => {
                            this.loading = false;
                            console.log(err)
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        resolveHandle() {
            if(this.auditLoading) {
                return false
            }
            this.$refs.departmentForm.validate(isValid => {
                if(isValid) {
                    if(this.isEdit && this.departmentForm.parentDepartmentId === this.departmentForm.id) {
                        shortTips("上级部门不能选择当前部门！");
                        return false;
                    }
                    if(!this.departmentForm.groupVersion) {
                        shortTips("版本号无效，请切换客户重试！");
                        return false;
                    }
                    this.auditLoading = true;
                    if (this.isAuditEdit) {
                        DepartmentService.editAndPassDepartment({
                            id: this.departmentForm.id,
                            customerId: this.departmentForm.customerId,
                            customerName: this.departmentForm.customerName,
                            name: this.departmentForm.name,
                            parentDepartmentId: this.departmentForm.parentDepartmentId,
                            parentDepartmentName: this.departmentForm.parentDepartmentName,
                            memberCount: this.departmentForm.memberCount,
                            description: this.departmentForm.description,
                            business: this.departmentForm.business,
                            requirement: this.departmentForm.requirement,
                            interviewProcess: this.departmentForm.interviewProcess,
                            salaryStructure: this.departmentForm.salaryStructure,
                            groupVersion: this.departmentForm.groupVersion
                        }).then(res => {
                            this.auditLoading = false;
                            shortTips('申请已编辑成功并审批通过！');
                            this.$emit('audit-resolved', this.index);
                            this.cancelHandle();
                        }).catch(err => {
                            this.auditLoading = false;
                        })
                    } else {
                        DepartmentService.auditOperate({
                            id: this.departmentForm.id,
                            status: 1,
                            groupVersion: this.departmentForm.groupVersion,
                            customerId: this.departmentForm.customerId,
                            rejectResean: 0,
                            auditDetail: ''
                        }).then(res => {
                            this.auditLoading = false;
                            shortTips('申请已审批通过！');
                            this.$emit('audit-resolved', this.index);
                            this.cancelHandle();
                        }).catch(err => {
                            this.auditLoading = false;
                        })
                    }
                } else {
                    if (!this.isAuditEdit) {
                        shortTips('请编辑完善信息后再进行通过操作！');
                    }
                    return false;
                }
            });
            
        },
        rejectHandle() {
            if(this.auditLoading) {
                return false
            }

            this.$emit('audit-reject', this.index, this.departmentForm.id, this.departmentForm.customerId, this.departmentForm.groupVersion);
            this.cancelHandle();
        },
        auditEditHandle() {
            this.isAuditEdit = true;
        }
    }
}
</script>

<style lang="scss" scope>
    .edit-department-dialog.el-dialog {
        .el-dialog__body {
            padding-right: 0;
        }
        .department-form {
            width: 100%;
            padding-right: 20px;
            max-height: 560px;
            overflow: hidden;
            overflow-y: auto;
            .el-form-item__label {
                margin-bottom: 0;
            }
            .el-select {
                width: 100%;
            }

            .parent-department-tip {
                display: block;
                font-size: 12px;
                color: #999;
                line-height: 20px;
                margin-top: 4px;
            }
        }
    }
    .exist-department-list {
        p {
            font-size: 16px;
            color: #666;
        }

        ul {
            max-height: 200px;
            overflow-y: auto;
        }

        ul li {
            width: 100%;
            height: 30px;
            line-height: 30px;;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
