<template>
  <div class="message-wrapper" :class="messagePosition">
    <div
      v-if="!isSystem && currentConversationType === TIM.TYPES.CONV_C2C"
      class="c2c-layout"
      :class="messagePosition">
      <div class="avatar-container" v-if="showAvatar">
        <!-- 头像 -->
        <avatar :src="avatar"/>
      </div>
      <div class="col-2">
        <message-header 
          v-if="showMessageHeader" 
          :message="message" 
          :current-conversation="currentConversation"
          :current-user-profile="currentUserProfile"
        />
        <!-- 消息主体 -->
        <div class="content-wrapper">
          <message-status-icon
            v-if="isMine && message.status !== 'unSend'"
            :message="message" />
          <text-element
            v-if="message.msgType === TIM.TYPES.MSG_TEXT"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"
          />
          <image-element
            v-else-if="message.msgType === TIM.TYPES.MSG_IMAGE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"
          />
          <file-element
            v-else-if="message.msgType === TIM.TYPES.MSG_FILE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"
          />
          <sound-element
            v-else-if="message.msgType === TIM.TYPES.MSG_SOUND"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          />
          <!-- <group-tip-element
            v-else-if="message.msgType===TIM.TYPES.MSG_GRP_TIP"
            :payload="message.msgContent"
          />
          <group-system-notice-element
            v-else-if="message.msgType === TIM.TYPES.MSG_GRP_SYS_NOTICE"
            :payload="message.msgContent"
            :message="message"
          /> -->
          <custom-element
            v-else-if="message.msgType === TIM.TYPES.MSG_CUSTOM"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :isInner="isInner"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"
            :currentConversation="currentConversation"
          />
         <!-- <face-element
            v-else-if="message.msgType === TIM.TYPES.MSG_FACE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          />
          <video-element
            v-else-if="message.msgType === TIM.TYPES.MSG_VIDEO"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          />
          <geo-element
            v-else-if="message.msgType === TIM.TYPES.MSG_GEO"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          /> -->
          <span style="padding: 5px 0 0 8px" v-else-if="message.msgType === TIM.TYPES.MSG_VIDEO">视频消息暂不支持在网页查看，请到app打开</span>
          <span v-else>暂未支持的消息类型：{{message.msgType}}</span>
        </div>
        <!-- <message-footer v-if="showMessageHeader" :message="message" /> -->
      </div>
      <div class="col-3">
        <!-- 消息状态 -->
      </div>
    </div>

    <div
      v-if="!isSystem && currentConversationType === TIM.TYPES.CONV_GROUP"
      class="group-layout"
      :class="messagePosition">
      <div class="avatar-container" v-if="showAvatar" >
        <avatar
          class="group-member-avatar"
          :style="message.fromAccountId === userId ? 'cursor: default' : ''"
          :src="avatar"
          @click.native="showGroupMemberProfile"/>
      </div>
      <div class="col-2">
        <!-- 消息主体 -->
        <message-header 
          v-if="showMessageHeader"
          :message="message"
          :current-conversation="currentConversation"
          :current-user-profile="currentUserProfile"/>
        <div class="content-wrapper">
          <message-status-icon v-if="isMine && message.status !== 'unSend'" :message="message" />
          <text-element
            v-if="message.msgType === TIM.TYPES.MSG_TEXT && message.fromAccountId !== 'openIM123456' && message.fromAccountId !== 'baza_im_admin'"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"/>
          <image-element
            v-else-if="message.msgType === TIM.TYPES.MSG_IMAGE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :isWorkBenchMain="isWorkBenchMain"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"/>
          <file-element
            v-else-if="message.msgType === TIM.TYPES.MSG_FILE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"/>
          <sound-element
            v-else-if="message.msgType === TIM.TYPES.MSG_SOUND"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"/>
          <group-tip-element
            v-else-if="message.msgType === TIM.TYPES.MSG_GRP_TIP ||
                      (message.msgType === TIM.TYPES.MSG_CUSTOM && message.msgContent.data === 'group_create') ||
                      ((message.fromAccountId === 'openIM123456' || message.fromAccountId === 'baza_im_admin') && message.msgType !== TIM.TYPES.MSG_IMAGE)"
            :isMine="isMine"
            :message="message"
            :deleteMemberNick="deleteMemberNick"
            :groupMembers="groupMembers"
            :payload="message.msgContent"/>
          <custom-element
            v-else-if="message.msgType === TIM.TYPES.MSG_CUSTOM"
            :isMine="isMine"
            :isInner="isInner"
            :payload="message.msgContent"
            :message="message"
            :groupId="currentConversationType === TIM.TYPES.CONV_GROUP ? currentConversation.groupProfile.groupID : ''"
            :currentConversation="currentConversation"/>
          <!--<face-element
            v-else-if="message.msgType === TIM.TYPES.MSG_FACE"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          />
          <video-element
            v-else-if="message.msgType === TIM.TYPES.MSG_VIDEO"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          />
          <geo-element
            v-else-if="message.msgType === TIM.TYPES.MSG_GEO"
            :isMine="isMine"
            :payload="message.msgContent"
            :message="message"
          /> -->
          <span v-else-if="message.msgType === TIM.TYPES.MSG_VIDEO">该消息暂不支持在网页查看，请到app打开</span>
          <span v-else>暂未支持的消息类型：{{message.msgType}}</span>
        </div>
      </div>
      <!-- <div class="col-3"> -->
        <!-- 消息状态 -->
      <!-- </div> -->
    </div>

    <div class="system-layout" v-if="isSystem">
     <!-- <div class="avatar-container">
         <avatar :src="avatar" :type="currentConversationType" />
      </div> -->
      <div class="col-2">
        <div class="date">{{ message.msgTime | fullDate }}</div>
        <system-notice :payload="message.msgContent" :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '../avatar.vue'
import MessageStatusIcon from './message-status-icon.vue'
import MessageHeader from './message-header.vue'
// import MessageFooter from './message-footer'
import FileElement from './message-elements/file-element.vue'
// import FaceElement from './message-elements/face-element.vue'
import ImageElement from './message-elements/image-element.vue'
import TextElement from './message-elements/text-element.vue'
import SoundElement from './message-elements/sound-element.vue'
// import VideoElement from './message-elements/video-element.vue'
import GroupTipElement from './message-elements/group-tip-element.vue'
import SystemNotice from './message-elements/system-notice.vue'
import CustomElement from './message-elements/custom-element.vue'
// import GeoElement from './message-elements/geo-element.vue'
import emitter from '#/js/mixins/emitter.js';
import { getFullDate } from '../utils/date.js'
import { avatarHost } from '../utils/avatarHost.js';

export default {
  name: 'MessageItem',
  mixins: [emitter],
  components: {
    Avatar,
    MessageHeader,
    // MessageFooter,
    MessageStatusIcon,
    FileElement,
    // FaceElement,
    ImageElement,
    TextElement,
    SoundElement,
    GroupTipElement,
    SystemNotice,
    CustomElement,
    // VideoElement,
    // GeoElement,
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    memberList: Array,
    currentConversation: {
      type: Object,
      required: true
    },
    currentUserProfile: {
      type: Object,
      required: true
    },
    isInner: Boolean,
    deleteMemberNick: String,
    isWorkBenchMain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderDom: [],
      groupMembers: [],
    }
  },
  computed: {
    userId() {
      return document.querySelector('input[name="userUnionId"]') ? document.querySelector('input[name="userUnionId"]').value : this.$store.state.user.userInfo.unionId;
    },
    isSystem() {
        return this.currentConversation.conversationID == 'C2C_openIM123456';
    },
    // 是否显示头像，群提示消息不显示头像
    showAvatar() {
      if (this.message.status === 'unSend') {
        return false
      } else if (this.currentConversation.type === 'C2C' && !this.message.isRevoked) { // C2C且没有撤回的消息
        return true
      } else if (this.currentConversation.type === 'GROUP' && !this.message.isRevoked) { // group且没有撤回的消息
        return this.message.msgType !== this.TIM.TYPES.MSG_GRP_TIP &&
              !(this.message.msgType === TIM.TYPES.MSG_CUSTOM && this.message.msgContent.data === 'group_create') &&
              this.message.fromAccountId !== 'openIM123456' && this.message.fromAccountId !== 'baza_im_admin'
      }
      return false
    },
    avatar() {
      return `${avatarHost()}/user/${this.message.fromAccountId}`
      // if (this.currentConversation.type === 'C2C') {
      //   if (this.message.fromAccountId === this.userId) {
      //     return this.currentUserProfile.faceURL;
      //   }else {
      //     return this.currentConversation.userProfile.avatar;
      //   }
      // } else if (this.currentConversation.type === 'GROUP') {
      //   let avatarUrl = '';
      //   this.memberList.some(item => {
      //     if (item.userID === this.message.fromAccountId) {
      //       avatarUrl = item.avatar;
      //       return true;
      //     }
      //   })
      //   return avatarUrl;
      // } else {
      //   return '';
      // }
    },
    currentConversationType() {
      return this.currentConversation.type
    },
    isMine() {
      return this.message.fromAccountId == this.userId;
    },
    messagePosition() {
      if (
        ['TIMGroupTipElem', 'TIMGroupSystemNoticeElem'].includes(
          this.message.msgType
        ) || (this.message.msgType === TIM.TYPES.MSG_CUSTOM && this.message.msgContent.data === 'group_create')
        || this.message.fromAccountId === 'openIM123456' || this.message.fromAccountId === 'baza_im_admin'
      ) {
        return 'position-center'
      }
      if (this.message.isRevoked) { // 撤回消息
        return 'position-center'
      }
      if (this.isMine) {
        return 'position-right'
      } else {
        return 'position-left'
      }
    },
    showMessageHeader() {
      if (
        ['TIMGroupTipElem', 'TIMGroupSystemNoticeElem'].includes(
          this.message.msgType
        )
      ) {
        return false
      }
      if (this.message.isRevoked || this.message.status === 'unSend') { // 撤回消息或未发送的卡片分享消息
        return false
      }
      return true
    },
  },
  filters: {
    fullDate(date) {
      if(!date) {
        return '';
      }

      let time = new Date(date);
      if (time.toString().indexOf('1970') > -1) {
        time = new Date(date * 1000);
      }
      return getFullDate(time)
    }
  },
  watch: {
    memberList: {
      handler: function() {
        this.memberList.forEach(member => {
          let hasMember = this.groupMembers.some(item => item.userID === member.userID);
          if(!hasMember) {
            this.groupMembers.push(member);
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    showGroupMemberProfile(event) {
      if(this.message.fromAccountId !== this.userId) {
        this.dispatch('IMBaseApp', 'select-conversation-from-system-notice', `C2C_${this.message.fromAccountId}`);
      }
      // tim
      //   .getGroupMemberProfile({
      //     groupID: this.message.to,
      //     userIDList: [this.message.from]
      //   }).then(({ data: { memberList } }) => {
      //     if (memberList[0]) {
      //       this.$emit('showMemberProfile', { event, member: memberList[0] })
      //     }
      //   }).catch(err => {
      //     console.log('getGroupMemberProfileError', err);
      //   })
    }
  }
}
</script>

<style lang="stylus" scoped>
.message-wrapper {
  margin: 20px 0;
  .col-2 {
    width: auto;
  }

  .avatar-container {
    min-width: 25px;
    width: 25px;
    height: 25px;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
  }
}

.group-layout, .c2c-layout, .system-layout {
  display: flex;

  .col-1 {
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .group-member-avatar {
    cursor: pointer;
  }

  .col-2 {
    display: flex;
    flex-direction: column;
    // max-width 50% // 此设置可以自适应宽度，目前由bubble限制
  }

  .col-3 {
    width: 30px;
  }

  .date {
    font-size: 12px;
    color: #333;
    text-align: center;
    margin: -4px 0 10px;
  }

  &.position-left {
    .col-2 {
      align-items: flex-start;
    }
  }

  &.position-right {
    flex-direction: row-reverse;

    .col-2 {
      align-items: flex-end;
    }
  }

  &.position-center {
    justify-content: center;
    .base {
      justify-content: center;
    }
  }
}

.system-layout .col-2 {
  margin: 0 auto;
}

.c2c-layout {
  .col-2 {
    margin: 0;
    .base {
      margin-top: 3px;
    }
  }
}

.group-layout {
  .col-2 {
    margin: 0;
    .chat-bubble {
      margin-top: 5px;
    }
  }
}
</style>
