<template>
    <div class="notice-container" v-show="showNotice" v-loading="loading">
        <div class="notice-title">
            <span class="title-text">事项提醒</span>
            <span class="date-picker">
                <button @click="handleMonthMinus"><i class="el-icon-arrow-left"></i></button>
                {{date.month}}月 {{date.year}}
                <button @click="handleMonthPlus"><i class="el-icon-arrow-right"></i></button>
            </span>
            <span class="notice-close" @click.stop="handleClose"></span>
        </div>
        <div class="notice-tab">
            <span class="tab-item delay-item" :class="statusSelect == 2 ? 'isSelect': ''" @click="tabNotice(2)">已延期事项  {{isPastCount}}</span>
            <span class="tab-item done-item" :class="statusSelect == 1 ? 'isSelect': ''" @click="tabNotice(1)">已完成事项  {{isDoneCount}}</span>
        </div>
        <ul class="notice-list" v-if="noticeList.length > 0">
            <li
                class="notice-item"
                v-for="item in noticeList"
                :key="item.id"
                :class="item.status == 1 ? 'done-item' : (item.status == 2 ? 'past-item':'')"
            >
                <p class="notice-info">
                    <span class="info-name">
                        <!-- <a :href="`/Headhunting/MyCompany.html#/candidateDetail/${item.resumeId}`" target="_blank">{{item.realName}}</a> -->
                        <a :href="`/#/candidateDetail/${item.resumeId}`" target="_blank">{{item.realName}}</a>
                    </span>
                    <span class="info-time" v-if="item.isToday">今日 {{item.remindTime | getHM}}事项</span>
                    <span class="info-time color-gray" v-else>{{item.remindTime | showMonthTime}}事项</span>
                    <span class="info-status">{{item.status | statusText}}</span>
                </p>
                <p class="notice-content">{{item.content}}</p>
                <notice-opr :noticeItem="item" :index="item.index" @handleDone="handleDone" @handleDel="handleDel" @keepVisible="keepVisible"></notice-opr>
            </li>
        </ul>
        <div class="no-notice-data" v-else>
            暂无提醒事项
        </div>
    </div>
</template>
<script>
import NoticeOpr from './noticeOperate';
import moment from 'moment';
const statusMap = {
    '0': '',
    '1': '已完成',
    "2": '已延期'
}
export default {
    name: 'noticeList',
    components:{
        NoticeOpr
    },
    data() {
        return {
            showNotice: true,
            date:{
                month:7,
                year:2019
            },
            noticeList:[],
            noticeData: [],
            loading: false,
            status: [],
            statusSelect: 0,
            isDoneCount: 0,
            isPastCount: 0
        }
    },
    mounted() {
        this.setTodayDate();
        document.querySelector(".notice-container").addEventListener('click', (ev) => {
            ev.stopPropagation();
        })
    },
    filters:{
        showMonthTime: function (time) {
            return moment(time).format('YYYY-MM-DD HH:mm');
        },
        statusText:function(key){
            return statusMap[key]
        },
        getHM:function(time){
            return moment(time).format('HH:mm');
        }
    },
    methods: {
        handleMonthMinus(){
            let month = this.date.month;
            let year = this.date.year;
            if(month>1){
                month--;
            }else{
                month = 12;
                year --;
            }
            this.date = {
                month: month,
                year: year
            }
            this.getNoticeList();
        },
        handleMonthPlus(){
            let month = this.date.month;
            let year = this.date.year;
            if(month < 12){
                month ++;
            }else{
                month = 1;
                year ++;
            }
            this.date = {
                month: month,
                year: year
            }
            this.getNoticeList();
        },
        handleClose(){
            //this.showNotice = false;
            this.$emit('handleClose');
        },
        handleShow(){
            this.showNotice = true;
        },
        getNoticeList(){
            this.loading = true;
            _request({
                method: 'GET',
                url: `/Resume/Reminder/list?year=${this.date.year}&month=${this.date.month}`
            }).then(res => {
                let done = 0, past = 0;
                this.statusSelect = 0;
                if(res.length > 0) {
                    let today = moment().format('YYYY-MM-DD').replace(/\-/ig, '\/');
                    res.forEach((item, index) => {
                        item.index = index;
                        if(item.status == 0) {
                            let todayTime = new Date(today).getTime();
                            let noticeTime = new Date(moment(item.remindTime).format('YYYY-MM-DD').replace(/\-/ig, '\/')).getTime();
                            if(noticeTime < todayTime) {
                                item.status = 2;
                                past++;
                            }
                        } else {
                            done++;
                        }
                    })
                }
                this.noticeData = res;
                this.isDoneCount = done;
                this.isPastCount = past;
                this.sortNotice();
                this.loading = false;
            }).catch(err => {
                console.log(err)
                this.loading = false;
            })
        },
        handleDone(index){
            this.isDoneCount++;
            if(this.noticeData[index].status == 2) {
                this.isPastCount--;
            }
            this.noticeData[index].status = 1;
            this.sortNotice();
        },
        handleDel(index){
            if(this.noticeData[index].status == 2) {
                this.isPastCount--;
            } else if(this.noticeData[index].status == 1) {
                this.isDoneCount--;
            }
            this.noticeData.splice(index, 1);
        },
        sortNotice(){
            let statusSelect = this.statusSelect;
            let arr = [];
            let nowDate = new Date();
            if(statusSelect == 0) {
                arr = arr.concat(this.noticeData);
                arr.sort((a, b) => {
                    return b.status - a.status;
                })
                let leftNoticeList = arr.splice(this.isDoneCount + this.isPastCount);
                leftNoticeList.sort((a, b) => {
                    return new Date(a.remindTime).getTime() - new Date(b.remindTime).getTime();
                })
                let todayNoticeArr = [];
                let otherDayNoticeArr = [];
                leftNoticeList.forEach((item) =>{
                    if(new Date(item.remindTime).toDateString() === nowDate.toDateString()){
                        item.isToday = true;
                        todayNoticeArr.push(item);
                    }else{
                        otherDayNoticeArr.push(item);
                    }
                })
                leftNoticeList = todayNoticeArr.concat(otherDayNoticeArr);
                arr = leftNoticeList.concat(arr);
            } else {
                let todayNoticeArr = [];
                let otherDayNoticeArr = [];
                this.noticeData.forEach((item) => {
                    if(item.status !== statusSelect) {
                        return false;
                    }
                    if(new Date(item.remindTime).toDateString() === nowDate.toDateString()){
                        item.isToday = true;
                        todayNoticeArr.push(item);
                    }else{
                        otherDayNoticeArr.push(item);
                    }
                })
                arr = arr.concat(todayNoticeArr,otherDayNoticeArr);
            }
            this.noticeList = arr;
        },
        tabNotice(status){
            if(this.statusSelect == status) {
                this.statusSelect = 0;
            } else {
                this.statusSelect = status;
            }
            this.sortNotice();
        },
        keepVisible(){
            this.$emit('keepVisible');
        },
        setTodayDate(){
            let nowDate = new Date();
            this.date = {
                month: nowDate.getMonth() + 1,
                year: nowDate.getFullYear()
            }
        },
        resetDate(){
            this.setTodayDate();
            this.getNoticeList();
        }
    },
}
</script>
<style lang="scss" scoped>
.notice-container {
    position: relative;
    width: 330px;
    height: 540px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font-size: 14px;
    padding: 20px 4px 20px 20px;
    z-index: 999;
    border-radius: 4px;
    font-weight: normal;
    .notice-title{
        position: relative;
        color: #444;
        .title-text{
            font-weight: bold;
            margin-right: 20px;
        }
        .date-picker{
            button{
                outline: none;
                border: none;
                background-color: #fff;
                padding: 0 4px;
                i {
                    font-size: 12px;
                    vertical-align: middle;
                    color: #666;
                }
                &:focus,&:hover{
                    i {
                        color: $primary;
                    }
                }
            }
        }
        .notice-close{
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            top: 0;
            right: 10px;
            border-radius: 50%;
            background: #eee;
            cursor: pointer;
            &::before, &::after {
                content: "";
                width: 2px;
                height: 12px;
                background-color: #d8d8d8;
                position: absolute;
                top: 4px;
                left: 9px;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
    .notice-tab{
        padding: 16px 0;
        .tab-item{
            display: inline-block;
            height: 20px;
            line-height: 20px;
            padding: 0 10px;
            border-radius: 10px;
            margin-right: 10px;
            cursor: pointer;
            &.delay-item{
                background-color: #FEF1EE;
                color: #FC7859;
                &.isSelect,&:hover {
                    background-color: #FC7859;
                    color: #fff;
                }
            }
            &.done-item{
                background-color: #ebf8f5;
                color: #38BC9D;
                &.isSelect,&:hover {
                    background-color: $primary;
                    color: #fff;
                }
            }
        }
    }
    .notice-list{
        flex-grow: 1;
        overflow-y: scroll;
        .notice-item{
            position: relative;
            padding: 8px 0;
            border-bottom: 1px solid #eee;
            &:last-child{
                border: none;
            }
            &.done-item{
                .notice-content{
                    text-decoration: line-through;
                    color: #999;
                }
            }
            &.past-item{
                .notice-info{
                    .info-status{
                        color: #FC7859;
                    }
                }
            }
            .notice-info{
                background-color: transparent;
                position: relative;
                font-size: 12px;
                padding-left: 12px;
                display: flex;
                align-items: center;
                &:before{
                    display: block;
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color:$primary;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
                >span{
                    margin-right: 10px;
                }
                .info-name{
                    display: inline-block;
                    font-size: 14px;
                    max-width: 80px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                    a {
                        color: #444;
                    }
                    &:hover{
                        a{
                            color: $primary;
                        }
                    }
                }
                .info-time{
                    color: #4A90E2;
                }

                .color-gray{
                    color: #999;
                }

                .info-status{
                    color: #38BC9D;
                }

            }
            .notice-content{
                color: #666;
            }
            &.done-item, &.past-item {
                .info-time{
                    color: #999;
                }
            }
        }
    }
    .no-notice-data {
        height: 438px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 14px;
        background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
        background-position: top 85px center;
        background-size: 110px;
    }

    ::-webkit-scrollbar {
        width: 18px;
        height: 18px;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
        border-radius: 999px;
        border: 5px solid transparent;
    }

    ::-webkit-scrollbar-thumb {
        min-height: 80px;
        box-shadow: 0 0 0 5px rgba(198, 199, 198, 0.5) inset;
    }
}
</style>
