<template>
<el-dialog
    :visible="dialogVisible"
    width="956px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="point-rank-dialog"
    class="point-rank-dialog-wrapper"
    append-to-body>
    <div class="point-rank-wrapper">
        <span class="hide-dialog-btn" @click="hideDialog"></span>
        <div class="point-rank-type">
            <span
                class="rank-type-item"
                :class="{'active': rankType === 0}"
                @click="handleRankType(0)">
                周榜
            </span>
            <span
                class="rank-type-item"
                :class="{'active': rankType === 1}"
                @click="handleRankType(1)">
                总榜
            </span>
        </div>
        <div class="team-btn-list">
            <!-- <span
                class="team-btn-item"
                :class="{'active': !isTeam}"
                @click="handleTeamType(false)">
                个人
            </span> -->
            <span
                class="team-btn-item"
                :class="{'active': isTeam}"
                @click="handleTeamType(true)">
                团队
            </span>
        </div>
        <div class="point-rank">
            <span class="point-rank-title">
                {{rankType === 1 ? '总' : (rankDateType === 1 ? '本周' : '上周')}}积分{{isTeam ? '团队' : '个人'}}排行榜
            </span>
            <div class="point-rank-time" v-if="rankType === 0">
                统计时间：{{rankTime}}
            </div>
            <div class="point-rank-list-wrapper" v-loading="listLoading">
                <ul class="point-rank-list">
                    <template v-for="(item, index) in rankList">
                        <li
                            class="point-rank-item"
                            :key="index">
                            <span
                                class="rank-result"
                                :class="index < 3 ? `rank-result-${index}` : ''">
                                {{index > 2 ? index + 1 : ''}}
                            </span>
                            <div class="rank-user-info">
                                <img
                                    class="rank-user-avatar"
                                    :src="item.avatar"
                                    v-if="!isTeam"/>
                                <span class="rank-user-name" :title="item.name" :style="isTeam ? 'max-width: 230px;' : ''">
                                    {{item.name}}
                                </span>
                            </div>
                            <span class="rank-point">
                                {{!isTeam ? '' : '人均'}}
                                {{item.point | currency('', 0)}}分
                            </span>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="point-rank-list-footer">
                <span class="my-rank-result">
                    我的{{!isTeam ? '' : '团队'}}排名为：
                    <span class="text-orange">{{rank || '-'}}</span>
                </span>
                <span class="rank-point-sum">
                    {{isTeam ? (rankType === 1 ? '人均总' : (rankDateType === 1 ? '本周人均' : '上周人均')) : (rankType === 1 ? '总' : (rankDateType === 1 ? '本周获得' : '上周获得'))}}积分：
                    <span class="text-orange">{{point || '-'}}</span>
                </span>
                <el-tooltip popper-class="average-point-tip" effect="light" content="人均积分=团队在职人员总积分/团队在职人员" placement="bottom-start" v-if="isTeam" :visible-arrow="false">
                    <span class="icon-question">
                        <font-icon class="question-hover" href="#icon-talent_ql"></font-icon>
                        <font-icon class="question-no-hover" href="#icon-talent_qd"></font-icon>
                    </span>
                </el-tooltip>
            </div>
        </div>
        <span class="last-week-btn text-operate-btn" @click="handleRankDateType" v-if="rankType === 0">
            {{rankDateType === 0 ? '本周' : '上周'}}积分排行榜
        </span>
    </div>
</el-dialog>
</template>

<script>
import pointService from '#/js/service/pointService.js';
import moment from 'moment';
export default {
    data() {
        return {
            dialogVisible: false,
            listLoading: false,
            rankType: 0,
            rankDateType: 0,
            isTeam: true,
            rankTime: "",
            rankList: [],
            rank: "-",
            point: "-"
        }
    },
    methods: {
        show(rankDateType) {
            this.rankDateType = rankDateType;
            this.dialogVisible = true;
            this.getRankList();
            localStorage.setItem('pointRankListDate', moment().day(1).format('YYYYMMDD'));
        },
        getRankList() {
            let params = {
                rankType: this.rankType,
                rankDateType: this.rankDateType,
                isTeam: this.isTeam
            }
            this.listLoading = true;
            pointService.getPointRankList(params).then(res => {
                this.rank = res.rank;
                this.point = res.point;
                this.rankList = res.rankListItems;
                this.rankTime = moment(res.startDate).format('MM.DD') + '~' + moment(res.endDate).format('MM.DD');
                this.$nextTick(() => {
                    this.$el.querySelector('.point-rank-list-wrapper').scrollTop = 0;
                })
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.listLoading = false;
            });
        },
        handleRankType(rankType) {
            this.rankType = rankType;
            this.getRankList();
        },
        handleTeamType(isTeam) {
            this.isTeam = isTeam;
            this.getRankList();
        },
        handleRankDateType() {
            this.rankDateType = this.rankDateType === 1 ? 0 : 1;
            this.getRankList();
        },
        hideDialog() {
            this.dialogVisible = false;
            this.rankType = 0;
            this.rankDateType = 0;
            // this.isTeam = false;
            this.rankTime = "";
            this.rankList = [];
            this.rank = "-";
            this.point = "-";
        }
    }
}
</script>

<style lang="scss" scope>
.point-rank-dialog.el-dialog {
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 0;
    }
    .point-rank-wrapper {
        position: relative;
        width: 956px;
        height: 620px;
        border: 5px solid #FCC539;
        border-radius: 4px;
        background-color: #fff;
        .hide-dialog-btn {
            width: 18px;
            height: 18px;
            color: #666;
            border-radius: 9px;
            border: 1px solid #666;
            position: absolute;
            right: 14px;
            top: 14px;
            line-height: 18px;
            cursor: pointer;
            &::before {
                content: "×";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover {
                color: #fff;
                background-color: #e95352;
                border-color: #e95352;
            }
        }
        .point-rank-type {
            position: absolute;
            top: -45px;
            left: 0;
            .rank-type-item {
                display: inline-block;
                height: 40px;
                width: 120px;
                line-height: 40px;
                background-color: #fff;
                position: relative;
                z-index: 1;
                font-size: 18px;
                color: #666;
                text-align: center;
                border: 4px solid #ddd;
                border-bottom: none;
                border-radius: 24px 24px 0 0;
                cursor: pointer;
                &.active {
                    border-color: #FCC539;
                    z-index: 2;
                    color: #FCC539;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                }
                &:nth-last-of-type(1) {
                    left: -4px;
                }
            }
        }
        .team-btn-list {
            position: absolute;
            right: 32px;
            top: 40px;
            .team-btn-item {
                line-height: 25px;
                font-size: 16px;
                color: #999;
                cursor: pointer;
                display: inline-block;
                &:nth-last-of-type(1) {
                    margin-left: 10px;
                }
                &.active {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                }
            }
        }
        .point-rank {
            width: 100%;
            height: 100%;
            border: 2px solid #EEB31C;
            border-radius: 2px;
            border-radius: 2px;
            background: url("~@src/assets/images/point/rank-image.png") no-repeat bottom;
            background-size: contain;
            text-align: center;
            .point-rank-title {
                margin: 46px auto 38px;
                height: 40px;
                width: auto;
                display: inline-block;
                line-height: 36px;
                font-size: 30px;
                color: #444;
                border-bottom: 4px double #444;
            }
            .point-rank-time {
                position: absolute;
                left: 406px;
                top: 100px;
                line-height: 18px;
                font-size: 12px;
                color: #444;
            }
            .point-rank-list-wrapper {
                width: 520px;
                height: 425px;
                margin: 0 auto;
                margin-bottom: 20px;
                overflow: hidden;
                overflow-y: auto;
                border: 2px solid #F8F8F8;
                border-top: 4px solid #fcc539;
                box-shadow: 0 6px 13px 0 rgba(161,116,0,0.13);
                border-radius: 2px;
                border-radius: 2px;
                background-color: #fff;
                .point-rank-item {
                    width: 100%;
                    height: 70px;
                    padding: 0 22px 0 35px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    &:nth-of-type(2n) {
                        background-color: #f8f8f8;
                    }
                    .rank-result {
                        width: 42px;
                        height: 34px;
                        margin-right: 58px;
                        line-height: 34px;
                        text-align: center;
                        color: #EEB31C;
                        &.rank-result-0 {
                            background: url("~@src/assets/images/point/pic_no1.png") no-repeat center;
                        }
                        &.rank-result-1 {
                            background: url("~@src/assets/images/point/pic_no2.png") no-repeat center;
                        }
                        &.rank-result-2 {
                            background: url("~@src/assets/images/point/pic_no3.png") no-repeat center;
                        }
                    }
                    .rank-user-info {
                        width: 230px;
                        margin-right: 10px;
                        color: #444;
                        text-align: left;
                        display: inline-flex;
                        align-items: center;
                        .rank-user-avatar {
                            margin-right: 16px;
                            border: 1px solid #999;
                            width: 34px;
                            height: 34px;
                            border-radius: 50%;
                        }
                        .rank-user-name {
                            display: inline-block;
                            max-width: 180px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .rank-point {
                        display: inline-block;
                        width: 100px;
                        text-align: right;
                        color: #F98F33;
                    }
                }
            }
            .point-rank-list-footer {
                line-height: 22px;
                margin: 0 auto;
                font-size: 16px;
                color: #444;
                .text-orange {
                    color: #F98F33;
                    font-weight: bold;
                }
                .my-rank-result {
                    margin-right: 25px;
                }
                .icon-question {
                    margin-left: 8px;
                    width: 18px;
                    height: 18px;
                    display: inline-block;
                    .question-hover, .question-no-hover {
                        width: 100%;
                        height: 100%;
                        min-width: unset;
                        min-height: unset;
                    }
                    .question-hover {
                        display: none;
                    }
                    &:hover{
                        .question-hover{
                            display: inline-block;
                        }
                        .question-no-hover{
                            display: none;
                        }
                    }
                }
            }
        }
        .last-week-btn {
            position: absolute;
            right: 34px;
            bottom: 18px;
        }
    }
}
</style>

<style lang="scss">
.average-point-tip.el-tooltip__popper.is-light {
    margin-left: 0 !important;
    margin-top: 6px;
}
</style>
