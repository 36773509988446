<template>
    <div class="un-audit-customer-department-dialog">
        <el-dialog
            :visible.sync="unAuditCustomerDepartmentdialogVisible"
            width="748px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <template v-slot:title>
                <span class="title-tip">
                    <!-- 当前有 <span class="count">{{ auditList?.length }}</span> 条组织架构审批即将过期 请先处理 -->
                    当前有 <span class="count">{{ auditList?.length }}</span> 条组织架构审批未完成 请先处理
                </span>
            </template>
            <div class="content">
                <el-table
                    :data="auditList"
                    style="width: 100%"
                    tooltip-effect="light">
                    <el-table-column
                        prop="name"
                        :show-overflow-tooltip="true"
                        min-width="150"
                        label="部门名称"
                    ></el-table-column>
                    <el-table-column
                        prop="customerName"
                        min-width="150"
                        label="客户名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="creator"
                        :show-overflow-tooltip="true"
                        min-width="150"
                        label="申请人"
                    ></el-table-column>
                    <el-table-column
                        min-width="150"
                        label="申请时间"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.created ? scope.row.created.slice(0, 10) : ''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <span class="approve-btn" @click="handleApprove(scope.$index, scope.row)">审批</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <edit-department-dialog 
            ref="departmentDialog"
            @audit-resolved="handleAuditResolved"
            @audit-reject="handleAuditReject"
        ></edit-department-dialog>
        <audit-reject-dialog ref="auditRejectDialog" @audit-reject-done="handleAuditResolved"></audit-reject-dialog>
    </div>
</template>

<script>
import EditDepartmentDialog from '#/views/customers/component/edit-department-dialog.vue';
import AuditRejectDialog from '#/views/department-create/components/audit-reject-dialog.vue';

export default {
    components: {
        EditDepartmentDialog,
        AuditRejectDialog,
    },
    data() {
        return {
            unAuditCustomerDepartmentdialogVisible: false,
            auditList: [],
        };
    },

    mounted() {
        
    },

    methods: {
        show(auditList) {
            console.log(auditList);
            this.auditList = auditList;
            this.unAuditCustomerDepartmentdialogVisible = true;

        },
        handleClose() {
            this.unAuditCustomerDepartmentdialogVisible = false;
        },

        handleApprove(index, item) {
            console.log(item);
            this.$refs.departmentDialog.show(false, {
                index: index,
                id: item.id,
                customerId: item.customerName ? item.customerId : ''
            }, true);
        },
        handleAuditResolved(index) {
            // console.log(index);
            if(index !== '') {
                this.auditList.splice(index, 1);

                this.$emit('refresh-audit-list');
            }
        },
        handleAuditReject(index, id, customerId, groupVersion) {
            this.$refs.auditRejectDialog.show({index, id, customerId, groupVersion});

            this.$emit('refresh-audit-list');
        }
    },
};
</script>

<style lang="scss" scoped>
.un-audit-customer-department-dialog {
    .title-tip {
        font-size: 18px;
        color: #444;
        .count {
            color: #FF6564;
        }
    }

    .el-dialog__body {
        padding: 20px !important;
        .content {
            max-height: 500px;
            overflow-y: auto;
            /deep/ .el-table {
                border: 1px solid #D3D3D3;
                color: #666;
                thead.has-gutter tr th {
                    background-color: #F8F8F8;
                    border: none;
                    .cell {
                        font-weight: normal;
                    }
                }
                tbody tr td {
                    border: none;
                }

                // .el-table__row:nth-child(2n) {
                //     background-color: #F8F8F8;
                // }
                // .el-table__row:nth-child(2n+1) {
                //     background-color: #fff;
                // }
                .el-table__row:nth-child(even) { // 偶数
                    background-color: #F8F8F8;
                }
                .el-table__row:nth-child(odd) { // 奇数
                    background-color: #fff;
                }

                .approve-btn {
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>