<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="490px"
        height="850px"
        :close-on-press-escape="false"
        :before-close="handleClose"
        :append-to-body="true"
    >
        <div class="title" slot="title">
            <el-popover
                placement="bottom"
                width="104"
                trigger="hover"
                popper-class="share-popper"
                >
                <span slot="reference"><i class="el-icon-share"></i> 分享</span>
                <div id="qrcode" class="qr-code"></div>
            </el-popover>
        </div>
        <!-- <span>这是一段信息 周年庆</span> -->
        <div class="pic-container">
            <div class="pic-main" v-loading="loading">
                <div class="h1">
                    <img src="@src/assets/images/anniversary/share_title.png" alt="">
                </div>
                <div class="pre">Dear</div>
                
                <div class="date">Hi～今天是你加入我们 <span class="orange" v-text="annData.anniversary">3</span> 周年的日子，你于 <span class="orange" v-text="annData.joinFirmTime">2018年11月8日</span> 加入我们，转眼间，我们已经携手走过了 <span class="orange" v-text="annData.days">1068</span> 个日日夜夜。</div>

                <div class="bold">你做的每件事都被铭记</div>

                <div class="do-title">你在猎必得</div>

                <div class="do-item">
                    <img src="@src/assets/images/anniversary/create.png" alt="">
                    <span class="item-text">
                        创建了 <span class="green" v-text="annData.candidateCount">6788</span> 位人才
                    </span>
                </div>
                <div class="do-item">
                    <img src="@src/assets/images/anniversary/search_icon.png" alt="">
                    <span class="item-text">
                        寻访了 <span class="green" v-text="annData.inquiryLogCount">6788</span> 位人才
                    </span>
                </div>
                <div class="do-item">
                    <img src="@src/assets/images/anniversary/tel_icon.png" alt="">
                    <span class="item-text">
                        打了 <span class="green" v-text="annData.callRecordCount">6788</span> 分钟电话
                    </span>
                </div>
                <div class="do-item">
                    <img src="@src/assets/images/anniversary/recommend_icon.png" alt="">
                    <span class="item-text">
                        推荐了 <span class="green" v-text="annData.recommendationCount">6788</span> 个人才
                    </span>
                </div>
                <div class="do-item">
                    <img src="@src/assets/images/anniversary/offer.png" alt="">
                    <span class="item-text">
                        帮助客户和人才 <span class="green" v-text="annData.offerCount">6788</span> 个offer
                    </span>
                </div>
                
                <div class="tks-text">
                    感谢往日里你的辛勤付出，与公司一同成长、壮大。未来，让我们继续携手前行。<span class="orange">祝入职 {{annData.anniversary}} 周年快乐！</span>
                </div>

            </div>
            <div class="heart">
                <img src="@src/assets/images/anniversary/love_icon.png" alt="">
            </div>
            <div class="pic-footer">
                <img src="@src/assets/images/anniversary/logo.png" alt="">
            </div>
        </div>

    </el-dialog>
</template>

<script>
import moment from 'moment';
import QRCode from 'qrcodejs2';

import anniversaryService from '#/js/service/anniversaryService.js';
export default {
    name: 'AnniversaryDialog',
	components: {
	},
	data() {
		return {
            dialogVisible: false,
            annData: {}, // 周年数据
            loading: false,
        };
    },
    created() {
        // this.getData();
    },
	mounted() {
        this.qrCode();
	},
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        unionId() {
            return this.userInfo.unionId;
        },
        userId() {
            return this.userInfo.id;
        },
    },
    components: {
        QRCode,
    },
	methods: {
        show() {
          this.getData();
        },
        qrCode() {
            console.log(NODE_ENV)
            const env = ['development', 'rc', 'staging'].includes(NODE_ENV) ? 'stg': 'pro';
            const url = env == 'stg' ? 'https://portal.stg.liebide.com' : 'https://portal.liebide.com';
            const text = `${url}?userid=${this.userId}&env=${env}`;
            console.log(text)
            let qrcode = new QRCode('qrcode', {
                text: text,
                width: 120,
                height: 120,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H
            });
        },
        getData() {
            this.loading = true;
            anniversaryService.annualUserData(this.userId).then(res => {
                this.annData = {
                    ...res,
                    joinFirmTime: moment(res.joinFirmTime).format(`YYYY年MM月DD日`),
                    days: moment().diff(moment(res.joinFirmTime), 'days'),
                    callRecordCount: parseInt(res.callRecordCount/60),
                };
                // 如果anniversary>0才显示
                if(this.annData.anniversary > 0) {
                  this.dialogVisible = true;
                }
            }).finally(() => {
                this.loading = false;
            });
            // weixin://dl/business/?t=KnAdLyRHAUh
        },
        getImg() {
            const id = `wx45bf9eea65fde39e`,
                secret = `7c04e42716a832ade136dd1e86ecaec2`;
            // axios({
            //     method: 'GET',
            //     url: `https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=${id}&secret=${secret}`
            // }).then(res => {
            // }).catch(e => {
            //     console.log(e)
            // })
        },
        handleClose() {
            const _year = moment().format('YYYY');
            localStorage.setItem('anniversaryShowed', _year);
            this.dialogVisible = false;
        },
    },
}
</script>
<style>

</style>
<style lang="scss" scope>
.pic-container{
    width: 450px;
    height: 800px;
    background: #38BC9D;
    overflow: hidden;
    position: relative;
    
    .pic-main{
        width: 413px;
        height: 707px;
        background: url("~@src/assets/images/anniversary/share_bg.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 23px;
        margin-top: 24px;
        box-sizing: border-box;
        padding: 50px 30px 0 15px;
        .h1{
            text-align: center;
            >img{
                width: 332px;
                height: 49px;
            }
        }
        .pre{
            font-size: 16px;
            line-height: 32px;
            color: #116450;
            // font-weight: bold;
        }
        .date{
            font-size: 14px;
            // font-weight: bold;
            line-height: 31px;
            color: #116450;
        }
        .bold{
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 30px;
        }
        .do-title{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 44px;
        }
        .do-item{
            font-size: 14px;
            color: #666;
            line-height: 42px;
            >img{
                width: 22px;
                height: 24px;
                margin-right: 15px;
            }
        }
        .tks-text{
            font-size: 14px;
            color: #666;
            line-height: 26px;
            margin-top: 30px;
        }
        .orange{
            color: #F98F33;
            font-size: 24px;
        }
        .green{
            color: #38BC9D;
            font-size: 24px;
        }
        
    }
    .heart{
        width: 76px;
        height: 140px;
        position: absolute;
        right: 0;
        top: 240px;
    }
    .pic-footer{
        text-align: center;
        margin-top: 15px;
        >img{
            width: 134px;
            height: 20px;
        }

    }
}

.share-popper{
    // .qr-code{
    //     width: 80px;
    //     height: 80px;
        >img{
            width: 80px;
            height: 80px;
            background: red;
        }
    // }
    .qr-code{
        width: 120px;
        height: 120px;
    }
}
</style>