var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "system-notice-wrapper" }, [
    _vm.payload.ext.type == "newRecommend" ||
    _vm.payload.ext.type == "recommendStatusUpdate"
      ? _c(
          "a",
          {
            attrs: { href: _vm.recommendUrl, target: "_blank" },
            on: { click: _vm.goToLink },
          },
          [
            _c("div", { staticClass: "system-notice" }, [
              _c("h3", [_vm._v(_vm._s(_vm.titleMap[_vm.payload.ext.type]))]),
              _c("div", { staticClass: "user-info" }, [
                _c("img", {
                  attrs: {
                    src: `${_vm.avatarBaseUrl}/user/${_vm.noticeData.operatorId}`,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.handleCurrentConversation.apply(
                        null,
                        arguments
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "user-info-text" }, [
                  _vm.payload.ext.type == "newRecommend"
                    ? _c("p", [
                        _vm._v(
                          _vm._s(_vm.noticeData.operatorName) +
                            " 给你推荐了一份简历"
                        ),
                      ])
                    : _vm._e(),
                  _vm.payload.ext.type == "recommendStatusUpdate"
                    ? _c("p", [
                        _vm._v(
                          _vm._s(_vm.noticeData.operatorName) +
                            " 反馈了你的推荐"
                        ),
                      ])
                    : _vm._e(),
                  _vm.payload.ext.type == "newRecommend"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("fullDate")(
                              new Date(_vm.noticeData.recommendDate)
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm.payload.ext.type == "recommendStatusUpdate"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("fullDate")(
                              new Date(_vm.noticeData.flowUpdateDate)
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("p", { staticClass: "recommend-info candidate" }, [
                _vm._v(
                  "\n                候选人：" +
                    _vm._s(_vm.noticeData.candidateName) +
                    " [" +
                    _vm._s(_vm.noticeData.title) +
                    "]\n            "
                ),
              ]),
              _vm.payload.ext.type == "recommendStatusUpdate"
                ? _c("p", { staticClass: "recommend-info candidate" }, [
                    _vm._v(
                      "\n                推荐状态：" +
                        _vm._s(
                          _vm._f("recommentStatusText")(_vm.noticeData.status)
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "recommend-info" }, [
                _vm._v(
                  "\n                推荐职位：" +
                    _vm._s(_vm.noticeData.jobName) +
                    " [" +
                    _vm._s(_vm.noticeData.customerName) +
                    "]\n            "
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.payload.ext.type == "newJob"
      ? _c("div", { staticClass: "system-notice" }, [
          _c("h3", [_vm._v(_vm._s(_vm.titleMap[_vm.payload.ext.type]))]),
          _c("p", { staticClass: "recommend-info" }, [
            _vm._v(
              "\n            昨日系统新增了 " +
                _vm._s(_vm.noticeData.newJobNumber) +
                " 个新职位 \n            "
            ),
            _c(
              "a",
              {
                attrs: { href: `/#/jobList${_vm.jobDate}`, target: "_blank" },
                on: { click: _vm.goToLink },
              },
              [_vm._v("查看详情")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.payload.ext.type == "recommendNotProcess"
      ? _c("div", { staticClass: "system-notice" }, [
          _c("h3", [_vm._v(_vm._s(_vm.titleMap[_vm.payload.ext.type]))]),
          _c("p", { staticClass: "recommend-info" }, [
            _vm._v(
              "\n            本月有 " +
                _vm._s(_vm.noticeData.recommendNotProcess) +
                " 位推荐给你的候选人还未处理，请尽快处理 \n            "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "/Headhunting/#/floatingList?status=0&time=1",
                  target: "_blank",
                },
                on: { click: _vm.goToLink },
              },
              [_vm._v("立即处理")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.payload.ext.type == "kickedGroup"
      ? _c("div", { staticClass: "system-notice" }, [
          _c("h3", [_vm._v(_vm._s(_vm.titleMap[_vm.payload.ext.type]))]),
          _c("p", { staticClass: "recommend-info" }, [
            _vm._v(
              "\n            你被群主踢出群聊 " +
                _vm._s(_vm.payload.ext.data.groupName) +
                "\n        "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.payload.ext.type == "visibleLink"
      ? _c(
          "div",
          {
            staticClass: "system-notice visible-link",
            class:
              _vm.payload.ext.data.button && _vm.payload.ext.data.url
                ? ""
                : "visible-link-no-btn",
          },
          [
            _vm.payload.ext.data.title
              ? _c("h3", { staticClass: "visible-link-title" }, [
                  _vm._v(_vm._s(_vm.payload.ext.data.title)),
                ])
              : _vm._e(),
            _vm.payload.ext.data.content
              ? _c(
                  "p",
                  { staticClass: "recommend-info visible-link-content" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.payload.ext.data.content) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.payload.ext.data.button &&
            _vm.payload.ext.data.url &&
            _vm.payload.ext.data.url.indexOf("departmentCreateRequestDeny") ==
              -1
              ? _c(
                  "a",
                  {
                    staticClass: "visible-link-button",
                    attrs: { href: _vm.payload.ext.data.url, target: "_blank" },
                    on: { click: _vm.goToLink },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.payload.ext.data.button) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.payload.ext.data.button &&
            _vm.payload.ext.data.url &&
            _vm.payload.ext.data.url.indexOf("departmentCreateRequestDeny") > -1
              ? _c(
                  "a",
                  {
                    staticClass: "visible-link-button",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.showAuditDenyDetail(_vm.payload.ext.data.url)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.payload.ext.data.button) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }