<template>
  <div :class="isFold ? 'sidebar-wrap is-fold' : 'sidebar-wrap'">
    <!-- <div class="sidebar-logo"></div> -->
    <ul v-if="!isFold" class="sidebar-list">
      <li
        :class="item.isActive ? 'sidebar-item active' : 'sidebar-item'"
        v-for="(item, index) in currentSideBar"
        :key="index"
        v-show="item.isShow"
      >
        <div class="sidebar-item-text" @click="handleSidebarItem(item, index)">
          <span class="sidebar-icon">
            <font-icon :href="`#${item.isActive ? item.activeIcon : item.normalIcon}`"></font-icon>
          </span>
          <span>{{ item.text }}</span>
          <i v-if="item.shortcut && item.shortcut.length > 0" class="el-icon-arrow-right" :class="item.isExpand ? 'expanded' : ''"></i>
        </div>
        <ul class="sidebar-menu" v-show="item.isExpand">
          <li class="sidebar-menu-item"
            v-for="(shortcut, shortcutIndex) in item.shortcut"
            :key="shortcutIndex"
            :class="shortcut.path == currentPath ? 'active' : ''"
            v-show="shortcut.isShow"
            @click="handleShortcut(item, shortcut)"
          >{{ shortcut.cnTitle }}</li>
        </ul>
        
      </li>
    </ul>
    <ul v-else class="fold-sidebar-list">
      <li
        :class="item.isActive ? 'fold-sidebar-list-item active' : 'fold-sidebar-list-item'"
        v-for="(item, index) in currentSideBar"
        :key="index"
        v-show="item.isShow"
      >
        <el-popover
          :popper-class="getPopoverClass(item)"
          v-if="item.shortcut.length>0"
          placement="right-start"
          trigger="hover">
          <ul class="sidebar-menu">
            <li class="sidebar-menu-item"
              v-for="(shortcut, shortcutIndex) in item.shortcut"
              :key="shortcutIndex"
              v-show="shortcut.isShow"
              @click="handleShortcut(item, shortcut)"
            >{{ shortcut.cnTitle }}</li>
          </ul>
          <el-tooltip
            class="item"
            :popper-class="`sidebar-title sidebar-title-${item.name}`"
            :content="item.text"
            placement="bottom-start"
            :disabled="!isFold"
            effect="light"
            :visible-arrow="false"
            slot="reference">
            <span class="el-dropdown-link">
              <a
                class="sidebar-link"
                :href="item.link"
                :target="item.link == 'javascript:;' ? '_self' : '_blank'"
              >
                <span class="sidebar-icon">
                  <font-icon :href="`#${item.isActive ? item.activeIcon : item.normalIcon}`"></font-icon>
                </span>
              </a>
            </span>
          </el-tooltip>
        </el-popover>
        <el-tooltip
          class="item"
          popper-class="sidebar-title"
          :content="item.text"
          placement="bottom-start"
          :disabled="!isFold"
          effect="light"
          :visible-arrow="false"
          v-else>
            <span class="sidebar-link">
              <span class="sidebar-icon" @click="handleSidebarItem(item, index)">
                <font-icon class="item-icon" :href="`#${item.isActive ? item.activeIcon : item.normalIcon}`"></font-icon>
              </span>
            </span>
        </el-tooltip>
      </li>
    </ul>
    <!-- <a 
      class="customer-service-btn" 
      href="https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=K21oHxh2D8" 
      target="_blank" 
    > -->
    <!-- <div 
      class="customer-service-btn" 
      @mouseover="showQR = true" 
      @mouseleave="showQR = false"
    >
      <img :src="require('#/assets/images/customer_service.png')" />
      <span>联系客服</span>
    </div>
    <img v-show="showQR" class="service-qr-code" :src="require('#/assets/images/service-qr-code.png')" alt=""> -->
    <!-- icon-cebianshouqi1 -->
    <div class="copy-right" v-if="!isFold">
        <span class="copy-txt">© 2020-2024 liebide.com</span>
    </div>
    <a
      class="sidebar-btn expand-btn"
      href="javascript:;"
      @click="handleFold"
    >
        <font-icon
            v-if="!isFold"
            class="expand-icon"
            href="#icon-cebianshouqi"
        ></font-icon>
        <font-icon
            v-else
            class="expand-icon"
            href="#icon-cebianshouqi1"
        ></font-icon>
    </a>
  </div>
</template>
<script>
import { study } from "#/js/config/api.json";
import { mapActions } from 'vuex';
export default {
  name: "sidebar",
  components: {
  },
  data() {
    return {
      showQR: false,

      isFold: false,
      sidebar: [
        {
            isActive: true,
            text: "工作台",
            name: "workbench",
            activeIcon: "icon-shouye",
            normalIcon: "icon-shouye",
            link: "/Headhunting",
            isShow: true,
            isExpand: true,
        //   shortcut: []
        // 我的人才池 人才跟进 流程管理 我的订单
            shortcut: [
                {
                enable: true,
                cnTitle: "我的人才池",
                enTitle: "TalentPool",
                noticeData:null,
                path:'/talentPool',
                icon:'',
                isShow: true,
                aliveComponent: 'TalentPool'
                },
                {
                enable: true,
                cnTitle: "人才跟进",
                enTitle: "TrackingList",
                noticeData:null,
                path:'/trackingList',
                icon:'',
                isShow: true,
                aliveComponent: 'TrackingList'
                },
                {
                enable: true,
                cnTitle: "流程管理",
                enTitle: "FloatingList",
                noticeData:null,
                path:'/floatingList/myRecommend',
                icon:'',
                isShow: true,
                aliveComponent: 'FloatingListMyRecommend'
                },
                {
                enable: true,
                cnTitle: "我的订单",
                enTitle: "MyOrders",
                noticeData:null,
                path:'/myOrders/grabbedJob',
                icon:'',
                isShow: true,
                aliveComponent: 'MyOrdersGrabbedJob'
                },
            ]
        },
        {
          isActive: false,
          text: "业务",
          name: "business",
          activeIcon: "icon-work",
          normalIcon: "icon-work",
          link: "javascript:;",
          isShow: false,
          isExpand: false,
          shortcut: [
            // {
            //   enable: true,
            //   cnTitle: "人才-旧",
            //   enTitle: "talent",
            //   noticeData:null,
            //   path:'/Headhunting/MyCompany.html#/candidates/firmCandidate',
            //   icon:'',
            //   isShow: false,
            //   aliveComponent: ''
            // },
            {
              enable: true,
              cnTitle: "人才",
              enTitle: "Talent",
              noticeData:null,
              path:'/candidates/firmCandidate',
              icon:'',
              isShow: false,
              aliveComponent: 'FirmCandidate'
            },
            {
              enable: true,
              cnTitle: "职位搜索",
              enTitle: "JobList",
              noticeData:null,
              // path:'/Headhunting/MyCompany.html#/jobList',
              path:'/jobList',
              icon:'',
              isShow: false,
              // aliveComponent: ''
              aliveComponent: 'JobList'
            },
            {
              enable: true,
              cnTitle: "协议职位",
              enTitle: "JobList",
              noticeData:null,
              // path:'/Headhunting/MyCompany.html#/jobList',
              path:'/protocolJobList',
              icon:'',
              isShow: false,
              // aliveComponent: ''
              aliveComponent: 'JobList'
            },
            // {
            //   enable: true,
            //   cnTitle: "客户-旧",
            //   enTitle: "client",
            //   noticeData:null,
            //   path:'/Headhunting/MyCompany.html#/customerList',
            //   icon:'',
            //   isShow: false,
            //   aliveComponent: ''
            // },
            {
              enable: true,
              cnTitle: "客户",
              enTitle: "Customer",
              noticeData:null,
              // path:'/Headhunting/MyCompany.html#/customerList',
              path:'/customerList',
              icon:'',
              isShow: false,
              // aliveComponent: ''
              aliveComponent: 'CustomerList'
            },
            // {
            //   enable: true,
            //   cnTitle: "项目-旧",
            //   enTitle: "project",
            //   noticeData:null,
            //   path:'/Headhunting/MyCompany.html#/project',
            //   icon:'',
            //   isShow: false,
            //   aliveComponent: ''
            // },
            {
              enable: true,
              cnTitle: "项目",
              enTitle: "Project",
              noticeData:null,
              // path:'/Headhunting/MyCompany.html#/project',
              path:'/project',
              icon:'',
              isShow: false,
              // aliveComponent: ''
              aliveComponent: 'Project'
            },
            // {
            //   enable: true,
            //   cnTitle: "市场-旧",
            //   enTitle: "market",
            //   noticeData:null,
            //   path:'/Headhunting/MyCompany.html#/market',
            //   icon:'',
            //   isShow: false,
            //   aliveComponent: ''
            // },
            {
              enable: true,
              cnTitle: "市场",
              enTitle: "Market",
              noticeData:null,
              // path:'/Headhunting/MyCompany.html#/market',
              path:'/market',
              icon:'',
              isShow: false,
              // aliveComponent: ''
              aliveComponent: 'Market'
            },
            // {
            //   enable: true,
            //   cnTitle: "职通车",
            //   enTitle: "csdnJDT",
            //   noticeData:null,
            //   path:'/jdt',
            //   icon:'',
            //   isShow: false,
            //   aliveComponent: 'Jdt'
            // },
            // {
            //   enable: true,
            //   cnTitle: "旧-职通车",
            //   enTitle: "csdnJDT",
            //   noticeData:null,
            //   path:'/Headhunting/MyCompany.html#/jdt',
            //   icon:'',
            //   isShow: true,
            //   aliveComponent: ''
            // },
            {
              enable: true,
              cnTitle: "大厂机会",
              enTitle: "Opportunity",
              noticeData:null,
              path:'/corporationOpportunity',
              icon:'',
              isShow: false,
              aliveComponent: 'Opportunity'
            },
            {
              enable: true,
              cnTitle: "TSDN",
              enTitle: "",
              noticeData:null,
              path:'/tsdn',
              icon:'',
              isShow: false,
              aliveComponent: 'TSDN'
            },
          ]
        },
        {
            isActive:false,
            text:'数据',
            name: "data",
            activeIcon: "icon-paihangbang",
            normalIcon: "icon-paihangbang",
            link: "javascript:;",
            // isShow: true,
            isShow: false,
            isExpand: false,
            shortcut: 
          [
            {
                enable: true,
                enTitle:'Data',
                cnTitle:'个人数据',
                noticeData:null,
                path:'/data',
                icon:'',
                isShow: false,
                aliveComponent: 'Data'
            },
            {
                enable: true,
                enTitle:'Team Data',
                cnTitle:'团队数据',
                noticeData:null,
                path:'/teamData',
                icon:'',
                isShow: false,
                aliveComponent: 'TeamData'
            },
            {
                enable: true,
                enTitle:'Types Data',
                cnTitle:'赛道数据',
                noticeData:null,
                path:'/typesData',
                icon:'',
                isShow: false,
                aliveComponent: 'TypesData'
            },
            // {
            //     enable: true,
            //     enTitle:'Jdt Data',
            //     cnTitle:'职通车数据',
            //     noticeData:null,
            //     path:'/jdtData',
            //     icon:'',
            //     isShow: false,
            //     aliveComponent: 'JdtData'
            // }
          ]
        },
        {
          isActive: false,
          text: "猎企设置",
          name: "companyManagement",
          activeIcon: "icon-shangquan",
          normalIcon: "icon-shangquan",
          link: "javascript:;",
          isShow: false,
          isExpand: false,
          shortcut: [
            {
                enable: true,
                enTitle:'Member',
                cnTitle:'成员',
                noticeData: null,
                path: "/member",
                icon: '',
                isShow: false,
                aliveComponent: 'Member'
            },
            {
                enable: true,
                enTitle:'PerformanceSet',
                cnTitle:'业绩设置',
                noticeData:null,
                path:'/performanceSet',
                icon:'',
                isShow: false,
                aliveComponent: 'PerformanceSet'
            },
            {
                enable: true,
                enTitle:'PointSet',
                cnTitle:'积分设置',
                noticeData: null,
                path: '/pointSetting/point',
                icon: '',
                isShow: false,
                aliveComponent: 'PointSettingPrize'
            },
            {
                enable: true,
                enTitle:'Merge',
                cnTitle:'简历设置',
                noticeData: null,
                path: '/resumeMerge',
                icon: '',
                isShow: false,
                aliveComponent: 'ResumeMerge'
            },
            {
                enable: true,
                enTitle:'Authorize',
                cnTitle:'授权设置',
                noticeData: null,
                path: '/authorization',
                icon: '',
                isShow: false,
                aliveComponent: 'Authorization'
            },
            {
              enable: true,
              cnTitle: "大厂机会审批",
              enTitle: "AuditOpportunity",
              noticeData:null,
              path:'/auditOpportunity',
              icon:'',
              isShow: false,
              aliveComponent: 'AuditOpportunity'
            },
            {
              enable: true,
              cnTitle: "Offer播报",
              enTitle: "",
              noticeData:null,
              path:'/offerBroadcast',
              icon:'',
              isShow: false,
              aliveComponent: 'OfferBroadcast'
            },
            // {
            //     enable: true,
            //     enTitle:'JdtSet',
            //     cnTitle:'职通车设置',
            //     noticeData: null,
            //     path: '/jdtSet',
            //     icon: '',
            //     isShow: false,
            //     aliveComponent: 'JdtSet'
            // },
            {
                enable: true,
                enTitle:'JobSet',
                cnTitle:'职位类别设置',
                noticeData: null,
                path: '/jobSet',
                icon: '',
                isShow: false,
                aliveComponent: 'JobSet'
            },
            // {
            //     enable: true,
            //     enTitle:'UserPermissionSet',
            //     cnTitle:'用户权限设置',
            //     noticeData: null,
            //     path: '/orgAuditSet',
            //     icon: '',
            //     isShow: false,
            //     aliveComponent: 'OrgAuditSet'
            // }
            {
                enable: true,
                enTitle:'UserPermissionSet',
                cnTitle:'用户权限设置',
                noticeData: null,
                path: '/rolePermissionSet',
                icon: '',
                isShow: false,
                // isShow: true,
                aliveComponent: 'RolePermissionSet'
            },
            {
                enable: true,
                enTitle:'BusinessClassificationSet',
                cnTitle:'业务分类设置',
                noticeData: null,
                path: '/businessClassification',
                icon: '',
                isShow: false,
                aliveComponent: 'BusinessClassificationSet'
            },
            {
                enable: true,
                enTitle:'CompanySet',
                cnTitle:'企业设置',
                noticeData: null,
                path: '/companySetting',
                icon: '',
                isShow: false,
                aliveComponent: 'CompanySetting'
            },
            {
                enable: true,
                enTitle:'EnterpriseWechatSet',
                cnTitle:'企业微信设置',
                noticeData: null,
                path: '/enterpriseWechatSet',
                icon: '',
                isShow: false,
                aliveComponent: 'EnterpriseWechatSet'
            },
            
            // {
            //     enable: true,
            //     enTitle:'CompanySet',
            //     cnTitle:'旧-企业设置',
            //     noticeData: null,
            //     path: '/Headhunting/MyCompany.html#/companySetting',
            //     icon: '',
            //     isShow: true,
            //     aliveComponent: ''
            // },
            // {
            //     enable: true,
            //     enTitle:'Authorize',
            //     cnTitle:'旧-授权设置',
            //     noticeData: null,
            //     path: '/Headhunting/MyCompany.html#/authorization',
            //     icon: '',
            //     isShow: true,
            //     aliveComponent: ''
            // },
          ]
        },
        {
          isActive: false,
          text: "运营平台",
          name: "operationPlatform",
          activeIcon: "icon-chart-bar",
          normalIcon: "icon-chart-bar",
          link: "/operationPlatform",
          isShow: false,
          shortcut: [],
        },
      ],
      lastActiveItem: 'workbench'
    };
  },
  watch: {
    userInfo: function(val, oldVal) {
      if(val) {
        // 管理员
        if(val.isAdministrator) {
          this.sidebar[1].isShow = true;
          this.sidebar[1].shortcut[0].isShow = true;
          this.sidebar[1].shortcut[1].isShow = true;
        //   this.sidebar[1].shortcut[2].isShow = true;
          this.sidebar[1].shortcut[3].isShow = true;
          this.sidebar[1].shortcut[4].isShow = true;
          this.sidebar[1].shortcut[5].isShow = true;
        //   this.sidebar[1].shortcut[6].isShow = true;
        //   this.sidebar[1].shortcut[7].isShow = true;

          this.sidebar[2].isShow = true;
          this.sidebar[2].shortcut[0].isShow = true;
          this.sidebar[2].shortcut[1].isShow = true;
          this.sidebar[2].shortcut[2].isShow = true;
          
          this.sidebar[3].isShow = true;
          this.sidebar[3].shortcut[3].isShow = true;
          if(val.isCFUser){
            // this.sidebar[1].shortcut[2].isShow = true;
            
            this.sidebar[3].shortcut[4].isShow = true;
            // this.sidebar[3].shortcut[5].isShow = true;
            // this.sidebar[3].shortcut[6].isShow = true;
          }
          this.sidebar[3].shortcut[7].isShow = true;
          this.sidebar[3].shortcut[8].isShow = true;
          if(val.isCFUser){
            this.sidebar[3].shortcut[9].isShow = true;
          }
          this.sidebar[3].shortcut[11].isShow = true;
          // this.sidebar[4].isShow = true;
        //   this.sidebar[5].isShow = true;

          // this.sidebar[8].shortcut[0].isShow = true;
          // this.sidebar[8].shortcut[1].isShow = true;
          // this.sidebar[8].shortcut[2].isShow = true;
          // this.sidebar[8].shortcut[4].isShow = true;
          // this.sidebar[8].shortcut[5].isShow = true;
          if(val.isCFUser) {
            if(!val.tsdnuser) {
                this.sidebar[1].shortcut[5].isShow = true;
            }
            
            for(let i = 0; i < 3; i++) {
              this.sidebar[3].shortcut[i].isShow = true;
            }
          }
        }

        // tsdnuser用户
        if(val.tsdnuser) {
          this.sidebar[1].shortcut[7].isShow = true;
        }
        
        // Leader
        if(val.isAdminOrTeamLeader) {
            this.sidebar[2].isShow = true;
            this.sidebar[2].shortcut[1].isShow = true;
            // if(val.isCFUser){
            //     this.sidebar[2].shortcut[3].isShow = true;
            // }
        }
        // if(val.isAdminOrTeamLeader && val.isCFUser) {
        //   this.sidebar[5].shortcut[3].isShow = true;
        // }

        // if(val.isMarketWhiteList) {
        //   this.sidebar[9].isShow = true;
        // }
        // 是否拥有授权设置的权限
        // if(val.canAuthorizeCandidate) {
        //     this.sidebar[3].shortcut[3].isShow = true;
        // }

        // 判断当前用户所处角色拥有的权限
        if(val.privilegeCodeList){
            val.privilegeCodeList.forEach( el => {
            //   if(el=='Business'){
                // CompanyOpportunity
              if(['Talent', 'Job','AgreementJob', 'Customer', 'Project', 'Market', 'CompanyOpportunity', 'TSDN'].includes(el)){
                  this.sidebar[1].isShow = true;
                  if(el=='Talent') {
                      this.sidebar[1].shortcut[0].isShow = true;
                  } else if(el=='Job'){
                      this.sidebar[1].shortcut[1].isShow = true;
                  } else if(el=='AgreementJob'){
                      this.sidebar[1].shortcut[2].isShow = true;
                  } else if(el=='Customer'){
                      this.sidebar[1].shortcut[3].isShow = true;
                  } else if(el=='Project'){
                      this.sidebar[1].shortcut[4].isShow = true;
                  } else if(el=='Market'){
                    this.sidebar[1].shortcut[5].isShow = true;
                  } else if(el=='CompanyOpportunity' && val.isCFUser && !val.tsdnuser){
                    this.sidebar[1].shortcut[6].isShow = true;
                  } else if(el=='TSDN'){
                    this.sidebar[1].shortcut[7].isShow = true;
                  }

                //   this.sidebar[1].shortcut[0].isShow = true;
                //   this.sidebar[1].shortcut[1].isShow = true;
                //     this.sidebar[1].shortcut[6].isShow = true;
                //   }

                //   this.sidebar[1].shortcut[0].isShow = true;
                //   this.sidebar[1].shortcut[1].isShow = true;
                //   this.sidebar[1].shortcut[2].isShow = true;
                //   this.sidebar[1].shortcut[3].isShow = true;
                
                //   if(val.isMarketWhiteList) {
                //       this.sidebar[1].isShow = true;
                //       this.sidebar[1].shortcut[4].isShow = true;
                //   }

                //   if(val.isCFUser) {
                //     this.sidebar[1].shortcut[5].isShow = true;
                //   }
                
              } else if(['PersonalData', 'TeamData', 'TrackData', 'LimitedTeamData'].includes(el)){
                  this.sidebar[2].isShow = true;
                  if(el=='PersonalData') {
                      this.sidebar[2].shortcut[0].isShow = true;
                  } else if(el == 'TeamData' || el == 'LimitedTeamData'){
                      this.sidebar[2].shortcut[1].isShow = true;
                  } else if(el=='TrackData'){
                      this.sidebar[2].shortcut[2].isShow = true;
                  }
              } 
            //   else if(el=='Member'){
            //       this.sidebar[4].isShow = true;
            //   } 
            //   else if(el=='Financial'){
                
            //   } else if(el=='HuntingCompanySet'){
            //   } 
              else if(['PerformanceSet', 'PointSet', 'ResumeSet','AuthorizationSet', 'CompanyOpportunityAuditSet', 'OfferBroadcastSet', 'JobCategorySet', 'UserPermissionSet', 'BusinessClassifySet', 'FirmSet', 'WeComSet', 'Member',].includes(el)){
                  this.sidebar[3].isShow = true;
                  if(el=='Member') {
                      this.sidebar[3].shortcut[0].isShow = true;
                  } else if(el=='PerformanceSet' && val.isCFUser) {
                      this.sidebar[3].shortcut[1].isShow = true;
                  } else if(el=='PointSet' && val.isCFUser){
                      this.sidebar[3].shortcut[2].isShow = true;
                  } else if(el=='ResumeSet'){
                      this.sidebar[3].shortcut[3].isShow = true;
                  } else if(el=='AuthorizationSet' && val.isCFUser){
                      this.sidebar[3].shortcut[4].isShow = true;
                  } else if(el=='CompanyOpportunityAuditSet' && val.isCFUser){
                      this.sidebar[3].shortcut[5].isShow = true;
                  } else if(el=='OfferBroadcastSet' && val.isCFUser){
                      this.sidebar[3].shortcut[6].isShow = true;
                  } else if(el=='JobCategorySet'){
                      this.sidebar[3].shortcut[7].isShow = true;
                  } else if(el=='UserPermissionSet'){
                      this.sidebar[3].shortcut[8].isShow = true;
                  } else if(el=='BusinessClassifySet' && val.isCFUser){
                      this.sidebar[3].shortcut[9].isShow = true;
                  } else if(el=='FirmSet'){
                      this.sidebar[3].shortcut[10].isShow = true;
                  } else if(el=='WeComSet'){    
                      this.sidebar[3].shortcut[11].isShow = true;
                  }
                  
                //   this.sidebar[3].shortcut[7].isShow = true;
                //   this.sidebar[3].shortcut[8].isShow = true;
                //   if(val.isCFUser) {
                //     //   this.sidebar[3].shortcut[6].isShow = true;
                //       this.sidebar[3].shortcut[9].isShow = true;
                //       for(let i = 0; i < 3; i++) {
                //           this.sidebar[3].shortcut[i].isShow = true;
                //       }
                //   }
              } else if(el=='PlatformOperations') { //平台运营
                  this.sidebar[4].isShow = true;
              } 
            //   else if(el=='TeamData') {
            //     if(val.isCFUser || val.tsdnuser) {
            //       this.sidebar[2].isShow = true;
            //       this.sidebar[2].shortcut[1].isShow = true;
            //     }
            //   } else if(el == 'AgreementJob') {
            //     this.sidebar[1].shortcut[2].isShow = true;
            //   }
          })
        }

        // 内控才有大厂机会审批和offer播报的权限
        // if(val.isCFUser && val.privilegeCodeList?.includes('CompanyOpportunityManage')) {
        //     this.sidebar[3].shortcut[4].isShow = true;
        //     this.sidebar[3].shortcut[5].isShow = true;
        // }
        // HRBP有团队数据权限
        if(val.roleCodeList?.includes('BRHRBP') || val.roleCodeList?.includes('LRHRBP')) {
            this.sidebar[2].isShow = true;
            this.sidebar[2].shortcut[1].isShow = true;
        }
      }
    },
    '$route.path': function(val, oldVal) {
        let activeIndex = 0;
        if(val == '/jobList') {
            activeIndex = 1;
        }
        if(val == '/data' || val == '/teamData' || val == '/typesData' || val == '/jdtData') {
            activeIndex = 2;
        }
        if(val == '/performanceSet' || val == '/pointSetting/prize' || val == '/resumeMerge' || val == '/jdtSet' || val == '/jobSet' || val == '/rolePermissionSet' || val == '/companySetting' || val == '/enterpriseWechatSet') {
            activeIndex = 3;
        }
        // if(val == '/member') {
            // activeIndex = 4;
        // }
        //   if(val == '/operationPlatform') {
        if(val.includes('/operationPlatform')) {
            activeIndex = 6;
        }
        this.sidebar.forEach((item, index) => {
            if (index == activeIndex) {
                item.isActive = true;
            } else {
                item.isActive = false;
            }
        });
    }
  },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isFinancial () {
            return this.userInfo.privilegeCodeList?.includes('Financial');
        },
        candidateListUrl() {
            //旧版人才库页面已废弃
            return `/Headhunting/MyCompany.html#${this.$store.state.candidateListUrl.candidateListUrl}`;
        },
        currentSideBar() {
            if(this.userInfo.isCFUser == false) {
                const _idx = this.sidebar.findIndex(item => item.name == 'csdnJDT');
                if(_idx >= 0) this.sidebar.splice(_idx, 1);
            }
            return this.sidebar;
        },
        currentPath() {
            return this.$route.path;
        },
        isTsdnuser(){
            return this.$store.state.user.userInfo.tsdnuser;
        }, 
    },
    mounted() {
        // this.checkLoveStudy();
        let isFoldSiderbar = localStorage.getItem('isFoldSiderbar') ? JSON.parse(localStorage.getItem('isFoldSiderbar')) : false;
        this.isFold = isFoldSiderbar;
    },
  methods: {
    getPopoverClass(item) {
        let className = 'sidebar-popover';
        if (this.isFold) {
            className += ' fold';
        }
        if (item.name == 'business') {
            className += ' business';
        }
        if (item.name == 'data') {
            className += ' data';
        }
        return className;
    },
    handleFold() {
        this.isFold = !this.isFold;
        localStorage.setItem('isFoldSiderbar', this.isFold);
        this.$store.state.workbench.isActive = this.isFold;
        this.$emit('fold-callback', this.isFold);
    },
    handleSidebarItem(sidebarItem, sidebarIndex) {
      // let navArr = ['workbench','study','data'];
      // if(!navArr.includes(sidebarItem.name)){
      //     return;
      // }
      if(sidebarItem.name == 'workbench' || sidebarItem.name == 'member') {
        this.sidebar.forEach((item, index) => {
          if (index == sidebarIndex) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }
        });
      }
      switch (sidebarItem.name) {
        case "workbench":
          this.lastActiveItem = "workbench";
          sidebarItem.isExpand = !sidebarItem.isExpand;
          this.sidebar[1].isExpand = false;
          this.sidebar[2].isExpand = false;
          this.sidebar[3].isExpand = false;
          this.handleHome();
          break;
        case 'business':
          sidebarItem.isExpand = !sidebarItem.isExpand;
          this.sidebar[0].isExpand = false;
          this.sidebar[2].isExpand = false;
          this.sidebar[3].isExpand = false;
          break;
        case 'data':
          sidebarItem.isExpand = !sidebarItem.isExpand;
          this.sidebar[0].isExpand = false;
          this.sidebar[1].isExpand = false;
          this.sidebar[3].isExpand = false;
          break;
        case 'companyManagement':
          sidebarItem.isExpand = !sidebarItem.isExpand;
          this.sidebar[0].isExpand = false;
          this.sidebar[1].isExpand = false;
          this.sidebar[2].isExpand = false;
          break;
        case 'member':
          this.routerSwitch(sidebarItem.link);
          break;
        case "study":
          this.handleLoveStudy();
          break;
        case "operationPlatform":
             const tabItems = {
                "enTitle": "",
                "cnTitle": "运营平台",
                "path": "/operationPlatform",
                "aliveComponent": "OperationPlatform",
                "isActive": true,
            };
            this.invokePushTabItems(tabItems);
            this.routerSwitch(sidebarItem.link);
            break;
        default:
          break;
      }
    },
    handleHome() {
      this.$router.push({ path: "/" });
    //   this.$router.push({ path: "/Headhunting" });
      this.invokeSetTabItems([]);
      let keepAliveComponents = this.$store.state.keepAlive.keepAliveComponents;
      // ["TrackingList","TalentPool","FloatingListMyRecommend","MyOrdersCreatedJob"].forEach(item => {
      //   if(keepAliveComponents.indexOf(item) >= 0) {
      //     this.invokeNoKeepAlive(item);
      //   }
      // })
      keepAliveComponents.forEach(item => {
          this.invokeNoKeepAlive(item);
      })
    },
    handleLoveStudy() {
      _tracker.track('xiaoetong_entry_button_click');
      window.open('https://appdhywguik2938.pc.xiaoe-tech.com');
    },
    checkLoveStudy() {
      _request({
        url: study.love_study,
        method: 'GET',
        throwBusinessError: true
      }).then(() => {
        this.sidebar[4].isShow = true;
      }).catch(() => {
        this.sidebar[4].isShow = false;
      })
    },
    routerSwitch(path) {
      this.$router.push({ path: path });
    },
    handleShortcut(siderItem, shortcut){
        console.log(siderItem)
        console.log(shortcut)
        if(!shortcut.path){
            return;
        }
        if(shortcut.path === this.$route.path){
            return false;
        }

        this.toggleExpand(siderItem);

        let item = {},
            pathname = "";
      
        switch(shortcut.path){
            // case '/Headhunting/MyCompany.html#/candidates/firmCandidate':
            //     pathname = '人才';
            //     break;
            case '/candidates/firmCandidate':
                pathname = '人才';
                break;
            // case '/Headhunting/MyCompany.html#/jobList':
            //     pathname = '职位';
            //     break;
            case '/jobList':
                pathname = '职位';
                break;
            case '/protocolJobList':
                pathname = '职位';
                break;
            // case '/Headhunting/MyCompany.html#/customerList':
            //     pathname = '客户';
            //     break;
            case '/customerList':
                pathname = '客户';
                break;
            // case '/Headhunting/MyCompany.html#/project':
            //     pathname = '项目';
            //     break;
            case '/project':
                pathname = '项目';
                break;
            // case '/Headhunting/MyCompany.html#/market':
            //     pathname = '市场';
            //     break;
            case '/#/market':
                pathname = '市场';
                break;
            case '/Headhunting/MyCompany.html#/jdt':
                pathname = '职通车';
                break;
            case '/trackingList':
                pathname = 'TrackingList';
                break;
            case '/talentPool':
                pathname = 'TalentPool';
                break;
            case '/floatingList/myRecommend':
                if(this.isFinancial) {
                    shortcut.path = '/floatingList/apply';
                }
                pathname = 'FloatingList';
                break;
            case '/myOrders/grabbedJob':
                pathname = 'MyOrders';
                break;
            case '/data':
                pathname = 'Data';
                break;
            case '/typesData':
                pathname = 'TypesData';
                break;
            case '/performanceSet':
                pathname = 'PerformanceSet';
                break;
            case '/pointSetting/prize':
                pathname = 'PointSettingPrize';
                break;
            case '/resumeMerge':
                pathname = 'ResumeMerge';
                break;
            case '/jdtSet':
                pathname = 'JdtSet';
                break;

        }
        _tracker.track('PageViewCount', JSON.stringify({
            page: shortcut.path,
            pageName: pathname,
            from: 'sideBar'
        }));
        if(shortcut.aliveComponent) {
            item = {
                cnTitle: shortcut.cnTitle,
                enTitle: shortcut.enTitle,
                aliveComponent: shortcut.aliveComponent,
                path: shortcut.path,
                isActive: true
            }
            // if (shortcut.cnTitle == '人才') {
            //   item.cnTitle = '企业人才库';
            //   item.enTitle = 'FirmCandidate';
            // }
            // shortcut.aliveComponent != 'JobList' && 
            // && shortcut.aliveComponent != 'OfferBroadcast'
            // && shortcut.aliveComponent != 'CompanySetting'
            // && shortcut.aliveComponent != 'AuditOpportunity'
            // && shortcut.aliveComponent != 'Market'&& shortcut.aliveComponent != 'Jdt' && shortcut.aliveComponent != 'Opportunity' && shortcut.aliveComponent != 'CustomerList' && shortcut.aliveComponent != 'Project' 
            // if(shortcut.aliveComponent != 'Authorization' ) {
              if (sessionStorage.getItem("tabItems")) {
                  let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
                  let doublePageList = ['Floating List', 'Orders', 'PointSet', 'UserPermissionSet'];
                  let _index, hasDoublePageTab = false;
                  hasDoublePageTab = doublePageList.some((pageEnTitle) => {
                      _index = tabItems.findIndex(item => item.enTitle === pageEnTitle);
                      return item.enTitle === pageEnTitle && _index > -1;
                  });
                  if(hasDoublePageTab) {
                      tabItems.splice(_index, 1, item);
                      this.invokeSetTabItems(tabItems);
                      this.invokeSwitchTab(_index);
                  } else {
                      _index = tabItems.findIndex(tabItem => tabItem.path === item.path);

                      if(document.querySelector('.el-table__body-wrapper')) {
                          let lastScrollTop = document.querySelector('.el-table__body-wrapper').scrollTop;
                          let lastIndex = tabItems.findIndex((tabItem) => {
                              return tabItem.path === this.$route.path;
                          });
                          if(lastIndex > -1) {
                              tabItems[lastIndex].lastScrollTop = lastScrollTop;
                          }
                      }

                      if (_index > -1) {
                          this.invokeSetTabItems(tabItems);
                          this.invokeSwitchTab(_index);
                      } else {
                          tabItems.push(item);
                          this.invokeSetTabItems(tabItems);
                          this.invokeSwitchTab(tabItems.length - 1);
                      }
                  }
              } else {
                  this.invokePushTabItems(item);
              }
            // }
            console.log(item.path)
            this.routerSwitch(item.path);
        } else {
            window.open(shortcut.path);
        }
    },
    toggleExpand(sidebarItem) {
      switch (sidebarItem.name) {
        case 'business':
          sidebarItem.isExpand = true;
          this.sidebar[2].isExpand = false;
          this.sidebar[3].isExpand = false;
          break;
        case 'data':
          sidebarItem.isExpand = true;
          this.sidebar[1].isExpand = false;
          this.sidebar[3].isExpand = false;
          break;
        case 'companyManagement':
          sidebarItem.isExpand = true;
          this.sidebar[1].isExpand = false;
          this.sidebar[2].isExpand = false;
          break;
        default:
          break;
      }
    },
    handleData(){
        this.$router.push({ path: "/data" });
    },
    handleTrack(item){
        if(item.name == 'study') {
            return;
        }
        let [page,pageName] = [item.link, item.text];
        switch(item.name){
            case 'data':
                page = '/data';
                break;
            case 'stydy':
                page = '';
                break;
        }

        _tracker.track('PageViewCount', JSON.stringify({
            page: page,
            pageName: pageName,
            from: 'sideBar'
        }));
    },
    ...mapActions({
      invokePushTabItems: 'workbench/invokePushTabItems',
      invokeSetTabItems: 'workbench/invokeSetTabItems',
      invokeSwitchTab: 'workbench/invokeSwitchTab',
      invokeNoKeepAlive: 'keepAlive/invokeNoKeepAlive'
    })
  }
};
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  position: relative;
  color: $primary;
  // width: 146px;
//   width: 156px;
  width: 230px;
//   height: 100%;
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
//   padding: 30px 0;
//   box-shadow: 2px 0 6px rgba(7, 0, 2, 0.2);
  border-right: 1px solid rgba(235,235,235,0.92);
  background: #fff;
  z-index: 10;
  //transition: width 0.4s ease-in;
  .sidebar-logo {
    // width: 75px;
    // height: 21px;
    width: 92px;
    height: 40px;
    // margin: 0 auto 40px;
    margin: 0 auto;
    background: url("~@src/assets/images/home/logo.png");
    background-size: cover;
  }
  .sidebar-list {
    // margin-top: 10px;
    font-size: 14px;
    color: #888;
    height: calc(100% - 50px);;
    overflow-x: hidden;  //兼容火狐样式
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
      border-width: 2px;
    }
    scrollbar-width: thin;

    .sidebar-item {
      margin-bottom: 10px;

      &-text {
        position: relative;
        padding: 0 10px 0 28px;
        // margin: 0 10px;
        height: 50px;
        line-height: 50px;
        // border-radius: 6px;
        text-align: left;
        cursor: pointer;
        font-size: 16px;
        color: #333;

        .sidebar-icon {
          display: inline-block;
        //   width: 16px;
        //   height: 16px;
          vertical-align: 0px;
          margin-right: 12px;
          font-size: 20px;
        }

        .el-icon-arrow-right {
          position: absolute;
          top: 50%;
          right: 10px;
          width: 12px;
          height: 12px;
          margin-top: -5px;
          font-size: 12px;
          color: #999;
          transition: all 0.3s;

          &.expanded {
            transform: rotate(-90deg);
          }
        }

        &:hover{
            // background: rgba(56, 188, 157, 0.1);
          background-color: #f5f5f5;
        }
      }

      .sidebar-menu {
        margin-bottom: -6px;

        &-item {
          box-sizing: border-box;
          height: 50px;
          line-height: 42px;
        //   padding-left: 38px;
        //   margin: 4px 10px;
          padding: 4px 10px 4px 58px;
        //   border-radius: 10px;
          cursor: pointer;
          white-space: nowrap;
          color: #999;
          font-size: 16px;

          &:hover {
            background-color: #f5f5f5;
            color: #666;
            // border-radius: 10px;
          }

          &.active {
            // background-color: #f5f5f5;
            color: #38BC9D;
            position: relative;
            background: rgba(56,188,157,0.11);
            &::before{
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 5px;
                background: $primary;
            }
          }
        }
      }

      &.active {
        .sidebar-item-text {
          position: relative;
          color: #333;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -10px;
            width: 5px;
            height: 14px;
            margin-top: -7px;
            background-color: $primary;
          }
        }
        // background: $primary;
        // box-shadow: 0 6px 8px -2px rgba(56, 188, 157, 0.4);
        // .sidebar-link {
        //   color: #fff;
        // }
      }

    }
  }

  .customer-service-btn {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 56px;
    left: 50%;
    bottom: 70px;
    margin-left: -28px;

    img {
      display: block;
      width: 30px;
      height: 26px;
      margin: 0 auto;
    }

    span {
      display: block;
      font-size: 14px;
      color: $primary;
      line-height: 33px;
      text-align: center;
    }
  }
  .service-qr-code {
    position: absolute;
    width: 90px;
    height: 90px;
    bottom: 70px;
    left: 90%;
  }
  
  .sidebar-btn {
    position: absolute;
    display: inline-block;
    width: 26px;
    height: 26px;
    bottom: 20px;
    right: -11px;
    cursor: pointer;
    text-align: center;
    background: $primary;
    border-radius: 3px;
    border: 1px solid #38BC9D;
    .expand-icon{
        fill: white;
    }
    // transform: rotate(180deg);
    // &.expand-btn {
    //   background: url("~@src/assets/images/icon/icon-expand.png") center no-repeat;
    //   background-size: 12px 12px;
      // &:active,
      // &:focus,
      // &:hover {
      //   background: url("~@src/assets/images/home/icon_expand_active.svg")
      //     center no-repeat;
      // }
    // }
  }
  &.is-fold {
    /* 折叠的样式 */
    width: 70px;
    // padding: 15px;
    //transition: width 0.4s ease-in;
    .sidebar-logo {
      width: 50px;
      height: 38px;
      background: url("~@src/assets/images/home/logo_small.png");
      margin-left: 5px;
    }
    .fold-sidebar-list {
      &-item {
        position: relative;
        // width: 60px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;

        &.active {
            background: rgba(56,188,157,0.11);
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            // top: 50%;
            // right: -15px;
            left: 0;
            width: 5px;
            height: 100%;
            background: $primary;
            // height: 14px;
            // margin-top: -7px;
            background-color: $primary;
          }
        }

        &:hover {
          background-color: #f5f5f5;
        //   border-radius: 10px;
        }

        .sidebar-link {
          display: block;
          width: 100%;
          height: 100%;
          color: #444;
          font-size: 20px;
        }
      }
    }
    // .sidebar-btn {
    //   right: 28px;
    //   transform: rotate(180deg);
    // }
  }
  /deep/ .el-dropdown{
      vertical-align: top;
  }

  .copy-right{
    text-align: center;
    // margin-top: 100px;
    position: absolute;
    display: block;
    // width: 26px;
    // height: 26px;
    bottom: 20px;
    width: 100%;
    // right: -11px;
    .copy-txt{
        font-size: 14px;
        color: #999;
    }
  }
}
@media only screen and (max-height: 768px) {
  .sidebar-wrap {
    .sidebar-logo {
      margin-bottom: 30px;
    }
    .sidebar-list {
      .sidebar-item {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
<style lang="scss">
.sidebar-title.el-tooltip__popper {
  width: 60px;
  height: 32px;
  margin: -5px 0 0 4px !important;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.20);
  border-radius: 6px;
  font-size: 16px;
  color: #666666;
  line-height: 32px;
  text-align: center;
  border: none !important;
}
.sidebar-title-companyManagement.el-tooltip__popper {
  width: 80px;
}
.sidebar-popover.el-popover {
  padding: 10px 0;
  margin-left: 40px !important;
  min-width: 130px;
  border-radius: 6px;

  .sidebar-menu-item {
    width: 104px;
    height: 40px;
    line-height: 40px;
    margin: 5px auto;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    color: #666;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  &.fold {
    margin-left: 36px !important;
  }
  &.business {
    // min-width: 84px;
    min-width: 114px;
    .sidebar-menu-item {
    //   width: 60px;
        width: 90px;
    }
  }
  &.data {
    min-width: 114px;
    .sidebar-menu-item {
      width: 90px;
    }
  }
}
.el-tooltip__popper.is-light.sidebar-title{
    left: 32px !important;
    line-height: 32px;
    font-size: 14px;
    color: #4A4A4A;
}
</style>
