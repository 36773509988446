import { broadcast as broadcastUrl } from '#/js/config/api.json';
import { javaBroadcast as javaBroadcastUrl } from '#/js/config/javaApi.json';

export default {
    // 获取offer播报设置
    getOfferBroadcastSetting(params) {
        return _request({
            method: "GET",
            baseURL: "LbdOpenApi",
            url: broadcastUrl.get_offer_broadcast_setting,
            data: params
        });
    },
    
    // 更新offer播报设置
    updateOfferBroadcastSetting(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: broadcastUrl.update_offer_broadcast_setting,
            data: params
        });
    },

    // 获取播报数据统计列表
    getBroadcastList(params) {
        return _request({
            url: javaBroadcastUrl.broadcast_get_list,
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            method: "POST",
            data: params
        });
    },
    
    // 获取播报发放详情
    getBroadcastListDetail(params) {
        return _request({
            url: javaBroadcastUrl.broadcast_list_detail,
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            method: "GET",
            data: params
        });
    },

    // 导出播报数据统计列表
    exportBroadcast(params) {
        return _request({
            url: javaBroadcastUrl.broadcast_export,
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            method: "POST",
            responseType: "blob",
            data: params
        });
    },

    // 抢红包按钮
    grabRedPacket(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: broadcastUrl.grab_red_packet,
            data: params,
        });
    },
};