import Vue from 'vue'

if (!Date.prototype.add) {
    Date.prototype.add = function (obj) { // obj = {year:1,month:-1,day:2};
        let year = this.getFullYear(), month = this.getMonth(),time=0;
        for (let q in obj) {
            if (q == "year") {
                year += obj[q]
            } else if (q == "month") {
                month += obj[q];
                if(month>12){
                    year += parseInt(month/12);
                    month -= 12;
                }else if(month<1){
                    month = 12+month;
                    year += parseInt(month/12);
                }
            } else if (q == "day") {
                time = obj[q] * 24 * 60 * 60 * 1000;
            }
        }
        return new Date(new Date(year+"/"+(month+1)+"/"+this.getDate()).getTime()+time);
    }
}

if (!Date.prototype.format) {
    Date.prototype.format = function (format) {
        let o = {
            "M+": this.getMonth() + 1, //month
            "d+": this.getDate(), //day
            "h+": this.getHours(), //hour
            "m+": this.getMinutes(), //minute
            "s+": this.getSeconds(), //second
            "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
            "S": this.getMilliseconds() //millisecond
        }

        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        }

        for (let k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }

        return format;
    }
}

function i10n (str) {
    return str;
}

function makeEmpyArray(size, filling){
    return Array.apply(null, Array(size || 4)).map(Number.prototype.valueOf,filling || 0)
}

// 自动补零
function markPadding(str, size){
    if(str.length < size){
        return makeEmpyArray(size - str.length).join('') + str;
    }else{
        return str;
    }
}

function doubleNumTime(num) {
    num += '';
    return num.length == 1 ? '0' + num : num;
}

const DateFormatMap = {
    "yyyy-MM-dd": (year, month, day, hour, minutes, seconds) => {
        return year + '-' + month + '-' + day;
    },
    "yyyy-MM-dd HH:mm": (year, month, day, hour, minutes, seconds) => {
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes;
    }
}
const defaultFormatFunction = DateFormatMap["yyyy-MM-dd HH:mm"];
// TODO 引入moment.js
function getDateStringWithFormat(date, format = "yyyy-MM-dd HH:mm"){
    let year = date.getFullYear(),
        month = date.getMonth()+1,
        day = date.getDate(),
        hour = date.getHours(),
        minutes = date.getMinutes(),
        seconds = date.getSeconds();
    month = markPadding(month + '', 2);
    day = markPadding(day + '', 2);
    hour = markPadding(hour + '', 2);
    minutes = markPadding(minutes + '', 2),
        seconds = markPadding(seconds + '', 2);
    return (DateFormatMap[format] || defaultFormatFunction)(year, month, day, hour, minutes, seconds)
}

// str = '2014/01/12 12:30'
function magicTime(str) {
    if (!str) {
        return '';
    }else {
        str = str.replace(/T/g,' ');
    }
    let now = new Date();
    let date = new Date(str);
    // 日期无效，则需再处理
    if(isNaN(date.getTime())){
        date = new Date(str.replace(/-/g,'/'));
    }
    if(isNaN(date.getTime())){
        return "";
    }
    if(date > now){
        return '刚刚';
    }
    str = getDateStringWithFormat(date);

    if (typeof serviceDateOffset == 'string') {
        now = new Date(now.getTime() + serviceDateOffset);
    }
    let year = now.getFullYear(),
        month = now.getMonth()+1,
        day = now.getDate(),
        nowDate = new Date(year + '/' + month + '/' + day),
        hour = now.getHours(),
        minutes = now.getMinutes(),
        yestoday = nowDate.add({ day: -1 }),
        tomorrow = nowDate.add({ day: 1 });
    let maxDate = new Date('2900/01/01').getTime(), miniDate = new Date('2000/01/01').getTime();
    let dateObj = new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()),
        dateHours = date.getHours(),
        dateMinu = date.getMinutes(),
        dateTime = date.getTime(),
        nowTime = now.getTime(),
        oneHourTime = 60*60*1000;
    if (date.toString() == 'Invalid Date') {
        return str;
    }
    if (dateTime >= maxDate) {
        return i10n('很久以后');
    } else if (dateTime <= miniDate) {
        return i10n('很久以前');
    } else {
        if (yestoday.getTime() == dateObj.getTime()) {
            return i10n('昨天') + ' ' + doubleNumTime(date.getHours()) + ':' + doubleNumTime(date.getMinutes());
        } else if (tomorrow.getTime() == dateObj.getTime()) {
            return i10n('明天') + ' ' + doubleNumTime(date.getHours()) + ':' + doubleNumTime(date.getMinutes()); ;
        } else if (dateObj.getTime() == nowDate.getTime()) {
            if (dateTime > nowTime - oneHourTime * 4 && dateTime < nowTime - oneHourTime) {  //目标时间小于当前时间，并且不超过4小时
                return (hour - dateHours) + i10n('小时前');
            } else if (dateTime < nowTime + oneHourTime * 4 && dateTime > nowTime + oneHourTime) {  //目标时间大于当前时间，并且不超过4小时
                return (dateHours - hour) + i10n('小时后');
            } else if (dateHours == hour || (dateTime > nowTime && dateTime < nowTime + oneHourTime) || (dateTime < nowTime && dateTime > nowTime - oneHourTime)) {  // 目标时间与当前时间
                let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                if(minutesDiff==0||minutesDiff==1){
                    return i10n('刚刚');
                }else if(minutesDiff>0){
                    return minutesDiff + i10n('分钟后');
                } else {
                    return (minutesDiff*-1) + i10n('分钟前');
                }
            }else{
                return i10n('今天') + ' ' + doubleNumTime(dateHours) + ':' + doubleNumTime(dateMinu);
            }
        }
        return str;
    }
}

function magicDate(str) { //将时分秒去掉
    let result = magicTime(str);

    let idx = result.indexOf(' ');
    if (idx !== -1) {
        result = result.substr(0, idx);
    }
    return result;
}

// 时间工具，取时分
function magicHourMinute(str){
    const d = new Date(str.replace(/-/g,'/'));
    return markPadding(d.getHours() + '', 2) + ':' + markPadding(d.getMinutes() + '', 2);
}

// 取月
function magicMonth (str) {
    const d = new Date(str.replace(/-/g,'/'));
    return markPadding(d.getMonth() + 1 + '', 2);
}

// 取天
function magicDay (str) {
    const d = new Date(str.replace(/-/g,'/'));
    return markPadding(d.getDate() + '', 2);
}

function magicDateTime(str) { //将时分秒去掉
    let result = magicTime(str);
    return result;
}

export function date (datetime) {
    return datetime ? magicDate(datetime) : '';
};

export function dateWithMinite (datetime) {
    return datetime.slice(0, 16);
};

export function time (datetime) {
    return datetime ? magicHourMinute(datetime) : '';
};

export function dateWithoutYear (datetime, cn = false) {
    if (!datetime) {
        return '';
    }

    const month = magicMonth(datetime);
    const day = magicDay(datetime);

    return cn
        ? `${parseInt(month, 10)}月${parseInt(day, 10)}日`
        : `${month}-${day}`;
};

export function datetimeWithoutYear(datetime) {
    if (!datetime) {
        return '';
    }

    const month = magicMonth(datetime);
    const day = magicDay(datetime);
    const hourMinute = magicHourMinute(datetime);

    return `${month}-${day} ${hourMinute}`;
};

export function datetime(str){
    return str ? magicDateTime(str) : '';
};

export function foretime(str) {
    if (!str) {
        return '';
    }

    const now = new Date();
    const date = new Date(str);

    let yearEscaped = now.getFullYear() - date.getFullYear();
    let monthEscaped = now.getMonth() - date.getMonth();
    let dayEscaped = now.getDate() - date.getDate();

    if (yearEscaped === 0 && monthEscaped === 0 && dayEscaped === 0) {
        let hourEscaped = now.getHours() - date.getHours();
        let minuteEscaped = now.getMinutes() - date.getMinutes();
        let secondEscaped = now.getSeconds() - date.getSeconds();

        if (hourEscaped > 0) {
            return `${hourEscaped} 小时前`;
        } else if (minuteEscaped > 0) {
            return `${minuteEscaped} 分钟前`;
        } else {
            return `刚刚`;
        }
    } else {
        return foredate(str);
    }
};

export function foredate(str) {
    if (!str) {
        return '';
    }

    const now = new Date();
    const date = new Date(str);
    const dateInterval = now.getTime() - date.getTime();

    let yearEscaped = Math.floor(dateInterval / (365 * 24 * 3600 * 1000));
    let monthEscaped = Math.floor(dateInterval / (30 * 24 * 3600 * 1000));
    let weekEscaped = Math.floor(dateInterval / (7 * 24 * 3600 * 1000));
    let dayEscaped = Math.floor(dateInterval / (24 * 3600 * 1000));

    if (yearEscaped >= 1) {
        return `${yearEscaped} 年前`;
    } else if (monthEscaped >= 1) {
        return monthEscaped > 5 ? '半年前' : `${monthEscaped} 个月前`;
    } else if (weekEscaped >= 1) {
        return `${weekEscaped} 周前`;
    } else {
        return '近 7 天';
    }
};

export function hourToDay(hours) {
    if(hours < 24) {
        return hours;
    } else {
        return Math.floor(hours / 24);
    }
};

export function formatSecond(value) {
    if(!parseInt(value)){
        return '';
    }
    let hh,mm,ss,result;
    hh = parseInt(value/3600);
    if(hh<10&&hh>0){
        hh = "0" + hh
    };
    mm = parseInt((value-hh*3600)/60);
    if(mm<10){
        mm = "0" + mm
    };
    ss = parseInt((value-hh*3600)%60);
    if(ss<10){
        ss = "0" + ss
    };
    if(hh){
        result = hh + ":" + mm + ":" + ss;
    }else{
        result = mm + ":" + ss;
    }
    return result;
};

export function formatTimeYeJi(val) {
    if(val == 0 || !val) {
        return '0:00';
    }

    val = Number(val);
    let m = Math.floor(val/60);
    let s = Math.floor(val%60);
    s = s < 10 ? ('0' + s): s
    return m + ':' + s;
}

export function formatMinute(val) {
    var newValue = val * 60;
    return formatSecond(newValue);
}

export function formatDateWithDot(value) {
    if(!value) {
        return '';
    }
    if(value && value.indexOf('9999')>-1){
        return '至今';
    }else if(value == '0001-01-01T00:00:00'){
        return '时间未知';
    }
    if(value && value.includes(' ')) {
        return value.split(' ')[0].replace(/-/g,'.');
    } else {
        return value.split('T')[0].replace(/-/g,'.');
    }
}

export function formatDateWithMonth(value) {
    if(!value) {
        return '';
    }
    if(value.indexOf('9999')>-1){
        return '至今';
    }else if(value == '0001-01-01T00:00:00'){
        return '';
    }
    return value.split('T')[0].substr(0,7)
}

export function formatDateWithDotMonth(value) {
    if(!value) {
        return '';
    }
    if(value.indexOf('9999')>-1){
        return '至今';
    }else if(value == '0001-01-01T00:00:00'){
        return '时间未知';
    }
    return value.split('T')[0].substr(0,7).replace(/-/g,'.')
}

export function formatDateWithoutTime(value) {
    if(!value) {
        return '';
    }
    if(value == '0001-01-01T00:00:00'){
        return '';
    }
    if(value.indexOf('9999')>-1){
        return '至今';
    }

    return value.split('T')[0]
}

export function getDateDiff(value){
    if(!value) return;

    let start = value.split('|')[0],
        end = value.split('|')[1],
        year,month,day,diffTime,returnStr = '';
    let yearDiff = 365*24*60*60*1000,
        monthDiff = 30*24*60*60*1000,
        dayDiff = 24*60*60*1000;

    if(start == '0001-01-01T00:00:00' || end == '0001-01-01T00:00:00'){
        return '未知时长';
    }
    if(end.indexOf('9999')>-1){
        end = new Date();
    }

    start = new Date(start);
    end = new Date(end);
    diffTime = end - start;
    year = Math.floor(diffTime/yearDiff);
    month = Math.floor((diffTime-year*yearDiff)/monthDiff);
    day = Math.floor((diffTime-year*yearDiff-month*monthDiff)/dayDiff);

    if(year){
        returnStr += (year + '年');
    }
    if(month){
        returnStr += (month + '个月');
    }
    if(day){
        returnStr += (day + '天');
    }
    return returnStr;
}
export function getMonthDiff(value){
    if(!value) return;

    let start = value.split('|')[0],
        end = value.split('|')[1],
        year,month,diffTime,returnStr = '';
    let yearDiff = 365*24*60*60*1000,
        monthDiff = 30*24*60*60*1000

    if(start == '0001-01-01T00:00:00' || end == '0001-01-01T00:00:00'){
        return '未知时长';
    }
    if(end.indexOf('9999')>-1){
        end = new Date();
    }

    start = new Date(start);
    end = new Date(end);
    diffTime = end - start;
    year = Math.floor(diffTime/yearDiff);
    month = Math.floor((diffTime-year*yearDiff)/monthDiff);

    if(year){
        returnStr += (year + '年');
    }
    if(month){
        returnStr += (month + '个月');
    }
    if(returnStr) {
        return `（${returnStr}）`;
    } else {
        return "";
    }
}

export function gone(timestamp) {
    timestamp = timestamp.replace(/\s/, 'T');

    const date = new Date();
    const goneDate = new Date(timestamp);

    if (date.getFullYear() === goneDate.getFullYear()) {
        if (date.getMonth() === goneDate.getMonth() && date.getDate() === goneDate.getDate()) {
            const hours = goneDate.getHours();
            let rangeText;

            if (hours >= 6 && hours < 12) {
                rangeText = '上午';
            } else if (hours >= 12 && hours < 18) {
                rangeText = '下午';
            } else if (hours >= 18 && hours < 24) {
                rangeText = '晚上';
            } else if (hours >= 0 && hours < 6) {
                rangeText = '凌晨';
            } else {
                rangeText = '';
            }
            return [
                rangeText,
                [
                    hours,
                    markPadding(goneDate.getMinutes() + '', 2),
                ].join(':'),
            ].join('');
        } else {
            return [
                markPadding(goneDate.getMonth() + 1 + '', 2),
                '月',
                markPadding(goneDate.getDate() + '', 2),
                '日',
            ].join('');
        }
    } else {
        return [
            markPadding(goneDate.getFullYear() + '', 2),
            markPadding(goneDate.getMonth() + 1 + '', 2),
            markPadding(goneDate.getDate() + '', 2),
        ].join('.');
    }
}

Vue.filter('date', date);
Vue.filter('dateWithMinite', dateWithMinite);
Vue.filter('time', time);
Vue.filter('datetime', datetime);
Vue.filter('dateWithoutYear', dateWithoutYear);
Vue.filter('datetimeWithoutYear', datetimeWithoutYear);
Vue.filter('smartMonth', magicMonth);
Vue.filter('smartDay', magicDay);
Vue.filter('smartTime', magicTime);
Vue.filter('foredate', foredate);
Vue.filter('foretime', foretime);
Vue.filter('hourToDay', hourToDay);
Vue.filter('formatSecond', formatSecond);
Vue.filter('formatMinute', formatMinute);
Vue.filter('formatTimeYeJi', formatTimeYeJi);
Vue.filter('formatDateWithDot', formatDateWithDot);
Vue.filter('formatDateWithDotMonth', formatDateWithDotMonth);
Vue.filter('formatDateWithMonth', formatDateWithMonth);
Vue.filter('formatDateWithoutTime', formatDateWithoutTime);
Vue.filter('getDateDiff',getDateDiff);
Vue.filter('getMonthDiff',getMonthDiff);
