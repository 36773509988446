var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "im-side-bar" },
    [
      _c(
        "div",
        {
          staticClass: "header-bar",
          on: {
            click: function ($event) {
              return _vm.$emit("closeInstantMessage")
            },
          },
        },
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v("消息")]
          ),
          _c(
            "el-popover",
            {
              ref: "msgNotifiedPopper",
              attrs: {
                trigger: "click",
                placement: "bottom-end",
                width: 164,
                offset: 20,
                "popper-class": "message-notified-popper",
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "message-notified-set" },
                [
                  _vm._v("\n                消息通知\n                "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#38bc9d",
                      "inactive-color": "#bfbfbf",
                    },
                    on: { change: _vm.handleMessageNotifiedSet },
                    model: {
                      value: _vm.isMessageNotified,
                      callback: function ($$v) {
                        _vm.isMessageNotified = $$v
                      },
                      expression: "isMessageNotified",
                    },
                  }),
                ],
                1
              ),
              _c("el-button", {
                staticClass: "message-notified-set-btn",
                attrs: {
                  slot: "reference",
                  title: "设置消息通知",
                  icon: "el-icon-setting",
                },
                slot: "reference",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "conversation-list-wrapper" }, [
        _c(
          "div",
          { staticClass: "conversation-header" },
          [
            _c("el-input", {
              staticClass: "search-input",
              attrs: {
                name: "im-search-input",
                placeholder: "搜索",
                "prefix-icon": "el-icon-search",
                maxlength: 100,
              },
              on: { change: _vm.handleSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "keyword",
              },
            }),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSearchMode,
                  expression: "isSearchMode",
                },
              ],
              staticClass: "search-input-clear el-icon-error",
              on: { click: _vm.handleClearSearch },
            }),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "light",
                  content: "发起群聊",
                  placement: "bottom",
                  "popper-class": "create-group-tip",
                },
              },
              [
                _c("el-button", {
                  staticClass: "create-group-btn",
                  attrs: { icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.showCreateGroupDialog(false)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSearchMode,
                expression: "!isSearchMode",
              },
            ],
            staticClass: "conversation-list-container",
            style: _vm.isLargeSize ? "max-height: 632px" : "",
          },
          [
            _vm.conversationList.length
              ? _c(
                  "div",
                  { staticClass: "conversation-list" },
                  [
                    _vm._l(
                      _vm.conversationList,
                      function (conversation, index) {
                        return [
                          conversation.type != _vm.TIM.TYPES.CONV_SYSTEM
                            ? _c("conversation-item", {
                                key: index,
                                attrs: {
                                  userId: _vm.userId,
                                  checkResult:
                                    _vm.checkTypeResult(conversation),
                                  isOnline: _vm.checkOnline(conversation),
                                  conversation: conversation,
                                  isCompany:
                                    conversation.type ===
                                      _vm.TIM.TYPES.CONV_GROUP &&
                                    _vm.companyGroupId ===
                                      conversation.groupProfile.groupID,
                                  maxWidth: _vm.isLargeSize
                                    ? _vm.conversationList.length > 9
                                      ? "220px"
                                      : "226px"
                                    : _vm.conversationList.length > 5
                                    ? "220px"
                                    : "226px",
                                  currentConversationID:
                                    _vm.currentConversationID,
                                  atMeConversationList:
                                    _vm.atMeConversationList,
                                },
                                on: {
                                  handleCurrentConversation:
                                    _vm.selectConversation,
                                },
                              })
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                )
              : _c("div", { staticClass: "empty-data" }, [
                  _vm._v("\n                暂无数据\n            "),
                ]),
          ]
        ),
        _vm.isSearchMode
          ? _c(
              "div",
              {
                staticClass: "search-result-container",
                style: _vm.isLargeSize ? "height: 632px" : "",
              },
              [
                _c("search-result", {
                  attrs: {
                    searchResultObject: _vm.searchResultObject,
                    isSearchEnd: _vm.isSearchEnd,
                    keyword: _vm.keyword,
                  },
                  on: {
                    handleCurrentConversation: _vm.searchResultClick,
                    handlePositionMessage: _vm.positionMessage,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("create-group-dialog", {
        ref: "createGroupDialog",
        attrs: { userId: _vm.userId },
        on: { selectConversation: _vm.selectConversation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }