<template>
    <el-dialog
    title="审核不通过详情"
    :visible.sync="dialogVisible"
    width="500px"
    v-loading="loading"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
>
    <el-form
        class="reject-form"
        ref="rejectForm"
        :model="formData"
        label-width="114px">
        <el-form-item
            label="原因"
            prop="rejectResean">
            <el-input
                disabled
                type="textarea"
                v-model="formData.rejectResean"
                placeholder="暂无"
                maxlength="300"
                :rows="3"
                :show-word-limit="true"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="详细说明"
            prop="auditDetail">
            <el-input
                disabled
                type="textarea"
                v-model="formData.auditDetail"
                placeholder="暂无"
                maxlength="300"
                :rows="3"
                :show-word-limit="true"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="审批人"
            prop="auditDetail">
            <el-input
                disabled
                type="textarea"
                v-model="formData.auditName"
                placeholder="暂无"
                maxlength="300"
                :rows="3"
                :show-word-limit="true"
            ></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmHandle">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import DepartmentService from '#/js/service/departmentService.js';

    export default {
        name: "accept-dialog.vue",
        components: {
        },
        data() {
            return {
                dialogVisible:false,
                loading:false,
                formData: {
                    rejectResean: '',
                    auditDetail: '',
                    auditPerson: ''
                },
                reasonList: ['无', '重复申请，已存在该部门', '信息有误，请重新确认信息', '系统判断']
            }
        },
        methods: {
            show(id) {
                this.dialogVisible=true;
                this.getRejectReason(id);
            },
            getRejectReason(id) {
                this.loading = true;
                DepartmentService.getDepartmentApplyDetail({
                    id: id
                }).then(res => {
                    this.loading = false;
                    this.formData.rejectResean = this.reasonList[res.rejectResean || 0];
                    this.formData.auditDetail = res.auditDetail;
                    this.formData.auditName = res.auditName;
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            },
            /**
             * 确认按钮fn
             */
            confirmHandle() {
                this.formData = {
                    id: '',
                    rejectResean: '',
                    auditDetail: '',
                    auditPerson: ''
                };
                this.dialogVisible = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-row {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .color-red{
        color: red;
    }
</style>
