import { im as imUrl } from '#/js/config/javaApi.json';

export default {
    getUserSig() {
        return _request({
            method: 'POST',
            url: imUrl.get_sig,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            throwBusinessError: true,
            throwHttpError: true
        })
    },
    getMessageList(param) {
        return _request({
            method: 'POST',
            url: imUrl.get_message_list,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            data: param,
            throwBusinessError: true
        })
    },
    checkHasAtMe(params = {models: []} = {}) {
        return _request({
            method: 'POST',
            url: imUrl.check_has_at_me,
            data: params,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            throwBusinessError: true,
            throwHttpError: true
        })
    },
    checkInnerLabel(params = {accountIds = [], groupIds = []} = {}) {
        return _request({
            method: 'POST',
            url: imUrl.check_inner_label,
            data: params,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            throwBusinessError: true,
            throwHttpError: true
        })
    },
    revokeMessage(params = {msgSeq = 0} = {}) {
        return _request({
            method: 'POST',
            url: imUrl.recall_message,
            data: params,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getTeamList() {
        return _request({
            method: 'POST',
            url: imUrl.get_team_list,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getMemberList(param) {
        return _request({
            method: 'POST',
            url: imUrl.get_member_list,
            data: param,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getOuterMemberList(param) {
        return _request({
            method: 'POST',
            url: imUrl.get_external_member_list,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getNameById(param) {
        return _request({
            method: 'POST',
            url: imUrl.batch_get_name,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            throwBusinessError: true,
            throwHttpError: true
        })
    },
    sendSystemNotification(param) {
        return _request({
            method: 'POST',
            url: imUrl.send_system_notification,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    historySearch(param) {
        return _request({
            method: 'POST',
            url: imUrl.history_search,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getHistoryMessageList(param) {
        return _request({
            method: 'POST',
            url: imUrl.history_message_list,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getFirmGroupCount() {
        return _request({
            method: 'GET',
            url: imUrl.firm_group_count,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            }
        })
    },
    getUserOnlineStatus(param) {
        return _request({
            method: 'POST',
            url: imUrl.user_online_status,
            data: param,
            headers: {
                "AuthorizationIM": _getCookie('access_token')
            },
            throwBusinessError: true,
            throwHttpError: true
        })
    },

    // tracklist分享给后台发数据
    trackListShare(param){
        return _request({
            method: 'POST',
            url: imUrl.track_list_share,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: param,
            // throwBusinessError: true,
            // throwHttpError: true
        })
    },
}
