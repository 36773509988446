
const URL = {
    getAIRecommend: '/Headhunting/IntelligentRecommend/MyCandidates',
    toView: '/Headhunting/IntelligentRecommend/MyCandidate/%p/ToView',
    unSuitable: '/Headhunting/IntelligentRecommend/MyCandidate/%p/Unsuitable'
};

export default {
    /**
     * 获取landing page推荐列表
     * @param {number} start 分页开始下标
     * @param {number} take 分页条目数
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getAIRecommend (start, take) {
        return _request({
            method: 'GET',
            url: URL.getAIRecommend,
            data: {
                start: start ? start : 0,
                take: take ? take: 6,
            }
        })
    },
    /**
     * 立即看看
     * @param {string} candidateId 简历id
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    toView (candidateId) {
        return _request({
            method: 'POST',
            url: URL.toView.replace('%p',candidateId),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    /**
     * 暂不符合
     * @param {string} candidateId 简历id
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    unSuitable (candidateId) {
        return _request({
            method: 'POST',
            url: URL.unSuitable.replace('%p',candidateId),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }
}
