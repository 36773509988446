<template>
  <message-bubble
    :isMine=isMine
    :message=message
    :groupId="groupId">
    <template v-for="(item, index) in contentList">
      <span :key="index" v-if="item.name === 'text'" v-html="translateText(item.text)"></span>
      <img v-else-if="item.name === 'img'" :src="item.src" width="20px" height="20px" :key="index"/>
    </template>
  </message-bubble>  
  <!-- <div class="chat-bubble">
    <div class="message-content" :class="isMine ? 'message-send' : 'message-received'">
      <template v-for="(item, index) in contentList">
        <span :key="index" v-if="item.name === 'text'">{{ item.text }}</span>
        <img v-else-if="item.name === 'img'" :src="item.src" width="20px" height="20px" :key="index"/>
      </template>
    </div>
  </div> -->
</template>

<script>
import MessageBubble from '../message-bubble'
import { decodeText } from '../../utils/decodeText.js'

export default {
  name: 'TextElement',
  components: {
    MessageBubble
  },
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    groupId: String
  },
  computed: {
    contentList() {
      return decodeText(this.payload)
    }
  },
  methods: {
    translateText(text) {
      let res = "";
      res = text.replace(/\</g, '&lt;').replace(/\>/g, '&gt;').replace(/((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?(\s|$)/, function() {
        return `<a class="text-message-link" href="${arguments[0]}" target="_blank">${arguments[0]}</a>`;
      });
      return res;
    }
  }
}
</script>

<style lang="scss" scope>
  .text-message-link {
    color: #4A90E2;
    &:hover, &:focus, &:active {
      color: #4A90E2;
    }
  }
</style>
