// import axios from 'axios';
import axios from './ax3.js';
import qs from 'qs';
import hostConfig from '#/js/config/host.json';
import { errorMessage } from '#/js/util/tools.js';
import { clearCookie, getCookie } from './global';
import CertificationService from '#/js/service/certificationService.js';

const NODE_ENV = process.env.NODE_ENV;
window.NODE_ENV = NODE_ENV;
window.xxx = process.env.VUE_APP_HASH;
window._host = hostConfig.pro;
if (NODE_ENV == 'development' || NODE_ENV == 'rc') {
    if(NODE_ENV == 'rc') {
        window._host = hostConfig.rc;
    }else {
        window._host = hostConfig.dev;
    }

    let localHostEnabled = localStorage.getItem('localHostEnabled');
    let localHostData = localStorage.getItem('localHostData')
    if (localHostEnabled == 1 && localHostData) {
        window._host = JSON.parse(localStorage.getItem('localHostData'));
    }
} else if (NODE_ENV == 'staging') {
    window._host = hostConfig.stg;
}

if(window._host && window._host.h5_page.indexOf('http') == -1) {
    window._host.h5_page = location.protocol + window._host.h5_page;
}

export default class LbdRequest {
    constructor(config) {
        this.mockBaseUrl = config.mockBaseUrl || 'http://192.168.1.72:3000/mock/20';
        this.errMsgHandler = config.errMsgHandler || null;
        this.commonHeaders = Object.assign({'X-Requested-With': 'XMLHttpRequest'}, config.commonHeaders) || {};
        this.dynamicCommonHeaders = config.dynamicCommonHeaders || {};

        this._request = this._request.bind(this);
    }

    getQueryString(name) {
	    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	    var r = window.location.search.substr(1).match(reg);
		if (r != null)
			return unescape(r[2]);
		return null;
    }

    refreshToken() {
        const params = {
            userName: localStorage.getItem('userName'),
            refreshToken: localStorage.getItem('refreshToken'),
        };

        return new Promise((resolve, reject) => {
            CertificationService
            .refreshToken(params)
            .then(res => {
                setCookie('access_token', res.accessToken, (res.expiresIn || 3600) - 5*60);

                localStorage.setItem('userName', res?.userName);
                localStorage.setItem('refreshToken', res?.refreshToken);
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
        });
    }

    async _request(json) {
        if(this.dynamicCommonHeaders) {
            this.commonHeaders = Object.assign({}, this.commonHeaders, this.dynamicCommonHeaders());
        }
       
        let ajaxData = {
            method: json.method || 'GET',
            url: json.url,
            baseURL: this.getQueryString('useMock') ? this.mockBaseUrl : json.baseURL || '',
            headers: Object.assign({}, this.commonHeaders, json.headers || {}),
        }

        ajaxData.headers['BazaReferer'] = window.location && window.location.href ? window.location.href : '';
        ajaxData.headers['BazaPageTitle'] = window.encodeURIComponent && window.document && window.document.title ? window.encodeURIComponent(window.document.title) : '';

        if(json.responseType) {
            ajaxData.responseType = json.responseType;
        }

        if(!ajaxData.baseURL) {
            ajaxData.baseURL = _host.portal;
            // if(
            //     location.host.indexOf('localhost') > -1 
            //     || location.host.indexOf('192.168.') > -1
            //     || location.host.indexOf('dev.liebide.com') > -1
            //     || location.host.indexOf('dev1.liebide.com') > -1 
            //     || location.host.indexOf('dev2.liebide.com') > -1 
            //     || location.host.indexOf('rc.liebide.com') > -1
            // ) {
            //     ajaxData.baseURL = '//www.rc2.liebide.com';
            // }
        }

        // 做整体拦截，刷新token
        // console.log(getCookie('access_token'))
        // const whiteListUrl = [
        //     '/openapi/Account/GetToken',
        //     '/Antiforgerytoken',
        //     `/openapi/AuthAccount/LoginedUserInfo`,
        //     `/openapi/Account/RefreshToken`,
        //     `/openapi/Account/LoginOut`,
        // ];
        
        // if(!getCookie('access_token') && !whiteListUrl.includes(ajaxData.url)) {
        //     console.log(ajaxData.url)
        //     await this.refreshToken();
        // }


        if(ajaxData.baseURL == 'LbdJavaApi') {
            // ajaxData.headers['Authorization'] = _getCookie('access_token');

            // 平台api的登录token的key，修改为AuthorizationPlatform
            if(json.javaProject && (json.javaProject === 'operation' || json.javaProject === 'javaSetting' || json.javaProject === 'javaCommon')) {
                ajaxData.headers['AuthorizationPlatform'] = _getCookie('access_token');
            } else {
                if (!(json.javaProject && json.javaProject === 'openIM')) { 
                    ajaxData.headers['Authorization'] = _getCookie('access_token');
                }
            }

            if(json.javaProject && json.javaProject === 'performance') {
                ajaxData.baseURL = _host.javaPerformance;
                // if(NODE_ENV == 'staging') {
                //     ajaxData.baseURL = '//headhuting-stg-admin.liebide.com';
                // }
                // if(location.host.indexOf('stg.liebide.com') > -1) {
                //     ajaxData.baseURL = '//headhuting-stg-admin.liebide.com';
                // }    

                var bazaTrackAppKey = "4dadfd1867ab45bd9710fffb1246543a";
                if (location.host == 'portal.stg.liebide.com') {
                    bazaTrackAppKey = "979e587c16dc40fd8a909f72904461cb";           
                }
                if (location.host == 'portal.liebide.com') {
                    bazaTrackAppKey = "40a7952f790b481ea70b07816f50ad61";
                }
                ajaxData.headers['BazaClientAppKey'] = bazaTrackAppKey;
                ajaxData.headers['BazaClientAppVersion'] = '5.0.2';
                var pageId = this.commonHeaders?.UPASession ? JSON.parse(this.commonHeaders.UPASession)?.PageViewId : '';
                ajaxData.headers['BazaPageId'] = pageId;
            } else if(json.javaProject && json.javaProject === 'operation') {
                ajaxData.baseURL = _host.javaOperation;
                // if(NODE_ENV == 'staging') {
                //     ajaxData.baseURL = '//api-credits-exchange-stg.bazhua.me';
                // }
                // if(location.host.indexOf('stg.liebide.com') > -1) {
                //     ajaxData.baseURL = '//api-credits-exchange-stg.bazhua.me';
                // }   
                
                var bazaTrackAppKey = "4dadfd1867ab45bd9710fffb1246543a";
                if (location.host == 'portal.stg.liebide.com') {
                    bazaTrackAppKey = "979e587c16dc40fd8a909f72904461cb";           
                }
                if (location.host == 'portal.liebide.com') {
                    bazaTrackAppKey = "40a7952f790b481ea70b07816f50ad61";
                }
                ajaxData.headers['BazaClientAppKey'] = bazaTrackAppKey;
                ajaxData.headers['BazaClientAppVersion'] = '5.0.2';
                var pageId = this.commonHeaders?.UPASession ? JSON.parse(this.commonHeaders.UPASession)?.PageViewId : '';
                ajaxData.headers['BazaPageId'] = pageId;
            } else if(json.javaProject && json.javaProject === 'point'){
                ajaxData.baseURL = _host.javaPoint;
                // if(NODE_ENV == 'staging') {
                //     ajaxData.baseURL = '//api-credits-exchange-stg.bazhua.me';
                // }
                // if(location.host.indexOf('stg.liebide.com') > -1) {
                //     ajaxData.baseURL = '//api-credits-exchange-stg.bazhua.me';
                // }    
            } else if(json.javaProject && json.javaProject === 'jdt') {
                ajaxData.baseURL = _host.javaJdt;
                // if(NODE_ENV == 'staging') {
                //     ajaxData.baseURL = 'https://api.stg.bazhua.me/api/jdtopenapi';
                // }
                // if(location.host.indexOf('stg.liebide.com') > -1) {
                //     ajaxData.baseURL = 'https://api.stg.bazhua.me/api/jdtopenapi';
                // } 
            } else if(json.javaProject && json.javaProject === 'openIM') {
                ajaxData.baseURL = _host.javaOpenIM;
            } else if(json.javaProject && json.javaProject === 'javaCommon') {
                ajaxData.baseURL = _host.javaCommon;
            } else if(json.javaProject && json.javaProject === 'oldJavaApi') {
                ajaxData.baseURL = _host.oldJavaApi;
            } else if(json.javaProject && json.javaProject === 'javaSetting') {
                ajaxData.baseURL = _host.javaSetting;

                var bazaTrackAppKey = "4dadfd1867ab45bd9710fffb1246543a";
                if (location.host == 'portal.stg.liebide.com') {
                    bazaTrackAppKey = "979e587c16dc40fd8a909f72904461cb";           
                }
                if (location.host == 'portal.liebide.com') {
                    bazaTrackAppKey = "40a7952f790b481ea70b07816f50ad61";
                }
                ajaxData.headers['BazaClientAppKey'] = bazaTrackAppKey;
                ajaxData.headers['BazaClientAppVersion'] = '5.0.2';
                var pageId = this.commonHeaders?.UPASession ? JSON.parse(this.commonHeaders.UPASession)?.PageViewId : '';
                ajaxData.headers['BazaPageId'] = pageId;
            }
            if(NODE_ENV == 'development') {
                if(json.baseURLPort) {
                    // ajaxData.baseURL = `http://192.168.1.191:${json.baseURLPort}`;
                    ajaxData.baseURL = _host.javaPoint;
                } else {
                    // ajaxData.baseURL = 'http://192.168.1.191:8701';
                    ajaxData.baseURL = _host.javaPerformance;
                }
                if(json.javaProject === 'jdt') {
                    ajaxData.baseURL = _host.javaJdt;
                }
                if(json.javaProject === 'javaSetting') {
                    ajaxData.baseURL = _host.javaSetting;
                }
            }
            // if(
            //     location.host.indexOf('localhost') > -1 
            //     || location.host.indexOf('dev.liebide.com') > -1 
            //     || location.host.indexOf('dev1.liebide.com') > -1 
            //     || location.host.indexOf('dev2.liebide.com') > -1 
            //     || location.host.indexOf('rc.liebide.com') > -1
            // ) {
            //     if(json.baseURLPort) {
            //         // ajaxData.baseURL = `http://192.168.1.191:${json.baseURLPort}`;
            //         ajaxData.baseURL = `//report-rc.8aza.com:${json.baseURLPort}`;
            //     } else {
            //         // ajaxData.baseURL = 'http://192.168.1.191:8701';
            //         ajaxData.baseURL = '//report-rc.8aza.com';
            //     }
            //     if(json.javaProject === 'jdt') {
            //         ajaxData.baseURL = 'https://api.rc.bazhua.me/api/jdtopenapi';
            //     }
            // }
        }else {
            if (ajaxData.url.indexOf('/openIM/apis') > -1 
                || ajaxData.url.indexOf('/api/imcenter/im') > -1 
                || ajaxData.baseURL == 'LbdOpenApi' 
                || ajaxData.url.indexOf('/api/mediacenter/media') > -1
                || ajaxData.url.indexOf('/api/phonecenter') > -1
                || ajaxData.url.indexOf('/api/tagcenter/tag') > -1
            ) {
                if(ajaxData.baseURL == 'LbdOpenApi') {
                    ajaxData.headers['AuthorizationLBD'] = _getCookie('access_token');

                    var bazaTrackAppKey = "4dadfd1867ab45bd9710fffb1246543a";
                    if (location.host == 'portal.stg.liebide.com') {
                        bazaTrackAppKey = "979e587c16dc40fd8a909f72904461cb";           
                    }
                    if (location.host == 'portal.liebide.com') {
                        bazaTrackAppKey = "40a7952f790b481ea70b07816f50ad61";
                    }
                    ajaxData.headers['BazaClientAppKey'] = bazaTrackAppKey;
                    ajaxData.headers['BazaClientAppVersion'] = '5.0.2';
                    var pageId = this.commonHeaders?.UPASession ? JSON.parse(this.commonHeaders.UPASession)?.PageViewId : '';
                    ajaxData.headers['BazaPageId'] = pageId;
                }
                ajaxData.baseURL = _host.openApi;
                // if(NODE_ENV == 'development') {
                //     ajaxData.baseURL = 'https://api.rc.bazhua.me';
                // }
                // if(NODE_ENV == 'staging') {
                //     ajaxData.baseURL = '//api.stg.bazhua.me';
                // }
                // if(
                //     location.host.indexOf('localhost') > -1 
                //     || location.host.indexOf('dev.liebide.com') > -1 
                //     || location.host.indexOf('dev1.liebide.com') > -1 
                //     || location.host.indexOf('dev2.liebide.com') > -1 
                //     || location.host.indexOf('rc.liebide.com') > -1
                //     || location.host.indexOf('spth.ibole.net') > -1
                // ) {
                //     ajaxData.baseURL = 'https://api.rc.bazhua.me';
                // }
                // if(location.host.indexOf('stg.liebide.com') > -1) {
                //     ajaxData.baseURL = '//api.stg.bazhua.me';
                // }
            }else {
                ajaxData.withCredentials = true;
                ajaxData.headers['AuthorizationLBD'] = _getCookie('access_token');

                var bazaTrackAppKey = "4dadfd1867ab45bd9710fffb1246543a";
                if (location.host == 'portal.stg.liebide.com') {
                    bazaTrackAppKey = "979e587c16dc40fd8a909f72904461cb";           
                }
                if (location.host == 'portal.liebide.com') {
                    bazaTrackAppKey = "40a7952f790b481ea70b07816f50ad61";
                }
                ajaxData.headers['BazaClientAppKey'] = bazaTrackAppKey;
                ajaxData.headers['BazaClientAppVersion'] = '5.0.2';
                // ajaxData.headers['BazaPageId'] = _generateUUID();
                // console.log(this);
                // console.log(JSON.parse(this.commonHeaders?.UPASession)?.PageViewId);
                var pageId = this.commonHeaders?.UPASession ? JSON.parse(this.commonHeaders.UPASession)?.PageViewId : '';
                ajaxData.headers['BazaPageId'] = pageId;

                if (json.data && json.data.__RequestVerificationToken) {
                    ajaxData.headers['RequestVerificationToken'] = json.data.__RequestVerificationToken;
                }
                if (json.data && Object.prototype.toString.call(json.data) == '[object FormData]' && json.data.get('__RequestVerificationToken')) {
                    ajaxData.headers['RequestVerificationToken'] = json.data.get('__RequestVerificationToken');
                }
            }
        }

        if(json.method.toUpperCase && json.method.toUpperCase() == 'GET') {
            ajaxData.params = json.data || {};
        }else if(json.method.toUpperCase && (json.method.toUpperCase() == 'POST' || json.method.toUpperCase() == 'DELETE') || json.method.toUpperCase() == 'PUT') {
            ajaxData.data = json.data || {};
        }
        if(ajaxData.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
            ajaxData.data = qs.stringify(ajaxData.data);
        }

        return await new Promise((resolve, reject) => {
            axios(ajaxData)
                .then(res => {
                    //下载文件
                    if(ajaxData.responseType && ajaxData.responseType == 'blob') {
                        resolve(res);
                        return false;
                    }
                    if(res.status == 200) {
                        // oldApi为了兼容老式接口数据未统一封装问题
                        if(json.apiType && json.apiType == 'oldApi') {
                            resolve(res.data);
                            return false;
                        }
                        
                        if(res.data.succeeded || res.data.success) {
                            resolve(res.data.data);
                        }else {
                            if(res.data && (res.data.errorCode == 401|| res.data.ErrorCode == 401|| res.data.code == 401 ||res.data.errorCode == 403|| res.data.ErrorCode == 403|| res.data.code == 403)) {
                                // && ajaxData.url !='/openapi/AuthAccount/LoginedUserInfo'
                                console.log('401--------')
                                console.log(ajaxData.url)
                                localStorage.setItem('url_401', ajaxData.url)
                                let list = JSON.parse(localStorage.getItem('url_401_list')) || [];
                                const errData = {
                                    url: ajaxData.url,
                                    headers: ajaxData.headers,
                                    res: res,
                                    time: new Date(),
                                };
                                list.unshift(errData);
                                if(list.length > 20) {
                                    list.length = 19;
                                }
                                localStorage.setItem('url_401_list', JSON.stringify(list));
                                
                                // IM和标签消息的401不跳转
                                if(!ajaxData.url.includes('/imcenter/im') && !ajaxData.url.includes('tagcenter/tag')) {
                                    clearCookie('access_token');
                                    // 处理401有returnUrl不回跳原页面的bug
                                    if(location.hash.includes('?ReturnUrl')) {
                                        location.href = `/Account/${location.hash}`;
                                    } else {
                                        location.href = '/Account/#/login?ReturnUrl=' + encodeURIComponent(location.pathname + location.search + location.hash);

                                        if(window.parent.location.hash == '#/batchCandidateDetail' || window.parent.location.hash == '#/batchOpportunityResumeDetail') {
                                            window.parent.location.href = '/Account/#/login?ReturnUrl=' + encodeURIComponent(window.parent.location.pathname + window.parent.location.hash);
                                        }
                                    }
                                    return false;
                                }
                            }
                            if(json.throwBusinessError) {
                                reject(res.data);
                            }else {
                                // console.log('e-----------------------------e')
                                // console.log(res)
                                // console.log(JSON.stringify(res))
                                reject('lbd-api-error');
                                if(res.data.error && res.data.error.description) {
                                    this.errMsgHandler && this.errMsgHandler(res.data.error.description);
                                } else if(res.data.errorMessage) {
                                    this.errMsgHandler && this.errMsgHandler(res.data.errorMessage);
                                } else {
                                    this.errMsgHandler && this.errMsgHandler(errorMessage(res.data));
                                    // this.errMsgHandler && this.errMsgHandler(res.data.message || res.data.errorMessage || '系统异常，请稍后重试');
                                }
                            }
                        }
                    }else {
                        if(json.throwHttpError) {
                            reject(res);
                        }else {
                            reject('lbd-api-error');
                            this.errMsgHandler && this.errMsgHandler('网络错误，请稍后重试');
                        }
                    }
                }).catch(err => {
                    // console.log(`err------------------------------res`)
                    // console.log(err)
                    // console.log(err.toString())
                    
                    //被取消的重复请求不做弹窗提示
                    if (err.constructor.prototype.toString() == 'Cancel') {
                        return false;
                    }

                    if(json.throwHttpError) {
                        reject(err);
                    }else {
                        // if(ajaxData.url != `/openapi/AuthAccount/LoginedUserInfo`) { // 这个用来验证是否登录，得排除401报错
                        if (err.toString().includes('403')) {
                            if (location.pathname.indexOf('/Account') > -1 && location.hash.indexOf('/login') > -1) {
                            } else {
                                this.errMsgHandler && this.errMsgHandler('权限不足，登录后重试');
                            }
                            location.href = '/Account/#/login';
                        } else {
                            this.errMsgHandler && this.errMsgHandler('网络错误，请稍后重试');
                        }
                        reject('lbd-api-error');
                    }
                })
        })


    }

    async _upaRequest(json) {

    }
}
