var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isNewProject ? _vm.showTools : true,
          expression: "isNewProject ? showTools : true",
        },
      ],
      staticClass: "float-option-wrap",
      class: _vm.isNewProject ? "position-top-bar" : "position-float",
      attrs: { id: "floatOption" },
      on: { mouseleave: _vm.handleToolsHide },
    },
    [
      _c(
        "div",
        { staticClass: "float-option" },
        [
          _vm.isShowIntegralGuide && !_vm.isNewProject
            ? _c(
                "div",
                {
                  staticClass: "float-item integral-ball default-hover",
                  attrs: { id: "integralBall" },
                  on: { click: _vm.goToMyPoint },
                },
                [
                  _c("p", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isShowPointUpdate,
                            expression: "!isShowPointUpdate",
                          },
                        ],
                        staticClass: "integral-count",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.integralCount, "", 0))
                        ),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowPointUpdate,
                            expression: "isShowPointUpdate",
                          },
                        ],
                        staticClass: "integral-count",
                      },
                      [
                        _c("ICountUp", {
                          ref: "countUp",
                          attrs: {
                            endVal: _vm.integralCount,
                            options: _vm.options,
                          },
                        }),
                        _c("br"),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v("我的积分")]
                    ),
                  ]),
                  _c("transition", { attrs: { name: "integral" } }, [
                    _vm.isShowIntegralTip
                      ? _c("div", { staticClass: "integral-tip" }, [
                          _vm._v(
                            "积分" +
                              _vm._s(_vm.increaseCount > 0 ? "+" : "") +
                              _vm._s(_vm.increaseCount)
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.isNewProject
            ? _c(
                "div",
                { staticClass: "float-item schedule-notice" },
                [
                  _c("notice-widget", {
                    ref: "noticeWidget",
                    attrs: { isNewProject: _vm.isNewProject },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "float-item-bottom",
              class: _vm.forceExpand ? "force-expand" : "",
            },
            [
              _vm.isShowWorkBox
                ? _c("div", { staticClass: "float-item work-box" }, [_vm._m(0)])
                : _vm._e(),
              _vm.isShowWorkReport
                ? _c(
                    "div",
                    { staticClass: "float-item daily-summary has-sub-menu" },
                    [
                      _vm._m(1),
                      _c("div", { staticClass: "sub-menu-container" }, [
                        _c(
                          "div",
                          { staticClass: "sub-menu" },
                          [
                            _vm.isShowWorkReport
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "首次请在6:30pm后查看日报数据哦",
                                      placement: "left",
                                      effect: "light",
                                      "popper-class": "report-tip",
                                      disabled: !_vm.hasHoverTip,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "float-item work-report",
                                        on: { click: _vm.showWorkReport },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "float-item-icon el-icon-document",
                                        }),
                                        _c("p", [
                                          _vm._v("工作"),
                                          _c("br"),
                                          _vm._v("日报"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isShowRealtimeData
                ? _c(
                    "div",
                    {
                      staticClass: "float-item realtime-data",
                      on: { click: _vm.showRealtimeData },
                    },
                    [_vm._m(2)]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "helpful-application",
                  staticClass: "float-item helpful-application has-sub-menu",
                  on: { click: _vm.handleShowAppDesc },
                },
                [_vm._m(3)]
              ),
              _c(
                "el-popover",
                {
                  staticClass: "float-item small-secretary",
                  attrs: {
                    placement: "left",
                    width: "152",
                    trigger: "hover",
                    "visible-arrow": false,
                    "popper-class": "help-detail-popover",
                  },
                },
                [
                  _c("div", { staticClass: "help-detail" }, [
                    _c("img", {
                      staticClass: "wechat-code",
                      attrs: {
                        width: "98",
                        height: "98",
                        src: _vm.helperWechatCode,
                        alt: "微信二维码",
                      },
                    }),
                    _c("p", { staticClass: "liebide-phone" }, [
                      _vm._v("0755-26014780"),
                    ]),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "liebide-email",
                          attrs: { href: "mailto: service@liebide.com" },
                        },
                        [_vm._v("service@liebide.com")]
                      ),
                    ]),
                  ]),
                  _c("p", { attrs: { slot: "reference" }, slot: "reference" }, [
                    _vm._v("小秘书"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("summary-dialog", {
            ref: "summaryDialog",
            attrs: { "close-summary-dialog": _vm.closeDialog },
          }),
          _c("realtimedata-dialog", {
            ref: "realtimedataDialog",
            attrs: { "close-realtimedata-dialog": _vm.closeRealtimedataDialog },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "float-option float-option-interval float-option-systemRecommend",
          class: [
            _vm.systemRecommendIsShow === 3 ? "show" : "",
            _vm.systemRecommendIsShow === 2 ? "hidden" : "",
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "float-item systemRecommend",
              on: { click: _vm.showSystemRecommend },
            },
            [_vm._m(4)]
          ),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showAppDesc
          ? _c("div", { staticClass: "helpful-app-desc" }, [
              _c("h3", [
                _vm._v("\n                全部应用\n                "),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: { click: _vm.handleHideAppDesc },
                }),
              ]),
              _c("ul", [
                _c("li", [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/floatOption/logo_xls.png"),
                    },
                  }),
                  _c("div", { staticClass: "helpful-app-desc-right" }, [
                    _c("p", { staticClass: "title" }, [_vm._v("简历小猎手")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "帮助你自动保存招聘网站简历、自动判重匹配人才库简历，快速更新旧简历的浏览器插件。"
                      ),
                      _c("br"),
                      _vm._v(
                        "当前支持领英、脉脉、猎聘、拉勾、智联、智联卓聘、前程无忧等主流招聘网站。"
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://xls.bazhua.me/download",
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "span",
                          { on: { click: _vm.downloadResumeHunter } },
                          [_vm._v("下载»")]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/floatOption/logo_lbd.png"),
                    },
                  }),
                  _c("div", { staticClass: "helpful-app-desc-right" }, [
                    _c("p", { staticClass: "title" }, [_vm._v("猎必得APP")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "\n                            1.APP也可在企业库快速找人，收藏简历、编辑简历、拨打电话、添加寻访、添加事项提醒；"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            2.实时查看我推荐的候选人的流程状态，快速处理推荐给我的候选人的流程状态；"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            3.可通过Trackinglist轻松跟进人选。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            4.智能拨号：支持在线拨打免费网络电话\n                        "
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.handleToggleAndroidAppQrcode },
                      },
                      [_vm._v("安卓下载»")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "ios-btn",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.handleToggleIOSAppQrcode },
                      },
                      [_vm._v("苹果下载»")]
                    ),
                    _c("div", { staticClass: "qrcode-box" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAndroidAppQrcode,
                            expression: "showAndroidAppQrcode",
                          },
                        ],
                        staticClass: "qrcode android-qrcode",
                        attrs: {
                          src: require("@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png"),
                        },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showIOSAppQrcode,
                            expression: "showIOSAppQrcode",
                          },
                        ],
                        staticClass: "qrcode ios-qrcode",
                        attrs: {
                          src: require("@src/assets/images/floatOption/qrcode_dl_ios_app.png"),
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm.forceExpand
        ? _c(
            "div",
            {
              staticClass: "helpful-app-guide",
              on: { click: (e) => e.stopPropagation() },
            },
            [
              _c(
                "div",
                {
                  staticClass: "helpful-app-guide-container",
                  style: { top: _vm.helpfulGuideTop + "px" },
                },
                [
                  _vm._m(5),
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/floatOption/helpful_app_guide.png"),
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "helpful-app-guide-btn",
                      on: { click: _vm.handleCloseHelpfulGuide },
                    },
                    [_vm._v("好的")]
                  ),
                ]
              ),
              _c("div", { staticClass: "helpful-app-guide-mask" }),
            ]
          )
        : _vm._e(),
      _c("global-inquiry-dialog", { ref: "globalInquiryDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("工具箱"),
      _c("br"),
      _c("i", { staticClass: "float-icon-triangle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("每日"), _c("br"), _vm._v("总结")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("实时"), _c("br"), _vm._v("数据")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("增值"), _c("br"), _vm._v("应用")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("智能"), _c("br"), _vm._v("推荐")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "helpful-app-guide-target" }, [
      _c("p", [_vm._v("增值"), _c("br"), _vm._v("应用")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }