var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notice-widget",
      class: _vm.isNewProject ? "" : "square-notice-widget",
      on: { click: _vm.showPopover },
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "notice-popover",
            placement: _vm.isNewProject ? "bottom" : "left",
            trigger: "click",
            "visible-arrow": false,
          },
          on: { show: _vm.handleTip, hide: _vm.handleActiveClass },
          model: {
            value: _vm.popoverVisible,
            callback: function ($$v) {
              _vm.popoverVisible = $$v
            },
            expression: "popoverVisible",
          },
        },
        [
          _c("notice-list", {
            ref: "noticeList",
            on: { handleClose: _vm.handleClose, keepVisible: _vm.keepVisible },
          }),
          _vm.isNewProject
            ? _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _c("font-icon", { attrs: { href: "#icon-shengyin" } }),
                  _vm.noticeCount > 0
                    ? _c("span", { staticClass: "notice-text" }, [
                        _vm._v("您今天有"),
                        _c("i", [_vm._v(_vm._s(_vm.noticeCount))]),
                        _vm._v("条事项提醒待完成，"),
                        _c("i", [_vm._v("点击查看")]),
                      ])
                    : _c("span", { staticClass: "notice-text" }, [
                        _vm._v("事项提醒，"),
                        _c("i", [_vm._v("点击查看")]),
                      ]),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "notice-widget-content",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _vm._v("\n            事项"),
                  _c("br"),
                  _vm._v("提醒\n            "),
                  _vm.noticeCount
                    ? _c("span", { staticClass: "corner-count" }, [
                        _vm._v(_vm._s(_vm.noticeCount)),
                      ])
                    : _vm._e(),
                ]
              ),
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTip,
              expression: "showTip",
            },
          ],
          staticClass: "notice-tip",
          class: _vm.isNewProject ? "position-bottom" : "position-left",
        },
        [
          _vm._v(
            _vm._s(_vm.isWorking ? `${_vm.period}好` : "下班喽") + "，你今天有"
          ),
          _c("i", [_vm._v(_vm._s(_vm.noticeCount))]),
          _vm._v(
            "个提醒事项" + _vm._s(_vm.isWorking ? "待" : "还没") + "完成~"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }