<template>
    <div class="announcements-tip" v-if="enableShow">
        <div class="announcements-tip-container">
            <template v-if="user.isHeadhunter">
                <div class="announcements-tip-content">
                    <template v-if="!user.emailConfirmed">
                        <!-- 您的邮箱尚未验证，为您能及时获取职位信息，请<a class="site-bulletin-link" href="/Headhunting/MyCompany.html#/emailVerify" target="_blank">点击验证 &gt;</a>。 -->
                        <!-- 您的邮箱尚未验证，为您能及时获取职位信息，请<a class="site-bulletin-link" href="/Headhunting/#/personalCenter?isEmailVerify=true" target="_blank">点击验证 &gt;</a>。 -->
                        您的邮箱尚未验证，为您能及时获取职位信息，请<a class="site-bulletin-link" href="/Headhunting/#/personalCenter?Tab=accountSet" target="_blank">点击验证 &gt;</a>。
                    </template>
                    <template v-else-if="user.joiningStep === 2">
                        您申请创建的<span class="site-bulletin-firm">{{user.firmShortName}}</span>，小猎正加快给您审核，请耐心等待。
                    </template>
                    <template v-else-if="user.joiningStep === 3">
                        您正在加入<span class="site-bulletin-firm">{{user.firmShortName}}</span>，管理员尚未通过审核，请联系管理员。
                    </template>
                    <template v-else-if="!user.joiningStep || user.joiningStep === 0">
                        您尚未创建／加入公司，完成认证后即可做单，<a class="site-bulletin-link" href="/Firm/Search" target="_blank">点击认证 &gt;</a>。
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="announcements-tip-content">
                    <template v-if="!user.emailConfirmed && (user.joiningStep === 3 || user.joiningStep === 2)">
                        您正在{{user.joiningStep === 3 ? '加入' : '创建的'}}<span class="site-bulletin-firm">{{user.firmShortName}}</span>{{user.joiningStep === 2 ? '正在审核中' : ''}}，验证邮箱<span class="site-bulletin-link">{{user.email}}</span>可以加快审核速度哦！
                        <a class="site-bulletin-action site-bulletin-email" href="javascript: void(0);" v-if="!isSendVerifyEmail" @click="emailVerify">发送验证链接</a>
                        <a class="site-bulletin-action site-bulletin-email" href="javascript: void(0);" v-else>{{remainSecondSend}}s 后可重新发送</a>
                    </template>
                    <template v-if="user.emailConfirmed && (user.joiningStep === 3 || user.joiningStep === 2)">
                        您正在{{user.joiningStep === 3 ? '加入' : '创建的'}}<span class="site-bulletin-firm">{{user.firmShortName}}</span>{{user.joiningStep === 2 ? '正在审核中。先去发职位吧！' : '，管理员尚未通过审核，请联系管理员。'}}
                    </template>
                    <template v-if="!user.emailConfirmed && user.joiningStep === 4">
                        您已成功加入<span class="site-bulletin-firm">{{user.firmShortName}}</span>，就差验证邮箱了。
                        <a class="site-bulletin-action site-bulletin-email" href="javascript: void(0);" v-if="!isSendVerifyEmail" @click="emailVerify">发送验证链接</a>
                        <a class="site-bulletin-action site-bulletin-email" href="javascript: void(0);" v-else>{{remainSecondSend}}s 后可重新发送</a>
                    </template>
                    <template v-if="!user.joiningStep || user.joiningStep === 0">
                        您尚未创建／加入公司，完成认证后即可做单，<a class="site-bulletin-link" href="/Firm/Search" target="_blank">点击认证 &gt;</a>。
                    </template>
                </div>
                <!-- <button type="button" class="tip-operate" @click="cancelFirmApply(user.joiningStep)" v-if="user.joiningStep === 2 || user.joiningStep === 3">取消申请</button> -->
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: Object
    },
    computed: {
        enableShow() {
            // return (location.href.indexOf('/Headhunting/MyCompany.html#/emailVerify') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
            // return (location.href.indexOf('/Headhunting/#/personalCenter?isEmailVerify=true') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
            return (location.href.indexOf('/Headhunting/#/personalCenter?Tab=myAdvantage') < 0) && ((this.user && this.user.joiningStep !== 4) || !this.user.emailConfirmed || false);
        }
    },
    data() {
        return {
            isSendVerifyEmail: false,
            remainSecondSend: 60
        }
    },
    mounted() {},
    methods: {
        emailVerify() {
            _request({
                url: '/Email/Validate',
                method: 'POST',
                data: {
                    email: this.user.email
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                throwBusinessError: true
            }).then(res => {
                // console.log(res)
                // if(!res.succeeded) {
                //     console.log(1111111)
                //     shortTips(res.message || '获取失败!');
                // } else {
                //     console.log(222222)
                    shortTips('邮件已发送');
                    this.isSendVerifyEmail = true;
                    let reSendInterval = setInterval(() => {
                        this.remainSecondSend--;
                        if(this.remainSecondSend == 0) {
                            this.isSendVerifyEmail = false;
                            this.remainSecondSend = 60;
                            clearInterval(reSendInterval);
                        }
                    }, 1000);
                // }
            }).catch( err => {
                // console.log(err);
                shortTips(err.message || '获取失败!');
            });
        },
        cancelFirmApply(step) {
            let url = step == 3 ? '/Me/CancelFirmApplication' : '/Me/CancelFirmJoiningApplication';
            _request({
                url: url,
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                shortTips("取消成功");
                this.user.joiningStep === 0;
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scope>
.announcements-tip {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #faecca;
    background-color: #fcf8e2;
    &-container {
        width: 1200px;
        margin: 0 auto;
    }
    &-content {
        font-size: 16px;
        line-height: 50px;
        color: #8b6e37;
        .site-bulletin-link,
        .site-bulletin-firm,
        .site-bulletin-email {
            color: #333;
            padding: 0 3px;
        }
        .site-bulletin-link:hover {
            color: $primary;
            cursor: pointer;
        }
    }
}
</style>