<template>
  <div class="base" :class="[ isMine ? 'right' : 'left']">
    <div class="name text-ellipsis">{{ from }}</div>
    <div class="date">{{ date }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTime, getFullDate, isToday } from '../utils/date.js'
export default {
  name: 'MessageHeader',
  props: {
    message: {
      type: Object,
      required: true
    },
    currentConversation: {
      type: Object,
      required: true
    },
    currentUserProfile: {
      type: Object,
      required: true
    },
  },
  computed: {
    date() {
      let time = new Date(this.message.msgTime);
      if (time.toString().indexOf('1970') > -1) {
        time = new Date(this.message.msgTime * 1000);
      }
      if(isToday(time)) {
        return getTime(time);
      }
      return getFullDate(time)
    },
    from() {
      // const isC2C = this.currentConversation.type === this.TIM.TYPES.CONV_C2C
      // if (this.isMine) {
      //   return this.currentUserProfile.nick || this.currentUserProfile.userID
      // }
      // if (isC2C) {
      //   return (
      //     this.currentConversation.userProfile.nick ||
      //     this.currentConversation.userProfile.userID
      //   )
      // } else if (this.currentConversation.type === this.TIM.TYPES.CONV_SYSTEM) {
      //   return this.message.type === this.TIM.TYPES.MSG_GRP_SYS_NOTICE
      //     ? '群系统通知'
      //     : '系统通知'
      // }
      if(this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
        return this.isMine || (this.message.msgType === TIM.TYPES.MSG_CUSTOM && this.message.msgContent.data === 'group_create') ? "" : this.message.fromAccountName;
      } else {
        return ""
      }
    },
    isMine() {
      return this.message.fromAccountId === this.currentUserProfile.userID
    }
  }
}
</script>

<style lang="stylus" scoped>
.right {
  display: flex;
  flex-direction: row-reverse;
}

.left {
  display: flex;
  flex-direction: row;
}

.base {
  color: $secondary;
  font-size: 12px;
  color: #999;
}

.name {
  padding: 0 4px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
