<template>
<div class="topbar-wrap">
    <!-- <div :class="showBtn?'topbar-wrap':'topbar-wrap has-tab'"> -->
    <div class="cell">
        <img
            alt="logo"
            src="~@src/assets/images/workbench/workbench-logo.png"
            @click="jumpHomePage"
        />
        <!-- 今日提醒 -->
        <div class="schedule-notice">
            <notice-widget ref="noticeWidget"></notice-widget>
        </div>
    </div>
    <div class="cell topbar-wrap-right">
        <!-- 今日提醒end -->
        <!-- <point-card v-if="user.isCFUser" id="pointCard" @showPointRank="showPointRank"></point-card> -->
        <el-dropdown placement="bottom" v-show="isDepartmentPermission || isAdministrator">
            <!-- <span class="topbar-btn department-btn">
                <font-icon class="topbar-btn-icon" href="#icon-plus"></font-icon>
                <span>组织架构</span>
            </span> -->
            <button class="custom-btn">
                <font-icon
                    class="btn-icon"
                    href="#icon-zuzhijiagou"
                ></font-icon>组织架构
            </button>
            <el-dropdown-menu class="top-bar no-arrow" slot="dropdown">
                <el-dropdown-item @click.native="showDepartmentDialog">单个创建</el-dropdown-item>
                <el-dropdown-item v-if="auditCustomerList.length > 0" @click.native="showCustomerSelectDialog">批量创建</el-dropdown-item>
                <el-dropdown-item v-if="(isDepartmentManageAudit || isAdministrator) && auditCustomerList.length > 0" @click.native="jumpApplyList('audit')">我的审批</el-dropdown-item>
                <el-dropdown-item @click.native="jumpApplyList('apply')">我的申请</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        
            <el-dropdown placement="bottom" v-show="isJobPermission || isAdministrator">
                <button class="custom-btn">
                    <font-icon
                        class="btn-icon"
                        href="#icon-file-add"
                    ></font-icon>职位
                </button>
                <el-dropdown-menu class="top-bar no-arrow" slot="dropdown">
                    <el-dropdown-item @click.native="createJob('/jobCreate/single', 'createSingleJob')">单个创建</el-dropdown-item>
                    <el-dropdown-item @click.native="createJob('/jobCreate/multi', 'createBatchJob')">批量创建</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
                <!-- <el-dropdown-item v-if="isBindingPinganAccount && isBindingPingnAccountAndJobDistributor" @click.native="gotoLink(`/Headhunting/MyCompany.html#/Customer/${appointedCustomersId}#jobOperation`)">分配平安职位</el-dropdown-item>
                    <el-dropdown-item v-if="isBindingPinganAccount && !isBindingPingnAccountAndJobDistributor"  @click.native="createJob(`/Headhunting/MyCompany.html#/Customer/${appointedCustomersId}#jobOperation`, 'createPinanJob')">创建平安职位</el-dropdown-item> -->
            <el-dropdown ref="resumeUploadDropdown" placement="bottom" v-show="isTalentPermission || isAdministrator">
                <!-- <span class="el-dropdown-link">
                    <a href="javascript:;"><span class="topbar-btn"><font-icon class="topbar-btn-icon" href="#icon-plus"></font-icon>简历</span></a>
                </span> -->
                <button class="custom-btn">
                    <font-icon
                        class="btn-icon"
                        href="#icon-add-circle"
                    ></font-icon>简历
                </button>
                <el-dropdown-menu class="top-bar no-arrow" slot="dropdown">
                    <el-dropdown-item @click.native="gotoLink('/Headhunting/MyCompany.html?type=1#/resumeUpload/singleUpload')">企业简历</el-dropdown-item>
                    <el-dropdown-item @click.native="gotoLink('/Headhunting/MyCompany.html?type=0#/resumeUpload/singleUpload')">个人简历</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="gotoLink('/?type=1#/resumeUpload/singleUpload')">企业简历</el-dropdown-item>
                    <el-dropdown-item @click.native="gotoLink('/?type=0#/resumeUpload/singleUpload')">个人简历</el-dropdown-item> -->
                </el-dropdown-menu>
            </el-dropdown>


            <el-popover
                placement="bottom"
                width="112"
                trigger="hover"
                :open-delay="500"
                :visible-arrow="false"
                popper-class="kefu-popover">
                <div class="kefu-detail">
                    <img class="wechat-code" width="98" height="98" src="~@src/assets/images/service-qr-code.png" alt="微信二维码">
                    <p class="liebide-phone">客服微信</p>
                </div>
                <span class="icon-wrap" slot="reference">
                    <font-icon
                        class="btn-icon-b"
                        href="#icon-kefu"
                    ></font-icon>
                </span>
            </el-popover>

            <el-popover
                width="328"
                trigger="hover"
                :open-delay="500"
                placement="bottom"
                v-model="appCardVisible"
                :visible-arrow="false"
                popper-class="app-card-popover"
                @show="appCardShow">
                <div class="app-card">
                    <div class="app-card-left">
                        <img src="@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png" alt="猎必得app" width="115px" height="115px" />
                        <span class="app-card-title">扫码下载猎必得app</span>
                    </div>
                    <div class="app-card-right">
                        <p class="app-card-text">
                            1.随时随地，极速找人<br/>
                            2.实时处理/查看推荐状态<br/>
                            3.轻松跟进人选
                        </p>
                        <p class="app-card-text">
                            更多功能，敬请期待…
                        </p>
                        <p class="app-card-text app-card-tip">
                            八爪盒子app即是猎必得app
                        </p>
                    </div>
                </div>
                <span class="icon-wrap" slot="reference">
                    <font-icon
                        class="btn-icon-b"
                        href="#icon-shoujihao"
                    ></font-icon>
                </span>
                
                <!-- <span class="topbar-btn app-card-btn" slot="reference">
                    <i class="el-icon-mobile topbar-btn-icon"></i>
                    <span>移动端</span>
                </span> -->
            </el-popover>

            <el-dropdown
                trigger="hover"
                placement="bottom"
                :visible-arrow="false"
                @command="handleToolsCommand">
                <span class="icon-wrap">
                    <font-icon
                        class="btn-icon-b"
                        href="#icon-modular"
                    ></font-icon>
                </span>
                <el-dropdown-menu slot="dropdown" class="tools-dropdown-menu no-arrow">
                    <el-dropdown-item command="workDayReport">
                        <div class="item-wrap">
                            <font-icon
                                class="item-icon"
                                href="#icon-survey"
                            ></font-icon>
                            <span class="item-text">工作日报</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="realtimeData">
                        <div class="item-wrap">
                            <font-icon
                                class="item-icon"
                                href="#icon-chart-bar"
                            ></font-icon>
                            <span class="item-text">实时数据</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="appDesc">
                        <div class="item-wrap">
                            <font-icon
                                class="item-icon"
                                href="#icon-modular"
                            ></font-icon>
                            <span class="item-text">增值应用</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-popover
                            placement="left"
                            width="152"
                            trigger="hover"
                            :visible-arrow="false"
                            popper-class="help-detail-popover">
                            <div class="help-detail">
                                <img class="wechat-code" width="98" height="98" :src="helperWechatCode" alt="微信二维码">
                                <p class="liebide-phone">0755-26014780</p>
                                <p>
                                    <a href="mailto: service@liebide.com" class="liebide-email">service@liebide.com</a>
                                </p>
                            </div>
                            <div class="item-wrap" slot="reference">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-work"
                                ></font-icon>
                                <span class="item-text">小秘书</span>
                                <font-icon
                                    class="item-arrow-icon"
                                    href="#icon-right-copy"
                                ></font-icon>
                            </div>
                        </el-popover>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item divided>
                        <el-popover
                            placement="left"
                            width="152"
                            trigger="hover"
                            :visible-arrow="false"
                            popper-class="help-detail-popover">
                            <div class="help-detail">
                                <img class="wechat-code" width="98" height="98" src="~@src/assets/images/service-qr-code.png" alt="微信二维码">
                                <p class="liebide-phone">客服微信</p>
                            </div>
                            <div class="item-wrap" slot="reference">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-kefu"
                                ></font-icon>
                                <span class="item-text">联系客服</span>
                                <font-icon
                                    class="item-arrow-icon"
                                    href="#icon-right-copy"
                                ></font-icon>
                            </div>
                        </el-popover>
                    </el-dropdown-item> -->
                    <el-dropdown-item divided command="loveStudy" v-if="user.isCFUser">
                        <div class="item-wrap">
                            <font-icon
                                class="item-icon"
                                href="#icon-electronics"
                            ></font-icon>
                            <span class="item-text">学院学习</span>
                            <!-- <font-icon
                                class="item-arrow-icon"
                                href="#icon-right-copy"
                            ></font-icon> -->
                        </div>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item>
                        <el-popover
                            placement="left"
                            width="312"
                            title="发送链接邀请朋友注册"
                            trigger="hover"
                            :visible-arrow="false"
                            popper-class="invite-friend-popover">
                            <div class="invite-detail">
                                <p>{{invitationUrl}}</p>
                                <el-row>
                                    <button 
                                        type="primary" 
                                        ref="copyBtn"
                                        class="copy-btn"
                                        :data-clipboard-text="invitationUrl"
                                        >复制</button>
                                </el-row>
                            </div>
                            <div class="item-wrap" slot="reference">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-fenxiang"
                                ></font-icon>
                                <span class="item-text">邀请好友</span>
                                <font-icon
                                    class="item-arrow-icon"
                                    href="#icon-right-copy"
                                ></font-icon>
                            </div>
                        </el-popover>
                    </el-dropdown-item> -->
                </el-dropdown-menu>
            </el-dropdown>

            <span class="im-btn" ref="IMBtn" @click.stop="showIMDialog">
                <font-icon
                    class="btn-icon-im"
                    href="#icon-xiaoxizhongxin"
                ></font-icon>
                <span class="num" 
                    v-if="messageNum > 0 && messageNum <= 99">{{messageNum}}</span>
                <span class="num-more-100" v-if="messageNum>99"></span>
            </span>

            <!-- <span
                ref="IMBtn"
                class="topbar-btn topbar-btn-chat"
                :class="{'is-hover': isShowIMDialog, 'has-new-message': messageNum > 0}"
                @click.stop="showIMDialog"
            >
                <font-icon class="topbar-btn-icon chat-hover" href="#icon-ic_chat-active"></font-icon>
                <font-icon class="topbar-btn-icon chat-no-hover" href="#icon-ic_chat"></font-icon>
                消息
                <template v-if="messageNum > 0">
                    <span class="im-num">{{messageNum > 99 ? '···' : messageNum}}</span>
                </template>
            </span> -->
        <!-- </div> -->

        <!-- <div @mouseleave="handleToolsHide">
            <span class="tools-btn-wrap" @mouseenter="handleToolsShow">
                工具箱
                <font-icon class="tools-btn" :href="toolsShow ? '#icon-toggle-close' : '#icon-toggle-open'"></font-icon>
            </span>
        </div> -->
        <summary-entry ref="summaryEntry" :user="user" v-if="!!user.firmId"></summary-entry>

        <el-popover
            placement="bottom-end"
            width="262"
            popper-class="avator-popper"
            :visible-arrow="false"
            trigger="hover"
            >
            <span class="topbar-avatar" slot="reference">
                <img :src="avatarBase64 || `${userAvatar}?size=60`" v-if="userAvatar" alt="avator">
                <img src="~@src/assets/images/default-user.png" v-else alt="avator">
            </span>
            <div class="avator-wrap">
                <div class="avator-top" >
                    <div class="pic" @click="gotoLink('/#/personalCenter')">
                        <img :src="avatarBase64 || `${userAvatar}?size=60`" v-if="userAvatar" alt="avator">
                        <img src="~@src/assets/images/default-user.png" v-else alt="avator">
                    </div>
                    <div class="infos" @click="gotoLink('/#/personalCenter')">
                        <p class="name ">
                            <span class="name-txt ellipsis">{{user.realName}}</span>
                            <font-icon
                                class="name-icon"
                                href="#icon-right-copy"
                            ></font-icon>
                        </p>
                        <p class="email">{{user.email}}</p>
                    </div>
                </div>
                <div class="func-wrap">
                    <div class="func-cell" @click="gotoLink('/#/personalCenter')">
                        <font-icon
                            class="func-icon"
                            href="#icon-shouye"
                        ></font-icon>
                        <p class="txt">我的资料</p>
                    </div>
                    <div class="func-cell" @click="gotoLink('/Headhunting/MyCompany.html#/myPoint')" v-if="user.isCFUser">
                        <font-icon
                            class="func-icon"
                            href="#icon-lihe"
                        ></font-icon>
                        <p class="txt">我的积分</p>
                    </div>
                    <div class="func-cell" @click="showSuggestFeedBack">
                        <font-icon
                            class="func-icon"
                            href="#icon-pinglun"
                        ></font-icon>
                        <p class="txt">意见反馈</p>
                    </div>
                    <div class="func-cell" @click="gotoLink('https://www.yuque.com/r/liebide/books')">
                        <font-icon
                            class="func-icon"
                            href="#icon-bangzhu"
                        ></font-icon>
                        <p class="txt">帮助中心</p>
                    </div>
                    <div class="func-cell" @click="logout">
                        <font-icon
                            class="func-icon"
                            href="#icon-tuichu"
                        ></font-icon>
                        <p class="txt">退出登录</p>
                    </div>
                </div>
            </div>
        </el-popover>

        <!-- <el-dropdown class="topbar-user" placement="bottom">
            <span class="el-dropdown-link">
                <span class="topbar-avatar">
                    <img :src="avatarBase64 || `${userAvatar}?size=60`" v-if="userAvatar" alt="">
                    <img src="~@src/assets/images/default-user.png" v-else alt="">
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu
                class="top-bar user-list"
                slot="dropdown">
                <el-dropdown-item  @click.native="gotoLink('/#/personalCenter')">
                    <font-icon href="#icon-person"></font-icon>
                    我的资料
                </el-dropdown-item>
                <el-dropdown-item  @click.native="gotoLink('/Headhunting/MyCompany.html#/myPoint')" v-if="user.isCFUser">
                    <font-icon class="default" href="#icon-gift1"></font-icon>
                    <font-icon class="hover" href="#icon-gift1-copy"></font-icon>
                    我的积分
                </el-dropdown-item>
                <el-dropdown-item  @click.native="gotoLink('https://www.yuque.com/r/liebide/books')">
                    <font-icon class="default" href="#icon-bangzhu1"></font-icon>
                    <font-icon class="hover" href="#icon-bangzhu2"></font-icon>
                    帮助中心
                </el-dropdown-item>
                <el-dropdown-item @click.native="showSuggestFeedBack">
                    <font-icon class="default" href="#icon-topbar_suggest"></font-icon>
                    <font-icon class="hover" href="#icon-topbar_suggest-copy"></font-icon>
                    意见反馈
                </el-dropdown-item>
                <el-dropdown-item  @click.native="logout">
                    <font-icon href="#icon-exit"></font-icon>
                    退出
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown> -->

        <!-- 
        <el-dropdown-item  @click.native="gotoLink('/Headhunting/MyCompany.html#/personalCenter')">
            <font-icon href="#icon-person"></font-icon>
            我的资料
        </el-dropdown-item>
        <el-dropdown-item  @click.native="gotoLink('/Headhunting/MyCompany.html#/personalCenter?Tab=Password')">
            <font-icon href="#icon-lock1"></font-icon>
            修改密码
        </el-dropdown-item>
        <el-dropdown-item  @click.native="gotoLink('/#/personalCenter?Tab=Password')">
            <font-icon href="#icon-lock1"></font-icon>
            修改密码
        </el-dropdown-item> -->

        <!-- 这里可能要移动一下位置重新设计 -->
        <!-- <announcement-tip 
            :user="user" 
            v-if="user.id && !isFirmJoining"
            >
        </announcement-tip> -->

        <suggest-feedback-dialog 
            ref="suggestFeedback">
        </suggest-feedback-dialog>

        <auth-customer-select-dialog 
            ref="authCustomerSelectDialog" 
            :customerList="auditCustomerList" 
            @authCustomerSelected="handleAuthCustomerSelect">
        </auth-customer-select-dialog>

        <un-audit-customer-department-dialog 
            ref="unAuditCustomerDepartmentDialog" 
            @refresh-audit-list="getAuditList">
        </un-audit-customer-department-dialog>
    </div>
</div>
</template>
<script>
import { account } from '#/js/config/api.json';
import PointCard from './pointCard';
import NoticeWidget from '#/component/notice/noticeWidget';
import FloatOption from '#/component/page/float-option/float-option.vue';
import SummaryEntry from '#/component/summary/summary-entry.vue';
import AnnouncementTip from '#/component/announcement-tip.vue';
import SuggestFeedbackDialog from '#/component/common/dialog/suggest-feedback-dialog.vue';
import AuthCustomerSelectDialog from '#/component/common/dialog/auth-customer-select-dialog.vue'
import CustomerService from '#/js/service/customerService.js';
import CertificationService from '#/js/service/certificationService.js';
import eventBus from '#/js/util/event-bus.js';
import ClipboardJS from 'clipboard';    //文字复制插件

import { mapActions } from 'vuex';

import DepartmentService from '#/js/service/departmentService.js';
import UnAuditCustomerDepartmentDialog from '#/component/common/dialog/un-audit-customer-department-dialog.vue';
export default {
    name: 'topbar',
    componentName: 'TopBar',
    props:{
        showBtn:{
            type: Boolean,
            default:true
        }
    },
    components:{
        PointCard,
        NoticeWidget,
        FloatOption,
        SummaryEntry,
        AnnouncementTip,
        SuggestFeedbackDialog,
        AuthCustomerSelectDialog,
        UnAuditCustomerDepartmentDialog
    },
    data() {
        return {
            auditList: [],
            unAuditCustomerDepartmentNum: 0,
            
            appointedCustomersId:'',
            messageNum: 0,
            // toolsShow: false,
            isShowIMDialog: false,
            appCardVisible: false,
            auditCustomerList: [],
            avatarBase64: '',

            helperWechatCode: "//hstatic.hirede.com/lbd/images/helper/hh-helper-wechat.jpg", //小秘书二维码
            invitationUrl: '',
        }
    },
    created() {
        // this.getAuditList();
    },
    mounted() {
        // this.getAppointedCustomers();
        this.$on('has-new-msg', (item) => {
            this.messageNum = item.num;
            setTimeout(() => {
                this.$refs.IMBtn.classList.remove('btn-chat-active');
                if(this.messageNum > 0) {
                    if(!this.$refs.IMBtn.classList.contains('is-hover')) {
                        this.$refs.IMBtn.classList.add('btn-chat-active');
                    }
                }
            }, 100)
        });
        this.$on('close-im', () => {
            this.isShowIMDialog = false;
        });

        eventBus.$on('setAvatarBase64', data => {
            this.avatarBase64 = data;
        });

        // this.getHaveAuditPermissionsCustomerList();

        // console.log(this.$store.state.user)
        // console.log(this.$store.state.user.userInfo)
        // if(this.user.isAdministrator || this.isBusinessPermission){
        //     this.getHaveAuditPermissionsCustomerList();
        // }

        this.invitationUrl = window.location.origin + "/Account/#/login?UserSource=Invitation&InviterId=" + this.$store.state.user.userInfo.id;
            
        // this.initClipboard();
        
    },
    computed: {
        user(){
            return this.$store.state.user.userInfo;
        },
        // isBindingPinganAccount(){
        //     return this.user.isBindingPinganAccount;
        // },
        // isBindingPingnAccountAndJobDistributor(){
        //     return this.user.isBindingPingnAccountAndJobDistributor;
        // },
        verificationToken() {
            return this.$store.state.verificationToken.verificationToken
        },
        userAvatar(){
            return this.user.avatarUrl ? this.user.avatarUrl.replace('localhost:17984', 'www.rc2.liebide.com') : '';
        },
        isAdministrator(){
            return this.user.isAdministrator;
        },
        isJobPermission() {
            return (this.user.privilegeCodeList || []).includes('Job');
        },
        isCustomerPermission() {
            return (this.user.privilegeCodeList || []).includes('Customer');
        },
        isTalentPermission() {
            return (this.user.privilegeCodeList || []).includes('Talent');
        },
        isBusinessPermission() {
            return this.user.privilegeCodeList;
        },
        isFirmJoining() {
            let pathList = ['/firmSearch', '/firmCreate', '/firmJoining', '/firmQuestionnaire'];
            return pathList.indexOf(this.$route.path) > -1;
        },
        isDepartmentCreate() {
            return (this.user.privilegeCodeList || []).includes('DepartmentManageAudit');
        },
        isDepartmentManageAudit() {
            return (this.user.privilegeCodeList || []).includes('DepartmentReviewer');
        },
        isDepartmentPermission() {
            return this.user.privilegeCodeList?.includes('DepartmentManageAudit') || this.user.privilegeCodeList?.includes('DepartmentReviewer');
        },
    },
    watch: {
        // isAdministrator(val){
        //     // console.log("-00000000000001")
        //     if(val){
        //         // console.log(-111111111)
        //         this.getHaveAuditPermissionsCustomerList();
        //     }
        // },
        isBusinessPermission(val) {
            if(val && val.length>0 && (val.includes('CustomerCreateAudit') || val.includes('DepartmentReviewer') )){
                this.getHaveAuditPermissionsCustomerList();

                this.getAuditList();
            }
        }
    },
    methods: {
        initClipboard() {
            const copyLink = new ClipboardJS(this.$refs.copyBtn);
            copyLink.on('success', (res) => {
                shortTips("复制成功");
            });
            copyLink.on('error', (err) => {
                shortTips("复制失败，请手动复制。");
            });
        },

        jumpHomePage() {
            location.href = '/';
        },
        
        getAuditList() {
            DepartmentService.getAuditList().then(res => {
                this.auditList = res || [];
                this.unAuditCustomerDepartmentNum = res.length;
            }).catch(err => {
                console.log(err);
            })
        },

        gotoLink(url){
            console.log("🚀 ~ gotoLink ~ url:", url)
            // @click.native="jumpApplyList('apply')"

            // gotoLink('/#/personalCenter'
            if(url == '/#/personalCenter') {
                const item = {
                    "enTitle": "",
                    "cnTitle": "个人中心",
                    "path": "/personalCenter",
                    "aliveComponent": "personalCenter",
                    "isActive": true
                };
                // "enTitle": "",
                //     "cnTitle": "我的申请-组织架构",
                //     "path": "/departmentApplyList",
                //     "aliveComponent": "DepartmentApplyList",
                //     "isActive": true
                this.invokePushTabItems(item);
                this.$router.push({ path: item.path });
            } else {
                window.open(url);
            }
            
        },
        // getAppointedCustomers(){
        //     let _this = this;
        //     if(window.appointedCustomersId) {
        //         _this.appointedCustomersId = window.appointedCustomersId;
        //         return true;
        //     }
        //     $.get('/Firm/GetAppointedCustomers').then(res =>{
        //         if(res.succeeded || res.Succeeded) {
        //             _this.appointedCustomersId = res.data[0].defaultCustomerId;
        //             window.appointedCustomersId = res.data[0].defaultCustomerId;
        //         }else{
        //             if(res.errorCode == 401 || res.ErrorCode == 401) {
        //                 location.href = '/Login';
        //             }
        //         }
        //     }).fail(err => {
        //     })
        // },
        logout() {
            // _request({
            //     method: 'POST',
            //     url:account.quit,
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     },
            //     data: {
            //         __RequestVerificationToken: this.verificationToken
            //     }
            // })

            
            const params = {
                accessToken: _getCookie('access_token'),
                userName: localStorage.getItem('userName'),
            };
            CertificationService
            .loginOut(params)
            .then(()=>{
                clearCookie('access_token');

                if(this.$refs.summaryEntry && openIM) {
                    this.$refs.summaryEntry.quitIM();
                }
                sessionStorage.removeItem('perpormancesDetailData');
                localStorage.removeItem('perpormancesDetailData');
                location.href = '/Account/#/login?ReturnUrl=' + encodeURIComponent(location.pathname + location.search + location.hash);
            })
        },
        handleToolsShow(){
            this.$refs.summaryEntry.$refs.floatOption.handleToolsShow();
            document.querySelector(".float-option-wrap").style.zIndex = "1001";
            // this.toolsShow = true;
        },
        handleToolsHide() {
            this.$refs.summaryEntry.$refs.floatOption.handleToolsHide();
            document.querySelector(".float-option-wrap").style.zIndex = "";
            // this.toolsShow = false;
        },
        showIMDialog() {
            _tracker.track('ShowInstantMessage', JSON.stringify({
                isFromWorkbench: true
            }));
            this.isShowIMDialog = true;
            this.$refs.IMBtn.classList?.remove('btn-chat-active');
            this.$refs.summaryEntry.$refs.instantMessage.showInstantMessage(); 
        },
        showSuggestFeedBack() {
            this.$refs.suggestFeedback.show();
        },
        createJob(link, clickType) {
            console.log("🚀 ~ createJob ~ link, clickType:", link, clickType)
            if(this.user.isCFUser && this.$store.state.standstillJob.standstillJobNum > 0) {
                shortTips('你有未处理的僵尸职位，不能创建新职位，请先到职位页面或我创建的职位进行处理');
                setTimeout(() => {
                    _tracker.track('StandstillJobDialogAutoShow', JSON.stringify({
                        from: clickType
                    }));
                    // window.open('/Headhunting/MyCompany.html#/jobList?isShowStandstill=true');
                    window.open('/#/jobList?isShowStandstill=true');
                }, 1500);
            } else if(this.user.isCFUser && this.$store.state.standstillJob.unbindDeptJobNum > 10) {
                shortTips('你有未绑定部门的职位，不能创建新职位，请先到职位页面或我创建的职位进行处理');
                setTimeout(() => {
                    _tracker.track('JobBindDeptDialogAutoShow', JSON.stringify({
                        from: clickType
                    }));
                    // window.open('/Headhunting/MyCompany.html#/jobList?isShowJobBindDept=true');
                    window.open('/#/jobList?isShowJobBindDept=true');
                }, 1500);
            } else if(!this.isAdministrator && this.isDepartmentManageAudit && this.unAuditCustomerDepartmentNum > 0) {
                this.$refs.unAuditCustomerDepartmentDialog.show(this.auditList);
            } else {
                // window.open(link);
                this.jumpCreatePage(link, clickType);
            }
        },
        jumpCreatePage(link, clickType) {
            window.open(`/Headhunting/MyCompany.html#${link}`);
            // let item = {};
            // if(clickType == 'createSingleJob') {
            //     item = {
            //         "enTitle": "",
            //         "cnTitle": "单个创建职位",
            //         "path": "/jobCreate/single",
            //         "aliveComponent": "JobCreateSingle",
            //         "isActive": true
            //     }
            // } else {
            //     item = {
            //         "enTitle": "",
            //         "cnTitle": "批量创建职位",
            //         "path": "/jobCreate/multi",
            //         "aliveComponent": "JobCreateMulti",
            //         "isActive": true
            //     }
            // }

            // this.invokePushTabItems(item);
            // this.$router.push({ path: link });
        },
        appCardShow() {
            _tracker.track('AppDownloadCardShow', JSON.stringify({
                from: "TopBar"
            }));
        },
        showPointRank() {
            this.$refs.summaryEntry.$refs.pointRankDialog.show(1);
        },
        // 组织架构：单个创建
        showDepartmentDialog() {
            // this.$refs.summaryEntry.$refs.departmentDialog.show();
            // console.log(this.user.isAdministrator)
            // console.log(this.isBusinessPermission)
            // console.log(this.isBusinessPermission.includes('Business'))
            if(this.user.isAdministrator || this.isDepartmentCreate || this.isDepartmentManageAudit){
                // this.$refs.summaryEntry.$refs.departmentDialog.show();
                if(!this.isAdministrator && this.isDepartmentManageAudit && this.unAuditCustomerDepartmentNum > 0) {
                    this.$refs.unAuditCustomerDepartmentDialog.show(this.auditList);
                } else {
                    this.$refs.summaryEntry.$refs.departmentDialog.show();
                }
            } else {
                shortTips("权限不足，请联系管理员")
            }
        },
        // 组织架构：批量创建
        showCustomerSelectDialog() {
            // this.$refs.authCustomerSelectDialog.show();
            if(this.user.isAdministrator || this.isDepartmentCreate || this.isDepartmentManageAudit){
                // this.$refs.authCustomerSelectDialog.show();
                if(!this.isAdministrator&& this.isDepartmentManageAudit && this.unAuditCustomerDepartmentNum > 0) {
                    this.$refs.unAuditCustomerDepartmentDialog.show(this.auditList);
                } else {
                    this.$refs.authCustomerSelectDialog.show();
                }
            } else {
                shortTips("权限不足，请联系管理员")
            }
        },
        handleAuthCustomerSelect(customerId) {
            window.open(`/Headhunting/MyCompany.html#/batchCreateDepartment/${customerId}`);
            // window.open(`/#/batchCreateDepartment/${customerId}`);
        },
        getHaveAuditPermissionsCustomerList() {
            CustomerService.getHaveAuditPermissionsCustomerList()
                .then(res => {
                    this.auditCustomerList = res || [];
                    this.$store.dispatch('setHaveAuditPermissionsCustomerList', this.auditCustomerList);
                })
        },
        jumpApplyList(type) {
            let item = {};
            if(type == 'audit') {
                item = {
                    "enTitle": "",
                    "cnTitle": "我的审批-组织架构",
                    "path": "/departmentAuditList",
                    "aliveComponent": "DepartmentAuditList",
                    "isActive": true
                }
            }else {
                item = {
                    "enTitle": "",
                    "cnTitle": "我的申请-组织架构",
                    "path": "/departmentApplyList",
                    "aliveComponent": "DepartmentApplyList",
                    "isActive": true
                }
            }

            this.invokePushTabItems(item);
            this.$router.push({ path: item.path });
        },
        ...mapActions('workbench',[
            'invokePushTabItems'
        ]),

        handleToolsCommand(command) {
            switch(command) {
                case 'workDayReport':
                    this.$refs.summaryEntry.showWorkReport();
                    break; 
                case 'realtimeData':
                    this.$refs.summaryEntry.showRealtimeData();
                    break; 
                case 'appDesc':
                    this.$refs.summaryEntry.showAppDesc = true;
                    break; 
                case 'loveStudy':
                    _tracker.track('xiaoetong_entry_button_click');
                    window.open('https://appdhywguik2938.pc.xiaoe-tech.com');
                    break;
                default:
                    break;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
// .el-popper {
//     overflow: auto; 
// }
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.work-table-top-bar{
    // box-sizing: border-box;
    // border-bottom: 1px solid #ddd;
    
}
.topbar-wrap{
    // display: flex;
    // height: 44px;
    // padding: 0 20px 0 30px;
    // justify-content: flex-end;
    // align-items: center;
    // background: #fff;

    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: white;
    border-bottom: 1px solid #ebebeb;
    align-items: center;

    &-right {
        position: relative;
        background-color: #fff;
        z-index: 10;
    }

    .cell {
        display: flex;
        align-items: center;
        >img{
            width: 225px;
            min-width: 225px;
            height: 27px;
            cursor: pointer;
        }

        .custom-btn{
            margin-right: 10px;
            width: 112px;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #CCCCCC;
            height: 34px;
            background: transparent;
            font-size: 14px;
            color: #333;
            &:hover{
                border: 1px solid $primary;
                // background: #fafafa;
            }
            .btn-icon {
                fill: #666;
                margin-right: 4px;
                font-size: 16px;
                vertical-align: -3px;
            }
        }
        .icon-wrap{
            text-align: center;
            padding: 0 10px;
            .btn-icon-b {
                fill: #333;
                font-size: 22px;
                margin-left: 0;
                cursor: pointer;
                &:hover {
                    fill: $primary;
                }
            }
        }
        .im-btn{
            display: inline-block;
            position: relative;
            text-align: center;
            padding: 0 10px;
            .btn-icon-im {
                fill: #333;
                font-size: 22px;
                cursor: pointer;
                &:hover {
                    fill: $primary;
                }
            }
            .num{
                display: inline-block;
                height: 16px;
                line-height: 16px;
                border-radius: 8px;
                padding: 0 5px;
                font-size: 12px;
                color: white;
                background: #FF4B4B;
                position: absolute;
                top: 0;
                right: 0;
            }
            .num-more-100{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #FF4B4B;
                border: 1px solid #FFFFFF;
                position: absolute;
                top: 2px;
                right: 10px;
            }
        }

        .btn-icon-b {
            fill: #333;
            font-size: 22px;
            margin-left: 23px;
            cursor: pointer;
            &:hover {
                fill: $primary;
            }
        }
        

        .uesr-avator {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-left: 28px;
            border: 1px solid rgba(168, 168, 168, 0.44);
            border-radius: 50%;
            overflow: hidden;
        }
    }
    /* &.has-tab{
        height: 44px;
        padding: 0 20px 0 30px;
        background: #E8E8E8;
    } */
    .schedule-notice{
        // flex-grow: 1;
        // margin-left: 21px;
        display: flex;
        align-items: center;
        margin: 0 0 0 21px;
        white-space: nowrap;
        
        &::before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 20px;
            background: #ebebeb;
            vertical-align: middle;
            margin-right: 21px;
        }
    }
    .topbar-btn{
        display: inline-block;
        background-color: rgba(56,188,157,.1);
        border-radius: 15px;
        font-size: 14px;
        color: #38BC9D;
        width: 70px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        vertical-align: middle;
        margin-right: 9px;
        cursor: pointer;
        &:hover{
            background-color: rgba(56,188,157,1);
            color: #fff;
        }
        .topbar-btn-icon {
            margin-right: 2px;
        }

        &.topbar-btn-chat {
            .chat-hover {
                display: none;
            }
        }

        &.topbar-btn-chat:hover,
        &.is-hover,
        &.btn-chat-active {
            .chat-hover {
                display: inline-block !important;
            }
            .chat-no-hover {
                display: none;
            }
        }
        &.has-new-message {
            width: 94px;
        }
        &.btn-chat-active {
            color: #fff;
            animation: flicker-orange 3s linear;
            animation-fill-mode: forwards;
        }
        &.is-hover {
            background: #38BC9D;
            color: #fff;
        }
        .im-num {
            margin-left: 4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #E24E3A;
            font-size: 12px;
            color: #fff;
        }
        &.app-card-btn {
            width: 82px;
            padding-left: 16px;
            position: relative;
            .topbar-btn-icon {
                font-size: 16px;
                position: absolute;
                top: 4px;
                left: 11px;
            }
        }
        &.department-btn {
            width: 92px;
        }
    }
    .topbar-user {
        &.el-dropdown {
            margin-right: 30px;
        }
    }
    .topbar-avatar{
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #eee;
        vertical-align: middle;
        overflow: hidden;
        border: 1px solid #979797;
        margin: 0 10px;
        >img{
            width: 100%;
            height: 100%;
        }
    }
}
.el-popover.avator-popper{
    background: #FFFFFF;
    box-shadow: 4px 4px 4px 0px rgba(244,244,244,0.5);
    border-radius: 16px;
    border: 1px solid rgba(235,235,235,0.92);
    padding: 28px 25px;
    .avator-wrap{
        .avator-top{
            border-bottom: 1px solid rgba(235,235,235,0.92);
            padding-bottom: 18px;
            display: flex;
            flex-flow: row nowrap;
            .pic{
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #eee;
                vertical-align: middle;
                overflow: hidden;
                border: 1px solid #979797;
                margin: 0 10px;
                cursor: pointer;
                >img{
                    width: 100%;
                    height: 100%;
                }
            }
            .infos{
                cursor: pointer;
                >p{
                    margin: 0;
                }
                .name{
                    height: 25px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 25px;
                    .name-txt{
                        max-width: 120px;
                        display: inline-block;
                        margin-right: 5px;
                    }
                    .name-icon{
                        color: #666;
                        font-size: 10px;
                        vertical-align: text-top;
                    }
                }
                .email{
                    height: 17px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 17px;
                }
            }
        }
        .func-wrap{
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* 一行三个 */  
            grid-auto-rows: auto;  
            gap: 10px; /* 网格项之间的间距 */  
            grid-auto-flow: row dense; /* 自动换行，靠左排列 */  
            .func-cell{
                text-align: center;
                padding-top: 15px;
                cursor: pointer;
                .func-icon{
                    font-size: 25px;
                    fill: #000;
                }
                .txt{
                    font-size: 14px;
                    font-weight: 500;
                    color: #666666;
                    line-height: 20px;
                    margin: 7px 0 0;
                }
                &:hover{
                    .func-icon{
                        fill: $primary;
                    }
                    .txt{
                        color: $primary;;
                    }
                }
            }

        }
    }



}  
  

.tools-dropdown-menu{
    .item-wrap{
        display: flex;
        flex-flow: row nowrap;
        font-size: 16px;
        justify-content: space-between;
        width: 130px;
        align-items: center;
        height: 44px;
        .item-icon{
            font-size: 18px;
        }
        .item-text{
            padding-left: 16px;
            flex: 1;
        }
        .item-arrow-icon{
            font-size: 12px;
        }
    }
}
.help-detail-popover.el-popover {
    padding: 0;
    border-radius: 8px;
    .help-detail {
        height: 190px;
        padding-top: 24px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        background-color: rgb(255, 255, 255);
        color: rgb(102, 102, 102);
    }
}
.kefu-popover.el-popover {
    width: 106px;
    // height: 112px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px 0px rgba(244,244,244,0.5);
    border-radius: 10px;
    border: 1px solid rgba(235,235,235,0.92);
    .kefu-detail{
        text-align: center;
    }
}
.invite-friend-popover{
    padding: 0;
    border-radius: 8px;
    .invite-detail{
        >p{
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            line-height: 14px;
        }
        .copy-btn{
            width: 90px;
            height: 34px;
            background: #59d6b9;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 32px;
            &:hover{
                background: #73e2c8;
            }
        }

    }
}
.top-bar {
    &.el-dropdown-menu {
        padding: 0;
    }
    .el-dropdown-menu__item{
        padding: 0 18.5px;
        font-size: 14px;
        line-height: 44px;
        color: #666;
        .font-icon{
            margin-right: 4px;
            font-size: 18px;
            color: #aaa;
            &.svg-batch-create{
                font-size: 20px;
            }
            &.svg-fenpei{
                font-size: 22px;
                margin-bottom: -4px;
            }
            &.hover {
                display: none;
            }
        }
        &:hover{
            .font-icon{
                color: $primary;
                &.hover {
                    display: inline-block;
                }
                &.default {
                    display: none;
                }
            }
        }
    }
}
.tools-btn-wrap{
    padding: 10px 0;
    user-select: none;
}

.app-card-popover.el-popover {
    padding: 14px;
    padding-right: 10px;
    height: 168px;
    .app-card {
        display: flex;
        &-left {
            width: 139px;
            padding-right: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-right: 1px solid #ddd;
            .app-card-title {
                font-size: 14px;
                line-height: 20px;
                color: #4a4a4a;
            }
        }
        &-right {
            padding-top: 8px;
            padding-left: 10px;
            font-size: 12px;
            color: #666;
            .app-card-text {
                line-height: 18px;
                margin-bottom: 6px;
            }
            .app-card-tip {
                margin-top: 30px;
                margin-bottom: 0;
            }
        }
    }
}

@keyframes flicker {
    0% {
        background: #38BC9D;
        color: #fff;
    }
    25% {
        background: #EAF9F6;
        color: #38BC9D;
    }
    50% {
        background: #38BC9D;
        color: #fff;
    }
    75% {
        background: #EAF9F6;
        color: #38BC9D;
    }
    100% {
        background: #38BC9D;
        color: #fff;
    }
}
@keyframes flicker-orange {
    0% {
        background: #F5A623;
    }
    25% {
        background: #EAF9F6;
    }
    50% {
        background: #F5A623;
    }
    75% {
        background: #EAF9F6;
    }
    100% {
        background: #F5A623;
    }
}
</style>