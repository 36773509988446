<template>
    <div class="system-recommend-dialog" :class="isShow ? 'show' : 'hidden'">
        <span class="icon icon-close" @click="close">

        </span>
        <div class="dialog-container">
            <span class="icon icon-AIhousekeeper">
                <!--职位详情标题-->
                <span v-if="type === 0">系统帮你找到的合适候选人，省时又高效～</span>
                <!--简历详情标题-->
                <span v-if="type === 1">更多相似的候选人，可能对您有帮助～</span>
            </span>
            <swiper :options="swiperOption" ref="mySwiper">
                <swiperSlide v-for="(item, index) in listData" :key="item.candidateId">
                    <li class="recommend-item">
                        <p class="base-info"><span
                            class="info-name">{{item.candidateName}}</span><span>{{item.title}}</span></p>
                        <p class="list-info">
                            <span class="info-item" v-if="item.city">{{item.city}}</span>
                            <span class="info-item" v-if="item.company">{{item.company}}</span>
                        </p>
                        <p class="list-info">
                            <span class="info-item" v-if="item.school">{{item.school}}</span>
                            <span class="info-item" v-if="item.degree">{{item.degree}}</span>
                            <span class="info-item" v-if="item.yearOfExperience">{{item.yearOfExperience}}年</span>
                        </p>
                        <p class="time-info">
                            最近更新：{{item.updated | momentFormatDate}}
                        </p>
                        <span class="btn-new view-btn" @click="gotoDetail(item.candidateId)">立即看看</span>
                    </li>
                </swiperSlide>

                <!--<div class="swiper-button-prev" slot="button-prev"></div>-->
                <!--<div class="swiper-button-next" slot="button-next"></div>-->
            </swiper>
            <!--<div class="swiper-menu" v-if="listData.length > 3">-->
            <!--<span class="icon icon-swiper-left" slot="button-prev" @click="prev"></span>-->
            <!--<span class="icon icon-swiper-right" slot="button-next" @click="next"></span>-->
            <!--</div>-->
            <div class="swiper-menu" v-if="listData.length > 3">
                <span class="icon-new icon-leftarrow icon-swiper-left" slot="button-prev" @click="prev"></span>
                <span class="icon-new icon-rightarrow icon-swiper-right" slot="button-next" @click="next"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import 'swiper/dist/css/swiper.css';
    import {swiper, swiperSlide} from 'vue-awesome-swiper';

    import {intelligent_recommend as intelligent_recommend_url} from '#/js/config/api.json';
    import tool from '#/js/tool.js';

    import moment from 'moment'

    export default {
        name: "systemRecommend-dialog",
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                isShow: false,
                swiperOption: {
                    loop: false,
                    slidesPerView: 3,
                    navigation: {
                        nextEl: '.icon-swiper-right',
                        prevEl: '.icon-swiper-left',
                    },
                    updateTranslate: true,
                },
                showTimeout: 1,
                listData: [],
                candidateIds: [],
                candidateId: '',
                jobId: '',
                type: 0,
                index: 0,
                limitIndex: 0,
                isMiniScreen: false,
            }
        },
        created() {
            window.dialog = this;
            this.init();
            window.onresize = tool.throttle(() => {
                this.handleResize();
            }, 1000)
        },
        methods: {
            init() {
                let id, url;
                let isCFUser = this.$store ? this.$store.state.user.userInfo.isCFUser : $('input[name="isCFUser"]').val();
                let _this = this;
                if (!isCFUser) {
                    return this.isShow = false;
                }

                window.onhashchange = function () {
                    _this.init()
                };

                if (/^\/Job\/[a-z0-9A-Z\-]+$/ig.test(location.pathname)) {
                    id = location.pathname.split('/')[2];
                    url = intelligent_recommend_url.job_recommend.replace(/%p/g, id);
                    this.jobId = id;
                    this.type = 0;
                } else {
                    clearTimeout(this.showTimeout);
                    this.showTimeout = 3;
                    this.$emit('floatHidden');
                    return this.isShow = false;
                }

                // else if (/^\#\/candidateDetail\/[a-z0-9A-Z\-]+$/ig.test(location.hash)){
                //     id = location.hash.split('/')[2];
                //     this.candidateId = id;
                //     url = intelligent_recommend_url.recommend_recommend.replace(/%p/g,id);
                //     this.type = 1;
                //     this.qrcodeUrl = `/Sharing/QRCode?url=`+encodeURIComponent(`${location.origin}/Sharing/Recommendation?code=${id}`);
                // }

                this.$emit('close');

                _request({
                    method: 'GET',
                    url: url
                }).then(res => {
                    this.listData = res.length > 6 ? res.slice(0, 6) : res;
                    if (this.listData.length <= 0) {
                        this.isShow = false;
                    } else {
                        let _this = this, time = 8000;
                        _this.listData.forEach((candidate) => {
                            _this.candidateIds.push(candidate.candidateId);
                        });
                        this.exposureTrack(0, 3);
                        this.showTimeout = setTimeout(function () {
                            _this.showTimeout = 2;
                            _this.isShow = true;
                            _this.$emit('show');
                            lbdTrack.track('systemRecommend', {
                                from: _this.type === 0 ? 'jobDetailView' : 'candidateDetailView',
                                list_count: _this.listData.length,
                                candidateIds: _this.candidateIds,
                                origin_jobId: _this.jobId,
                                origin_candidateId: _this.candidateId,
                                type: 'auto'
                            })
                        }, time);
                    }
                })
            },
            handleResize() {
                if (window.innerWidth <= 1344) {
                    this.$nextTick(() => {
                        this.$refs.mySwiper.swiper.update();
                        this.isMiniScreen = true;
                    })
                } else {
                    this.$nextTick(() => {
                        this.$refs.mySwiper.swiper.update();
                        this.isMiniScreen = false;
                    })
                    return false;
                }
            },
            gotoDetail(id) {
                let eventName = 'systemRecommend_toView';//职位详情
                let from = 'jobDetailView';
                if (this.type == 1) {
                    from = 'candidateDetailView';
                }
                lbdTrack.track(eventName, {
                    candidateId: id,
                    from: from,
                    origin_jobId: this.jobId,
                    origin_candidateId: this.candidateId
                });
                //window.open(`/Headhunting/MyCompany.html#/candidateDetail/${id}`);
                let tempWindow = window.open('_blank');
                // tempWindow.location = `/Headhunting/MyCompany.html#/candidateDetail/${id}`;
                tempWindow.location = `/#/candidateDetail/${id}`;
            },
            show() {
                let status = true;
                if (this.listData.length === 0) {
                    return this.$message.warning('暂无推荐数据。');
                }

                if (this.showTimeout !== 2 && this.showTimeout !== 3) {
                    clearTimeout(this.showTimeout);
                    this.showTimeout = 3;
                }

                if (this.showTimeout === 3) {
                    lbdTrack.track('systemRecommend', {
                        from: this.type === 0 ? 'jobDetailView' : 'candidateDetailView',
                        list_count: this.listData.length,
                        candidateIds: this.candidateIds,
                        origin_jobId: this.jobId,
                        origin_candidateId: this.candidateId,
                        type: 'click'
                    })
                }
                this.showTimeout = 3;
                this.isShow = true;
            },
            close() {
                this.isShow = false;
                this.$emit('close');
            },
            prev() {
                this.$refs.mySwiper.swiper.slidePrev()
            },
            next() {
                if (this.index + 3 <= this.listData.length - 1) {
                    this.index = this.index + 1;
                }
                this.$refs.mySwiper.swiper.slideNext();
            },
            exposureTrack(start, end) {
                let eventName = 'systemRecommend_candidateExposure';//职位详情
                let from = 'jobDetailView', candidateIdList = [];
                if (this.type == 1) {
                    from = 'candidateDetailView';
                }
                for (let i = start; i < end; i++) {
                    candidateIdList.push(this.listData[i].candidateId);
                }

                lbdTrack.track(eventName, {
                    candidateIds: candidateIdList,
                    from: from,
                    origin_jobId: this.jobId,
                    origin_candidateId: this.candidateId
                });
            }
        },
        watch: {
            index(value) {
                this.exposureTrack(this.index + 2, this.index + 3);
            },
            isShow() {
                this.handleResize();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~#/css/common';

    .system-recommend-dialog {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 247px;
        background-color: rgba(#000, .7);
        z-index: 6000;
        transition: 1s;

        &.hidden {
            bottom: 270px;
            transform: translateX(100%) scale(0);
            opacity: 0;
        }

        .icon-close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 24px;
            height: 24px;
            border-radius: 50%;

            &:hover {
                background-color: rgba(#fff, .1);
            }

            &:before {
                content: '';
                display: block;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
                background-image: url('../../assets/images/systemRecommend-dialog/ic_off_tj.svg');
            }
        }

        .dialog-container {
            position: relative;
            margin: auto;
            padding: 15px 0;
            width: 1200px;
            color: #fff;

            .icon-AIhousekeeper {
                margin-bottom: 16px;
                line-height: 26px;
                font-size: 18px;
                color: #50E3C2;
                font-weight: bold;

                &:before {
                    content: '';
                    display: inline-block;
                    position: relative;
                    float: left;
                    margin-right: 10px;
                    width: 24px;
                    height: 26px;
                    background-image: url('../../assets/images/systemRecommend-dialog/pic_zhinengan.svg');
                }
            }

            /deep/ .icon-swiper-left {
                position: absolute;
                top: -36px;
                right: 60px;
                width: 52px;
                height: 26px;
                border-radius: 13px;
                cursor: pointer;
                font-size: 16px;

                &:hover {
                    background-color: rgba(#fff, .1);
                }

                &:before {
                    display: inline-block;
                    position: relative;
                    top: 50%;

                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 30px;
                    height: 20px;

                }
            }

            /deep/ .icon-swiper-right {
                position: absolute;
                top: -36px;
                right: 0;
                width: 52px;
                height: 26px;
                border-radius: 13px;
                font-size: 16px;

                &:hover {
                    background-color: rgba(#fff, .1);
                }

                &:before {
                    display: inline-block;
                    position: relative;
                    top: 50%;

                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 30px;
                    height: 20px;
                }
            }

            .swiper-menu {
                position: absolute;
                right: 0;
                top: 56px;
            }

            .swiper-container {
                /*overflow: inherit;*/
            }

            .swiper-wrapper {
                /*overflow: hidden;*/
            }
        }
    }

    .recommend-item {
        position: relative;
        width: 386px;
        height: 175px;
        border: 1px solid #ddd;
        padding: 20px;
        font-size: 14px;
        color: #666;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        background: #fff;

        &:nth-child(3n) {
            margin-right: 0;
        }

        /*        &:hover {
                    box-shadow: 0 0 12px rgba(0, 0, 0, .2);
                    transform: translate3d(0, -6px, 0);
                    transition: all .6s;
                }*/

        .info-name {
            color: #444;
            font-size: 16px;
            margin-right: 12px;
            font-weight: bold;
        }

        p {
            margin-bottom: 8px;
        }

        .view-btn {
            position: absolute;
            display: block;
            width: 100px;
            height: 36px;
            line-height: 36px;
            background: #39BC9C;
            border-radius: 2px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            bottom: 20px;
            right: 10px;

            &:hover {
                background-color: #36B495;
            }
        }

        .time-info {
            margin-top: 20px;
            position: relative;
            padding-top: 6px;

            &:before {
                display: block;
                position: absolute;
                content: '';
                width: 42px;
                height: 1px;
                background: #ddd;
                left: 0;
                top: 0;
            }
        }

        .base-info{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .list-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .info-item {
                display: inline-block;
                padding: 0 6px;
                border-right: 1px solid #666;
                line-height: 1;

                &:last-child {
                    border: none;
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    @media screen and (max-width: 1366px) {
        .system-recommend-dialog {
            height: 188px;

            .icon-close {
                top: -30px;
                right: 0;
                background-color: rgba(#000, .6);

                &:hover, &:active {
                    background-color: rgba(#494a4a, .8);
                }
            }

            .dialog-container /deep/ {
                width: 900px;

                .icon-AIhousekeeper {
                    margin-bottom: 6px;
                    font-size: 14px;
                }

                .swiper-slide {
                    transform: scale(0.75) translate(-50px, -20px);
                }
            }

            .recommend-item {
                padding: 10px;
                height: 155px;

                .time-info {
                    margin-top: 10px;
                }
            }
        }
    }
</style>
