import moment from 'moment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import md5 from 'blueimp-md5';

const dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;

export function _generateUUID() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
}

export function _getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
}

export function _getQueryString_candidateDetail(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

export function _getQueryStringByHash(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    if(window.location.hash.indexOf("?") < 0){
        return null;
    }
    let r = window.location.hash.split("?")[1].match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}
/**
 * @mark 数月前的日期起始时间
 * @param {几个月} num
 */
export function _monthAgoDateRange(num = 1){
    let startDate = moment().subtract(num, 'months').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    return {
        startDate:startDate,
        endDate: endDate
    }
}

/**
 *
 * @export
 * @param {*} date为标准格式化的日期
 * @returns
 */
export function _momentFormatDate(date){
    let nowTime = new Date();
    let nowTimeStamp = nowTime.getTime();
    let thisTimeStamp = new Date(date).getTime();
    let dayTimeStamp = new Date(new Date(date).toLocaleDateString()).getTime();
    let todayTimeStamp = new Date(nowTime.toLocaleDateString()).getTime();
    let yestodayTimeStamp = new Date(nowTime.toLocaleDateString()).getTime() - 24*60*60*1000;
    if(nowTimeStamp - thisTimeStamp < 60*60*1000){
        return moment(date).fromNow();
    }else if(dayTimeStamp === todayTimeStamp){
        return `今天  ${moment(date).format('HH:mm:ss')}`;
    }else if(dayTimeStamp === yestodayTimeStamp){
        return `昨天  ${moment(date).format('HH:mm:ss')}`;
    }else{
        return date;
    }
}

export function _getCookie(name) {
    // if(name == 'access_token') {
    //     return localStorage.getItem('access_token') || '';
    // }else {
    //     var strCookie = document.cookie;
    //     var arr = strCookie.split('; ');
    //     for (var i = 0; i < arr.length; i++) {
    //        var t = arr[i].split("=");
    //         if(t[0] == name) {
    //             return t[1];
    //         }
    //     };
    // }
    // return null;

    return getCookie(name);
}

// 设置cookie
export function setCookie(name, value, seconds) {
    seconds = seconds || 0;   //seconds有值就直接赋值，没有为0，这个根php不一样。
    var expires = "";
    if (seconds != 0 ) {      //设置cookie生存时间
        var date = new Date();
        date.setTime(date.getTime()+(seconds*1000));
        expires = "; expires="+date.toUTCString();
    }
    document.cookie = name+"="+escape(value)+expires+"; path=/";   //转码并赋值
}

// 取得cookie
export function getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';') // 把cookie分割成组
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i] // 取得字符串
        while (c.charAt(0) == ' ') { // 判断一下字符串有没有前导空格
            c = c.substring(1, c.length) // 有的话，从第二位开始取
        }
        if (c.indexOf(nameEQ) == 0) { // 如果含有我们要的name
            return unescape(c.substring(nameEQ.length, c.length)) // 解码并截取我们要值
        }
    }
    return null
}

// 清除cookie
export function clearCookie(name) {
    setCookie(name, "", -1);
}

export function _isDangerousString(str){
    return dangerousStringReg.test(str);
}

// //获取浏览器指纹
_initFingerprint();    //启动时预先生成指纹
export function _initFingerprint () {
    if (localStorage.getItem('BazaBrowserId') && localStorage.getItem('BazaBrowserId').length > 32) {
        axios.defaults.headers.common['BazaBrowserId'] = localStorage.getItem('BazaBrowserId');
    } else { 
        const fpPromise = FingerprintJS.load({
          monitoring: false
        });
      
        // Get the visitor identifier when you need it.
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                // This is the visitor identifier:
                const visitorId = result.visitorId;
                console.log(visitorId);

                const md5Str = md5(_generateUUID());
                const browserId = visitorId + '-' + md5Str;
                console.log(browserId);

                localStorage.setItem('BazaBrowserId', browserId);
                axios.defaults.headers.common['BazaBrowserId'] = browserId;
    
                // return visitorId;
            }).catch(err => {
                // return '';
            });
    }

}
