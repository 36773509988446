var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-selected", style: { height: _vm.height + "px" } },
    [
      _vm.selectedMemberNumber
        ? [
            _c("div", { staticClass: "selected-title" }, [
              _vm._v(
                "\n            已选择" +
                  _vm._s(_vm.selectedMemberNumber) +
                  "位联系人\n        "
              ),
            ]),
            _c("div", { staticClass: "selected-list-container" }, [
              _c(
                "ul",
                { staticClass: "selected-list" },
                _vm._l(_vm.selectedMemberList, function (member, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !member.disabled,
                          expression: "!member.disabled",
                        },
                      ],
                      key: index,
                      staticClass: "selected-item",
                      class: member.firmShortName
                        ? "external-selected-item"
                        : "",
                    },
                    [
                      _c("img", {
                        staticClass: "member-avatar",
                        attrs: {
                          src: `${_vm.avatarBaseUrl}/${
                            member.type === _vm.TIM.TYPES.CONV_GROUP
                              ? "group"
                              : "user"
                          }/${
                            member.type === _vm.TIM.TYPES.CONV_GROUP
                              ? member.groupId
                              : member.unionId
                          }`,
                        },
                      }),
                      !_vm.isShare
                        ? [
                            member.firmShortName
                              ? _c("div", { staticClass: "member-text-info" }, [
                                  _c(
                                    "span",
                                    { staticClass: "member-nick" },
                                    [
                                      member.realName
                                        ? [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(member.realName) +
                                                "@" +
                                                _vm._s(member.nickname) +
                                                "\n                                "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(member.nick) +
                                                "\n                                "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c("span", { staticClass: "member-firm" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(member.firmShortName) +
                                        "\n                            "
                                    ),
                                  ]),
                                ])
                              : _c(
                                  "span",
                                  { staticClass: "member-nick" },
                                  [
                                    member.realName
                                      ? [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(member.realName) +
                                              "@" +
                                              _vm._s(member.nickname) +
                                              "\n                            "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(member.nick) +
                                              "\n                            "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                          ]
                        : _vm._e(),
                      _vm.isShare
                        ? [
                            member.groupId
                              ? _c("span", { staticClass: "member-nick" }, [
                                  _vm._v(_vm._s(member.groupName)),
                                ])
                              : _c("div", { staticClass: "member-text-info" }, [
                                  _c("span", { staticClass: "member-nick" }, [
                                    _vm._v(
                                      _vm._s(member.name || member.userName)
                                    ),
                                  ]),
                                  member.company || member.title
                                    ? _c(
                                        "span",
                                        { staticClass: "member-firm" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(member.company) +
                                              " | " +
                                              _vm._s(member.title) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "member-firm" },
                                        [
                                          _vm._v(
                                            "\n                                职通车候选人\n                            "
                                          ),
                                        ]
                                      ),
                                ]),
                          ]
                        : _vm._e(),
                      _c("span", {
                        staticClass: "member-remove el-icon-error",
                        on: {
                          click: function ($event) {
                            return _vm.removeSelected(member)
                          },
                        },
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]
        : _c("div", { staticClass: "selected-title" }, [
            _vm._v("\n        未选择联系人\n    "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }