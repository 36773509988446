<template>
  <el-dialog
    :visible="isShow"
    title="分享到"
    width="602px"
    custom-class="share-to-im-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :destroy-on-close="true"
    @close="hide">
    <div class="share-to-im-container">
        <div class="member-list-container">
            <el-input 
                class="search-input" 
                placeholder="搜索" 
                prefix-icon="el-icon-search" 
                v-model.trim="keyword" 
                @change="handleSearch"
            ></el-input>
            
            <p v-if="!isSearchMode" class="member-list-container-tip">最近联系人</p>
            <ul v-if="!isSearchMode" class="member-list-container-ul">
                <li v-for="member in memberList" :key="member.unionId || member.groupId">
                    <member-item 
                        :member="member"
                        :isShare="true"
                        :isGroup="!!member.groupId"
                        type="conversationList"
                        :value="checkMemberSelected(member)"
                        @member-select="handleMemberSelect"
                    ></member-item>
                </li>
            </ul>
            <div v-if="isSearchMode" class="search-result-container">
                <search-result 
                    :isShare="true"
                    :searchResultObject="searchResultObject"
                    :isSearchEnd="isSearchEnd"
                    :shareData="shareData"
                    :selectedMemberList="selectedMemberList"
                    :innerLabelMap="innerLabelMap"
                    @member-select="handleMemberSelect" 
                ></search-result>
            </div>
        </div>
        <div class="share-operate-container">
            <selected-member-list
                :isShare="true"
                :height="390"
                :selectedMemberList="selectedMemberList"
                @removeSelected="removeSelected">
            </selected-member-list>
            <div class="share-btn-list">
                <el-button class="cancel-btn" @click="hide">取消</el-button>
                <el-button class="share-btn" @click="handleShare" type="primary">分享</el-button>
            </div>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import MemberItem from '../group/member-item.vue';
import SelectedMemberList from '../group/selected-member-list.vue';
import SearchResult from '../search/search-result.vue';
import emitter from '#/js/mixins/emitter.js';
import imService from '#/js/service/imService.js';
export default {
    components: {
        MemberItem,
        SearchResult,
        SelectedMemberList
    },
    data() {
        return {
            isShow: false,
            isSearchMode: false,
            keyword: '',
            isSearchEnd: false,
            searchResultObject: {},
            selectedMemberList: [],
            shareData: {},
            completeConversationList: []
        }
    },
    mixins: [emitter],
    props: {
        conversationList: Array,
        firmId: String,
        innerLabelMap: Object,
        userProfileMap: Object,
    },
    computed: {
        memberList() {
            let result = [];
            if(this.shareData.type) {
                this.conversationList.some(item => {
                    if(result.length < 30) {
                        let obj = {};
                        if(item.type == TIM.TYPES.CONV_GROUP) {
                            if(this.shareData.type == 'jobShare' && !this.shareData.isPublic) {
                                if(this.innerLabelMap && this.innerLabelMap[item.groupProfile.groupID] === 1) {
                                    obj = {
                                        type: item.type,
                                        groupId: item.groupProfile.groupID,
                                        groupName: item.groupProfile.name
                                    }
                                    result.push(obj);
                                }
                            }else {
                                obj = {
                                    type: item.type,
                                    groupId: item.groupProfile.groupID,
                                    groupName: item.groupProfile.name
                                }
                                result.push(obj);
                            }
                        }
                        if(item.type == TIM.TYPES.CONV_C2C && item.conversationID != "C2C_openIM123456") {
                            if(this.shareData.type == 'jobShare' && !this.shareData.isPublic) {
                                if(this.innerLabelMap && this.innerLabelMap[item.userProfile.userID] !== 2) {
                                    obj = {
                                        type: item.type,
                                        unionId: item.userProfile.userID,
                                        name: item.userProfile.nick,
                                        company: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].company) || "",
                                        title: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].title) || ""
                                    }
                                    result.push(obj);
                                }
                            } else if(this.shareData.type == 'candidateShare') {
                                if(this.innerLabelMap && this.innerLabelMap[item.userProfile.userID] === 1) {
                                    obj = {
                                        type: item.type,
                                        unionId: item.userProfile.userID,
                                        name: item.userProfile.nick,
                                        company: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].company) || "",
                                        title: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].title) || ""
                                    }
                                    result.push(obj);
                                }
                            } else {
                                obj = {
                                    type: item.type,
                                    unionId: item.userProfile.userID,
                                    name: item.userProfile.nick,
                                    company: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].company) || "",
                                    title: (this.userProfileMap[item.userProfile.userID] && this.userProfileMap[item.userProfile.userID].title) || ""
                                }
                                result.push(obj);
                            }
                        }
                    }else {
                        return true;
                    }
                })
            }
            return result;
        },
        isCandidateShare() {
            return this.shareData.type == 'candidateShare'
        }
    },
    mounted() {
    },
    methods: {
        show(shareData) {
            this.isShow = true;
            this.shareData = shareData;
        },
        hide() {
            this.isShow = false;
            this.isAddGroupNember = false;
            this.groupName = "";
            this.selectedMemberList = [];
            this.keyword = '';
            this.isSearchMode = false;
            this.searchResultObject = {};
            // this.$refs.memberList.selectedMemberList = [];
        },
        checkIsSameFirm(item) {
            return item.userProfile.firmId == this.firmId;
        },
        handleSearch() {
            if(this.keyword) {
                this.isSearchEnd = false;
                let params = {
                    keywords: [this.keyword],
                    pageNo: 1,
                    pageSize: 20,
                    searchType: 0,
                    needToSearchJdtMember: !this.isCandidateShare
                };

                if(this.shareData.type == 'batchTrackListShare') {
                    params.needToSearchJdtMember = false;
                    params.searchType = 5;
                }

                imService.historySearch(params).then(res =>{
                    this.searchResultObject = res;
                }).finally(() => {
                    this.isSearchEnd = true;
                })
                this.isSearchMode = true;
            }else {
                this.isSearchMode = false;
            }
        },
        checkMemberSelected(member) {
            return this.selectedMemberList.some(item => {
                if(member.unionId) {
                    return item.unionId === member.unionId
                }else {
                    return item.groupId === member.groupId
                }
            });
        },
        handleMemberSelect(value, member) {
            let selectedIndex = -1;
            this.selectedMemberList.some((item, index) =>{
                if(item.unionId) {
                    if(item.unionId == member.unionId) {
                        selectedIndex = index;
                        return true;
                    }
                }else if(item.groupId) {
                    if(item.groupId == member.groupId) {
                        selectedIndex = index;
                        return true;
                    }
                }
            })
            if(value) {
                if(selectedIndex == -1) {
                    this.selectedMemberList.push(member);
                }
            }else {
                if(selectedIndex > -1) {
                    this.selectedMemberList.splice(selectedIndex, 1);
                }
            }
        },
        handleSelectMember(list) {
            this.selectedMemberList = list;
            console.log(this.selectedMemberList);
        },
        removeSelected(member) {
            this.handleMemberSelect(false, member);
        },
        groupNameInput(value) {
            let enc = new TextEncoder("utf-8");
            for(let i = 0; i < 30; i++) {
                if(enc.encode(value).length > 30) {
                    value = value.substring(0, value.length-1);
                } else {
                    this.groupName = value;
                    break;
                }
            }
        },
        handleShare() {
            if(!this.selectedMemberList.length) {
                shortTips('至少选择一位联系人进行分享！');
                return
            }
            if(window.lbdTrack){
                lbdTrack.track('IMShareClick',{
                    type: this.shareData.type
                })
            }else{
                _tracker.track('IMShareClick',JSON.stringify({
                    type: this.shareData.type
                }))
            }

            // tracklist分享给后台发数据
            if(this.shareData.type == 'batchTrackListShare') {
                const _shareTo = this.selectedMemberList.map(item => item.unionId || item.groupId)
                let param = {
                    shareTo: _shareTo,
                    trackingListShareResumes: this.shareData.trackingListShareResumes,
                };
                imService.trackListShare(param).then(res => {
                    console.log(`trackling分享数据添加成功`);
                });
            }else{
                this.selectedMemberList.forEach(item =>{
                    let data = Object.assign({}, this.shareData);
                    delete data.type;

                    let msgExt = {
                        type: this.shareData.type,
                        data: data
                    }

                    openIM.createCustomMessage({
                        data: JSON.stringify(msgExt),
                        extension: 'Share',
                        description: "",
                    })
                    .then(({ data }) => {
                        console.log(`data:`, data);
                        let messageStr = data;

                        openIM.sendMessage({
                            recvID: item.type === 'GROUP' ? '' : item.unionId,
                            groupID: item.type === 'GROUP' ? item.groupId : '',
                            offlinePushInfo: {
                                title: '新消息提醒',
                                desc: '',
                                ex: '',
                                iOSPushSound: '',
                                iOSBadgeCount: true
                            },
                            message: messageStr,
                        }).then(({ data, errCode })=>{
                            if (errCode === 0 || errCode === undefined) {
                                // message.msgSeq = data.message.sequence;
                                // message.msgTime = data.message.time;
                                this.$emit('sendMessage', JSON.parse(data));
                            } else {
                                console.log('发送失败！');
                            }
                        }).catch(err=>{
                            console.log('sendMessageError：', err);
                        });
                    })
                    .catch(err => { 
                        console.log(`err:`, err)
                    });

                    // let msgExt = {
                    //     type: this.shareData.type,
                    //     data: data
                    // }
                    // let message = tim.createCustomMessage({
                    //     to: item.unionId || item.groupId,
                    //     conversationType: item.groupId ? TIM.TYPES.CONV_GROUP : TIM.TYPES.CONV_C2C,
                    //     payload: {
                    //         data: "",
                    //         description: "",
                    //         extension: JSON.stringify(msgExt)
                    //     }
                    // });
                    // this.tim.sendMessage(message, {
                    //     offlinePushInfo: {
                    //         description: JSON.stringify(msgExt)
                    //     }
                    // });
                });
            }
            shortTips('分享成功！');
            this.isShow = false;
            this.selectedMemberList = [];
        }
    }
}
</script>

<style lang="scss" scope>
.share-to-im-dialog.el-dialog {
    width: 602px;
    height: 490px;
    .el-dialog__header {
        background-color: $primary;
        border-radius: 6px 6px 0 0;
        height: 50px;
        line-height: 50px;
        .el-dialog__title {
            color: #fff;
            font-size: 16px;
        }
        .el-dialog__close {
            color: #fff;
        }
    }
    .el-dialog__body {
        padding: 0;
    }
    .share-to-im-container {
        display: flex;
        height: 440px;
        .member-list-container {
            display: flex;
            flex-direction: column;
            width: 274px;
            height: 100%;

            .search-input {
                width: auto;
                margin: 14px 14px 4px;

                .el-input__icon {
                    line-height: 36px;
                }

                input {
                    width: 246px;
                    height: 34px;
                    background: #F6F6F6;
                    border: 1px solid #EEEEEE;
                    border-radius: 4px;
                }
            }

            &-tip {
                font-size: 12px;
                color: #999;
                margin: 14px;
            }

            &-ul {
                flex: 1;
                flex-grow: 1;
                overflow-y: auto;
                padding: 0 14px;
            }
            .search-result-container {
                height: calc(100% - 52px);
            }
        }
        .share-operate-container {
            position: relative;
            width: 328px;
            border-left: 1px solid #d8d8d8;
            
            .share-btn-list {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding-bottom: 7px;
                text-align: center;
                .share-btn, .cancel-btn {
                    border-radius: 6px;
                    line-height: 36px;
                    height: 36px;
                    width: 140px;
                }
                .cancel-btn {
                    margin-left: 20px;
                }
            }
        }
    }
}
</style>