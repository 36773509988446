const state = {
    loading: false,
    sourceFrom: "",
    dialogPasteVisible: false
};

const getters = {
    loading(state) {
        return state.loading;
    },
    sourceFrom(state) {
        return state.sourceFrom;
    },
    dialogPasteVisible(state){
        return state.dialogPasteVisible;
    }
};

const actions = {
    invokeShowLoading({ commit, state }) {
        commit("showLoading");
    },
    invokeHideLoading({ commit, state }) {
        commit("hideLoading");
    },
    invokeSetSourceFrom({ commit, state }, sourceFrom) {
        commit("setSourceFrom", sourceFrom);
    },
    invokeSetPastDialogVisible({ commit, state }, bool){
        commit("setPastDialogVisible", bool);
    }
};

const mutations = {
    showLoading(state) {
        state.loading = true;
    },
    hideLoading(state) {
        state.loading = false;
    },
    setSourceFrom(state, sourceFrom) {
        state.sourceFrom = sourceFrom;
    },
    setPastDialogVisible(state,bool){
        state.dialogPasteVisible = bool;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
