<template>
<message-bubble
  :isMine=isMine
  :message=message
  :groupId="groupId">  
  <div class="file-element-wrapper" title="单击下载" @click="downloadFile">
    <div class="header">
      <i class="file-icon" :class="fileIconName"></i>
      <div class="file-element">
        <span class="file-name">{{ fileName }}</span>
        <!-- <span class="file-size">{{ size }}</span> -->
      </div>
    </div>
    <el-progress
      v-if="showProgressBar"
      :percentage="percentage"
      :color="percentage => (percentage === 100 ? '#67c23a' : '#409eff')"
    />
  </div>
</message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
import { Progress } from 'element-ui'
export default {
  name: 'FileElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    groupId: String
  },
  components: {
    MessageBubble,
    ElProgress: Progress
  },
  computed: {
    fileIconName() {
      let fileName = this.payload.fileName;
      if(fileName.indexOf('.doc') > -1) {
        return 'doc-file-icon'
      }
      if(fileName.indexOf('.xls') > -1) {
        return 'excel-file-icon'
      }
      if(fileName.indexOf('.rar') > -1 || fileName.indexOf('.zip') > -1) {
        return 'compress-file-icon'
      }
      return 'default-file-icon'
    },
    fileName() {
      return this.payload.fileName
    },
    fileUrl() {
      return this.payload.fileUrl
    },
    size() {
      const size = this.payload.fileSize
      if (size > 1024) {
        if (size / 1024 > 1024) {
          return `${this.toFixed(size / 1024 / 1024)} Mb`
        }
        return `${this.toFixed(size / 1024)} Kb`
      }
      return `${this.toFixed(size)}B`
    },
    showProgressBar() {
      return this.$parent.message.status === 'unSend'
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100)
    }
  },
  methods: {
    toFixed(number, precision = 2) {
      return number.toFixed(precision)
    },
    downloadFile() {
      // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
      if (window.fetch) {
        fetch(this.fileUrl)
          .then(res => res.blob())
          .then(blob => {
            let a = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = this.fileName
            a.click()
          })
      } else {
        let a = document.createElement('a')
        a.href = this.fileUrl
        a.target = '_blank'
        a.download = this.filename
        a.click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-element-wrapper {
  cursor: pointer;
}
.header {
  display: flex;
  position: relative;
  line-height: 16px;
}
.file-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: -3px;
  &.doc-file-icon {
    background: url('~@src/assets/images/sendMail/icon-doc-active-small.png') no-repeat center;
  }
  &.excel-file-icon {
    background: url('~@src/assets/images/sendMail/icon-excel-active-small.png') no-repeat center;
  }
  &.compress-file-icon {
    background: url('~@src/assets/images/sendMail/icon-compress-active-small.png') no-repeat center;
  }
  &.default-file-icon {
    background: url('~@src/assets/images/sendMail/icon-default-active-small.png') no-repeat center;
  }
}
.file-element {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-right: 4px;
}
.file-size {
  font-size: 12px;
  padding-top: 5px;
}
</style>
