<template>
    <div class="navs-tab">
        <div
            @click="handleMove('left')"
            class="cell-l"
        >
            <font-icon
                class="icon"
                href="#icon-xiangzuo"
            ></font-icon>
        </div>
        <div class="cell-wrap">
            <div
                :style="{
                            left: left+'px',
                        }"
                class="wrap-inner"
                ref="wrapInner"
            >
                <div
                    :class="{'active': index==1}"
                    :key="index"
                    class="tab-cell"
                    v-for="(it, index) in [1,2,3,4,5,6,7,8,9,10,11,12,13,13,13,1,31,3,13,1,1,313]"
                >
                    <span class="tab-text">导出报表</span>
                    <!-- <span class="tab-close">
                                icon-off icon-close-copy
                    </span>-->
                    <font-icon
                        class="tab-close"
                        href="#icon-close-copy"
                    ></font-icon>
                </div>
            </div>
        </div>
        <div
            @click="handleMove('right')"
            class="cell-r"
        >
            <font-icon href="#icon-xiangzuo-copy"></font-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tab-navs',
    components: {
    },
    data () {
        return {
            left: 0,
        };
    },
    created () { },
    mounted () { },
    methods: {
        handleMove (type) {
            console.log(this.$refs.wrapInner.parentNode.offsetWidth);
            const parentNodeWidth = this.$refs.wrapInner.parentNode.offsetWidth,
                innerNodeWidth = this.$refs.wrapInner.offsetWidth,
                between = parentNodeWidth - innerNodeWidth;

            if (between >= 0) return;

            if (type == 'left') {
                if (this.left >= between && this.left <= 0) {
                    this.left += 50;
                    if (this.left > 0) {
                        this.left = 0;
                    }
                }
            } else {
                if (this.left >= between && this.left <= 0) {
                    this.left -= 50;
                    if (this.left < between) {
                        this.left = between;
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss" scope>
.navs-tab {
    width: 100%;
    height: 52px;
    background: white;
    display: flex;
    flex-flow: row nowrap;
    .cell-wrap {
        padding-top: 9px;
        flex: 1;
        // overflow-x: auto;
        overflow: hidden;
        max-width: calc(100% - 80px);
        position: relative;
        .wrap-inner {
            position: absolute;
            width: auto;
            white-space: nowrap;

            .tab-cell {
                display: inline-block;
                height: 34px;
                background: white;
                border-radius: 5px;
                border: 1px solid #cccccc;
                margin-right: 10px;
                line-height: 32px;
                padding: 0 10px;
                user-select: none;
                &:first-child {
                    margin-left: 10px;
                }
                // border: 1px solid #38bc9d;
                .tab-text {
                    font-size: 14px;
                    color: #999;
                    max-width: 88px;
                }
                .tab-close {
                    cursor: pointer;
                    margin-left: 10px;
                    width: 10px;
                    height: 10px;
                    // color: #ccc;
                    fill: #999;
                    // fill: black;
                    &:hover {
                        background: #f1fffc;
                    }
                }
            }
            .active {
                border: 1px solid #38bc9d;
                background: #f1fffc;
                .tab-text {
                    color: #38bc9d;
                }
                .tab-close {
                    fill: #38bc9d;
                }
            }
        }
    }
    .cell-l,
    .cell-r {
        width: 39px;
        height: 100%;
        line-height: 52px;
        text-align: center;
        cursor: pointer;
        .icon {
            width: 14px;
            height: 16px;
            fill: #999;
            // background: blue;
        }
    }
    .cell-l {
        border-right: 1px solid rgba(235, 235, 235, 0.92);
    }
    .cell-r {
        border-left: 1px solid rgba(235, 235, 235, 0.92);
    }
}
</style>