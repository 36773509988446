<template>
    <el-dialog
        width="500px"
        title="请选择不通过审核的原因"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-form
            class="reject-form"
            ref="rejectForm"
            :model="formData"
            label-width="114px">
            <el-form-item
                label="原因"
                prop="rejectResean">
                <el-select
                    v-model="formData.rejectResean"
                    placeholder="请选择">
                    <el-option
                        v-for="item in reasonList"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="详细说明"
                prop="auditDetail">
                <el-input
                    type="textarea"
                    v-model="formData.auditDetail"
                    placeholder="请输入"
                    maxlength="300"
                    :rows="3"
                    :show-word-limit="true"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog">取消</el-button>
            <el-button type="primary" @click="handleSubmit" v-loading="auditLoading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
export default {
    data() {
        return {
            loading: false,
            auditLoading: false,
            dialogVisible: false,
            formData: {
                id: '',
                customerId: '',
                status: 2,
                groupVersion: '',
                rejectResean: '',
                auditDetail: ''
            },
            reasonList: [{
                text: '重复申请，已存在该部门',
                value: 1
            },{
                text: '信息有误，请重新确认信息',
                value: 2
            },{
                text: '其他',
                value: 3
            }],
            index: ''
        }
    },
    methods: {
        show(departmentJson) {
            Object.assign(this.formData, departmentJson);
            this.index = departmentJson.index;
            this.dialogVisible = true;
        },
        handleCloseDialog() {
            this.auditLoading = false;
            this.formData.rejectResean = '';
            this.formData.auditDetail = '';
            this.dialogVisible = false;
        },
        handleSubmit() {
            if(this.auditLoading) {
                return false;
            }
            if(!this.formData.rejectResean) {
                shortTips('请选择原因');
                return false;
            }
            if(!this.formData.auditDetail) {
                shortTips('请填写详细说明');
                return false;
            }
            this.auditLoading = true;
            DepartmentService.auditOperate(this.formData)
            .then(res => {
                this.auditLoading = false;
                shortTips('申请已拒绝！');
                this.$emit('audit-reject-done', this.index);
                this.handleCloseDialog();
            }).catch(err => {
                this.auditLoading = false;
            })
        }
    }
}
</script>

<style lang="scss" scope>
    .input-visible-firm.el-select {
        width: 340px;
        .el-tag.el-tag--info {
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            background-color: #fff;
            border: none;
            color: #666;
            padding: 0;
            &:nth-of-type(1) {
                padding-left: 8px;
            }
            &:nth-of-type(n+2) {
                &::before {
                    content: ",";
                    margin-right: 6px;
                }
            }
            .el-tag__close {
                display: none;
            }
        }
    }
</style>