import { certification as certificationUrl,
    login as loginUrl} from '#/js/config/api.json';
import { user as userUrl } from '#/js/config/javaApi.json';


export default {
    login(params) {
        return _request({
            url: loginUrl.login,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
            throwBusinessError: true,
        });
    },

    // register(params) {
    //     return _request({
    //         url: certificationUrl.register,
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded"
    //         },
    //         data: params
    //     })
    // },

    register(params) {
        return _request({
            url: loginUrl.register,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
            throwBusinessError: true,
        });
    },
    
    loginOut(params) {
        return _request({
            url: loginUrl.loginOut,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    refreshToken(params) {
        return _request({
            url: loginUrl.refreshToken,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    loginedUserInfo(params) {
        return _request({
            url: loginUrl.loginedUserInfo,
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params,
            throwBusinessError: true,
        });
    },
    
    userInfo() {
        return _request({
            url: userUrl.user_info,
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            method: "GET"
        });
    },

    getCode(params) {
        return _request({
            url: loginUrl.get_code,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    
    fillCompanyInfo(params) {
        return _request({
            url: loginUrl.fill_company_info,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params,
            throwBusinessError: true,
        });
    },
    // getCode(params) {
    //     return _request({
    //         method: 'POST',
    //         baseURL: 'LbdJavaApi',
    //         javaProject: 'tps',
    //         url: loginUrl.validate_mobile,
    //         data: params
    //     });
    // },


    forgetPassword(params) {
        return _request({
            url: certificationUrl.forget_password,
            method: "POST",
            data: params
        })
    },

    // 企业微信获取state
    weComGetStateInfo(params) {
        return _request({
            url: loginUrl.weComGetStateInfo,
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },
    
    weComLogin(params) {
        return _request({
            url: loginUrl.weComLogin,
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 自建应用代开发授权
    WeComFirmRegister(params) {
        return _request({
            url: loginUrl.WeComFirmRegister.replace('%p', params),
            method: "POST",
            baseURL: "LbdOpenApi",
            // data: params,
        });
    },

    // 用户账号绑定企业微信并登录
    WeComBindLogin(params) {
        return _request({
            url: loginUrl.WeComBindLogin,
            method: "POST",
            baseURL: "LbdOpenApi",
            throwBusinessError: true,
            data: params,
        });
    },

    // 用户绑定企业微信
    WeComUserBinding(params) {
        return _request({
            url: loginUrl.WeComUserBinding,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 获取用户手机验证码
    GetMobileVerifyCode(params) {
        return _request({
            url: loginUrl.GetMobileVerifyCode,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 判断公司是否授权了自建应用代开发
    FirmThirdBoundInfo(params) {
        return _request({
            url: loginUrl.FirmThirdBoundInfo,
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 判断用户是否绑定了企业微信登录
    UserThirdBoundInfo(params) {
        return _request({
            url: loginUrl.UserThirdBoundInfo,
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 用户解绑企业微信登录
    WeComUserUnbound(params) {
        return _request({
            url: loginUrl.WeComUserUnbound,
            method: "POST",
            baseURL: "LbdOpenApi",
            throwBusinessError: true,
            data: params,
        });
    },

    // 企业微信公司解绑
    WeComFirmUnbound(params) {
        return _request({
            url: loginUrl.WeComFirmUnbound,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

    // 验证用户第三方（企业微信）绑定信息
    ValidateUserBoundInfo(params) {
        return _request({
            url: loginUrl.ValidateUserBoundInfo,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params,
        });
    },

}