var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "请选择客户",
        visible: _vm.dialogVisible,
        width: "380px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-select",
        {
          staticClass: "auth-customer-select-input",
          attrs: {
            "popper-class": "auth-customer-select",
            disabled: _vm.isDisabled,
            filterable: "",
            placeholder: _vm.placeholder,
          },
          model: {
            value: _vm.customerId,
            callback: function ($$v) {
              _vm.customerId = $$v
            },
            expression: "customerId",
          },
        },
        _vm._l(_vm.customerList, function (customer) {
          return _c("el-option", {
            key: customer.id,
            attrs: {
              value: customer.id,
              label: customer.name,
              title: customer.name,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleCustomerChange(customer)
              },
            },
          })
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }