var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.isFold ? "sidebar-wrap is-fold" : "sidebar-wrap" },
    [
      !_vm.isFold
        ? _c(
            "ul",
            { staticClass: "sidebar-list" },
            _vm._l(_vm.currentSideBar, function (item, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.isShow,
                      expression: "item.isShow",
                    },
                  ],
                  key: index,
                  class: item.isActive ? "sidebar-item active" : "sidebar-item",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sidebar-item-text",
                      on: {
                        click: function ($event) {
                          return _vm.handleSidebarItem(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "sidebar-icon" },
                        [
                          _c("font-icon", {
                            attrs: {
                              href: `#${
                                item.isActive
                                  ? item.activeIcon
                                  : item.normalIcon
                              }`,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", [_vm._v(_vm._s(item.text))]),
                      item.shortcut && item.shortcut.length > 0
                        ? _c("i", {
                            staticClass: "el-icon-arrow-right",
                            class: item.isExpand ? "expanded" : "",
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.isExpand,
                          expression: "item.isExpand",
                        },
                      ],
                      staticClass: "sidebar-menu",
                    },
                    _vm._l(item.shortcut, function (shortcut, shortcutIndex) {
                      return _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: shortcut.isShow,
                              expression: "shortcut.isShow",
                            },
                          ],
                          key: shortcutIndex,
                          staticClass: "sidebar-menu-item",
                          class:
                            shortcut.path == _vm.currentPath ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.handleShortcut(item, shortcut)
                            },
                          },
                        },
                        [_vm._v(_vm._s(shortcut.cnTitle))]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          )
        : _c(
            "ul",
            { staticClass: "fold-sidebar-list" },
            _vm._l(_vm.currentSideBar, function (item, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.isShow,
                      expression: "item.isShow",
                    },
                  ],
                  key: index,
                  class: item.isActive
                    ? "fold-sidebar-list-item active"
                    : "fold-sidebar-list-item",
                },
                [
                  item.shortcut.length > 0
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": _vm.getPopoverClass(item),
                            placement: "right-start",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "sidebar-menu" },
                            _vm._l(
                              item.shortcut,
                              function (shortcut, shortcutIndex) {
                                return _c(
                                  "li",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: shortcut.isShow,
                                        expression: "shortcut.isShow",
                                      },
                                    ],
                                    key: shortcutIndex,
                                    staticClass: "sidebar-menu-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleShortcut(
                                          item,
                                          shortcut
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(shortcut.cnTitle))]
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                slot: "reference",
                                "popper-class": `sidebar-title sidebar-title-${item.name}`,
                                content: item.text,
                                placement: "bottom-start",
                                disabled: !_vm.isFold,
                                effect: "light",
                                "visible-arrow": false,
                              },
                              slot: "reference",
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      href: item.link,
                                      target:
                                        item.link == "javascript:;"
                                          ? "_self"
                                          : "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "sidebar-icon" },
                                      [
                                        _c("font-icon", {
                                          attrs: {
                                            href: `#${
                                              item.isActive
                                                ? item.activeIcon
                                                : item.normalIcon
                                            }`,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            "popper-class": "sidebar-title",
                            content: item.text,
                            placement: "bottom-start",
                            disabled: !_vm.isFold,
                            effect: "light",
                            "visible-arrow": false,
                          },
                        },
                        [
                          _c("span", { staticClass: "sidebar-link" }, [
                            _c(
                              "span",
                              {
                                staticClass: "sidebar-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSidebarItem(item, index)
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  staticClass: "item-icon",
                                  attrs: {
                                    href: `#${
                                      item.isActive
                                        ? item.activeIcon
                                        : item.normalIcon
                                    }`,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                ],
                1
              )
            }),
            0
          ),
      !_vm.isFold
        ? _c("div", { staticClass: "copy-right" }, [
            _c("span", { staticClass: "copy-txt" }, [
              _vm._v("© 2020-2024 liebide.com"),
            ]),
          ])
        : _vm._e(),
      _c(
        "a",
        {
          staticClass: "sidebar-btn expand-btn",
          attrs: { href: "javascript:;" },
          on: { click: _vm.handleFold },
        },
        [
          !_vm.isFold
            ? _c("font-icon", {
                staticClass: "expand-icon",
                attrs: { href: "#icon-cebianshouqi" },
              })
            : _c("font-icon", {
                staticClass: "expand-icon",
                attrs: { href: "#icon-cebianshouqi1" },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }