<template>
    <div class="system-notice-wrapper">
        <!-- :class="{'disable-link': isDisableLink}" -->
        <!-- https://local.liebide.com:8080/Employee/#/recommendDetail/10725d83-4566-409d-8da0-08dac8728fee -->
        <a 
            :href="recommendUrl"
            target="_blank"
            v-if="payload.ext.type == 'newRecommend' || payload.ext.type == 'recommendStatusUpdate'"
            @click="goToLink">
            <div class="system-notice">
                <h3>{{titleMap[payload.ext.type]}}</h3>
                <div class="user-info">
                    <img 
                        @click.prevent.stop="handleCurrentConversation" 
                        :src="`${avatarBaseUrl}/user/${noticeData.operatorId}`" 
                    />
                    <div class="user-info-text">
                        <p v-if="payload.ext.type == 'newRecommend'">{{noticeData.operatorName}} 给你推荐了一份简历</p>
                        <p v-if="payload.ext.type == 'recommendStatusUpdate'">{{noticeData.operatorName}} 反馈了你的推荐</p>
                        <span v-if="payload.ext.type == 'newRecommend'">{{new Date(noticeData.recommendDate) | fullDate}}</span>
                        <span v-if="payload.ext.type == 'recommendStatusUpdate'">{{new Date(noticeData.flowUpdateDate) | fullDate}}</span>
                    </div>
                </div>
                <p class="recommend-info candidate">
                    候选人：{{noticeData.candidateName}} [{{noticeData.title}}]
                </p>
                <p v-if="payload.ext.type == 'recommendStatusUpdate'" class="recommend-info candidate">
                    推荐状态：{{noticeData.status | recommentStatusText}}
                </p>
                <p class="recommend-info">
                    推荐职位：{{noticeData.jobName}} [{{noticeData.customerName}}]
                </p>
            </div>
        </a>
        <div v-if="payload.ext.type == 'newJob'" class="system-notice">
            <h3>{{titleMap[payload.ext.type]}}</h3>
            <p class="recommend-info">
                昨日系统新增了 {{noticeData.newJobNumber}} 个新职位 
                <!-- <a :href="`/Headhunting/MyCompany.html#/jobList${jobDate}`" target="_blank" @click="goToLink">查看详情</a> -->
                <a :href="`/#/jobList${jobDate}`" target="_blank" @click="goToLink">查看详情</a>
            </p>
        </div>
        <!-- <div v-if="payload.ext.type == 'newJob'" class="system-notice">
            <h3>{{titleMap[payload.ext.type]}}</h3>
            <p class="recommend-info">
                产品经理 {{noticeData.newJobNumber}}个、产品运营 {{noticeData.newJobNumber}}个、前端开发 {{noticeData.newJobNumber}}个 
                <a :href="`/Headhunting/MyCompany.html#/jobList${jobDate}`" target="_blank" @click="goToLink">查看详情</a>
            </p>
        </div> -->
        <div v-if="payload.ext.type == 'recommendNotProcess'" class="system-notice">
            <h3>{{titleMap[payload.ext.type]}}</h3>
            <p class="recommend-info">
                本月有 {{noticeData.recommendNotProcess}} 位推荐给你的候选人还未处理，请尽快处理 
                <a href="/Headhunting/#/floatingList?status=0&time=1" target="_blank" @click="goToLink">立即处理</a>
            </p>
        </div>
        <div v-if="payload.ext.type == 'kickedGroup'" class="system-notice">
            <h3>{{titleMap[payload.ext.type]}}</h3>
            <p class="recommend-info">
                你被群主踢出群聊 {{payload.ext.data.groupName}}
            </p>
        </div>
        <div
            v-if="payload.ext.type == 'visibleLink'"
            class="system-notice visible-link"
            :class="(payload.ext.data.button && payload.ext.data.url) ? '' : 'visible-link-no-btn'">
            <h3 v-if="payload.ext.data.title" class="visible-link-title">{{payload.ext.data.title}}</h3>
            <p v-if="payload.ext.data.content" class="recommend-info visible-link-content">
                {{payload.ext.data.content}}
            </p>
            <a
                v-if="payload.ext.data.button && payload.ext.data.url && payload.ext.data.url.indexOf('departmentCreateRequestDeny') == -1"
                class="visible-link-button"
                :href="payload.ext.data.url"
                target="_blank"
                @click="goToLink">
                {{payload.ext.data.button}}
            </a>
            <a
                v-if="payload.ext.data.button && payload.ext.data.url && payload.ext.data.url.indexOf('departmentCreateRequestDeny') > -1"
                class="visible-link-button"
                href="javascript:void(0)"
                @click="showAuditDenyDetail(payload.ext.data.url)">
                {{payload.ext.data.button}}
            </a>
        </div>
    </div>
</template>

<script>
import { getFullDate, getDateWithYear } from '../../utils/date.js';
import { recommendHashMap } from '#/js/config/recommend-detail.json';
import emitter from '@src/js/mixins/emitter';
import { avatarHost } from '../../utils/avatarHost.js';
export default {
    mixins: [emitter],
    data(){
        return {
            titleMap: {
                "newRecommend": "简历推荐提醒",
                "recommendStatusUpdate": "推荐状态变更提醒",
                "newJob": "新职位提醒",
                "recommendNotProcess": "本月未处理推荐提醒",
                "kickedGroup": "群提示消息"
            }
        }
    },
    props: {
        message: Object,
        payload: Object
    },
    computed: {
        firmId() {
            return document.querySelector('input[name="firmId"]') ? document.querySelector('input[name="firmId"]').value : this.$store.state.user.userInfo.firmId;
        },
        noticeData() {
            return this.payload.ext.data;
        },
        recommendUrl() {
            if(location.pathname.includes('Employee/')) {
                return `/Employee/#/recommendDetail/${this.noticeData.recommendId}`;
            }
            if(location.pathname.includes('Operation/')) {
                let _activeTabName = '';
                if(this.noticeData.jobType == 5) {
                    _activeTabName = 'pm';
                }
                if(this.noticeData.jobType == 3) {
                    _activeTabName = 'hr';
                }
                if(this.noticeData.jobType === undefined || _activeTabName == '') return;
                return `/Operation/#/recommendDetail/${this.noticeData.recommendId}?activeTabName=${_activeTabName}`;
            }
            if(this.firmId == this.noticeData.candidateFirmId) {
                // return `/Headhunting/MyCompany.html#/candidateDetail/${this.noticeData.candidateId}/recommendation/${this.noticeData.recommendId}`;
                return `/#/candidateDetail/${this.noticeData.candidateId}/recommendation/${this.noticeData.recommendId}`;
            }else {
                // return `/Headhunting/MyCompany.html#/recommendation/${this.noticeData.recommendId}/recommendationList`
                return `/#/recommendation/${this.noticeData.recommendId}/recommendationList`
            }
        },
        jobDate() {
            let date = this.message.msgTime;
            if(!date) {
                return '';
            }
            if (new Date(date).toString().indexOf('1970') > -1) {
                date = date * 1000;
            }
            let yestoday = getDateWithYear(new Date(date - 24*60*60*1000));
            return `?startDate=${yestoday}&endDate=${yestoday}`;
        },
        avatarBaseUrl() {
            return avatarHost();
        },
        // isDisableLink() {
        //     return location.pathname.includes('Employee/') || location.pathname.includes('Operation/');
        // },
    },
    filters: {
        fullDate(date) {
            return date ? getFullDate(new Date(date)) : '';
        },
        recommentStatusText(status) {
            if(status || status === 0) {
                return recommendHashMap[status].text;
            }
            return "";
        },
    },
    methods: {
        handleCurrentConversation() {
            let unionId = this.payload.ext.type == 'newRecommend' ? this.noticeData.recommenderUnionId : this.noticeData.operatorId;
            if(this.message.toAccountId == unionId) {
                return false;
            }
            let conversationId = 'C2C_' + unionId;
            this.dispatch('IMBaseApp', 'select-conversation-from-system-notice', conversationId);
        },
        goToLink() {
            if(window.lbdTrack) {
                lbdTrack.track('IMSystemMessageClick', {
                    messageType: this.payload.ext.type
                })
            } else {
                _tracker.track('IMSystemMessageClick', JSON.stringify({
                    messageType: this.payload.ext.type
                }))
            }
        },
        showAuditDenyDetail(url) {
            let index = url.indexOf('-');
            let id = url.slice(index + 1, url.length)
            console.log(id);
            this.dispatch('IMBaseApp', 'show-audit-deny-detail-dialog', id);
        }
    }
}
</script>

<style lang="scss" scoped>
    .system-notice {
        width: 285px;
        min-height: 75px;
        margin: 0 auto;
        padding: 12px 16px 16px;
        border: 1px solid #DDDDDD;
        box-shadow: 0 0 2px 0 rgba(179,179,179,0.15);
        border-radius: 6px;
        > h3 {
            line-height: 20px;
            padding-bottom: 6px;
            margin: 0;
            font-size: 14px;
            color: #4A4A4A;
            font-weight: bold;
        }
        a {
            color: $primary;
        }
        .user-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 4px;
            margin-bottom: 12px;

            > img {
                display: block;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                border: 1px solid $primary;
                border-radius: 50%;
                flex-shrink: 0;
            }

            &-text {
                font-size: 12px;
                color: #333;
                p {
                    margin: 0;
                    word-wrap: break-word;
                    word-break: break-all;
                }

                span {
                    color: #ff7700;
                }
            }
        }
        .recommend-info {
            font-size: 12px;
            color: #333;
            line-height: 16px;
            margin: 0;

            &.candidate {
                margin-bottom: 8px;
            }
        }
        &.visible-link {
            padding-bottom: 40px;
            .visible-link-title {
                position: relative;
                &::after {
                    content: "";
                    display: inline-block;
                    width: calc(100% + 30px);
                    height: 1px;
                    background-color: #f0f0f0;
                    position: absolute;
                    left: -15px;
                    bottom: 0;
                }
            }
            .visible-link-content {
                margin-top: 10px;
            }
            .visible-link-button {
                float: right;
                padding: 0 12px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                height: 24px;
                background-color: $primary;
                color: #fff;
                font-size: 12px;
                margin-top: 4px;
            }
        }
        &.visible-link.visible-link-no-btn {
            padding-bottom: 16px;
        }
    }
</style>