import { phone as phoneUrl } from '#/js/config/javaApi.json';
import { phone_call } from '#/js/config/api.json';

export default {
    doCall(params) {
        return _request({
            method: 'POST',
            url: phoneUrl.do_call,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationPhone": _getCookie('access_token')
            },
            data: params
        })
    },
    doCallWithArea(params) {
        return _request({
            method: 'POST',
            url: phoneUrl.do_call_with_area,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationPhone": _getCookie('access_token')
            },
            data: params
        })
    },
    addInquiry(params) {
        return _request({
            method: 'POST',
            url: phoneUrl.update_inquiry,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationPhone": _getCookie('access_token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params
        })
    },
    getPhoneList() {
        return _request({
            method: 'GET',
            url: phone_call.get_phone_list,
            throwBusinessError: true
        })
    },
    setPhone(params) {
        return _request({
            method: 'POST',
            url: phone_call.set_phone,
            data: params
        })
    },
    
    // 添加手机华为白名单
    addMobileWhiteList(phone) {
        return _request({
            method: 'POST',
            url: phone_call.add_mobile_white_list.replace(/\%p/, phone),
            baseURL: "LbdOpenApi",
        });
    },

    getMsgCode (params) {
        return _request({
            method: 'POST',
            url: phone_call.get_msg_code,
            data: params
        })
    },
    deleteTelephone (params) {
        return _request({
            method: 'POST',
            url: phone_call.delete_telephone,
            data: params
        })
    }
}
