var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "summary-entry-container" },
    [
      _c("instant-message", {
        ref: "instantMessage",
        attrs: { isNewProject: _vm.isNewProject },
      }),
      _c("landingDialog", { ref: "landingDialog" }),
      _c("systemRecommendDialog", {
        ref: "systemRecommendDialog",
        on: { close: _vm.closeSystemRecommend },
      }),
      _c("notice-dialog", {
        ref: "noticeDialog",
        on: { showNoticeList: _vm.showNoticeList },
      }),
      _vm.isCfUser && !_vm.isTsdnuser
        ? _c("point-rank-dialog", { ref: "pointRankDialog" })
        : _vm._e(),
      _c("edit-department-dialog", { ref: "departmentDialog" }),
      _vm.isCfUser
        ? _c(
            "el-dialog",
            {
              ref: "confirmListDialog",
              attrs: {
                "custom-class": "confirm-list-dialog",
                width: "1200px",
                "show-close": false,
                visible: _vm.isShowConfListDialog,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShowConfListDialog = $event
                },
              },
            },
            [
              !_vm.hasConfirmed
                ? [
                    _c("div", { staticClass: "confirm-list-title" }, [
                      _vm._v(
                        "\n                候选人进入面试确认列表\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "confirm-list-tip" }, [
                      _vm._v(
                        "\n                由于面试双向确认的新功能于2021/2/5上线，为了将2021/1/1至2021/2/5的数据补充完整，TS和PM环节双向确认面试才可记为有效面试，请大家在2021/3/1零点前将“候选人进入面试确认列表”中的候选人处理完毕，以免影响QBB发放。该功能将于2021/3/1零点下线，过时数据将无法再追回。\n            "
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.confirmListLoading,
                            expression: "confirmListLoading",
                          },
                        ],
                        ref: "confirmListTable",
                        staticClass: "confirm-list-table",
                        attrs: {
                          data: _vm.confirmList,
                          "max-height": "284",
                          stripe: "",
                          "tooltip-effect": "light",
                        },
                        on: { "selection-change": _vm.confListSelectionChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "28" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "updated",
                            label: "时间",
                            width: "150",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "update-date-cell" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("dateFilter")(
                                                scope.row.updateDate
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4255209579
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "candidateName",
                            label: "候选人姓名",
                            width: "90",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "candidate-name-link",
                                        attrs: {
                                          href: `/#/candidateDetail/${scope.row.candidateId}`,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(scope.row.candidateName) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3835508286
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "jobName",
                            label: "推荐职位",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(scope.row.jobName) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2290989510
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "customerName",
                            label: "推荐公司",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(scope.row.customerName) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2933353669
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "status",
                            label: "最新状态",
                            width: "110",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("recommendText")(
                                              scope.row.status
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            899343842
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "recommenderName",
                            label: "推荐顾问",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(scope.row.recommenderName) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            949095458
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "publisherName",
                            label: "职位发布顾问",
                            width: "120",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(scope.row.jobOwnerName) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2220405159
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "recommendDate",
                            label: "推荐时间",
                            width: "150",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("dateFilter")(
                                              scope.row.recommendDate
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            514226155
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "interviewDate",
                            label: "进入面试时间",
                            width: "150",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("dateFilter")(
                                              scope.row.interviewDate
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3723468662
                          ),
                        }),
                        _c("template", { slot: "empty" }, [
                          _c("div", [
                            _c("span", { staticClass: "empty-img" }),
                            _c("p", { staticClass: "empty-data" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "work-table-footer" }, [
                      _c("div", { staticClass: "footer-left" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.confListAllSelect,
                              expression: "confListAllSelect",
                            },
                          ],
                          attrs: { hidden: "", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.confListAllSelect)
                              ? _vm._i(_vm.confListAllSelect, null) > -1
                              : _vm.confListAllSelect,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.confListAllSelect,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.confListAllSelect = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.confListAllSelect = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.confListAllSelect = $$c
                              }
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "all-select-checkbox",
                          on: { click: _vm.handleConfListAllSelect },
                        }),
                        _c("span", [_vm._v("全选")]),
                        _c("span", { staticClass: "select-count" }, [
                          _vm._v("已选择\n                        "),
                          _c("span", { staticClass: "color-orange" }, [
                            _vm._v(_vm._s(_vm.confListMultipleCount)),
                          ]),
                          _vm._v(
                            "\n                        条记录\n                    "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "footer-right" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "normal-btn confirm-into-btn",
                              attrs: {
                                disabled: _vm.confListMultipleCount == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleConfirmInto(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        确认进入过面试\n                    "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "normal-btn",
                              attrs: {
                                disabled: _vm.confListMultipleCount == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleConfirmInto(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        未进入过面试\n                    "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "normal-btn",
                              on: { click: _vm.hideConfListDialog },
                            },
                            [
                              _vm._v(
                                "\n                        退出\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "confirm-done" }, [
                      _c("i", { staticClass: "confirm-done-icon" }),
                      _c("p", [
                        _vm._v(
                          "已确认所选候选人" +
                            _vm._s(
                              _vm.hasConfirmedType == 0
                                ? "进入面试"
                                : "未进入面试"
                            )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "confirm-done-footer" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "normal-btn",
                            on: { click: _vm.hideConfListDialog },
                          },
                          [
                            _vm._v(
                              "\n                    退出\n                "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "normal-btn confirm-into-btn",
                            on: { click: _vm.continueConfirm },
                          },
                          [
                            _vm._v(
                              "\n                    继续确认\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm.isCfUser
        ? _c("anniversary-dialog", { ref: "anniversaryDialog" })
        : _vm._e(),
      _c("summary-dialog", {
        ref: "summaryDialog",
        attrs: { "close-summary-dialog": _vm.closeDialog },
      }),
      _c("realtimedata-dialog", {
        ref: "realtimedataDialog",
        attrs: { "close-realtimedata-dialog": _vm.closeRealtimedataDialog },
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showAppDesc
          ? _c("div", { staticClass: "helpful-app-desc" }, [
              _c("h3", [
                _vm._v("\n                全部应用\n                "),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: { click: _vm.handleHideAppDesc },
                }),
              ]),
              _c("ul", [
                _c("li", [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/floatOption/logo_xls.png"),
                    },
                  }),
                  _c("div", { staticClass: "helpful-app-desc-right" }, [
                    _c("p", { staticClass: "title" }, [_vm._v("简历小猎手")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "帮助你自动保存招聘网站简历、自动判重匹配人才库简历，快速更新旧简历的浏览器插件。"
                      ),
                      _c("br"),
                      _vm._v(
                        "当前支持领英、脉脉、猎聘、拉勾、智联、智联卓聘、前程无忧等主流招聘网站。"
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://xls.bazhua.me/download",
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "span",
                          { on: { click: _vm.downloadResumeHunter } },
                          [_vm._v("下载»")]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/floatOption/logo_lbd.png"),
                    },
                  }),
                  _c("div", { staticClass: "helpful-app-desc-right" }, [
                    _c("p", { staticClass: "title" }, [_vm._v("猎必得APP")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "\n                            1.APP也可在企业库快速找人，收藏简历、编辑简历、拨打电话、添加寻访、添加事项提醒；"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            2.实时查看我推荐的候选人的流程状态，快速处理推荐给我的候选人的流程状态；"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            3.可通过Trackinglist轻松跟进人选。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            4.智能拨号：支持在线拨打免费网络电话\n                        "
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.handleToggleAndroidAppQrcode },
                      },
                      [_vm._v("安卓下载»")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "ios-btn",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.handleToggleIOSAppQrcode },
                      },
                      [_vm._v("苹果下载»")]
                    ),
                    _c("div", { staticClass: "qrcode-box" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAndroidAppQrcode,
                            expression: "showAndroidAppQrcode",
                          },
                        ],
                        staticClass: "qrcode android-qrcode",
                        attrs: {
                          src: require("@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png"),
                        },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showIOSAppQrcode,
                            expression: "showIOSAppQrcode",
                          },
                        ],
                        staticClass: "qrcode ios-qrcode",
                        attrs: {
                          src: require("@src/assets/images/floatOption/qrcode_dl_ios_app.png"),
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }