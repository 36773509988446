var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instant-message", class: { "is-home": _vm.isHomePage } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isHomePage || _vm.isShow,
              expression: "isHomePage || isShow",
            },
          ],
          staticClass: "instant-message-container",
          class: {
            "large-size": _vm.isLargeSize,
            "in-new-project": _vm.isNewProject,
            "is-home-page": _vm.isHomePage,
          },
        },
        [
          _c("side-bar", {
            ref: "sideBar",
            attrs: {
              conversationList: _vm.conversationList,
              isLargeSize: _vm.isLargeSize,
              userId: _vm.userId,
              onlineUserList: _vm.onlineUserList,
              innerLabelMap: _vm.innerLabelMap,
              companyGroupId: _vm.companyGroupId,
              currentConversationID: _vm.currentConversation.conversationID,
              atMeConversationList: _vm.atMeConversationList,
            },
            on: {
              closeInstantMessage: _vm.closeInstantMessage,
              updateCurrentConversation: _vm.updateCurrentConversation,
              updateMessagePosition: _vm.updateMessagePosition,
              updateAtMeData: _vm.updateAtMeData,
            },
          }),
          _c("current-conversation", {
            ref: "current-conversation",
            attrs: {
              currentConversation: _vm.currentConversation,
              isLargeSize: _vm.isLargeSize,
              currentMessageList: _vm.currentMessageList,
              currentMessageListFetched: _vm.currentMessageListFetched,
              currentUserProfile: _vm.currentUserProfile,
              isCompleted: _vm.isCompleted,
              isAfterCompleted: _vm.isAfterCompleted,
              memberList: _vm.memberList,
              userId: _vm.userId,
              userProfileMap: _vm.c2cUserProfileMap,
              isInner: _vm.checkInner(_vm.currentConversation),
              messageLoading: _vm.messageLoading,
              messagePagingLoading: _vm.messagePagingLoading,
              isPositionMode: _vm.isPositionMode,
              flipOverType: _vm.flipOverType,
              companyGroupId: _vm.companyGroupId,
            },
            on: {
              quitGroup: _vm.quitGroup,
              enLargeDialog: _vm.enLargeDialog,
              closeInstantMessage: _vm.closeInstantMessage,
              getMore: _vm.getMoreMessageList,
              updateMessageList: _vm.updateMessageList,
              updateCurrentConversation: _vm.updateCurrentConversation,
            },
          }),
        ],
        1
      ),
      _c("image-previewer", {
        ref: "image-previewer",
        attrs: { imgUrlList: _vm.imgUrlList },
      }),
      _c("share-to-im-dialog", {
        ref: "share-to-im-dialog",
        attrs: {
          conversationList: _vm.conversationList,
          userProfileMap: _vm.c2cUserProfileMap,
          firmId: _vm.firmId,
          innerLabelMap: _vm.innerLabelMap,
        },
      }),
      _c("audit-deny-detail-dialog", { ref: "auditDenyDetailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }