<template>
    <svg class="icon font-icon" aria-hidden="true">
        <use :xlink:href="href"></use>
    </svg>
</template>

<script>
    export default {
        name: "FontIcon",
        data() {
            return {
            }
        },
        props: {
            href: {
                type: String,
                required: true
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
</style>
