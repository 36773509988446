var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "inquiry-dialog",
      attrs: {
        visible: _vm.isShow,
        title: _vm.isEdit ? "编辑沟通寻访" : "添加沟通寻访",
        width: "610px",
        "show-close": false,
        "before-close": _vm.handleCancel,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _vm.isFromPhoneCall
        ? _c("p", [
            _vm._v("为候选人：" + _vm._s(_vm.realName) + " 添加沟通寻访"),
          ])
        : _vm._e(),
      _c("div", { staticClass: "inquiry-dialog-tab" }, [
        _c(
          "label",
          { class: _vm.validChecked ? "radio-item selected" : "radio-item" },
          [
            _c("span", { staticClass: "radio-wrap" }, [
              _c("input", {
                staticClass: "radio-input",
                attrs: { type: "radio", name: "remark" },
                on: { click: _vm.handleValid },
              }),
            ]),
            _c("span", [_vm._v("有效寻访")]),
          ]
        ),
        !_vm.isEdit
          ? _c(
              "label",
              {
                class: _vm.invalidChecked
                  ? "radio-item selected"
                  : "radio-item",
              },
              [
                _c("span", { staticClass: "radio-wrap" }, [
                  _c("input", {
                    staticClass: "radio-input",
                    attrs: { type: "radio", name: "remark" },
                    on: { click: _vm.handleInvalid },
                  }),
                ]),
                _c("span", [_vm._v("无效寻访")]),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "inquiry-item" }, [
        false
          ? _c(
              "label",
              {
                class: _vm.validChecked ? "radio-item selected" : "radio-item",
              },
              [
                _c("span", { staticClass: "radio-wrap" }, [
                  _c("input", {
                    staticClass: "radio-input",
                    attrs: { type: "radio", name: "remark" },
                    on: { click: _vm.handleValid },
                  }),
                ]),
                _c("span", [_vm._v("有效寻访")]),
                _vm.validChecked && !_vm.isTrackingList && !_vm.isEdit
                  ? _c(
                      "el-checkbox",
                      {
                        staticClass: "checkbox-wrap",
                        model: {
                          value: _vm.addToTrackingList,
                          callback: function ($$v) {
                            _vm.addToTrackingList = $$v
                          },
                          expression: "addToTrackingList",
                        },
                      },
                      [_vm._v("将该简历添加至Tracking List方便后续沟通")]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.validChecked,
                expression: "validChecked",
              },
            ],
            staticClass: "common-inquiry",
          },
          [
            _c("el-input", {
              staticClass: "remark-text",
              attrs: {
                type: "textarea",
                autosize: { minRows: 3, maxRows: 9 },
                placeholder: "添加一条新寻访记录",
              },
              model: {
                value: _vm.inquiryContent,
                callback: function ($$v) {
                  _vm.inquiryContent = $$v
                },
                expression: "inquiryContent",
              },
            }),
            _vm.validChecked && !_vm.isTrackingList && !_vm.isEdit
              ? _c(
                  "el-checkbox",
                  {
                    staticClass: "checkbox-wrap",
                    model: {
                      value: _vm.addToTrackingList,
                      callback: function ($$v) {
                        _vm.addToTrackingList = $$v
                      },
                      expression: "addToTrackingList",
                    },
                  },
                  [_vm._v("将该简历添加至Tracking List方便后续沟通")]
                )
              : _vm._e(),
            _c("div", { staticClass: "scroll-box" }, [
              !_vm.isFromAuthorization &&
              (_vm.isFromPhoneCall ||
                (!_vm.isEdit && _vm.isFirmResume && _vm.isCandidateDetail))
                ? _c(
                    "div",
                    { staticClass: "candidate-info" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                        更新候选人的最新公司和职位\n                        "
                        ),
                      ]),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("customer-select-or-write", {
                                staticClass: "candidate-info-input",
                                class: !_vm.changeCompanyOrTitle
                                  ? "no-checkbok"
                                  : "",
                                attrs: {
                                  size: "mini",
                                  customerId: _vm.customerId,
                                  customerName: _vm.company,
                                },
                                on: {
                                  "update:customerId": function ($event) {
                                    _vm.customerId = $event
                                  },
                                  "update:customer-id": function ($event) {
                                    _vm.customerId = $event
                                  },
                                  "update:customerName": function ($event) {
                                    _vm.company = $event
                                  },
                                  "update:customer-name": function ($event) {
                                    _vm.company = $event
                                  },
                                  "finish-callback": _vm.finishCallback,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-input", {
                                staticClass: "candidate-info-input",
                                class: !_vm.changeCompanyOrTitle
                                  ? "no-checkbok"
                                  : "",
                                attrs: {
                                  size: "mini",
                                  placeholder: "当前职位名称",
                                  maxlength: 128,
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function ($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "date-picker-pannel" },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-calendar" },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "date-picker-wrap" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "month",
                                        placeholder: "开始时间",
                                        "picker-options":
                                          _vm.setExperienceStartDateOption(),
                                      },
                                      model: {
                                        value: _vm.workStartDate,
                                        callback: function ($$v) {
                                          _vm.workStartDate = $$v
                                        },
                                        expression: "workStartDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "date-picker-split" },
                                  [_vm._v("~")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "date-picker-wrap" },
                                  [
                                    _vm.workEndDate &&
                                    _vm.workEndDate.toString().indexOf("9999") >
                                      -1
                                      ? _c(
                                          "div",
                                          { staticClass: "date-tonow" },
                                          [
                                            _vm._v(
                                              "\n                                        至今\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.workEndDate &&
                                    _vm.workEndDate.toString().indexOf("9999") >
                                      -1
                                      ? _c("el-date-picker", {
                                          staticClass: "opacity-date-picker",
                                          attrs: {
                                            type: "month",
                                            placeholder: "结束时间",
                                            "picker-options":
                                              _vm.setExperienceEndDateOption(),
                                          },
                                          model: {
                                            value: _vm.workEndDate,
                                            callback: function ($$v) {
                                              _vm.workEndDate = $$v
                                            },
                                            expression: "workEndDate",
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.workEndDate ||
                                    _vm.workEndDate
                                      .toString()
                                      .indexOf("9999") === -1
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            type: "month",
                                            placeholder: "结束时间",
                                            "picker-options":
                                              _vm.setExperienceEndDateOption(),
                                          },
                                          model: {
                                            value: _vm.workEndDate,
                                            callback: function ($$v) {
                                              _vm.workEndDate = $$v
                                            },
                                            expression: "workEndDate",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.customerId
                                ? _c("customer-department-select", {
                                    ref: "departmentSelect",
                                    staticClass: "candidate-info-depart-select",
                                    attrs: {
                                      size: "mini",
                                      customerId: _vm.customerId,
                                      departmentId: _vm.customerDepartmentId,
                                      customerDepartmentName:
                                        _vm.customerDepartmentName,
                                      placeholder:
                                        '输入完整路径，多级部门用"，"分隔',
                                    },
                                    on: {
                                      "update-department": _vm.updateDepartment,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.changeCompanyOrTitle
                        ? _c(
                            "el-checkbox",
                            {
                              staticClass: "checkbox-item",
                              model: {
                                value: _vm.isAddWork,
                                callback: function ($$v) {
                                  _vm.isAddWork = $$v
                                },
                                expression: "isAddWork",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        添加为最新的工作经历\n                        "
                              ),
                              _c("i", { staticClass: "add-work-tip" }, [
                                _vm._v("(新增一段最近工作经历时，请记得勾选)"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "select-block" }, [
                _c(
                  "div",
                  { staticClass: "select-block-title" },
                  [
                    _c("font-icon", {
                      staticClass: "active time-icon",
                      attrs: { href: "#icon-time-active" },
                    }),
                    _vm._v(
                      "\n                        跳槽时机\n                    "
                    ),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "inquiry-select-list" },
                  _vm._l(_vm.opportunityArr, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: item.selected
                          ? "select-item selected"
                          : "select-item",
                        on: {
                          click: function ($event) {
                            return _vm.handleItem(
                              item,
                              _vm.opportunityArr,
                              "opportunitySelected"
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-block" }, [
                _c(
                  "div",
                  { staticClass: "select-block-title" },
                  [
                    _c("font-icon", {
                      staticClass: "active",
                      attrs: { href: "#icon-building-active" },
                    }),
                    _vm._v(
                      "\n                        雇主画像\n                    "
                    ),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "inquiry-select-list" },
                  _vm._l(_vm.employerArr, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: item.selected
                          ? "select-item selected"
                          : "select-item",
                        on: {
                          click: function ($event) {
                            return _vm.handleItem(
                              item,
                              _vm.employerArr,
                              "employerSelected"
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-block" }, [
                _c(
                  "div",
                  { staticClass: "select-block-title" },
                  [
                    _c("font-icon", {
                      staticClass: "active",
                      attrs: { href: "#icon-salary-active" },
                    }),
                    _vm._v(
                      "\n                        期望报酬\n                    "
                    ),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "inquiry-select-list" },
                  [
                    _vm._l(_vm.rewardArr, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: item.selected
                            ? "select-item selected"
                            : "select-item",
                          on: {
                            click: function ($event) {
                              return _vm.handleItem(
                                item,
                                _vm.rewardArr,
                                "rewardSelected"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isInput,
                            expression: "!isInput",
                          },
                        ],
                        class: _vm.isCustom
                          ? "select-item selected"
                          : "select-item",
                        on: {
                          click: function ($event) {
                            return _vm.handleItem(
                              { id: 0, text: "" },
                              _vm.rewardArr,
                              "rewardSelected"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.customInput ? _vm.customInput : "自定义")
                        ),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isInput,
                          expression: "isInput",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customInput,
                          expression: "customInput",
                        },
                      ],
                      ref: "customInput",
                      staticClass: "custom-input",
                      attrs: { type: "text", maxLength: "36" },
                      domProps: { value: _vm.customInput },
                      on: {
                        blur: _vm.handleBlur,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.customInput = $event.target.value
                        },
                      },
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "hide-switch" },
                [
                  _c("span", { staticClass: "hide-switch-text" }, [
                    _vm._v("是否隐藏"),
                  ]),
                  _c("el-switch", {
                    attrs: {
                      width: 40,
                      "active-color": "#38bc9d",
                      "inactive-color": "#999999",
                    },
                    on: { change: _vm.handleHideStatus },
                    model: {
                      value: _vm.isHide,
                      callback: function ($$v) {
                        _vm.isHide = $$v
                      },
                      expression: "isHide",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      !_vm.isEdit
        ? _c("div", { staticClass: "inquiry-item" }, [
            false
              ? _c(
                  "label",
                  {
                    class: _vm.invalidChecked
                      ? "radio-item selected"
                      : "radio-item",
                  },
                  [
                    _c("span", { staticClass: "radio-wrap" }, [
                      _c("input", {
                        staticClass: "radio-input",
                        attrs: { type: "radio", name: "remark" },
                        on: { click: _vm.handleInvalid },
                      }),
                    ]),
                    _c("span", [_vm._v("无效寻访")]),
                  ]
                )
              : _vm._e(),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invalidChecked,
                    expression: "invalidChecked",
                  },
                ],
                staticClass: "invalid-list",
              },
              _vm._l(_vm.invalidArr, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: item.selected ? "li-item selected" : "li-item",
                    on: {
                      click: function ($event) {
                        return _vm.handleItem(
                          item,
                          _vm.invalidArr,
                          "invalidText"
                        )
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v("\n                    " + _vm._s(item.text)),
                      item.remark
                        ? _c("i", { staticClass: "remark" }, [
                            _vm._v("（" + _vm._s(item.remark) + "）"),
                          ])
                        : _vm._e(),
                    ]),
                    item.selected
                      ? _c("i", {
                          staticClass: "el-icon-check cancel-selected-icon",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.cancelSelected.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invalidChecked,
                    expression: "invalidChecked",
                  },
                ],
                staticClass: "hide-switch",
              },
              [
                _c("span", { staticClass: "hide-switch-text" }, [
                  _vm._v("是否隐藏"),
                ]),
                _c("el-switch", {
                  attrs: {
                    width: 40,
                    "active-color": "#38bc9d",
                    "inactive-color": "#999999",
                  },
                  on: { change: _vm.handleHideStatus },
                  model: {
                    value: _vm.isHide,
                    callback: function ($$v) {
                      _vm.isHide = $$v
                    },
                    expression: "isHide",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }