<template>
    <inquiry-dialog ref="inquiryDialog" @phone-call-inquiry-added="refreshCandidateInquiry"></inquiry-dialog>
</template>

<script>
    import InquiryDialog from '#/component/common/dialog/inquiryDialog.vue';
    import { candidate as candidateDetailConfig } from '#/js/config/api.json';

    export default {
        name: "global-inquiry-dialog",
        components: {
            InquiryDialog,
        },
        props:{
        },
        computed:{
           
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            initInquirySignalREvent() {
                //挂断电话弹出寻访弹框
                window._globalSignalR.onSignalRData('NotifyCallServiceEnd',(val)=>{
                    console.log('NotifyCallServiceEnd接收成功');
                    console.log(val.data.resumeId);
                    console.log(this.$store.state.phoneCall.resumeId);

                    if(val.data.resumeId == this.$store.state.phoneCall.resumeId) {
                        let data = {
                            resumeId: val.data.resumeId,
                            recordId: val.data.recordId 
                        }
                        this.showGlobalInquiryDialog(data);
                    }
                })

                //关闭寻访弹框
                window._globalSignalR.onSignalRData('CloseInquiryLog',(val)=>{
                    console.log('CloseInquiryLog接收成功')
                    this.hideGlobalInquiryDialog();
                })
            },
            showGlobalInquiryDialog(data) {
                this.$store.dispatch('setLastCallResumeId', '');

                let { resumeId, recordId } = {...data};
                let url = candidateDetailConfig.candidate_detail.replace('%p', resumeId);
                _request({
                    url: url,
                    method: "GET"
                }).then(res => {
                    this.$refs.inquiryDialog.show({
                        resumeId: resumeId,
                        recordId: recordId,
                        candidateId: res.id,
                        isFirmResume: res.isFirm,
                        company: res.company,
                        title: res.title,
                        realName: res.realName,
                        workExperiences: res.workExperiences,
                        isFromPhoneCall: true,
                        isFromAuthorization: res.isFromAuthorization
                    });
                })
            },
            hideGlobalInquiryDialog() {
                let inquiryDialog = this.$refs.inquiryDialog;
                inquiryDialog.isShow = false;
                inquiryDialog.handleResetValid();
                inquiryDialog.handleResetInvalid();
            },
            refreshCandidateInquiry() {
                this.$emit('refresh-candidate-inquiry');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
