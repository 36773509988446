<template>
    <div
        class="float-option-wrap"
        :class="isNewProject?'position-top-bar':'position-float'"
        id="floatOption"
        @mouseleave="handleToolsHide"
        v-show="isNewProject ? showTools : true"
    >
        <div class="float-option">
            <!-- 我的积分 -->
            <div class="float-item integral-ball default-hover" id="integralBall" v-if="isShowIntegralGuide&&!isNewProject"
                @click="goToMyPoint">
                <p>
                    <span class="integral-count" v-show="!isShowPointUpdate">{{integralCount | currency('',0)}}<br/></span>
                    <span class="integral-count" v-show="isShowPointUpdate">
                        <ICountUp ref="countUp" :endVal="integralCount" :options="options"/><br/>
                    </span>
                    <span style="display: inline-block;white-space: nowrap;">我的积分</span>
                </p>
                <transition name="integral">
                    <div v-if="isShowIntegralTip" class="integral-tip">积分{{increaseCount > 0 ? '+' :''}}{{increaseCount}}</div>
                </transition>
            </div>

            <!-- 日程提醒 -->
            <div class="float-item schedule-notice" v-if="!isNewProject">
                <notice-widget :isNewProject="isNewProject" ref="noticeWidget"></notice-widget>
            </div>
            <!-- 日程提醒end -->

            <div class="float-item-bottom" :class="forceExpand ? 'force-expand' : ''">
                <div class="float-item work-box" v-if="isShowWorkBox">
                    <p>工具箱<br/>
                        <i class="float-icon-triangle"></i>
                    </p>
                </div>

                <!-- 每日总结 -->
                <div
                    class="float-item daily-summary has-sub-menu"
                    v-if="isShowWorkReport">
                    <!-- v-if="isShowWorkReport || isShowDailyCalendar"> -->
                    <p>每日<br/>总结</p>
                    <div class="sub-menu-container">
                        <div class="sub-menu">
                            <!-- 工作日报 -->
                            <el-tooltip
                                content="首次请在6:30pm后查看日报数据哦"
                                placement="left"
                                effect="light"
                                popper-class="report-tip"
                                v-if="isShowWorkReport"
                                :disabled="!hasHoverTip">
                                <div class="float-item work-report" @click="showWorkReport">
                                    <i class="float-item-icon el-icon-document"></i>
                                    <p>工作<br/>日报</p>
                                </div>
                            </el-tooltip>
                            <!-- 每日日历 -->
                            <!-- <div class="float-item daily-calendar" v-if="isShowDailyCalendar" @click="showDailyCalendar" >
                                <i class="float-item-icon el-icon-date"></i>
                                <p>每日<br/>日历</p>
                            </div> -->
                        </div>
                    </div>
                </div>

                <!-- 实时数据 -->
                <div class="float-item realtime-data" v-if="isShowRealtimeData" @click="showRealtimeData" >
                    <p>实时<br/>数据</p>
                </div>

                <!-- 增值应用 -->
                <div class="float-item helpful-application has-sub-menu" ref="helpful-application" @click="handleShowAppDesc">
                    <p>增值<br/>应用</p>
                    <!--<div class="sub-menu-container">
                        <div class="sub-menu">
                            简历小猎手
                            <div class="float-item resume-hunter" @click="downloadResumeHunter" >
                                <i class="float-item-icon xls-icon"></i>
                                <p>简历<br/>小猎手</p>
                            </div>
                            电话助手
                            <div class="float-item phone-assistant" v-if="isShowPhoneAssistant" @click="downloadPhoneAssistant">
                                <i class="float-item-icon phone-icon"></i>
                                <p>电话<br/>助手</p>
                                <div class="download-tip" v-if="isDownloadPhoneAssistant">正在下载~</div>
                            </div>
                        </div>
                    </div>-->
                </div>

                <!-- 小秘书 -->
                <el-popover
                    placement="left"
                    width="152"
                    trigger="hover"
                    :visible-arrow="false"
                    class="float-item small-secretary"
                    popper-class="help-detail-popover">
                    <div class="help-detail">
                        <img class="wechat-code" width="98" height="98" :src="helperWechatCode" alt="微信二维码">
                        <p class="liebide-phone">0755-26014780</p>
                        <p>
                            <a href="mailto: service@liebide.com" class="liebide-email">service@liebide.com</a>
                        </p>
                    </div>
                    <p slot="reference">小秘书</p>
                </el-popover>
            </div>
            
            <!-- 工作日报弹窗 -->
            <summary-dialog
                ref="summaryDialog"
                :close-summary-dialog="closeDialog"
            ></summary-dialog>

            <!-- 实时数据弹窗 -->
            <realtimedata-dialog
                ref="realtimedataDialog"
                :close-realtimedata-dialog="closeRealtimedataDialog"
            ></realtimedata-dialog>

            <!-- 每日日历弹窗 -->
            <!-- <daily-calendar-dialog
                ref="dailyCalendarDialog"
                :close-dialog="closeDialog"
                v-if="isShowDailyCalendar"
            ></daily-calendar-dialog> -->

            <!-- <ali-resume-dialog ref="aliResumeDialog" v-if="isShowDailyCalendar"></ali-resume-dialog> -->
        </div>
        <div class="float-option float-option-interval float-option-systemRecommend" :class="[systemRecommendIsShow === 3 ? 'show' : '', systemRecommendIsShow === 2 ? 'hidden' : '']">
            <div class="float-item systemRecommend" @click="showSystemRecommend">
                <p>智能<br/>推荐</p>
            </div>
        </div>
        <transition name="fade">
            <div v-if="showAppDesc" class="helpful-app-desc">
                <h3>
                    全部应用
                    <i class="el-icon-close" @click="handleHideAppDesc"></i>
                </h3>
                <ul>
                    <li>
                        <img :src="require('@src/assets/images/floatOption/logo_xls.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">简历小猎手</p>
                            <p class="desc">帮助你自动保存招聘网站简历、自动判重匹配人才库简历，快速更新旧简历的浏览器插件。<br/>当前支持领英、脉脉、猎聘、拉勾、智联、智联卓聘、前程无忧等主流招聘网站。</p>
                            <a href="https://xls.bazhua.me/download" target="_blank"><span @click="downloadResumeHunter">下载&raquo;</span></a>
                        </div>
                    </li>
                    <!-- <li v-if="isShowPhoneAssistant">
                        <img :src="require('@src/assets/images/floatOption/logo_phone.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">电话助手</p>
                            <p class="desc">集一键拨打电话、智能识别简历自动弹屏、添加寻访、报表分析、录音回听等功能于一身的电话拨打系统，接入设备登录后即可使用。</p>
                            <a href="http://dl.rchezi.com/PhoneAssistant/PhoneAssistant_Setup.exe">
                                <span @click="downloadPhoneAssistant">下载&raquo;</span>
                            </a>
                            <a href="/Headhunting/MyCompany.html#/phoneAssistant" target="_blank">使用说明&raquo;</a>
                        </div>
                    </li> -->
                    <li>
                        <img :src="require('@src/assets/images/floatOption/logo_lbd.png')" />
                        <div class="helpful-app-desc-right">
                            <p class="title">猎必得APP</p>
                            <p class="desc">
                                1.APP也可在企业库快速找人，收藏简历、编辑简历、拨打电话、添加寻访、添加事项提醒；<br/>
                                2.实时查看我推荐的候选人的流程状态，快速处理推荐给我的候选人的流程状态；<br/>
                                3.可通过Trackinglist轻松跟进人选。<br/>
                                4.智能拨号：支持在线拨打免费网络电话
                            </p>
                            <a href="javascript:void(0)" @click="handleToggleAndroidAppQrcode">安卓下载&raquo;</a>
                            <a class="ios-btn" href="javascript:void(0)" @click="handleToggleIOSAppQrcode">苹果下载&raquo;</a>
                            <!-- <a class="ios-btn" href="javascript:void(0)">苹果下载<i>（即将开放）</i></a> -->
                            <div class="qrcode-box">
                                <img class="qrcode android-qrcode" v-show="showAndroidAppQrcode" :src="require('@src/assets/images/floatOption/qrcode_dl_android_app_V1.0.3.png')" />
                                <img class="qrcode ios-qrcode" v-show="showIOSAppQrcode" :src="require('@src/assets/images/floatOption/qrcode_dl_ios_app.png')" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>
        <div class="helpful-app-guide" v-if="forceExpand" @click="(e) => e.stopPropagation()">
            <div class="helpful-app-guide-container" :style="{top: helpfulGuideTop + 'px'}">
                <div class="helpful-app-guide-target">
                    <p>增值<br />应用</p>
                </div>
                <img :src="require('@src/assets/images/floatOption/helpful_app_guide.png')" />
                <span class="helpful-app-guide-btn" @click="handleCloseHelpfulGuide">好的</span>
            </div>
            <div class="helpful-app-guide-mask"></div>
        </div>
        <global-inquiry-dialog ref="globalInquiryDialog"></global-inquiry-dialog>
    </div>
</template>

<script>
    import ICountUp from 'vue-countup-v2';
    import {integral as integral_url} from '#/js/config/api.json';
    import summaryDialog from './summary-dialog.vue';
    import RealtimedataDialog from './realtimedata-dialog.vue';
    import NoticeWidget from '#/component/notice/noticeWidget';
    // import DailyCalendarDialog from './daily-calendar-dialog.vue';
    // import AliResumeDialog from './ali-resume-dialog.vue';
    import globalSignalR from '#/js/util/globalSignalR.js';
    import GlobalInquiryDialog from '#/component/common/dialog/globalInquiryDialog.vue';

    if(!window.messageQueue){
        window.messageQueue = [];
    }

    export default {
        name: "floatOption",
        components: {
            ICountUp,
            summaryDialog,
            RealtimedataDialog,
            NoticeWidget,
            // DailyCalendarDialog,
            // AliResumeDialog,
            GlobalInquiryDialog
        },
        props: {
            user: Object,
            systemRecommendIsShow: Number,
            isNewProject: false
        },
        data() {
            return {
                integralCount: 0,//当前积分总数
                isShowIntegralGuide: false, //是否展示积分球
                isShowIntegralTip: false, //是否展示积分 增加 tip
                increaseCount: 10,//增长积分数
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: '',
                    startVal: 0
                },
                isShowPointUpdate: false, //是否展示积分变化
                tipQueenArr: [],//提示的队列，先进先出原则
                hasHoverTip: false, //是否展示工作日报提醒
                isShowWorkReport: false, //是否展示工作日报
                isShowRealtimeData: false, //是否展示实时数据
                isShowPhoneAssistant: false, //是否展示电话助手
                newUpload: "20190429", //电话助手新版本发布日期
                isDownloadPhoneAssistant: false, //是否点击电话助手下载
                helperWechatCode: "//hstatic.hirede.com/lbd/images/helper/hh-helper-wechat.jpg", //小秘书二维码
                isShowWorkBox: true,
                showTools: false,
                isShowDialog: false,
                // isShowDailyCalendar: false,
                showAppDesc: false,
                showAndroidAppQrcode: false,
                showIOSAppQrcode: false,
                forceExpand: false,
                helpfulGuideTop: 0
            };
        },
        watch: {
            user: {
                handler: function (val) {
                    if(!document.querySelector('input[name="firmId"]')) {
                        if(val.isCFUser) {
                            //积分初始化
                            this.getMyPoint();
                            // 积分更新 暴露给全局
                            window.UPDATE_INTEGRAL = (json) => {
                                this.updateCount(json.point);
                            }
                        }
                        if(val.isEmployer) {
                            this.helperWechatCode = '//hstatic.hirede.com/lbd/images/helper/hr-helper-wechat.jpg';
                            this.isShowIntegralGuide = false;
                            this.isShowRealtimeData = false;
                            this.isShowPhoneAssistant = false;
                            this.isShowWorkReport = false;
                            this.isShowWorkBox = false;
                            // this.isShowDailyCalendar = false;
                        } else {
                            this.isShowIntegralGuide = val.isCFUser;
                            this.isShowWorkReport = true;
                            this.isShowWorkBox = true;
                            this.isShowRealtimeData = val.firmId ? true : false;
                            this.isShowPhoneAssistant = val.isCFUser;
                            // this.isShowDailyCalendar = val.isCFUser;
                        }
                    } else {
                        if(document.querySelector('input[name="isCFUser"]').value) {
                            //积分初始化
                            this.getMyPoint();
                            // 积分更新 暴露给全局
                            window.UPDATE_INTEGRAL = (json) => {
                                this.updateCount(json.point);
                            }
                        }
                    }
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            // if(window.location.href.indexOf('Headhunting')<=-1){
            //     let urlPrefix = document.querySelector('input[name="signalRServerUrl"]').value;
            //     window._globalSignalR = new globalSignalR({
            //         connectName: 'BazaHub',
            //         urlPrefix:urlPrefix,
            //         tokenFn: () => { return _getCookie('access_token'); },
            //         // hubMethodName:["AddPoints","closeWorkReport","ReceiveMessage","NotifyAssignedPerson","CheckAppStatus","CallUp"]
            //         hubMethodName:["AddPoints","closeWorkReport","ReceiveMessage","NotifyAssignedPerson","NotifyCallServiceEnd","CloseInquiryLog"]
            //     })
            //     window._globalSignalR.onSignalRData('AddPoints',(val)=>{
            //         if((this.user && this.user.isCFUser) || document.querySelector('input[name="isCFUser"]').value) {
            //             console.log('AddPoints接收成功')
            //             val.data.point = val.data.Point;
            //             setTimeout(() =>{
            //                 shortTips(`～恭喜你～<br />获得${val.data.point}积分`);
            //             }, 2000)
            //             window.UPDATE_INTEGRAL&&window.UPDATE_INTEGRAL(val.data);
            //         }
            //     })
            //     window._globalSignalR.onSignalRData('closeWorkReport',(val)=>{
            //         console.log('closeWorkReport接收成功')
            //         if(window.summaryVM){
            //             summaryVM.isShow = false;
            //         }
            //     })
            //     window._globalSignalR.onSignalRData('NotifyAssignedPerson',(val)=>{
            //         console.log('NotifyAssignedPerson接收成功')
            //         let data = {
            //             created: val.data.data.Created,
            //             customerName: val.data.data.CustomerName,
            //             distributor: val.data.data.Distributor,
            //             importCount: val.data.data.ImportCount,
            //             jobs: val.data.data.Jobs,
            //             jobsBatchCreatedType: val.data.data.JobsBatchCreatedType,
            //             recordId: val.data.data.RecordId
            //         }
            //         messageQueue.push(data);
            //     })

            //     this.$refs.globalInquiryDialog.initInquirySignalREvent();
            // }
            if(document.querySelector('input[name="firmId"]')) {
                //兼容老版的未加入公司的情况，隐藏入口
                if (document.querySelector('input[name="userIdentityType"]').value != "0") {
                    // hr只展示小秘书
                    this.helperWechatCode = '//hstatic.hirede.com/lbd/images/helper/hr-helper-wechat.jpg';
                    this.isShowIntegralGuide = false;
                    this.isShowRealtimeData = false;
                    this.isShowPhoneAssistant = false;
                    this.isShowWorkReport = false;
                    this.isShowWorkBox = false;
                    // this.isShowDailyCalendar = false;
                } else {
                    // 猎头身份
                    this.isShowWorkReport = true;
                    this.isShowWorkBox = true;
                    //用户加入团队，展示实时数据
                    if (document.querySelector('input[name="firmId"]').value) {
                        this.isShowRealtimeData = true;
                    }
                    //CF用户展示积分入口、电话助手、每日日历
                    if (document.querySelector('input[name="isCFUser"]').value) {
                        this.isShowIntegralGuide = true;
                        this.isShowPhoneAssistant = true;
                        // this.isShowDailyCalendar = true;
                    }
                }
            }

            let hasHoverTip = localStorage.getItem("hasClickSummary");
            if (hasHoverTip) {
                this.hasHoverTip = false;
            }

            if(!this.isNewProject && !localStorage.getItem('helpfulAppGuideViewed')) {
                this.forceExpand = true;
                this.$nextTick(() => {
                    let target = this.$refs['helpful-application'];
                    if(target) {
                        this.helpfulGuideTop = target.getBoundingClientRect().top;
                    }
                })
            }
        },
        methods: {
            getMyPoint() {
                let url = integral_url.my_point_info;
                _request({
                    url: url,
                    method: 'GET'
                }).then(res => {
                    this.integralCount = res.currentPoint;
                })
            },
            goToMyPoint() {
                if(window.lbdTrack){
                    lbdTrack.track('ClickGoToMyPoint', {
                        from: 'FloatOption'
                    })
                } else {
                    _tracker.track('ClickGoToMyPoint', JSON.stringify({
                        from: 'FloatOption'
                    }));
                }
                window.open('/Headhunting/MyCompany.html#/myPoint');
            },
            updateCount(count) {
                this.tipQueenArr.push(count);
                let len = this.tipQueenArr.length;
                this.updatePoint(count, (len - 1) * 3000);
            },
            updatePoint(count, time) {
                let delay = time || 0;
                setTimeout(() => {
                    this.isShowPointUpdate = true;
                    this.increaseCount = count;
                    this.isShowIntegralTip = true;
                }, delay);
                setTimeout(() => {
                    this.isShowIntegralTip = false;
                    const that = this;
                    this.$refs.countUp.update(that.integralCount + count);
                    this.integralCount += count;
                    this.tipQueenArr.splice(0, 1);
                }, delay + 2500);
            },
            showWorkReport() {
                if(window.lbdTrack){
                    lbdTrack.track('clickWorkReport', {})
                } else {
                    _tracker.track('clickWorkReport');
                }
                let noMoreBtn = false;
                if (window.location.href.indexOf("workReport") > -1) {
                    noMoreBtn = true;
                }
                localStorage.setItem("hasClickSummary", true);
                this.hasHoverTip = false;
                this.isShowDialog = true;
                this.$refs.summaryDialog.showDialog({
                    isToday: true,
                    user: this.user,
                    noMoreBtn: noMoreBtn,
                    triggerType: 2
                });
            },
            showRealtimeData() {
                $(".realtime-data:first").addClass("realtime-data-hover");
                $("#integralBall").removeClass("default-hover");
                this.isShowDialog = true;
                this.$refs.realtimedataDialog.showDialog();
            },
            downloadPhoneAssistant() {
                if(window.lbdTrack){
                    lbdTrack.track('clickPhoneAssistant', {})
                } else {
                    _tracker.track('clickPhoneAssistant');
                }
                // document.location.assign('http://dl.rchezi.com/PhoneAssistant/PhoneAssistant_Setup.exe');
                
                this.isDownloadPhoneAssistant = true;
                setTimeout(() => {
                    this.isDownloadPhoneAssistant = false;
                }, 3000);
            },
            closeRealtimedataDialog() {
                $("#integralBall").addClass("default-hover");
                $(".realtime-data:first").removeClass("realtime-data-hover");
                this.isShowDialog = false;
                this.handleToolsHide();
            },
            showSystemRecommend(){
                this.$emit('showSystemRecommend');
            },
            handleToolsHide(){
                if(!this.isNewProject || this.isShowDialog){
                    return false;
                }
                this.showTools = false;
                this.showAppDesc = false;
            },
            handleToolsShow(){
                if(!this.isNewProject){
                    return false;
                }
                this.showTools = true;
            },
            // showDailyCalendar() {
            //     if(window.lbdTrack){
            //         lbdTrack.track('clickDailyCalendar', {})
            //     } else {
            //         _tracker.track('clickDailyCalendar');
            //     }
            //     this.isShowDialog = true;
            //     this.$refs.dailyCalendarDialog.show();
            // },
            closeDialog() {
                this.isShowDialog = false;
                this.handleToolsHide();
            },
            downloadResumeHunter() {
                if(window.lbdTrack){
                    lbdTrack.track('clickResumeHunter', {})
                } else {
                    _tracker.track('clickResumeHunter');
                }
                // window.open('https://xls.bazhua.me/download');
            },
            handleShowAppDesc() {
                this.showAppDesc = true;
            },
            handleHideAppDesc() {
                this.showAppDesc = false;
            },
            handleToggleAndroidAppQrcode() {
                this.showAndroidAppQrcode = !this.showAndroidAppQrcode;
            },
            handleToggleIOSAppQrcode() {
                this.showIOSAppQrcode = !this.showIOSAppQrcode;
            },
            handleCloseHelpfulGuide() {
                this.forceExpand = false;
                this.showAppDesc = true;
                localStorage.setItem('helpfulAppGuideViewed', true);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .float-option-wrap{
        position: fixed;
        right: 0;
        top: 200px;
        z-index: 2002;
        border-radius: 4px;
        width: 64px;
        // visibility: hidden;
        &.position-top-bar{
            top: 7px;
            right: -60px;
            position: absolute;
        }
        &:hover {
            display: block !important;
        }
    }
    .float-option {
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);

        &.float-option-interval{
            margin-top: 10px;
        }

        .float-item-bottom {
            position: relative;
            animation: slideInDown 500ms linear forwards;
            overflow: hidden;

            &.force-expand {
                height: 100%!important;
                overflow: visible!important;

                .work-box {
                    visibility: hidden!important;
                }
            }
        }
        &.float-option-systemRecommend{
            display: none;
            opacity: 1;
            transition: 1s;
            &.show{
                display: block;
                animation: systemRecommendInAnimation 1s linear forwards !important;
            }

            &.hidden{
                display: block;
                animation: systemRecommendOutAnimation 1s linear forwards !important;
            }
        }
        .float-item {
            width: 64px;
            height: 64px;
            font-size: 12px;
            color: rgb(102, 102, 102);
            position: relative;
            cursor: pointer;
            background-color: rgb(248, 248, 248);

            &:hover {
                animation: fadeIn 500ms linear forwards;
                & > p {
                    color: rgb(255, 255, 255);
                    font-size: 14px;
                    font-weight: bold;
                }
                &::after {
                    display: none;
                }
            }

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 63px;
                left: 50%;
                transform: translateX(-50%);
                width: 32px;
                height: 1px;
                background-color: rgb(221, 221, 221);
            }

            & > p {
                margin: 0;
                line-height: 18px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.integral-count {
                    font-size: 16px;
                }
            }

            &.integral-ball {
                text-align: center;

                &.default-hover {
                    animation: fadeIn 500ms linear;
                    background: url("~@src/assets/images/floatOption/pic_wallet.png");
                    & > p {
                        font-size: 12px;
                        color: rgb(255, 255, 255);
                        font-weight: bold;
                    }
                    &::after {
                        display: none;
                    }
                }

                &:hover {
                    background: url("~@src/assets/images/floatOption/pic_wallet.png");

                    & > p {
                        font-size: 12px;
                    }

                    & + .float-item-bottom .float-item:nth-of-type(1)::after {
                        display: none;
                    }
                }

                span.integral-count {
                    font-size: 16px !important;
                    line-height: 19px;
                }
            }

            .integral-tip {
                position: absolute;
                right: 74px;
                bottom: 0;
                height: 30px;
                width: 88px;
                padding-top: 7px;
                border-radius: 16px;
                background: rgba(0, 0, 0, 0.4);
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: rgb(255, 255, 255);
                animation: fadeInUpDown 2500ms linear forwards;
            }

            &.daily-summary {
                .work-report {
                    .float-item-icon {
                        color: $primary;
                    }
                }
                .daily-calendar {
                    .float-item-icon {
                        color: #EE9F1B;
                    }
                }
                &:hover {
                    background: url("~@src/assets/images/floatOption/pic_report.png");
                }
            }

            &.realtime-data {
                &.realtime-data-hover {
                    background: url("~@src/assets/images/floatOption/pic_date.png");

                    & > p {
                        color: rgb(255, 255, 255);
                        font-size: 14px;
                        font-weight: bold;
                    }

                    &::after {
                        display: none;
                    }

                    & + .float-item::after {
                        display: none;
                    }
                }

                &:hover {
                    background: url("~@src/assets/images/floatOption/pic_date.png");
                }
            }

            &.helpful-application {
                display: block;

                &.actived,
                &:hover {
                    background: url("~@src/assets/images/floatOption/pic_dhzs.png");
                }
                .float-item {
                    .float-item-icon {
                        width: 22px;
                        height: 22px;
                    }
                    &.resume-hunter {
                        p {
                            white-space: nowrap;
                            text-align: center;
                            left: 66% !important;
                        }
                        .float-item-icon {
                            width: 26px;
                            height: 26px;
                            left: 13% !important;
                        }
                    }
                    .xls-icon {
                        background: url("~@src/assets/images/floatOption/icon_xls.png") no-repeat;
                        background-size: contain;
                    }
                    .phone-icon {
                        background: url("~@src/assets/images/floatOption/icon_phone.png") no-repeat;
                        background-size: contain;
                    }
                    .download-tip {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 94px;
                        height: 30px;
                        padding: 0;
                        border-radius: 16px;
                        background: rgba(0, 0, 0, 0.4);
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                        font-size: 12px;
                        line-height: 30px;
                        text-align: center;
                        color: rgb(255, 255, 255);
                        animation: fadeInUpDown 2500ms linear forwards;
                    }
                }
            }
            &.small-secretary {
                white-space: nowrap;
                display: block;
                &:hover {
                    background: url("~@src/assets/images/floatOption/pic_xms.png");
                }
                &::after {
                    display: none;
                }
                p.el-popover__reference {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    p.el-popover__reference {
                        color: rgb(255, 255, 255);
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            &.work-box {
                display: none;
                position: absolute;
                top: 0;
                border-radius: 4px;
                z-index: 1000;

                &::after {
                    display: none;
                }

                &:hover > p {
                    font-size: 12px;
                    color: rgb(102, 102, 102);
                    cursor: unset;
                }

                p {
                    white-space: nowrap;
                    text-align: center;
                }

                .float-icon-triangle {
                    width: 14px;
                    height: 14px;
                    margin-top: 6px;
                    display: inline-block;
                    background: url("~@src/assets/images/floatOption/icon-triangle.png");
                }
            }
            &.systemRecommend{
                position: relative;
                border-radius: 4px;
                text-align: center;

                &:hover p{
                    color: $primary;
                }

                &::after{
                    display: none;
                }
            }
            &.has-sub-menu {
                position: relative;
                .sub-menu-container {
                    position: absolute;
                    right: 64px;
                    top: 0;
                    visibility: hidden;
                    padding-right: 6px;
                    background-color: inherit;
                }
                .sub-menu {
                    height: 64px;
                    display: flex;
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
                    border-radius: 4px;
                    overflow: hidden;
                    .float-item {
                        width: 88px;
                        background: #fff;
                        &::after {
                            display: none;
                        }
                        &:nth-last-of-type(2){
                            &::after {
                                content: "";
                                display: inline-block;
                                position: absolute;
                                top: 50%;
                                left: auto;
                                right: 0;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 32px;
                                background-color: rgb(221, 221, 221);
                            }
                        }
                        &:hover {
                            animation: none;
                            & > p {
                                color: $primary;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                        .float-item-icon {
                            font-size: 22px;
                            position: absolute;
                            top: 50%;
                            left: 18%;
                            transform: translateY(-50%);
                        }
                        p {
                            left: 61%;
                        }
                    }
                }
                &:hover {
                    .sub-menu-container {
                        visibility: visible;
                    }
                }
            }
        }

    }

    .helpful-app-desc {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 493px;
        padding-top: 18px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
        border-right: 1px solid #eee;
        border-radius: 2px;
        z-index: 1000;

        > h3 {
            padding: 0 24px;
            line-height: 44px;

            > i {
                float: right;
                font-size: 14px;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                background-color: #f5f5f5;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        > ul {
            padding-left: 19px;

            li {
                padding: 20px 24px 15px 3px;
                border-bottom: 1px solid #eee;

                > img {
                    display: block;
                    float: left;
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                &:last-child {
                    border: 0 none;
                }
            }

            .helpful-app-desc-right {
                overflow: hidden;

                p.title {
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 3px;

                    i {
                        font-size: 12px;
                    }
                }

                p.desc {
                    font-size: 14px;
                    color: #666;
                    margin: 0;
                }
                
                a {
                    margin-top: 6px;
                    margin-right: 10px;
                    color: $primary;

                    &.ios-btn {
                        margin-left: 50px;

                        i{
                            font-size: 12px;
                            color: #666;
                        }
                    }
                }

                > img, .qrcode {
                    display: block;
                    width: 94px;
                    height: 94px;
                }
                .qrcode-box {
                    position: relative;
                    width: 100%;
                    height: 94px;
                }
                .qrcode {
                    position: absolute;
                    top: 0;
                    left: 0;

                    &.android-qrcode {
                        left: -4px;
                    }

                    &.ios-qrcode {
                        left: 120px;
                    }
                }
            }
        }
    }
    .fade-enter-active {
        transition: all .3s ease;
    }
    .fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-enter, .fade-leave-to {
        transform: translateX(493px);
        opacity: 0;
    }

    .helpful-app-guide {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &-mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
        }

        &-container {
            position: absolute;
            right: 0;
            width: 500px;
            height: 80px;
        }

        &-target {
            position: absolute;
            top: 0;
            right: 0;
            width: 64px;
            height: 64px;
            text-align: center;
            background-color: #fff;
            border-radius: 2px;

            p {
                width: 34px;
                height: 42px;
                margin-top: 11px;
                margin-left: 15px;
                background-color: #f5f5f5;
            }
        }

        img {
            position: absolute;
            top: -4px;
            right: 76px;
        }

        &-btn {
            position: absolute;
            top: 34px;
            right: 182px;
            font-size: 14px;
            color: #00ceab;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: 1433px) {
        .float-option-wrap.position-float{
            .float-option .float-item {
                &:hover {
                    animation: fadeInLight 500ms linear forwards;
                }
            }

            .float-option .float-item-bottom {
                animation: slideOutUp 500ms linear forwards;

                & > .work-box {
                    display: block;
                    animation: fadeIn 500ms linear forwards;
                }

                &:hover {
                    animation: slideInDown 500ms linear forwards;
                    height: 100%;

                    & > .work-box {
                        animation: fadeOut 500ms linear forwards;
                        z-index: 0;
                    }
                }
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeInLight {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            visibility: hidden;
        }
    }

    @keyframes fadeInUpDown {
        from {
            opacity: 0.5;
            bottom: 0;
        }
        50% {
            opacity: 1;
            bottom: 17px;
        }
        70% {
            opacity: 1;
        }
        to {
            opacity: 0;
            bottom: 34px;
        }
    }

    @keyframes slideOutUp {
        to {
            height: 64px;
        }
    }

    @keyframes slideInDown {
        from {
            height: 64px;
        }
        to {
            height: 100%;
            overflow: visible;
        }
    }

    @keyframes systemRecommendOutAnimation {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            visibility: hidden;
        }
    }

    @keyframes systemRecommendInAnimation {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
</style>

<style lang="scss">
.help-detail-popover.el-popover {
    padding: 0;
    border-radius: 8px;
    .help-detail {
        height: 190px;
        padding-top: 24px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        background-color: rgb(255, 255, 255);
        color: rgb(102, 102, 102);
        .liebide-phone {
            margin-top: 10px;
            margin-bottom: 6px;
        }
        .liebide-email {
            color: rgb(102, 102, 102);
            &:hover {
                color: rgb(52, 176, 146);
            }
        }
    }
}
.report-tip.el-tooltip__popper {
    font-size: 12px;
    color: #7c7b7c;
    padding: 10px 12px;
    word-break: keep-all;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    .popper__arrow {
        border: none;
    }
}
</style>
