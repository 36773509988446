import { customerList as customerListUrl, department as departmentUrl } from '#/js/config/api.json';

export default {
    getMyCustomerList() {
        return _request({
            method: "POST",
            url: customerListUrl.get_my_company_customers
        });
    },
    editDepartment(params) {
        return _request({
            url: departmentUrl.update_or_update_dep,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    editAndPassDepartment(params) {
        return _request({
            url: departmentUrl.update_and_pass,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    createOrUpdateDep(params) {
        return _request({
            url: departmentUrl.create_department,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    getGroupVersion(params) {
        return _request({
            url: departmentUrl.get_group_version.replace(/%p/g, params.customerId),
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
    getDepartmentBySearch(params) {
        return _request({
            url: departmentUrl.departments_by_search.replace(/%p/g, params.customerId),
            method: "GET",
            data: {
                key: params.key,
                isIncludeParent: params.isIncludeParent
            }
        })
    },
    getDepartmentBySearchBatch(params) {
        return _request({
            url: departmentUrl.departments_by_search_batch,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: {
                keys: params.keys,
                customerId: params.customerId
            }
        })
    },
    getDepartmentList(params) {
        return _request({
            url: departmentUrl.get_department_list.replace(/%p/g, params.id),
            method: "GET"
        })
    },
    getSubDepartmentList(params) {
        return _request({
            url: departmentUrl.get_sub_department_list.replace(/%p/g, params.customerId).replace(/%d/g, params.id),
            method: "GET",
            throwBusinessError: true,
            throwHttpError: true
        })
    },
    getCustomerDepartments(params) {
        return _request({
            url: departmentUrl.get_customer_departments.replace(/%p/g, params.customerId),
            method: "POST",
            data: params
        })
    },
    deleteDepartment(params) {
        return _request({
            url: departmentUrl.delete_department,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    getDepartmentDetail(params) {
        return _request({
            url: departmentUrl.get_department_detail.replace(/%p/g, params.customerId).replace(/%d/g, params.id),
            method: "GET"
        })
    },
    getDepartmentComments(params) {
        return _request({
            url: departmentUrl.get_department_comment.replace(/%p/g, params.departmentId),
            method: "GET",
            data: params
        })
    },
    addDepartmentComment(params) {
        let json = Object.assign({
            type: 3
        }, params)
        return _request({
            url: departmentUrl.add_department_comment.replace(/%p/g, params.departmentId),
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: json
        })
    },
    departmentCommentLike(params) {
        return _request({
            url: departmentUrl.department_comment_like.replace(/%p/g, params.commentId),
            method: "POST"
        })
    },
    getApplyList() {
        return _request({
            url: departmentUrl.get_apply_list,
            method: "POST",
            baseURL: "LbdOpenApi"
        })
    },
    getAuditList() {
        return _request({
            url: departmentUrl.get_audit_list,
            method: "POST",
            baseURL: "LbdOpenApi"
        })
    },
    getDepartmentApplyDetail(params) {
        return _request({
            url: departmentUrl.get_apply_detail.replace(/%p/g, params.id),
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
    auditOperate (params) {
        return _request({
            url: departmentUrl.audit_operate,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    getDepartmentTree (params) {
        return _request({
            url: departmentUrl.get_department_tree.replace(/%p/g, params.customerId),
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
    batchEditDepartment(params) {
        return _request({
            url: departmentUrl.batch_edit_department,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    }
}
