var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enableShow
    ? _c("div", { staticClass: "announcements-tip" }, [
        _c(
          "div",
          { staticClass: "announcements-tip-container" },
          [
            _vm.user.isHeadhunter
              ? [
                  _c(
                    "div",
                    { staticClass: "announcements-tip-content" },
                    [
                      !_vm.user.emailConfirmed
                        ? [
                            _vm._v(
                              "\n                    您的邮箱尚未验证，为您能及时获取职位信息，请"
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "site-bulletin-link",
                                attrs: {
                                  href: "/Headhunting/#/personalCenter?Tab=accountSet",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("点击验证 >")]
                            ),
                            _vm._v("。\n                "),
                          ]
                        : _vm.user.joiningStep === 2
                        ? [
                            _vm._v("\n                    您申请创建的"),
                            _c("span", { staticClass: "site-bulletin-firm" }, [
                              _vm._v(_vm._s(_vm.user.firmShortName)),
                            ]),
                            _vm._v(
                              "，小猎正加快给您审核，请耐心等待。\n                "
                            ),
                          ]
                        : _vm.user.joiningStep === 3
                        ? [
                            _vm._v("\n                    您正在加入"),
                            _c("span", { staticClass: "site-bulletin-firm" }, [
                              _vm._v(_vm._s(_vm.user.firmShortName)),
                            ]),
                            _vm._v(
                              "，管理员尚未通过审核，请联系管理员。\n                "
                            ),
                          ]
                        : !_vm.user.joiningStep || _vm.user.joiningStep === 0
                        ? [
                            _vm._v(
                              "\n                    您尚未创建／加入公司，完成认证后即可做单，"
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "site-bulletin-link",
                                attrs: {
                                  href: "/Firm/Search",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("点击认证 >")]
                            ),
                            _vm._v("。\n                "),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "announcements-tip-content" },
                    [
                      !_vm.user.emailConfirmed &&
                      (_vm.user.joiningStep === 3 || _vm.user.joiningStep === 2)
                        ? [
                            _vm._v(
                              "\n                    您正在" +
                                _vm._s(
                                  _vm.user.joiningStep === 3 ? "加入" : "创建的"
                                )
                            ),
                            _c("span", { staticClass: "site-bulletin-firm" }, [
                              _vm._v(_vm._s(_vm.user.firmShortName)),
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.user.joiningStep === 2 ? "正在审核中" : ""
                              ) + "，验证邮箱"
                            ),
                            _c("span", { staticClass: "site-bulletin-link" }, [
                              _vm._v(_vm._s(_vm.user.email)),
                            ]),
                            _vm._v(
                              "可以加快审核速度哦！\n                    "
                            ),
                            !_vm.isSendVerifyEmail
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "site-bulletin-action site-bulletin-email",
                                    attrs: { href: "javascript: void(0);" },
                                    on: { click: _vm.emailVerify },
                                  },
                                  [_vm._v("发送验证链接")]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass:
                                      "site-bulletin-action site-bulletin-email",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.remainSecondSend) +
                                        "s 后可重新发送"
                                    ),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                      _vm.user.emailConfirmed &&
                      (_vm.user.joiningStep === 3 || _vm.user.joiningStep === 2)
                        ? [
                            _vm._v(
                              "\n                    您正在" +
                                _vm._s(
                                  _vm.user.joiningStep === 3 ? "加入" : "创建的"
                                )
                            ),
                            _c("span", { staticClass: "site-bulletin-firm" }, [
                              _vm._v(_vm._s(_vm.user.firmShortName)),
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.user.joiningStep === 2
                                  ? "正在审核中。先去发职位吧！"
                                  : "，管理员尚未通过审核，请联系管理员。"
                              ) + "\n                "
                            ),
                          ]
                        : _vm._e(),
                      !_vm.user.emailConfirmed && _vm.user.joiningStep === 4
                        ? [
                            _vm._v("\n                    您已成功加入"),
                            _c("span", { staticClass: "site-bulletin-firm" }, [
                              _vm._v(_vm._s(_vm.user.firmShortName)),
                            ]),
                            _vm._v("，就差验证邮箱了。\n                    "),
                            !_vm.isSendVerifyEmail
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "site-bulletin-action site-bulletin-email",
                                    attrs: { href: "javascript: void(0);" },
                                    on: { click: _vm.emailVerify },
                                  },
                                  [_vm._v("发送验证链接")]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass:
                                      "site-bulletin-action site-bulletin-email",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.remainSecondSend) +
                                        "s 后可重新发送"
                                    ),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                      !_vm.user.joiningStep || _vm.user.joiningStep === 0
                        ? [
                            _vm._v(
                              "\n                    您尚未创建／加入公司，完成认证后即可做单，"
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "site-bulletin-link",
                                attrs: {
                                  href: "/Firm/Search",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("点击认证 >")]
                            ),
                            _vm._v("。\n                "),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }