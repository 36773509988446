<template>
    <div class="notice-widget" :class="isNewProject?'':'square-notice-widget'"  @click="showPopover">
        <el-popover
            popper-class="notice-popover"
            :placement="isNewProject?'bottom':'left'"
            trigger="click"
            :visible-arrow="false"
            @show="handleTip"
            v-model="popoverVisible"
            @hide="handleActiveClass"
        >
            <notice-list ref="noticeList" @handleClose="handleClose" @keepVisible="keepVisible"></notice-list>
            <!-- 新项目的提示 -->
            <div slot="reference" v-if="isNewProject">
                <font-icon href="#icon-shengyin"></font-icon>
                <span class="notice-text" v-if="noticeCount>0">您今天有<i>{{noticeCount}}</i>条事项提醒待完成，<i>点击查看</i></span>
                <span class="notice-text" v-else>事项提醒，<i>点击查看</i></span>
            </div>
            <!-- 新项目的提示end -->
            <!-- 老项目的提示 -->
            <div class="notice-widget-content" slot="reference" v-else>
                事项<br/>提醒
                <span v-if="noticeCount" class="corner-count">{{noticeCount}}</span>
            </div>
            <!-- 老项目的提示end -->
        </el-popover>
        <span v-show="showTip" class="notice-tip" :class="isNewProject?'position-bottom':'position-left'">{{isWorking?`${period}好`:'下班喽'}}，你今天有<i>{{noticeCount}}</i>个提醒事项{{isWorking?'待':'还没'}}完成~</span>
    </div>
</template>
<script>
import NoticeList from '#/component/notice/noticeList';
import {mapState,mapGetters,mapActions} from 'vuex';
import moment from 'moment';
export default {
    name:'noticeWidget',
    components:{
        NoticeList
    },
    data() {
        return {
            //noticeCount: 0,
            showTip: false,
            //popoverVisible: false,
            isNewProject: window._isNewProject,
            isWorking: true, //用于区分上下班的提示文案
            period: '早上'
        }
    },
    mounted() {
        _request({
            method: 'GET',
            url: "/Resume/Reminder/TodayNotCompleteCount"
        }).then(res => {
            //this.noticeCount = res;
            this.invokeSetNoticeCount(res);
            if(res!=0){
                this.handleShowTip();
            }
        }).catch(err => {
            console.log(err)
        });
        window.notice = this;
    },
    computed: {
        ...mapGetters('notice',{
            noticeCount: 'noticeCount',
            noticeVisible: 'noticeVisible'
        }),
        popoverVisible:{
            get(){
                return this.noticeVisible;
            },
            set(val){
                this.invokeSetNoticeVisible(val);
            }
        }
    },
    methods: {
        showPopover(){
            //this.$refs.noticeList.handleShow();
            this.$refs.noticeList.resetDate();
        },
        handleTip(){
            if(this.showTip) {
                localStorage.setItem('noticeTipDate', new Date().getTime());
                this.showTip = false;
            }
            this.$refs.noticeList.getNoticeList();
            $(".square-notice-widget").addClass("square-notice-widget-hover");
            $("#integralBall").removeClass("default-hover");
        },
        handleClose(){
            document.querySelector('.notice-popover').style.display = "none";
            $(".square-notice-widget").removeClass("square-notice-widget-hover");
            $("#integralBall").addClass("default-hover");
            document.querySelector('.site-header').style.zIndex = '';
            this.popoverVisible = false;
            //this.invokeSetNoticeVisible(false);
        },
        handleShowTip(){
            this.getPeriod();
            let nowDate = new Date();
            let hour = nowDate.getHours();
            let tempDateStamp = localStorage.getItem('noticeTipDate')*1;
            if(!tempDateStamp){
                if(hour>=9&&hour<18){
                    this.isWorking = true;
                    this.showTip = true;
                }else if(hour>=18){
                    this.isWorking = false;
                    this.showTip = true;
                }
            }else{
                if(nowDate.toDateString() === new Date(tempDateStamp).toDateString()){
                    //同一天
                    let tempHour = new Date(tempDateStamp).getHours();
                    if(hour>=9&&hour<18){
                        if(tempHour>=18){
                         this.isWorking = false;
                         this.showTip = true;
                        }
                    }
                }else{
                    if(hour>=9&&hour<18){
                        this.isWorking = true;
                        this.showTip = true;
                    }else if(hour>=18){
                        this.isWorking = false;
                        this.showTip = true;
                    }
                }
            }
        },
        keepVisible(){
            this.popoverVisible = true;
            //this.invokeSetNoticeVisible(true);
        },
        handleActiveClass(){
            if(window._isNewProject){
                return;
            }else{
                //移除float-option的active样式
                $(".square-notice-widget").removeClass("square-notice-widget-hover");
                $("#integralBall").addClass("default-hover");
            }
        },
        getPeriod(){
            let nowTime = new Date();
            let hour = nowTime.getHours();
            let period = "";
            if (hour < 12) {
                period = "早上";
            } else if(hour < 13){
                period = "中午";
            }else if (hour < 18) {
                period = "下午";
            } else {
                period = "晚上";
            }
            this.period = period;
        },
        ...mapActions('notice',[
            'invokeSetNoticeCount',
            'invokeSetNoticeVisible'
        ])
    },
}
</script>
<style lang="scss">
.notice-widget{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    i{
        font-style: none;
    }
    .font-icon{
        // color: $primary;
        color: #000;
        font-size: 20px;
        margin-right: 3px;
        vertical-align: text-bottom;
    }

    .notice-text{
        font-size: 16px;
        >i{
            color: #FF4B4B;
            padding: 0 2px;
        }
    }
    .notice-tip{
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
        position: absolute;
        background: #F2FFFC;
        color: $primary;
        padding: 12px;
        box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
        border-radius: 2px;
        white-space: nowrap;
        z-index: 999;
        cursor: text;
        >i{
            color:#FC7859;
        }
        &.position-bottom{
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            &:before {
                position: absolute;
                top: -8px;
                left: 50%;
                margin-left: -4px;
                display: block;
                content: '';
                border-bottom: 8px solid #F2FFFC;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
            }
            &:after {
                position: absolute;
                top: -6px;
                left: 50%;
                margin-left: -4px;
                display: block;
                content: '';
                border-bottom: 8px solid #F2FFFC;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
            }
        }
        &.position-left{
            top: 50%;
            right: 80px;
            transform: translateY(-50%);
            &:before {
                position: absolute;
                right: -8px;
                top: 50%;
                margin-top: -6px;
                display: block;
                content: '';
                border-left: 8px solid #F2FFFC;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
            &:after {
                position: absolute;
                right: -6px;
                top: 50%;
                margin-top: -6px;
                display: block;
                content: '';
                border-left: 8px solid #F2FFFC;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
    }

    &.square-notice-widget{
        //旧项目的提醒
        position: relative;
        width: 64px;
        height: 64px;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 18px;
        font-size: 12px;
        &:hover,&-hover{
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            background: url("~@src/assets/images/floatOption/pic_notice.png");
            .notice-widget-content{
                .corner-count{
                    left: calc(100% - 10px);
                }
            }

        }
        .notice-widget-content{
            position: relative;
            .corner-count{
                font-size: 12px;
                font-weight: normal;
                position: absolute;
                display: inline-block;
                background: #FC7859;
                color: #fff;
                border-radius: 9px;
                line-height: 1;
                padding: 3px 6px;
                left: calc(100% - 6px);
                top: -8px;
                transform: scale(.8);
            }
        }

    }
}
.notice-popover{
    /* position: absolute;
    top:calc(100% + 10px);
    left: 0; */
    padding: 0 !important;
    margin-right: 30px !important;
}
</style>


