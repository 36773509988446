const state = {
    recommendPosition: ''
}

const getters = {

}

const actions = {
    setRecommendPosition({ commit, state }, recommendPosition) {
        commit('updateRecommendPosition', { recommendPosition: recommendPosition });
    }
}

const mutations = {
    updateRecommendPosition(state, { recommendPosition }) {
        state.recommendPosition = recommendPosition;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
