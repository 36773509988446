
const focus = {
    // 当被绑定的元素插入到 DOM 中时
    inserted: function (el) {
        // 聚焦元素
        if(['INPUT', 'SELECT', 'CHECKBOX', 'RADIO'].includes(el.tagName)) {
            el.focus();
        } else {
            const subEl = el.getElementsByTagName('INPUT')[0] ||
                el.getElementsByTagName('SELECT')[0] ||
                el.getElementsByTagName('CHECKBOX')[0] ||
                el.getElementsByTagName('RADIO')[0] || null;
            subEl?.focus();
        }
    }
};
export default focus;

