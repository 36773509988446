var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.isSearchEnd,
          expression: "!isSearchEnd",
        },
      ],
      staticClass: "search-result",
    },
    [
      _vm.isSearchEnd && !_vm.empty
        ? _c("ul", { staticClass: "search-result-list" }, [
            _vm.searchResultObject.innerMemberInfos &&
            _vm.searchResultObject.innerMemberInfos.length > 0
              ? _c("li", [
                  _c(
                    "dl",
                    [
                      _c("dt", [_vm._v("公司同事")]),
                      _vm._l(
                        _vm.searchResultObject.innerMemberInfos,
                        function (member, index) {
                          return _c(
                            "dd",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.isSingle ||
                                    index <= 1 ||
                                    (index >= 2 && _vm.innerMemberViewAll),
                                  expression:
                                    "isSingle || index <= 1 || (index >= 2 && innerMemberViewAll)",
                                },
                              ],
                              key: member.unionId,
                              on: {
                                click: function ($event) {
                                  return _vm.handleJumpConversation(
                                    "c2c",
                                    member.unionId
                                  )
                                },
                              },
                            },
                            [
                              _vm.isShare
                                ? _c("member-item", {
                                    attrs: {
                                      member: member,
                                      isShare: true,
                                      type: "innerMemberList",
                                      disabled: _vm.checkMemberDisabled(
                                        member.unionId
                                      ),
                                      value: _vm.checkMemberSelected(
                                        member.unionId
                                      ),
                                    },
                                    on: {
                                      "member-select": _vm.handleMemberSelect,
                                    },
                                  })
                                : [
                                    _c("img", {
                                      attrs: {
                                        src: `${_vm.avatarBaseUrl}/user/${member.unionId}`,
                                      },
                                    }),
                                    _c("div", { staticClass: "text-info" }, [
                                      _c("p", { staticClass: "primary" }, [
                                        _vm._v(_vm._s(member.userName)),
                                      ]),
                                      _c("p", { staticClass: "normal" }, [
                                        _vm._v(
                                          _vm._s(member.company) +
                                            " | " +
                                            _vm._s(member.title)
                                        ),
                                      ]),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  !_vm.isSingle &&
                  _vm.searchResultObject.innerMemberInfos.length > 2 &&
                  _vm.innerMemberViewAll == false
                    ? _c(
                        "p",
                        {
                          staticClass: "view-more",
                          on: {
                            click: function ($event) {
                              _vm.innerMemberViewAll = true
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.filteredGroupInfos.length > 0
              ? _c("li", [
                  _c(
                    "dl",
                    [
                      _c("dt", [_vm._v("群聊")]),
                      _vm._l(_vm.filteredGroupInfos, function (group, index) {
                        return _c(
                          "dd",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.isSingle ||
                                  index <= 1 ||
                                  (index >= 2 && _vm.groupViewAll),
                                expression:
                                  "isSingle || index <= 1 || (index >= 2 && groupViewAll)",
                              },
                            ],
                            key: group.groupId,
                            on: {
                              click: function ($event) {
                                return _vm.handleJumpConversation(
                                  "group",
                                  group.groupId
                                )
                              },
                            },
                          },
                          [
                            _vm.isShare
                              ? _c("member-item", {
                                  attrs: {
                                    member: group,
                                    isGroup: true,
                                    isShare: true,
                                    type: "groupList",
                                    disabled: _vm.checkMemberDisabled(
                                      group.groupId
                                    ),
                                    value: _vm.checkMemberSelected(
                                      group.groupId
                                    ),
                                  },
                                  on: {
                                    "member-select": _vm.handleMemberSelect,
                                  },
                                })
                              : _vm._e(),
                            !_vm.isShare
                              ? [
                                  _c("img", {
                                    attrs: {
                                      src: `${_vm.avatarBaseUrl}/group/${group.groupId}`,
                                    },
                                  }),
                                  _c("div", { staticClass: "text-info" }, [
                                    _c("p", { staticClass: "primary" }, [
                                      _vm._v(_vm._s(group.groupName)),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                  !_vm.isSingle &&
                  _vm.filteredGroupInfos.length > 2 &&
                  _vm.groupViewAll == false
                    ? _c(
                        "p",
                        {
                          staticClass: "view-more",
                          on: {
                            click: function ($event) {
                              _vm.groupViewAll = true
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            !_vm.isShare &&
            _vm.messageInfo &&
            _vm.messageInfo.data &&
            _vm.messageInfo.data.length > 0
              ? _c("li", [
                  _c(
                    "dl",
                    [
                      _c("dt", [_vm._v("聊天记录")]),
                      _vm._l(_vm.messageInfo.data, function (message, index) {
                        return _c(
                          "dd",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.isSingle ||
                                  index <= 1 ||
                                  (index >= 2 && _vm.messageViewAll),
                                expression:
                                  "isSingle || index <= 1 || (index >= 2 && messageViewAll)",
                              },
                            ],
                            key: message.id,
                            on: {
                              click: function ($event) {
                                return _vm.handleShowRecordList(message)
                              },
                            },
                          },
                          [
                            message.type == 0
                              ? _c("img", {
                                  attrs: {
                                    src: `${_vm.avatarBaseUrl}/user/${message.id}`,
                                  },
                                })
                              : _vm._e(),
                            message.type == 1
                              ? _c("img", {
                                  attrs: {
                                    src: `${_vm.avatarBaseUrl}/group/${message.id}`,
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "text-info" }, [
                              _c("p", { staticClass: "primary" }, [
                                _vm._v(_vm._s(message.name)),
                              ]),
                              _c("p", { staticClass: "normal" }, [
                                _vm._v(
                                  _vm._s(message.count) + "条相关的聊天记录"
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  !_vm.isSingle &&
                  _vm.messageInfo.data &&
                  _vm.messageInfo.data.length > 2 &&
                  _vm.messageViewAll == false
                    ? _c(
                        "p",
                        {
                          staticClass: "view-more",
                          on: {
                            click: function ($event) {
                              _vm.messageViewAll = true
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.canShowOuterMembers &&
            _vm.searchResultObject.outerMemberInfos &&
            _vm.searchResultObject.outerMemberInfos.length > 0
              ? _c("li", [
                  _c(
                    "dl",
                    [
                      _c("dt", [_vm._v("外部公司")]),
                      _vm._l(
                        _vm.searchResultObject.outerMemberInfos,
                        function (member, index) {
                          return _c(
                            "dd",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.isSingle ||
                                    index <= 1 ||
                                    (index >= 2 && _vm.outerMemberViewAll),
                                  expression:
                                    "isSingle || index <= 1 || (index >= 2 && outerMemberViewAll)",
                                },
                              ],
                              key: member.unionId,
                              on: {
                                click: function ($event) {
                                  return _vm.handleJumpConversation(
                                    "c2c",
                                    member.unionId
                                  )
                                },
                              },
                            },
                            [
                              _vm.isShare
                                ? _c("member-item", {
                                    attrs: {
                                      member: member,
                                      isShare: true,
                                      type: "outerMemberList",
                                      disabled: _vm.checkMemberDisabled(
                                        member.unionId
                                      ),
                                      value: _vm.checkMemberSelected(
                                        member.unionId
                                      ),
                                    },
                                    on: {
                                      "member-select": _vm.handleMemberSelect,
                                    },
                                  })
                                : [
                                    _c("img", {
                                      attrs: {
                                        src: `${_vm.avatarBaseUrl}/user/${member.unionId}`,
                                      },
                                    }),
                                    _c("div", { staticClass: "text-info" }, [
                                      _c("p", { staticClass: "primary" }, [
                                        _vm._v(_vm._s(member.userName)),
                                      ]),
                                      _c("p", { staticClass: "normal" }, [
                                        _vm._v(
                                          _vm._s(member.company) +
                                            " | " +
                                            _vm._s(member.title)
                                        ),
                                      ]),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  !_vm.isSingle &&
                  _vm.searchResultObject.outerMemberInfos.length > 2 &&
                  _vm.outerMemberViewAll == false
                    ? _c(
                        "p",
                        {
                          staticClass: "view-more",
                          on: {
                            click: function ($event) {
                              _vm.outerMemberViewAll = true
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.searchResultObject.jdtMemberInfos &&
            _vm.searchResultObject.jdtMemberInfos.length > 0
              ? _c("li", [
                  _c(
                    "dl",
                    [
                      _c("dt", [_vm._v("职通车候选人")]),
                      _vm._l(
                        _vm.searchResultObject.jdtMemberInfos,
                        function (member, index) {
                          return _c(
                            "dd",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.isSingle ||
                                    index <= 1 ||
                                    (index >= 2 && _vm.jdtMemberViewAll),
                                  expression:
                                    "isSingle || index <= 1 || (index >= 2 && jdtMemberViewAll)",
                                },
                              ],
                              key: member.unionId,
                              on: {
                                click: function ($event) {
                                  return _vm.handleJumpConversation(
                                    "c2c",
                                    member.unionId
                                  )
                                },
                              },
                            },
                            [
                              _vm.isShare
                                ? _c("member-item", {
                                    attrs: {
                                      member: member,
                                      isShare: true,
                                      type: "outerMemberList",
                                      disabled: _vm.checkMemberDisabled(
                                        member.unionId
                                      ),
                                      value: _vm.checkMemberSelected(
                                        member.unionId
                                      ),
                                    },
                                    on: {
                                      "member-select": _vm.handleMemberSelect,
                                    },
                                  })
                                : [
                                    _c("img", {
                                      attrs: {
                                        src: `${_vm.avatarBaseUrl}/user/${member.unionId}`,
                                      },
                                    }),
                                    _c("div", { staticClass: "text-info" }, [
                                      _c("p", { staticClass: "primary" }, [
                                        _vm._v(_vm._s(member.userName)),
                                      ]),
                                      _c("p", { staticClass: "normal" }, [
                                        _vm._v(
                                          _vm._s(member.company) +
                                            " | " +
                                            _vm._s(member.title)
                                        ),
                                      ]),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  !_vm.isSingle &&
                  _vm.searchResultObject.jdtMemberInfos.length > 2 &&
                  _vm.jdtMemberViewAll == false
                    ? _c(
                        "p",
                        {
                          staticClass: "view-more",
                          on: {
                            click: function ($event) {
                              _vm.jdtMemberViewAll = true
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.isSearchEnd && _vm.empty
        ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showRecordList
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.recordListLoading,
                    expression: "recordListLoading",
                  },
                ],
                staticClass: "chat-record-list",
              },
              [
                _c("p", { staticClass: "chat-record-list-title" }, [
                  _c("i", [
                    _vm._v("聊天记录-" + _vm._s(_vm.recordConversationName)),
                  ]),
                  _c("span", { on: { click: _vm.handleHideRecordList } }, [
                    _vm._v("返回"),
                  ]),
                ]),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.getRecordList,
                        expression: "getRecordList",
                      },
                    ],
                    attrs: {
                      "infinite-scroll-immediate": false,
                      "infinite-scroll-disabled":
                        !_vm.recordListFetched ||
                        _vm.recordListPageLoading ||
                        _vm.recordListNoMore,
                    },
                  },
                  [
                    _vm._l(_vm.recordList, function (record, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.handleJumpMessage(record)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: `${_vm.avatarBaseUrl}/user/${record.fromAccountId}`,
                            },
                          }),
                          _c("div", { staticClass: "text-info" }, [
                            _c("p", { staticClass: "primary" }, [
                              _vm._v(_vm._s(record.userName)),
                            ]),
                            _c("p", { staticClass: "normal" }, [
                              _vm._v(_vm._s(record.content)),
                            ]),
                          ]),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(_vm._s(_vm._f("getDate")(record.msgTime))),
                          ]),
                        ]
                      )
                    }),
                    _vm.recordListFetched && _vm.recordListPageLoading
                      ? _c("p", { staticClass: "loading-info" }, [
                          _vm._v("加载中..."),
                        ])
                      : _vm._e(),
                    _vm.recordListFetched && _vm.recordListNoMore
                      ? _c("p", { staticClass: "loading-info" }, [
                          _vm._v("没有更多了"),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }