<template>
  <div class="group-tip-element-wrapper">{{text}}</div>
</template>

<script>
export default {
  name: 'GroupTipElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    groupMembers: {
      type: Array,
      default: []
    },
    deleteMemberNick: String
  },
  computed: {
    text() {
      if(this.message.msgType === TIM.TYPES.MSG_CUSTOM && this.message.msgContent.data === 'group_create') {
        return `${this.message.fromAccountName} 发起了群聊`
      }
      if(this.message.fromAccountId === 'openIM123456' || this.message.fromAccountId === 'baza_im_admin') {
        return this.message.msgContent.text;
      }
      return this.getGroupTipContent(this.payload)
    }
  },
  methods: {
    getGroupTipContent(payload) {
      let nameList = [],
          operator = {
            nick: ""
          };
      operator = this.groupMembers.find(member => member.userID === payload.operatorID);
      if(payload.userIDList && payload.userIDList.length > 0) {
        let user = {};
        payload.userIDList.forEach(userID => {
          let user = this.groupMembers.find(member => member.userID === userID);
          if(user) {
            nameList.push(user.nick);
          }
        })
      }
      switch (payload.operationType) {
        case TIM.TYPES.GRP_TIP_MBR_JOIN:
          return nameList.length ? `${operator.nick} 邀请 ${nameList.join(',')} 加入群聊` : ` ${operator.nick} 邀请新成员入群`
        case TIM.TYPES.GRP_TIP_MBR_QUIT:
          return nameList.length ? `${nameList.join(',')} 退出了群聊` : `有成员退出了群聊`
        case TIM.TYPES.GRP_TIP_MBR_KICKED_OUT:
          return this.deleteMemberNick ? `${operator.nick} 将 ${this.deleteMemberNick} 移出群聊` : (nameList.length ? `${operator.nick} 将 ${nameList.join(',')} 移出群聊` : `${operator.nick} 将成员移出群聊`)
        case TIM.TYPES.GRP_TIP_MBR_SET_ADMIN:
          return nameList.length ? `${operator.nick} 将 ${nameList.join(',')} 设为管理员` : `${operator.nick} 将成员设为管理员`
        case TIM.TYPES.GRP_TIP_MBR_CANCELED_ADMIN:
          return nameList.length ? `${operator.nick} 将 ${nameList.join(',')} 管理员撤销` : `${operator.nick} 将管理员撤销`
        case TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED:
          let profileUpdateText = [];
          if(payload.newGroupProfile.name) {
            profileUpdateText.push(`修改群名称为：“${payload.newGroupProfile.name}”`)
          }
          if(payload.newGroupProfile.introduction) {
            profileUpdateText.push(`修改群简介为：“${payload.newGroupProfile.introduction}”`)
          }
          if(payload.newGroupProfile.notification) {
            profileUpdateText.push(`修改群公告为：“${payload.newGroupProfile.notification}”`)
          }
          if(payload.newGroupProfile.ownerID) {
            let newOwner = this.groupMembers.find(member => member.userID === payload.newGroupProfile.ownerID)
            profileUpdateText.push(`修改群主为：“${newOwner.nick}”`)
          }
          return `${operator.nick} ${profileUpdateText.join(`\n${operator.nick} `)}`
        case TIM.TYPES.GRP_TIP_MBR_PROFILE_UPDATED:
          return '群成员资料修改'
        default:
          return '[群提示消息]'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group-tip-element-wrapper {
  background: #f6f6f6;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
}
</style>
