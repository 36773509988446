var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-wrapper", class: _vm.messagePosition },
    [
      !_vm.isSystem && _vm.currentConversationType === _vm.TIM.TYPES.CONV_C2C
        ? _c("div", { staticClass: "c2c-layout", class: _vm.messagePosition }, [
            _vm.showAvatar
              ? _c(
                  "div",
                  { staticClass: "avatar-container" },
                  [_c("avatar", { attrs: { src: _vm.avatar } })],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _vm.showMessageHeader
                  ? _c("message-header", {
                      attrs: {
                        message: _vm.message,
                        "current-conversation": _vm.currentConversation,
                        "current-user-profile": _vm.currentUserProfile,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "content-wrapper" },
                  [
                    _vm.isMine && _vm.message.status !== "unSend"
                      ? _c("message-status-icon", {
                          attrs: { message: _vm.message },
                        })
                      : _vm._e(),
                    _vm.message.msgType === _vm.TIM.TYPES.MSG_TEXT
                      ? _c("text-element", {
                          attrs: {
                            isMine: _vm.isMine,
                            payload: _vm.message.msgContent,
                            message: _vm.message,
                            groupId:
                              _vm.currentConversationType ===
                              _vm.TIM.TYPES.CONV_GROUP
                                ? _vm.currentConversation.groupProfile.groupID
                                : "",
                          },
                        })
                      : _vm.message.msgType === _vm.TIM.TYPES.MSG_IMAGE
                      ? _c("image-element", {
                          attrs: {
                            isMine: _vm.isMine,
                            payload: _vm.message.msgContent,
                            message: _vm.message,
                            groupId:
                              _vm.currentConversationType ===
                              _vm.TIM.TYPES.CONV_GROUP
                                ? _vm.currentConversation.groupProfile.groupID
                                : "",
                          },
                        })
                      : _vm.message.msgType === _vm.TIM.TYPES.MSG_FILE
                      ? _c("file-element", {
                          attrs: {
                            isMine: _vm.isMine,
                            payload: _vm.message.msgContent,
                            message: _vm.message,
                            groupId:
                              _vm.currentConversationType ===
                              _vm.TIM.TYPES.CONV_GROUP
                                ? _vm.currentConversation.groupProfile.groupID
                                : "",
                          },
                        })
                      : _vm.message.msgType === _vm.TIM.TYPES.MSG_SOUND
                      ? _c("sound-element", {
                          attrs: {
                            isMine: _vm.isMine,
                            payload: _vm.message.msgContent,
                            message: _vm.message,
                          },
                        })
                      : _vm.message.msgType === _vm.TIM.TYPES.MSG_CUSTOM
                      ? _c("custom-element", {
                          attrs: {
                            isMine: _vm.isMine,
                            payload: _vm.message.msgContent,
                            message: _vm.message,
                            isInner: _vm.isInner,
                            groupId:
                              _vm.currentConversationType ===
                              _vm.TIM.TYPES.CONV_GROUP
                                ? _vm.currentConversation.groupProfile.groupID
                                : "",
                            currentConversation: _vm.currentConversation,
                          },
                        })
                      : _vm.message.msgType === _vm.TIM.TYPES.MSG_VIDEO
                      ? _c(
                          "span",
                          { staticStyle: { padding: "5px 0 0 8px" } },
                          [_vm._v("视频消息暂不支持在网页查看，请到app打开")]
                        )
                      : _c("span", [
                          _vm._v(
                            "暂未支持的消息类型：" + _vm._s(_vm.message.msgType)
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-3" }),
          ])
        : _vm._e(),
      !_vm.isSystem && _vm.currentConversationType === _vm.TIM.TYPES.CONV_GROUP
        ? _c(
            "div",
            { staticClass: "group-layout", class: _vm.messagePosition },
            [
              _vm.showAvatar
                ? _c(
                    "div",
                    { staticClass: "avatar-container" },
                    [
                      _c("avatar", {
                        staticClass: "group-member-avatar",
                        style:
                          _vm.message.fromAccountId === _vm.userId
                            ? "cursor: default"
                            : "",
                        attrs: { src: _vm.avatar },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showGroupMemberProfile.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "col-2" },
                [
                  _vm.showMessageHeader
                    ? _c("message-header", {
                        attrs: {
                          message: _vm.message,
                          "current-conversation": _vm.currentConversation,
                          "current-user-profile": _vm.currentUserProfile,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "content-wrapper" },
                    [
                      _vm.isMine && _vm.message.status !== "unSend"
                        ? _c("message-status-icon", {
                            attrs: { message: _vm.message },
                          })
                        : _vm._e(),
                      _vm.message.msgType === _vm.TIM.TYPES.MSG_TEXT &&
                      _vm.message.fromAccountId !== "openIM123456" &&
                      _vm.message.fromAccountId !== "baza_im_admin"
                        ? _c("text-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              payload: _vm.message.msgContent,
                              message: _vm.message,
                              groupId:
                                _vm.currentConversationType ===
                                _vm.TIM.TYPES.CONV_GROUP
                                  ? _vm.currentConversation.groupProfile.groupID
                                  : "",
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_IMAGE
                        ? _c("image-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              payload: _vm.message.msgContent,
                              message: _vm.message,
                              isWorkBenchMain: _vm.isWorkBenchMain,
                              groupId:
                                _vm.currentConversationType ===
                                _vm.TIM.TYPES.CONV_GROUP
                                  ? _vm.currentConversation.groupProfile.groupID
                                  : "",
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_FILE
                        ? _c("file-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              payload: _vm.message.msgContent,
                              message: _vm.message,
                              groupId:
                                _vm.currentConversationType ===
                                _vm.TIM.TYPES.CONV_GROUP
                                  ? _vm.currentConversation.groupProfile.groupID
                                  : "",
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_SOUND
                        ? _c("sound-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              payload: _vm.message.msgContent,
                              message: _vm.message,
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_GRP_TIP ||
                          (_vm.message.msgType === _vm.TIM.TYPES.MSG_CUSTOM &&
                            _vm.message.msgContent.data === "group_create") ||
                          ((_vm.message.fromAccountId === "openIM123456" ||
                            _vm.message.fromAccountId === "baza_im_admin") &&
                            _vm.message.msgType !== _vm.TIM.TYPES.MSG_IMAGE)
                        ? _c("group-tip-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              message: _vm.message,
                              deleteMemberNick: _vm.deleteMemberNick,
                              groupMembers: _vm.groupMembers,
                              payload: _vm.message.msgContent,
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_CUSTOM
                        ? _c("custom-element", {
                            attrs: {
                              isMine: _vm.isMine,
                              isInner: _vm.isInner,
                              payload: _vm.message.msgContent,
                              message: _vm.message,
                              groupId:
                                _vm.currentConversationType ===
                                _vm.TIM.TYPES.CONV_GROUP
                                  ? _vm.currentConversation.groupProfile.groupID
                                  : "",
                              currentConversation: _vm.currentConversation,
                            },
                          })
                        : _vm.message.msgType === _vm.TIM.TYPES.MSG_VIDEO
                        ? _c("span", [
                            _vm._v("该消息暂不支持在网页查看，请到app打开"),
                          ])
                        : _c("span", [
                            _vm._v(
                              "暂未支持的消息类型：" +
                                _vm._s(_vm.message.msgType)
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.isSystem
        ? _c("div", { staticClass: "system-layout" }, [
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("div", { staticClass: "date" }, [
                  _vm._v(_vm._s(_vm._f("fullDate")(_vm.message.msgTime))),
                ]),
                _c("system-notice", {
                  attrs: {
                    payload: _vm.message.msgContent,
                    message: _vm.message,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }