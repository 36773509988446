var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "message-bubble",
    {
      attrs: {
        isMine: _vm.isMine,
        message: _vm.message,
        groupId: _vm.groupId,
        customClass: _vm.customClass,
      },
    },
    [
      _c(
        "div",
        { staticClass: "custom-element-wrapper" },
        [
          _vm.payload.data === "survey"
            ? _c(
                "div",
                { staticClass: "survey" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("对IM DEMO的评分和建议"),
                  ]),
                  _c("el-rate", {
                    attrs: {
                      disabled: "",
                      "show-score": "",
                      "text-color": "#ff9900",
                      "score-template": "{value}",
                    },
                    model: {
                      value: _vm.rate,
                      callback: function ($$v) {
                        _vm.rate = $$v
                      },
                      expression: "rate",
                    },
                  }),
                  _c("div", { staticClass: "suggestion" }, [
                    _vm._v(_vm._s(_vm.payload.ext)),
                  ]),
                ],
                1
              )
            : !_vm.payload.data &&
              _vm.payload.ext &&
              _vm.payload.ext.type.indexOf("Share") > -1
            ? _c("share-message", {
                attrs: {
                  message: _vm.message,
                  isInner: _vm.isInner,
                  currentConversation: _vm.currentConversation,
                },
              })
            : !_vm.payload.data &&
              _vm.payload.ext &&
              _vm.payload.ext.type === "atMessage"
            ? _c("span", {
                staticClass: "at-message",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.translateAtMessage(_vm.payload.ext.data.message)
                  ),
                },
              })
            : !_vm.payload.data &&
              _vm.payload.ext &&
              _vm.payload.ext.type === "visibleLink"
            ? _c("span", { staticClass: "common-template-message" }, [
                _vm.payload.ext.data.title
                  ? _c("i", { staticClass: "common-template-message-title" }, [
                      _vm._v(_vm._s(_vm.payload.ext.data.title)),
                    ])
                  : _vm._e(),
                _vm.payload.ext.data.content
                  ? _c(
                      "i",
                      { staticClass: "common-template-message-content" },
                      [_vm._v(_vm._s(_vm.payload.ext.data.content))]
                    )
                  : _vm._e(),
                _vm.payload.ext.data.button &&
                _vm.payload.ext.data.url &&
                _vm.payload.ext.data.url.indexOf(
                  "departmentCreateRequestDeny"
                ) == -1
                  ? _c(
                      "a",
                      {
                        staticClass: "common-template-message-link",
                        class: { "disable-link": _vm.isDisableLink },
                        attrs: {
                          target: "_blank",
                          href: _vm.payload.ext.data.url,
                        },
                      },
                      [_vm._v(_vm._s(_vm.payload.ext.data.button))]
                    )
                  : _vm._e(),
                _vm.payload.ext.data.button &&
                _vm.payload.ext.data.url &&
                _vm.payload.ext.data.url.indexOf(
                  "departmentCreateRequestDeny"
                ) > -1
                  ? _c(
                      "a",
                      {
                        staticClass: "common-template-message-link",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.showAuditDenyDetail(
                              _vm.payload.ext.data.url
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.payload.ext.data.button))]
                    )
                  : _vm._e(),
              ])
            : _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }