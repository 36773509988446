const state = {
    resumeId: ''
}

const getters = {

}

const actions = {
    setLastCallResumeId({ commit, state }, resumeId) {
        commit('setLastCallResumeId', { resumeId: resumeId });
    }
}

const mutations = {
    setLastCallResumeId(state, { resumeId }) {
        state.resumeId = resumeId;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
