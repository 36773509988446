<template>
  <div class="instant-message" :class="{'is-home': isHomePage}">
    <!-- <div
        class="instant-message-btn-container"
        v-if="!isNewProject && !isHomePage"
        v-show="!isShow"
        @click="showInstantMessage({isClickShow: true})">
        <div
            ref="instantMessageBtn"
            class="instant-message-btn"
            :class="{'active': messageNum > 0}">
            <i class="im-btn-icon"></i>
            消息
            <template v-if="messageNum">
                <span class="im-num">{{messageNum > 99 ? '···' : messageNum}}</span>
            </template>
        </div>
    </div> -->
    <div
        class="instant-message-container"
        v-show="isHomePage || isShow"
        :class="{'large-size': isLargeSize, 'in-new-project': isNewProject, 'is-home-page': isHomePage}">
        <side-bar
            ref="sideBar"
            :conversationList="conversationList"
            :isLargeSize="isLargeSize"
            :userId="userId"
            :onlineUserList="onlineUserList"
            :innerLabelMap="innerLabelMap"
            :companyGroupId="companyGroupId"
            :currentConversationID="currentConversation.conversationID"
            :atMeConversationList="atMeConversationList"
            @closeInstantMessage="closeInstantMessage"
            @updateCurrentConversation="updateCurrentConversation"
            @updateMessagePosition="updateMessagePosition"
            @updateAtMeData="updateAtMeData"
        ></side-bar>
        <current-conversation
            ref="current-conversation"
            :currentConversation="currentConversation"
            :isLargeSize="isLargeSize"
            :currentMessageList="currentMessageList"
            :currentMessageListFetched="currentMessageListFetched"
            :currentUserProfile="currentUserProfile"
            :isCompleted="isCompleted"
            :isAfterCompleted="isAfterCompleted"
            :memberList="memberList"
            :userId="userId"
            :userProfileMap="c2cUserProfileMap"
            :isInner="checkInner(currentConversation)"
            :messageLoading="messageLoading"
            :messagePagingLoading="messagePagingLoading"
            :isPositionMode="isPositionMode"
            :flipOverType="flipOverType"
            :companyGroupId="companyGroupId"
            @quitGroup="quitGroup"
            @enLargeDialog="enLargeDialog"
            @closeInstantMessage="closeInstantMessage"
            @getMore="getMoreMessageList"
            @updateMessageList="updateMessageList"
            @updateCurrentConversation="updateCurrentConversation"
        ></current-conversation>
    </div>
    <image-previewer ref="image-previewer" :imgUrlList="imgUrlList" />
    <share-to-im-dialog 
        ref="share-to-im-dialog" 
        :conversationList="conversationList"
        :userProfileMap="c2cUserProfileMap"
        :firmId="firmId" 
        :innerLabelMap="innerLabelMap"
    />
    <audit-deny-detail-dialog ref="auditDenyDetailDialog"></audit-deny-detail-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
// import tim from './tim.js';
import TIM from 'tim-js-sdk';
// window.tim = tim;
window.TIM = TIM;
// Vue.prototype.tim = tim;
Vue.prototype.TIM = TIM;

import openIM from './open-im.js';
window.openIM = openIM;
Vue.prototype.openIM = openIM;

import './assets/icon/iconfont.css';
import './assets/icon/tim.css';

import SideBar from './side-bar.vue';
import CurrentConversation from './conversation/current-conversation.vue';
import ImagePreviewer from './message/image-previewer.vue';
import ShareToImDialog from './share/share-to-im-dialog.vue';
import AuditDenyDetailDialog from '#/component/common/dialog/audit-deny-detail-dialog.vue';
import emitter from '@src/js/mixins/emitter';
import imService from '#/js/service/openIMService.js';
import { convertSdkMsgToLbdMsg, convertConversation, convertMessage } from './utils/convertIM.js';
import { avatarHost } from './utils/avatarHost.js';

import { conversitionList as mockConversitionList } from './open-im-mock.js';

export default {
    componentName: 'IMBaseApp',
    mixins: [emitter],
    props: {
        isNewProject: Boolean,
        isHomePage: Boolean
    },
    components: {
        SideBar,
        CurrentConversation,
        ImagePreviewer,
        ShareToImDialog,
        AuditDenyDetailDialog
    },
    data() {
        return {
            pageTabVisible: document.visibilityState == 'visible' ? true : false, //页签是否处于激活状态
            isLogin: false,
            userSig: {},
            isShow: false,
            isLargeSize: false,
            conversationList: [],
            memberList: [],
            atMeConversationList: [],
            isCompleted: false,
            isAfterCompleted: true,
            currentConversation: {},
            currentUserProfile: {},
            currentMessageList: [],
            currentMessageListFetched: false,
            nextReqMessageID: '', //群聊向上翻页
            nextReqMessageTime: '', //单聊向上翻页
            nextAfterReqMessageID: '', //群聊向下翻页
            nextAfterReqMessageTime: '', //单聊向下翻页
            acceptNotNotifyList: [],
            innerLabelMap: {},
            isShare: false,
            shareMessage: {},
            messageLoading: false,
            messagePagingLoading: false,
            isPositionMode: false, //是否为搜索消息定位模式
            flipOverType: 0, //0：向上翻页。1：向下翻页
            companyGroupId: "",
            c2cUserProfileMap: {},
            onlineUserList: [],
            imCanRetryFlag: true
        }
    },
    computed: {
        messageNum() {
            let number = 0;
            this.conversationList.forEach(item => {
                if(item.unreadCount > 0 && ((this.currentConversation.type && item.conversationID !== this.currentConversation.conversationID) || !this.currentConversation.type)) {
                    if(!(item.type === TIM.TYPES.CONV_GROUP && item.groupProfile.selfInfo.messageRemindType === 'AcceptNotNotify')) {
                        number += item.unreadCount
                    }
                }
            })
            if(number > 0 && !this.isNewProject) {
                this.$refs.instantMessageBtn && this.$refs.instantMessageBtn.classList.remove('active');
                this.$refs.instantMessageBtn && this.$refs.instantMessageBtn.classList.add('active');
            }
            return number
        },
        userId() {
            // return document.querySelector('input[name="userUnionId"]') ? document.querySelector('input[name="userUnionId"]').value : this.$store.state.user.userInfo.unionId;
            return this.$store.state.user.userInfo.unionId;
        },
        firmId() {
            return document.querySelector('input[name="firmId"]') ? document.querySelector('input[name="firmId"]').value : this.$store.state.user.userInfo.firmId;
        },
        imgUrlList() {
            return this.currentMessageList
                .filter(message => message.msgType === TIM.TYPES.MSG_IMAGE && !message.isRevoked) // 筛选出没有撤回并且类型是图片类型的消息
                .map(message => message.msgContent.imageInfoArray[0].url)
        },
        isWorkbenchMain() {
            return /\/Headhunting(\/#)?\/$/g.test(location.href);
        },
        firmGroupId() {
            return this.$store.state.user.userInfo.tbdFirmId;
        }
    },
    watch: {
        messageNum: {
            handler: function(val) {
                this.dispatch('TopBar', 'has-new-msg', {num: val});
            },
            immediate: true
        }
    },
    mounted() {
        let fn = window.onstorage;
        window.onstorage = (e) => {
            fn && fn(e);
            if(e.key == 'instantMessageVisible') {
                if(e.newValue === "true") {
                    this.isShow = true;
                }else {
                    this.isShow = false;
                }
            }
        }
        this.$on('image-loaded', (json) => {
            if(!this.isPositionMode) {
                this.$refs['current-conversation'].keepMessageListOnButtom();
            }
        });
        this.$on('image-preview', (json) => {
            this.$refs['image-previewer'].handlePreview(json);
        });
        this.$on('share-message-send', (item) => {
            if(window.lbdTrack){
                lbdTrack.track('IMShareCardSend',{
                    type: this.shareMessage.msgContent.ext.type
                })
            } else {
                _tracker.track('IMShareCardSend',JSON.stringify({
                    type: this.shareMessage.msgContent.ext.type //todo
                }))
            }
            let message = {},
                json = this.shareMessage;
            this.clearUnsendShareMessage();

            openIM.createCustomMessage({
                data: JSON.stringify(json.msgContent.ext),
                extension: 'Share',
                description: "",
            }).then(({ data }) => {
                console.log(`data:`, data);
                let messageStr = data;

                openIM.sendMessage({
                    recvID: json.toAccountId,
                    groupID: '',
                    offlinePushInfo: {
                        title: '新消息提醒',
                        desc: '',
                        ex: '',
                        iOSPushSound: '',
                        iOSBadgeCount: true
                    },
                    message: messageStr,
                }).then(({ data, errCode })=>{
                    if (errCode === 0 || errCode === undefined) {
                        try {
                            let msg = JSON.parse(data);
                            msg = convertMessage(msg);
                            msg.payload.ext = JSON.parse(msg.payload.data);
                            msg.payload.data = null;
                            this.updateMessageList(msg);
                        } catch (err) {
                            console.log(err);
                        }
                        // data.message.payload.ext = json.msgContent.ext;
                        // this.updateMessageList(data.message);
                        console.log('发送成功！');
                    } else {
                        console.log('发送失败！');
                    }
                }).catch(err=>{
                    console.log('sendMessageError：', err);
                });
            }).catch(err => { 
                console.log(`err:`, err)
            });

            // message = openIM.createCustomMessage({
            //     to: json.toAccountId,
            //     conversationType: TIM.TYPES.CONV_C2C,
            //     payload: {
            //         data: "",
            //         description: "",
            //         extension: JSON.stringify(json.msgContent.ext)
            //     }
            // });
            // tim.sendMessage(message, {
            //     offlinePushInfo: {
            //         description: JSON.stringify(json.msgContent.ext)
            //     }
            // }).then(({ data }) => {
            //     data.message.payload.ext = json.msgContent.ext;
            //     this.updateMessageList(data.message);
            // })
            // .catch(error => {
            //     console.log('sendMessageError：', error.message);
            // });
        });
        this.$on('update-group-info', (json) => {
            if (json.name) {
                this.currentConversation.groupProfile.name = json.name;
            }
            if (json.selfInfo) {
                this.currentConversation.groupProfile.selfInfo = json.selfInfo;
            }
        });
        this.$on('add-group-member', (json) => {
            this.$refs.sideBar.showCreateGroupDialog(true, json);
        })
        this.$on('revoke-message', (json) => {
            let currentConversation = this.conversationList.find(item => {
                return item.conversationID === json.conversationID;
            })
            currentConversation.lastMessage.isRevoked = true;
        })
        this.$on('updateMemberList', () => {
            
            this.$nextTick(() => {
                if (this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
                    setTimeout(() => {
                        this.getMemberList();
                        this.$refs['current-conversation'].refreshGroupInfo();
                    }, 1000)
                }
            });
        })
        this.$on('select-conversation-from-system-notice', (conversationId) => {
            this.$refs.sideBar.selectSingleConversation(conversationId);
        })
        this.$on('addUserProfileMap', (item) => {
            if(!(item.accountId in this.c2cUserProfileMap)) {
                this.$set(this.c2cUserProfileMap, item.accountId, item.param)
            }
        })
        this.$on('show-audit-deny-detail-dialog', (id) => {
            this.$refs.auditDenyDetailDialog.show(id);
            this.closeInstantMessage();
        })
        this.initListener();
        if(this.pageTabVisible) {
            this.getSignatureAndLogin();
        }
        this.handleTabVisibilityChange();
        // setTimeout(() =>{
        //     this.sendSystemNotification();
        // }, 3000)
        setInterval(() => {
            if(this.isLogin) {
                this.updateOnlineStatus();
            }
        }, 300000);
    },
    methods: {
        // sendSystemNotification(type) {
        //     let extension = {
        //             type: "recommendStatusUpdate",
        //             data: {
        //             unionId: "a8310455-3795-49de-8e91-4b9dce9b0719",
        //             recommenderName: "张三疯",
        //             recommenderAvatar: "",
        //             flowUpdateDate: new Date(),
        //             candidateName: "李思思",
        //             title: "鼓励师",
        //             recommendId: "xxx",
        //             candidateId: "xxxx",
        //             jobName: "程序员鼓励师",
        //             status: 0,
        //             customerName: "八爪"
        //         }
        //     }
        //     if(type == 2) {
        //         extension = {
        //             type: "newJob",
        //             data: {
        //                 newJobNumber: 10  //新职位数量
        //             }
        //         }
        //     }else if(type == 3) {
        //         extension = {
        //             type: "recommendNotProcess",
        //             data: {
        //                 recommendNotProcess: 32  //未处理推荐数量
        //             }
        //         }
        //     }
        //     imService.sendSystemNotification({
        //         toAccountId: this.userId,
        //         msgContent: JSON.stringify(extension)
        //     })
        // },
        showInstantMessage({isClickShow = false, isShare = false, json = {}} = {}) {
            if(isShare) {
                this.isShare = isShare;
                if(json.type === 'jobShare') {
                    if(json.isInnerJob && !(json.unionId in this.innerLabelMap)) {
                        //需要请求是否是内部成员
                        imService.checkInnerLabel({
                            accountIds: [json.unionId],
                            groupIds: []
                        }).then(result => {
                            if(result && result.length) {
                                if(result[0].accountId) {
                                    this.$set(this.innerLabelMap, `${result[0].accountId}`, result[0].checkResult);
                                }
                                if(result[0].checkResult !== 2) {
                                    this.shareMessage = {
                                        status: 'unSend',
                                        fromAccountId: this.userId,
                                        toAccountId: json.unionId,
                                        msgType: TIM.TYPES.MSG_CUSTOM,
                                        msgSeq: null,
                                        msgTime: null,
                                        msgContent: {
                                            data: null,
                                            ext: {
                                                type: "jobShare",
                                                data: {
                                                    jobId: json.jobId,
                                                    jobName: json.jobName,
                                                    firmShortName: json.firmShortName,
                                                    location: json.location,
                                                    yearOfExperience: json.yearOfExperience,
                                                    degree: json.degree,
                                                    salary: json.salary,
                                                    commissionValue: json.commissionValue,
                                                    creatorId: json.creatorId,
                                                    creatorName: json.creatorName,
                                                    creatorAvatar: json.creatorAvatar
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    this.isShare = false;
                                }
                            }
                        }).catch(err => {
                            console.log('checkInnerLabelError', err);
                        });
                    } else if(!json.isInnerJob || (json.isInnerJob && this.innerLabelMap[json.unionId] !== 2)) {
                        this.shareMessage = {
                            status: 'unSend',
                            fromAccountId: this.userId,
                            toAccountId: json.unionId,
                            msgType: TIM.TYPES.MSG_CUSTOM,
                            msgSeq: null,
                            msgTime: null,
                            msgContent: {
                                data: null,
                                ext: {
                                    type: "jobShare",
                                    data: {
                                        jobId: json.jobId,
                                        jobName: json.jobName,
                                        firmShortName: json.firmShortName,
                                        location: json.location,
                                        yearOfExperience: json.yearOfExperience,
                                        degree: json.degree,
                                        salary: json.salary,
                                        commissionValue: json.commissionValue,
                                        creatorId: json.creatorId,
                                        creatorName: json.creatorName,
                                        creatorAvatar: json.creatorAvatar
                                    }
                                }
                            }
                        }
                    } else {
                        this.isShare = false;
                    }
                } else {
                    this.shareMessage = {
                        status: 'unSend',
                        fromAccountId: this.userId,
                        toAccountId: json.unionId,
                        msgType: TIM.TYPES.MSG_CUSTOM,
                        msgSeq: null,
                        msgTime: null,
                        msgContent: {
                            data: null,
                            ext: {
                                type: "candidateShare",
                                data: {
                                    resumeId: json.resumeId,
                                    candidateName: json.candidateName,
                                    title: json.title,
                                    company: json.company,
                                    cityName: json.cityName,
                                    yearOfExperience: json.yearOfExperience,
                                    degree: json.degree,
                                    school: json.school
                                }
                            }
                        }
                    }
                }
            }
            if(json && json.unionId) {
                if(this.currentConversation.conversationID !== `C2C${json.unionId}`) {
                    //打开指定聊天窗口
                    this.$refs.sideBar.selectConversationById({
                        conversationId: `C2C_${json.unionId}`
                    }, true);
                } else if(this.isShare) {
                    if(window.lbdTrack){
                        lbdTrack.track('IMShareCardCreate',{
                            type: json.type
                        })
                    } else {
                        _tracker.track('IMShareCardCreate',JSON.stringify({
                            type: json.type
                        }))
                    }
                    this.clearUnsendShareMessage();
                    this.currentMessageList.push(this.shareMessage);
                    this.isShare = false;
                }
            }
            if(isClickShow) {
                if(window.lbdTrack) {
                    lbdTrack.track('ShowInstantMessage', {
                        isFromWorkbench: false
                    });
                }
            }
            this.isShow = true;
            localStorage.instantMessageVisible = true;
        },
        closeInstantMessage() {
            this.isShow = false;
            localStorage.instantMessageVisible = false;
            if(this.isNewProject) {
                this.dispatch('TopBar', 'close-im');
            } else {
                this.$refs.instantMessageBtn.classList.remove('active');
            }
            this.$refs.sideBar.handleClearSearch();
            this.$refs.sideBar.$refs.msgNotifiedPopper.showPopper = false;
            this.clearUnsendShareMessage();
        },
        showShareToImDialog(shareData) {
            this.$refs['share-to-im-dialog'].show(shareData);
        },
        handleTabVisibilityChange() {
            let self = this;
            document.addEventListener('visibilitychange', () => {
                if(document.visibilityState == 'visible') {
                    self.pageTabVisible = true;
                    if(!this.isLogin && openIM) {
                        this.getSignatureAndLogin();
                    }
                }else {
                    self.pageTabVisible = false;

                }
                console.log( document.visibilityState );
                console.log( self.pageTabVisible );
            });
        },
        getSignatureAndLogin() {
            if(localStorage.getItem('imUserSig')) {
                this.userSig = localStorage.getItem('imUserSig');
                this.login({
                    useLocalSig: true
                });
            }else {
                imService.getUserSig().then(res =>{
                    this.userSig = res.token;
                    localStorage.setItem('imUserSig', res.token);
                    this.login();
                }).catch((err) => {
                    if(!err.code || (err.code && err.code == '401')) {
                        imService.getUserSig().then(res =>{
                            this.userSig = res.token;
                            localStorage.setItem('imUserSig', res.token);
                            this.login();
                        })
                    } else {
                        console.log(err);
                        // shortTips("系统异常，请稍后重试");
                    }
                })
            }
        },
        refreshSignature() {
            return new Promise((resolve, reject) => {
                if(localStorage.getItem('imUserSig')) {
                    resolve(true);
                } else {
                    imService.getUserSig().then(res =>{
                        this.userSig = res.token;
                        localStorage.setItem('imUserSig', res.token);
                        resolve(true);
                    }).catch(err => {
                        resolve(false);
                    })
                }

            })
        },
        initListener() {
            openIM.on(openIM.CbEvents.ONCONNECTSUCCESS, this.onConnectSuccess)
            openIM.on(openIM.CbEvents.ONCONNECTFAILED, this.onConnectFailed)
            openIM.on(openIM.CbEvents.ONKICKEDOFFLINE, this.onKickOut)
            openIM.on(openIM.CbEvents.ONUSERTOKENEXPIRED, this.onTokenExpired)
            openIM.on(openIM.CbEvents.ONRECVNEWMESSAGES, this.onReceiveMessage)
            openIM.on(openIM.CbEvents.ONRECVMESSAGEREVOKED, this.onRevokedMessage)
            // openIM.on(openIM.CbEvents.ONNEWCONVERSATION, this.onUpdateConversationList)
            openIM.on(openIM.CbEvents.ONNEWCONVERSATION, this.onNewConversation)
            openIM.on(openIM.CbEvents.ONCONVERSATIONCHANGED, this.onUpdateConversationList)
            openIM.on(openIM.CbEvents.ONTOTALUNREADMESSAGECOUNTCHANGED, this.onUnreadCountChange)
            openIM.on(openIM.CbEvents.OnGROUPINFOCHANGED, this.onGroupInfoChanged)
            // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
            // tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this)
            // // SDK NOT READT
            // tim.on(TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this)
            // // 收到新消息
            // tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
            // // 被踢出
            // tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut);
            // // 会话列表更新
            // tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList);
            // // 消息被撤回
            // tim.on(TIM.EVENT.MESSAGE_REVOKED, this.onRevokedMessage);
        },
        login({useLocalSig}={}) {
            openIM.login({
                // userID: this.userId,
                // userSig: this.userSig
                userID: this.userId,                                // 用户ID
                token: this.userSig,
                // url: this.userSig.url,    // jssdk server ws地址
                // url: "wss://121.37.25.72:10003",    // jssdk server ws地址
                // url: "wss://open-im-rc.liebide.com/jssdk_gateway",    // jssdk server ws地址
                // apiAddress: "https://open-im-rc.liebide.com/api",
                apiAddress: _host.openIMApiAddress,
                wsAddress: _host.openIMWsAddress,
                // wsAddress: "wss://open-im-rc.liebide.com/msg_gateway",
                // apiAddress: "https://192.168.1.34:10002",
                // wsAddress: "wss://192.168.1.34:10001",
                platformID: 5, 
            }).then(() => {
                this.isLogin = true;
                console.log('loginSuccess');
                openIM.getSelfUserInfo().then(({ data })=>{
                    this.currentUserProfile = JSON.parse(data);
                }).catch(err => {
                    console.log(err);
                });
                this.getAllConversationList();
            }).catch(error => {
                if(useLocalSig && this.imCanRetryFlag) {
                    this.imCanRetryFlag = false;
                    localStorage.removeItem('imUserSig');
                    this.getSignatureAndLogin();
                }
                console.log('loginError', error);
            }).finally(() => {
            })
        },
        onConnectSuccess() {
            console.log("connect suc...");
            console.log(this.isLogin);
            // tim.getMyProfile()
            //     .then(({ data }) => {
            //         this.currentUserProfile = data;
            //     })
            //     .catch(error => {
            //         // this.$store.commit('showMessage', {
            //         //     type: 'error',
            //         //     message: error.message
            //         // })
            //     });

            if (this.isLogin) {
                openIM.getSelfUserInfo().then(({ data })=>{
                    this.currentUserProfile = JSON.parse(data);
                }).catch(err => {
                    console.log(err);
                });
                this.getAllConversationList();
            }
        },
        onConnectFailed() {
            console.log('connect failed');
            // localStorage.removeItem('imUserSig');
            // this.getSignatureAndLogin();
        },
        // onReadyStateUpdate({ name }) {
        //     const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false;
        //     if(isSDKReady) {
        //         console.log('ready');
        //         // this.getConversationList();
        //         tim.getMyProfile()
        //             .then(({ data }) => {
        //                 this.currentUserProfile = data;
        //             })
        //             .catch(error => {
        //                 // this.$store.commit('showMessage', {
        //                 //     type: 'error',
        //                 //     message: error.message
        //                 // })
        //             })
        //     } else {
        //         console.log("sdkNotReady");
        //     }
        // },
        onKickOut() {
            this.isLogin = false;
            this.updateConversationListTime = 0;
            // if(this.pageTabVisible) {
            //     this.dispatch('TopBar', 'has-new-msg', {num: 0});
            //     document.addEventListener('mousemove', () =>{
            //         this.getSignatureAndLogin();
            //     }, {once: true})
            // }
                // 收到被踢下线通知
                // event.name - TIM.EVENT.KICKED_OUT
                // event.data.type - 被踢下线的原因，例如 :
                //   - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
                //   - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
                //   - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢（v2.4.0起支持）。
        },
        onTokenExpired() {
            this.isLogin = false;
            if(this.pageTabVisible) {
                document.addEventListener('mousemove', () =>{
                    this.getSignatureAndLogin();
                }, {once: true})
            }
        },
        onReceiveMessage({ data }) {
            console.log(data);
            let messageList = JSON.parse(data);

            messageList = messageList.filter(item => {
                return item.contentType != 111;
            });

            let filteredMessageList = [],
                firmGroupMessageList = [];
            if (Array.isArray(messageList)) {
                // 筛选出当前会话的消息
                filteredMessageList = messageList.filter(item => {
                    if (item.sessionType === 1) {
                        return this.currentConversation.userProfile && this.currentConversation.userProfile.userID === item.sendID;
                    } else {
                        return this.currentConversation.groupProfile && this.currentConversation.groupProfile.groupID === item.groupID;
                    }
                })
                firmGroupMessageList = messageList.filter(item => item.groupID === this.firmGroupId);
            } else {
                if (this.currentConversation.userProfile && this.currentConversation.userProfile.userID === item.sendID) {
                    filteredMessageList = [messageList];
                }
                if(messageList.groupID === this.firmGroupId) {
                    firmGroupMessageList = [messageList];
                }
            }
            filteredMessageList = filteredMessageList.map(item => convertMessage(item));
            firmGroupMessageList = firmGroupMessageList.map(item => convertMessage(item));
            if(firmGroupMessageList.length > 0 && this.isWorkbenchMain) {
                this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                    newMessageList: firmGroupMessageList
                });
            }
            if(filteredMessageList.length > 0 && this.isPositionMode) {
                this.$refs['current-conversation'].handleReceiveMessage();
                return false;
            }
            if(filteredMessageList.length > 0) {
                this.$refs['current-conversation'].handleReceiveMessage();
            }
            let lbdMsgList = [];
            filteredMessageList.forEach(message => {
                if(message.type === TIM.TYPES.MSG_CUSTOM && !message.payload.data) {
                    try {
                        message.payload.extension = JSON.parse(message.payload.extension);
                    } catch {
                        message.payload.extension = message.payload.extension;
                    }
                    message.payload.ext = message.payload.extension;
                }
                if(message.type === TIM.TYPES.MSG_CUSTOM && message.payload.data) {
                    try {
                        message.payload.ext = JSON.parse(message.payload.data);
                        message.payload.data = null;
                    } catch(err) {
                        message.payload.ext = message.payload.data;
                        message.payload.data = null;
                    }
                }
                let LbdMsg = convertSdkMsgToLbdMsg(message);
                if(this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
                    LbdMsg.fromAccountName = message.nick;
                }
                if(
                    (message.conversationType === TIM.TYPES.CONV_C2C && message.from === this.currentConversation.userProfile.userID)
                    ||
                    (message.conversationType === TIM.TYPES.CONV_GROUP && message.conversationID === this.currentConversation.conversationID)
                ) {
                    // 将消息设置为已读
                    // tim.setMessageRead({ conversationID: message.conversationID })
                    // .then(res => {
                    //     console.log('currentConversationId消息已读');
                    // }).catch(err => {
                    //     console.log('setMessageReadError', err);
                    // });
                    this.setCurrentConversationRead();

                    if(this.$refs['current-conversation'].unreadCount > 10) {
                        this.$refs['current-conversation'].unreadCount++;
                    }
                    // if(LbdMsg.msgType === TIM.TYPES.MSG_GRP_TIP) {
                    //     if(LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED ||
                    //         LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_QUIT ||
                    //         LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_KICKED_OUT ||
                    //         LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_JOIN) {
                    //         lbdMsgList.push(LbdMsg);
                    //     }
                    // } else {
                    //     lbdMsgList.push(LbdMsg);
                    // }
                    lbdMsgList.push(LbdMsg);
                }
            })
            this.currentMessageList = [...this.currentMessageList, ...lbdMsgList];

            let notifyMessageList = messageList;
            notifyMessageList = notifyMessageList.map(item => convertMessage(item))
            this.handleMessageNotified(notifyMessageList);
            this.handleGroupTip(messageList);
        },
        setCurrentConversationRead() {
             if (this.currentConversation.type === TIM.TYPES.CONV_C2C) {
                openIM.markC2CMessageAsRead({
                    userID: this.currentConversation.userProfile.userID,
                    msgIDList: []
                }).then(({ data })=>{
                    console.log('data');
                }).catch(err => {
                    console.log('setMessageReadError', err);
                })
            }
            if (this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
                openIM.markGroupMessageAsRead({
                    groupID: this.currentConversation.groupProfile.groupID,
                    msgIDList:[]
                }).then(({ data })=>{
                    console.log('data');
                }).catch(err => {
                    console.log('setMessageReadError', err);
                })
            }
            // if (this.currentConversation.conversationType === TIM.TYPES.CONV_SYSTEM
            //     || (this.currentConversation.conversationType === openIM.SessionType.Single && this.currentConversation.userID === "openIM123456")
            // ) {
            //     openIM.markNotifyMessageHasRead(this.currentConversation.conversationID).then(({ data })=>{
            //         console.log('data');
            //     }).catch(err => {
            //         console.log('setMessageReadError', err);
            //     })
            // }
        },
        onUnreadCountChange({ data }) {
            console.log(data);
        },
        onNewConversation({ data }) {
            console.log(data);
            this.getAllConversationList({
                isSelectFirst: true
            });
        },
        onUpdateConversationList({ data }) {
            if (this.conversationList.length == 0) {
                return false;
            }
            if (!data || data == '[]') {
                return false;
            }
            console.log(data);
            
            let _self = this;
            let list = data ? JSON.parse(data) : [];
            if (list.length > 0) {
                let curList = this.conversationList;
                let groupUserIds = [];

                list.forEach(conv => {
                    conv = convertConversation(conv);

                    if (conv.lastMessage.type === TIM.TYPES.MSG_CUSTOM && conv.lastMessage.payload.data) {
                        let customMsg = JSON.parse(conv.lastMessage.payload.data);
                        if (customMsg.type == 'atMessage') {
                            let atUsers = customMsg.data.atUsers;
                            if (atUsers.indexOf(this.userId) > -1 && _self.atMeConversationList.indexOf(conv.conversationID) == -1) {
                                _self.atMeConversationList.push(conv.conversationID);
                            }
                        }
                    }

                    curList = curList.map(item => {
                        if (conv.conversationID === item.conversationID) {
                            return conv;
                        } else {
                            return item;
                        }
                    })

                    if(conv.type === TIM.TYPES.CONV_GROUP) {
                        groupUserIds.push({
                            accountId: conv.lastMessage.fromAccount,
                            type: 0
                        });
                    }
                    if(conv.type === TIM.TYPES.CONV_C2C && conv.userProfile.userID !== 'openIM123456') {
                        groupUserIds.push({
                            accountId: conv.userProfile.userID,
                            type: 0
                        });
                    }
                });

                if(groupUserIds.length > 0) {
                    imService.getNameById({
                        dataList: groupUserIds
                    }).then(res => {
                        let param = {};
                        res.forEach(item => {
                            for(let i = 0; i < curList.length; i++) {
                                if(curList[i].type === TIM.TYPES.CONV_GROUP && curList[i].lastMessage.fromAccount === item.accountId) {
                                    curList[i].lastMessage.fromAccoutName = item.accountUserName;
                                }
                                // if(!(item.accountId in this.c2cUserProfileMap)) {
                                //     param = {
                                //         firmId: item.firmId,
                                //         company: item.company,
                                //         title: item.title
                                //     }
                                //     this.$set(this.c2cUserProfileMap, item.accountId, param)
                                // }
                            }
                        })
                        this.conversationList = curList;
                    }).catch(err => {
                        this.conversationList = curList;
                        console.log("getNameByIdError:", err);
                    })
                } else {
                    this.conversationList = curList;
                }




                
            }
        },
        getAllConversationList(json) {
            openIM.getAllConversationList().then(({ data })=>{
                try {
                    console.log(JSON.parse(data));
                    let conversationList = JSON.parse(data);
                    console.log(convertConversation);

                    conversationList = conversationList.map(item => {
                        return convertConversation(item);
                    });
                    conversationList.forEach(item => {
                        if (item.isNotInGroup) {
                            // quitList.push(item);
                            openIM.deleteConversationFromLocalAndSvr(item.openIMConversationID).then(({ data })=>{
                                console.log('删除会话成功');
                            }).catch(err=>{
                                console.log('deleteConversationError：', err);
                            })
                        }
                    });
                    
                    conversationList = conversationList.filter(item => {
                        return !item.isNotInGroup;
                    });
                    this.acceptNotNotifyList = [];
                    let groupUserIds = [];
                    conversationList.forEach(item => {
                        if(item.type === TIM.TYPES.CONV_GROUP) {
                            groupUserIds.push({
                                accountId: item.lastMessage.fromAccount,
                                type: 0
                            });
                            if(item.groupProfile.selfInfo.messageRemindType === "AcceptNotNotify") {
                                this.acceptNotNotifyList.push(item.conversationID);
                            }
                            if(item.conversationID === `GROUP_${this.firmGroupId}`) {
                                this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                                    unreadCount: item.unreadCount
                                });
                            }
                        }
                        if(item.type === TIM.TYPES.CONV_C2C && item.userProfile.userID !== 'openIM123456') {
                            groupUserIds.push({
                                accountId: item.userProfile.userID,
                                type: 0
                            });
                        }
                        if(item.lastMessage.type === TIM.TYPES.MSG_CUSTOM && !item.lastMessage.payload.data) {
                            //猎必得自定义消息体 description 和 data 都为空字符串
                            try {
                                item.lastMessage.payload.extension = item.lastMessage.payload.extension ? JSON.parse(item.lastMessage.payload.extension) : "";
                            } catch(err) {
                                item.lastMessage.payload.extension = item.lastMessage.payload.extension;
                            }
                        }
                    })
                    this.conversationList = conversationList;

                    if (json && json.isUpdate) {
                        return false;
                    }

                    if(this.conversationList.length > 0) {
                        this.updateOnlineStatus();
                        if(this.currentConversation.conversationID) {
                            // let hasSelected = this.conversationList.some(item => item.conversationID === this.currentConversation.conversationID);
                            // if(!hasSelected) {
                                var conversationId = this.currentConversation.conversationID
                                this.updateCurrentConversation({
                                    conversation: {}
                                })
                                setTimeout(() => {
                                    this.$refs.sideBar.selectConversationById({
                                        conversationId: conversationId
                                    }, true);
                                }, 500)
                            // }
                        }
                    }

                    // if (json && json.isSelectFirst) {
                    //     this.$refs.sideBar.selectConversation(this.conversationList[0], true);
                    // }

                    if(groupUserIds.length > 0) {
                        imService.getNameById({
                            dataList: groupUserIds
                        }).then(res => {
                            let param = {};
                            res.forEach(item => {
                                for(let i = 0; i < conversationList.length; i++) {
                                    if(conversationList[i].type === TIM.TYPES.CONV_GROUP && conversationList[i].lastMessage.fromAccount === item.accountId) {
                                        this.$set(conversationList[i].lastMessage, 'fromAccoutName', item.accountUserName)
                                    }
                                    if(!(item.accountId in this.c2cUserProfileMap)) {
                                        param = {
                                            firmId: item.firmId,
                                            company: item.company,
                                            title: item.title
                                        }
                                        this.$set(this.c2cUserProfileMap, item.accountId, param)
                                    }
                                }
                            })
                        }).catch(err => {
                            console.log("getNameByIdError:", err);
                        })
                    }
                    // 首次获取会话列表时需要查询有未读消息的群组会话中是否有 @我 的消息
                    let models = [],
                        checkHasAtMeConversations = {},
                        innerAccoutIds = [],
                        innerGroupIds = [];
                    conversationList.forEach(item => {
                        if(item.type === TIM.TYPES.CONV_GROUP && item.unreadCount > 0) {
                            checkHasAtMeConversations[item.groupProfile.groupID] = item.conversationID;
                            models.push({
                                groupId: item.groupProfile.groupID,
                                msgSeq: '',
                                offset: 0,
                                pageSize: item.unreadCount
                            });
                            if(item.lastMessage.fromAccount === 'openIM123456' && item.lastMessage.messageForShow.indexOf('加入群聊') > -1) {
                                if(item.unreadCount > 1) {
                                    item.unreadCount--;
                                } else {
                                    // openIM.markC2CMessageAsRead({
                                    //     userID: item.conversationID.replace('C2C_', '').replace('GROUP_', ''),
                                    //     msgIDList:[]
                                    // }).then(({ data })=>{
                                    //     console.log('currentConversationId消息已读');
                                    // }).catch(err=>{
                                    //     console.log('setMessageReadError', err);
                                    // })
                                    // tim.setMessageRead({ conversationID: item.conversationID })
                                    // .then(res => {
                                    //     console.log('currentConversationId消息已读');
                                    // }).catch(err => {
                                    //     console.log('setMessageReadError', err);
                                    // });
                                }
                            }
                        }
                        if(item.type !== TIM.TYPES.CONV_SYSTEM) {
                            if(item.type === TIM.TYPES.CONV_C2C) {
                                innerAccoutIds.push(item.userProfile.userID);
                            }
                            if(item.type === TIM.TYPES.CONV_GROUP) {
                                innerGroupIds.push(item.groupProfile.groupID);
                            }
                        }
                    });
                    if(innerAccoutIds.length > 0 || innerGroupIds.length > 0) {
                        imService.checkInnerLabel({
                            accountIds: innerAccoutIds,
                            groupIds: innerGroupIds
                        }).then(result => {
                            if(result && result.length) {
                                result.forEach(item => {
                                    if(item.accountId) {
                                        this.$set(this.innerLabelMap, `${item.accountId}`, item.checkResult);
                                        if(item.companyResult) {
                                            this.companyGroupId = item.accountId;
                                        }
                                    }
                                });
                            }
                        }).catch(err => {
                            console.log('checkHasAtMeError', err);
                        });
                    }
                    if(models.length > 0) {
                        imService.checkHasAtMe({
                            models: models
                        }).then(group => {
                            if(group && group.length) {
                                group.forEach(item => {
                                    if(item.checkResult && this.atMeConversationList.indexOf(checkHasAtMeConversations[item.groupId]) == -1) {
                                        this.atMeConversationList.push(checkHasAtMeConversations[item.groupId])
                                    }
                                });
                            }
                        }).catch(err => {
                            console.log('checkHasAtMeError', err);
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err=>{
                console.log(err);
            });
        },
        getMessageIndex(message) {
            let positionIndex = -1;
            this.currentMessageList.some((item, index) => {
                if(item.msgSeq == message.msgSeq) {
                    positionIndex = index;
                    return true;
                }
            })
            return positionIndex;
        },
        scrollToMessageByIndex(index) {
            let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
            if(messageListNode 
                && messageListNode.getElementsByClassName('message-wrapper')[index]
                && messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView
            ) {
                messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView();
            }
        },
        getMoreMessageList({unreadCount, flipOverType = 0} = {}) {
            this.getMessageList({
                isReset: false, 
                unreadCount: unreadCount,
                flipOverType: flipOverType
            });
            // this.getOpenIMMessageList({
            //     isReset: false, 
            //     unreadCount: unreadCount,
            //     flipOverType: flipOverType
            // });
        },
        onRevokedMessage({ data: messageList}) {
            let currentMessageList = this.currentMessageList;
            messageList.forEach(message => {
                for(let i = 0; i < currentMessageList.length; i++) {
                    if(message.time === currentMessageList[i].msgTime && message.conversationID === this.currentConversation.conversationID) {
                        currentMessageList[i].isRevoked = true;
                        break;
                    }
                }
            })
        },
        getMessageList({isReset, unreadCount, flipOverType = 0, positionMessageInfo}) {
            this.currentMessageListFetched = false;
            this.flipOverType = flipOverType;
            if(isReset) {
                this.currentMessageList = [];
                this.isCompleted = false;
                this.isAfterCompleted = true;
                this.messageLoading = true;
                this.nextAfterReqMessageID = '';
                this.nextAfterReqMessageTime = '';
                // this.nextReqMessageID = '';

                if(positionMessageInfo) {
                    this.isPositionMode = true;
                }else {
                    this.isPositionMode = false;
                }
            }else {
                this.messagePagingLoading = true;
            }

            // if(this.currentConversation.conversationID == 'C2Cbaza_im_admin') {
            //     this.currentMessageList = [1, 2, 3];
            //     // let conversationID = this.currentConversation.conversationID;
            //     // let nextReqMessageID = '';
            //     // tim.getMessageList({ conversationID, nextReqMessageID, count: 15 }).then(imReponse => {
            //     //     this.currentMessageList = [...imReponse.data.messageList, ...this.currentMessageList];
            //     //     console.log("currentMessageList", this.currentMessageList)
            //     //     if(nextReqMessageID) {
            //     //         this.$nextTick(() =>{
            //     //             let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
            //     //             let index = imReponse.data.messageList.length - 1;
            //     //             messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView();
            //     //         })
            //     //     }
            //     //     this.nextReqMessageID = imReponse.data.nextReqMessageID;
            //     //     this.isCompleted = imReponse.data.isCompleted;
            //     // })
            //     return false;
            // }

            let param = {
                count: unreadCount ? unreadCount : 10
            }
            if(this.currentConversation.type == TIM.TYPES.CONV_GROUP) {
                param.type = 1;
                if(flipOverType == 0) {
                    param.nextReqMessageId = this.nextReqMessageID;
                }else {
                    param.flipOverType = flipOverType;
                    param.nextReqMessageId = this.nextAfterReqMessageID;
                }
                param.groupId = this.currentConversation.groupProfile.groupID;
            } else if(this.currentConversation.type == TIM.TYPES.CONV_C2C){
                param.type = 0;
                if(flipOverType == 0) {
                    param.nextReqMessageTime = this.nextReqMessageTime;
                }else {
                    param.flipOverType = flipOverType;
                    param.nextReqMessageTime = this.nextAfterReqMessageTime;
                }
                param.fromAccountId = this.currentUserProfile.userID;
                param.toAccountId = this.currentConversation.userProfile.userID;
            }

            if(positionMessageInfo) {
                param.positionFlag = true;
                param.count = 5;
                if(positionMessageInfo.type == 1) {
                    param.nextReqMessageId = positionMessageInfo.msgSeq;
                }else {
                    param.nextReqMessageTime = positionMessageInfo.msgTime;
                }
            }

            let currentConversationId = this.currentConversation.conversationID;
            imService.getMessageList(param)
                .then(res =>{
                    if(currentConversationId != this.currentConversation.conversationID) {
                        return false;
                    }

                    if(isReset && positionMessageInfo) {
                        this.isCompleted = res.isCompleted;
                        this.nextReqMessageID = res.nextReqMessageId;
                        this.nextReqMessageTime = res.nextReqMessageTime || 0;
                        this.isAfterCompleted = res.isAfterCompleted;
                        this.nextAfterReqMessageID = res.nextAfterReqMessageId || 0;
                        this.nextAfterReqMessageTime = res.nextAfterReqMessageTime || 0;
                    }else {
                        if(flipOverType == 0) {
                            this.isCompleted = res.isCompleted;
                            this.nextReqMessageID = res.nextReqMessageId;
                            this.nextReqMessageTime = res.nextReqMessageTime || 0;
                        }else {
                            this.isAfterCompleted = res.isAfterCompleted;
                            this.nextAfterReqMessageID = res.nextAfterReqMessageId || 0;
                            this.nextAfterReqMessageTime = res.nextAfterReqMessageTime || 0;
                        }
                    }

                    if(flipOverType == 1 && res.isAfterCompleted == true) {
                        this.isPositionMode = false;
                    }


                    if(res.messageList) {
                        res.messageList.forEach((message, index) => {
                            if(message.msgType === TIM.TYPES.MSG_CUSTOM && !message.msgContent.data) {
                                try {
                                    message.msgContent.ext = JSON.parse(message.msgContent.ext);
                                } catch(err) {
                                    message.msgContent.ext = message.msgContent.ext;
                                }
                            }
                        })
                        if(flipOverType == 0) {
                            this.currentMessageList = [...res.messageList, ...this.currentMessageList];
                        }else {
                            this.currentMessageList = [...this.currentMessageList, ...res.messageList];
                        }

                        //翻页定位
                        if(!isReset && !unreadCount) {
                            this.$nextTick(() =>{
                                if(flipOverType == 0) {
                                    let index = res.messageList.length - 1;
                                    this.scrollToMessageByIndex(index);
                                }else {
                                    let index = this.currentMessageList.length - res.messageList.length - 1;
                                    this.scrollToMessageByIndex(index);
                                }
                            })
                        }
                        //搜索到的消息定位
                        if(isReset && positionMessageInfo) {
                            let positionIndex = this.getMessageIndex(positionMessageInfo);
                            
                            if(positionIndex > -1) {
                                this.$nextTick(() =>{
                                    this.$nextTick(() =>{
                                        this.scrollToMessageByIndex(positionIndex);
                                        // this.$nextTick(() => {
                                        //     if(!res.isCompleted && !res.isAfterCompleted) {
                                        //         this.$refs['current-conversation'].bindScroll('all');
                                        //     }else if(!res.isCompleted) {
                                        //         this.$refs['current-conversation'].bindScroll('up');
                                        //     }else if(!res.isAfterCompleted) {
                                        //         this.$refs['current-conversation'].bindScroll('down');
                                        //     }
                                        // })
                                    })
                                })
                            }
                        }
                    }
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    this.currentMessageListFetched = true;
                    
                    if(this.isShare) {
                        if(window.lbdTrack){
                            lbdTrack.track('IMShareCardCreate',{
                                type: this.shareMessage.msgContent.ext.type
                            })
                        } else {
                            _tracker.track('IMShareCardCreate',JSON.stringify({
                                type: this.shareMessage.msgContent.ext.type
                            }))
                        }
                        this.currentMessageList.push(this.shareMessage);
                        this.isShare = false;
                    }
                    if(!unreadCount) {
                        this.$refs['current-conversation'].unreadCount = this.currentConversation.unreadCount;
                    }
                    if(unreadCount) {
                        this.$refs['current-conversation'].unreadCount = 0;
                        if(this.$refs['current-conversation'].isAtMe) {
                            this.$nextTick(() => {
                                this.$refs['current-conversation'].scrollToAtMe();
                            })
                        } else {
                            let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
                            if(messageListNode) {
                                messageListNode.getElementsByClassName('message-wrapper')[0].scrollIntoView();
                            }
                        }
                    }
                    // 将消息设置为已读
                    if(this.currentConversation.unreadCount > 0) {
                        // tim.setMessageRead({ conversationID: currentConversationId })
                        // .then(res => {
                        //     console.log('currentConversationId消息已读');
                        // }).catch(err => {
                        //     console.log('setMessageReadError', err);
                        // });

                        this.setCurrentConversationRead();
                    }
                }).catch(err =>{
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    if(err.errorMessage && err.errorMessage !== '查询类型不能为空') {
                        shortTips(err.errorMessage)
                    }
                }).finally(() => {
                })

            // if(isReset) {
            //     this.currentMessageList = [];
            //     this.isCompleted = false;
            //     this.nextReqMessageID = '';
            // }
            // let conversationID = this.currentConversation.conversationID;
            // let nextReqMessageID = this.nextReqMessageID;
            // tim.getMessageList({ conversationID, nextReqMessageID, count: 15 }).then(imReponse => {
            //     this.currentMessageList = [...imReponse.data.messageList, ...this.currentMessageList];
            //     console.log("currentMessageList", this.currentMessageList)
            //     if(nextReqMessageID) {
            //         this.$nextTick(() =>{
            //             let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
            //             let index = imReponse.data.messageList.length - 1;
            //             messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView();
            //         })
            //     }
            //     this.nextReqMessageID = imReponse.data.nextReqMessageID;
            //     this.isCompleted = imReponse.data.isCompleted;
            //     // this.$nextTick(() =>{
            //     //     let lastIndex = this.currentMessageList.length - 1;
            //     //     this.$refs[`message_item_${lastIndex}`][0].$el.scrollIntoView();
            //     // })
            //     // 更新messageID，续拉时要用到
            //     // context.state.nextReqMessageID = imReponse.data.nextReqMessageID
            //     // context.state.isCompleted = imReponse.data.isCompleted
            //     // 更新当前消息列表，从头部插入
            //     // context.state.currentMessageList = [...imReponse.data.messageList, ...currentMessageList]
            // })
        },
        // 处理 @ 我的消息
        handleMessageNotified(messageList) {
            const notifiedMessageList = messageList.filter(message => {
                return this.acceptNotNotifyList.indexOf(message.conversationID) === -1 || (message.type === TIM.TYPES.MSG_CUSTOM && message.payload.extension.type === 'atMessage')
            });
            notifiedMessageList.forEach((message, index) => {
                // @ 我 的消息
                if(message.type === TIM.TYPES.MSG_CUSTOM && message.payload.extension.type === 'atMessage') {
                    let isAtMe = message.payload.extension.data.atUsers.some(item => {
                        return item === this.userId || (item === '********-****-****-****-************' && message.toAccountId !== this.userId);
                    })
                    if(isAtMe) {
                        if(this.currentConversation.conversationID === message.conversationID && !this.$refs['current-conversation'].isAtMe) {
                            this.$refs['current-conversation'].isAtMe = true;
                        }
                        if(this.currentConversation.conversationID !== message.conversationID) {
                            this.updateAtMeData(true, {
                                conversationID: message.conversationID
                            })
                        }
                    }
                }
                if(localStorage.getItem('isMessageNotified') && localStorage.getItem('isMessageNotified') == 'true') {
                    let hidden = typeof document.hasFocus !== 'function' ? document.hidden : !document.hasFocus();
                    if(hidden) {
                        // 用户打开系统通知开关
                        if(message.type !== TIM.TYPES.MSG_GRP_TIP && !(message.from === "C2C_openIM123456" && message.payload.text && message.payload.text.indexOf('加入群聊') > -1)) {
                            this.notifyMe(message)
                        }
                    }
                }
            })
        },
        // 使用 window.Notification 进行全局的系统通知
        notifyMe(message) {
            // 需检测浏览器支持和用户授权
            if (!('Notification' in window)) {
                return
            } else if (window.Notification.permission === 'granted') {
                this.handleNotify(message)
            } else if (window.Notification.permission !== 'denied') {
                window.Notification.requestPermission().then(permission => {
                    // 如果用户同意，就可以向他们发送通知
                    if (permission === 'granted') {
                        this.handleNotify(message)
                    }
                })
            }
        },
        handleNotify(message) {
            if(message.to == this.userId) {
                // 消息匹配
                let title = "有新消息",
                    messageShow = "";
                switch(message.type) {
                    case TIM.TYPES.MSG_TEXT:
                        messageShow = message.payload.text;
                        break;
                    case TIM.TYPES.MSG_IMAGE:
                        messageShow = "[图片]";
                        break;
                    case TIM.TYPES.MSG_AUDIO:
                        messageShow = "[语音]";
                        break;
                    case TIM.TYPES.MSG_VIDEO:
                        messageShow = "[视频]";
                        break;
                    case TIM.TYPES.MSG_FILE:
                        messageShow = "[文件]";
                        break;
                    case TIM.TYPES.MSG_GEO:
                        messageShow = "[位置]";
                        break;
                    case TIM.TYPES.MSG_CUSTOM:
                        let extension = JSON.parse(message.payload.data);
                        if(extension.type === 'atMessage') {
                            let isAtMe = extension.data.atUsers.some(item => {
                                return item === this.userId || (item === '********-****-****-****-************' && message.toAccountId !== this.userId);
                            })
                            if(isAtMe) {
                                title = "有人提到了你";
                                messageShow = extension.data.message;
                            }
                        } else if(extension.type === 'newRecommend') {
                            title = "简历推荐提醒";
                            messageShow = "有人给你推荐了候选人";
                        } else if(extension.type === 'recommendStatusUpdate') {
                            title = "推荐状态变更提醒";
                            messageShow = "你的相关推荐状态有变更";
                        } else if(extension.type === 'newJob') {
                            title = "新增职位提醒";
                            messageShow = "昨日系统新增了职位，快来抢单";
                        } else if(extension.type === 'recommendNotProcess') {
                            title = "本月未处理推荐提醒";
                            messageShow = "你本月还有未处理的推荐，请及时处理";
                        } else if(extension.type === 'jobShare') {
                            title = "职位分享";
                            messageShow = "有人给你分享了职位";
                        } else if(extension.type === 'candidateShare') {
                            title = "候选人分享";
                            messageShow = "有人给你分享了候选人";
                        }
                    case TIM.TYPES.MSG_GRP_TIP:
                        messageShow = "[群提醒消息]";
                        break;
                    case TIM.TYPES.MSG_GRP_SYS_NOTICE:
                        messageShow = "[群系统通知消息]";
                        break;
                }
                const notification = new window.Notification(title, {
                    icon: require('@src/assets/images/home/logo_small.png'),
                    body: messageShow,
                    data: {
                        conversationID: message.conversationID
                    }
                })
                notification.onclick = (e) => {
                    window.focus();
                    this.showInstantMessage();
                    if(notification.data && notification.data.conversationID) {
                        this.$refs['sideBar'] && this.$refs['sideBar'].selectConversationById({
                            conversationId : notification.data.conversationID,
                            isScrollIntoView: true
                        });
                    }
                    notification.close()
                }
            }
        },
        handleGroupTip(messageList) {
            // 筛选出当前会话的退群/被踢群的 quitGroupTip
            let groupTips = messageList.filter(message => {
                return message.type === TIM.TYPES.MSG_GRP_TIP || (message.from === "C2C_openIM123456" && message.payload.text && message.payload.text.indexOf('加入群聊') > -1)
            });
            if(groupTips.length > 0) {
                let conversationIndex = -1;
                groupTips.forEach(item => {
                    conversationIndex = this.conversationList.findIndex(conversation => item.conversationID === conversation.conversationID)
                    if(this.conversationList[conversationIndex].unreadCount > 0) {
                        this.$set(this.conversationList[conversationIndex], 'unreadCount', this.conversationList[conversationIndex].unreadCount - 1);
                        if(this.isNewProject && document.querySelector('.topbar-btn.topbar-btn-chat').classList.contains('btn-chat-active')) {
                            this.dispatch('TopBar', 'close-im');
                        } else if(!this.isNewProject && this.$refs.instantMessageBtn.classList.contains('active')){
                            this.$refs.instantMessageBtn.classList.remove('active');
                        }
                    } else {
                        // tim.setMessageRead({ conversationID: item.conversationID })
                        // .then(res => {
                        //     console.log('currentConversationId消息已读');
                        // }).catch(err => {
                        //     console.log('setMessageReadError', err);
                        // });
                        openIM.markGroupMessageAsRead({
                            groupID: item.conversationID.replace('GROUP_', ''),
                            msgIDList: []
                        }).then(({ data })=>{
                            console.log(data);
                        }).catch(err=>{
                            console.log(err);
                        })
                    }
                })
            }
            let quitGroupTips = groupTips.filter(message => {
                return this.currentConversation.conversationID === message.conversationID &&
                        (message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_QUIT || 
                        message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_KICKED_OUT) 
            });
            if (quitGroupTips.length > 0) {
                this.getMemberList();
            }
        },
        checkConversationIfNew(conversation) {
            let result = true;
            this.conversationList.some(item => {
                if (item.conversationID === conversation.conversationID) {
                    result = false;
                    return true;
                }
            });
            return result;
        },
        updateCurrentConversation({conversation, positionMessageInfo}) {
            if (this.checkConversationIfNew(conversation) && conversation.conversationID) {
                this.conversationList = [conversation, ...this.conversationList];

                if (conversation.type === TIM.TYPES.CONV_C2C) {
                    imService.getNameById({
                        dataList: [{
                            accountId: conversation.userProfile.userID,
                            type: 0
                        }]
                    }).then(res => {
                        let param = {};
                        let result = res[0];
                        if (!(result.accountId in this.c2cUserProfileMap)) {
                            this.$set(this.c2cUserProfileMap, result.accountId, {
                                firmId: result.firmId,
                                company: result.company,
                                title: result.title
                            })
                        }
                    }).catch(err => {
                        console.log("getNameByIdError:", err);
                    })
                }
            }
            this.$refs['current-conversation'].hideGroupInfo();
            this.currentConversation = conversation;
            if(conversation.conversationID) {
                this.$nextTick(() => {
                    if (this.currentConversation.type === TIM.TYPES.CONV_GROUP) {
                        this.$refs['current-conversation'].refreshGroupInfo();
                    }
    
                    this.setCurrentConversationRead();
                });
            }
            if(conversation.conversationID) {
                //切换会话，暂停音频
                if(document.querySelector('#imSoundAudio')) {
                    let imSoundAudio = document.querySelector('#imSoundAudio');
                    if(!imSoundAudio.paused) {
                        imSoundAudio.pause();
                        imSoundAudio.currentTime = 0;
                    }
                }
                this.currentMessageListFetched = false;

                // let latestMsg = JSON.parse(conversation.latestMsg) || {};

                // this.nextReqMessageTime = latestMsg.sendTime === 0 ? new Date().getTime() : latestMsg.sendTime;
                // this.nextReqMessageID = latestMsg.clientMsgID;
                this.nextReqMessageTime = + new Date();
                // this.nextReqMessageID = conversation.lastMessage.lastSequence === 0 ? '' : conversation.lastMessage.lastSequence;
                this.nextReqMessageID = '';
                this.$refs['current-conversation'].isAtMe = false;
                this.$refs['current-conversation'].atMeMsgStart = -1;
                this.$refs['current-conversation'].hasNewMessage = false;
                this.$refs['current-conversation'].isShowScrollButtomTips = false;
                this.$refs['current-conversation'].unreadCount = 0;
                if(this.atMeConversationList.indexOf(conversation.conversationID) > -1) {
                    if(this.currentConversation.unreadCount > 0) {
                        this.$refs['current-conversation'].isAtMe = true;
                    } else {
                        this.updateAtMeData(false, this.currentConversation);
                    }
                }
                this.getMessageList({
                    isReset: true,
                    positionMessageInfo: positionMessageInfo
                });
                // this.getOpenIMMessageList({
                //     isReset: true,
                //     positionMessageInfo: positionMessageInfo
                // });
                // if(conversation.conversationType === openIM.SessionType.Group) {
                //     this.getMemberList();
                // }
                if(conversation.type === TIM.TYPES.CONV_GROUP) {
                    this.getMemberList();
                }
                if(!this.$refs['current-conversation'].isSystem) {
                    this.$refs['current-conversation'].$refs.messageSendBox.messageContent = "";
                }
            }
        },
        getOpenIMMessageList({isReset, unreadCount, flipOverType = 0, positionMessageInfo}) {
            this.currentMessageListFetched = false;
            this.flipOverType = flipOverType;
            if(isReset) {
                this.currentMessageList = [];
                this.isCompleted = false;
                this.isAfterCompleted = true;
                this.messageLoading = true;
                this.nextAfterReqMessageID = '';
                this.nextAfterReqMessageTime = '';
                this.nextReqMessageID = '';

                if(positionMessageInfo) {
                    this.isPositionMode = true;
                }else {
                    this.isPositionMode = false;
                }
            }else {
                this.messagePagingLoading = true;
            }

            // if(flipOverType == 0) {
            //                 this.currentMessageList = [...res.messageList, ...this.currentMessageList];
            //             }else {
            //                 this.currentMessageList = [...this.currentMessageList, ...res.messageList];
            //             }

            let getCount = unreadCount || 10;
            if (flipOverType == 0) {
                openIM.getHistoryMessageList({
                    groupID: this.currentConversation.groupID,
                    startClientMsgID: this.nextReqMessageID,
                    count: getCount,
                    userID: this.currentConversation.userID
                }).then(({ data })=>{
                    try {
                        let list = JSON.parse(data);
                        if (list && list.length > 0) {
                            this.nextReqMessageID = list[0].clientMsgID;
                            this.currentMessageList = [...list, ...this.currentMessageList]; 
                            //翻页定位
                            if(!isReset && !unreadCount) {
                                this.$nextTick(() =>{
                                    let index = list.length - 1;
                                    this.scrollToMessageByIndex(index);
                                })
                            }
                            //搜索到的消息定位
                            if(isReset && positionMessageInfo) {
                                let positionIndex = this.getMessageIndex(positionMessageInfo);
                                
                                if(positionIndex > -1) {
                                    this.$nextTick(() =>{
                                        this.$nextTick(() =>{
                                            this.scrollToMessageByIndex(positionIndex);
                                        })
                                    })
                                }
                            }
                        }
                        if (list && list.length < getCount) {
                            this.isCompleted = true;
                        } else {
                            this.isCompleted = false;
                        }

                        if(this.isShare) {
                            if(window.lbdTrack){
                                lbdTrack.track('IMShareCardCreate',{
                                    type: this.shareMessage.msgContent.ext.type
                                })
                            } else {
                                _tracker.track('IMShareCardCreate',JSON.stringify({
                                    type: this.shareMessage.msgContent.ext.type
                                }))
                            }
                            this.currentMessageList.push(this.shareMessage);
                            this.isShare = false;
                        }
                        if(!unreadCount) {
                            this.$refs['current-conversation'].unreadCount = this.currentConversation.unreadCount;
                        }
                        if(unreadCount) {
                            this.$refs['current-conversation'].unreadCount = 0;
                            if(this.$refs['current-conversation'].isAtMe) {
                                this.$nextTick(() => {
                                    this.$refs['current-conversation'].scrollToAtMe();
                                })
                            } else {
                                let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
                                if(messageListNode) {
                                    messageListNode.getElementsByClassName('message-wrapper')[0].scrollIntoView();
                                }
                            }
                        }
                        console.log(this);
                        // 将消息设置为已读
                        if(this.currentConversation.unreadCount > 0) {
                            // tim.setMessageRead({ conversationID: currentConversationId })
                            // .then(res => {
                            //     console.log('currentConversationId消息已读');
                            // }).catch(err => {
                            //     console.log('setMessageReadError', err);
                            // });
                            if (this.currentConversation.conversationType === openIM.SessionType.Single) {
                                openIM.markC2CMessageAsRead({
                                    userID: this.currentConversation.userID,
                                    msgIDList: []
                                }).then(({ data })=>{
                                    console.log('data');
                                }).catch(err => {
                                    console.log('setMessageReadError', err);
                                })
                            }
                            if (this.currentConversation.conversationType === openIM.SessionType.Group) {
                                openIM.markGroupMessageAsRead(this.currentConversation.groupID).then(({ data })=>{
                                    console.log('data');
                                }).catch(err => {
                                    console.log('setMessageReadError', err);
                                })
                            }
                            if (this.currentConversation.conversationType === openIM.SessionType.Notification
                                || (this.currentConversation.conversationType === openIM.SessionType.Single && this.currentConversation.userID === "openIM123456")
                            ) {
                                openIM.markNotifyMessageHasRead(this.currentConversation.conversationID).then(({ data })=>{
                                    console.log('data');
                                }).catch(err => {
                                    console.log('setMessageReadError', err);
                                })
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                    
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    this.currentMessageListFetched = true;
                }).catch(err => {
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    // if(err.errorMessage && err.errorMessage !== '查询类型不能为空') {
                    //     shortTips(err.errorMessage)
                    // }
                    console.log(err);
                })
            } else {
                openIM.getHistoryMessageListReverse({
                    groupID: this.currentConversation.groupID,
                    startClientMsgID: this.nextAfterReqMessageID,
                    count: getCount,
                    userID: this.currentConversation.userID
                }).then(({ data })=>{
                    try {
                        let list = JSON.parse(data);
                        if (list && list.length > 0) {
                            this.nextReqMessageID = list[list.length - 1].clientMsgID;
                            this.currentMessageList = [...this.currentMessageList, ...list];

                            //翻页定位
                            if(!isReset && !unreadCount) {
                                this.$nextTick(() =>{
                                    let index = this.currentMessageList.length - res.messageList.length - 1;
                                    this.scrollToMessageByIndex(index);
                                })
                            }
                            //搜索到的消息定位
                            if(isReset && positionMessageInfo) {
                                let positionIndex = this.getMessageIndex(positionMessageInfo);
                                
                                if(positionIndex > -1) {
                                    this.$nextTick(() =>{
                                        this.$nextTick(() =>{
                                            this.scrollToMessageByIndex(positionIndex);
                                        })
                                    })
                                }
                            }
                        }
                        if (list && list.length < getCount) {
                            this.isAfterCompleted = true;
                            this.isPositionMode = false;
                        } else {
                            this.isAfterCompleted = false;
                        }

                        if(this.isShare) {
                            if(window.lbdTrack){
                                lbdTrack.track('IMShareCardCreate',{
                                    type: this.shareMessage.msgContent.ext.type
                                })
                            } else {
                                _tracker.track('IMShareCardCreate',JSON.stringify({
                                    type: this.shareMessage.msgContent.ext.type
                                }))
                            }
                            this.currentMessageList.push(this.shareMessage);
                            this.isShare = false;
                        }
                        if(!unreadCount) {
                            this.$refs['current-conversation'].unreadCount = this.currentConversation.unreadCount;
                        }
                        if(unreadCount) {
                            this.$refs['current-conversation'].unreadCount = 0;
                            if(this.$refs['current-conversation'].isAtMe) {
                                this.$nextTick(() => {
                                    this.$refs['current-conversation'].scrollToAtMe();
                                })
                            } else {
                                let messageListNode = this.$refs['current-conversation'].$refs['message-list'];
                                if(messageListNode) {
                                    messageListNode.getElementsByClassName('message-wrapper')[0].scrollIntoView();
                                }
                            }
                        }
                        // 将消息设置为已读
                        if(this.currentConversation.unreadCount > 0) {
                            // tim.setMessageRead({ conversationID: currentConversationId })
                            // .then(res => {
                            //     console.log('currentConversationId消息已读');
                            // }).catch(err => {
                            //     console.log('setMessageReadError', err);
                            // });
                            if (this.currentConversation.conversationType === openIM.SessionType.Group) {
                                openIM.markGroupMessageAsRead(this.currentConversation.groupID).then(({ data })=>{
                                    console.log('data');
                                }).catch(err => {
                                    console.log('setMessageReadError', err);
                                })
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    this.currentMessageListFetched = true;
                }).catch(err => {
                    this.messageLoading = false;
                    this.messagePagingLoading = false;
                    // if(err.errorMessage && err.errorMessage !== '查询类型不能为空') {
                    //     shortTips(err.errorMessage)
                    // }
                    console.log(err);
                })
            }

        },
        updateMessagePosition({positionMessageInfo}) {
            let positionIndex = this.getMessageIndex(positionMessageInfo);;
            if(positionIndex > -1) {
                this.scrollToMessageByIndex(positionIndex);
            }else {
                this.getMessageList({
                    isReset: true,
                    positionMessageInfo: positionMessageInfo
                }); 
            }
        },
        updateAtMeData(isAddAtMe, conversation) {
            if(isAddAtMe) {
                if(this.atMeConversationList.indexOf(conversation.conversationID) === -1) {
                    this.atMeConversationList.push(conversation.conversationID);
                }
            } else {
                let atMeConversationIndex = this.atMeConversationList.indexOf(conversation.conversationID);
                if(atMeConversationIndex > -1) {
                    this.atMeConversationList.splice(atMeConversationIndex, 1);
                }
            }
        },
        enLargeDialog() {
            this.isLargeSize = !this.isLargeSize;
        },
        getMessageGroupId(message) {
            if (message.sessionType === openIM.SessionType.Single) {
                // return `c2c_${message.groupID}`;
                if (this.currentConversation.conversationID.indexOf(message.sendID) > -1 ||
                    this.currentConversation.conversationID.indexOf(message.recvID) > -1
                ) {
                    return this.currentConversation.conversationID;
                } else {
                    return '';
                }
            }
            if (message.sessionType === openIM.SessionType.Group) {
                return `group_${message.groupID}`;
            }
            return '';
        },
        updateMessageList(data) {
            let firmGroupMessageList = [];
            if(!this.currentConversation.conversationID) {
                return false;
            }
            if (Array.isArray(data)) {
                // 筛选出当前会话的消息
                const result = data.filter(item => item.conversationID.indexOf( this.currentConversation.conversationID) > -1)

                if (this.currentConversation.conversationID === `GROUP_${this.firmGroupId}`) {
                    this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                        newMessageList: result
                    });
                }

                result = result.map(item => {
                    return convertSdkMsgToLbdMsg(item)
                })
                this.currentMessageList = [...this.currentMessageList, ...result]
            } else if (data.conversationID.indexOf(this.currentConversation.conversationID) > -1) {
                if (this.currentConversation.conversationID === `GROUP_${this.firmGroupId}`) {
                    this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                        newMessageList: [data]
                    });
                }

                data = convertSdkMsgToLbdMsg(data)
                this.currentMessageList = [...this.currentMessageList, data]
            }
            // this.$nextTick(() =>{
            //     let lastIndex = this.currentMessageList.length - 1;
            //     this.$refs[`message_item_${lastIndex}`][0].$el.scrollIntoView();
            // })
        },
        clearUnsendShareMessage() {
            this.currentMessageList = this.currentMessageList.filter(message => {
                return message.status !== 'unSend'
            });
        },
        onGroupInfoChanged() {
            this.getMemberList();
        },
        async getMemberList() {
            let groupId = this.currentConversation.groupProfile.groupID,
                memberList = [];

            openIM.getGroupsInfo([groupId])
            .then(async ({ data }) => {
                console.log(data);

                if (!data || data == '[]') {
                    return false;
                }

                let count = JSON.parse(data)[0].memberCount;
                for(let i = 0; i < Math.ceil(count / 100); i++) {
                    await openIM.getGroupMemberList({
                        groupID: groupId, // 组ID
                        filter: 0, // 1普通成员, 2群主，3管理员
                        offset: 100 * i, // 偏移量，每次开始的index值
                        count: 100, // 每次拉取的数量
                    }).then((list) => {
                        list = JSON.parse(list.data);

                        list = list.map(item => {
                            return {
                                userID: item.userID,
                                nick: item.nickname,
                                avatar: `${avatarHost()}/user/${item.userID}` || item.faceURL,
                                role: item.roleLevel === 2 ? 'Owner' : ''
                            }
                        })
                        memberList = memberList.concat(list);

                        if(i === Math.ceil(count / 100) - 1) {
                            let memberIdList = [];
                            memberList.forEach(item => {
                                memberIdList.push({
                                    accountId: item.userID,
                                    type: 0
                                });
                            })
                            imService.getNameById({
                                dataList: memberIdList
                            }).then(res => {
                                res.forEach(item => {
                                    for(let i = 0; i < memberList.length; i++) {
                                        if (memberList[i].userID === item.accountId) {
                                            memberList[i].nick = item.accountUserName;
                                        }
                                    }

                                    // for(let i = 0; i < this.conversationList.length; i++) {
                                    //     if(this.conversationList[i].type === TIM.TYPES.CONV_GROUP && this.conversationList[i].lastMessage.fromAccount === item.accountId) {
                                    //         this.$set(this.conversationList[i].lastMessage, 'fromAccoutName', item.accountUserName)
                                    //     }
                                    // }
                                })



                                
                                memberList.unshift({
                                    userID: "",
                                    nick: "所有人",
                                    avatar: require('@src/assets/images/default-user.png')
                                });

                                this.memberList = memberList;
                            }).catch(err => {
                                console.log("getNameByIdError:", err);

                                memberList.unshift({
                                    userID: "",
                                    nick: "所有人",
                                    avatar: require('@src/assets/images/default-user.png')
                                });

                                this.memberList = memberList;
                            })

                            // memberList.unshift({
                            //     userID: "",
                            //     nick: "所有人",
                            //     avatar: require('@src/assets/images/default-user.png')
                            // });

                            // this.memberList = memberList;
                        }
                    }).catch(err => {
                        console.log("getGroupMemberListError: ", err);
                    })
                    // await tim.getGroupMemberList({
                    //     groupID: group.groupID,
                    //     count: 100,
                    //     offset: 100 * i
                    // }).then(({ data }) => {
                    //     list = list.concat(data.memberList);
                    //     if(i === Math.ceil(count / 100) - 1) {
                    //         list.unshift({
                    //             userID: "",
                    //             nick: "所有人",
                    //             avatar: require('@src/assets/images/default-user.png')
                    //         });
                    //         this.memberList = list;
                    //     }
                    // }).catch(err => {
                    //     console.log("getGroupMemberListError: ", err);
                    // })
                }
            });

        },
        checkInner(conversation) {
            if(conversation.type === TIM.TYPES.CONV_SYSTEM) {
                return false;
            }
            if(conversation.type === TIM.TYPES.CONV_C2C) {
                return this.innerLabelMap[conversation.userProfile.userID] === 1;
            }
            if(conversation.type === TIM.TYPES.CONV_GROUP) {
                return this.innerLabelMap[conversation.groupProfile.groupID] === 1;
            }
        },
        quitGroup() {
            this.updateCurrentConversation({
                conversation: {}
            });
            this.isCompleted = false;
            this.currentMessageListFetched = false;
            this.getAllConversationList({isUpdate: true});
            // this.$nextTick(() => {
            //     debugger;
            //     let conversation = this.conversationList.find(item => item.type !== TIM.TYPES.CONV_SYSTEM)
            //     if(conversation) {
            //         this.$refs.sideBar.selectConversation(conversation, true);
            //     } else {
            //         this.updateCurrentConversation({
            //             conversation: {}
            //         });
            //     }
            // })
        },
        async updateOnlineStatus() {
            let C2CUserIds = [];
            this.conversationList.forEach(conversation => {
                if(conversation.type === TIM.TYPES.CONV_C2C && conversation.conversationID !== 'C2C_openIM123456') {
                    C2CUserIds.push(conversation.userProfile.userID);
                }
            });
            if(C2CUserIds.length) {
                let checkSig = await this.refreshSignature();
                if (!checkSig) {
                    shortTips("未知异常，请刷新页面重试！");
                    return false;
                }
                
                let onlineList = [];
                imService.getUserOnlineStatus({
                    userIDList: C2CUserIds,
                    token: localStorage.getItem('imUserSig'),
                }).then(result => {
                    if(result && result.length) {
                        result.forEach(item => {
                            if(item.status === 'online') {
                                onlineList.push(item.userID);
                            }
                        });
                        this.onlineUserList = onlineList;
                    } else {
                        this.onlineUserList = [];
                    }
                }).catch(err => {
                    console.log('getUserOnlineStatusError', err);
                });
                
            }
        },
        logout() {
            // if(this.isLogin) {
                // tim.logout().then(res => {
                //     console.log('IMLogoutSuccess')
                // }).catch(err => {
                //     console.log('IMLogoutError', err)
                // })
            // }

            if(this.isLogin) {
                openIM.logout().then(res => {
                    console.log("open im logout suc...");
                }).catch(err => {
                    console.log("open im logout failed...");
                })
            }
        }
    }
}
</script>

<style lang="scss">
.disable-link{
    pointer-events: none;
}
</style>
<style lang="scss" scope>
.instant-message {
    line-height: 18px;

    &.is-home {
        height: 100%;
        overflow: hidden;
    }

    &-btn-container {
        background-color: #fff;
        position: fixed;
        z-index: 2000;
        right: 40px;
        bottom: 0;
        width: 180px;
        height: 32px;
        border-radius: 4px 4px 0 0;
        border: 1px solid $primary;
        border-bottom: none;
        overflow: hidden;
        .instant-message-btn {
            width: 100%;
            height: 100%;
            background-color: #eaf9f6;
            font-size: 12px;
            color: $primary;
            padding-left: 10px;
            line-height: 32px;
            letter-spacing: 0.1em;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.active {
                animation: flicker-orange 3s linear;
                animation-fill-mode: forwards;
            }
            .im-btn-icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
                background: url('~@src/assets/images/icon/icon-instant-message.svg') no-repeat;
                background-size: contain;
            }
            .im-num {
                margin-left: 84px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #E24E3A;
                font-size: 12px;
                color: #fff;
            }
            &:focus {
                outline: 0;
            }
        }
    }
    &-container {
        width: 660px;
        height: 450px;
        display: flex;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
        border-radius: 4px 4px 0 0;
        transition: all .4s ease-out;
        position: fixed;
        bottom: 0;
        right: 40px;
        z-index: 1002;
        &.in-new-project {
            top: 55px;
            right: 60px;
            bottom: unset;
            &::before {
                content: "";
                width: 14px;
                height: 14px;
                background-color: $primary;
                transform: rotate(45deg);
                transform-origin: right top;
                position: absolute;
                top: 0;
                right: 30px;
            }
        }
        &.large-size {
            width: 742px;
            height: 730px;
            transition: all .4s ease-out;
        }

        &.is-home-page {
            position: initial;
            box-shadow: none;
            width: auto;
            height: 100%;

            .current-conversation-wrapper {
                min-width: initial;

                .conversation-header-bar {
                    color: #666;
                    background-color: #f6f6f6;

                    .conversation-info {
                        margin: 0 auto;
                    }

                    .instant-message-operate {
                        display: none;
                    }
                }
            }

            .im-side-bar {
                width: 200px;
                min-width: initial;
                flex-shrink: 0;

                .header-bar {
                    display: none;
                }

                .conversation-list .conversation-info {
                    max-width: 146px!important;
                }

                .conversation-list-wrapper .conversation-header .search-input .el-input__inner {
                    width: 142px;
                }
            }
        }

        ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }
        ::-webkit-scrollbar-thumb {
            min-height: 80px;
            box-shadow: 0 0 0 5px rgba(198, 199, 198, 0.5) inset;
        }
        ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
            border-radius: 999px;
            border: 3px solid transparent;
        }
    }
}
@media screen and (max-height: 730px){
    .instant-message-container.large-size {
        height: 100%;
    }
}
</style>
<style lang="scss">
@keyframes flicker-orange {
    0% {
        background: #F5A623;
    }
    25% {
        background: #EAF9F6;
    }
    50% {
        background: #F5A623;
    }
    75% {
        background: #EAF9F6;
    }
    100% {
        background: #F5A623;
    }
}
</style>