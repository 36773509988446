<template>
    <div class="im-side-bar">
        <div class="header-bar" @click="$emit('closeInstantMessage')">
            <span @click.stop>消息</span>
            <el-popover
                ref="msgNotifiedPopper"
                trigger="click"
                @click.native.stop
                placement="bottom-end"
                :width="164"
                :offset="20"
                popper-class="message-notified-popper">
                <div class="message-notified-set">
                    消息通知
                    <el-switch
                        v-model="isMessageNotified"
                        active-color="#38bc9d"
                        inactive-color="#bfbfbf"
                        @change="handleMessageNotifiedSet"
                    ></el-switch>
                </div>
                <el-button slot="reference" class="message-notified-set-btn" title="设置消息通知" icon="el-icon-setting"></el-button>
            </el-popover>
        </div>
        <div class="conversation-list-wrapper">
            <div class="conversation-header">
                <el-input 
                    class="search-input" 
                    name="im-search-input"
                    placeholder="搜索" 
                    prefix-icon="el-icon-search" 
                    v-model.trim="keyword"
                    :maxlength="100"
                    @change="handleSearch"
                ></el-input>
                <span v-show="isSearchMode" class="search-input-clear el-icon-error" @click="handleClearSearch"></span>
                <el-tooltip
                    effect="light"
                    content="发起群聊"
                    placement="bottom"
                    popper-class="create-group-tip">
                    <el-button
                        class="create-group-btn" 
                        icon="el-icon-plus" 
                        @click="showCreateGroupDialog(false)"
                    ></el-button>
                </el-tooltip>
            </div>
            <div v-show="!isSearchMode" class="conversation-list-container" :style="isLargeSize ? 'max-height: 632px' : ''">
                <div class="conversation-list" v-if="conversationList.length">
                    <template v-for="(conversation, index) in conversationList">
                        <conversation-item
                            v-if="conversation.type != TIM.TYPES.CONV_SYSTEM"
                            :key="index"
                            :userId="userId"
                            :checkResult="checkTypeResult(conversation)"
                            :isOnline="checkOnline(conversation)"
                            :conversation="conversation"
                            :isCompany="conversation.type === TIM.TYPES.CONV_GROUP && companyGroupId === conversation.groupProfile.groupID"
                            :maxWidth="isLargeSize ? (conversationList.length > 9 ? '220px' : '226px') : (conversationList.length > 5 ? '220px' : '226px')"
                            :currentConversationID="currentConversationID"
                            :atMeConversationList="atMeConversationList"
                            @handleCurrentConversation="selectConversation">
                        </conversation-item>
                    </template>
                </div>
                <div class="empty-data" v-else>
                    暂无数据
                </div>
            </div>
            <div v-if="isSearchMode" class="search-result-container" :style="isLargeSize ? 'height: 632px' : ''">
                <search-result
                    :searchResultObject="searchResultObject" 
                    :isSearchEnd="isSearchEnd"
                    @handleCurrentConversation="searchResultClick"
                    :keyword="keyword"
                    @handlePositionMessage="positionMessage"
                ></search-result>
            </div>
        </div>
        <create-group-dialog
            :userId="userId"
            ref="createGroupDialog"
            @selectConversation="selectConversation">
        </create-group-dialog>
    </div>
</template>

<script>
import ConversationItem from './conversation/conversation-item.vue';
import CreateGroupDialog from './group/create-group-dialog.vue';
import SearchResult from './search/search-result.vue';
import imService from '#/js/service/openIMService.js';
import { convertConversation } from './utils/convertIM';
export default {
    props: {
        conversationList: Array,
        isLargeSize: Boolean,
        userId: String,
        currentConversationID: String,
        atMeConversationList: Array,
        innerLabelMap: Object,
        companyGroupId: String,
        onlineUserList: Array
    },
    components: {
        ConversationItem,
        CreateGroupDialog,
        SearchResult
    },
    data() {
        return {
            isMessageNotified: false,
            isSearchMode: false,
            keyword: '',
            searchResultObject: {},
            isSearchEnd: false,
            searchId: ""
        }
    },
    mounted() {
        if(localStorage.getItem('isMessageNotified') && localStorage.getItem('isMessageNotified') == 'true') {
            this.isMessageNotified = true;
        }
    },
    methods: {
        //设置是否要开启浏览器推送
        handleMessageNotifiedSet() {
            if(this.isMessageNotified) {
                if(window.lbdTrack) {
                    lbdTrack.track('IMBrowerNotifiedOpen', {})
                } else {
                    _tracker.track('IMBrowerNotifiedOpen')
                }
            }
            localStorage.setItem('isMessageNotified', this.isMessageNotified);
        },
        selectConversation(conversation, isScrollIntoView) {
            // this.selectConversationById({
            //     conversationId: conversation.conversationID, 
            //     isScrollIntoView: isScrollIntoView
            // });

            if(this.currentConversationID !== conversation.conversationID) {
                this.$emit('updateCurrentConversation', {
                    conversation: conversation
                });

                this.$emit('updateAtMeData', false, {
                    conversationID: conversation.conversationID
                })
            }
            if (isScrollIntoView) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        document.querySelector('.conversation-item.choose') && document.querySelector('.conversation-item.choose').scrollIntoView();
                    })
                })
            }
        },
        selectSingleConversation(conversationId) {
            openIM.getOneConversation({
                sourceID: conversationId.replace('C2C_', ''),
                sessionType: 1
            }).then(({ data })=>{
                try {
                    this.$emit('updateCurrentConversation', {
                        conversation: convertConversation(JSON.parse(data)),
                        positionMessageInfo: null
                    });
                    this.$nextTick(() => {
                        document.querySelector('.conversation-item.choose') && document.querySelector('.conversation-item.choose').scrollIntoView();
                    })
                } catch (err) {
                    console.log(err);
                }
            })
        },
        selectConversationById({conversationId, isScrollIntoView, positionMessageInfo}) {
            let sourceId = '', sessionType = 1;
            if (conversationId.indexOf('C2C_') > -1) {
                sourceId = conversationId.replace('C2C_', '');
            }
            if (conversationId.indexOf('GROUP_') > -1) {
                sourceId = conversationId.replace('GROUP_', '');
                sessionType = 2;
            }
            if(this.currentConversationID !== conversationId) {
                openIM.getOneConversation({
                    sourceID: sourceId,
                    sessionType: sessionType
                }).then(({ data })=>{
                    try {
                        this.$emit('updateCurrentConversation', {
                            conversation: convertConversation(JSON.parse(data)),
                            positionMessageInfo: positionMessageInfo
                        });
                        if(isScrollIntoView) {
                            this.$nextTick(() => {
                                document.querySelector('.conversation-item.choose') && document.querySelector('.conversation-item.choose').scrollIntoView();
                            })
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.$emit('updateAtMeData', false, {
                        conversationID: conversationId
                    })
                })
                // tim.getConversationProfile(conversationId)
                // .then(({ data }) => {
                //     this.$emit('updateCurrentConversation', {
                //         conversation: data.conversation,
                //         positionMessageInfo: positionMessageInfo
                //     });
                //     if(isScrollIntoView) {
                //         this.$nextTick(() => {
                //             document.querySelector('.conversation-item.choose') && document.querySelector('.conversation-item.choose').scrollIntoView();
                //         })
                //     }
                // }).catch(err => {
                //     console.log(err);
                // }).finally(() =>{
                //     this.$emit('updateAtMeData', false, {
                //         conversationID: conversationId
                //     })
                // })
            }else {
                if(isScrollIntoView) {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            document.querySelector('.conversation-item.choose') && document.querySelector('.conversation-item.choose').scrollIntoView();
                        })
                    })
                }
                positionMessageInfo 
                && this.$emit('updateMessagePosition', {
                    positionMessageInfo: positionMessageInfo
                });
            }
        },
        searchResultClick(conversation) {
            if(window.lbdTrack) {
                lbdTrack.track('IMSideBarSearchResultClick', {
                    searchId: this.searchId
                })
            } else {
                _tracker.track('IMSideBarSearchResultClick', JSON.stringify({
                    searchId: this.searchId
                }))
            }
            // this.selectConversationById({
            //     conversationId : conversationId,
            //     isScrollIntoView: true
            // });

            conversation = convertConversation(conversation);

            this.selectConversation(conversation, true);

            this.handleClearSearch();
        },
        positionMessage(record) {
            let conversationId = '';
            if(record.type == 0) {
                if(this.userId == record.fromAccountId) {
                    conversationId = 'C2C_' + record.toAccountId;
                }else {
                    conversationId = 'C2C_' + record.fromAccountId;
                }
            }else {
                conversationId = 'GROUP_' + record.groupId;
            }
            this.selectConversationById({
                conversationId: conversationId,
                isScrollIntoView: true,
                positionMessageInfo: record
            })
            this.keyword = '';
            this.isSearchMode = false;
        },
        checkTypeResult(conversation) {
            if(conversation.type === TIM.TYPES.CONV_SYSTEM) {
                return false;
            }
            if(conversation.type === TIM.TYPES.CONV_C2C) {
                return this.innerLabelMap[conversation.userProfile.userID];
            }
            if(conversation.type === TIM.TYPES.CONV_GROUP) {
                return this.innerLabelMap[conversation.groupProfile.groupID];
            }
        },
        showCreateGroupDialog(isAdd, group) {
            this.$refs.createGroupDialog.show(isAdd, group);
        },
        handleSearch() {
            if(this.keyword) {
                this.searchId = _generateUUID();
                if(window.lbdTrack) {
                    lbdTrack.track('IMSideBarSearch', {
                        searchId: this.searchId
                    })
                } else {
                    _tracker.track('IMSideBarSearch', JSON.stringify({
                        searchId: this.searchId
                    }))
                }
                this.isSearchEnd = false;
                imService.historySearch({
                    keywords: [this.keyword],
                    pageNo: 1,
                    pageSize: 20,
                    searchType: 0,
                    needToSearchJdtMember: true
                }).then(res =>{
                    this.searchResultObject = res;
                }).finally(() => {
                    this.isSearchEnd = true;
                })
                this.isSearchMode = true;
            }else {
                this.isSearchMode = false;
            }
        },
        handleClearSearch() {
            this.isSearchMode = false;
            this.keyword = '';
            this.searchResultObject = {};
            this.isSearchEnd = false;
        },
        checkOnline(conversation) {
            return conversation.type === TIM.TYPES.CONV_C2C && this.onlineUserList.some(userId => userId === conversation.userProfile.userID);
        }
    }
}
</script>

<style lang="scss" scope>
.im-side-bar {
    min-width: 280px;
    width: 280px;
    height: 100%;
    .header-bar {
        background: #38BC9D;
        height: 40px;
        padding: 0 6px 0 12px;
        line-height: 40px;
        color: #fff;
        display: flex;
        justify-content: space-between;
    }
    .message-notified-set-btn.el-button {
        color: #fff;
        font-size: 14px;
        width: 20px;
        min-width: unset;
        height: 20px;
        padding: 0;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        line-height: 18px;
        border: none;
        &:active, &:hover, &:focus {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    .conversation-list-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
        .conversation-header {
            position: relative;
            width: 100%;
            height: 58px;
            padding: 12px;
            padding-right: 8px;
            .search-input {
                width: auto;
                .el-input__inner {
                    width: 222px;
                    height: 34px;
                    line-height: 32px;
                    background-color: #f6f6f6;
                    border-radius: 2px;
                    border: 1px solid #eee;
                }
                .el-input__icon {
                    line-height: 36px;
                }
            }
            .search-input-clear {
                position: absolute;
                top: 50%;
                right:50px;
                margin-top: -7px;
            }
            .create-group-btn {
                width: 30px;
                min-width: unset;
                height: 26px;
                margin-left: 8px;
                background-color: #fff;
                padding: 0;
                line-height: 26px;
                text-align: center;
                border: 1px solid #CFCFCF;
                border-radius: 6px;
                color: #333;
            }
        }
        .conversation-list-container {
            max-height: 352px;
        }
        .search-result-container {
            height: 352px;
        }
        .conversation-list-container, .search-result-container {
            flex-grow: 1;
            transition: all .4s ease-out;
        }
        .conversation-list-container {
            overflow: hidden;
            overflow-y: auto;
            .empty-data {
                width: 100%;
                text-align: center;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>

<style lang="scss">
.message-notified-popper.el-popper {
    .message-notified-set {
        color: #444;
        display: flex;
        justify-content: space-between;
    }
}
.create-group-tip.el-tooltip__popper.is-light {
    margin-left: 0;
    padding: 0;
    width: 100px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    color: #333;
}
</style>
