import axios from 'axios'
import CertificationService from '#/js/service/certificationService.js';
import { getCookie, _getCookie } from './global';

const service = axios.create();
// 是否正在刷新的标记
let isRefreshing = false
//重试队列
let requests = []


const refreshToken = () => {
    const params = {
        userName: localStorage.getItem('userName'),
        refreshToken: localStorage.getItem('refreshToken'),
    };
    return new Promise((resolve, reject) => {
        CertificationService
            .refreshToken(params)
            .then(res => {
                console.log(`refresh-----------`, res)
                console.log(JSON.stringify(res))
                setCookie('access_token', res.accessToken, (res.expiresIn || 3600) - 5 * 60);
                // setCookie('access_token', res.accessToken, 60);
                // console.log(res?.accessToken)

                localStorage.setItem('userName', res?.userName);
                localStorage.setItem('refreshToken', res?.refreshToken);
                resolve(res);
            })
            .catch(err => {
                console.log(`Refresh---------------------error`)
                console.log(err)
                console.log(JSON.stringify(err));
                reject(err);
            })
    });
}

// 请求拦截器
service.interceptors.request.use(
    config => {
        // console.log(`config-------------------------1`)
        // console.log(config)

        const whiteListUrl = [
            '/openapi-unauth/Account/GetToken',
            '/Antiforgerytoken',
            // `/openapi/AuthAccount/LoginedUserInfo`,
            `/openapi-unauth/Account/RefreshToken`,
            `/openapi-unauth/Account/LoginOut`,
            `/Account/ForgotPasswordApi`,
            `/openapi-unauth/Account/ValidateMobile`,
            `/openapi-unauth/Account/Register`,
            `/openapi-unauth/Account/WeComStateInfo`,
            `/openapi-unauth/Account/WeComLogin`,
            '/openapi-unauth/Account/WeComBindLogin',
        ];

        //给请求头添加token
        // if (response.data.code === 401) {
        // console.log(config.url)
        // console.log(getCookie('access_token'))
        // console.log(!getCookie('access_token'))
        // console.log(!getCookie('access_token') && !whiteListUrl.some(item => config.url.includes(item)))
        // console.log(!whiteListUrl.some(item => config.url.includes(item)))

        if (!getCookie('access_token') && !whiteListUrl.some(item => config.url.includes(item))) {
            if (!isRefreshing) {
                isRefreshing = true
                //调用刷新token的接口
                return refreshToken()
                .then(res => {
                    // const { token } = res.data
                    // // 替换token
                    // setToken(token)
                    // response.headers.Authorization = `${token}`
                    // token 刷新后将数组的方法重新执行
                    requests.forEach((cb) => cb(_getCookie('access_token')))
                    requests = [] // 重新请求完清空

                    let _config = config;
                    if(_config.headers.hasOwnProperty('AuthorizationLBD')) {
                        _config.headers.AuthorizationLBD = `${_getCookie('access_token')}`;
                    }
                    if(_config.headers.hasOwnProperty('Authorization')) {
                        _config.headers.Authorization = `${_getCookie('access_token')}`;
                    }
                    if(_config.headers.hasOwnProperty('AuthorizationTag')) {
                        _config.headers.AuthorizationTag = `${_getCookie('access_token')}`;
                    }
                    if(_config.headers.hasOwnProperty('AuthorizationIM')) {
                        _config.headers.AuthorizationIM = `${_getCookie('access_token')}`;
                    }
                    // service(_config)
                    return _config;
                }).catch(err => {
                    //跳到登录页
                    // removeToken()
                    console.log(`err：` + err);
                    clearCookie('access_token');
                    // router.push('/login')
                    location.href = '/Account/#/login?ReturnUrl=' + encodeURIComponent(location.pathname + location.search + location.hash)
                    return Promise.reject(err)
                }).finally(() => {
                    isRefreshing = false;
                    // TODO:这个重新刷新相当的坑，目前没有好办法，只能这样先凑合[不能定位到以前那个实体]
                    // location.reload();
                })
            } else {
                // 返回未执行 resolve 的 Promise
                // 必须return Promise而不是return config，否则请求将会直接执行
                return new Promise(resolve => {
                    // 用函数形式将 resolve 存入，等待刷新后再执行
                    requests.push(token => {
                   
                        let _config = config;
                        if(_config.headers.hasOwnProperty('AuthorizationLBD')) {
                            _config.headers.AuthorizationLBD = `${token}`;
                        }
                        if(_config.headers.hasOwnProperty('Authorization')) {
                            _config.headers.Authorization = `${token}`;
                        }
                        if(_config.headers.hasOwnProperty('AuthorizationTag')) {
                            _config.headers.AuthorizationTag = `${token}`;
                        }
                        if(_config.headers.hasOwnProperty('AuthorizationIM')) {
                            _config.headers.AuthorizationIM = `${token}`;
                        }
                        
                        resolve(_config);
                        // return _config;
                    })
                })
                // return config;
            }
        }

        return config
    },
    error => {
        // 处理请求错误
        return Promise.reject(error)
    }
)


export default service;