//依赖模块：request.js

export default class track{
    pageCode = '';

    constructor(config = { }){
        this.pageCode = config.pageCode || '';
        this.init();
    }

    init() {
        let self = this;
        document.addEventListener('click', (e) => {
            self.recursiveCheckTrackId(e.target);
        }, false)
    }

    // 递归检测元素或者其祖先节点是否拥有trackId属性
    recursiveCheckTrackId(el) {
        let dataSet = this.getDataSet(el);
        if(dataSet.trackId) {
            this.track(dataSet.trackId, dataSet.trackData);
        }else if(el.parentNode && el.parentNode.tagName && el.parentNode.tagName.toUpperCase() != 'BODY') {
            this.recursiveCheckTrackId(el.parentNode);
        }
    }

    getDataSet(el) {
        if(el.dataset) {
            return el.dataset;
        }

        let  result = {};
        Array.prototype.forEach.call(el.attributes, (attr) => {
            if(attr.name.indexOf('data-') === 0) {
                let nodeNames = attr.name.slice(5).split('-');
                let nodeName = nodeNames[0];
                nodeNames.slice(1).forEach(name => {
                    if(name) {
                        nodeName += name.slice(0,1).toUpperCase() + name.slice(1);
                    }
                })
                result[nodeName] = attr.value;
            }
        })
        return result;
    }
 
    track (eventCode, eventData){
        eventData = eventData || JSON.stringify({});
        _request && _request({
            url: "/UPA/events",
            method: 'POST',
            throwBusinessError: true,
            throwHttpError: true,
            data: {
                pageCode: this.pageCode,
                eventCode: eventCode,
                eventData: eventData
            }
        })
    }

    trackPageView (pageViewData = {}) {
        _request && _request({
            url: "/upa/pageview",
            method: 'POST',
            throwBusinessError: true,
            throwHttpError: true,
            data: pageViewData
        })
    }

    setPageCode (val){
        this.pageCode = val;
    }

    getPageCode (){
        return this.pageCode;
    }
}
