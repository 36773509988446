var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog", class: _vm.isShow ? "show" : "hide" },
    [
      _c("div", { staticClass: "screen" }),
      _c("div", { staticClass: "dialog-container" }, [
        _c("div", [
          _c("header", [
            _c("div", { staticClass: "table-center header" }, [
              _c("div", [
                _vm._v("系统已经帮你找到"),
                _c("span", { staticClass: "color-ori" }, [
                  _vm._v(_vm._s(_vm.listData.length)),
                ]),
                _vm._v("位"),
              ]),
              _c("div", [_vm._v("高质量候选人，快来试试吧")]),
            ]),
          ]),
          _c("div", {
            staticClass: "icon-new icon-close",
            on: { click: _vm.hide },
          }),
        ]),
        _c(
          "div",
          { staticClass: "candidate-list" },
          _vm._l(_vm.listData, function (item, index) {
            return _c(
              "el-container",
              { key: index, staticClass: "candidate-item" },
              [
                _c(
                  "el-main",
                  [
                    _c(
                      "el-row",
                      { staticClass: "first-info" },
                      [
                        _c("span", { staticClass: "user-info-name" }, [
                          _vm._v(_vm._s(item.candidateName)),
                        ]),
                        _c("span", { staticClass: "user-info-jobTitle" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        !item.isTracking
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAddTrackingList(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _vm._v(
                                  "\n                            加入Tracking List\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.isTracking
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleViewTrackingList(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-check" }),
                                _vm._v(
                                  "\n                            查看Tracking List\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("el-row", [
                      _c("span", { staticClass: "user-info-location" }, [
                        _vm._v(_vm._s(item.city)),
                      ]),
                      _vm._v(" |\n                        "),
                      _c(
                        "span",
                        { staticClass: "user-info-company text-ellipsis" },
                        [_vm._v(_vm._s(item.company))]
                      ),
                    ]),
                    _c("el-row", [
                      item.school
                        ? _c("span", { staticClass: "user-info-school" }, [
                            _vm._v(_vm._s(item.school) + " |"),
                          ])
                        : _vm._e(),
                      item.degree
                        ? _c("span", { staticClass: "user-info-title" }, [
                            _vm._v(_vm._s(item.degree) + " |"),
                          ])
                        : _vm._e(),
                      item.yearOfExperience
                        ? _c("span", { staticClass: "user-info-exp" }, [
                            _vm._v(_vm._s(item.yearOfExperience) + "年"),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-aside",
                  { attrs: { width: "278px" } },
                  [
                    _c("el-row", { staticClass: "user-info-updatetime" }, [
                      _c("span", [
                        _vm._v(
                          "最近更新：" +
                            _vm._s(_vm._f("momentFormatDate")(item.updated))
                        ),
                      ]),
                    ]),
                    item.operationStatus !== 2 && item.operationStatus !== 3
                      ? _c(
                          "el-row",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.unSuitable(item)
                                  },
                                },
                              },
                              [_vm._v("暂不符合")]
                            ),
                            item.operationStatus === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toView(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            立即看看\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.operationStatus === 4
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "el-button--surprise",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toView(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            立即查看，有惊喜\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.operationStatus === 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "el-button--warning",
                                    on: {
                                      click: function ($event) {
                                        return _vm.recommend(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            推荐试试\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("el-row", { staticClass: "text-right" }, [
                          item.operationStatus === 2
                            ? _c(
                                "span",
                                { staticClass: "recommend icon icon-resolve" },
                                [_vm._v("已推荐")]
                              )
                            : _vm._e(),
                          item.operationStatus === 3
                            ? _c("span", { staticClass: "unsuitable" }, [
                                _vm._v("暂不符合"),
                              ])
                            : _vm._e(),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }