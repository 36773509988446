<template>
    <div class="notice-opr" v-loading="loading">
        <span class="opr-btn done-btn" @click="handleFinish" v-if="noticeItem.status!=1">完成</span>
        <span class="opr-btn del-btn" @click="handleDelete">删除</span>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import emitter from '#/js/mixins/emitter.js';
import moment from 'moment';
export default {
    name:'noticeOpr',
    props:{
        noticeItem:{
            type:Object
        },
        index:{
            type: Number
        }
    },
    mixins: [emitter],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        handleFinish(){
            _tracker.track('TalentPoolExecuteOperation', JSON.stringify({
                operationType: 'resolveReminder',
                operationText: '处理事项提醒',
                resolveReminderType: 'complete'
            }));
            this.loading = true;
            _request({
                method: 'POST',
                url: `/Resume/Reminder/${this.noticeItem.id}/Complete`
            }).then(res => {
                this.loading = false;
                this.$emit('handleDone', this.index);
                this.reduceCount();
            }).catch(err => {
                console.log(err)
                this.loading = false;
            })
        },
        handleDelete(){
            _tracker.track('TalentPoolExecuteOperation', JSON.stringify({
                operationType: 'resolveReminder',
                operationText: '处理事项提醒',
                resolveReminderType: 'delete'
            }));
            this.$confirm('是否确定删除提醒?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                _request({
                    method: 'POST',
                    url: `/Resume/Reminder/${this.noticeItem.id}/Remove`
                }).then(res => {
                    this.loading = false;
                    this.$emit('handleDel',this.index);
                    this.$emit('keepVisible');
                    if(this.noticeItem.status!=1){
                        this.reduceCount();
                    }
                }).catch(err => {
                    console.log(err)
                    this.loading = false;
                })
            }).catch(() => {
                this.$emit('keepVisible');
            });
        },
        reduceCount(){
            let today = moment().format('YYYY-MM-DD').replace(/\-/ig, '\/');
            if(new Date(moment(this.noticeItem.remindTime).format('YYYY-MM-DD').replace(/\-/ig, '\/')).toLocaleDateString() == new Date(today).toLocaleDateString()){
                let noticeCount = this.$store.state.notice.noticeCount;
                this.invokeSetNoticeCount(noticeCount-1);
            }
        },
        ...mapActions('notice',[
            'invokeSetNoticeCount'
        ])
    },
}
</script>
<style lang="scss" scoped>
.notice-opr{
    font-size: 12px;
    .opr-btn{
        display: inline-block;
        margin-right: 40px;
        cursor: pointer;
        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
        &.done-btn{
            color: #38BC9D;
        }
        &.del-btn{
            color: #999;
        }
    }
}
</style>
