//日期处理
Date.prototype.format = function (format) {
    var o = {
        "M+": this.getMonth() + 1, //month
        "d+": this.getDate(), //day
        "h+": this.getHours(), //hour
        "m+": this.getMinutes(), //minute
        "s+": this.getSeconds(), //second
        "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
        "S": this.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(format))
            format = format.replace(RegExp.$1,
                RegExp.$1.length == 1 ? o[k] :
                    ("00" + o[k]).substr(("" + o[k]).length));
    return format;
}

export const formatTime = (val) => {
    var re = /-?\d+/;
    var m = re.exec(val);
    var d = new Date(parseInt(m[0]));
    return d.format("yyyy-MM-dd hh:mm:ss");
}

export const arrayIntersect = (arr) => {
    let result = new Array();
    let obj = {};
    let length = arr.length;
    if (length > 1) {
        for (var i = 0; i < length; i++) {
            for (var j = 0; j < arr[i].length; j++) {
                var str = arr[i][j];
                if (!obj[str]) {
                    obj[str] = 1;
                } else {
                    obj[str]++;
                    if (obj[str] == length) {
                        result.push(str);
                    }
                }
            }
        }
        return result;
    }
    return arr[0];
}

export const getWorkAreas = (arr) => {
    if(!arr) return;
    if (arr.length) {
        let arrNew = new Array();
        for (var i = 189; i <= 220; i++) {
            arrNew[i - 189] = arr[i];
        }
        arrNew.push(arr[949], arr[950], arr[954]);
        return arrNew;
    }
    return []
}

export const getHotWorkAreas = (arr) => {
    if(!arr) return;
    if (arr.length) {
        let arrNew = new Array();
        for (var i = 190; i <=220; i++) {
            arrNew[i - 190] = arr[i];
        }
        arrNew.push(arr[949], arr[950], arr[954]);
        return arrNew;
    }
    return []
}

export const getAreaName = (areaArr, areaId) => {
    let areaName = '';
    areaArr.map(function (item) {
        if (item.id === areaId) {
            areaName = item.name;
            return;
        }
        for (let list in item.children) {
            let data = item.children[list];
            if (data.id == areaId) {
                areaName = data.name;
                break;
                return;
            }
        }
    })
    return areaName;
}

export const getCookieSort = (cookieArr) => { //排序cookie值重复次数
    let areaIdArr = cookieArr.split(',');
    let numArr = [];
    let temp = "";
    let count = 0;
    for (var i = 0; i < areaIdArr.length; i++) {
        if (areaIdArr[i] != -1) {
            temp = areaIdArr[i];
            for (var j = 0; j < areaIdArr.length; j++) {
                if (temp === areaIdArr[j]) {
                    count++;
                    areaIdArr[j] = -1;//这个时候临时改变这次循环时元素变量的值，以便不参与下次循环
                }
            }
            numArr.push({
                id: temp,
                num: count
            });
            count = 0;
        }
    }
    numArr.sort(function (a, b) { //排序
        return b.num - a.num
    })
    return numArr
}
/**
 * 对象数组的深度拷贝.
 * source是原数据，extendObj是新增的键值对
 */
export const objArrDeepCopy = (source,extendObj) => {
    var sourceCopy = source instanceof Array ? [] : {};
    for (var item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? objArrDeepCopy(source[item],extendObj) : source[item];
        if(typeof extendObj === 'object'&& !(sourceCopy instanceof Array)){
            for(var param in extendObj){
                sourceCopy[param] = extendObj[param];
            }
        }
    }
    return sourceCopy;
}

/**
 * 去掉html标签
 * @returns {string}
 */
String.prototype.stripHTML = function() {
    var reTag = /<(?:.|\s)*?>/g;
    return this.replace(reTag,"");
}

/**
 * 提取url搜索字段
 */
export const getQueryString = function(name,hash) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    if(hash){
        if(!window.location.hash){
            return '';
        };
        var r = decodeURIComponent(window.location.hash).substr(1).match(reg);
    }else{
        var r = decodeURIComponent(window.location.search).substr(1).match(reg);
    }
    if (r != null) {
        return r[2];
    }
    return null;
}
//提取查询参数
export const getUrlParam = function(name){
    let result = '';
    let searchURL = window.location.search;
    searchURL = searchURL.substring(1, searchURL.length);
    let paramsArr = searchURL.split("&");
    for(var i=0, item; item=paramsArr[i]; i++){
        if(item.split('=')[0]===name){
            result = item.split('=')[1];
        }
    }
    return result;
}
/*
* 频率控制
*/
export const throttle = function (func, wait) {
    var ctx, args, rtn, timeoutID; // caching
    var last = 0;
  
    return function throttled () {
      ctx = this;
      args = arguments;
      var delta = new Date() - last;
      if (!timeoutID)
        if (delta >= wait) call();
        else timeoutID = setTimeout(call, wait - delta);
      return rtn;
    };
  
    function call () {
      timeoutID = 0;
      last = +new Date();
      rtn = func.apply(ctx, args);
      ctx = null;
      args = null;
    }
};
//错误处理
export function errorMessage(result, delimiter) {
    delimiter = delimiter ? delimiter : ",";

    if (typeof result === "string") {
        try {
            result = JSON.parse(result);
        } catch (e) {
            result = {
                message: '操作失败，请重试！'
            };
        }
    }

    var resultData = result.data;
    var resultMessage = result.message || result.msg;

    // 1, 2
    if (resultMessage) {
        return resultMessage;
    }

    if (resultData) {

        // 3
        if (resultData.code && resultData.message) {
            return resultData.message;

            // 4
        } else if (resultData.code && resultData.description) { 
            return resultData.description;
        } else {
            return Object.keys(resultData).reduce(function (errorMessageSummary, errorKey) {
                var errorMessage = resultData[errorKey];

                if (Array.isArray(errorMessage)) {
                    errorMessageSummary = errorMessageSummary.concat(errorMessage);
                } else {
                    errorMessageSummary.push(errorMessage);
                }

                return errorMessageSummary;
            }, []).join(delimiter);
        }
    }

    return '';
};

// 过滤特殊字符串
const forbiddenString = {
    "&": "&amp;",
    "<(\\w)": function($1,$2){
        return "&lt;" + $2;
    },
    "<(\\!)": function ($1, $2) {
        return "&lt;" + $2;
    },
    "<(\\?)": function ($1, $2) {
        return "&lt;" + $2;
    },
    "<(\\/)": function ($1, $2) {
        return "&lt;" + $2;
    }
};

// 将危险字符替换为 HTML 实体
//
// @param {String} str
// @param {Boolean} amp 是否替换 & 为 &amp;
export function filterForbiddenString(str, amp = true) {
    let result = str || '';

    Object.keys(forbiddenString).forEach(key => {
        if (key !== '&' || amp) {
            result = result.replace(new RegExp(key, 'g'), forbiddenString[key]);
        }
    })

    return result;
};

export function foredate(str) {
    if (!str) {
        return '';
    }

    const now = new Date();
    const date = new Date(str);
    const dateInterval = now.getTime() - date.getTime();

    let yearEscaped = Math.floor(dateInterval / (365 * 24 * 3600 * 1000));
    let monthEscaped = Math.floor(dateInterval / (30 * 24 * 3600 * 1000));
    let weekEscaped = Math.floor(dateInterval / (7 * 24 * 3600 * 1000));
    let dayEscaped = Math.floor(dateInterval / (24 * 3600 * 1000));

    if (yearEscaped >= 1) {
        return `${yearEscaped} 年前`;
    } else if (monthEscaped >= 1) {
        return monthEscaped > 5 ? '半年前' : `${monthEscaped} 个月前`;
    } else if (weekEscaped >= 1) {
        return `${weekEscaped} 周前`;
    } else {
        return '近 7 天';
    }
};

const digitsRE = /(\d{3})(?=\d)/g;
export function currencyFn(number, currency = '', decimals = 2) {
    number = parseFloat(number);

    if (!isFinite(number) || (!number && number !== 0)) {
        return ''
    }

    const stringified = Math.abs(number).toFixed(decimals);
    const _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified;

    const i = _int.length % 3;
    const head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
        : '';
    const _float = decimals
        ? stringified.slice(-1 - decimals)
        : '';
    const sign = number < 0 ? '-' : '';

    return sign + currency + head +
        _int.slice(i).replace(digitsRE, '$1,') +
        _float;
};

export function capitalize (key = '') {
    return key.charAt(0).toUpperCase() + key.substring(1);
};

function isType(target, type) {
    return Object.prototype.toString.call(target) === `[object ${capitalize(type)}]`;
};

export function isString (target) {
    return isType(target, 'string');
};

export function isNumber (target) {
    return isType(target, 'number');
};

export function isBoolean (target) {
    return isType(target, 'boolean');
};

export function isObject (target) {
    return target && isType(target, 'object');
};

export function isFunction (target) {
    return isType(target, 'function');
};

export function isUndefined (target) {
    return isType(target, 'undefined');
};

export function isDate(target) {
    return isType(target, 'date');
};

export function isRegExp(target) {
    return isType(target, 'regExp');
};

export function isNull (target) {
    return target === null;
};

export function isNaN(target) {
    return target !== target;
};

// 混合对象，并且深入拷贝引用对象
export function extendObject(dest){
    const srcList = Array.prototype.slice.call(arguments, 1);
    srcList.forEach(src => {
        if(isNull(src) || !isObject(src)){
            return;
        }
    
        Object.keys(src).forEach((key) => {
            let value = src[key];
            if(Array.isArray(value)){
                dest[key] = [].concat(value);
            }else if(isObject(value)){
                let originTarget = dest[key];
                if(Array.isArray(originTarget) || isNull(originTarget) || !isObject(originTarget)){
                    originTarget = {};
                }
                dest[key] = extendObject(originTarget, value);
            }else{
                dest[key] = value;
            }
        })
    })

    return dest;
}


export function has (target, key) {
    return target.hasOwnProperty && target.hasOwnProperty(key);
};
// 拓展对象属性（不覆盖）
//
// @param {Object} 目标对象
// @param {Object...}
// @return {Object} 返回目标对象
export function defaults (dest) {
    const srcList = Array.prototype.slice.call(arguments, 1);

    srcList.forEach(src => {
        if (!src || typeof src !== 'object') {
            return;
        }
        
        Object.keys(src).forEach(key => {

            if ((!has(dest, key) && has(src, key))) {
                dest[key] = src[key];
            }
        })
    })

    return dest;
};

//生日转年龄
export function getAgeFromBirthday(birthday) {
    if(!birthday || birthday == '' || birthday.indexOf('0001') == 0) {
        return '';
    }
    return (new Date()).getFullYear() - birthday.slice(0, 4);
};