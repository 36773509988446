<template>
    <div class="member-selected" :style="{'height': height + 'px'}">
        <template v-if="selectedMemberNumber">
            <div class="selected-title">
                已选择{{selectedMemberNumber}}位联系人
            </div>
            <div class="selected-list-container">
                <ul class="selected-list">
                    <li 
                        class="selected-item" 
                        :class="member.firmShortName ? 'external-selected-item' : ''" 
                        v-for="(member, index) in selectedMemberList" 
                        :key="index"
                        v-show="!member.disabled"
                    >
                        <img class="member-avatar" :src="`${avatarBaseUrl}/${member.type === TIM.TYPES.CONV_GROUP ? 'group' : 'user'}/${member.type === TIM.TYPES.CONV_GROUP ? member.groupId : member.unionId}`" />
                        <!-- <img class="member-avatar" :src="member.avatarUrl"/> -->
                        <template v-if="!isShare">
                            <div v-if="member.firmShortName" class="member-text-info">
                                <span class="member-nick">
                                    <template v-if="member.realName">
                                        {{member.realName}}@{{member.nickname}}
                                    </template>
                                    <template v-else>
                                        {{member.nick}}
                                    </template>
                                </span>
                                <span class="member-firm">
                                    {{member.firmShortName}}
                                </span>
                            </div>
                            <span v-else class="member-nick">
                                <template v-if="member.realName">
                                    {{member.realName}}@{{member.nickname}}
                                </template>
                                <template v-else>
                                    {{member.nick}}
                                </template>
                            </span>
                        </template>
                        <template v-if="isShare">
                            <span v-if="member.groupId" class="member-nick">{{member.groupName}}</span>
                            <div v-else class="member-text-info">
                                <span class="member-nick">{{member.name || member.userName}}</span>
                                <span class="member-firm" v-if="member.company || member.title">
                                    {{member.company}} | {{member.title}}
                                </span>
                                <span class="member-firm" v-else>
                                    职通车候选人
                                </span>
                            </div>
                        </template>
                        <span class="member-remove el-icon-error" @click="removeSelected(member)"></span>
                    </li>
                </ul>
            </div>
        </template>
        <div class="selected-title" v-else>
            未选择联系人
        </div>
    </div>
</template>

<script>
import { avatarHost } from '../utils/avatarHost.js';
export default {
    props: {
        selectedMemberList: Array,
        height: Number,
        isShare: Boolean
    },
    computed: {
        selectedMemberNumber() {
            return this.selectedMemberList.filter(item => !item.disabled).length
        },
        avatarBaseUrl() {
            return avatarHost();
        }
    },
    methods: {
        removeSelected(member) {
            this.$emit('removeSelected', member);
        }
    }
}
</script>

<style lang="scss" scope>
.member-selected {
    width: 100%;
    padding: 14px 18px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    .selected-title {
        color: #999;
        line-height: 20px;
        padding-bottom: 6px;
    }
    .selected-list-container {
        flex-grow: 1;
        overflow: hidden;
        overflow-y: auto;
    }
    .selected-list {
        margin-top: 4px;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 18px;
        .selected-item {
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            &.external-selected-item {
                height: 36px;
                line-height: 36px;
            }

            .member-avatar {
                width: 32px;
                height: 32px;
                display: inline-block;
                border-radius: 16px;
                border: 1px solid $primary;
            }
            .member-text-info {
                display: flex;
                flex-direction: column;
                width: 216px;
                height: 36px;
                line-height: initial;
            }
            .member-nick, .member-firm {
                display: inline-block;
                color: #4a4a4a;
                margin: 0 8px;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex-grow: 1;
            }
            .member-firm {
                font-size: 12px;
                color: #999;
            }
            .member-remove {
                font-size: 18px;
                line-height: 32px;
                color: #d6d6d6;
                cursor: pointer;
                &:hover {
                    color: #999;
                }
            }
        }
    }
}
</style>