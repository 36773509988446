var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "message-bubble",
    { attrs: { isMine: _vm.isMine, message: _vm.message } },
    [
      _c(
        "div",
        {
          staticClass: "sound-element-wrapper",
          attrs: { title: "单击播放" },
          on: { click: _vm.playOrPause },
        },
        [
          _c("i", {
            staticClass: "icon-voice-new",
            class: { "is-mine-sound": _vm.isMine },
            staticStyle: { position: "relative" },
          }),
          _vm._v("\r\n    " + _vm._s(_vm.second + '"') + "\r\n  "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }