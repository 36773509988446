var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: _vm.shape === "circle" ? "shape-circle" : "",
    },
    [_c("img", { attrs: { src: _vm.avatarSrc } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }