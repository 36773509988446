<template>
    <div class="realtimedata-dialog" v-if="isShow">
        <div class="statistic-bag">
            <div class="main-content">
                <i class="icon-close" @click="closeDialog"></i>
                <div class="dialog-nav">
                    <img src="//hstatic.hirede.com/lbd/images/activity/2016010801.png" />
                </div>
                <div class="dialog-left">
                    <div class="count-item">
                        <i class="count-icon count-icon-job"></i>
                        更新职位
                        <span class="number">{{listData.recommendationStatistics.jobsCount}}</span>
                    </div>
                    <div class="count-item">
                        <i class="count-icon count-icon-recommendation"></i>
                        新增推荐
                        <span class="number">{{listData.recommendationStatistics.recommendationsCount}}</span>
                    </div>
                    <div class="count-item">
                        <i class="count-icon count-icon-comment"></i>
                        新增评论
                        <span class="number">{{listData.recommendationStatistics.commentsCount}}</span>
                    </div>
                    <div class="count-item">
                        <i class="count-icon count-icon-interview"></i>
                        面试安排
                        <span class="number">{{listData.recommendationStatistics.interviewsCount}}</span>
                    </div>
                    <div class="count-item">
                        <i class="count-icon count-icon-offer"></i>
                        Offer　　
                        <span class="number">{{listData.recommendationStatistics.offerThisWeekCount}}</span>
                    </div>
                </div>
                <div class="dialog-right">
                    <ul class="detail-list" v-if="listData.list.length != 0">
                        <li class="detail-item" v-for="(item, index) in listData.list" :key="index">
                            <div class="avater-box">
                                <img class="avater" :src="[item.posterAvatarUrl ? item.posterAvatarUrl : avatar]" width="38" />
                            </div>
                            <div class="detail-info">
                                <p>
                                    <span class="m-r-5">{{item.posterRealName}}</span>
                                    <span class="m-r-5">@{{item.posterNickName}}</span>
                                    <span class="m-r-5">{{item.posterFirmShortName}}</span>
                                    <span style="margin-right: 20px;">{{item.posterTitle}}</span>
                                    <span class="created-time">{{item.created}}</span>
                                </p>
                                <div v-if="item.type == 1">
                                    推荐了候选人<span class="candidate-name">{{item.candidateName}}</span>
                                </div>
                                <div v-if="item.type == 2 || item.type == 4">
                                    接受了候选人<span class="candidate-name">{{item.candidateName}}</span>
                                </div>
                                <div v-if="item.type == 3">
                                    拒绝了候选人<span class="candidate-name">{{item.candidateName}}</span><br/>
                                    <span>{{item.rejectedReason }}</span>
                                </div>
                                <div v-if="item.type == 5">
                                    将候选人<span class="candidate-name">{{item.candidateName}}</span>推进到面试阶段
                                </div>
                                <div v-if="item.type == 6">
                                    为候选人<span class="candidate-name">{{item.candidateName}}</span>安排了面试
                                </div>
                                <div v-if="item.type == 7">
                                    为候选人<span class="candidate-name">{{item.candidateName}}</span>取消了面试
                                </div>
                                <div v-if="item.type == 8">
                                    反馈了候选人<span class="candidate-name">{{item.candidateName}}</span>的面试结果 (<span>{{item.result}}</span>)<br/>
                                    <span>{{item.evaluation}}</span>
                                </div>
                                <div v-if="item.type == 9">
                                    将候选人<span class="candidate-name">{{item.candidateName}}</span>推进到Offer阶段
                                </div>
                                <div v-if="item.type == 10">
                                    将候选人<span class="candidate-name">{{item.candidateName}}</span>推进到入职阶段
                                </div>
                                <div v-if="item.type == 11">
                                    淘汰了候选人<span class="candidate-name">{{item.candidateName}}</span>
                                </div>
                                <div v-if="item.type == 12">
                                    暂停了<span> {{item.jobName}} </span>职位<br>
                                    <span>{{item.reason}} | {{item.feedToBreak}}</span>
                                </div>
                                <div v-if="item.type == 13">
                                    完成了<span> {{item.jobName}} </span>职位<br>
                                    <span>{{item.reason}} | {{item.feedToBreak}}</span>
                                </div>
                                <div v-if="item.type == 14">
                                    重启了<span> {{item.jobName}} </span>职位
                                </div>
                                <div v-if="item.type == 15">
                                    发布了<span> {{item.jobName}} </span>职位
                                </div>
                                <div v-if="item.type == 16">
                                    将<span> {{item.jobName}} </span>职位移交给<span class="candidate-name">{{item.to}}</span>
                                </div>
                                <div v-if="item.type == 17">
                                    将候选人<span class="candidate-name">{{item.candidateName}}</span>设置为离职状态
                                </div>
                                <div v-if="item.type == 18">
                                    筛选通过了候选人<span class="candidate-name">{{item.candidateName}}</span>
                                </div>
                                <div v-if="item.type == 22">
                                    将职位[<span>{{item.jobName}}</span>]的状态修改为不紧急
                                </div>
                                <div v-if="item.type == 23">
                                    将职位<span>{{item.jobName}}</span>的状态修改为紧急
                                </div>
                                <div v-if="item.type == 1024">
                                    <span v-html="item.content"></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <p v-if="listData.list.length == 0">暂无数据</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setInterval, clearInterval } from 'timers';
const avatar = require("@src/assets/images/icon/work-report/avatar.png");

export default {
    props: {
        closeRealtimedataDialog: Function
    },
    data () {
        return {
            avatar: avatar,
            isShow: false,
            listData: {
                list: [],
                recommendationStatistics: {
                    jobsCount: 0,
                    recommendationsCount: 0,
                    commentsCount: 0,
                    interviewsCount: 0,
                    offerThisWeekCount: 0
                }
            },
            listAnimation: "",
            animateIndex: 1
        }
    },
    methods: {
        closeDialog(){
            clearInterval(this.listAnimation);
            this.animateIndex = 1;
            this.isShow = false;
            this.closeRealtimedataDialog();
        },
        showDialog(){
            this.isShow = true;
            _request({
                url: "/MarketActivity/Feed/ActivityStreams",
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then((res) => {
                this.listData = res;
                let leng = this.listData.list.length;
                if(leng > 0) {
                    let i = this.animateIndex;
                    this.listAnimation = setInterval(() => {
                        if(i == leng) {
                            i = 0;
                        }
                        $(".detail-list:first").animate({
                            marginTop: 26 - 130 * i
                        }, 500, () => {
                            $(this).css({ marginTop: 26 - 130 * i + "px" });
                        });
                        i++;
                    }, 5000);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.m-r-5 {
    margin-right: 5px;
}
.realtimedata-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;

    .statistic-bag {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;

        .main-content {
            position: absolute !important;
            width: 1200px;
            height: 566px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            z-index: 100000;

            .icon-close {
                position: absolute;
                top: -9px;
                right: 3px;
                width: 30px;
                height: 30px;
                padding: 6px;
                box-sizing: border-box;
                cursor: pointer;
                border-radius: 0.5rem;
                border: 2px solid #ffcc33;
                z-index: 100001;
                background: url("~@src/assets/images/floatOption/dialog_close.png") no-repeat #ffffff;
                background-size: contain;
                &::before {
                    display: none;
                }
            }

            .dialog-nav {
                width: 200px;
                position: absolute;
                left: 50%;
                margin: 0 auto;
                top: -30px;
                transform: translateX(-50%);

                & > img {
                    width: 100%;
                }
            }

            .dialog-left, .dialog-right {
                float: left;
                margin: 15px;
                box-sizing: border-box;
            }

            .dialog-left {
                width: 358px;
                padding: 20px;
                margin-left: 30px;
                background-color: #fffbea;
                border-radius: 8px;
                border: 1px solid #f9ebdc;
                
                .count-item {
                    height: 50px;
                    font-size: 16px;
                    margin: 40px 20px;
                    line-height: 50px;
                    border-radius: 20px;
                    background-color: blanchedalmond;
                    text-align: center;
                    position: relative;

                    .count-icon {
                        width: 17px;
                        height: 17px;
                        line-height: 50px;
                        margin-right: 5px;
                        display: inline-block;
                        &::before {
                            display: none;
                        }
                    }
                    .count-icon-job {
                        background: url("~@src/assets/images/floatOption/icon-job.png") ;
                    }
                    .count-icon-recommendation {
                        background: url("~@src/assets/images/floatOption/icon-recommendation.png");
                    }
                    .count-icon-comment {
                        width: 19px;
                        height: 19px;
                        background: url("~@src/assets/images/floatOption/icon-comment.png");
                    }
                    .count-icon-interview {
                        background: url("~@src/assets/images/floatOption/icon-interview.png");
                    }
                    .count-icon-offer {
                        background: url("~@src/assets/images/floatOption/icon-offer.png");
                    }

                    .number{
                        float: right;
                        width: 80px;
                        font-size: 36px;
                        margin-right: 30px;
                        color: #ff8a00;
                    }
                }
            }
            .dialog-right {
                width: 752px;
                height: 536px;
                margin-right: 30px;
                overflow: hidden;

                .detail-list {
                    list-style-type: none;
                    width: 90%;
                    margin: 30px 35px;

                    .detail-item {
                        height: 110px;
                        margin-bottom: 20px;

                        &:before,
                        &:after {
                            content: "";
                            display: table;
                            clear: both;
                        }
                        .avater-box {
                            float: left;
                            .avater {
                                width: 60px;
                                height: 60px;
                                border-radius: 30px;
                            }
                        }
                        p {
                            line-height: 18px;
                            word-break: break-all;
                        }
                        .detail-info {
                            margin-left: 80px;
                            max-height: 100px;
                            overflow: hidden;
                            font-size: 14px;
                            color: rgb(102, 102, 102);
                        }
                        .created-time {
                            float: right;
                        }
                        .candidate-name {
                            color: rgb(56, 188, 156);
                        }
                    }
                }
            }
        }
    }
}
</style>
