import Vue from 'vue'
import { isNumber } from '#/js/util/tools.js';

const CURRENCY_ACCURACY = 100;

const digitsRE = /(\d{3})(?=\d)/g

export function humanSize (size) {
    return `${(size / 1024).toFixed(1)}KB`
};

export function currency(number, currency = '', decimals = 2) {
    number = parseFloat(number);

    if (!isFinite(number) || (!number && number !== 0)) {
        return ''
    }

    const stringified = Math.abs(number).toFixed(decimals);
    const _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified;

    const i = _int.length % 3;
    const head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
        : '';
    const _float = decimals
        ? stringified.slice(-1 - decimals)
        : '';
    const sign = number < 0 ? '-' : '';

    return sign + currency + head +
        _int.slice(i).replace(digitsRE, '$1,') +
        _float;
};

export function cnTenThousand (number) {
    if (!isNumber(number)) {
        return number;
    }

    const digit = number / Math.pow(10, 4);
    const floorDigit = Math.floor(digit);

    if (digit >= 1) {
        if (digit - floorDigit - 0.1 >= 0) {
            return `${digit.toFixed(1)} 万`;
        } else {
            return `${floorDigit} 万`;
        }
    } else {
        return `${number} `;
    }
};

// 5000 => 50
export function fenToYuan (amount) {
    return parseFloat(amount || '0') / CURRENCY_ACCURACY
};

export function int (target) {
    return parseInt(target, 10);
};

export function float (target) {
    return parseFloat(target);
};

export function sign (target) {
    if (target > 0) {
        return `+${target}`;
    } else if (target < 0) {
        return `-${Math.abs(target)}`;
    } else {
        return '0';
    }
};

export function magicTenThousand (number) {
    if (!isNumber(number)) {
        return number;
    }

    const digit = number / Math.pow(10, 4);
    const floorDigit = Math.floor(digit);

    if (digit >= 1 && digit < 10) {
        return `${digit.toFixed(1)}`;
    } else if(digit >= 10) {
        return Math.round(digit);
    } else {
        return `${number}`;
    }
};

export function numberToFixedTwo(val) {
    if(!val) {
        return '0.00';
    }
    var temp = Number(val);
    if(window.isNaN(temp) && (typeof temp === 'number')){
        return val;
    }else {
        return temp.toFixed(2);
    }
}

Vue.filter('int', int);
Vue.filter('float', float);
Vue.filter('sign', sign);
Vue.filter('currency', currency);
Vue.filter('humanSize', humanSize);
Vue.filter('fenToYuan', fenToYuan);
Vue.filter('cnTenThousand', cnTenThousand);
Vue.filter('magicTenThousand', magicTenThousand);
Vue.filter('numberToFixedTwo', numberToFixedTwo);
