const state = {
    searchTraceId: '',
}

const getters = {
    getSearchTraceId(state) {
        return state.searchTraceId
    }
}

const actions = {
    asyncUpdateSearchTraceId({commit, state}, param){
        commit('updateSearchTraceId',param);
    }
}

const mutations = {
    updateSearchTraceId(state, searchTraceId) {
        state.searchTraceId = searchTraceId;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
