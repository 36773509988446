var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "group-profile",
    },
    [
      _c(
        "div",
        { staticClass: "group-profile-item group-name" },
        [
          _c("div", { staticClass: "group-name-title" }, [
            _vm._v("\n            群名\n        "),
          ]),
          !_vm.isUpdateGroupName
            ? _c("span", { staticClass: "group-name-show" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.group.name) + "\n            "
                ),
                !_vm.isCompany && _vm.isGroupOwner
                  ? _c("i", {
                      staticClass: "el-icon-edit",
                      on: { click: _vm.handleGroupName },
                    })
                  : _vm._e(),
              ])
            : !_vm.isCompany
            ? _c("el-input", {
                on: { input: _vm.inputGroupName, blur: _vm.updateGroupName },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.updateGroupName.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.groupNameInput,
                  callback: function ($$v) {
                    _vm.groupNameInput = $$v
                  },
                  expression: "groupNameInput",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "group-profile-item group-member-list-wrapper" },
        [
          _c(
            "div",
            { staticClass: "group-member-list" },
            [
              !_vm.isCompany
                ? _c(
                    "span",
                    { staticClass: "group-member-item add-member-btn" },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline btn-icon",
                        on: { click: _vm.addGroupMember },
                      }),
                      _vm._v("\n                添加\n            "),
                    ]
                  )
                : _vm._e(),
              !_vm.isCompany && _vm.isGroupOwner && !_vm.isRemoveGroupMember
                ? _c(
                    "span",
                    { staticClass: "group-member-item remove-member-btn" },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-close btn-icon",
                        on: {
                          click: function ($event) {
                            _vm.isRemoveGroupMember = true
                          },
                        },
                      }),
                      _vm._v("\n                删除\n            "),
                    ]
                  )
                : _vm._e(),
              !_vm.isCompany && _vm.isGroupOwner && _vm.isRemoveGroupMember
                ? _c(
                    "span",
                    { staticClass: "group-member-item finish-member-btn" },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-check btn-icon",
                        on: {
                          click: function ($event) {
                            _vm.isRemoveGroupMember = false
                          },
                        },
                      }),
                      _vm._v("\n                完成\n            "),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.groupMemberList, function (member, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "group-member-item" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "member-avatar-wrapper",
                        style:
                          member.userID === _vm.userId
                            ? "cursor: default;"
                            : "",
                      },
                      [
                        _c("img", {
                          attrs: { src: member.avatar },
                          on: {
                            click: function ($event) {
                              return _vm.chatMember(member.userID)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "member-name",
                        attrs: { title: member.nick },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(member.nick) +
                            "\n                "
                        ),
                      ]
                    ),
                    member.userID !== _vm.userId && _vm.isRemoveGroupMember
                      ? _c("i", {
                          staticClass: "el-icon-error",
                          on: {
                            click: function ($event) {
                              return _vm.removeMember(member)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
          _vm.hasMore
            ? _c(
                "span",
                {
                  staticClass: "group-member-more",
                  on: {
                    click: function ($event) {
                      _vm.loadMore = !_vm.loadMore
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.loadMore ? "收起" : "查看全部") +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "group-profile-item message-notified-switch" },
        [
          _c("span", [_vm._v("消息免提醒")]),
          _c("el-switch", {
            attrs: { "active-color": "#38bc9d", "inactive-color": "#d8d8d8" },
            on: { change: _vm.handleMessageRemindType },
            model: {
              value: _vm.groupMessageNotified,
              callback: function ($$v) {
                _vm.groupMessageNotified = $$v
              },
              expression: "groupMessageNotified",
            },
          }),
        ],
        1
      ),
      !_vm.isCompany
        ? _c(
            "div",
            {
              staticClass: "group-profile-item quit-group-btn",
              on: { click: _vm.beforeQuitGroup },
            },
            [_c("span", [_vm._v("退出群聊")])]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }