const state = {
    customerList: []
}

const actions = {
    setHaveAuditPermissionsCustomerList({ commit, state }, customerList) {
        commit('updateHaveAuditPermissionsCustomerList', { customerList });
    },
}

const mutations = {
    updateHaveAuditPermissionsCustomerList(state, { customerList }) {
        state.customerList = customerList;
    },
}

export default {
    state,
    actions,
    mutations
}
