import {candidate as candidateUrl, talentPool as talentPoolUrl, trackingList as trackingListUrl, resume as resumeUrl} from '#/js/config/api.json';
import degree from '#/js/config/degreeKeyValue.json';
import yearOfExperienceRegion from '#/js/config/yearOfExperienceRegion.json';

const URL = {
    getSearchHistory: '/Headhunting/TalentPool/LatestSearchHistories',
    removeSearchHistory: '/Headhunting/TalentPool/DeleteSearchHistory',
    lastest_recommendations: "/Resume/%p/LatestRecommendations",
    search:'/Headhunting/TalentPool/Search',
    lastest_operation:'/Headhunting/TalentPool/LatestLogs',
    getBubbleGroupedCount: '/Headhunting/TalentPool/BubbleGroupedCount',
    getLatestInquiryLogs: '/Headhunting/TalentPool/LatestInquiryLogs',
    getInquiryLogs: '/Resume/%p/InquiryLogs',
    addInquiryToResume: '/Resume/%p/InquiryLog',
    updateInquiryToResume:'/Resume/%p/InquiryLog/Update'
}

export default {
    /**
     * 获取搜索历史记录
     * @returns {*|PromiseLike<any | never>|Promise<any | never>}
     */
    getSearchHistory(isTrackingList) {
        return _request({
            method: 'GET',
            url: URL.getSearchHistory,
            data: {
                isTrackingList : isTrackingList
            }
        }).then(res => {
            let result = res.map(item => {
                return {
                    text: item
                }
            });

            return Promise.resolve(result);
        })
    },
    /**
     * 删除搜索历史纪录
     * @param searchContent 历史纪录文本
     * @returns {*}
     */
    removeSearchHistory(searchContent) {
        console.log(searchContent)
        return _request({
            method: 'POST',
            url: URL.removeSearchHistory,
            data:{
                searchContent: searchContent
            }
        })
    },

    /**
     * 列表查看最新推荐情况
     * @returns {*}
     */
    getLastestRecommendations(params = {id, isRecommendByMe = false} = {}) {
        return _request({
            method: 'GET',
            url: URL.lastest_recommendations.replace(/\%p/ig, params.id),
            data: {
                isRecommendByMe: params.isRecommendByMe
            }
        })
    },

    /**
     * 列表查看最新操作
     * @returns {*}
     */
    getLastestOperation(id){
        return _request({
            url: URL.lastest_operation,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: id
        });
    },

    /**
     * 列表搜索方法
     * @returns {*}
     */
    search(params) {
        return _request({
            method: 'POST',
            url: URL.search,
            data: params
        }).then(res => {
            const labelName = ['location', 'workExperienceCompaniesName', 'yearOfExperienceRegion','educationTags','degree', 'behaviorCenterOperation', 'tags'],
                degreeIndexMap={
                    0 : 6, //未知
                    6 : 0, //博士
                    4 : 1, //硕士
                    5 : 2, //MBA
                    3 : 3, //本科
                    2 : 4, //大专
                    1 : 5, //高中及以下
                },
                yearOfexpMap = {
                    0 : 4, //未知
                    1 : 3, // 3年以内
                    2 : 2, // 3-5年
                    3 : 1, // 5-10年
                    4 : 0, // 10年以上
                },
                schoolMap = {
                    "985": 2,
                    "211": 1,
                    "其他": 0
                };
            labelName.map(label => {
                const orderArray = res.categoryGroupedItemsDic[label];
                if (!orderArray) {
                    return res[label] = [];
                }
                if(label === 'degree' || label === 'yearOfExperienceRegion'){
                    const map = label === 'degree' ? degreeIndexMap : yearOfexpMap,itemMap = label === 'degree' ? degree : yearOfExperienceRegion;
                    Object.keys(itemMap).forEach(item => {
                        for(let i = 0; i < orderArray.length; i ++){
                            if(item === orderArray[i].itemValue){
                                return ;
                            } else if (item != orderArray[i].itemValue && i === orderArray.length - 1){
                                return orderArray.push({
                                    itemValue: item,
                                    itemText : item != 0 ? itemMap[item] : '未知',
                                    count : 0,
                                })
                            }
                        }
                    })
                    orderArray.sort((item1,item2) => {
                        return Number(map[item1.itemValue]) - Number(map[item2.itemValue]);
                    })
                }
                if(label === 'educationTags') {
                    Object.keys(schoolMap).forEach(item => {
                        for(let i = 0; i < orderArray.length; i ++){
                            if(item === orderArray[i].itemValue){
                                return ;
                            } else if (item != orderArray[i].itemValue && i === orderArray.length - 1){
                                return orderArray.push({
                                    itemValue: item,
                                    itemText : item,
                                    count : 0,
                                })
                            }
                        }
                    })
                    orderArray.sort((item1,item2) => {
                        return Number(schoolMap[item2.itemValue]) - Number(schoolMap[item1.itemValue]);
                    })
                }
                if(label == 'behaviorCenterOperation') {
                    orderArray.sort((item1,item2) => {
                        return item2.count - item1.count;
                    })
                }
                orderArray.map(item => {
                    if(label === 'location'){
                        item.id = item.itemValue;
                    }
                    item.text = item.itemText;
                    item.label = item.itemText;
                    item.isSelect = false;
                });
            });
            res.list.map( item => {
                item.resumeListItem.resumeInquiryLog = {};
            });
            return res;
        })
    },
    /**
     * 加入TrackingList
     * @param {
     *  sourceFrom: int 加入TrackingList来源
     *              1-TalentPool，2-TrackingList，3-FloatingList，4-简历详情，5-简历详情寻访，6-TalentPool寻访，7-NameList寻访，8-电话助手寻访，9-LBD推荐弹窗，10-简历详情智能推荐，11-职位详情智能推荐，12-抢单结果智能推荐，13-推荐结果智能推荐
     *  resumeIds: [] 简历id
     * }
     * @returns {
     *  successCount: 0 加入成功数量
     *  existCount: 0 已存在数量
     *  failCount: 0 加入失败数量
     *  total：总数
     * }
     */
    addToTrackingList(params = {resumeIds, sourceFrom} = {}) {
        return _request({
            url: trackingListUrl.batch_trackinglist.replace(/\%p/ig, params.sourceFrom),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {resumeIds: params.resumeIds}
        });
    },
    /**
     * 导入企业库/批量抢占归属
     * @param {
     *  tbdResumeIds: [] 个人库简历ID
     * }
     * @returns {
     *  failCount: 0 导入失败数量
     *  successCount: 0 导入成功数量
     *  total: 0    导入全部数量
     *  list: [{
     *      errorMessage: '' 错误信息
     *      successed: true 导入结果
     *      id: ''  对应企业库简历ID，仅当导入成功时才有值
     *      tbdResumeId: '' 人才库建立ID
     * }]
     * }
     */
    importToFirm(params = {tbdResumeIds} = {}) {
        return _request({
            url: candidateUrl.import_to_firm,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params
        });
    },
    /**
     * 收藏
     */
    resumeCollect(param = {resumeId} = {}) {
        return _request({
            url: talentPoolUrl.resume_collect.replace(/\%p/ig, param.resumeId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },
    /**
     * 取消收藏
     */
    resumeUncollect(param = {resumeId} = {}) {
        return _request({
            url: talentPoolUrl.resume_uncollect.replace(/\%p/ig, param.resumeId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },
    /**
     * 获得个人标签库全部标签
     * @returns [
     *  {
     *    tagName 标签名称
     *    created 创建时间
     *  }
     * ]
     */
    getMyItaps() {
        return _request({
            url: talentPoolUrl.get_my_itaps,
            method: 'GET'
        });
    },
    /**
     * 添加标签
     * @param {
     *  resumeIds: []   需要添加标签的简历
     *  tagsName: []   标签数组
     *  type: 1     添加类型，1为单份简历添加，2为多份简历添加（批量添加）
     * }
     * @returns {*}
     */
    addItapToResume(param = {resumeIds, tagsName, type} = {}) {
        return _request({
            url: talentPoolUrl.add_itap,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param
        });
    },
    /**
     * 移除标签
     * @param {
     *  resumeId: ''   需要移除标签的简历
     *  tagName: ''   标签
     * }
     * @returns {*}
     */
    removeItapFromResume(param = {resumeId, tagName} = {}) {
        return _request({
            url: talentPoolUrl.remove_itap.replace(/\%p1/ig, param.resumeId).replace(/\%p2/ig, param.tagName),
            method: 'DELETE'
        });
    },
    /**
     * 添加寻访
     * @param {
     *  namelistId: '' 候选人ID
     *  resumeId: '' 简历ID
     *  addToTrackingList: '' 是否将该简历加入tracking list
     *  isValidInquiry: Boolean 是否是有效寻访
     *  content: '' 寻访记录
     *  jobHoppingOccasion: '' 跳槽时机
     *  employerInfo: '' 雇主画像
     *  expectSalary: '' 期望报酬
     * }
     * @return {*}
     */
    addInquiryToResume(resumeId, param = {
        IsAddTrackingList,
        isValidInquiry,
        content,
        jobHoppingOccasion,
        employerInfo,
        expectSalary
    } = {}) {
        param.ResumeId = resumeId;
        return _request({
            url: URL.addInquiryToResume.replace(/\%p/ig, resumeId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: param
        })
    },
    /**
     * 更新单条简历数据
     * @param {
     *  resumeId: '' 简历ID
     *  isTrackingList: 是否是trackinglist表格
     * }
     * @return {*}
     */
    updateSingle(param = {resumeId, isTrackingList} = {}) {
        return _request({
            url: talentPoolUrl.update_single.replace(/\%p/ig, param.resumeId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                isTrackingList: param.isTrackingList
            }
        })
    },
    /**
     * 获取单个人才更多简历
     * @param {
     *  talentId: '' 人才ID,
     *  resumeId: '' 展示的简历的简历ID,
     *  isTrackingList: Boolean 是否是trackinglist表格
     * }
     * @return {*}
     */
    getMoreResume(param = {talentId, resumeId, isTrackingList} = {}) {
        return _request({
            url: talentPoolUrl.get_more_resume.replace(/\%p/ig, param.talentId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param
        })
    },
    /**
     * 获取气泡聚合
     * @param params
     * @returns {*}
     */
    getBubbleGroupedCount(params = {
        startDate,
        enDateTime,
        locationIds,
        keyWords,
        title,
        companyName,
        realName,
        mobile,
        email,
        label,
        inquiryLogContent
    } = {}) {
        return _request({
            url: URL.getBubbleGroupedCount,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        })
    },
    viewContact(candidateId,param = {type, __RequestVerificationToken} = {}){
        return _request({
            url: talentPoolUrl.view_contact.replace(/\%p/ig, candidateId),
            method: 'POST',
            data: param
        })
    },
    getLatestInquiryLogs(ResumeIds) {
        return _request({
            url: URL.getLatestInquiryLogs,

            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                ResumeIds
            }
        });
    },
    
    getInquiryLogs(resumeId, params) {
        return _request({
            url: URL.getInquiryLogs.replace(/\%p/ig, resumeId),
            method: 'GET',
            data: params
        });
    },

    getReminder(resumeId){
        return _request({
            url: talentPoolUrl.get_reminder.replace(/\%p/ig, resumeId),
            method: 'GET'
        })
    },
    /**
     * 移除TrackingList
     * @param {
    *  sourceFrom: int 操作来源
    *              1-TalentPool，2-TrackingList，3-FloatingList，4-简历详情，5-简历详情寻访，6-TalentPool寻访，7-NameList寻访，8-电话助手寻访，9-LBD推荐弹窗，10-简历详情智能推荐，11-职位详情智能推荐，12-抢单结果智能推荐，13-推荐结果智能推荐
    *  resumeIds: [] 简历id
    * }
    * @returns {
    *  successCount: 0 加入成功数量
    *  existCount: 0 已存在数量
    *  failCount: 0 加入失败数量
    *  total：总数
    * }
    */
    removeTrackingList(params = {resumeIds, sourceFrom} = {}) {
        return _request({
            url: trackingListUrl.batch_trackinglist.replace(/\%p/ig, params.sourceFrom),
            method: 'DELETE',
            data: {
                resumeIds: params.resumeIds
            }
        });
    },
    /**
     * 添加推荐原因
     * @param {
     *  resumeId: '' 简历ID
     *  reason: '' 推荐原因
     * }
     */
    addRecommendReason(param = {resumeId, reason} = {}) {
        return _request({
            url: resumeUrl.resume_tempRecommendReason_add,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param
        });
    },
    /**
     * 更新推荐原因
     * @param {
     *  resumeId: '' 简历ID
     *  reason: '' 推荐原因
     * }
    */
    updateRecommendReason(param = {resumeId, reason} = {}) {
        return _request({
            url: resumeUrl.resume_tempRecommendReason_update,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param
        });
    },
    /*
     * 编辑寻访
     * @param {
     *  id: '' 寻访ID
     *  resumeId: '' 简历ID
     *  addToTrackingList: '' 是否将该简历加入tracking list
     *  isValidInquiry: Boolean 是否是有效寻访
     *  content: '' 寻访记录
     *  jobHoppingOccasion: '' 跳槽时机
     *  employerInfo: '' 雇主画像
     *  expectSalary: '' 期望报酬
     * }
     * @return {*}
     */
    updateInquiryToResume(resumeId, id, token,param = {
        IsAddTrackingList,
        isValidInquiry,
        content,
        jobHoppingOccasion,
        employerInfo,
        expectSalary
    } = {}) {
        param.ResumeId = resumeId;
        param.id = id;
        param.__RequestVerificationToken = token;
        return _request({
            url: URL.updateInquiryToResume.replace(/\%p/ig, resumeId),
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: param
        })
    },
    /**
     * 获取候选人待沟通职位
     * @param {*} params 
     */
    getLastestComminucations(params = {resumeId = ""} = {}) {
        return _request({
            url: trackingListUrl.get_last_communications.replace(/\%p/ig, params.resumeId),
            method: "GET"
        })
    },
    /**
     * 获取候选人待沟通职位
     * @param {*} params 
     */
    removeComminucation(params = {id = ""} = {}) {
        return _request({
            url: trackingListUrl.remove_comminucation.replace(/\%p/ig, params.id),
            method: "GET"
        })
    },

    getTeamInfo() {
        return _request({
            url: trackingListUrl.get_team_info,
            method: 'GET'
        });
    },

    /**
     * 获取简历绑定的组织架构信息
     * @param {*} params 
     */
     getResumeOrg(params = {}) {
        return _request({
            url: talentPoolUrl.get_resume_org.replace(/%p/g, params.resumeId),
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
}
