var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "system-recommend-dialog",
      class: _vm.isShow ? "show" : "hidden",
    },
    [
      _c("span", { staticClass: "icon icon-close", on: { click: _vm.close } }),
      _c(
        "div",
        { staticClass: "dialog-container" },
        [
          _c("span", { staticClass: "icon icon-AIhousekeeper" }, [
            _vm.type === 0
              ? _c("span", [_vm._v("系统帮你找到的合适候选人，省时又高效～")])
              : _vm._e(),
            _vm.type === 1
              ? _c("span", [_vm._v("更多相似的候选人，可能对您有帮助～")])
              : _vm._e(),
          ]),
          _c(
            "swiper",
            { ref: "mySwiper", attrs: { options: _vm.swiperOption } },
            _vm._l(_vm.listData, function (item, index) {
              return _c("swiperSlide", { key: item.candidateId }, [
                _c("li", { staticClass: "recommend-item" }, [
                  _c("p", { staticClass: "base-info" }, [
                    _c("span", { staticClass: "info-name" }, [
                      _vm._v(_vm._s(item.candidateName)),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.title))]),
                  ]),
                  _c("p", { staticClass: "list-info" }, [
                    item.city
                      ? _c("span", { staticClass: "info-item" }, [
                          _vm._v(_vm._s(item.city)),
                        ])
                      : _vm._e(),
                    item.company
                      ? _c("span", { staticClass: "info-item" }, [
                          _vm._v(_vm._s(item.company)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("p", { staticClass: "list-info" }, [
                    item.school
                      ? _c("span", { staticClass: "info-item" }, [
                          _vm._v(_vm._s(item.school)),
                        ])
                      : _vm._e(),
                    item.degree
                      ? _c("span", { staticClass: "info-item" }, [
                          _vm._v(_vm._s(item.degree)),
                        ])
                      : _vm._e(),
                    item.yearOfExperience
                      ? _c("span", { staticClass: "info-item" }, [
                          _vm._v(_vm._s(item.yearOfExperience) + "年"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("p", { staticClass: "time-info" }, [
                    _vm._v(
                      "\n                        最近更新：" +
                        _vm._s(_vm._f("momentFormatDate")(item.updated)) +
                        "\n                    "
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "btn-new view-btn",
                      on: {
                        click: function ($event) {
                          return _vm.gotoDetail(item.candidateId)
                        },
                      },
                    },
                    [_vm._v("立即看看")]
                  ),
                ]),
              ])
            }),
            1
          ),
          _vm.listData.length > 3
            ? _c("div", { staticClass: "swiper-menu" }, [
                _c("span", {
                  staticClass: "icon-new icon-leftarrow icon-swiper-left",
                  attrs: { slot: "button-prev" },
                  on: { click: _vm.prev },
                  slot: "button-prev",
                }),
                _c("span", {
                  staticClass: "icon-new icon-rightarrow icon-swiper-right",
                  attrs: { slot: "button-next" },
                  on: { click: _vm.next },
                  slot: "button-next",
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }