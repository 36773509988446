import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import verificationToken from './modules/verificationToken'
import candidateDetail from './modules/candidateDetail'
import workbench from './modules/workbench'
import keepAlive from './modules/keepAlive'
import myItaps from './modules/myItaps'
import notice from './modules/notice';
import recommendInfo from './modules/recommendInfo';
import resumeUpload from './modules/resumeUpload';
import candidateListUrl from './modules/candidateListUrl';
import standstillJob from './modules/standstillJob';
import departmentAudit from './modules/departmentAudit';
import phoneCall from './modules/phoneCall';
import commonTrack from './modules/commonTrack';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        verificationToken,
        candidateDetail,
        workbench,
        keepAlive,
        myItaps,
        notice,
        recommendInfo,
        resumeUpload,
        candidateListUrl,
        standstillJob,
        departmentAudit,
        phoneCall,
        commonTrack
    }
})
