<template>
    <el-dialog
        class="notice-dialog"
        title="待处理候选人提醒"
        :visible.sync="dialogVisible"
        width="420px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true"
    >
        <div class="notice-list">
            <div
                class="notice-item"
                v-for="(notice, index) in noticeList"
                :key="index"
            >
                <div class="notice-title text-item">
                    <span class="notice-time">{{notice.remindTime | timeFilter}}</span>，你有新的提醒。
                </div>
                <div class="notice-candidate text-item">
                    <span class="notice-text">候选人：</span>
                    <span class="notice-realname text-primary" @click="goToCandidate(notice)">
                        {{notice.realName}}
                    </span>
                </div>
                <div class="notice-content text-item">
                    <span class="notice-text">提醒内容：</span>
                    <span class="content-text notice-text" >{{notice.content}}</span>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="readMore">查看更多</el-button> -->
            <el-button type="primary" @click="confirmHandle">我知道了</el-button>
        </span>
  </el-dialog>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            noticeList: [],
            dialogVisible: false,
            loading: false,
            closeTimeout: ""
        }
    },
    filters: {
        timeFilter(val) {
            return moment(val).format('YYYY/MM/DD HH:mm');
        }
    },
    methods: {
        show(list) {
            this.noticeList = list;
            this.dialogVisible = true;
            this.closeTimeout = setInterval(() => {
                if(localStorage.getItem('realTimeNotice')) {
                    this.confirmHandle();
                }
            }, 1000);
        },
        goToCandidate(notice) {
            if(window.lbdTrack){
                lbdTrack.track('noticeReadTimeDialogCandidateClick', {
                    resumeId: notice.resumeId,
                    noticeId: notice.id
                });
            } else {
                _tracker.track('noticeReadTimeDialogCandidateClick', JSON.stringify({
                    resumeId: notice.resumeId,
                    noticeId: notice.id
                }));
            }
            // window.open(`/Headhunting/MyCompany.html#/candidateDetail/${notice.resumeId}`);
            window.open(`/#/candidateDetail/${notice.resumeId}`);
        },
        readMore() {
            if(window.lbdTrack){
                lbdTrack.track('noticeReadTimeDialogReadMore', {});
            } else {
                _tracker.track('noticeReadTimeDialogReadMore');
            }
            this.confirmHandle();
            this.$emit('showNoticeList');
        },
        confirmHandle() {
            this.dialogVisible = false;
            localStorage.setItem('realTimeNotice', true);
            clearInterval(this.closeTimeout);
        }
    }
}
</script>

<style lang="scss" scope>
    .text-primary {
        color: $primary;
    }
    .notice-item {
        margin-top: 40px;
        &:nth-of-type(1) {
            margin-top: 0;
        }
        .text-item {
            line-height: 20px;
            margin-bottom: 8px;
            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }
        .notice-realname {
            cursor: pointer;
        }
        .notice-text {
            color: #444;
        }
        .notice-content {
            display: flex;
            .content-text {
                white-space: pre-line;
                display: inline-block;
            }
        }
    }
</style>