var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "customer-select-input",
      attrs: {
        "popper-class": "customer-select",
        filterable: "",
        clearable: "",
        disabled: _vm.isDisabled,
        placeholder: _vm.placeholder,
      },
      on: {
        clear: function ($event) {
          return _vm.handleCustomerChange({
            customerId: "",
            customerName: "",
          })
        },
      },
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleKeyupEnter.apply(null, arguments)
        },
      },
      model: {
        value: _vm.customerId,
        callback: function ($$v) {
          _vm.customerId = $$v
        },
        expression: "customerId",
      },
    },
    _vm._l(_vm.customerFilterList, function (customer) {
      return _c("el-option", {
        key: customer.customerId,
        attrs: {
          value: customer.customerId,
          label: customer.customerName,
          title: customer.customerName,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleCustomerChange(customer)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }