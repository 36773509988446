var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "428",
        "popper-class": "point-card",
        "visible-arrow": false,
        trigger: "hover",
      },
    },
    [
      _c("div", { staticClass: "point-card" }, [
        _c("div", { staticClass: "point-card-header" }, [
          _c("h3", [_vm._v("当前总积分")]),
          _c("span", { staticClass: "point-card-date" }, [
            _vm._v("20190320-" + _vm._s(_vm.today)),
          ]),
        ]),
        _c("div", { staticClass: "point-card-body" }, [
          _c("p", { staticClass: "sum-point" }, [
            _vm._v(_vm._s(_vm._f("currency")(_vm.integralCount, "", 0))),
          ]),
          _c("p", { staticClass: "today-point" }, [
            _vm._v("\n                今日获得积分\n                "),
            _c("span", { staticClass: "font-orange" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.todayPoint, "", 0))),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "point-card-footer" },
          [
            _c(
              "a",
              {
                staticClass: "point-card-link",
                attrs: {
                  href: "/Headhunting/MyCompany.html#/myPoint",
                  target: "_blank",
                },
                on: {
                  click: function ($event) {
                    return _vm.goToMyPoint("PointCard")
                  },
                },
              },
              [_vm._v("积分规则")]
            ),
            _vm._v("\n            |\n            "),
            _c(
              "a",
              {
                staticClass: "point-card-link",
                attrs: {
                  href: "/Headhunting/MyCompany.html#/myPointGetRecord",
                  target: "_blank",
                },
                on: { click: _vm.goToMyPointGetRecord },
              },
              [_vm._v("获得明细")]
            ),
            _vm.isShowPointRank
              ? [
                  _vm._v("\n                |\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "point-card-link",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("showPointRank")
                        },
                      },
                    },
                    [_vm._v("排行榜")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "a",
        {
          staticClass: "integral-wrap",
          attrs: {
            slot: "reference",
            href: "/Headhunting/MyCompany.html#/myPoint",
            target: "_blank",
          },
          on: {
            click: function ($event) {
              return _vm.goToMyPoint("TopBar")
            },
          },
          slot: "reference",
        },
        [
          _c("font-icon", { attrs: { href: "#icon-qiandai" } }),
          _c("ICountUp", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowUpdate,
                expression: "isShowUpdate",
              },
            ],
            ref: "countUp",
            attrs: { endVal: _vm.integralCount, options: _vm.options },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isShowUpdate,
                  expression: "!isShowUpdate",
                },
              ],
            },
            [_vm._v(_vm._s(_vm._f("currency")(_vm.integralCount, "", 0)))]
          ),
          _c("transition", { attrs: { name: "integral" } }, [
            _vm.isShowTip
              ? _c("div", { staticClass: "integral-tip" }, [
                  _vm._v("积分+" + _vm._s(_vm.increaseCount)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }